import React, { memo, useState } from "react";
import { Grid, TextField, MenuItem } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components
import Highcharts from "highcharts";
import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import CommonService from "../../../utils/services/CommonService";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  HighchartContainer,
} from "../../../styles/common";
import { getUploadToken } from "../../../utils";

const LengthStayHospital = () => {
  // This is the initial state of the DOM
  const initialData = {
    hospital_code: "",
    city_code: "",
    region_code: "",
    roomsin_hospitals: "",
    ward_type: "",
    facility_code: "",
    bed_grade: "",
    admission: "",
    visitor_patient: "",
    age: "",
    admission_patient: "",
    illness: "",
  };

  const hospital_code = [
    { label: "c", value: "c" },
    { label: "e", value: "e" },
    { label: "b", value: "b" },
    { label: "a", value: "a" },
    { label: "f", value: "f" },
    { label: "d", value: "d" },
    { label: "g", value: "g" },
  ];
  const city_code = [
    { label: "3", value: "3" },
    { label: "5", value: "5" },
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "6", value: "6" },
    { label: "9", value: "9" },
    { label: "10", value: "10" },
    { label: "4", value: "4" },
    { label: "11", value: "11" },
    { label: "7", value: "7" },
    { label: "13", value: "13" },
  ];
  const ward_type = [
    { label: "R", value: "R" },
    { label: "S", value: "S" },
    { label: "Q", value: "Q" },
    { label: "P", value: "P" },
    { label: "T", value: "T" },
    { label: "U", value: "U" },
  ];
  const facility_code = [
    { label: "F", value: "F" },
    { label: "E", value: "E" },
    { label: "D", value: "D" },
    { label: "B", value: "B" },
    { label: "A", value: "A" },
    { label: "C", value: "C" },
  ];
  const bed_grade = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
  ];
  const admission = [
    { label: "Emergency", value: "Emergency" },
    { label: "Trauma", value: "Trauma" },
    { label: "Urgent", value: "Urgent" },
  ];
  const age = [
    { label: "51-60", value: "51-60" },
    { label: "71-80", value: "71-80" },
    { label: "31-40", value: "31-40" },
    { label: "41-50", value: "41-50" },
    { label: "81-90", value: "81-90" },
    { label: "61-70", value: "61-70" },
    { label: "21-30", value: "21-30" },
    { label: "11-20", value: "11-20" },
    { label: "0-10", value: "0-10" },
    { label: "91-100", value: "91-100" },
  ];
  const illness = [
    { label: "Extreme", value: "Extreme" },
    { label: "Moderate", value: "Moderate" },
    { label: "Minor", value: "Minor" },
  ];

  const [sampleData, setSampleData] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "payerProvider",
    "lengthstayhospital",
  );
  const Token = getUploadToken();

  const showResult = () => {
    const NewDataSet = {
      hospital_code: "c",
      city_code: "3",
      region_code: "Z",
      roomsin_hospitals: "3",
      ward_type: "R",
      facility_code: "F",
      bed_grade: "2",
      admission: "Emergency",
      visitor_patient: "2",
      age: "51-60",
      admission_patient: "4911",
      illness: "Extreme",
    };

    setSampleData(NewDataSet);
  };
  const showResult2 = () => {
    const NewDataSet = {
      hospital_code: "b",
      city_code: "11",
      region_code: "X",
      roomsin_hospitals: "4",
      ward_type: "R",
      facility_code: "F",
      bed_grade: "2",
      admission: "Emergency",
      visitor_patient: "2",
      age: "31-40",
      admission_patient: "2405",
      illness: "Moderate",
    };
    setSampleData(NewDataSet);
    setResultData("");
  };

  const getResult = () => {
    const {
      hospital_code,
      city_code,
      region_code,
      roomsin_hospitals,
      ward_type,
      facility_code,
      bed_grade,
      admission,
      visitor_patient,
      age,
      admission_patient,
      illness,
    } = sampleData;

    const params = `${hospital_code},${city_code},${region_code},${roomsin_hospitals},${ward_type},${facility_code},${bed_grade},${admission},${visitor_patient},${age},${admission_patient},${illness},${Token.token}`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Field(s) are empty" });
    } else {
      setSpinner(true);

      getQueryResult("withParams", params)
        .then((res) => {
          console.log("result", res);
          setSpinner(false);
          setResultData(res.result);
          console.log(res);
          const params = [];
          const percentages = res.importance;
          percentages.map((arr) => {
            params.push({
              name: arr[0],
              y: arr[1],
            });
          });
          Highcharts.chart("prediction-chart", {
            chart: {
              plotShadow: false,
              type: "pie",
            },
            title: {
              text: "MAJOR CONTRIBUTING FACTORS",
            },
            tooltip: {
              pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                  enabled: true,
                  format: "<b>{point.name}</b>: {point.percentage:.1f} %",
                  style: {
                    color:
                      (Highcharts.theme
                        && Highcharts.theme.contrastTextColor)
                      || "black",
                  },
                },
              },
            },
            credits: {
              enabled: false,
            },
            series: [
              {
                colorByPoint: true,
                data: params,
              },
            ],
          });
          setSpinner(false);
        })
        .catch((err) => {
          console.log(err);

          setSpinner(false);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const handleInputs = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Recent Covid-19 Pandemic has raised alarms over one of the most
            overlooked area to focus: Healthcare Management. While healthcare
            management has various use cases for using data science, patient
            length of stay is one critical parameter to observe and predict if
            one wants to improve the efficiency of the healthcare management in
            a hospital.
          </p>
          <p>
            This parameter helps hospitals to identify patients of high LOS risk
            (patients who will stay longer) at the time of admission. Once
            identified, patients with high LOS risk can have their treatment
            plan optimized to minimize LOS and lower the chance of staff/visitor
            infection. Also, prior knowledge of LOS can aid in logistics such as
            room and bed allocation planning.
          </p>
          The following parameters would be used to predict the LOS:
          <Grid container>
            <Grid item xs={6}>
              <ul>
                <li>
                  <b>Hospital_type_code :</b>
                  {' '}
                  Unique code for the type of
                  Hospital
                </li>
                <li>
                  <b>Hospital_region_code :</b>
                  {' '}
                  Region Code of the Hospital
                </li>
                <li>
                  <b>Ward_Type :</b>
                  {' '}
                  Code for the Ward type
                </li>
                <li>
                  <b>Bed Grade :</b>
                  {' '}
                  Condition of Bed in the Ward
                </li>
                <li>
                  <b>Severity of Illness :</b>
                  {' '}
                  Severity of the illness recorded
                  at the time of admission
                </li>
                <li>
                  <b>Age :</b>
                  {' '}
                  Age of the patient
                </li>
                <li>
                  <b>Admission_Deposit :</b>
                  {' '}
                  Deposit at the Admission Time
                </li>
              </ul>
            </Grid>
            <Grid item xs={6}>
              <ul>
                <li>
                  <b>City_Code_Hospital :</b>
                  {' '}
                  City Code of the Hospital
                </li>
                <li>
                  <b>Available Extra Rooms in Hospital :</b>
                  {' '}
                  Number of Extra
                  rooms available in the Hospital
                </li>
                <li>
                  <b>Ward_Facility_Code :</b>
                  {' '}
                  Code for the Ward Facility
                </li>
                <li>
                  <b>Type of Admission :</b>
                  {' '}
                  Admission Type registered by the
                  Hospital
                </li>
                <li>
                  <b>Visitors with Patient :</b>
                  {' '}
                  Number of Visitors with the
                  patient
                </li>
              </ul>
            </Grid>
          </Grid>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/LengthofStay/length_of_stay.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.kaggle.com/nehaprabhavalkar/av-healthcare-analytics-ii"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container direction="row" justify="center" spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                id="hospital_code"
                variant="outlined"
                name="hospital_code"
                label="Hospital Type Code"
                select
                value={sampleData.hospital_code}
                onChange={handleInputs}
              >
                {hospital_code.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                id="city_code"
                variant="outlined"
                name="city_code"
                label="City Code Hospital"
                select
                value={sampleData.city_code}
                onChange={handleInputs}
              >
                {city_code.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                id="region_code"
                variant="outlined"
                name="region_code"
                label="Hospital Region Code"
                value={sampleData.region_code}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                id="roomsin_hospitals"
                variant="outlined"
                name="roomsin_hospitals"
                label="Available Extra Rooms in Hospital"
                value={sampleData.roomsin_hospitals}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                id="ward_type"
                variant="outlined"
                name="ward_type"
                label="Ward Type"
                select
                value={sampleData.ward_type}
                onChange={handleInputs}
              >
                {ward_type.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                id="facility_code"
                variant="outlined"
                name="facility_code"
                label="Ward Facility Code"
                select
                value={sampleData.facility_code}
                onChange={handleInputs}
              >
                {facility_code.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                id="bed_grade"
                variant="outlined"
                name="bed_grade"
                label="Bed Grade"
                select
                value={sampleData.bed_grade}
                onChange={handleInputs}
              >
                {bed_grade.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                id="admission"
                variant="outlined"
                name="admission"
                label="Type of Admission"
                select
                value={sampleData.admission}
                onChange={handleInputs}
              >
                {admission.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                id="visitor_patient"
                variant="outlined"
                name="visitor_patient"
                label="Visitors with Patient"
                value={sampleData.visitor_patient}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                id="age"
                variant="outlined"
                name="age"
                label="Age"
                select
                value={sampleData.age}
                onChange={handleInputs}
              >
                {age.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                id="admission_patient"
                variant="outlined"
                name="admission_patient"
                label="Admission Deposit"
                value={sampleData.admission_patient}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                id="illness"
                variant="outlined"
                name="illness"
                label="Severity of Illness"
                select
                value={sampleData.illness}
                onChange={handleInputs}
              >
                {illness.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={showResult}
              >
                Sample 1
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={showResult2}
              >
                Sample 2
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={getResult}
              >
                Submit
              </StyledButton>
            </Grid>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {resultData && (
            <Result>
              <strong>Result:</strong>
              {resultData}
            </Result>
          )}
          <Grid item xs={12}>
            <HighchartContainer id="prediction-chart" display />
          </Grid>
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(LengthStayHospital);
