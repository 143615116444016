export default {
  llm: "llm",
  nba: "nba",
  antibioticResistancePrediction: "antibiotic-resistance-prediction",
  deepeyeVisualizer: "deepeye-visualizer",
  competitorAnalyzer: "competitor-analyzer",
  drugDiscovery: "drug-discovery",
  incisioncare: "incisioncare-management",
  shoulderImplant: "shoulder-implant",
  roboticSegmentation: "robotic-segmentation",
  mentalHealth: "mental-health",
  primerClassifier: "primer-classifier",
  forecastingExcellence: "forecasting-excellence",
  bioLemmatizer: "bio-lemmatizer",
  neptuneGaphBot: "neptuneGaphBot",
  drugCascading: "drug-cascading",
  polyPharmacy: "drug-cascading",
  jakInhibitors: "jak-inhibitors",
  drugRecommendation: "drug-recommendation",
  ocrHandwritten: "ocr-handwritten",
  mirthHL7: "mirth-hl7",
  dataInteroperablePlatform: "data-interoperable-platform",
  surgerySkills: "surgery-skills",
  tabularDataSynthesizer: "tabular-data",
  ultraSoundImageSegmentation: "ultra-sound-image-segmentation",
  maskDetection: "mask-detection",
  mentalHealthPrevalence: "mental-health-prevalence",
  shapleyEMR: "shapley-emr",
  ocrCheckValidation: "ocr-check-validation",
  predictingAdClick: "predicting-ad-click",
  customerSegmentation: "customer-segmentation",
  predictingADR: "predictingadr",
  geneticVariantClassification: "genetic-variant-classification",
  classfyingRheumotoidArthritisPatients: "classfying-rheumotoid-arthritis-patients",
  predictingHeartDisease: "predicting-heart-disease",
  predictingType2Diabetes: "predicting-type2-diabetes",
  timePeriod2CompleteClinicalTrial: "time-period2complete-clinical-trial",
  geneVariations: "gene-variations",
  predictingEligibility: "predicting-eligibility",
  sideEffects: "side-effects",
  brainTumorSegmentation: "brain-tumor-segmentation",
  networkGraphAnalyzer: "network-graph-analyzer",
  selfService: "self-service",
  timelinessOfCare: "timeliness-of-care",
  shapleyImages: "shapley-images",
  multiAccuracy: "multi-accuracy",
  clinicalnotesPreprocessor: "clinical-notes-preprocessor",
  feasibilityAnalysis: "feasibility-analysis",
  mortalityEffect: "mortality-effect",
  strokePrediction: "stroke-prediction",
  predictingCerebralInfarction: "predicting-cerebral-infarction",
  textSummarizer: "text-summarizer",
  cpcaImages: "cpca-images",
  cpca: "cpca",
  drugReviewAnalyzer: "drug-review-analyzer",
  caloriePredictor: "calorie-predictor",
  lungCancerComorbidities: "lung-cancer-comorbidities",
  liverDiseasePrediction: "liver-disease-prediction",
  deepScap: "deep-scap",
  deepNuclie: "deep-nuclie",
  geneExpressionAnalysis: "gene-expression-analysis",
  predictBioMechanical: "predict-bio-mechanical",
  breastCancerClassification: "breast-cancer-classification",
  breastCancerProteomes: "breast-cancer-proteomes",
  medicalImagingVisualQnA: 'medical-imaging-Visual-QnA',
  cancerLectins: "cancer-lectins",
  predictOligomericState: "predict-oligomeric-state",
  frequentPharmacyVisitor: "frequent-pharmacy-visitor",
  behaviouralAnalysis: "behavioural-analysis",
  genomePhenotype: "genome-phenotype",
  miceProteinClassification: "miceProtein-classification",
  genomeFrequencyAnalysis: "genome-frequencyanalysis",
  geneSimilarity: "gene-similarity",
  gastrointestinalCancer: "gastrointestinal-cancer",
  cervicalCancerScreening: "cervical-cancerscreening",
  prostateCancerDetection: "prostate-cancerdetection",
  vnet: "vnet",
  colonPathology: "colon-pathology",
  geneticAlgorithm: "genetic-algorithm",
  retweetCountPredictor: "retweet-countPredictor",
  heartSoundsClassification: "heart-soundsclassification",
  medicalAppointmentNoShow: "medical-appointment-no-show",
  chestXRaysCovid19: "chest-xrays-covid19",
  faceEmotionRecognizer: "face-emotion-recognizer",
  cryoTherapyAnalysis: "cryo-therapy-analysis",
  melanomaClassification: "melanoma-classification",
  cervicalCancerRisk: "cervical-cancer-risk",
  eyesStatePrediction: "eyes-state-prediction",
  insuranceComplaintsResolution: "insurance-complaints-resolution",
  bern: "bern",
  usUninsured: "us-uninsured",
  lifeInsurance: "life-insurance",
  socialDistance: "social-distance",
  predictMortalityKorean: "predict-mortalityKorean",
  humanproteinImageclassification: 'humanprotein-image-classification',
  freightCostPrediction: "freight-cost-prediction",
  healthCareCostEstimation: "healthcare-costEstimation",
  askForAModel: "ask-for-a-model",
  activationMetastasis: "activation-metastasis",
  insuranceChurn: "insurance-churn",
  fraudClaimsDetection: "fraudClaims-detection",
  medicalExpensePrediction: "medicalExpense-prediction",
  retinaDamageClassification: "retina-damage-classification",
  populationHealthDashboard: "population-health-dashboard",
  rom: "rom",
  arrhythmiaClassification: "arrhythmia-classification",
  diabeticRetinopathy: "diabetic-retinopathy",
  imageColorization: "image-colorization",
  skinCancerAnalysis: "skinCancer-analysis",
  drugMOAPrediction: "drugMOA-prediction",
  bloodCellInception: "bloodcell-inception",
  suicideIdeation: "suicide-ideation",
  osteoarthritisSeverityPrediction: "osteoarthritis-severity-prediction",
  eEGClassification: "eeg-classification",
  eEGOnSensoryTask: "eegOn-sensoryTask",
  aclTear: "acl-tear",
  intracranialHemorrhage: "intracranial-hemorrhage",
  ibmMarketscan: "ibm-marketscan",
  sinGan: "singan",
  dcGAN: "dcgan",
  gan: "gan-lab",
  northAmericaSimulated: "north-america-simulated",
  portraitShadowRemoval: "portrait-shadow-semoval",
  handGuesterRecognition: "hand-guester-recognition",
  esrGAN: "esrGAN",
  araali: "araali",
  iotReceipesView: "iot-receipes-view",
  hardwareRecipes: 'hardwareRecipes',
  softwareRecipes: 'softwareRecipes ',
  IotTempaltes: "3dTempaltes",
  womensHealthWellBeing: "womens-health-well-being",
  pneumoniaDiagnosis: "pneumonia-diagnosis",
  insuranceClaimPrediction: "insurance-claim-prediction",
  dementiaPrediction: "dementia-prediction",
  breastCancerMammography: "breastCancer-mammography",
  pulmonaryEmbolism: "pulmonary-embolism",
  diagnosisPredictor: "diagnosis-predictor",
  healthInsuranceLeadPrediction: "healthInsurance-leadPrediction",
  uhgBreastCancerPrediction: "uhg-breast-cancer-prediction",
  heartAttackAnalysis: "heart-attack-analysis",
  fasterAi: 'faster-ai',
  sdoh: "sdoh",
  stressDetectionHRV: "stress-detection-hrv",
  footWoundImageSegmentation: "foot-wound-image-segmentation",
  surgicalSiteInfectionPrediction: "surgicalSite-infectionPrediction",
  federatedLearningMedicalData: "federatedLearning-medicalData",
  americanSignLanguageDetection: "americanSign-languageDetection",
  footWoundImageSegmentation: "foot-wound-image-segmentation",
  polypSegmentation: "polyp-segmentation",
  bowelpreparation: "bowel-preparation",
  fetalHealthClassification: "fetalHealth-classification",
  healthInsuranceCoverageRatePrediction: "healthInsurance-coverageRatePrediction",
  uHGStrokePrediction: "uHGStroke-prediction",
  adverseFoodEvents: "adverse-foodEvents",
  lengthStayHospital: "length-stay-hospital",
  whoSuicideStatistics: "who-suicide-statistics",
  legalDocParser: "legal-doc-parser",
  ocrCertificate: "ocr-certificate",
  providerNetworkAnalysis: "provider-network-analysis",
  sequenceGenerator: "sequence-generator",
  predictingParkinson: 'predicting-parkinson-disease',
  dopplegangerView: "digital-twins",
  cohortSense: 'cohort-sense',
  medicareLocator: "medicare-locator",
  hls: "hls-dashboard",
  covidDashboard: "covid-dashboard",
  chronicKidneyDisease: "chronic-kidneyDisease",
  patternAnalyzer: "pattern-analyzer",
  ace: "ace",
  genomicDataPreprocessor: "genomic-data-preprocessor",
  diseasePrognosis: "disease-prognosis",
  covid19Simulation: "covid19-simulation",
  neuronShapley: "neuron-shapley",
  clinicalEventsPatient: "clinical-events-patient",
  visionAidedGAN: "vision-aided-gan",
  ganlab: "Gan-lab",
  conVnet: "con-vnet",
  cnnExplainer: "cnn-explainer",
  activeLearner: "active-learner",
  anomagram: "anomagram",
  turbofanTycoon: "turbofan-tycoon",
  exBERT: "exbert",
  selfOrganizing: "self-organizing",
  textSummarization: "text-summarization",
  textGeneration: "med-info-guide",
  textgenerationNLP: "text-generation-nlp",
  questionGeneration:"question-generation",
  nameMatcher:"name-matcher",
  videoMetadataExtraction: "video-metadata-extraction",
  genomicMetadataIntegration: "genomic-metadata-integration",
  medicalReportGeneration: "medical-report-generation",
  cancerousCellDetection: "cancerous-Cell-Detection",
  differentialPrivacy: "differential-Privacy",
  anomalyDetector: "anomaly-detector",
  imageModel: "image-model",
  aqueousSolubility: "aqueous-solubility",
  ioTContinuous :"iot-continuous",
  providerDashboards: "provider-dashboards",
  multimodalSingleCellIntegration: "multimodal-singlecell-integration",
  proteinDeepLearning: "protein-Deep-Learning",
  toxPrediction:"tox-Prediction",
  knowledgeGraph: "knowledge-graph",
  encryptedTextData: "encrypted-text-data",
  periodontalChart: "periodontal-chart",
  dentalRadiographs: "dental-adiographs", 
  differentialPrivacyText: "differential-Privacy-text",
  annotatedCSV: "annotated-csv",
  responsibleAI: "responsible-ai",
  priorAuthorization:"prior-authorization",
  docSimilarityReport: "doc-similarityReport",
  imageSim:"image-sim",
  nlpToolkit: "nlp-Toolkit",
  insuranceFraudDetection:"insurance-fraud-detection",
  medCode:"med-code",
  responsibleAIModelExplainability:"responsible-ai-model-explainability",
  medTech:"med-tech",
  contextualSearch:"contextual-search",
  docSearch:"knowledge-search-platform",
  docSearchBot:"knowledge-search-chatbot",
  auditAssistReportPlatform:"audit-assist",
  siteMonitoring:"site-monitoring",
  sdlcRequirement:"sdlc-requirement",
  priorAuth:"prior-auth",

  // epidemiology
  predictCasesCountryWise: "predictCasesCountryWise",
  predictConfimedCases: "predictConfimedCases",
  PredictMortalityCases: "PredictMortalityCases",
  predictRecoveryCases: "predictRecoveryCases",

  // external solution/link ids
  malariaDetection: "malariaDetection",
  metastatiClassification: 'metastatiClassification',
  medicalDeviceSimulator: 'medical-device-simulator',
  cancerIncidentsUSCountry: 'cancer-incidents-us-country',
  cancerIncidentsUSState: 'cancer-incidents-us-state',
  nomograms: 'nomograms',
  replicativeImmortility: 'replicativeImmortility',
  genomeInstability: 'genomeInstability',
  evasionGrowthSuppressorSignals: 'evasionGrowthSuppressorSignals',
  resistanceCellDeath: 'resistanceCellDeath',
  sustainedProliferation: 'sustainedProliferation',
  alteredMetabolism: 'alteredMetabolism',
  avoidingImmuneDestruction: 'avoidingImmuneDestruction',
  tumorPromotingInflammation: 'tumorPromotingInflammation',
  AngiogenesisBloodVessel: 'AngiogenesisBloodVessel',
  covid19onCancerPatients: 'covid19-on-cancer-patients',
  colonCancerGeneAnalysis: 'colonCancerGeneAnalysis',
  globalDashboard: 'global-dashboard',
  hospitalRecommendation: 'hospital-recommendation',
  genRocket: 'genRocket',
  synthea: 'synthea',
  dataBrics: 'dataBrics',
  roboFlow: 'roboFlow',
  iQuartic: 'iQuartic',
  onyxHealth: 'onyxHealth',
  teleHealth: "teleHealth",
  whizai: "whizai",
  patientStratification: "patientStratification",
  interSystems: 'intersystems',
  healthBot: "healthBot",
  ePic: 'epic',
  talendForecasting: 'talendForecasting',
  mlopsDataiku: "mlopsDataiku",
  healthMonitor: "healthMonitor",
  dataChef: "data-chef",
  apiMonitor: "apiMonitor",
  populationHealthLake: "populationHealthLake",
  raindrop: "raindrop",
  appealsGrievances: "appealsGrievances",
  careCohortMgmt: "careCohortMgmt",
  pegaSmartClaims: "pegaSmartClaims",
  salesTraining: "salesTraining",
  mareanaFaq: "mareanaFaq",
  onePager: "onePager",
  plmLifeCycle: "plmLifeCycle",
  plmInsights: "plmInsights",
  imagedeIdentification: "imagede-identification",
  sentimentAnalysis: "sentiment-analysis",
  sARSCoVAssociation: "sARSCoVAssociation",
  covid19Genetics: "covid19Genetics",
  breastAssessmentTool: "breastAssessmentTool",
  proteinKinases: "proteinKinases",
  patientSimilarities: "patientSimilarities",
  cervicalCancerClassification: "cervicalCancerClassification",
  echoNet: "echoNet",
  colbertAnalysis: "colbert-analysis",
  distributionalShapley: "distributional-shapley",
  textEmotionClassification: "patient-feedback-analysis",
  zeroShotClassification: "manage-tag-clinical-notes",
  nlpTextGenerator:"nlp-text-generation",

  // epidemology
  casesDayWise: "casesDayWise",
  recoveredCasesPerDay: "recoveredCasesPerDay",
  googleBaseline: "googleBaseline",
  vaccinationProgress: "vaccinationProgress",
  // iot receipes
  hwReceipes1: "hwReceipes1",
  hwReceipes2: "hwReceipes2",
  hwReceipes3: "hwReceipes3",
  hwReceipes4: "hwReceipes4",
  hwReceipes5: "hwReceipes5",
  swReceipes1: "swReceipes1",
  swReceipes2: "swReceipes2",
  swReceipes3: "swReceipes3",
  swReceipes4: "swReceipes4",
  swReceipes5: "swReceipes5",
  arvr1: "arvr1",
  arvr2: "arvr2",
  arvr3: "arvr3",
  tempaltes3d1: "tempaltes3d1",
  otherTuts1: "otherTuts1",
  otherTuts2: "otherTuts2",

  // collabortaors
  devRPM: 'dev-rpm',
  devShapleyGanIntro: 'devShapleyGanIntro',
  devPrognosis: 'devPrognosis',
  devInsClaimPrediction: 'devInsClaimPrediction',
  devTumorMicroEnv: 'devTumorMicroEnv',
  devReadmission: 'devReadmission',
  devGenomics: 'devGenomics',
  devAIMLBlog: 'devAIMLBlog',
  devVideoMetadata: 'devVideoMetadata'

};
