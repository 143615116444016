import React, { memo, useState } from "react";
import styled from "styled-components";
import {
  Grid,
  Typography,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  Icon,
} from "@material-ui/core";
import Grow from "@material-ui/core/Grow";
import DataSourceIcon from "@material-ui/icons/GetApp";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

// import local components
import CommonService from "../../../utils/services/CommonService";

import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import Spinner from "../../common/loading/Spinner";
import "react-html5-camera-photo/build/css/index.css";
import Camera from "react-html5-camera-photo";
// import "!css-loader?modules!react-html5-camera-photo/build/css/index.css";

// images
import maskDetection_sample1 from "../../../assets/images/app/maskDetection_sample1.webp";

const MaskDetection = () => {
  const [showSpinner, setSpinner] = useState(false);
  const [startCapture, setStartCapture] = useState(false);
  const [maskResult, setMaskResult] = useState("");
  const [imageResult, setImageResult] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [uploadedFile, setUploadedFile] = useState("");
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService("z1dApps", "maskDetection");

  const { uploadFile } = CommonService("z1dApps", "maskDetection");

  const openCamera = () => {
    setStartCapture(false);
    setImageResult("");
    setMaskResult("");
    openCommonDialog();
  };
  const resetCapture = () => {
    setStartCapture(false);
    setUploadedFile("");
    setMaskResult("");
    setImageResult("");
  };

  const onChangeFields = (e) => {
    const uploadedFile = e.target.files[0];
    const reader = new FileReader();
    const url = reader.readAsDataURL(uploadedFile);

    reader.onloadend = function (e) {
      setUploadedFile([reader.result]);
      setStartCapture(true);
    }.bind();
    getUploadResult(uploadedFile);
  };

  const handleTakePhoto = async (dataUri) => {
    console.log(dataUri);
    const file = await fetch(dataUri)
      .then((r) => r.blob())
      .then(
        (blobFile) => new File(
          [blobFile],
          "image.png",
          { type: "image/png" },
          { crossOrigin: "anonymous" },
        ),
      );
    setUploadedFile(dataUri);
    getUploadResult(file);
    closeCommonDialog();
  };

  const getUploadResult = (file) => {
    setSpinner(true);
    setMaskResult("");
    uploadFile("uploadResult", file)
      .then((res) => {
        setSpinner(false);
        setMaskResult(res[0]);
        setImageResult(res[1]);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const openCommonDialog = () => {
    setOpenDialog(true);
  };

  const closeCommonDialog = () => {
    setOpenDialog(false);
    if (!uploadedFile) {
      setStartCapture(false);
    }
  };

  const getsampleResult = () => {
    setSpinner(true);
    setMaskResult("");
    getQueryResult("sampleResult")
      .then((res) => {
        setSpinner(false);
        setMaskResult(res[0]);
        setImageResult(res[1]);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            vLife Face mask detector can help the safety of stakeholders in
            times of Epidemics like Covid-19.
            <br />
            The solution is created using OpenCV, Keras/TensorFlow, and Deep
            Learning. It is trained using a two-class model of people wearing
            masks and people not wearing masks. MobileNetV2 is tuned on the
            mask/no mask dataset and obtained a classifier. The solution takes
            images for input and can detect whether a person is wearing mark or
            not.
          </p>
          <p> The tool performs the following actions</p>
          <ul>
            <li>Detects faces in images/video</li>
            <li>Extracts each individual face</li>
            <li>Applies the face mask classifier</li>
          </ul>
          <p>
            <strong> Application</strong>
          </p>
          <p>The Application shall be used for</p>
          <ul>
            <li>Commercial purpose like office, school, college entrance.</li>
            <li>
              Residential purpose - For the CCTV security camera of a
              house/Gated community.
            </li>
          </ul>
          <p>
            There the solution can be implemented to instantly identify people
            entering the premises are wearing masks or not.
          </p>
          <LinkContainer>
            <Grid container spacing={2}>

              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/Face_mask_detection/Face_mask_detection.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://github.com/prajnasb/observations/tree/master/experiements/data"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <br />
        <DemoContainer>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} sm={6}>
              <Paper elevation={3}>
                <MaskPaper>
                  <Image src={maskDetection_sample1} />
                  <br />
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={getsampleResult}
                  >
                    Sample
                  </StyledButton>
                </MaskPaper>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper elevation={3}>
                <MaskPaper>
                  {startCapture ? (
                    <>
                      <Image src={uploadedFile} alt="" />
                      <br />
                      <StyledButton
                        variant="contained"
                        color="primary"
                        onClick={resetCapture}
                      >
                        Try Different Image
                      </StyledButton>
                    </>
                  ) : (
                    <section>
                      <Typography variant="h6">
                        <Icon icon={faInfoCircle} />
                        {' '}
                        Capture Image
                      </Typography>
                      <StyledCaptureBTN variant="contained" color="primary">
                        <CameraAltIcon onClick={openCamera} fontSize="large" />
                      </StyledCaptureBTN>
                      <h3>OR</h3>
                      <Typography variant="h6">
                        <FontAwesomeIcon icon={faInfoCircle} />
                        {' '}
                        Upload Image
                      </Typography>
                      <HiddenInput
                        name="uploadedFile"
                        onChange={onChangeFields}
                        id="icon-button-file"
                        type="file"
                      />
                      <label
                        variant="contained"
                        color="primary"
                        htmlFor="icon-button-file"
                      >
                        <UploadIcon fontSize="large" />
                      </label>
                    </section>
                  )}
                </MaskPaper>
              </Paper>
            </Grid>
          </Grid>

          {showSpinner && <Spinner text="Loading..." />}
          {maskResult && (
            <Grid container XS={12}>
              <Grid item xs={12} md={12}>
                <Grow in={maskResult} timeout={1500}>
                  <div>
                    <br />
                    <section>
                      <Result>{maskResult}</Result>
                      <br />
                      {' '}
                      <br />
                      <Image
                        src={imageResult}
                        alt=""
                      />
                    </section>
                  </div>
                </Grow>
              </Grid>
            </Grid>
          )}
          <section>
            {/* dialog for all insights and solutions */}
            <Dialog
              onClose={closeCommonDialog}
              aria-labelledby="diabetes-dialog-title"
              open={openDialog}
              maxWidth="lg"
            >
              <DialogTitle
                id="diabetes-dialog-title"
                onClose={closeCommonDialog}
              >
                Capture Image
                <IconButton aria-label="Close" onClick={closeCommonDialog}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent dividers>
                <Typography gutterBottom>
                  <section>
                    <center>
                      <Camera onTakePhoto={handleTakePhoto} />
                    </center>
                  </section>
                </Typography>
              </DialogContent>
            </Dialog>
          </section>
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(MaskDetection);
const MaskPaper = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  @media only screen and (max-width: 450px) {
    padding-top: 0;
    height: auto;
    max-height: 300px;
    padding-bottom: 10px;
    .MuiTypography-root {
      font-size: 1rem;
    }
  }
`;
const Image = styled.img`
  width: 100%;
  max-width: 300px;
  max-height: 230px;
`;
const UploadIcon = styled(CloudUploadIcon)`
  cursor: pointer;
  margin-top: 20px;
`;
const HiddenInput = styled.input`
  display: none;
`;
const StyledCaptureBTN = styled(StyledButton)`
  margin-top: 20px;
`;
