import React, { memo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  MenuItem,
  Typography,
  Card,
  CardActionArea,
  CardContent,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components
import Collapse from "../../common/Collapse";

import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  List,
  ListItem,
  HighchartContainer,
} from "../../../styles/common";
// user id
import getUserData, { getUploadToken } from '../../../utils';

const useStyles = makeStyles({
  root: {
    height: "100%",
    textAlign: "left",
    backgroundColor: "#0080FF",
    color: "white",
  },
  link: {
    textDecoration: "none",
    color: "white",
  },
  textCenter: {
    textAlign: "center",
  },
});

function StyledCard({ name, cardAsLink }) {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardActionArea
        className={classes.link}
        component={cardAsLink && "a"}
        href={cardAsLink}
        target="_blank"
      >
        <CardContent>
          {name && (
            <Typography
              className={classes.textCenter}
              gutterBottom
              variant="h6"
              component="h5"
            >
              {name}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

const AdverseFoodEvents = () => {
  const initialData = {
    product_type: '',
    age: '',
    time_diff: '',
    diarrhoea: 0,
    vomiting: 0,
    nausea: 0,
    abdominal_pain: 0,
    malaise: 0,
    ovarian_cancer: 0,
    choking: 0,
    headache: 0,
    hypersensitivity: 0,
    abdominal_pain_upper: 0,
    gender: '',
    product_role: '',
    disclose_age: '',
    disclose_date: '',
  };

  const product_type = [
    {
      value: 40,
      label: 'Baby Food Prod',
    },
    {
      value: 7,
      label: 'Snack Food Item',
    },
    {
      value: 29,
      label: 'Soft Drink/Water',
    },
    {
      value: 9,
      label: 'Milk/Butter/Dried Milk Prod',
    },
    {
      value: 54,
      label: 'Vit/Min/Prot/Unconv Diet(Human/Animal)',
    },
    {
      value: 12,
      label: 'Cheese/Cheese Prod',
    },
    {
      value: 4,
      label: 'Macaroni/Noodle Prod',
    },
    {
      value: 21,
      label: 'Fruit/Fruit Prod',
    },
    {
      value: 35,
      label: 'Gelatin/Rennet/Pudding Mix/Pie Filling',
    },
    {
      value: 5,
      label: 'Cereal Prep/Breakfast Food',
    },
    {
      value: 41,
      label: 'Dietary Conv Food/Meal Replacements',
    },
    {
      value: 34,
      label: 'Choc/Cocoa Prod',
    },
    {
      value: 13,
      label: 'Ice Cream Prod',
    },
    {
      value: 24,
      label: 'Vegetables/Vegetable Products',
    },
    {
      value: 3,
      label: 'Bakery Prod/Dough/Mix/Icing',
    },
    {
      value: 23,
      label: 'Nuts/Edible Seed',
    },
    {
      value: 33,
      label: 'Candy W/O Choc/Special/Chew Gum',
    },
    {
      value: 27,
      label: 'Dressing/Condiment',
    },
    {
      value: 20,
      label: 'Fruit/Fruit Prod',
    },
    {
      value: 26,
      label: 'Vegetable Oils',
    },
    {
      value: 16,
      label: 'Fishery/Seafood Prod',
    },
    {
      value: 25,
      label: 'Vegetables/Vegetable Products',
    },
    {
      value: 37,
      label: 'Mult Food Dinner/Grav/Sauce/Special',
    },
    {
      value: 28,
      label: 'Spices, Flavors And Salts',
    },
    {
      value: 17,
      label: 'Meat, Meat Products and Poultry',
    },
    {
      value: 2,
      label: 'Whole Grain/Milled Grain Prod/Starch',
    },
    {
      value: 39,
      label: 'Prep Salad Prod',
    },
    {
      value: 31,
      label: 'Coffee/Tea',
    },
    {
      value: 53,
      label: 'Cosmetics',
    },
    {
      value: 45,
      label: 'Food Additives (Human Use)',
    },
    {
      value: 46,
      label: 'Food Additives (Human Use)',
    },
    {
      value: 38,
      label: 'Soup',
    },
    {
      value: 18,
      label: 'Vegetable Protein Prod',
    },
    {
      value: 22,
      label: 'Fruit/Fruit Prod',
    },
    {
      value: 36,
      label: 'Food Sweeteners (Nutritive)',
    },
    {
      value: 30,
      label: 'Beverage Bases/Conc/Nectar',
    },
    {
      value: 32,
      label: 'Alcoholic Beverage',
    },
    {
      value: 52,
      label: 'Miscellaneous Food Related Items',
    },
    {
      value: 14,
      label: 'Filled Milk/Imit Milk Prod',
    },
    {
      value: 15,
      label: 'Egg/Egg Prod',
    },
    {
      value: 51,
      label: 'Food Service/Conveyance',
    },
    {
      value: 50,
      label: 'Color Additiv Food/Drug/Cosmetic',
    },

  ];

  const gender = [
    { name: "Female", value: 1 },
    { name: "Male", value: 0 },
  ];

  const boolean = [
    { name: "No", value: 1 },
    { name: "Yes", value: 0 },
  ];

  const product_role = [
    { name: "Suspect", value: 1 },
    { name: "Concomitant", value: 0 },
  ];

  const [parameters, setParameters] = useState(initialData);
  const [result, SetResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService("patientEngagement", "adverseFood");
  const Token = getUploadToken()

  const data = [
    ["Outcomes based on different Event Start and Report Year", "https://public.tableau.com/app/profile/vlife.virtusa/viz/OutcomesondifferentEventStartandReportYear/Dashboard5"],
    ["Health Outcome based on Consumers symptoms", "https://public.tableau.com/app/profile/vlife.virtusa/viz/HealthOutcomebasedonConsumerssymptoms_16333487401680/Dashboard2"],
    ["Health Outcomes based on different industry types and Gender", "https://public.tableau.com/app/profile/vlife.virtusa/viz/HealthOutcomesbasedondifferentindustrytypesandGender_16332845523310/Dashboard5"],
    ["Time taken to report the event", "https://public.tableau.com/app/profile/vlife.virtusa/viz/TimeTakentoreporttheAdverseEvent/Dashboard10"],
    ["Outcomes based on Gender and Age Group", "https://public.tableau.com/app/profile/vlife.virtusa/viz/OutcomesbasedonGenderandAgeGroup/Dashboard10"],
  ];

  const showSample1 = () => {
    const NewDataSet = {
      product_type: 53,
      age: 46,
      time_diff: 37,
      diarrhoea: 0,
      vomiting: 0,
      nausea: 0,
      abdominal_pain: 0,
      malaise: 0,
      ovarian_cancer: 1,
      choking: 0,
      headache: 0,
      hypersensitivity: 0,
      abdominal_pain_upper: 0,
      gender: 1,
      product_role: 1,
      disclose_age: 1,
      disclose_date: 1,
    };

    setParameters(NewDataSet);
  };

  const showSample2 = () => {
    const NewDataSet = {
      product_type: 25,
      age: 81,
      time_diff: 46,
      diarrhoea: 1,
      vomiting: 1,
      nausea: 0,
      abdominal_pain: 1,
      malaise: 0,
      ovarian_cancer: 0,
      choking: 0,
      headache: 0,
      hypersensitivity: 0,
      abdominal_pain_upper: 0,
      gender: 1,
      product_role: 1,
      disclose_age: 0,
      disclose_date: 0,
    };

    setParameters(NewDataSet);
  };

  const getResult = () => {
    const {
      product_type, age, time_diff, diarrhoea, vomiting, nausea, abdominal_pain, malaise, ovarian_cancer, choking, headache, hypersensitivity, abdominal_pain_upper, gender, product_role, disclose_age, disclose_date,
    } = parameters;

    const params = `${product_type},${age},${time_diff},${diarrhoea},${vomiting},${nausea},${abdominal_pain},${malaise},${ovarian_cancer},${choking},${headache},${hypersensitivity},${abdominal_pain_upper},${gender},${product_role},${disclose_age},${disclose_date},${Token.token}`;

    let areValuesEmpty = false;
    Object.keys(parameters).forEach((key) => {
      if (parameters[key] === "") {
        areValuesEmpty = true;
      }
    });
    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);
      SetResultData("");

      getQueryResult("withParams", params)
        .then((res) => {
          console.log(res);
          setSpinner(false);
          SetResultData(res);
        })
        .catch((err) => {
          console.log(err);
          setSpinner(false);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const handleInputs = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setParameters({
      ...parameters,
      [name]: value,
    });
  };

  const handleCheckBox = (e) => {
    const { name } = e.target;
    const value = e.target.checked;
    const valueName = `${e.target.name}Value`;
    const val = value ? 1 : 0;

    console.log(name, value);
    setParameters({
      ...parameters,
      [name]: val,
      [valueName]: val,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>Intake of certain foods lead to conditions such as food allergy, intolerance and some even causes serious health effects. This is known as adverse food event. Even in today’s times, the dietary awareness of foods that cause adverse reactions still remains low. Therefore, awareness of diet that partially or completely excludes the intake of certain foods associated with severe reactions should be widespread among the consumers. </p>
          <p>An adverse event is submitted to the FDA to report adverse health effects and product complaints about foods, dietary supplements, and cosmetics. The FDA report covers all domestic and imported food except meat, poultry, and frozen, dried, and liquid eggs, and the labelling of alcoholic beverages (above 7% alcohol). It even includes dietary supplements, bottled water, food additives, infant formulas, and other food products. The FDA also regulates all cosmetic products and ingredients in the United States. </p>
          <p>FDA receives some adverse event reports directly from </p>
          <List>
            <ListItem>healthcare professionals (such as physicians, pharmacists, nurses and others)</ListItem>
            <ListItem>consumers (such as patients, family members, and others)</ListItem>
          </List>
          <p>This application can be used to help consumers/healthcare units in identifying the seriousness of the event in advance. And also, one can avoid exposure to these adverse events by adhering to a strict diet that eliminates such type of foods, dietary supplements, or cosmetics. Therefore, awareness of diet that partially or completely excludes the intake of certain foods associated with severe reactions should be widespread among the consumers.</p>
          <p><b>About Data:</b></p>
          <p>The data is from the U.S. Food and Drug Administration (FDA) CAERS report, consisting of factors such as age, gender, product role in the event, food industry type, consumer’s symptoms, etc. It contains approximately 90k records from 2004 to mid-2017 which were then classified into 2 classes:</p>
          <List>
            <ListItem>Serious Outcome</ListItem>
            <ListItem>Non-Serious Outcome</ListItem>
          </List>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
          <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.kaggle.com/datasets/fda/adverse-food-events"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Data source
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/AdverseFoodEvent/AdverseFoodEvent.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.kaggle.com/fda/adverse-food-events"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Dataset
                </a>
              </StyledButton>
            </Grid>
          </Grid>
          <Grid container spacing={3} direction="row">
            {data.map((data) => (
              <Grid item xs={12} sm={6} md={4}>
                <StyledCard
                  name={data[0]}
                  cardAsLink={data[1]}
                />
              </Grid>
            ))}
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container direction="row" justify="left" spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="product_type"
                label="Product Type"
                value={parameters.product_type}
                onChange={handleInputs}
              >
                {product_type.map((data) => (
                  <MenuItem value={data.value}>{data.label}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="Age (in years)"
                name="age"
                fullWidth
                onChange={handleInputs}
                value={parameters.age}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="Duration b/w Event Start and Event Report Date"
                name="time_diff"
                fullWidth
                onChange={handleInputs}
                value={parameters.time_diff}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="gender"
                label="Gender"
                value={parameters.gender}
                onChange={handleInputs}
              >
                {gender.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="product_role"
                label="Product Role"
                value={parameters.product_role}
                onChange={handleInputs}
              >
                {product_role.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="disclose_age"
                label="Disclose Age in the report"
                value={parameters.disclose_age}
                onChange={handleInputs}
              >
                {boolean.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="disclose_date"
                label="Disclose Date of Event in the report"
                value={parameters.disclose_date}
                onChange={handleInputs}
              >
                {boolean.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Typography>
                {' '}
                <h3>Symptoms:</h3>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <checkBoxContainer>
                <FormControlLabel
                  control={<Checkbox checked={!!parameters.diarrhoea} name="diarrhoea" onChange={handleCheckBox} />}
                  label="Diarrhoea"
                />
                <FormControlLabel
                  control={<Checkbox checked={!!parameters.vomiting} name="vomiting" onChange={handleCheckBox} />}
                  label="Vomiting"
                />
                <FormControlLabel
                  control={<Checkbox checked={!!parameters.nausea} name="nausea" onChange={handleCheckBox} />}
                  label="Nausea"
                />
                <FormControlLabel
                  control={<Checkbox checked={!!parameters.abdominal_pain} name="abdominal_pain" onChange={handleCheckBox} />}
                  label="Abdominal Pain"
                />
                <FormControlLabel
                  control={<Checkbox checked={!!parameters.malaise} name="malaise" onChange={handleCheckBox} />}
                  label="Malaise"
                />
                <FormControlLabel
                  control={<Checkbox checked={!!parameters.ovarian_cancer} name="ovarian_cancer" onChange={handleCheckBox} />}
                  label="Ovarian Cancer"
                />
                <FormControlLabel
                  control={<Checkbox checked={!!parameters.choking} name="choking" onChange={handleCheckBox} />}
                  label="Choking"
                />
                <FormControlLabel
                  control={<Checkbox checked={!!parameters.headache} name="headache" onChange={handleCheckBox} />}
                  label="Headache"
                />
                <FormControlLabel
                  control={<Checkbox checked={!!parameters.hypersensitivity} name="hypersensitivity" onChange={handleCheckBox} />}
                  label="Hypersensitivity"
                />
                <FormControlLabel
                  control={<Checkbox checked={!!parameters.abdominal_pain_upper} name="abdominal_pain_upper" onChange={handleCheckBox} />}
                  label="Abdominal Pain Upper"
                />
              </checkBoxContainer>
            </Grid>
            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={showSample1}
              >
                Sample Data1
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={showSample2}
              >
                Sample Data2
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={getResult}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
          <Result>
            <strong>Result:</strong>
            {`${result}`}
          </Result>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(AdverseFoodEvents);
