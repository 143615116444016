import React, { memo, useState } from "react";
import styled from "styled-components";
import {
  Grid,
  Paper,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

// import local components
import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";
import SolutionContainer from "../../common/SolutionContainerWrapper";

// images
import ImageEnhancementESRGAN_sol1 from "../../../assets/images/app/esrGan_sol1.webp";
import ImageEnhancementESRGAN_sample1 from "../../../assets/images/app/esrGan_sample1.webp";
import ImageEnhancementESRGAN_sample2 from "../../../assets/images/app/esrGan_sample2.webp";
import ImageEnhancementESRGAN_output1 from "../../../assets/images/app/esrGan_output1.webp";
import ImageEnhancementESRGAN_output2 from "../../../assets/images/app/esrGan_output2.webp";
import PresignedS3Link from "../../common/PresignedS3Link";

const ImageEnhancementESRGAN = () => {
  const [result, setResult] = useState("");
  const [notification, setNotification] = useState("");

  const getsample1 = () => {
    setResult({
      text: "Please find below the Image Enhanced using ESRGAN and the metadata comparison",
      image: ImageEnhancementESRGAN_output1,
      inputsize: "3.9kib",
      outputsize: "111kib",
      inputwidth: "223",
      outputwidth: "780",
      inputheight: "226",
      outputheight: "791",
      inputbits: "8",
      outputbits: "8",
      inputcolor: "3",
      outputcolor: "3",
      inputimagesize: "223x226",
      outputimagesize: "780x791",
      inputpixel: "0.050",
      outputpixel: "0.671",
    });
  };
  const getsample2 = () => {
    setResult({
      text: "Please find below the Image Enhanced using ESRGAN and the metadata comparison",
      image: ImageEnhancementESRGAN_output2,
      inputsize: "167kib",
      outputsize: "1583kib",
      inputwidth: "800",
      outputwidth: "2800",
      inputheight: "800",
      outputheight: "2800",
      inputbits: "8",
      outputbits: "8",
      inputcolor: "3",
      outputcolor: "3",
      inputimagesize: "800x800",
      outputimagesize: "2800x2800",
      inputpixel: "0.64",
      outputpixel: "7.8",
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>ESRGAN is the enhanced version of the SRGAN. Authors of the ESRGAN tried to enhance the SRGAN by modifying the model architecture and loss functions. </p>
          <p><strong>GAN</strong></p>
          <p>Before diving into the ESRGAN first let’s get a high-level understanding of the GAN. GANs are capable of generating fake data that looks realistic. Some of the GAN applications are to enhance the quality of the image. The high-level architecture of the GAN contains two main networks namely the generator network and the discriminator network. The generator network tries to generate the fake data and the discriminator network tries to distinguish between real and fake data, hence helping the generator to generate more realistic data. </p>
          <p><strong>ESRGAN</strong></p>
          <p>The Super-Resolution Generative Adversarial Network (SRGAN) is a seminal work that is capable of generating realistic textures during single image super-resolution. However, the hallucinated details are often accompanied with unpleasant artifacts. To further enhance the visual quality, three key components of SRGAN has been thoroughly studied - network architecture, adversarial loss and perceptual loss, and improved each of them to derive an Enhanced SRGAN (ESRGAN). The main architecture of the ESRGAN is the same as the SRGAN with some modifications. ESRGAN has Residual in Residual Dense Block (RRDB) which combines multi-level residual network and dense connection without Batch Normalization. </p>
          <p><strong>Architecture & Method of ESRGAN</strong></p>
          <p>The model uses Residual-in-Residual block as a basic convolution block instead of a basic residual network or simple convolution trunk to provide a better flow gradient at the microscopic level. In addition to that, the model lacks a batch normalization layer in the generator to prevent smoothing out the artifacts in the images. This allows the ESRGAN to produce images with a higher approximation of the sharp edges of the image artifacts.</p>
          <center>
            <Grid item xs={10} sm={8} md={8}>
              <img width="100%" src={ImageEnhancementESRGAN_sol1} alt="" />
            </Grid>
          </center>
          <p>The ESRGAN uses a Relativistic discriminator to better approximate the probability of an image being real or fake; thus, the intern produces better results. The generator uses a linear combination of Perceptual difference between real and fake images using a pre-trained VGG19 network, Pixel wise absolute difference between real and fake images, and Relativistic average loss between real and fake images function during adversarial training.</p>
          <LinkContainer>
            <Grid container spacing={2}>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <PresignedS3Link
                    href="ESRGAN/ESRGAN.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Notebook
                  </PresignedS3Link>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container spacing={2} direction="row" justify="center" alignItems="center">
            <Grid item xs={10} sm={6} md={4}>
              <Paper elevation={3}>
                <ImagePaper>
                  <Image src={ImageEnhancementESRGAN_sample1} />
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={getsample1}
                  >
                    Sample1
                  </StyledButton>
                </ImagePaper>
              </Paper>
            </Grid>
            <Grid item xs={10} sm={6} md={4}>
              <Paper elevation={3}>
                <ImagePaper>
                  <Image src={ImageEnhancementESRGAN_sample2} />
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={getsample2}
                  >
                    Sample2
                  </StyledButton>
                </ImagePaper>
              </Paper>
            </Grid>
          </Grid>
          {result && (
            <Grid container XS={12}>
              <Grid item xs={12} md={12}>
                <ResultDiv>
                  <Result>
                    <strong>Result : </strong>
                    <h4>{result.text}</h4>
                  </Result>
                </ResultDiv>
                <Grid container XS={12} spacing={2} justify="center" alignItems="center">
                  <Grid item xs={10} sm={8} md={5}>
                    <ResultImage src={result.image} alt="" />
                  </Grid>
                  <Grid item xs={12} sm={8} md={5}>
                    <Papers>
                      <TableDiv>
                        <TableHead>
                          <TableRow>
                            <StyledTableHead align="center"><strong>Parameter</strong></StyledTableHead>
                            <StyledTableHead align="center"><strong>Input Image</strong></StyledTableHead>
                            <StyledTableHead align="center"><strong>Output Image</strong></StyledTableHead>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>File size</TableCell>
                            <TableCell>
                              {result.inputsize}
                              {' '}
                            </TableCell>
                            <TableCell>
                              {result.outputsize}
                              {' '}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>File Type</TableCell>
                            <TableCell>Jpeg</TableCell>
                            <TableCell>Jpeg</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Image width</TableCell>
                            <TableCell>
                              {result.inputwidth}
                              {' '}
                            </TableCell>
                            <TableCell>
                              {result.outputwidth}
                              {' '}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Image Height</TableCell>
                            <TableCell>
                              {result.inputheight}
                              {' '}
                            </TableCell>
                            <TableCell>
                              {result.outputheight}
                              {' '}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Bits per sample</TableCell>
                            <TableCell>
                              {result.inputbits}
                              {' '}
                            </TableCell>
                            <TableCell>
                              {result.outputbits}
                              {' '}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Color Component</TableCell>
                            <TableCell>
                              {result.inputcolor}
                              {' '}
                            </TableCell>
                            <TableCell>
                              {result.outputcolor}
                              {' '}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Image Size</TableCell>
                            <TableCell>
                              {result.inputimagesize}
                              {' '}
                            </TableCell>
                            <TableCell>
                              {result.outputimagesize}
                              {' '}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Megapixels</TableCell>
                            <TableCell>
                              {result.inputpixel}
                              {' '}
                            </TableCell>
                            <TableCell>
                              {result.outputpixel}
                              {' '}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </TableDiv>
                    </Papers>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(ImageEnhancementESRGAN);

const ImagePaper = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  @media only screen and (max-width: 450px) {
    padding-top: 0;
    height: auto;
    max-height: 300px;
    padding-bottom: 10px;
    .MuiTypography-root {
      font-size: 1rem;
    }
  }
`;

const ResultImage = styled.img`
width: 320px;
height: 380px;
@media only screen and (max-width: 900px) {
  width: auto;
  max-width: 240px;
}  @media only screen and (max-width: 450px) {
  width: auto;
  max-width: 200px;
  height: auto;
  max-height: 200px;
}
`;
const Image = styled.img`
  width: 250px;
  height: 200px;
  padding: 3% 3% 3% 3%;
  @media only screen and (max-width: 900px) {
    width: auto;
    max-width: 240px;
  }  @media only screen and (max-width: 450px) {
    width: auto;
    max-width: 200px;
  }
`;
const ResultDiv = styled.div`
 margin-top: 5%;
`;
const StyledTableHead = styled(TableCell)`
  background-color: #3c40af;
  color: white;
  font-weight: bold;
`;

const TableDiv = styled(Table)`
  border: 1px solid grey;
`;

const Papers = styled.div`
  overflow: auto;
  }
     `;
