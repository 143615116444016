import React, { memo, useState } from "react";
import styled from "styled-components";
import { Grid, TextField, MenuItem } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import TableCell from "@material-ui/core/TableCell";
import Highcharts from "highcharts";

// local components
import Collapse from "../../common/Collapse";

import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  HighchartContainer,
} from "../../../styles/common";
import getUserData, { getUploadToken } from '../../../utils';

const PredictMortalityKorean = () => {
  const initialData = {
    gender: "",
    age: "",
    city: "",
    province: '',
    infection: '',
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [result, SetResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService("z1dApps", "KoreanMortality");
  const Token = getUploadToken()
  const gender = [
    { name: "Female", value: "female" },
    { name: "Male", value: "male" },
  ];

  const city = ['Nam-gu', 'Taebaek-si', 'Gyeongju-si', 'Cheongdo-gun', 'Gangseo-gu', 'Jungnang-gu', 'Jongno-gu', 'Mapo-gu', 'Seongbuk-gu', 'etc', 'Songpa-gu', 'Guro-gu', 'Seocho-gu', 'Gwanak-gu', 'Gangdong-gu', 'Gangnam-gu', 'Eunpyeong-gu', 'Yangcheon-gu', 'Seodaemun-gu', 'Nowon-gu', 'Dongdaemun-gu', 'Yeongdeungpo-gu', 'Haeundae-gu', 'Dongnae-gu', 'Seo-gu', 'Saha-gu', 'Suyeong-gu', 'Geumjeong-gu', 'Buk-gu', 'Yeonje-gu', 'Sasang-gu', 'Busanjin-gu', 'Michuhol-gu', 'Ulju-gun', 'Jung-gu', 'Dong-gu', 'Goyang-si', 'Pyeongtaek-si', 'Suwon-si', 'Guri-si', 'Siheung-si', 'Bucheon-si', 'Icheon-si', 'Anyang-si', 'Gwangmyeong-si', 'Cheongju-si', 'Cheonan-si', 'Gunsan-si', 'Gimje-si', 'Jeonju-si', 'Yeosu-si', 'Gyeongsan-si', 'Chilgok-gun', 'Jeju-do'];

  const infection = ['Shincheonji Church', 'contact with patient', 'Cheongdo Daenam Hospital', 'overseas inflow', 'etc', "Eunpyeong St. Mary's Hospital", 'Seongdong-gu APT', 'Onchun Church', 'Suyeong-gu Kindergarten', 'gym facility in Cheonan'];

  const province = ['Daegu', 'Gangwon-do', 'Gyeongsangbuk-do', 'Gyeongsangnam-do', 'Seoul', 'Busan', 'Incheon', 'Ulsan', 'Gyeonggi-do',
    'Chungcheongbuk-do', 'Chungcheongnam-do', 'Jeollabuk-do',
    'Jeollanam-do', 'Jeju-do'];

  const showSample1 = () => {
    const NewDataSet = {
      gender: "female",
      age: "86",
      province: "Daegu",
      city: "Nam-gu",
      infection: "Shincheonji Church",
    };

    setSampleData(NewDataSet);
  };

  const showSample2 = () => {
    const NewDataSet = {
      gender: "male",
      age: "25",
      province: "Gyeongsangbuk-do",
      city: "Chilgok-gun",
      infection: "contact with patient",
    };

    setSampleData(NewDataSet);
  };

  const getResult = () => {
    const {
      gender, age, province, city, infection,
    } = sampleData;

    const params = `${gender},${age},${province},${city},${infection},${Token.token}`;
    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });
    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);
      SetResultData('');

      getQueryResult("output", params)
        .then((res) => {
          console.log(res);
          setSpinner(false);
          SetResultData(res);
          formHighChart(res);
        })
        .catch((err) => {
          console.log(err);
          setSpinner(false);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const formHighChart = (data) => {
    Highcharts.chart('container_1', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      title: {
        text: 'Predicting Korean Mortality Cases',
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          },
        },
      },
      series: [{
        name: 'feature importance',
        colorByPoint: true,
        data: [{
          name: data[1][0],
          y: data[1][1],
        }, {
          name: data[2][0],
          y: data[2][1],
        }, {
          name: data[3][0],
          y: data[3][1],
        }, {
          name: data[4][0],
          y: data[4][1],
        }, {
          name: data[5][0],
          y: data[5][1],
        }],
      }],
    });
  };
  const handleInputs = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Predicting mortality rate of a specific country enables health administrators, researchers, and
            policymakers use prediction models to forecast patient and better capitalize the country’s health
            system utilization. This enables the respective interest groups to identify the seriousness and
            prompts worldwide leaders to take acute measures.
          </p>
          <p>
            Unlike traditional estimates, machine learning algorithms take several features to arrive at accurate
            rs and provide the most realistic prediction based on the user’s input. This application features a
            machine learning model that can predict the mortality rate of South Korea by taking as input features
            that describe the location, as well as the user’s age and gender, giving the model more information on
            localized patterns. The results obtained indicate the chances of survival of a particular user if exposed
            to Covid-19 in the mentioned location.
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/KoreanMortalityPrediction/korean_mortality.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.kaggle.com/datasets/kimjihoo/coronavirusdataset"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container direction="row" justify="center" spacing={2}>
            <Grid item xs={12} sm={5} md={5}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="gender"
                label="Gender"
                value={sampleData.gender}
                onChange={handleInputs}
              >
                {gender.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={0} sm={2} md={1} />
            <Grid item xs={12} sm={5} md={5}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="Age"
                name="age"
                fullWidth
                value={sampleData.age}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={5} md={5}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="city"
                label="City"
                value={sampleData.city}
                onChange={handleInputs}
              >
                {city.map((data) => (
                  <MenuItem value={data}>{data}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={0} sm={2} md={1} />
            <Grid item xs={12} sm={5} md={5}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="province"
                label="Province"
                value={sampleData.province}
                onChange={handleInputs}
              >
                {province.map((data) => (
                  <MenuItem value={data}>{data}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={5} md={5}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="infection"
                label="Infection"
                value={sampleData.infection}
                onChange={handleInputs}
              >
                {infection.map((data) => (
                  <MenuItem value={data}>{data}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={0} sm={7} md={6} />
            <br />
            <br />
            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={showSample1}
              >
                Sample 1
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={showSample2}
              >
                Sample 2
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={getResult}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          {' '}
          <br />
          <br />
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
          <Result>
            <strong>Result:</strong>
            {`${result[0]}`}
          </Result>
          )}
          <HighchartContainer
            id="container_1"
            display={result}
          />
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(PredictMortalityKorean);

const Paper = styled.div`
  border: 2px solid black;
`;
const StyledTableHead = styled(TableCell)`
  background-color: #3c40af;
  color: white;
  font-weight: bold;
`;
