import React, { memo } from "react";
import { Grid } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components
import Collapse from "../../common/Collapse";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
    Paragraph,
    StyledButton,
    DemoContainer,
    LinkContainer,
    List,
    ListItem,
} from "../../../styles/common";

const Ganlab = () => {

    return (
        <SolutionContainer>
            <Collapse text="Description">
                <Paragraph>
                    <p>
                        Generative Adversarial Networks, or GANs, are an approach to generative modeling using deep learning methods, such as convolutional neural networks.
                    </p>
                    <p>
                        Generative modeling is an unsupervised learning task in machine learning that involves automatically discovering and learning the regularities or patterns in input data in such a way that the model can be used to generate or output new examples that plausibly could have been drawn from the original dataset.
                    </p>
                    <p>
                        <strong>Generative adversarial networks</strong> are neural networks that learn to choose samples from a special distribution (the "generative" part of the name), and they do this by setting up a competition (hence "adversarial"). In this visualization, it is showing a GAN that learns a distribution of points in just two dimensions. t's much easier to show the system's mechanics in 2D.
                    </p>
                    <p>
                        GAN Lab uses TensorFlow.js, an in-browser GPU-accelerated deep learning library. Everything, from model training to visualization, is implemented with JavaScript.
                    </p>
                    <p><b>Playing with interactive features. </b> (GAN Lab has many cool features that support interactive experimentation.)</p>
                    <ul>
                        <li><b>Interactive hyperparameter adjustment</b> Click the edit icon () to reveal individual hyperparameters, and edit them on the fly during training.</li>
                        <li><b>User-defined data distribution</b> If you don't like our selection of distributions, draw your own by clicking the icon () at the end of the data distributions list.</li>
                        <li><b>Slow-motion mode</b> Lost track of the animation? Then you can slow it down by clicking the slow-motion icon () to enter slow-mo.</li>
                        <li><b>Manual step-by-step execution</b> If you want more control, you can manually train individual iteration step by step by clicking the icon ().</li>
                    </ul>
                </Paragraph>
                <LinkContainer>
                    <Grid container spacing={2}>

                        <Grid item>
                            <StyledButton
                                variant="outlined"
                                color="primary"
                                size="large"
                                startIcon={<OpenInNewIcon />}
                            >
                                <a
                                    href="https://minsuk.com/research/papers/kahng-ganlab-vast2018.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Read More
                                </a>
                            </StyledButton>
                        </Grid>
                        <Grid item>
                            <StyledButton
                                variant="outlined"
                                color="primary"
                                size="large"
                                startIcon={<OpenInNewIcon />}
                            >
                                <a
                                    href="https://poloclub.github.io/ganlab/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Source
                                </a>
                            </StyledButton>
                        </Grid>
                    </Grid>
                </LinkContainer>
            </Collapse>
            <Collapse text="Demo">
                <DemoContainer>
                    <center>
                        <iframe src="https://poloclub.github.io/ganlab/" width="100%" height="650" />
                    </center>

                </DemoContainer>
            </Collapse>
        </SolutionContainer>
    );
}

export default memo(Ganlab);
