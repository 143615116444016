import APIDATA from '../../../utils/services/api-config';

const Url = `${APIDATA.Z1D}:8502/`;

const fasterAIService = {
  getResult(params) {
    const getRequestConfig = {
      method: "GET",
      cache: "no-cache",
      headers: {
        Accept: "application/json",
      },
    };

    return WSClientWrapper.wrappedFetch(`${Url}${params}`, getRequestConfig);
  },
  uploadImage(file, params) {
    const fd = new FormData();
    fd.append('file', file);
    const getRequestConfig = {
      method: "POST",
      cache: "no-cache",
      headers: {
        Accept: "application/json",
      },
      body: fd,
    };

    return WSClientWrapper.wrappedFetch(`${Url}${params}`, getRequestConfig);
  },

};

export default fasterAIService;
