import categoryIds from "./categoryIds";
import solutionIds from "./solutionIds";
import imageDeepeyeVisualizer from "../../assets/images/solutions/deepeye-visualizer.webp";
import syntheticData from "../../assets/images/solutions/syntheticData.webp";
import mareana from "../../assets/images/solutions/mareana.webp";
import iotReciepe from "../../assets/images/solutions/iotReciepe.webp";
import bioPharmaImage from "../../assets/images/themes/bio-pharma.webp";
import medTechImage from "../../assets/images/themes/med-tech.webp";
import payerImage from "../../assets/images/themes/payer.webp";
import providerImage from "../../assets/images/themes/provider.webp";

import oncologyImg from "../../assets/images/themes/oncology.webp";
import covidImg from "../../assets/images/themes/covid.webp";
import t2dImg from "../../assets/images/themes/t2d.webp";
import orthopedicImage from "../../assets/images/themes/orthopedic.webp";
import renalImage from "../../assets/images/themes/renal.webp";
import interactiveTools from "../../assets/images/solutions/interactiveTools.webp";

export default [
  {
    id: categoryIds.biopharma,
    title: "Bio Pharma",
    type: "primary-category",
    subCategories: [
      "patient-engagement-biopharma",
      "commercial-analytics",
      "clinical-trials",
      "bioinformatics-as-a-service",
    ],
    solutions: [],
  },
  {
    id: categoryIds.medtech,
    title: "MedTech",
    type: "primary-category",
    subCategories: [
      "commercial-solutions",
      "provider-and-payerengagement",
      "patient-engagement-medtech",
      "connected-solutions",
    ],
    solutions: [],
  },
  {
    id: categoryIds.payers,
    title: "Payers",
    type: "primary-category",
    subCategories: [
      "claims-processing",
      "care-management",
      "member-journey",
      "provider-journey",
    ],
    solutions: [],
  },
  {
    id: categoryIds.biopharmaThemes,
    title: "Biopharma",
    summary:
      "Click to find themes and corresponding solutions in Bio Pharma based on service lines viz commercial analytics, patient engagement, clinical trials and bioinformatics",
    type: "theme",
    image: bioPharmaImage,
  },
  {
    id: categoryIds.payerThemes,
    title: "Payer",
    type: "primary-category",
    summary: "Payer is a company that pays for an administered medical service and is responsible for processing patient eligibility, enrolment, claims, and payment e.g. Insurance company. Click to Explore Payer themes for  Solutions.",
    image: payerImage,
    subCategories: [
      "pricing-strategy",
      "member-inquiry",
      "member-fullfillment",
      "healthcare-mgmt-networks",
      "eligibility-coverage",
      "benefit-management",
      "appeals-grievances",
      "nurse-helpline",
      "contextual-cse",
    ],
    solutions: [],
  },
  {
    id: categoryIds.providers,
    title: "Providers",
    image: imageDeepeyeVisualizer,
    type: "primary-category",
    subCategories: [
      "advanced-analytics",
      "ancillaries",
      "population-health-mgmt",
      "patient-engagement-providers",
      "virtual-care-providers",
    ],
    solutions: [],
  },
  {
    id: categoryIds.offerings,
    title: "Offering",
    type: "primary-category",
    subCategories: [
      "faster-ai",
      "platform-ai",
      "computer-vision",
      "synthetic-data",
    ],
    solutions: [],
  },
  {
    id: categoryIds.cancer,
    title: "Cancer",
    type: "primary-category",
    subCategories: [],
    isHideSubTitle: true,
    solutions: [
      solutionIds.drugDiscovery,
      solutionIds.geneVariations,
      solutionIds.lungCancerComorbidities,
      solutionIds.geneExpressionAnalysis,
      solutionIds.breastCancerClassification,
      solutionIds.breastCancerProteomes,
      solutionIds.CancerLectins,
      solutionIds.predictOligomericState,
      solutionIds.cryoTherapyAnalysis,
      solutionIds.melanomaClassification,
      solutionIds.activationMetastasis,
    ],
  },
  {
    id: categoryIds.covid19,
    title: "Covid-19",
    type: "primary-category",
    subCategories: [],
    isHideSubTitle: true,
    solutions: [solutionIds.chestXRaysCovid19],
  },
  {
    id: categoryIds.orthopedic,
    title: "Orthopedic",
    image: orthopedicImage,
    type: "primary-category",
    subCategories: [],
    isHideSubTitle: true,
    solutions: [
      solutionIds.classfyingRheumotoidArthritisPatients,
      solutionIds.deepScap,
      solutionIds.predictBioMechanical,
      solutionIds.rom,
      solutionIds.incisioncare,
      solutionIds.shapleyImages,
      solutionIds.aclTear,
      solutionIds.osteoarthritisSeverityPrediction,
    ],
  },
  {
    id: categoryIds.genomics,
    title: "Genomics",
    type: "primary-category",
    subCategories: [],
    isHideSubTitle: true,
    solutions: [
      solutionIds.geneticVariantClassification,
      solutionIds.antibioticResistancePrediction,
      solutionIds.geneVariations,
      solutionIds.geneExpressionAnalysis,
      solutionIds.breastCancerClassification,
      solutionIds.breastCancerProteomes,
      solutionIds.CancerLectins,
    ],
  },
  {
    id: categoryIds.medtech,
    title: "MedTech",
    image: imageDeepeyeVisualizer,
    type: "primary-category",
    subCategories: ["patientengagement", "provider-and-payerengagement"],
  },
  {
    id: categoryIds.MedTechThemes,
    title: "Med Tech",
    type: "primary-category",
    summary: "Explore themes having solutions analysing data from medical devices, wearables, image scanners, etc in order to prevent or delay of onset of diseases and also for promotion and monitoring of good health.",
    image: medTechImage,
    subCategories: [
      "imaging-driven-testing",
      "pre-op-support",
      "mental-health",
      "collect-analyze",
      "predictive-models",
      "interoperability",
      // "customer-experience",
      "health-monitoring",
      "supply-chain",
      "risk-stratification",
      "virtual-care",
      "monitoring-assistance",
      "health-support",
      "experience-journals",
      "provider-targeting",
      "observational-studies",
      "non-invasive-surgery",
      "patient-centricity",

    ],
    solutions: [],
  },
  {
    id: categoryIds.ProviderThemes,
    title: "Provider",
    summary:
      "A provider is a company that administers a healthcare service and provides inpatient or outpatient medical service or procedure , e.g. , Hospital or a clinic. Click to find Provider themes based solutions and insights.",
    image: providerImage,
    type: "primary-category",
    subCategories: [
      "precision-medicine",
      "discharge-delays",
      "advanced-rcm",
      "real-time-reporting",
      "claim-denials",
    ],
    solutions: [],
  },
  {
    id: categoryIds.provider, // Not id: categoryIds.providers
    title: "Provider",
    type: "primary-category",
    subCategories: ["Provider Service",  "ancillaries",  "patient-engagement-providers", ],
    solutions: [],
  },
  {
    id: categoryIds.genAI,
    title: "Gen AI",
    type: "primary-category",
    subCategories: [],
    isHideSubTitle: true,
    solutions: [
      solutionIds.llm,
      solutionIds.nba,
      solutionIds.contextualSearch,
      solutionIds.medCode,
      solutionIds.auditAssistReportPlatform,
      solutionIds.siteMonitoring,
      solutionIds.medTech,
      solutionIds.docSearch,
      solutionIds.docSearchBot,
      solutionIds.priorAuth,
    ],
  },
  {
    id: categoryIds.stanfordAI,
    title: "Stanford AI",
    type: "primary-category",
    subCategories: [],
    isHideSubTitle: true,
    solutions: [
      solutionIds.shapleyEMR,
      solutionIds.shapleyImages,
      solutionIds.multiAccuracy,
      solutionIds.cpcaImages,
      solutionIds.cpca,
      solutionIds.uhgBreastCancerPrediction,
      solutionIds.uHGStrokePrediction,
      solutionIds.echoNet,
      solutionIds.distributionalShapley,
      solutionIds.colbertAnalysis,
      solutionIds.covid19Simulation,
      solutionIds.neuronShapley,
      solutionIds.imageModel,
    ],
  },
  {
    id: categoryIds.type2Diabetes,
    title: "Type 2 Diabetes",
    type: "primary-category",
    subCategories: [],
    image: t2dImg,
    isHideSubTitle: true,
    solutions: [
      solutionIds.shapleyEMR,
      solutionIds.predictingType2Diabetes,
      solutionIds.networkGraphAnalyzer,
      solutionIds.multiAccuracy,
      solutionIds.clinicalnotesPreprocessor,
      solutionIds.cpca,
      solutionIds.drugReviewAnalyzer,
    ],
  },
  {
    id: categoryIds.renal,
    title: "Renal",
    image: renalImage,
    type: "primary-category",
    subCategories: [],
    isHideSubTitle: true,
    solutions: [solutionIds.networkGraphAnalyzer, solutionIds.bioLemmatizer, solutionIds.chronicKidneyDisease],
  },
  {
    id: 'epidemiology',
    title: "Epidemiology",
    type: "page",
    internalUrl: '/epidemiology',
    image: covidImg,
  },
  {
    id: categoryIds.oncology,
    title: "Oncology",
    image: oncologyImg,
    type: "primary-category",
    subCategories: [
      "incidence-etiology",
      "genetics-and-cancer",
      "cellular-hallmarks",
      "metastasis",
      "imaging",
      "treatment",
    ],
  },
  {
    id: categoryIds.syntheticDataGen,
    title: "Synthetic Data Generator",
    summary:
      "A  Repository of data that is generated programmatically. Its main purpose is to be flexible and rich enough to help an ML practitioner conduct fascinating experiments with various classification, regression, and clustering algorithms.",
    type: "primary-category",
    isHideSubTitle: true,
    image: syntheticData,
    solutions: [solutionIds.gan, solutionIds.genRocket, solutionIds.synthea],
  },

  // collaborators
  {
    id: categoryIds.collaborators,
    title: "Developers Collaborators",
    type: "primary-category",
    isHideSubTitle: true,
    solutions: [
      solutionIds.devPrognosis,
      solutionIds.devInsClaimPrediction,
      solutionIds.devTumorMicroEnv,
      solutionIds.devReadmission,
      solutionIds.devShapleyGanIntro,
      solutionIds.devAIMLBlog,
      solutionIds.devGenomics,
      solutionIds.devRPM,
      solutionIds.devShapleyGanIntro,
      solutionIds.devVideoMetadata
    ],
  },

  // innovation as service
  {
    id: categoryIds.mareana,
    title: "Mareana",
    type: "primary-category",
    summary:
      "Mareana enables Life Sciences companies to gain control over unstructured data by converting it into meaningful business intelligence for optimization, cost savings and enhanced customer experiences",
    image: mareana,
    isHideSubTitle: true,
    solutions: [
      solutionIds.salesTraining,
      solutionIds.mareanaFaq,
      solutionIds.onePager,
    ],
  },
  {
    id: categoryIds.iotReciepe,
    title: "IOT Receipes",
    type: "primary-category",
    summary:
      "Conceptualization and rapid prototyping of IoT solutions with hardware/electronics and software recipes.",
    image: iotReciepe,
    solutions: [
      solutionIds.hardwareRecipes,
      solutionIds.softwareRecipes,
      solutionIds.IotTempaltes,
    ],
  },
  {
    id: categoryIds.interactiveTools,
    title: "Interactive Tools",
    type: "primary-category",
    summary:
      "Explore the interactive applications to learn and know more about various ML and deep learning concepts",
    image: interactiveTools,
    solutions: [
      solutionIds.ganlab,
      solutionIds.conVnet,
      solutionIds.cnnExplainer,
      solutionIds.activeLearner,
      solutionIds.anomagram,
      solutionIds.turbofanTycoon,
      solutionIds.selfOrganizing,
      solutionIds.exBERT,
    ],
  },
];
