import React, { useState } from "react";
import {
  Typography, Grid, Button, TextField, Tooltip,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Redirect } from "react-router";

// import local components
import styled from "styled-components";
import { Link } from "react-router-dom";
import Spinner from "../common/loading/Spinner";
import MainLayout from "../common/MainLayout";
import Footer from "../views/home/Footer";

// images
import vLifeLogin from "../../assets/images/app/vLifeLogin.webp";
import resetPassword from "../../assets/images/app/resetPassword.webp";
import TransformedLogo from "../../assets/logos/vlifeLogo4.webp";
import { isPasswordValid } from "../../utils";
import { USER_MANAGEMENT } from "../../constants";
import commonServices from "../../utils/services/CommonService";
import PasswordValidator from "../common/PasswordValidator";
import { StyledBackdrop } from "../../styles/common";

const ResetPassword = ({ location }) => {
  const initialData = {
    email: location?.state?.destination,
    password: "",
    confirmPassword: "",
    token: "",
  };

  const [showSpinner, setSpinner] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("Token has been sent to mail, please check the inbox");
  const [showResend, setShowResend] = useState(false);
  const [redirectData, setRedirect] = useState(false);
  const [fields, setFields] = useState({ values: initialData, errors: {} });

  const { postQueryResult } = commonServices("portalAPI", "resetPwd");

  const setFieldObject = (errors, values) => {
    setFields({
      values: {
        ...fields.values,
        ...values,
      },
      errors: {
        ...fields.errors,
        ...errors,
      },
    });
  };

  const onChangeHandler = (event) => {
    const { value } = event.target;
    const { name } = event.target;
    setFieldObject({ [`${name}_error`]: false }, { [name]: value });
  };

  const resendToken = () => {
    const params = {
      username: location?.state?.user,
    };
    setSpinner(true);
    setErrorMessage(false);
    postQueryResult('resendToken', params)
      .then((response) => {
        setSuccessMessage(USER_MANAGEMENT.RESET_PWD_RESEND_TOKEN);
        setSpinner(false);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setErrorMessage(err.message);
      });
  };
  const handleResetPasswordClick = () => {
    const fieldErrors = {};
    let areFieldsEmpty;
    const areFieldsValid = true;
    const {
      PWD_POLICY_ERR, REQ_FIELD, PWD_MATCH_ERR, RESET_SUCESS,
    } = USER_MANAGEMENT;

    Object.keys(fields.values).map((key) => {
      if (!fields.values[key]) {
        fieldErrors[`${key}_error`] = REQ_FIELD;
        areFieldsEmpty = true;
      }
    });

    if (areFieldsEmpty) {
      setFieldObject(fieldErrors);
      return;
    }

    if (!isPasswordValid(fields.values.password)) {
      setErrorMessage(PWD_POLICY_ERR);
      return;
    }

    if (fields.values.password !== fields.values.confirmPassword) {
      setErrorMessage(PWD_MATCH_ERR);
      return;
    }

    if (areFieldsValid) {
      let params = {
        uname: location?.state?.user,
        vtoken: fields.values.token,
        password: fields.values.password,
      };
      setSpinner(true);
      setErrorMessage(false);
      setSuccessMessage(false);

      postQueryResult('resetPwd', params)
        .then((response) => {
          setSpinner(false);
          if (response.status === 200 && !response.Error) {
            if (location?.state?.id) {
              params = {
                data: {
                  id: location?.state?.id.toUpperCase(),
                  passwordResetFlag: false,
                },
              };
              postQueryResult('updateUser', params)
                .then((res) => {
                  console.log(res);
                })
                .catch((err) => {
                  setSpinner(false);
                  setErrorMessage(err.message);
                });
            }
            setSuccessMessage(RESET_SUCESS);
            setTimeout(() => {
              setRedirect({ pathname: '/login' });
            }, 3000);
          } else if (response.Error) {
            setErrorMessage(response.Error.message);
            setShowResend(true);
          }
        })
        .catch((err) => {
          setSpinner(false);
          console.log(err);
          setErrorMessage(err.message);
        });
    }
  };

  return (
    <MainLayout>
      {redirectData && <Redirect to={redirectData} />}
      <BgImage
        imageSrc={vLifeLogin}
        parallaxOffset="150"
        minHeight="100vh"
        opacity="0.8"
        color="#283caa"
      >
        <Container id="back-to-top-anchor">

          <LogoContainer id="logo" item xs={1}>
            <Link to="/">
              <LogoImage id="nav-logo" src={TransformedLogo} alt="" />
            </Link>
          </LogoContainer>
          <Grid container justify="center">
            <Grid item xs={12} sm={8} md={6}>
              <Grid
                container
                alignItems="center"
                alignContent="center"
                justifyContent="center"
              >
                <Grid item xs={10}>
                  <img width="15%" src={resetPassword} />
                  <WhiteTypography variant="h5" gutterbutton>
                    Reset Password
                  </WhiteTypography>
                </Grid>

              </Grid>
              <br />
              <Grid xs={12} container direction="column">
                <Grid
                  container
                  spacing={3}
                  direction="column"
                  alignItems="center"
                >
                  <Grid item xs={10}>
                    {errorMessage && (
                      <ErrorAlert severity="error" variant="outlined">
                        {errorMessage}
                      </ErrorAlert>
                    )}
                  </Grid>
                  <FullWidthGrid item xs={10}>
                    <CustomTextField
                      fullWidth
                      name="email"
                      label="Mail Id"
                      required
                      value={fields.values.email}
                      onChange={onChangeHandler}
                      error={fields.errors.password_error}
                      helperText={fields.errors.password_error}
                      type="email"
                      disabled
                      autoComplete="off"
                    />
                  </FullWidthGrid>
                  <FullWidthGrid item xs={10}>
                    <PasswordValidator value={fields.values.password}>
                      <CustomTextField
                        autoComplete="off"
                        fullWidth
                        name="password"
                        label="New Password"
                        required
                        value={fields.values.password}
                        onChange={onChangeHandler}
                        error={fields.errors.password_error}
                        helperText={fields.errors.password_error}
                        type="password"
                        size="large"
                      />
                    </PasswordValidator>
                  </FullWidthGrid>

                  <FullWidthGrid item xs={10}>
                    <CustomTextField
                      fullWidth
                      name="confirmPassword"
                      label="Confirm New Password"
                      required
                      value={fields.values.confirmPassword}
                      onChange={onChangeHandler}
                      error={fields.errors.confirmPassword_error}
                      helperText={fields.errors.confirmPassword_error}
                      type="password"
                      autoComplete="off"
                    />
                  </FullWidthGrid>
                  <FullWidthGrid item xs={10}>
                    <CustomTextField
                      fullWidth
                      name="token"
                      label="Token"
                      required
                      value={fields.values.token}
                      onChange={onChangeHandler}
                      error={fields.errors.token_error}
                      helperText={fields.errors.token_error}
                      type="Text"
                      autoComplete="off"
                    />
                  </FullWidthGrid>
                  <FullWidthGrid item xs={10}>
                    <Btn
                      variant="contained"
                      color="primary"
                      disabled={showSpinner}
                      onClick={handleResetPasswordClick}
                    >
                      Submit
                    </Btn>
                  </FullWidthGrid>
                  {showResend && (
                    <FullWidthGrid item xs={10}>
                      <Button variant="contained" onClick={resendToken}>
                        Resend Token
                      </Button>
                    </FullWidthGrid>
                  )}
                  {successMessage && (
                    <SuccessAlert severity="success">{successMessage}</SuccessAlert>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </BgImage>
      <StyledFooter hideSocialShare />
      <StyledBackdrop open={showSpinner}>
        <Spinner />
      </StyledBackdrop>
    </MainLayout>
  );
};
export default ResetPassword;

const Container = styled.div`
width: 100%;
background-color: rgba(40,60,170,0.8);
padding: 30px 0;
text-align: center;
`;
const BgImage = styled.div`
  background-image: url(${vLifeLogin});
  object-fit: cover;
  min-height: 90vh;
  opacity: 0.9;
  max-width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-position: center;
`;
const CustomTextField = styled(TextField)`
  width: 100% !important;
  color: #ffffff;
  border: 0;
  margin: -15px 85px 11px 8px !important;
  padding: 0;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;

  @media only screen and (max-width: 585px) {
    margin: -8px !important;
  }

  & label.MuiInputLabel-root {
    color: #ffffff;
    @media only screen and (max-width: 900px) {
      font-size: 15px !important;
    }
    @media only screen and (max-width: 450px) {
      font-size: 12px !important;
    }
  }
  & .MuiInput-underline:before {
    border-bottom-color: #ffffff;
  }
  & .MuiInputBase-input.MuiInput-input {
    color: #fc7703;
    @media only screen and (max-width: 900px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 450px) {
      font-size: 15px;
    }
    & .muiformhelpertext-root {
      @media only screen and (max-width: 900px) {
        font-size: 12px !important;
      }
      @media only screen and (max-width: 450px) {
        font-size: 10px !important;
      }
      color: #ffff;
    }
  }
  & .MuiFormHelperText-root {
    color: #ffff;
  }
  & label.Mui-focused {
    color: #fc7703;
  }
  & .MuiInput-underline:after {
    border-bottom-color: #fc7703;
  }
  & .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom-color: #fc7703;
  }
`;
const Btn = styled(Button)`
  color: #ffffff;
  background-color: #fc7703 !important;
  width: 100% !important;
  margin: 3% !important;
  & hover {
    background-color: #fc7703;
    transform: scale(1.1);
  }
  @media only screen and (max-width: 499px) {
    margin: 0px !important;
  }
`;
const StyledFooter = styled(Footer)`
  padding: 0px !important;
  @media only screen and (max-width: 900px) {
    padding: 0px !important;
  }
`;
const FullWidthGrid = styled(Grid)`
  width: 100% !important;
`;
const SuccessAlert = styled(Alert)`
&.MuiAlert-root{
  color: white;
  background: transparent;
}
`;
const ErrorAlert = styled(Alert)`
&.MuiAlert-root {
  color: white;
  border: 2px solid #fc7703;
  margin: 5px;
}
`;
const WhiteTypography = styled(Typography)`
  color: white;
`;
const LogoContainer = styled.div`
text-align: center;`;
const LogoImage = styled.img`
  margin: 10px;
  height: 30px;
  cursor: pointer;
  &:hover {
    translate: scale(1.05)
  }
`;
