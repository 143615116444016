import React, { memo, useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

import Highcharts from "highcharts";

// local components
import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  HighchartContainer,
} from "../../../styles/common";
import getUserData, { getUploadToken } from '../../../utils';

// image components
import drugreviewanalyzer_solution1 from "../../../assets/images/app/drugreviewanalyzer.webp";

const DrugReviewAnalyzer = () => {
  const initialData = {
    medication_name: "",
    urls: "",
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [result, SetResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService("commercialAnalytics", "drugReviewAnalyzer");
  const Token = getUploadToken();

  const formHighChart = (data1) => {
    Highcharts.chart('container', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      title: {
        text: 'SENTIMENT OF THE DRUG',
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          },
        },
      },
      series: [{
        name: 'Sentiment',
        colorByPoint: true,
        data: [{
          name: 'Positive',
          y: data1[0],
          color: '#bdef9e',

        }, {
          name: 'Negative',
          y: data1[1],
          color: '#398de6',

        },
        {
          name: 'Fake',
          y: data1[2],
          color: '#ef6e6e',

        },
        ],
      }],
    });
  };

  const showSample = () => {
    const NewDataSet = {
      medication_name: "insulin",
      urls: "https://www.webmd.com/diabetes/guide/overview,https://www.medicalnewstoday.com/articles/323760.php,https://www.endocrineweb.com/conditions/type-1-diabetes/what-insulin",
    };

    setSampleData(NewDataSet);

    SetResultData('');
    formHighChart('');
  };

  const getResult = () => {
    const {
      medication_name,
      urls,
    } = sampleData;

    const params = `${medication_name},${Token.token},${urls.split(' ').join(',')}`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });
    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("sample", params)
        .then((res) => {
          setSpinner(false);
          SetResultData(res.values);
          formHighChart(res.values);
        })
        .catch((err) => {
          setSpinner(false);
          setSpinner(false);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const handleInputs = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            <strong>Problem :</strong>
            {' '}
            After a successful launch of a drug into the market, there are opinions and expert reviews occurring which impact the sales of drug in long run. The reviews and feedback are unstructured and organizations have limited means to receive the feedback. There is a high chance that the feedback is often biased and does not reach the right stakeholder.
          </p>
          <p>
            <strong>Solution:</strong>
            {' '}
            This solution helps the actual stakeholder to analyse and identify the pitfalls through an unbiased review of launched drug. The reviews will help in estimating the sentiment of the drug being marketed. It will be an extra tool to the salesforce to support their cause at a sales pitch.
          </p>
          <p>
            <strong>Approach:</strong>
            {' '}
            This use case collects multiple urls from a user and web scraps the given links for the drug of interest. Once the application receives required amount of information, it weeds out the noise and finds the sentiment score of the drug using a lexicon and rule-based sentiment analysis tool called VADER (Valence Aware Dictionary and sEntiment Reasoner) sentiment analyzer.
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/blob/master/Drug_review_analyzer/Drug%20review%20analyzer%20-%20media%20analytics.ipynb"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.medicalnewstoday.com/articles/323760"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Data source
                </a>
              </StyledButton>
            </Grid>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.endocrineweb.com/conditions/type-1-diabetes/what-insulin"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Related Link
                </a>
              </StyledButton>
            </Grid>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.webmd.com/diabetes/diabetes-types-insulin#1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Related Link
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container direction="row" justify="center" spacing={2}>
            <Grid item xs={12} sm={10} md={8}>
              <img width="100%" src={drugreviewanalyzer_solution1} />
            </Grid>
            <br />
            <br />
            <Grid item xs={12} sm={6} md={5}>
              <TextField
                variant="outlined"
                fullWidth
                name="medication_name"
                label="Medication Name*"
                value={sampleData.medication_name}
                onChange={handleInputs}
              />
            </Grid>
            <Grid md={1} />
            <Grid item xs={12} sm={6} md={5}>
              <TextField
                variant="outlined"
                label="URLs*"
                name="urls"
                helperText="enter urls separated by comma"
                fullWidth
                onChange={handleInputs}
                value={sampleData.urls}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5} />
            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={showSample}
              >
                Sample Data
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={getResult}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
            <Result>
              <strong>Result:</strong>
              Below is an unbiased performance of the concerned drug
            </Result>
          )}
          <Grid xs={12} sm={12} md={12}>
            <HighchartContainer
              id="container"
              display={result}
            />
          </Grid>
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(DrugReviewAnalyzer);
