import React, { memo, useState } from "react";
import {
  Grid, Typography, Slide, Divider,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// import local components

import styled from "styled-components";
import LazyHero from "react-lazy-hero";
import Collapse from "../../common/Collapse";
import {
  StyledButton,
  SolutionContainer,
  LinkContainer,
  Paragraph,
} from "../../../styles/common";

// images
import dataInteroperablePlatform_sol1 from "../../../assets/images/app/dataInteroperablePlatform_sol1.webp";
import dataInteroperablePlatform_sol2 from "../../../assets/images/app/dataInteroperablePlatform_sol2.webp";
import dataInteroperablePlatform_sol3 from "../../../assets/images/app/dataInteroperablePlatform_sol3.webp";
import dataInteroperablePlatform_sample1 from "../../../assets/images/app/dataInteroperablePlatform_sample1.webp";
import dataInteroperablePlatform_sample2 from "../../../assets/images/app/dataInteroperablePlatform_sample2.webp";
import dataInteroperablePlatform_sample3 from "../../../assets/images/app/dataInteroperablePlatform_sample3.webp";
import dataInteroperablePlatform_sample4 from "../../../assets/images/app/dataInteroperablePlatform_sample4.webp";
import dataInteroperablePlatform_sample5 from "../../../assets/images/app/dataInteroperablePlatform_sample5.webp";
import HospitalCard from '../../common/HospitalCard';
import ZoomImage from "../../common/ZoomImage";

const data = [
  [
    "GenRocket",
    "Where we simulate the EMR records of 40 patients from 4 different hospitals",
    "https://app.genrocket.com/login/auth",
    "Please Contact vLife™ Team for Credentials",,
  ],
  [
    "Medical Device Simulator",
    "Where the Glucometer and Blood pressure readings are simulated for 40 patients.",
    "https://iot-device-simulator.vlifevirtusa.com/",
    "Please Contact vLife™ Team for Credentials",
  ],
  [
    "EMR data stored in s3",
    "Direct s3 link to open the csv file",
    "https://material.vlifevirtusa.com/RPM/EmrDevice_Data.htm",
    "",
    "",
    "https://apps.vlifevirtusa.com:8503/vLifePortalSSO/fetchObject?path=EMR-Records/emr-records.zip",
  ],
  [
    "Device data stored in s3",
    "Direct s3 link to open the csv file",
    "https://material.vlifevirtusa.com/RPM/Device_Data.htm",
    "",
    "",
    "https://apps.vlifevirtusa.com:8503/vLifePortalSSO/fetchObject?path=hims-poc-device-data/himss-poc-device-data.zip",
  ],
  [
    "EMR + Device data joined using Patient ID",
    "Direct s3 link to open the csv file & Screen shot of joined dataset highlighting the patient IDs ",
    "https://material.vlifevirtusa.com/RPM/emr-patients-record.htm",
    "",
    "",
    "https://apps.vlifevirtusa.com:8503/vLifePortalSSO/fetchObject?path=emr-med-device-data-zip-folder/emr-med-device-data.zip",
  ],
  [
    "Logistic Regression Model",
    "To predict the patient’s recovery status – Jupyter Notebook Link",
    "https://material.vlifevirtusa.com/InterSystems/InterSystems_HIMSS_Demo.html",
    "Please Contact vLife™ Team for Credentials",
  ],
  [
    "File generated by the Model with the Patient Recovery Status",
    "Direct s3 link to open the csv file ",
    "https://material.vlifevirtusa.com/RPM/Prediction-output.htm",
    "",
    "",
  ],
  [
    "Intersystems Transformations from csv to HL7",
    "Adapters to connect to csv , transform to hl7",
    "https://apps.vlifevirtusa.com:52773/csp/healthshare/virtusa/EnsPortal.ProductionConfig.zen?PRODUCTION=VIRTUSA.HIMSS.Production",
    "Please Contact vLife™ Team for Credentials",
  ],
  [
    "Patient Recovery Status Dashboard",
    "Data Discovery",
    "https://apps.vlifevirtusa.com:52773/csp/healthshare/virtusa/_DeepSee.UserPortal.DashboardViewer.zen?DASHBOARD=HIMSS%20dashboard.dashboard&DASHBOARD=HIMSS%20dashboard.dashboard&DASHBOARD=HIMSS%20dashboard.dashboard&DASHBOARD=HIMSS%20dashboard.dashboard&DASHBOARD=HIMSS%20dashboard.dashboard&DASHBOARD=HIMSS%20dashboard.dashboard&DASHBOARD=HIMSS%20dashboard.dashboard&DASHBOARD=HIMSS%20dashboard.dashboard&DASHBOARD=HIMSS%20dashboard.dashboard&EMBED=1&DASHBOARD=HIMSS%20dashboard.dashboard&DASHBOARD=HIMSS%20dashboard.dashboard&DASHBOARD=HIMSS%20dashboard.dashboard&DASHBOARD=HIMSS%20dashboard.dashboard&DASHBOARD=HIMSS%20dashboard.dashboard&DASHBOARD=HIMSS%20dashboard.dashboard&DASHBOARD=HIMSS%20dashboard.dashboard&DASHBOARD=HIMSS%20dashboard.dashboard&DASHBOARD=HIMSS%20dashboard.dashboard&EMBED=1&IRIS",
    "Please Contact vLife™ Team for Credentials",
    "",
  ],
  [
    "QuickSight Dashboard",
    "Data  Discovery",
    "",
    "",
    "",
    "https://apps.vlifevirtusa.com:8503/vLifePortalSSO/embedUrl?id=8a2bd537-3125-4bc1-8dce-043299311a5f",
    "Click Here",
  ],
];

const DataInteroperablePlatform = () => {
  const [videoUrl, setVideoUrl] = useState('');
  const [openDialog, setOpenDialog] = useState(false);

  const videoData = {
    appDemo: "https://material.vlifevirtusa.com/RPM/AppDemo.mp4",
    pushNotification: "https://material.vlifevirtusa.com/RPM/PushNotification.mp4",
  };

  const showVideoDialog = (videoUrl) => {
    setVideoUrl(videoData[videoUrl]);
    setOpenDialog(true);
  };
  const closeCommonDialog = () => {
    setOpenDialog(false);
  };

  return (
    <SolutionContainer>
      <BgImage>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item xs={12} sm={7}>
            <Slide direction="right" timeout={600} in>
              <Typography>
                <BannerTitle>Data Interoperable RPM Platform</BannerTitle>
              </Typography>
            </Slide>
          </Grid>
          <Grid item xs={12} sm={5}>
            <img width="400px" src={dataInteroperablePlatform_sol1} alt="" />
          </Grid>
        </Grid>
      </BgImage>
      <br />
      <br />
      <section>
        <div>
          <Grid container xs={12} direction="row">
            <Grid item xs={3}>
              <SubSectiontitle>
                <Typography variant="h4">About</Typography>
              </SubSectiontitle>
            </Grid>
          </Grid>
          <Collapse text="Description">
            <Grid container xs={12} direction="row">
              <Grid item xs={12} md={6}>
                <Paragraph>
                  Life sciences organizations are being challenged to craft
                  patient and provider-centric strategies in order to actively
                  participate in the optimization and personalization of care
                  delivery. These strategies include integrating products and
                  services into a care delivery platform consisting of
                  therapeutics, delivery devices, mobile apps, and data, with
                  the ability to provide clinical decision support and analytics
                  at the point of care. This can be addressed by enabling
                  seamless interoperability between traditionally isolated life
                  science, clinical health delivery, and payor IT systems.
                  <br />
                  <br />
                  The HL7 FHIR (Fast Healthcare Interoperability Resources)
                  standards for data formats and application API’s have evolved
                  from a vision of interoperability and data sharing a decade
                  ago to become a recognized and required standard for the
                  future of healthcare. FHIR provides the fundamental building
                  blocks to develop intelligent systems with the ability to
                  support actionable decision-making embedded within clinical
                  workflows. The application intends to showcase capabilities
                  using Intersystem services and solutions around data
                  management & data interoperability for HLS industry
                </Paragraph>
                <br />
                <LinkContainer>
                  <Grid container spacing={2}>
                    <Grid item>
                      <StyledButton
                        variant="outlined"
                        color="primary"
                        size="large"
                        startIcon={<OpenInNewIcon />}
                      >
                        <a
                          href="../../../assets/files/Intersystems_POC_HIMMS2020_v02.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Read More
                        </a>
                      </StyledButton>
                    </Grid>
                  </Grid>
                </LinkContainer>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography align="center" variant="subtitle2">
                  Here is the reference architecture for the solutions
                </Typography>
                <ZoomImage width="100%" src={dataInteroperablePlatform_sol3} />
              </Grid>
            </Grid>
          </Collapse>
          <Collapse text="Demo">
            <Grid item xs={12} md={6}>
              <SubSectiontitle>
                <Typography variant="subtitle2">
                  The following artifacts showcases various steps involved in
                  this solution.
                </Typography>
              </SubSectiontitle>
            </Grid>
            <Grid container spacing={3} direction="row">
              {data.map((data) => (
                <Grid item xs={12} sm={4} md={3}>
                  <HospitalCard
                    name={data[0]}
                    utility={data[1]}
                    link={data[2]}
                    s3Link={data[5]}
                    userName={data[3]}
                    s3LinkButtonName={data[6]}
                  />
                </Grid>
              ))}
              <Grid item xs={12} sm={4} md={3}>
                <HospitalCard
                  name="mHealth App Videos"
                  utility="Talks about mobile app navigation, patient engagement"
                  video={["appDemo", "pushNotification"]}
                  btnClick={showVideoDialog}
                />
              </Grid>
            </Grid>
            <br />
            <Grid container xs={12} direction="row">
              <Grid item xs={12} md={6}>
                <SubSectiontitle>

                  <Typography variant="subtitle2">
                    EMR + Device data joined using Patient ID
                  </Typography>
                </SubSectiontitle>
                <Divider />
              </Grid>
            </Grid>
            <Grid container spacing={3} xs={12} direction="row">
              <Grid item xs={12} md={3}>
                <ZoomImage
                  width="100%"
                  src={dataInteroperablePlatform_sample5}
                  id="DataJoiningEMR"
                />
              </Grid>
            </Grid>
            <br />
            <Grid container xs={12} direction="row">
              <Grid item xs={12} md={6}>
                <SubSectiontitle>
                  <Typography variant="subtitle2">
                    InterSystem services for providing data in HL7 FHIR
                    standards
                  </Typography>
                </SubSectiontitle>
              </Grid>
            </Grid>
            <Grid container spacing={3} xs={12} direction="row">
              <Grid item xs={12} md={3}>
                <ZoomImage
                  width="100%"
                  src={dataInteroperablePlatform_sample1}
                  id="sample1"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <ZoomImage
                  width="100%"
                  src={dataInteroperablePlatform_sample2}
                  id="sample2"
                />
              </Grid>
              <Grid itemxs={12} md={3}>
                <ZoomImage
                  width="100%"
                  src={dataInteroperablePlatform_sample3}
                  id="sample3"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <ZoomImage
                  width="100%"
                  src={dataInteroperablePlatform_sample4}
                  id="sample4"
                />
              </Grid>
            </Grid>
          </Collapse>
        </div>
      </section>
      <section>
        <Dialog
          onClose={closeCommonDialog}
          aria-labelledby="diabetes-dialog-title"
          open={openDialog}
        >
          <DialogTitle
            id="diabetes-dialog-title"
            onClose={closeCommonDialog}
          >
            {/* {this.state.dialogTitle} */}
            <IconButton
              aria-label="Close"
              onClick={closeCommonDialog}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            {videoUrl && (
              <video height="500px" width="500px" controls>
                <source
                  src={videoUrl}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            )}
          </DialogContent>
        </Dialog>
      </section>
    </SolutionContainer>
  );
};

export default memo(DataInteroperablePlatform);
const SubSectiontitle = styled.div`
  border-bottom: 3px solid #3175eb;
  text-align: left;
  margin-bottom: 20px;
  padding: 20px 0 10px 0;
`;
const BannerTitle = styled.text`
  color: #ffffff;
  font-size: 50px;
  font-weight: 200;
  text-align: left;
  padding-top: 25px;
  padding-left: 20px;
  @media only screen and (max-width: 650px) {
    font-size: 40px;
  }
  @media only screen and (max-width: 450px) {
    font-size: 30px;
  }
`;
const BgImage = styled.div`
  background-image: url(${dataInteroperablePlatform_sol2});
  object-fit: cover;
  min-height: 75vh;
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 1;
  max-width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-position: center;
  text-align: center;
  background: rgba(40, 60, 170, 0.8);

`;
