import React, { memo, useState } from "react";
import styled from 'styled-components';
import { TextField, Grid, AppBar, Tab, MenuItem } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import Grow from "@material-ui/core/Grow";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components
import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
    Paragraph,
    StyledButton,
    DemoContainer,
    List,
    ListItem,
    LinkContainer,
} from "../../../styles/common";
import { StyledTabs } from './IOTRecipesView';
import { TabPanel } from './vnet/components/ResultPanel';
import ZoomImage from '../../common/ZoomImage';

// images
import mnist from "../../../assets/images/app/image_modal1.png";
import fmnist from "../../../assets/images/app/image_modal2.png";
import xray from "../../../assets/images/app/image_modal3.png";
import imagemodal_sol1 from "../../../assets/images/app/DP_workflow.webp";

// user id
import getUserData, { getUploadToken } from '../../../utils';

const ImageModel = () => {

    const { postQueryResult } = CommonService(
        'z1dApps',
        'imagemodel'
    );
   const Token = getUploadToken();

    const [notification, setNotification] = useState('');
    const [showSpinner, setSpinner] = useState(false);

    const [userId, setUserId] = useState(getUserData()?.token?.emailid);
    const [columndata, setColumnData] = useState({ delta: "", noise_multiplier: "" });
    const [imgTabvalue, setimgTabvalue] = useState(0);

    // radio button
    const [radiovalue, setRadioValue] = useState("s3://vlife-applications/dp_image/mridul/input/data");
    const [radioName, setRadioName] = useState("fmnist");

    //data
    const [sampleResult, setSampleResult] = useState(false);
    const [sampleImage, setSampleImage] = useState(fmnist);
    const [metricscore, setMetricScore] = useState(null);
    const [scoreimg, setScoreImg] = useState(null);
    const [infer, setInfer] = useState(null);

    const imghandletabChange = (event, newValue) => {
        setimgTabvalue(newValue);
    };

    const handleRadioChange = (event) => {
        setRadioValue(event.target.value);
        setRadioName(event.target.name);
    };

    const handleInputs = (e) => {
        const { name } = e.target;
        const { value } = e.target;
        setColumnData({
            ...columndata,
            [name]: value,
        });
    };

    const getUploadResult = () => {

        setInfer('');
        setScoreImg('');
        setMetricScore('');

        const current = new Date();
        const date = `${current.getDate()}/${current.getMonth() + 1
            }/${current.getFullYear()}`;

        const {
            delta,
            noise_multiplier,
        } = columndata;

        const deltas = `${delta}`;
        const noise_multipliers = `${noise_multiplier}`;

        setSpinner(true);
        postQueryResult('upload', {
            token: Token.token,
            Context_param: {
                Application_name: 'dp_image',
                UserID: userId,
            },
            Content_param: {
                dataset: radioName, delta: deltas,
                noise_multiplier: noise_multipliers, s3Path: radiovalue
            },
        })
            .then((res) => {
                setSpinner(false);
                setNotification({ open: 'success', message: res.Console });
                setMetricScore(res?.Result);
            })
            .catch((err) => {
                setNotification({ open: 'error', message: err.message });
                setSpinner(false);
            });
    };

    const getMetricResult = () => {

        setInfer('');
        setScoreImg('');

        const current = new Date();
        const date = `${current.getDate()}/${current.getMonth() + 1
            }/${current.getFullYear()}`;

        const {
            delta,
            noise_multiplier,
        } = columndata;

        const deltas = `${delta}`;
        const noise_multipliers = `${noise_multiplier}`;

        setSpinner(true);
        postQueryResult('scoreimg', {
            token: Token.token,
            Context_param: {
                Application_name: 'dp_image',
                UserID: userId,
            },
            Content_param: {
                dataset: radioName, delta: deltas,
                noise_multiplier: noise_multipliers, s3Path: radiovalue
            },
        })
            .then((res) => {
                setSpinner(false);
                setNotification({ open: 'success', message: res.Console });
                setScoreImg(res?.Result);
            })
            .catch((err) => {
                setNotification({ open: 'error', message: err.message });
                setSpinner(false);
            });
    };

    const getFinalResult = () => {

        setInfer('');

        const current = new Date();
        const date = `${current.getDate()}/${current.getMonth() + 1
            }/${current.getFullYear()}`;

        const {
            delta,
            noise_multiplier,
        } = columndata;

        const deltas = `${delta}`;
        const noise_multipliers = `${noise_multiplier}`;

        setSpinner(true);
        postQueryResult('infer', {
            token: Token.token,
            Context_param: {
                Application_name: 'dp_image',
                UserID: userId,
            },
            Content_param: {
                dataset: radioName, delta: deltas,
                noise_multiplier: noise_multipliers, s3Path: radiovalue
            },
        })
            .then((res) => {
                setSpinner(false);
                setNotification({ open: 'success', message: res.Console });
                setInfer(res?.Result);
            })
            .catch((err) => {
                setNotification({ open: 'error', message: err.message });
                setSpinner(false);
            });
    };

    const getSampleUploadResult = () => {

        setSpinner(true);
        setSampleResult('');
        setInfer('');
        setScoreImg('');
        setMetricScore('');

        setTimeout(() => {
            setSpinner(false);
            setSampleResult(true);
            if (radioName == "mnist") {
                setSampleImage(mnist);
            }
            else if (radioName == "fmnist") {
                setSampleImage(fmnist);
            }
            else {
                setSampleImage(xray);
            }
        }, 2000);

    };

    return (
        <SolutionContainer snackbar={notification}>
            <Collapse text="Description">
                <Paragraph>
                    <p>
                        Maintaining an individual’s privacy is a major concern when collecting sensitive information from groups or organizations. A formalization of privacy known a differential privacy has become the gold standard with which to protect information from malicious agents. Differential privacy offers some of the most stringent know theoretical privacy guarantees. Intuitively, for some query on some dataset, a differentially private algorithm produces an output, regulated by a privacy parameter (Epsilon), that is statistically indistinguishable from the same query on this same dataset had any one individual’s information been removed. This powerful tool has been adopted by researchers and industry leaders, and has become particularly interesting to machine learning practitioners, who hope to leverage privatized data in training predictive models.
                    </p>
                    <p>
                        Because differential privacy often depends on adding noise, the results of differentially private algorithms can come at the cost of data accuracy and utility. However, differentially private machine learning algorithms have shown promise across a number of domains. These algorithms can provide tight privacy guarantees while still producing accurate predictions. A drawback to most methods, however, is in the one-off nature of training: once the model is produced, the privacy budget for a real dataset can be entirely consumed. The differentially private model is therefore inflexible to retraining and difficult to share/verify: the output model is a black box.
                    </p>
                    <p>
                        This can be especially disadvantageous in the presence of high dimensional data that require rigorous training techniques like dimensionality reduction or feature selection. With limited budget to spend, data scientists cannot exercise free range over a dataset, thus sacrificing model quality. In an effort to remedy this, and other challenges faced by traditional differentially private methods for querying, we can use differentially private techniques for synthetic data generation, investigate the privatized data, and train informed supervised learning models.
                    </p>
                    <center>
                        <Grid item xs={12} sm={10} md={8}>
                            <img width="90%" src={imagemodal_sol1} alt="" />
                            <p>Noise addition for Differential privacy in Deep learning workflow</p>
                        </Grid>
                    </center>
                    <p><strong>Business Uses:</strong></p>
                    <p>Differential privacy is important for businesses because:</p>
                    <List>
                        <ListItem>
                            Traditional Machine Learning Models trained on private information may unintentionally leak data. Also, these models are prone to many adversarial attacks (like Membership Inference attack). Differential privacy adds a controlled amount of randomness to a dataset to prevent anyone from obtaining information about individuals in the dataset. The added randomness is controlled. Therefore, the resulting dataset is still accurate enough to generate aggregate insights through data analysis while maintaining the privacy of individual participants.
                        </ListItem>
                        <ListItem>
                            It can help businesses to comply with data privacy regulations such as GDPR and CCPA without undermining their ability to analyze their customer behavior. Failure to comply with these regulations can result in serious fines.
                        </ListItem>
                        <ListItem>
                            Differential privacy enables businesses to share their data with other organizations to collaborate with them without risking their customers’ privacy.
                        </ListItem>
                    </List>
                    <p><strong>Applications of Differential Privacy:</strong></p>
                    <List>
                        <ListItem>
                            U.S. Census Bureau started to use differential privacy with the ⦁	2020 Census data. The dataset contains detailed demographic information about U.S. citizens. Without a privacy measure, this information can be traced back to individuals. The Bureau states that traditional anonymization techniques became obsolete. This is because of ⦁	re-identification methods that make it possible to reveal information of a specific individual from an anonymized dataset.
                        </ListItem>
                        <ListItem>
                            Personal data such as emojis, chats, browser search queries and health information can be privatized using differential privacy.
                        </ListItem>
                        <ListItem>
                            Differential privacy is also used in applications of other privacy-preserving methods in artificial intelligence such as federated learning or synthetic data generation.
                        </ListItem>
                    </List>
                </Paragraph>
                <LinkContainer>
                    <Grid container spacing={2}>
                        <Grid item>
                            <StyledButton
                                variant="outlined"
                                color="primary"
                                size="large"
                                startIcon={<OpenInNewIcon />}
                            >
                                <a
                                    href="https://material.vlifevirtusa.com/DP_Image/DpImage.html"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Notebook
                                </a>
                            </StyledButton>
                        </Grid>
                    </Grid>
                </LinkContainer>
            </Collapse>
            <Collapse text="Demo">
                <DemoContainer>
                    <Grid
                        container
                        spacing={2}
                        direction='row'
                        justify='center'
                        alignItems='center'
                    >
                        <Grid item xs={12}>
                            <b>Choose a sample dataset</b>
                        </Grid>
                        <Grid item xs={12} sm={5} md={4}>
                            <StyledRadioGroup
                                aria-labelledby='demo-controlled-radio-buttons-group'
                                name={radioName}
                                value={radiovalue}
                                onChange={handleRadioChange}
                            >
                                <FormControlLabel
                                    name="fmnist"
                                    value='s3://vlife-applications/dp_image/mridul/input/data'
                                    control={<Radio />}
                                    label='F-MNIST : Fashion Dataset'
                                />
                                <FormControlLabel
                                    name="mnist"
                                    value='s3://vlife-applications/dp_image/mridul/input/data"'
                                    control={<Radio />}
                                    label='MNIST : Digit Dataset'
                                />
                                <FormControlLabel
                                    name="xray"
                                    value='s3://vlife-applications/dp_image/mridul/input/chest2'
                                    control={<Radio />}
                                    label='Chest-Xray Dataset'
                                />
                            </StyledRadioGroup>
                        </Grid>
                        <Grid item xs={12} sm={5} md={3}>
                            <StyledButton
                                style={{ marginTop: '0' }}
                                variant='contained'
                                color='primary'
                                onClick={getSampleUploadResult}
                            >
                                Upload
                            </StyledButton>
                        </Grid>
                    </Grid>
                    <hr />
                    {sampleResult && (
                        <section>
                            <Grow in={sampleResult} timeout={2500}>
                                <div>
                                    <Grid container xs={12} spacing={2} direction="row" justifyContent="center">
                                        <Grid item xs={12} sm={4}>
                                            <section>
                                                <StyledFigure>
                                                    <ZoomImage src={sampleImage} width='90%' height='auto' />
                                                </StyledFigure>
                                            </section>
                                        </Grid>
                                    </Grid>
                                    <Grid container xs={12} spacing={2} direction="row" alignItems='left' justify='left'>
                                        <Grid item xs={12}>
                                            <List>
                                                <ListItem><strong>Delta:</strong> Delta is a bound on the external risk that won’t be restricted by epsilon. External risk is that which inherently exists no matter what you do with your dataset. Since this is a capability demonstration of DP on vlife, we have restricted the values of Delta between 0.0001 to 0.001.</ListItem>
                                                <ListItem><strong>Noise Multiplier:</strong> Noise Multiplier is your quantitative privacy guarantee. It gives a ceiling on how much the probability of a particular output can increase if you were to add or remove a single training example. Stringent privacy needs usually require a noise multiplier value of less than one. Since this is a capability demonstration of DP on vlife, we have restricted the values of noise multiplier between 1 to 5.</ListItem>
                                            </List>
                                        </Grid>
                                    </Grid>
                                    <Grid container xs={12} spacing={2} direction='row' justify='center'
                                        alignItems='center' >
                                        <Grid item xs={10} sm={6} md={4}>
                                            <TextField
                                                name="delta"
                                                label="Delta"
                                                variant="outlined"
                                                onChange={handleInputs}
                                                fullWidth
                                                select
                                                value={columndata.delta}
                                            >
                                                {["0.0001", "0.00015", "0.0002", "0.0003", "0.001"].map((value) => (
                                                    <MenuItem value={value}>
                                                        {value}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={10} sm={6} md={4}>
                                            <TextField
                                                name="noise_multiplier"
                                                label="Noise Multiplier"
                                                variant="outlined"
                                                onChange={handleInputs}
                                                fullWidth
                                                select
                                                value={columndata.noise_multiplier}
                                            >
                                                {["1", "1.5", "2", "3", "5"].map((value) => (
                                                    <MenuItem value={value}>
                                                        {value}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} sm={5} md={3}>
                                            <StyledButton
                                                style={{ marginTop: '0' }}
                                                variant='contained'
                                                color='primary'
                                                onClick={getUploadResult}
                                            >
                                                Execute
                                            </StyledButton>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grow>
                            <hr />
                            {metricscore && (
                                <section>
                                    <Grid container xs={12} spacing={2} direction="row" justifyContent="center">
                                        <Grid item xs={12} sm={12}>
                                            <p> <strong id='title'><FontAwesomeIcon icon={faLightbulb} color='#4caf50' /> &nbsp;Model Score</strong></p>
                                            <span id='label'> <strong><u>Non Private Model Score:</u> </strong>{metricscore?.non_privatemodel_score}</span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span id='label'><strong><u>Differentially Private Model Score :</u></strong> {metricscore?.privatemodel_score}</span>
                                        </Grid>
                                        <p>As we can see, when privacy is added; the accuracy of model is compromised. In other words, if we keep increasing privacy budget (i.e., noise multiplier) accuracy of model will start going down. Hence a tradeoff between noise multiplier and accuracy is required.</p>
                                        <Grid item xs={12} sm={5} md={3}>
                                            <StyledButton
                                                style={{ marginTop: '0' }}
                                                variant='contained'
                                                color='primary'
                                                onClick={getMetricResult}
                                            >
                                                Generate Privacy Metrics
                                            </StyledButton>
                                        </Grid>
                                    </Grid>
                                    <hr />
                                    {scoreimg && (<>
                                        <Grid container xs={12} spacing={2} direction="row" justifyContent="center">
                                            <Grid item xs={12}>
                                                <p>The privacy of a machine learning model will be evaluated using the ML privacy meter package. It uses membership inference attack, where given a data point it is predicted if the data point is used in target model training or not (i.e., member or non-member).</p>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <AppBar position="static" color="default">
                                                    <StyledTabs
                                                        value={imgTabvalue}
                                                        onChange={imghandletabChange}
                                                        aria-label="simple tabs example"
                                                    >
                                                        <Tab label="Confusion Matrix" />
                                                        <Tab label="ROC Curve" />
                                                        <Tab label="Signal Histogram" />
                                                    </StyledTabs>
                                                </AppBar>
                                                <TabPanel value={imgTabvalue} index={0}>
                                                    <Grid container xs={12} spacing={3} >
                                                        <Grid item xs={12} sm={12}>
                                                            <center>
                                                                Confusion matrix plot, shows the performace of the classification model. It compares the actual target values with those predicted by the machine learning model. This gives us a holistic view of how well our classification model is performing and what kinds of errors it is making.
                                                                Data point part of training data is <u>member class.</u> Data point not part of training data is <u>non-member class.</u>
                                                            </center>
                                                        </Grid>
                                                        <Grid item xs={6} sm={6}>
                                                            <StyledFigure>
                                                                <figcaption><strong>Non Private Model</strong></figcaption>
                                                                <ZoomImage src={scoreimg?.nonPrivate?.confusion_matrix} width='100%' height='auto' />
                                                            </StyledFigure>
                                                        </Grid>
                                                        <Grid item xs={6} sm={6}>
                                                            <StyledFigure>
                                                                <figcaption><strong>Differentially Private Model</strong></figcaption>
                                                                <ZoomImage src={scoreimg?.Private?.confusion_matrix} width='100%' height='auto' />
                                                            </StyledFigure>
                                                        </Grid>
                                                    </Grid>
                                                </TabPanel>
                                                <TabPanel value={imgTabvalue} index={1}>
                                                    <Grid container xs={12} spacing={3} ><br />
                                                        <center>
                                                            ROC curve (receiver operating characteristic curve) shows the performance of the
                                                            classification model (binary classifier). Higher the AUC (area under the curve) score,
                                                            greater the data leakage from model is. A random guess has an AUC score of 0.5 i.e.,
                                                            chances of being a member or non-member is 50 per cent.

                                                        </center>
                                                        <Grid item xs={6} sm={6}>
                                                            <StyledFigure>
                                                                <figcaption><strong>Non Private Model</strong></figcaption>
                                                                <ZoomImage src={scoreimg?.nonPrivate?.roc_curve} width='100%' height='auto' />
                                                            </StyledFigure>
                                                        </Grid>
                                                        <Grid item xs={6} sm={6}>
                                                            <StyledFigure>
                                                                <figcaption><strong>Differentially Private Model</strong></figcaption>
                                                                <ZoomImage src={scoreimg?.Private?.roc_curve} width='100%' height='auto' />
                                                            </StyledFigure>
                                                        </Grid>
                                                    </Grid>
                                                </TabPanel>
                                                <TabPanel value={imgTabvalue} index={2}>
                                                    <Grid container xs={12} spacing={3} ><br />
                                                        <center>
                                                            In histogram plot, there is blue and orange histogram representing member and
                                                            non-member respectively. Higher the difference between the height of these two
                                                            histogram, greater the data leakage from the model is.

                                                        </center>
                                                        <Grid item xs={6} sm={6}>
                                                            <StyledFigure>
                                                                <figcaption><strong>Non Private Model</strong></figcaption>
                                                                <ZoomImage src={scoreimg?.nonPrivate?.signal_histogram} width='100%' height='auto' />
                                                            </StyledFigure>
                                                        </Grid>
                                                        <Grid item xs={6} sm={6}>
                                                            <StyledFigure>
                                                                <figcaption><strong>Differentially Private Model</strong></figcaption>
                                                                <ZoomImage src={scoreimg?.Private?.signal_histogram} width='100%' height='auto' />
                                                            </StyledFigure>
                                                        </Grid>
                                                    </Grid>
                                                </TabPanel>
                                            </Grid>
                                            <Grid item xs={12} sm={5} md={3}>
                                                <StyledButton
                                                    style={{ marginTop: '0' }}
                                                    variant='contained'
                                                    color='primary'
                                                    onClick={getFinalResult}
                                                >
                                                    Generate Inference
                                                </StyledButton>
                                            </Grid>
                                        </Grid>
                                        {infer && (<>
                                            <Grid container xs={12} spacing={2} direction="row" justifyContent="center">
                                                <Grid item xs={12} sm={6}>
                                                    <StyledFigure>
                                                        <figcaption><strong>Non Private Model</strong></figcaption>
                                                        <ZoomImage src={infer?.Private} width='90%' height='auto' />
                                                    </StyledFigure>
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <StyledFigure>
                                                        <figcaption><strong>Differentially Private Model</strong></figcaption>
                                                        <ZoomImage src={infer?.NonPrivate} width='90%' height='auto' />
                                                    </StyledFigure>
                                                </Grid>
                                            </Grid>
                                        </>
                                        )}
                                    </>
                                    )}
                                </section>
                            )}
                        </section>
                    )}
                    {showSpinner && <Spinner text='Loading.. Please wait for a minute...' />}
                </DemoContainer>
            </Collapse>

        </SolutionContainer>
    );
};

export default memo(ImageModel);

export const StyledRadioGroup = styled(RadioGroup)`
                display: block;
                text-align: left;
                `;

const StyledFigure = styled.figure`
  font-size: smaller;
  text-align: center;
`;