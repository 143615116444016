import React, { memo, useState } from "react";
import styled from "styled-components";
import {
  Grid,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Grow from "@material-ui/core/Grow";
import Input from "@material-ui/core/Input";

// import local components
import CommonService from "../../../utils/services/CommonService";

import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  List,
  ListItem,
} from "../../../styles/common";
import SolutionContainer from "../../common/SolutionContainerWrapper";

// images
import diabeticRetinopathy_sample1 from "../../../assets/images/app/retinopathy1.webp";
import diabeticRetinopathy_sample2 from "../../../assets/images/app/retinopathy2.webp";

const { getQueryResult, uploadFile } = CommonService("bioInformatics", "diabetic");

const DiabeticRetinopathy = () => {
  const [showSpinner, setSpinner] = useState(false);
  const [result, setResult] = useState("");
  const [uploadfile, setUploadfile] = useState("");
  const [notification, setNotification] = useState("");

  const getsampleResult = (e) => {
    setSpinner(true);
    setResult("");
    const selectedSample = e.currentTarget.getAttribute("data-sample");
    getQueryResult("sample", selectedSample)
      .then((res) => {
        setSpinner(false);
        setResult(res);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const getUploadResult = () => {
    setSpinner(true);
    setResult("");
    uploadFile("upload", uploadfile)
      .then((res) => {
        setSpinner(false);
        setResult(res);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const onChangeFile = (e) => {
    const file = e.currentTarget.files[0];
    setUploadfile(file);
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Diabetic retinopathy is a complication in which the blood vessels of the tissue at the back of the eye (the retina) are damaged. This complication is mainly observed in diabetic (Type 1, Type 2, or gestational) patients. Early symptoms of diabetic retinopathy include floaters, blurriness, dark areas of vision and difficulty perceiving colours. Poorly controlled blood sugar levels are a high-risk factor for this complication and utmost care must be taken.
          </p>
          <p>
            Diabetic retinopathy at initial stages might cause very mild to no symptoms with slight vision problems. While mild cases can be treated with careful diabetes management, advanced cases may require laser treatment or surgery.
          </p>
          <p>
            This application uses Densenet-121 model trained on the APTOS 2019 Blindness Detection dataset to predict the severity of diabetic retinopathy from retina images.
          </p>
          <p>
            The APTOS 2019 Blindness Detection dataset consists of a large number of retina images captured using fundus photography under various imaging conditions.
          </p>
          <p>Each image has been rated by a clinician for the severity of diabetic retinopathy on a scale of 0 to 4: </p>
          <List>
            <ListItem>0 - No Diabetic Retinopathy</ListItem>
            <ListItem>1 - Mild</ListItem>
            <ListItem>2 - Moderate</ListItem>
            <ListItem>3 - Severe</ListItem>
            <ListItem>4 - Proliferative Diabetic Retinopathy</ListItem>
          </List>
          <p>Just as for any real-world dataset, this dataset contains noise in both, the images and the labels as well. Some images may also contain artifacts in the background, be out of focus, underexposed, or overexposed. These images were gathered from multiple clinics with the help of a variety of cameras over an extended period of time.  </p>
          <LinkContainer>
            <Grid container spacing={2}>

              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://www.kaggle.com/c/aptos2019-blindness-detection/data"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/DiabeticRetinopathy/DiabeticRetinopathyDetection.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <br />
        <DemoContainer>
          <Grid container spacing={2} direction="row" justify="center" alignItems="center">
            <Grid item xs={10} sm={5} md={3}>
              <Image src={diabeticRetinopathy_sample1} />
              <br />
              <StyledButton
                variant="contained"
                color="primary"
                data-sample="sample/"
                onClick={getsampleResult}
              >
                Sample1
              </StyledButton>
            </Grid>
            <Grid item xs={10} sm={5} md={3}>
              <Image src={diabeticRetinopathy_sample2} />
              <br />
              <StyledButton
                variant="contained"
                color="primary"
                data-sample="sample1/"
                onClick={getsampleResult}
              >
                Sample2
              </StyledButton>
            </Grid>
            <Grid item xs={12} sm={12} md={1}>
              <h3>OR</h3>
            </Grid>
            <Grid item xs={9} sm={5} md={3}>
              <Input
                type="file"
                color="primary"
                name="uploadedFile"
                onChange={onChangeFile}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <StyledButton
                style={{ marginTop: "0" }}
                variant="contained"
                color="primary"
                onClick={getUploadResult}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
            <Grid container spacing={2} xs={12}>
              <Grid item xs={12} md={12}>
                <Grow in={result} timeout={1500}>
                  <div>
                    <section>
                      <Result>
                        <strong>Result : </strong>
                        {result}
                      </Result>
                    </section>
                  </div>
                </Grow>
              </Grid>
            </Grid>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(DiabeticRetinopathy);

const Image = styled.img`
  width: 200px;
  height: 200px;
  @media only screen and (max-width: 900px) {
    width: auto;
    max-width: 220px;
  }  @media only screen and (max-width: 450px) {
    width: auto;
    max-width: 250px;
  }
`;
