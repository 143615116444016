import React, { memo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Card,
  CardActionArea,
  CardContent,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components
import Collapse from "../../common/Collapse";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
} from "../../../styles/common";

const useStyles = makeStyles({
  root: {
    height: "100%",
    textAlign: "left",
    backgroundColor: "#0080FF",
    color: "white",
  },
  link: {
    textDecoration: "none",
    color: "white",
  },
  textCenter: {
    textAlign: "center",
  },
});

function StyledCard({ name, cardAsLink }) {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardActionArea
        className={classes.link}
        component={cardAsLink && "a"}
        href={cardAsLink}
        target="_blank"
      >
        <CardContent>
          {name && (
            <Typography
              className={classes.textCenter}
              gutterBottom
              variant="h6"
              component="h5"
            >
              {name}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

const HealthInsuranceCoverageRatePrediction = () => {
  const [notification, setNotification] = useState("");

  const data = [

    ["Distribution of Marketplace Health Insurance Coverage across states(USA)", "https://public.tableau.com/app/profile/vlife.virtusa/viz/Healthinsurancecoverage_16323997975390/Dashboard3"],
    ["Analysis of Medicaid policy enrolment across states(USA)", "https://public.tableau.com/app/profile/vlife.virtusa/viz/MedicaidHealthInsurancePolicy/Sheet1"],
    ["Relation between Marketplace tax credits and Marketplace health insurance coverage across states(USA)", "https://public.tableau.com/app/profile/vlife.virtusa/viz/RelationofMarketplaceTaxCreditswithMarketplaceHealthInsuranceCoverage/RelationofMarketplaceTaxcreditwithMarketplaceHealthInsurance"],
    ["Enrolment of health insurance policies Medicaid and Medicare", "https://public.tableau.com/app/profile/vlife.virtusa/viz/AnalysisofEnrollmentofHealthInsurancePolicies_16327273222900/Dashboard1"],
    ["Marketplace tax credit analysis across states(USA)", "https://public.tableau.com/app/profile/vlife.virtusa/viz/HealthInsuranceTaxCredit/Sheet1"],

  ];

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>The Affordable Care Act (ACA) is the name for the comprehensive healthcare reform law of the United States of America, and its amendments address health insurance coverage, healthcare costs and preventive care. The law was enacted in two parts: The patient protection and the Affordable Care Act was signed into law on March 23, 2010 by President Barack Obama, and was amended by the Health Care and Education Reconciliation Act on March 30, 2010. In the United States, there are two insurance policies: Medicare and Medicaid. Medicaid is managed by the State based on income while Medicare is managed by the Federal government and is based on the age of the individuals. But there are special circumstances, like certain disabilities, that may allow younger people to avail Medicare.</p>
          <p>This application features tableau visualizations related to the coverage of health insurance policies that come under the healthcare reform law of the United States, the Affordable Care Act (ACA). The visualizations show the enrolment distribution for the two health insurance policies (Medicare and Medicaid) across the States.  </p>
          <p>These visualizations make use of the Health Insurance Coverage dataset. Exploratory data analysis (EDA) with the help of Python library like Pandas and Numpy was performed, after which visualizations have been created by using Matplotlib, Seaborn and Tableau. </p>
          <p>These visualizations can help in deriving insights such as the following: </p>
          <ul>
            <li>The percentage distribution of Marketplace Health Insurance Coverage across the states </li>
            <li>The relation between marketplace health insurance and marketplace tax credit </li>
            <li>The difference in enrolment rate between Medicaid and Medicare across the states </li>
          </ul>
          <p>The inferences derived from these visualizations can help the governing body in making decisions regarding their respective insurance policies. For example, it can aid the state government in taking decisions regarding the expansion of the Medicaid Health Insurance policy and reduce the mortality rate of that state. </p>

        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/HealthInsuranceLead/HealthInsuranceCoverage.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.kaggle.com/eamartey/health-insurance/data"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Dataset
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container spacing={3} direction="row">
            {data.map((data) => (
              <Grid item xs={12} sm={12} md={6}>
                <StyledCard
                  name={data[0]}
                  cardAsLink={data[1]}
                />
              </Grid>
            ))}
          </Grid>
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(HealthInsuranceCoverageRatePrediction);
