import React, { memo, useState } from "react";
import styled from "styled-components";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  Grid, TextField, MenuItem, Typography, Slider,
} from "@material-ui/core";

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// local components
import Collapse from "../../common/Collapse";
import CommonService from "../../../utils/services/CommonService";
import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  ListItem,
  Result,
} from "../../../styles/common";
import getUserData, { getUploadToken } from '../../../utils';

const CpcaImages = () => {
  const initialData = {
    alphaValue: 2,
    alphaType: 'auto',
  };

  const [forefields, setForeField] = useState([]);
  const [foredataRows, setForeDataRows] = useState([]);
  const [backfields, setBackField] = useState([]);
  const [backdataRows, setBackDataRows] = useState([]);
  const [imageUrl, setImage] = useState([]);
  const [sampleresult, setSample] = useState(false);
  const [result, setResult] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const [slideparameters, setslideParameters] = useState(initialData);

  const [checked, setChecked] = useState({ asm: true, scm: true, msm: false });

  const { getQueryResult } = CommonService(
    "sailApps", "cpcaImages",
  );
  const Token = getUploadToken();

  const getSample = () => {
    setResult('');
    setSample(true);
  };

  const getResult = () => {
    setSpinner(true);
    setResult('');

    const check = []; const nonchecked = []; let
      checkedparams = [];
    Object.entries(checked).map((arr) => {
      arr[1] ? check.push(arr[0]) : nonchecked.push(arr[0]);
    });
    checkedparams = [...check, ...nonchecked];

    const isAlphaValue = slideparameters.alphaType === 'manual' ? `,${slideparameters.alphaValue}` : '';

    const params = `${checkedparams},${slideparameters.alphaType}${isAlphaValue},${Token.token}`;

    getQueryResult("withParams", params)
      .then((res) => {
        setSpinner(false);
        setResult(true);
        const foreData = JSON.parse(res.fore_data);
        const foreDatadataRows = foreData.data;
        const foreDatafields = foreData.schema.fields;
        const backData = JSON.parse(res.back_data);
        const backDatadataRows = backData.data;
        const backDatafields = backData.schema.fields;
        const imgUrl = res.image_url;
        setImage(imgUrl);
        setForeField(foreDatafields);
        setForeDataRows(foreDatadataRows);
        setBackField(backDatafields);
        setBackDataRows(backDatadataRows);
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
        setNotification({ open: "error", message: err.message });
      });
  };

  const handleCheckBox = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    const isChecked = e.target.checked;
    const valueName = `${e.target.name}Value`;

    setChecked({
      ...checked,
      [name]: isChecked,
      // [valueName]: value,
    });
  };

  const handleInputs = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setslideParameters({
      ...slideparameters,
      [name]: value,
    });
  };

  const onSliderChange = (e, value, name) => {
    setslideParameters({
      ...slideparameters,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>Contrastive principal component analysis (cPCA), identifies low-dimensional structures that are enriched in a dataset relative to comparison data. cPCA with a background dataset enables us to visualize dataset-specific patterns missed by PCA and other standard methods.</p>
          <p><strong>What is PCA?</strong></p>
          <p>PCA projects the data onto a low-dimensional space and is especially powerful as an approach to visualize patterns, such as clusters, clines, and outliers in a dataset.</p>
          <p><strong>Why cPCA?</strong></p>
          <p>PCA is designed to explore one dataset at a time. But when multiple datasets or multiple conditions in one dataset are to be compared then the current state-of practice is to perform PCA on each dataset separately, and then manually compare the various projections.</p>
          <p>Contrastive PCA (cPCA) is designed to fill in this gap in data exploration and visualization by automatically identifying the projections that exhibit the most interesting differences across datasets. The main advantages of cPCA are its generality and ease of use. </p>
          <h3>Dataset:</h3>
          <p>
            <strong>Ultrasound imaging (sonography)</strong>
            {' '}
            is a diagnostic medical procedure that uses high-frequency sound waves to produce dynamic visual images of organs, tissues or blood flow inside the body. The sound waves are transmitted to the area to be examined and the returning echoes are captured to provide the physician with a ‘live’ image of the area. Ultrasound is based on sonar, and uses a machine with a computer processor to create Ultrasound images. A transducer is placed on the area of the body to be visualized. Returning sound waves, or echoes, are processed through the computer and converted into images.
          </p>
          <p>The Dataset consisted of 100 Ultrasound images of neck. These images were manually annotated by Doctors. The annotated labels are ASM, SCM, MSM and BP. Description of each label is given below:</p>
          <ListItem>
            <strong>The Anterior Scalene Muscle (ASM)</strong>
            {' '}
            is one of the lateral muscles of the neck, belonging to the scalene group. It is deeply placed, lying behind the Sternocleidomastoid. It is located between the subclavian vein and the subclavian artery. The roots of the brachial plexus pass posterior to it.
          </ListItem>
          <ListItem>
            <strong>Sternocleidomastoid (SCM) </strong>
            is a paired superficial muscle in the anterior portion of the neck. The sternocleidomastoid muscle (SCM) is an important landmark in the neck which divides it into an anterior and a posterior triangle. This muscle binds the skull to the sternum and clavicle. It protects the vertical neurovascular bundle of neck, branches of cervical plexus, deep cervical lymph nodes and soft tissues of neck from damage.
          </ListItem>
          <ListItem>
            <strong>The Brachial Plexus (BP) </strong>
            {' '}
            passes from the neck to the axilla and supplies the upper limb. The brachial plexus is responsible for cutaneous and muscular innervation of the entire upper limb, with two exceptions: the trapezius muscle innervated by the spinal accessory nerve and an area of skin near the axilla innervated by the intercostobrachial nerve.
          </ListItem>
          <ListItem>
            <strong>Middle Scalene Muscle (MSM)</strong>
            {' '}
            is the largest and longest muscle in the scalene group of lateral neck muscles. Often penetrated by the dorsal scapular and long thoracic nerves, it is deeply placed, lying behind Sternocleidomastoid.
          </ListItem>
          <h3>Scenario: </h3>
          <p>CPCA identifies low-dimensional structures that are enriched in a dataset relative to comparison data. Two datasets are involved in this model: Target dataset and the background dataset.</p>
          <p>The background dataset is considered as noise in the dataset. Because of the differentiating between target and background model gives the directions correspond to features carried uniquely by the target dataset, and are hence are more likely to lead to meaningful discovery of the additional structure of the target data compared to the background.</p>
          <p>In this model, the user can select any of the muscles as the target dataset. The unselected muscle will be considered as the background dataset.</p>
          <p>
            <strong>Alpha:</strong>
            {' '}
            Alpha is a hyperparameter in CPCA. Each value of α yields a direction with a different trade-off between target and background variance. By altering the alpha value, the principal components can be clustered to the user’s need.
          </p>
          <p>
            <strong>Auto Mode:  </strong>
            {' '}
            Instead of choosing a single value of α, in practice, model automatically select a few distinct values in such a way that the subspaces corresponding to each value of α lie far apart from one another, as characterized by the principal angle between the subspaces. This allows model to present the user with a few scatterplots, one for each selected value of α (that the user can quickly scan), which represent the behavior of cPCA for a wide range of α values, making the overall algorithm effectively hyperparameter-free
          </p>
          <p>
            <strong>Manual mode : </strong>
            {' '}
            Each selected value of α reveals different structure within the target dataset.
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/cPCA_images/CPCA_IMAGES.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.nature.com/articles/s41467-018-04608-8"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Stanford AI : Citations
                </a>
              </StyledButton>

            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid item xs={12}>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={getSample}
            >
              Sample
            </StyledButton>
          </Grid>
          {sampleresult && (
            <section>
              <ImageDiv>
                <Grid container xs={12} spacing={2} direction="row" justify="center" alignItems="center">
                  <Grid xs={12} sm={4} md={4}>
                    <Typography variant="h6" gutterBottom>ASM</Typography>
                    <Image src=" https://vlife.virtusa.com/cPCA_images/ultrasound_images/1asm.JPEG" alt="" />
                  </Grid>
                  <Grid xs={12} sm={4} md={4}>
                    <Typography variant="h6" gutterBottom>SCM</Typography>
                    <Image src="https://vlife.virtusa.com/cPCA_images/ultrasound_images/1scm.JPEG" alt="" />
                  </Grid>
                  <Grid xs={12} sm={4} md={4}>
                    <Typography variant="h6" gutterBottom>MSM</Typography>
                    <Image src="https://vlife.virtusa.com/cPCA_images/ultrasound_images/1msm.JPEG" alt="" />
                  </Grid>
                </Grid>
              </ImageDiv>
              <Grid container xs={12} spacing={1} direction="row" justify="center" alignItems="center">
                <Grid xs={12} md={2}>
                  <h4> Select Target Classes</h4>
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                  <center>
                    <Grid container xs={12} direction="row" justify="center" alignItems="center">
                      <Grid item xs={4} sm={3} md={3}>
                        <FormControlLabel
                          control={<Checkbox checked={checked.asm} name="asm" onChange={handleCheckBox} value={checked.asm} />}
                          label="asm"
                        />
                      </Grid>
                      <Grid item xs={4} sm={3} md={3}>
                        <FormControlLabel
                          control={<Checkbox checked={checked.scm} name="scm" onChange={handleCheckBox} value={checked.scm} />}
                          label="scm"
                        />
                      </Grid>
                      <Grid item xs={4} sm={3} md={3}>
                        <FormControlLabel
                          control={<Checkbox checked={checked.msm} name="msm" onChange={handleCheckBox} value={checked.msm} />}
                          label="msm"
                        />
                      </Grid>
                    </Grid>
                  </center>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextField
                    id="outlined-basic"
                    select
                    fullWidth
                    variant="outlined"
                    label="Alpha selection Type"
                    name="alphaType"
                    onChange={handleInputs}
                    value={slideparameters.alphaType}
                  >
                    <MenuItem key="0" value="auto">Auto</MenuItem>
                    <MenuItem key="1" value="manual">Manual</MenuItem>
                  </TextField>
                </Grid>
                {(slideparameters.alphaType === 'manual') && (
                  <Grid item xs={12} sm={3}>
                    <div>
                      <Typography id="label">
                        Alpha Value:
                        <b>{slideparameters.alphaValue}</b>
                      </Typography>
                      <Slider
                        value={slideparameters.alphaValue}
                        aria-labelledby="label"
                        valueLabelDisplay="auto"
                        name="alphaValue"
                        min={0}
                        max={1000}
                        marks={[{ value: 0, label: 0 }, { value: 1000, label: 1000 }]}
                        step={1}
                        onChange={(e, val) => onSliderChange(e, val, 'alphaValue')}
                      />
                    </div>
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12}>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={getResult}
                  >
                    Submit
                  </StyledButton>
                </Grid>
              </Grid>
              {showSpinner && <Spinner text="Loading..." />}
              {result
                && (
                <section>
                  <Grid container spacing={2} xs={12} direction="row" justify="center">
                    <Grid item xs={12} sm={12} md={12}>
                      <strong>ForeGround Data</strong>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Paper>
                        <Table>
                          <TableHead>
                            <TableRow>
                              {forefields.map((field) => (
                                <StyledTableHead key={field.name} align="left"><strong>{field.name}</strong></StyledTableHead>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {foredataRows.map((row) => (
                              <TableRow key={row.index}>
                                {forefields.map((field) => (
                                  <TableCell align="left">
                                    {field.name === "index"
                                      ? row[field.name] + 1
                                      : row[field.name]}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <strong>BackGround Data</strong>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Paper>
                        <Table>
                          <TableHead>
                            <TableRow>
                              {backfields.map((field) => (
                                <StyledTableHead key={field.name} align="left"><strong>{field.name}</strong></StyledTableHead>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {backdataRows.map((row) => (
                              <TableRow key={row.index}>
                                {backfields.map((field) => (
                                  <TableCell align="left">
                                    {field.name === "index"
                                      ? row[field.name] + 1
                                      : row[field.name]}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Paper>
                    </Grid>
                  </Grid>
                  <Result><strong>Result: </strong></Result>
                  <Grid container spacing={2} xs={12} direction="row" justify="center">
                    <Grid item xs={12} sm={12} md={12}>
                      <img src={imageUrl[0]} width="75%" height="250px" />
                    </Grid>
                    <Grid item xs={10} sm={10} md={10}>
                      <img src={imageUrl[1]} width="65%" height="250px" />
                    </Grid>
                  </Grid>
                </section>
                )}
            </section>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};
export default memo(CpcaImages);

const Paper = styled.div`
  overflow: auto;
`;
const StyledTableHead = styled(TableCell)`
  background-color: #3c40af;
  color: white;
  font-weight: bold;
`;

const Image = styled.img`
  border-radius: 3px;
  height: 200px;
  width: 200px;
  margin-bottom: 5%;
  @media only screen and (max-width: 900px) {
    width: auto;
    height: auto;
    max-width: 150px;
    min-width: 150px;
    max-height: 150px;
    min-height: 150px;
  }
  @media only screen and (max-width: 450px) {
    width: auto;
    max-width: 200px;
    min-width: 200px;
    float:center;
  }
`;
const ImageDiv = styled.div`
margin-top: 3%;
margin-bottom: 3%;
`;
