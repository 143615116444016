import React, { useState, useEffect } from "react";
import SolutionContainerWrapper from "../../common/SolutionContainerWrapper";
import styled from "styled-components";
import {
  Grid,
  Typography,
  AppBar,
  Tab,
  TextField,
  Tooltip,
} from "@material-ui/core";
import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  List,
  ListItem
} from "../../../styles/common";
import { StyledTabs } from "./IOTRecipesView";
import { TabPanel } from "./JakInhibitors";
import CommonService from "../../../utils/services/CommonService";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Plot from 'react-plotly.js';

// user id
import getUserData, { getUploadToken } from "../../../utils";

export const PriorAuthorizationDemo = () => {
  const { postQueryResult } = CommonService(
    "z1dAppsv1",
    "priorAuthorization"
  );
  const Token = getUploadToken()
  const initialData = {
    Drug_id: "",
    GPI_id: "",
    NDC_id: "",
    State_id: "",
    PCN_id: "",
    Bin_id: "",
  };

  const showSample1 = () => {
    const NewDataSet = {
      Drug_id: "381",
      GPI_id: "452",
      NDC_id: "617",
      State_id: "1",
      PCN_id: "0023",
      Bin_id: "018",
    };

    setSampleData(NewDataSet);
  };

  const showSample2 = () => {
    const NewDataSet = {
      Drug_id: "739",
      GPI_id: "990",
      NDC_id: "1593",
      State_id: "1",
      PCN_id: "0008",
      Bin_id: "008",
    };

    setSampleData(NewDataSet);
  };

  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");
  const [userId, setUserId] = useState(getUserData()?.token?.emailid);

  const [value, setValue] = useState(0);
  const [sampleData, setSampleData] = useState(initialData);
  const [result, setResult] = useState();
  const [inputDate, setInputDate] = useState(""); setForeCast
  const [foreCast, setForeCast] = useState();
  const [graphValue, setGraphValue] = useState({});
  const [value1, setValue1] = useState([]);
  const [value2, setValue2] = useState([]);


  //tab
  const tabHandleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDateChange = (e) => {
    const { value } = e.target;
    setInputDate(value);
  };

  //textfield
  const onChangeFields = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  //tab 1 api integration
  const getResult = () => {

    setResult("");
    const current = new Date();

    const date = `${current.getDate()}/${current.getMonth() + 1
      }/${current.getFullYear()}`;

    setSpinner(true);

    const { Drug_id, GPI_id, NDC_id, State_id, PCN_id, Bin_id } = sampleData;

    const DrugID = `${Drug_id}`;
    const GPIID = `${GPI_id}`;
    const NDCID = `${NDC_id}`;
    const StateID = `${State_id}`;
    const PCNID = `${PCN_id}`;
    const BinID = `${Bin_id}`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setSpinner(false);
      setNotification({ open: "error", message: "Fields are empty" });
    }
    else if (`${Drug_id}` <= '0' || `${GPI_id}` <= '0' || `${NDC_id}` <= '0' || `${State_id}` <= '0' || `${PCN_id}` <= '0' || `${Bin_id}` <= '0') {
      setNotification({ open: "error", message: "Fields value should be greater then '0'" });
      setSpinner(false);
    }
    else if (`${State_id}` >= 51) {
      setNotification({ open: "error", message: "Enter Valid State Id" });
      setSpinner(false);
    } else {
      postQueryResult("output", {
        token: Token.token,
        Context_param: {
          Application_name: "prior-auth",
          Execution_time: date,
          UserID: userId,
        },
        Content_param: {
          Drug_id: DrugID,
          GPI_id: GPIID,
          NDC_id: NDCID,
          State_id: StateID,
          PCN_id: PCNID,
          Bin_id: BinID,
        },
      })
        .then((res) => {
          setNotification({ open: "success", message: res.Console });
          console.log("respinse", res);
          setResult(res.Result);
          setSpinner(false);
        })
        .catch((err) => {
          setNotification({ open: "error", message: err.message });
          setSpinner(false);
        });
    }
  };

  const getForecastResult = () => {


    setForeCast("");

    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1
      }/${current.getFullYear()}`;

    setSpinner(true);
    postQueryResult("forecast", {
      Context_param: {
        Application_name: "prior-auth",
        Execution_time: date,
        UserID: userId,
      },
      Content_param: {
        date_val: inputDate,
      },
    })
      .then((res) => {
        setSpinner(false);
        setForeCast(res.Result);
        setNotification({ open: 'success', message: res.Console });
      })
      .catch((err) => {
        setNotification({ open: 'error', message: err.message });
        setSpinner(false);
      });
  };


  return (
    <SolutionContainerWrapper snackbar={notification}>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <AppBar position="static" color="default">
                <StyledTabs
                  value={value}
                  onChange={tabHandleChange}
                  aria-label="simple tabs example"
                >
                  <Tab label="PA Acceptance" />
                  <Tab label="Volume Forecasting" />
                </StyledTabs>
              </AppBar>
            </Grid>
          </Grid>
          <br />
          <TabPanel value={value} index={0}>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={6} md={4}>
                <Tooltip title="PrescriptionID" placement="top">
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    fullWidth
                    label="Drug ID"
                    name="Drug_id"
                    onChange={onChangeFields}
                    value={sampleData.Drug_id}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Tooltip
                  title="Indicator representing generic drug information "
                  placement="top"
                >
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    fullWidth
                    label="GPI ID"
                    name="GPI_id"
                    onChange={onChangeFields}
                    value={sampleData.GPI_id}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Tooltip title="National Drug Code" placement="top">
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    fullWidth
                    label="NDC ID"
                    name="NDC_id"
                    onChange={onChangeFields}
                    value={sampleData.NDC_id}
                  />
                </Tooltip>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Tooltip title="ID of a geographical state in the US (1 to 50)" placement="top">
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    fullWidth
                    label="State ID"
                    name="State_id"
                    onChange={onChangeFields}
                    value={sampleData.State_id}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Tooltip
                  title="Processor Control Number for routing Pharmacy transaction"
                  placement="top"
                >
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    fullWidth
                    label="PCN ID"
                    name="PCN_id"
                    onChange={onChangeFields}
                    value={sampleData.PCN_id}
                  />
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Tooltip title="Bank Identification Number" placement="top">
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    fullWidth
                    label="Bin ID"
                    name="Bin_id"
                    onChange={onChangeFields}
                    value={sampleData.Bin_id}
                  />
                </Tooltip>
              </Grid>
            </Grid>
            <br />

            <Grid
              container
              spacing={2}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={12}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={showSample1}
                >
                  Sample Data1
                </StyledButton>

                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={showSample2}
                >
                  Sample Data2
                </StyledButton>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={getResult}
                  endIcon={<ArrowForwardIcon />}
                >
                  Execute
                </StyledButton>
              </Grid>
            </Grid>

            {result && (
              <>
                <Result>
                  <strong>Result: </strong>
                  <Typography>{result}</Typography>
                </Result>
              </>
            )}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {" "}
            <br /><br />
            <Grid
              container
              xs={12}
              spacing={0}
              direction="row"
              justifyContent="space-evenly"
              alignItems="flex-start"
            >
              <p>Choose the date for which daily volume is to be predicted.</p><br />
              <Grid item xs={12} sm={4}>
                <TextField
                  id='outlined-basic'
                  size='small'
                  fullWidth
                  variant='outlined'
                  label='Date'
                  name='Date'
                  type='date'
                  value={inputDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={handleDateChange}
                  format="YYYY-MM-DD"
                  InputProps={{ inputProps: {min: "2017-01-01", max: "2023-12-30" } }}
                ></TextField>
                <center><HyperText>*select a date between <strong>2017-01-01 and 2023-12-30</strong></HyperText></center>
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <StyledButton
                  style={{ marginTop: '0' }}
                  variant='contained'
                  color='primary'
                  onClick={getForecastResult}
                >
                  Execute
                </StyledButton>
              </Grid>
              <Grid item xs={12}>
                <br /><br />
                <hr></hr>
              </Grid>
            </Grid>
            {foreCast && (
              <section>
                <Grid
                  container
                  xs={12}
                  spacing={0}
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="flex-start"
                >
                  <Grid item xs={12} sm={6}>
                    <Plot
                      data={[
                        {
                          domain: { x: [0, 1], y: [0, 1] },
                          value: foreCast[0],
                          title: { text: "Number of PA Requests" },
                          type: "indicator",
                          mode: "gauge+number",
                          delta: { reference: 400 },
                          // gauge: { axis: { range: [foreCast[1], foreCast[2]] } }
                          gauge: {
                            axis: { range: [null, foreCast[2]] },
                            steps: [
                              { range: [0, foreCast[1]], color: "white" },
                              { range: [foreCast[1], foreCast[2]], color: "gray", }
                            ],
                          }
                        }
                      ]}
                      layout={{ width: 500, height: 350 }}
                    />
                    <Mvalue>Min Value : <b>{foreCast[1].toFixed(0)}  </b>                                                   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      Max Value : <b>{foreCast[2].toFixed(0)}</b></Mvalue>
                  </Grid>
                  <Grid item xs={12}>
                    <br />
                    <hr></hr>
                    <br />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Paragraph><strong>Following is the interactive line graph for the daily PA volume forecasted till 2023 with the data from 2017-2019.</strong></Paragraph>
                    <br />
                    <iframe src={"https://material.vlifevirtusa.com/PriorAuth/graph.html"} width='90%' height='600px' />
                    <br /><br />
                  </Grid>
                </Grid>
              </section>
            )}
          </TabPanel>
          <br />
          {showSpinner && <Spinner text="Loading..." />}
        </DemoContainer>
      </Collapse>
    </SolutionContainerWrapper >
  );
};

const Mvalue = styled.p`
margin-top: -10px;
    `;

const LeftText = styled.p`
    textAlign: left;
    `

const HyperText = styled(Paragraph)`
font-size: 13px;
    `;

export default function PriorAuthorizationDesc() {
  return (
    <SolutionContainerWrapper>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Prior Authorization, a.k.a. pre-certification/pre-approval, is an approval that a Provider is required to obtain from a customer's health insurance partner before prescribing a specific medication or performing a particular medical procedure. Without this prior approval, the customer's health insurance plan may not pay for their treatment, leaving the customer responsible for the complete medical bill.
          </p>
          <p>
            Health insurance companies use prior authorization requirement as a way to keep Healthcare costs in check. With prior authorization, an insurer:
          </p>
          <List>
            <ListItem>
              Makes sure that the customer really needs the medication/treatment
            </ListItem>
            <ListItem>
              Checks whether the specified treatment/procedure is recommended for their situation
            </ListItem>
            <ListItem>
              Makes sure the treatment/procedure is up-to-date with the medical problem the customer is dealing with
            </ListItem>
            <ListItem>
              Makes sure that it is the most economical treatment option available for their condition
            </ListItem>
          </List>
          <p>
            Other factors that determines whether a prior authorization request is likely to be approved/rejected are contraindications for the requested medication/treatment, the accuracy of the diagnosis for the patient, whether other medications have been tried for the same medical condition, and whether it has failed.
          </p>
          <p>This application makes use of the CoverMyMeds dataset; a dataset that consists of around 1 million simulated entries of pharmacy claims data ranging across 2016-2018.</p>
          <p>A Gradient Boost classifier has been fine tuned to predict whether a PA would be approved or not. The following features have been taken into consideration to predict whether a prior authorization request will be approved/rejected from the PoV of a Payer:</p>
          <ListItem>
            GPI ID - Indicator representing generic drug information
          </ListItem>
          <ListItem>
            Drug ID - Prescription ID
          </ListItem>
          <ListItem>
            NDC ID - National Drug Code
          </ListItem>
          <ListItem>
            PCN ID - Processor Control Number for routing Pharmacy transaction
          </ListItem>
          <ListItem>
            BIN ID - Bank Identification Number
          </ListItem>
          <ListItem>
            State ID - ID of a geographical state in a country
          </ListItem>

          <p>In addition to this, Prophet - a time series analysis tool from Facebook, has been used to forecast the daily volume of prior authorization requests placed.</p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/PriorAuth/Notebooks/PA+Acceptance.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook 1
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/PriorAuth/Notebooks/Daily+Volume+Forecast.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook 2
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/domagal9/classifymymeds/tree/main/data"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
    </SolutionContainerWrapper>
  );
}
