import React, { memo, useState } from "react";
import styled from "styled-components";
import { Grid, TextField, MenuItem } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

// local components

import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import SolutionContainer from "../../common/SolutionContainerWrapper";

import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";
import { getUploadToken } from "../../../utils";

const MirthHL7 = () => {
  const initialData = {
    id: '',
    age: '',
    dob: '',
    gender: '',
    first_name: '',
    last_name: '',
    relation_type: '',
    f_name: '',
    l_name: '',
    insurance_plan: '',
    insurance_provider: '',
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [result, SetResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "z1dApps", "mirth",
  );
  const Token = getUploadToken()

  const gender = [
    { name: 'Female', value: 'F' },
    { name: 'Male', value: 'M' },
  ];

  const showSample1 = () => {
    const NewDataSet = {
      id: '1234a',
      age: '30',
      dob: '1990-04-05',
      gender: 'M',
      first_name: 'David',
      last_name: 'Harris',
      relation_type: 'FATHER',
      f_name: 'JOHN',
      l_name: 'HARRIS',
      insurance_plan: 'pre2',
      insurance_provider: 'LIFE PRUDENT BUYER',
    };

    setSampleData(NewDataSet);
  };

  const showSample2 = () => {
    const NewDataSet = {
      id: '1234b',
      age: '20',
      dob: '2000-02-04',
      gender: 'F',
      first_name: 'Amanda',
      last_name: 'Schull',
      relation_type: 'MOTHER',
      f_name: 'SARAH',
      l_name: 'Schull',
      insurance_plan: 'pre1',
      insurance_provider: 'LIFE PRUDENT BUYER',
    };

    setSampleData(NewDataSet);
  };

  const getResult = () => {
    const {
      id, first_name, last_name, age, gender, dob, relation_type, f_name, l_name, insurance_plan, insurance_provider,
    } = sampleData;

    const params = `${id},${first_name},${last_name},${age},${gender},${dob.split('-').reverse().join('/')},${relation_type},${f_name},${l_name},${insurance_plan},${insurance_provider},${Token.token}`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });
    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      SetResultData("");
      setSpinner(true);

      getQueryResult("withParams", encodeURIComponent(params), "POST")
        .then((res) => {
          setSpinner(false);
          SetResultData(res.split('\r'));
        })
        .catch((err) => {
          setSpinner(false);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const handleInputs = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Mirth is an open source cross-platform HL7 interface engine that enables bi-directional flow
            of HL7 messages between systems and applications over multiple transports. The message flow is
            enabled by a channel creation. The following components need to be configured in the channel.
          </p>
          <p>
            <strong>Source :</strong>
            {' '}
            The Source tab specifies how the channel receives its data. Set the
            input type which is the delimited text. Input will be taken from an S3 bucket.
          </p>
          <p>
            <strong>Destination :</strong>
            The Destination tab is configured very much like the source tab,
            and specifies where to send the output message. However, there can be more than one destination for a channel.
            HL7 is chosen as the destination format which is the output. The output will be saved back into an S3 bucket.
          </p>
          <p>
            <strong>Transformer :</strong>
            {' '}
            Transformers are rules that change and rearrange the data in the source
            message to create the destination message. The content is mapped in the delimited text to the respective
            HL7 segment using a javascript code.
          </p>
          <p>
            Demographic details of the patient such as ID, First Name, Last Name, Gender, DOB will be the input and
            they will be mapped to the MSH(Message Header), PID(Patient ID) segments of the HL7 message.
          </p>
          <p>Next of Kin Details such as person's closest relative/friend will be mapped to NK1 segment. </p>
          <p>And Insurance details will be mapped to IN1 segment of the HL7 message.</p>
          <p>All these mappings are done by mirth transformer.</p>
          <p>
            HL7 provides several standards and guidelines to help software vendors and healthcare providers store
            and uniformly move data. This way, applications can use the data without using special software for conversions
            or needing to send paper copies.
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2} />
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo for Sample">
        <DemoContainer>
          <Grid container direction="row" justify="left" spacing={2}>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                label="id"
                name="id"
                fullWidth
                value={sampleData.id}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                label="age"
                name="age"
                fullWidth
                onChange={handleInputs}
                value={sampleData.age}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="gender"
                label="gender"
                value={sampleData.gender}
                onChange={handleInputs}
              >
                {gender.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                label="relation_type"
                name="relation_type"
                fullWidth
                onChange={handleInputs}
                value={sampleData.relation_type}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                label="l_name"
                name="l_name"
                fullWidth
                onChange={handleInputs}
                value={sampleData.l_name}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                label="insurance_provider"
                name="insurance_provider"
                fullWidth
                onChange={handleInputs}
                value={sampleData.insurance_provider}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                label="first_name"
                name="f_name"
                fullWidth
                onChange={handleInputs}
                value={sampleData.f_name}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                label="last_name"
                name="last_name"
                fullWidth
                onChange={handleInputs}
                value={sampleData.last_name}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                label="dob"
                name="dob"
                type="date"
                fullWidth
                onChange={handleInputs}
                value={sampleData.dob}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                label="first_name"
                name="first_name"
                fullWidth
                onChange={handleInputs}
                value={sampleData.first_name}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                label="insurance_plan"
                name="insurance_plan"
                fullWidth
                onChange={handleInputs}
                value={sampleData.insurance_plan}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={showSample1}
              >
                Sample Data1
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={showSample2}
              >
                Sample Data2
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={getResult}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
            <section>
              <Result>
                <strong>Result:</strong>
              </Result>
              <Grid container direction="row" justify="center" spacing={2}>
                <ResultGrid item xs={12} sm={8} md={6}>
                  <List>
                    {result.map((Value) => (
                      <ListItem>
                        <ListItemIcon>
                          <ArrowForwardIosIcon />
                        </ListItemIcon>
                        <ListItemText primary={Value} />
                      </ListItem>
                    ))}
                  </List>
                </ResultGrid>
              </Grid>
            </section>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(MirthHL7);

const ResultGrid = styled(Grid)`
word-break: break-all;
                `;
