import React, { memo, useState } from "react";
import {
  Grid, TextField, FormControl, MenuItem,
} from "@material-ui/core";
import styled from "styled-components";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import DataSourceIcon from "@material-ui/icons/GetApp";

// local components
import Highcharts from "highcharts";
import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  HighchartContainer,
  Result,
} from "../../../styles/common";
import getUserData, { getUploadToken } from '../../../utils';

const CervicalCancerRisk = () => {
  const initialData = {
    Schiller: "",
    hormonal: "",
    first: "",
    condylomatosis: "",
    smokes: "",
    infected: "",
    std: "",
    Age: "",
    partnersCount: "",
    pregnencyCount: "",
  };

  const boolValues = [
    { name: "True", value: 1 },
    { name: "False", value: 0 },
  ];

  const [sampleData, setSampleData] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService("bioInformatics", "cervical_cancer");
  const Token = getUploadToken()

  const showResult = () => {
    const NewDataSet = {
      Schiller: "1",
      hormonal: "1",
      first: "17",
      condylomatosis: "0",
      smokes: "0",
      infected: "0",
      std: "0",
      Age: "40",
      partnersCount: "2",
      pregnencyCount: "3",
    };
    setSampleData(NewDataSet);
  };

  const showResult2 = () => {
    const NewDataSet = {
      Schiller: "0",
      hormonal: "1",
      first: "18",
      condylomatosis: "0",
      smokes: "0",
      infected: "0",
      std: "0",
      Age: "30",
      partnersCount: "2",
      pregnencyCount: "0",
    };
    setSampleData(NewDataSet);
  };

  const getResult = () => {
    const {
      Schiller,
      hormonal,
      first,
      condylomatosis,
      smokes,
      infected,
      std,
      Age,
      partnersCount,
      pregnencyCount,
    } = sampleData;

    const params = `${Schiller},${hormonal},${first},${condylomatosis},${smokes},${infected},${std},${Age},${partnersCount},${pregnencyCount},${Token.token}`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("output", params)
        .then((res) => {
          console.log(res);
          setSpinner(false);
          setResultData(res.result);
          const params = [];
          const percentages = res.importance;
          percentages.map((arr) => {
            params.push({
              name: arr[0],
              y: arr[1],
            });
          });
          Highcharts.chart("prediction-chart", {
            chart: {
              plotShadow: false,
              type: "pie",
            },
            title: {
              text: "MAJOR CONTRIBUTING FACTORS",
            },
            tooltip: {
              pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                  enabled: true,
                  format: "<b>{point.name}</b>: {point.percentage:.1f} %",
                  style: {
                    color:
                      (Highcharts.theme
                        && Highcharts.theme.contrastTextColor)
                      || "black",
                  },
                },
              },
            },
            credits: {
              enabled: false,
            },
            series: [
              {
                colorByPoint: true,
                data: params,
              },
            ],
          });
        })
        .catch((err) => {
          setSpinner(false);
          console.log(err);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const onChangeFields = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };
  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <section>
            <p>
              Cervical cancer is the most preventable type of cancer. Cervical
              cancer starts in the cells lining the cervix -- the lower part of
              the uterus (womb). The cervix connects the body of the uterus (the
              upper part where a fetus grows) to the vagina (birth canal).
              Cancer starts when cells in the body begin to grow out of control.
              The development of normal human cells mostly depends on the
              information contained in the cells’ DNA. DNA is the chemical in
              our cells that makes up our genes, which control how our cells
              work.
            </p>
            <p>
              <b>Human papillomaviruses (HPV)</b>
              {' '}
              have two proteins known as E6
              and E7 which turn off some tumor suppressor genes, such as p53 and
              Rb. This may allow the cells lining the cervix to grow too much
              and to develop changes in additional genes, which in some cases
              can lead to cancer. But HPV is not the only cause of cervical
              cancer. Most women with HPV don’t get cervical cancer, and other
              risk factors, like smoking and HIV infection, influence which
              women exposed to HPV are more likely to develop cervical cancer.
            </p>
            <p>
              The key risk factors for cervical cancer are being identified by
              studying the factors observed after Biopsy examination. This
              Dataset is Obtained from UCI Repository.
              {" "}
            </p>
          </section>
          <LinkContainer>
            <Grid container spacing={2}>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/Cervical_cancer_classification/CervicalCancerClassification.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://www.kaggle.com/loveall/cervical-cancer-risk-classification"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Data Source
                  </a>

                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <section>
            <h5>
              Please enter the values of following factors to predict the
              probability of person affected from cervical cancer.
            </h5>
          </section>
          <br />
          <section>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Schiller"
                          name="Schiller"
                          value={sampleData.Schiller}
                          onChange={onChangeFields}
                          select
                        >
                          {boolValues.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Hormonal Contraceptives"
                          name="hormonal"
                          value={sampleData.hormonal}
                          onChange={onChangeFields}
                          select
                        >
                          {boolValues.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />
                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="First sexual intercourse"
                          name="first"
                          value={sampleData.first}
                          onChange={onChangeFields}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="STDs:condylomatosis"
                          name="condylomatosis"
                          value={sampleData.condylomatosis}
                          onChange={onChangeFields}
                          select
                        >
                          {boolValues.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Smokes"
                          name="smokes"
                          value={sampleData.smokes}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="STDs Infected"
                          name="infected"
                          value={sampleData.infected}
                          onChange={onChangeFields}
                          select
                        >
                          {boolValues.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="STDs"
                          name="std"
                          value={sampleData.std}
                          onChange={onChangeFields}
                        >
                          {boolValues.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Age"
                          name="Age"
                          value={sampleData.Age}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Number of sexual partners"
                          name="partnersCount"
                          value={sampleData.partnersCount}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Number of pregnancies"
                          name="pregnencyCount"
                          value={sampleData.pregnencyCount}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>
                <br />
                <section>
                  <BorderGray>
                    Not sure about valid data inputs? Try any of the following
                    sample data...
                    <div align="right">
                      <StyledButton
                        variant="contained"
                        color="secondary"
                        onClick={showResult}
                      >
                        Sample 1
                      </StyledButton>

                      <StyledButton
                        variant="contained"
                        color="secondary"
                        onClick={showResult2}
                      >
                        Sample 2
                      </StyledButton>
                    </div>
                  </BorderGray>
                </section>
                <br />
                <section>
                  <div align="right">
                    <StyledButton
                      variant="contained"
                      color="primary"
                      onClick={getResult}
                    >
                      Submit
                    </StyledButton>
                  </div>
                </section>
              </Grid>
              <Grid item xs={12} md={6}>
                <section>
                  <h3>Output</h3>
                </section>
                {showSpinner && <Spinner text="Loading..." />}
                {resultData && (
                  <section>
                    <Result>
                      <strong>Result:</strong>
                      {`Heart Diseases:  ${resultData}`}
                    </Result>
                    <Grid container direction="row" justify="center">
                      <Grid item xs={12}>
                        <HighchartContainer
                          id="prediction-chart"
                          display
                        />
                      </Grid>
                    </Grid>
                  </section>
                )}
              </Grid>
            </Grid>
          </section>
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(CervicalCancerRisk);

const BorderGray = styled.div`
  border: 1px #d6d6d6 solid;
  padding: 20px;
`;
