import React, { memo, useState } from 'react';
import styled from 'styled-components';
import { TextField, Grid, AppBar, Tab, MenuItem, Paper, Typography } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

// local components
import Collapse from '../../common/Collapse';
import CommonService from '../../../utils/services/CommonService';
import Spinner from '../../common/loading/Spinner';
import { StyledTabs } from './IOTRecipesView';
import SolutionContainer from '../../common/SolutionContainerWrapper';
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  ListItem,
  List,
} from '../../../styles/common';
import { AntTab, AntTabs } from './vnet/components/DataResultTab';
import { a11yProps, TabPanel } from './vnet/components/ResultPanel';
import JsonDataTable from '../../common/JsonDataTable';

// user id
import getUserData, { getUploadToken } from '../../../utils';
import { setInputData } from '../../../store/actions/vnet';


const AnomalyDetector = () => {
  const { uploadFilesWithBody, postQueryResult } = CommonService(
    'pyod1',
    'pyod1'
  );
  const { postQueryResult: postQueryRes } = CommonService(
    'pyod2',
    'pyod2'
  );
  const Token = getUploadToken();

  const [notification, setNotification] = useState('');
  const [showSpinner, setSpinner] = useState(false);

  const [columndata, setColumnData] = useState({ feature1: "", feature2: "", algorithm: "" });
  const [userId, setUserId] = useState(getUserData()?.token?.emailid);
  // upload file
  const [selectedFile, setselectedFile] = useState('');
  const [FileName, setselectedFileName] = useState('');

  // radio button
  const [radiovalue, setRadioValue] = useState('');
  const [radioName, setRadioName] = useState('');

  // tab value
  const [value, setValue] = useState(0);
  const [Antvalue, setAntValue] = useState(0);

  //data
  const [data, setData] = useState('');
  const [columnName, setColumnName] = useState([]);
  const [paths, setPath] = useState('');
  const [resimg, setResImage] = useState('');
  const [inliner, setInliner] = useState('');
  const [outliner, setOutliner] = useState('');
  const [finalImg, setFinalImg] = useState('');
  const [downloadLink, setDownloadLink] = useState('');

  const tabhandleChange = (event, newValue) => {
    setValue(newValue);
    setData("");
    setFinalImg("");
    setResImage("");
    setDownloadLink("");
  };

  const handleChange = (event, newValue) => {
    setAntValue(newValue);
  };

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
    setRadioName(event.target.name);
  };

  const handleInputs = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setColumnData({
      ...columndata,
      [name]: value,
    });
  };


  const getUploadResult = () => {

    setData("");

    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1
      }/${current.getFullYear()}`;

    if (!selectedFile) {
      setNotification({ open: 'error', message: 'please upload a file' });
      return;
    }
    setSpinner(true);
    uploadFilesWithBody('upload', {
      token: JSON.stringify(Token),
      file: selectedFile,
      Context_param: JSON.stringify({
        Application_name: 'anomaly-detector',
        UserID: userId,
        Execution_time: date,
      }),
      Content_param: JSON.stringify({ Dataset_Name: FileName }),
    })
      .then((res) => {
        setSpinner(false);
        setNotification({ open: 'success', message: res.Console });
        setData(res.Result);
        setColumnName(res.Result.columnNames);
        setPath(res.Result.path)
      })
      .catch((err) => {
        setNotification({ open: 'error', message: err.message });
        setSpinner(false);
      });
  };

  const getFeatures = () => {
    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1
      }/${current.getFullYear()}`;

    setSpinner(true);

    const {
      feature1,
      feature2,
    } = columndata;

    const features1 = `${feature1}`;
    const features2 = `${feature2}`;

    postQueryRes('getfeature', {
      token:Token.token,
      Context_param: { Application_name: 'anomaly-detector', UserID: userId },
      Content_param: {
        Dataset_Name: FileName,
        s3Path: paths,
        Feature1: features1,
        Feature2: features2,
      },
    })
      .then((res) => {
        setNotification({ open: 'success', message: res.Console });
        setResImage(res.Result);
        setSpinner(false);
      })
      .catch((err) => {
        setNotification({ open: 'error', message: err.message });
        setSpinner(false);
      });
  };

  const getFindInliner = () => {
    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1
      }/${current.getFullYear()}`;

    setSpinner(true);

    const {
      feature1,
      feature2,
      algorithm,
    } = columndata;

    const features1 = `${feature1}`;
    const features2 = `${feature2}`;
    const algorithms = `${algorithm}`;

    postQueryRes('getfindinlier', {
      token:Token.token,
      Context_param: { Application_name: 'anomaly-detector', UserID: userId },
      Content_param: {
        Dataset_Name: FileName,
        s3Path: paths,
        Feature1: features1,
        Feature2: features2,
        Algorithm: algorithms,
      },
    })
      .then((res) => {
        setNotification({ open: 'success', message: res.Console });
        setInliner(res.Result.Inlier_path);
        setOutliner(res.Result.Outlier_path);
        setFinalImg(res.Result.Image_path);
        setSpinner(false);
      })
      .catch((err) => {
        setNotification({ open: 'error', message: err.message });
        setSpinner(false);
      });
  };

  const getInlier = () => {

    setSpinner(true);
    setDownloadLink("");

    postQueryResult('signedurl', {
      token:Token.token,
      Context_param: { Application_name: 'anomaly-detector', UserID: userId },
      Content_param: {
        filePath: inliner
      },
    })
      .then((res) => {
        setNotification({ open: 'success', message: res.Console });
        setDownloadLink(res.Result);
        setSpinner(false);
      })
      .catch((err) => {
        setNotification({ open: 'error', message: err.message });
        setSpinner(false);
      });
  };

  const getOutlier = () => {

    setSpinner(true);
    setDownloadLink("");

    postQueryResult('signedurl', {
      token:Token.token,
      Context_param: { Application_name: 'anomaly-detector', UserID: userId },
      Content_param: {
        filePath: outliner
      },
    })
      .then((res) => {
        setNotification({ open: 'success', message: res.Console });
        setDownloadLink(res.Result);
        setSpinner(false);
      })
      .catch((err) => {
        setNotification({ open: 'error', message: err.message });
        setSpinner(false);
      });
  };

  const getSampleUploadResult = () => {

    setData("");

    const current = new Date();
    const date = `${current.getDate()}/${current.getMonth() + 1
      }/${current.getFullYear()}`;

    setSpinner(true);
    postQueryResult('sampleupload', {
      token: Token.token,
      Context_param: {
        Application_name: 'anomaly-detector',
        Execution_time: date,
        UserID: userId,
      },
      Content_param: {
        Dataset_Name: radioName,
        s3Path: radiovalue,
      },
    })
      .then((res) => {
        setNotification({ open: 'success', message: res.Console });
        setSpinner(false);
        setData(res.Result);
        setColumnName(res.Result.columnNames);
        setPath(res.Result.path)
      })
      .catch((err) => {
        setNotification({ open: 'error', message: err.message });
        setSpinner(false);
      });
  };

  const onChangeFile = (e) => {
    const file = e.target.files[0];
    const filename = e.target.files[0].name;
    if (file) {
      setselectedFile(file);
      setselectedFileName(filename);
      // setResponse('');
    } else {
      // setResponse('');
    }
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text='Description'>
        <Paragraph>
          <p>
            <strong>What is an Outlier:</strong> An outlier is any data point
            which differs greatly from the rest of the observations in a
            dataset. Let’s see some real-life examples to understand outlier
            detection.
          </p>
          <p>
            1. When one student averages over 90% while the rest of the class is
            at 70% – a clear outlier
          </p>
          <p>
            2. While analyzing a certain customer’s purchase patterns, it turns
            out there’s suddenly an entry for a very high value. While most of
            his/her transactions fall below Rs. 10,000,
          </p>
          <p>
            This entry is for Rs. 1,00,000. It could be an electronic item
            purchase – whatever the reason, it’s an outlier in the overall data.
            There is a plethora of reasons why outliers exist. Perhaps an
            analyst made an error in the data entry, or the machine threw up an
            error in measurement, or the outlier could even be intentional! Some
            people do not want to disclose their information and hence input
            false information in forms.
          </p>
          <p>
            Outliers are of two types: Univariate and Multivariate. A univariate
            outlier is a data point that consists of extreme values in one
            variable only, whereas a multivariate outlier is a combined unusual
            score on at least two variables. Suppose you have three different
            variables – X, Y, Z. If you plot a graph of these in a 3-D space,
            they should form a sort of cloud. All the data points that lie
            outside this cloud will be multivariate outliers.
          </p>
          <p>
            <strong>Why should we use PyOD for Outlier Detection:</strong>{' '}
            Numerous outlier detection packages exist in various programming
            languages. PyOD is a scalable Python toolkit for detecting outliers
            in multivariate data. It provides access to around 20 outlier
            detection algorithms under a single well-documented API.
          </p>
          <p>
            Existing implementations like PyNomaly are not specifically designed
            for outlier detection (though it’s still worth checking out!). To
            fill this gap, Yue Zhao, Zain Nasrullah, and Zheng Li designed and
            implemented the PyOD library.
          </p>
          <p>
            <strong>Features of PyOD:</strong>
          </p>
          <p>
            PyOD has several advantages and comes with quite a few useful
            features. Here’s my pick of the bunch
          </p>
          <p>
            1. Open Source with detailed documentation and examples across
            various algorithms
          </p>
          <p>
            2. Supports advanced models, including Neural Networks, Deep
            Learning, and Outlier Ensembles
          </p>
          <p>
            3. Optimized performance with JIT (Just in Time) and parallelization
            using numba and joblib
          </p>
          <p>4. Compatible with both Python 2 & 3</p>
          <p>
            <strong>Outlier Detection Algorithms used in PyOD:</strong>
          </p>
          <p>
            Let’s see the outlier detection algorithms that power PyOD. It’s
            well and good implementing PyOD but I feel it’s equally important to
            understand how it works underneath. This will give you more
            flexibility when you’re using it on a dataset.{' '}
          </p>
          <p>1. fitting Angle-based Outlier Detector (ABOD)</p>
          <p>2. fitting Cluster-based Local Outlier Factor (CBLOF)</p>
          <p>3. fitting Feature Bagging</p>
          <p>4. fitting Histogram-based Outlier Detection (HBOS)</p>
          <p>5. fitting Isolation Forest</p>
          <p>6. fitting K Nearest Neighbors (KNN)</p>
          <p>7. fitting Local Outlier Factor (LOF)</p>
          <p>8. fitting Minimum Covariance Determinant (MCD)</p>
          <p>9. fitting One-class SVM (OCSVM)</p>
          <p>10. fitting Principal Component Analysis (PCA)</p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant='outlined'
                color='primary'
                size='large'
                startIcon={<OpenInNewIcon />}
              >
                <a href='https://github.com/yzhao062/pyod' target='_blank' rel='noreferrer'>
                  Notebook
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant='outlined'
                color='primary'
                size='large'
                startIcon={<OpenInNewIcon />}
              >
                <a href='https://www.kaggle.com/datasets/mathchi/diabetes-data-set' target='_blank' rel='noreferrer'>
                  Data Source
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text='Demo'>
        <DemoContainer>
          <Grid container xs={12} spacing={2} direction='row'>
            <Grid item xs={12}>
              <AppBar position='static' color='default'>
                <StyledTabs
                  value={value}
                  onChange={tabhandleChange}
                  aria-label='simple tabs example'
                >
                  <Tab label='Sample' />
                  <Tab label='BYOD' />
                </StyledTabs>
              </AppBar>
            </Grid>
          </Grid>
          <MainTabPanel value={value} index={0}>
            <section>
              <Grid
                container
                spacing={2}
                direction='row'
                justify='center'
                alignItems='center'
              >
                <Grid item xs={12}>
                  <b>Choose a sample dataset</b>
                </Grid>
                <Grid item xs={12} sm={5} md={3}>

                  <StyledRadioGroup
                    aria-labelledby='demo-controlled-radio-buttons-group'
                    name={radioName}
                    value={radiovalue}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      name='diabetes.csv'
                      value='anomaly-detector/sample/diabetes.csv'
                      control={<Radio />}
                      label='Diabetes.csv'
                    />
                    <FormControlLabel
                      name='chronic_kidney_disease.csv'
                      value='anomaly-detector/sample/chronic_kidney_disease.csv'
                      control={<Radio />}
                      label='Chronic_kidney_disease.csv'
                    />
                    <FormControlLabel
                      name='IndianLiverPatientDataset.csv'
                      value='anomaly-detector/sample/IndianLiverPatientDataset.csv'
                      control={<Radio />}
                      label='IndianLiverPatientDataset.csv'
                    />
                  </StyledRadioGroup>
                </Grid>
                <Grid item xs={12} sm={5} md={3}>
                  <StyledButton
                    style={{ marginTop: '0' }}
                    variant='contained'
                    color='primary'
                    onClick={getSampleUploadResult}
                  >
                    Upload
                  </StyledButton>
                </Grid>
              </Grid>
            </section>
          </MainTabPanel>
          <TabPanel value={value} index={1}>
            <section>
              <Grid
                container
                spacing={2}
                direction='row'
                justify='center'
                alignItems='center'
              >
                <Grid item xs={12} sm={12} md={12}>
                  <Paragraph>Please upload your own dataset or download a sample dataset from the given link and upload.</Paragraph>
                </Grid>
                <br />
                <Grid item xs={9} sm={7} md={5}>
                  <Input
                    type='file'
                    color='primary'
                    name='uploadedFile'
                    onChange={onChangeFile}
                  />
                </Grid>
                <Grid item xs={3} sm={5} md={3}>
                  <StyledButton
                    style={{ marginTop: '0' }}
                    variant='contained'
                    color='primary'
                    onClick={getUploadResult}
                  >
                    Upload
                  </StyledButton>
                </Grid>
                <Grid item xs={10} sm={10} md={4}>
                  <a
                    href='https://material.vlifevirtusa.com/AnomalyDetection/sample.csv'
                    download
                    rel='noreferrer'
                  >
                    Download a Sample file
                  </a>
                </Grid>
              </Grid>
            </section>
          </TabPanel>
          {data && (
            <section>
              <br /><br />
              <Grid
                container
                spacing={2}
                xs={12}
                direction='row'
                justify='center'
                alignItems='center'
              >
                <Grid item xs={10} sm={10} md={10}>
                  <AppBar position='static' color='default'>
                    <StyledTabs
                      value={Antvalue}
                      onChange={handleChange}
                      aria-label='simple tabs example'
                      fullWidth
                      style={{
                        background: 'black',
                        color: 'white',
                        fontWeight: 'bold',
                      }}
                    >
                      <Tab label='Data Sample' />
                      <Tab label='Data Statistics' />
                      <Tab label='Data Overview' />
                    </StyledTabs>
                  </AppBar>
                </Grid>
              </Grid>
              <TabPanel value={Antvalue} index={0}>
                <Grid
                  container
                  spacing={2}
                  xs={12}
                  direction='row'
                  justify='center'
                  alignItems='center'
                >
                  <Grid item xs={10} sm={10} md={10}>
                    <JsonDataTable
                      fields={data?.dfHead?.schema?.fields}
                      dataRows={data?.dfHead?.data}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={Antvalue} index={1}>
                <Grid
                  container
                  spacing={2}
                  xs={12}
                  direction='row'
                  justify='center'
                  alignItems='center'
                >
                  <Grid item xs={10} sm={10} md={10}>
                    <JsonDataTable
                      fields={data?.dfDescribe?.schema?.fields}
                      dataRows={data?.dfDescribe?.data}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={Antvalue} index={2}>
                <Grid
                  container
                  spacing={2}
                  xs={12}
                  direction='row'
                  justify='center'
                  alignItems='center'
                >
                  <Grid item xs={10} sm={10} md={10}>
                    <OverViewList tyee='none'>
                      {Object.entries(data.dfOverview).map((data) => (
                        <ListItem>
                          {' '}
                          <FontAwesomeIcon icon={faCheckCircle} />
                          {`${data[0]} : ${data[1]}`}
                        </ListItem>
                      ))}
                    </OverViewList>
                  </Grid>
                </Grid>
              </TabPanel>
              <FeatureContainer>
                <Grid container xs={12} spacing={2} direction='row' justify='center'
                  alignItems='center' >
                  <Grid item xs={10} sm={10} md={10}>
                    <strong>Please select 2 features for identifying outliers between them </strong>
                  </Grid>
                  <Grid item xs={10} sm={6} md={4}>
                    <TextField
                      name="feature1"
                      label="Feature1"
                      variant="outlined"
                      onChange={handleInputs}
                      fullWidth
                      select
                      value={columndata.feature1}
                    >
                      {columnName.map((value) => (
                        <MenuItem value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={10} sm={6} md={4}>
                    <TextField
                      name="feature2"
                      label="Feature2"
                      variant="outlined"
                      onChange={handleInputs}
                      fullWidth
                      select
                      value={columndata.feature2}
                    >
                      {columnName.map((value) => (
                        <MenuItem value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={10} sm={10} md={3}>
                    <StyledButton
                      style={{ marginTop: '0' }}
                      variant='contained'
                      color='primary'
                      onClick={getFeatures}
                    >
                      Execute
                    </StyledButton>
                  </Grid>
                </Grid>
              </FeatureContainer>
              {resimg && (
                <section>
                  <br /><br />
                  <Grid
                    container
                    spacing={2}
                    xs={12}
                    direction='row'
                    justify='center'
                    alignItems='center'
                  >
                    <Grid item xs={10} sm={10} md={10}>
                      <img src={resimg} width="95%" height="600px" />
                    </Grid>
                    <Grid item xs={10} sm={10} md={10}>
                      <strong>Based on the plots above, please choose an algorithm to separate out the outliers and the inliers</strong>
                    </Grid>
                    <Grid item xs={10} sm={6} md={5}>
                      <TextField
                        name="algorithm"
                        label="Algorithm"
                        variant="outlined"
                        onChange={handleInputs}
                        fullWidth
                        select
                        value={columndata.algorithm}
                      >
                        {["Angle-based Outlier Detector (ABOD)", "Cluster-based Local Outlier Factor (CBLOF)", "Feature Bagging", "Histogram-base Outlier Detection (HBOS)", "Isolation Forest", "K Nearest Neighbors (KNN)", "Local Outlier Factor (LOF)", "Minimum Covariance Determinant (MCD)", "One-class SVM (OCSVM)", "Principal Component Analysis (PCA)"].map((value) => (
                          <MenuItem value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={10} sm={4} md={3}>
                      <StyledButton
                        style={{ marginTop: '0' }}
                        variant='contained'
                        color='primary'
                        onClick={getFindInliner}
                      >
                        Execute
                      </StyledButton>
                    </Grid>
                  </Grid>
                  {finalImg && (
                    <section>
                      <br /><br />
                      <Grid
                        container
                        spacing={2}
                        xs={12}
                        direction='row'
                        justify='center'
                        alignItems='center'
                      >
                        <Grid item xs={10} sm={10} md={10}>
                          <img src={finalImg} width="45%" height="250px" />
                        </Grid>
                        <Grid item xs={10} sm={6} md={4}>
                          <StyledButton
                            style={{ marginTop: '0' }}
                            variant='contained'
                            color='primary'
                            onClick={getInlier}
                          >
                            Fetch Inliers
                          </StyledButton>
                        </Grid>
                        <Grid item xs={10} sm={6} md={4}>
                          <StyledButton
                            style={{ marginTop: '0' }}
                            variant='contained'
                            color='primary'
                            onClick={getOutlier}
                          >
                            Fetch Outliers
                          </StyledButton>
                        </Grid>
                      </Grid>
                      {downloadLink && (
                        <section>
                          <br /><br />
                          <Grid
                            container
                            spacing={2}
                            xs={12}
                            direction='row'
                            justify='center'
                            alignItems='center'
                          >
                            <Grid item xs={10} sm={10} md={10}>
                              <strong>Click to download the data</strong>
                            </Grid>
                            <Grid item xs={10} sm={10} md={10}>
                              <StyledButton
                                variant='outlined'
                                color='primary'>
                                <a
                                  href={downloadLink}
                                  download
                                  rel="noopener noreferrer"
                                >
                                  Download
                                </a>
                              </StyledButton>
                            </Grid>
                          </Grid>
                        </section>
                      )}
                    </section>
                  )}
                </section>
              )}
            </section>
          )}
          {showSpinner && <Spinner text='Loading...' />}
        </DemoContainer>
      </Collapse>
    </SolutionContainer >
  );
};

function MainTabPanel(props) {
  const { children, value, index } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tablepanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <PanelContainer>{children}</PanelContainer>}
    </div>
  );
}

export default memo(AnomalyDetector);

export const StyledRadioGroup = styled(RadioGroup)`
                display: block;
                text-align: left;
                `;

const PanelContainer = styled(Paper)`
                &.MuiPaper-root {
                    padding: 15px;
  }
                `;

const OverViewList = styled(List)`
                list-style: none;
                .svg-inline--fa {
                    margin - right: 3px;
                color: #2a9d8f;
  }
                `;

const FeatureContainer = styled.div`
                margin-top: 5%;
                `;
