import React, { memo, useState } from "react";
import styled from "styled-components";
import { TextField, Grid, Divider } from "@material-ui/core";

import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import CommonService from "../../../utils/services/CommonService";
import SolutionContainer from "../../common/SolutionContainerWrapper";

// local components
import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  ListItem,
} from "../../../styles/common";
import getUserData, { getUploadToken } from '../../../utils';

// image
import SequenceGenerator_sol1 from "../../../assets/images/app/genomesequence.webp";

const SequenceGenerator = () => {
  const initialData = {
    input: "",
  };

  const [result, SetResultData] = useState(false);
  const [sampleData, setSampleData] = useState(initialData);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService("bioInformatics", "sequenceGenerator");
  const Token = getUploadToken()

  const getResult = () => {
    const {
      input,
    } = sampleData;

    const params = `${input},${Token.token}`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });
    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("output", params)
        .then((res) => {
          console.log(res);
          setSpinner(false);
          SetResultData(res.seq);
        })
        .catch((err) => {
          console.log(err);
          setSpinner(false);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const handleInputs = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>The tool - Random DNA Sequence Generator - generates a random sequence of the length specified. Random sequences can be used to evaluate the significance of sequence analysis results.</p>
          <p>Random sequences can be used to evaluate the significance of sequence analysis results.</p>
          <p>Sequence Analysis includes wide range of relevant areas of research :</p>
          <p>
            {' '}
            <ol>
              <ListItem> The comparison of sequences in order to find similarity, often to infer if they are related (homologous)</ListItem>
              <ListItem> Identification of intrinsic features of the sequence such as active sites, post translational modification sites, gene-structures, reading frames, distributions of introns and exons and regulatory elements</ListItem>
              <ListItem> Identification of sequence differences and variations such as point mutations and single nucleotide polymorphism (SNP) in order to get the genetic marker.</ListItem>
              <ListItem> Revealing the evolution and genetic diversity of sequences and organisms</ListItem>
              <ListItem> Identification of molecular structure from sequence alone</ListItem>
            </ol>
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/blob/master/Sequence_Generator/SequenceGenerator.ipynb"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container spacing={2} direction="row" justify="center" alignItems="center">
            <Grid item xs={10} sm={10} md={10}>
              <Image src={SequenceGenerator_sol1} alt="" />
            </Grid>
            <Grid item xs={10} sm={5} md={3}>
              <strong>Enter length of random sequence:</strong>
            </Grid>
            <Grid item xs={10} sm={3} md={2}>
              <TextField
                variant="outlined"
                name="input"
                label="Number"
                onChange={handleInputs}
                value={sampleData.input}
              />
            </Grid>
            <Grid item xs={10} sm={10} md={2}>
              <StyledButton
                variant="contained"
                btnColor="#023e8a"
                color="primary"
                onClick={getResult}
              >
                submit
              </StyledButton>
            </Grid>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
          <>
            <Divider />
            <WrappedResult>
                <strong>Result:</strong>
                {result}
              </WrappedResult>
          </>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(SequenceGenerator);

const Image = styled.img`
  border-radius: 3px;
  height: 150px; 
  width: 450px;
  @media only screen and (max-width: 900px) {
    width: auto;
    height: auto;
    max-width: 400px;
    max-height: 100px;
  }  @media only screen and (max-width: 450px) {
    width: auto;
    height: auto;
    max-width: 200px;
    max-height: 50px;
  }
`;
const WrappedResult = styled(Result)`
  overflow-wrap: break-word;
`;