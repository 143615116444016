import React, { memo, useState } from "react";
import { Grid, TextField, MenuItem } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import DataSourceIcon from "@material-ui/icons/GetApp";

// local components
import Highcharts from 'highcharts';
import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import CommonService from "../../../utils/services/CommonService";
import {
  Paragraph,
  StyledButton,
  HighchartContainer,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";
import getUserData, { getUploadToken } from '../../../utils';

const SuicideIdeationPrediction = () => {
  // This is the initial state of the DOM

  const { getQueryResult } = CommonService(
    "payerProvider",
    "suicideProbability",
  );
  const Token = getUploadToken()

  const initialData = {
    gender: "",
    sexuality: "",
    age: "",
    incomeCat: "",
    race: "",
    bodyWeight: "",
    isVirgin: "",
    isProstitution: "",
    isPaidSex: "",
    friends: "",
    isSocialFear: "",
    isDepressed: "",
    obtainedHelp: "",
    employement: "",
    eduLevel: "",
    selfImprovement: "",
  };

  const fields = [
    { label: "Gender", name: "gender", options: ["Male", "Female"] },
    {
      label: "Sexuality",
      name: "sexuality",
      options: ["Straight", "Bisexual", "Gay/Lesbian"],
    },
    { label: "Age", name: "age" },
    {
      label: "Income - Category",
      name: "incomeCat",
      options: [
        "$0",
        "$1-$10000",
        "$10001-$19999",
        "$20000-$29999",
        "$30000-$39999",
        "$40000-$49999",
        "$50000-$74999",
        "$75000-$99999",
        "$100000-$124999",
        "$125000-$149999",
        "$150000-$174999",
        "$175000-$199999",
        "$200000",
      ],
    },
    {
      label: "Race",
      name: "race",
      options: ["White", "Black", "Hispanic", "Asian", "Mixed", "Other"],
    },
    {
      label: "Bodyweight",
      name: "bodyWeight",
      options: ["Normal Weight", "Under weight", "Overweight"],
    },
    { label: "Virgin", name: "isVirgin", options: ["yes", "no"] },
    {
      label: "Prostitution Legal",
      name: "isProstitution",
      options: ["yes", "no"],
    },
    { label: "Paid for sex", name: "isPaidSex", options: ["yes", "no"] },
    { label: "Friends", name: "friends" },
    { label: "Social Fear", name: "isSocialFear", options: ["yes", "no"] },
    { label: "Depressed", name: "isDepressed", options: ["yes", "no"] },
    {
      label: "Obtained help from others",
      name: "obtainedHelp",
      options: ["Socializing", "No help", "Emotional Support", "Not Sure"],
    },
    {
      label: "Employment",
      name: "employement",
      options: ["Employed", "Unemployed", "Student", "Retired", "Other"],
    },
    {
      label: "Education Level",
      name: "eduLevel",
      options: ["Bachelors", "High school", "other", "Masters"],
    },
    {
      label: "Self Improvement",
      name: "selfImprovement",
      options: [
        "None",
        "Clubs and Gyms",
        "Therapy",
        "Cosmetic",
        "Medicine",
        "Socializing",
        "Other",
        "All",
      ],
    },
  ];

  const [sampleData, setSampleData] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const formHighChart = (data) => {
    const params = [];
    Object.entries(data).map((arr, index) => params.push({
      name: arr[0],
      y: arr[1],
    }));
    Highcharts.chart("container", {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
      },
      title: {
        text: "Major Contributing factors",
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
        },
      },
      series: [
        {
          name: "Brands",
          colorByPoint: true,
          data: params,
        },
      ],
    });
  };

  const showResult = () => {
    const NewDataSet = {
      gender: "0",
      sexuality: "0",
      age: "14",
      incomeCat: "11",
      race: "5",
      bodyWeight: "0",
      isVirgin: "1",
      isProstitution: "0",
      isPaidSex: "0",
      friends: "6",
      isSocialFear: "1",
      isDepressed: "1",
      obtainedHelp: "3",
      employement: "1",
      eduLevel: "0",
      selfImprovement: "3",
    };

    setSampleData(NewDataSet);
    setResultData("");
  };
  const showResult2 = () => {
    const NewDataSet = {
      gender: "1",
      sexuality: "0",
      age: "21",
      incomeCat: "0",
      race: "5",
      bodyWeight: "0",
      isVirgin: "1",
      isProstitution: "0",
      isPaidSex: "0",
      friends: "10.0",
      isSocialFear: "1",
      isDepressed: "0",
      obtainedHelp: "2",
      employement: "3",
      eduLevel: "1",
      selfImprovement: "1",
    };
    setSampleData(NewDataSet);
    setResultData("");
  };

  const getResult = () => {
    const {
      gender,
      sexuality,
      age,
      incomeCat,
      race,
      bodyWeight,
      isVirgin,
      isProstitution,
      isPaidSex,
      friends,
      isSocialFear,
      isDepressed,
      obtainedHelp,
      employement,
      eduLevel,
      selfImprovement,
    } = sampleData;

    const params = `${gender},${sexuality},${age},${incomeCat},${race},${bodyWeight},${isVirgin},${isProstitution},${isPaidSex},${friends},${isSocialFear},${isDepressed},${obtainedHelp},${employement},${eduLevel},${selfImprovement},${Token.token}`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Field(s) are empty" });
    } else {
      setSpinner(true);

      getQueryResult("output", params)
        .then((res) => {
          setSpinner(false);
          setResultData(res[0]);
          formHighChart(res[1]);
        })
        .catch((err) => {
          setSpinner(false);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const onChangeFields = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Suicide causes immeasurable pain, suffering, and loss to
            individuals, families, and communities worldwide. According to the
            World Health Organization, every year close to 800 000 people take
            their own life and there are many more people who attempt suicide .
            Every suicide is a tragedy that affects families, communities and
            entire countries and has long-lasting effects on the people left
            behind . There are many previous studies regarding people’s suicidal
            attempt. However, there is no authentic work who has worked on this
            data set. Investigating the dataset has given some unique insightful
            information. Classification and feature selection analysis was
            performed on the dataset. Important feature selection analysis
            showed that person’s age and how one improves oneself (e.g. join
            clubs, gym) are the most important features. On the other hand, in
            case of classification analysis it was found that the model can
            83.7% correctly classify the persons who have attempted to commit
            suicide and who did not.
          </p>
          <p>
            Suicide Ideation leads to suicide. With the help of basic lifestyle
            based survey data we try to analyze and predict if certain lifestyle
            choices and factors give space for suicidal thoughts/ideations. The
            solution is a pure Classification problem with binary to
            multi-category data. The solution was created using Random Forest
            Classifier along with K nearest Neighbor supervised learning
            algorithm. This Solution was developed to answer the limitations
            that has been noticed in this usecase which was pre-developed by
            researchers who created this usecase. The solution predicts which of
            the factors have a more impact on an individual to attempt suicide
            or get suicidal ideation. It is observed that factors such as self
            improvement, income, friends and gender seem to have an noticeable
            impact on the suicide ideation.
          </p>
          <p>
            <strong> Application :</strong>
            {' '}
            Based on this survey analysis,
            organizations and companies can identify if an individual has
            suicidal tendency or not. Appropriate and timely. The model findings
            will help the researchers in this area to investigate more which
            factors are the most important for suicidal attempt and also will
            help to develop a better model for predicting suicidal attempt.
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/nahidsalekin/suicide-attempt-prediction/blob/master/Towards%20Developing%20a%20Machine%20Learning%20Model%20For%20Suicidal%20Attempt%20Prediction.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/SuicideIdeationPrediction/SuicidePrevention.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container spacing={2}>
            {fields.map((field) => (
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  fullWidth
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label={field.label}
                  name={field.name}
                  value={sampleData[field.name]}
                  onChange={onChangeFields}
                  select={field.options}
                  type={field.options ? "" : "text"}
                >
                  {field.options
                    && field.options.map((value, index) => (
                      <MenuItem key={value} value={index}>
                        {value}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
            ))}
            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="secondary"
                onClick={showResult}
              >
                Sample 1
              </StyledButton>

              <StyledButton
                variant="contained"
                color="secondary"
                onClick={showResult2}
              >
                Sample 2
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={getResult}
              >
                Submit
              </StyledButton>
            </Grid>
          </Grid>

          {showSpinner && <Spinner text="Loading..." />}
          {resultData && (
            <Result>
              <strong>Result:</strong>
              {resultData}
            </Result>
          )}
          <Grid item xs={12}>
            <HighchartContainer id="container" display />
          </Grid>
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(SuicideIdeationPrediction);
