import React, { memo, useState, useEffect } from "react";
import {
  Grid,
  TextField,
  MenuItem,
  FormControl,
  AppBar,
  Tabs,
  Tab,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Plot from "react-plotly.js";

// local components
import styled from "styled-components";
import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import CommonService from "../../../utils/services/CommonService";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
} from "../../../styles/common";
import getUserData, { getUploadToken } from '../../../utils';

const PatternAnalyzer = () => {
  const initialData = {
    graphtype: "",
    disease: "",
    numberOfPatients: "",
  };
  const dropdown = {
    Gender: [
      {
        value: "stylebarplot",
        label: "Stylebar Plot",
      },
      {
        value: "stackbarplot",
        label: "Stackbar Plot",
      },
    ],
    Age: [
      {
        value: "stylebarplot",
        label: "Stylebar Plot",
      },
      {
        value: "stackbarplot",
        label: "Stackbar Plot",
      },
      {
        value: "boxplot",
        label: "Box Plot",
      },
      {
        value: "lineplot",
        label: "Line Plot",
      },
    ],
    Race: [
      {
        value: "stylebarplot",
        label: "Stylebar Plot",
      },
      {
        value: "stackbarplot",
        label: "Stackbar Plot",
      },
    ],
    Medication: [
      {
        value: "stylebarplot",
        label: "Stylebar Plot",
      },
      {
        value: "stackbarplot",
        label: "Stackbar Plot",
      },
    ],
    Condition: [
      {
        value: "stylebarplot",
        label: "Stylebar Plot",
      },
      {
        value: "stackbarplot",
        label: "Stackbar Plot",
      },
    ],
  };

  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [disease, setDisease] = useState("");
  const [proofVector, setProofVector] = useState("Gender");
  const [rowData, setRowData] = useState([]);
  const [commmonDropDownList, setCommonDropdown] = useState(dropdown.Gender);
  const [response, setResponse] = useState("");
  const [inputFields, setInputValues] = useState(initialData);
  const [renderGraph, setRenderGraph] = useState(false);
  const [renderTable, setRenderTable] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [tabValue, SetTab] = useState(0);

  const { getQueryResult } = CommonService("comparetool", "patternAnalyzer");
  const Token = getUploadToken()

  const onChangeFields = (event) => {
    setInputValues({ disease: event.target.value, numberOfPatients: "", graphtype: "" });
    if (event.target.value === "heartdiseases" || event.target.value === "lungcancer") {
      setDisabled(true);
      setSpinner(false);
      setRenderGraph(false);
      setRenderTable(false);
    } else {
      setDisabled(false);
      setSpinner(false);
      setRenderGraph(false);
      setRenderTable(false);
    }
  };

  const handleChange = (event, value) => {
    console.log(value);
    let list = [];
    setRenderTable(false);
    setRenderGraph(false);
    setSpinner(false);

    if (value === 0) {
      setProofVector("Gender");
      list = dropdown.Gender;
    } else if (value === 1) {
      setProofVector("Age");
      list = dropdown.Age;
    } else if (value === 2) {
      setProofVector("Race");
      list = dropdown.Race;
    } else if (value === 3) {
      setProofVector("Medication");
      list = dropdown.Medication;
    } else if (value === 4) {
      setProofVector("Condition");
      list = dropdown.Condition;
    }
    setCommonDropdown(list);
    SetTab(value);
    setInputValues({ ...inputFields, graphtype: "", numberOfPatients: "" });
  };

  const updateInputValue = (evt) => {
    setInputValues({ ...inputFields, numberOfPatients: evt.target.value });
  };

  const onChangeDropdown = (event) => {
    const val = event.target.value;
    setInputValues({ ...inputFields, graphtype: event.target.value });
    if (val != null) {
      setRenderTable(false);
      setRenderGraph(false);
      const parameterString = `vector=${proofVector}&graph=${val}&limit=${inputFields.numberOfPatients}&disease=${inputFields.disease},${Token.token}`;
      console.log("paremeters", parameterString);
      let areValuesEmpty = false;
      Object.keys(inputFields).forEach((key) => {
        if (key !== 'graphtype' && inputFields[key] === "") {
          areValuesEmpty = true;
        }
      });
      if (areValuesEmpty) {
        setNotification({ open: "error", message: "Fields are empty" });
      } else {
        setSpinner(true);
        getQueryResult("output", parameterString)
          .then((resp) => {
            console.log(resp);
            setResponse(resp);
            createRowData(val, proofVector, resp.data);
            setSpinner(false);
          })
          .catch((err) => {
            setSpinner(false);
            console.log(err);
            setNotification({ open: "error", message: err.message });
          });
      }
    }
  };

  const createRowData = (graphtype, vector, data) => {
    // console.log(data[0].name);
    setRowData([]);
    switch (graphtype) {
      case "stylebarplot":
        if (vector === "Medication" || vector === "Condition") {
          for (let i = 0; i < data[0].x.length; i++) {
            for (let j = 0; j < data[0].x.length; j++) {
              if (data[0].x[i] === data[1].x[j]) {
                rowData.push(
                  <TableBody>
                    <TableCell>{data[0].x[i]}</TableCell>
                    <TableCell>{data[1].y[j]}</TableCell>
                    <TableCell>{data[0].y[i]}</TableCell>
                  </TableBody>,
                );
              }
            }
          }
        } else {
          for (let i = 0; i < data[0].x.length; i++) {
            rowData.push(
              <TableBody>
                <TableCell>{data[0].x[i]}</TableCell>
                <TableCell>{data[0].y[i]}</TableCell>
                <TableCell>{data[1].y[i]}</TableCell>
              </TableBody>,
            );
          }
        }
        setSpinner(false);
        setRenderTable(true);
        setRenderGraph(true);
        break;
      case "stackbarplot":
        if (vector === "Medication" || vector === "Condition") {
          for (let i = 0; i < data.length; i++) {
            rowData.push(
              <TableBody>
                <TableCell>{data[i].name}</TableCell>
                <TableCell>{data[i].y[1]}</TableCell>
                <TableCell>{data[i].y[0]}</TableCell>
              </TableBody>,
            );
          }
        } else {
          for (let i = 0; i < data.length; i++) {
            rowData.push(
              <TableBody>
                <TableCell>{data[i].name}</TableCell>
                <TableCell>{data[i].y[0]}</TableCell>
                <TableCell>{data[i].y[1]}</TableCell>
              </TableBody>,
            );
          }
        }
        setSpinner(false);
        setRenderTable(true);
        setRenderGraph(true);
        break;
      case "boxplot":
        for (let i = 0; i < data[0].y.length; i++) {
          rowData.push(
            <TableBody>
              <TableCell />
              <TableCell>{data[1].y[i]}</TableCell>
              <TableCell>{data[0].y[i]}</TableCell>
            </TableBody>,
          );
        }
        setSpinner(false);
        setRenderTable(true);
        setRenderGraph(true);
        break;
      case "lineplot":
        for (let i = 0; i < data[0].x.length; i++) {
          rowData.push(
            <TableBody>
              <TableCell />
              <TableCell>
                {' '}
                {data[0].y[i]}
                {' '}
              </TableCell>
              <TableCell>
                {' '}
                {data[1].y[i]}
                {' '}
              </TableCell>
            </TableBody>,
          );
        }
        setSpinner(false);
        setRenderTable(true);
        setRenderGraph(true);
        break;
      default:
        break;
    }
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Identify, compare and analyze the commonalities and variations
            between 2 different datasets across five different proof vectors
          </p>

          <Grid container>
            <Grid item xs={12} md={6}>
              <div>
                <b>Why Pattern Analyzer?​</b>

                <p>
                  In today’s data abundance every researcher comes across
                  multiple datasets of EMR. It becomes highly important to chalk
                  out the variations between the datasets.
                </p>
              </div>

              <div>
                <b>What Pattern Analyzer offers?</b>

                <ul>
                  <li>
                    Choice of proof vector to compare across the datasets
                  </li>
                  <li>
                    Plethora of visualizations to draw insights from each
                    dataset independently
                  </li>
                  <li>User can choose the size of data​​</li>
                  <li>
                    Pattern analyzer offers multiple drill downs from each
                    visualization
                  </li>
                </ul>
              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              <div>
                <b>Working with Pattern Analyzer</b>

                <ul>
                  <li>
                    To have meaningful insights, it is advised to have 2
                    different EMR datasets
                  </li>
                  <li>
                    The column names in both datasets should match on the
                    vectors to be compared
                  </li>
                  <li>
                    Once both datasets are uploaded, choose the data size for
                    comparison
                  </li>
                  <li>
                    Pattern Analyzer is a self explanatory tool and easy to use
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.kaggle.com/datasets/rashikrahmanpritom/heart-attack-analysis-prediction-dataset"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/HeartAttack/HeartAttackAnalysis.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <div>
            <Grid container direction="row" justify="center" spacing={3}>
              <Grid item xs={12} md={4}>
                <FormControl minWidth="90" varient="outlined" fullWidth>
                  <TextField
                    id="outlined-adornment-weight"
                    select
                    variant="outlined"
                    label="Select a disease"
                    name="disease"
                    value={inputFields.disease}
                    onChange={onChangeFields}
                  >
                    <MenuItem value="t2d">T2D</MenuItem>
                    <MenuItem value="heartdiseases">Heart Diseases</MenuItem>
                    <MenuItem value="lungcancer">Lung Cancer</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={10}>
                <AppBar position="static" color="default">
                  <StyledTabs
                    value={tabValue}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                    aria-label="disabled tabs example"
                    centered
                  >
                    <Tab label="Gender" />
                    <Tab label="Age" />
                    <Tab label="Race" />
                    <Tab label="Medication" disabled={disabled} />
                    <Tab label="Condition" disabled={disabled} />
                  </StyledTabs>
                </AppBar>
              </Grid>
            </Grid>
            <br />
            <br />

            {renderTable && (
              <div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Proof Vector Types</TableCell>
                      <TableCell align="left">Synthetic Data</TableCell>
                      <TableCell align="left">Real World Data</TableCell>
                    </TableRow>
                  </TableHead>
                  {rowData}
                </Table>
              </div>
            )}
          </div>
          <Grid container direction="row" justify="center">
            <Grid item xs={12} md={4}>
              <FormControl minWidth="90" varient="outlined" fullWidth>
                <TextField
                  label="Enter number of patients to compare"
                  name="numberOfPatients"
                  type="number"
                  onChange={updateInputValue}
                  value={inputFields.numberOfPatients}
                  margin="normal"
                />
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  select
                  variant="outlined"
                  label="Select a graph type"
                  name="graphtype"
                  value={inputFields.graphtype}
                  onChange={onChangeDropdown}
                >
                  {commmonDropDownList.map((data) => (
                    <MenuItem value={data.value}>{data.label}</MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            {renderGraph && (
              <Grid item xs={12}>
                <Plot data={response.data} layout={response.layout} />
              </Grid>
            )}
          </Grid>
          <section>{showSpinner && <Spinner text="Loading..." />}</section>

        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(PatternAnalyzer);

const StyledTabs = styled(Tabs)`
@media only screen and (max-width: 950px) {
  .MuiTabs-flexContainer{
    flex-wrap: wrap;
    .PrivateTabIndicator-colorPrimary-7{
      display: none;
    }
  }
}
@media only screen and (max-width: 650px) {
  background-color: #ffff;
  font-size: 12px;
  .MuiTabs-flexContainer{
    flex-direction: column;
    .MuiTab-textColorPrimary.Mui-selected{
      border: 1px solid #3f51b5;
    }
  }
`;
