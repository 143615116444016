import React, { memo, useState } from "react";
import styled from "styled-components";
import {
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Grow from "@material-ui/core/Grow";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

// import local components
import CommonService from "../../../utils/services/CommonService";
import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";
import SolutionContainer from "../../common/SolutionContainerWrapper";

// images
import federatedLearningMedicalData_sol1 from "../../../assets/images/app/fl-img1.webp";
import federatedLearningMedicalData_sol2 from "../../../assets/images/app/fl-img2.webp";
import federatedLearningMedicalData_sol3 from "../../../assets/images/app/fl-img3.webp";
import federatedLearningMedicalData_sol4 from "../../../assets/images/app/fl-img4.webp";
import federatedLearningMedicalData_sample1 from "../../../assets/images/app/fl-sample1.webp";
import federatedLearningMedicalData_sample2 from "../../../assets/images/app/fl-sample2.webp";

const { uploadFile } = CommonService("z1dApps", "federatedLearning");

const FederatedLearningMedicalData = () => {
  const [showSpinner, setSpinner] = useState(false);
  const [startCapture, setStartCapture] = useState(false);
  const [result, setResult] = useState("");
  const [uploadfile, setUploadfile] = useState("");
  const [notification, setNotification] = useState("");

  const getsample1 = () => {
    setStartCapture(false);
    setResult("The Diagnosis from Input Image is Moderate DR");
  };
  const getsample2 = () => {
    setStartCapture(false);
    setResult("The Diagnosis from Input Image is Proliferative DR ");
  };

  const getUploadResult = (uploadfile) => {
    setSpinner(true);
    setResult("");
    uploadFile("upload", uploadfile)
      .then((res) => {
        setSpinner(false);
        setResult(res);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const resetCapture = () => {
    setStartCapture(false);
    setUploadfile("");
    setResult("");
  };

  const onChangeFields = (e) => {
    const uploadedFile = e.currentTarget.files[0];
    const reader = new FileReader();
    const url = reader.readAsDataURL(uploadedFile);

    reader.onloadend = function (e) {
      setUploadfile([reader.result]);
      setStartCapture(true);
    }.bind();
    getUploadResult(uploadedFile);
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p><strong>How Federated Learning Works </strong></p>
          <p>Assume three hospitals decide to team up and build a model to help automatically analyse brain tumour images. If they chose to work with a client-server federated approach, a centralized server would maintain the global deep neural network and each participating hospital would be given a copy to train on their own dataset.</p>
          <center>
            <Grid item xs={10} sm={8} md={8}>
              <img width="100%" src={federatedLearningMedicalData_sol1} alt="" />
            </Grid>
          </center>
          <p>
            Once the model had been trained locally for a couple of iterations, the participants would send their updated version of the model back to the centralized server and keep their dataset within their own secure infrastructure. The central server would then aggregate the contributions from all the participants. The updated parameters would then be shared with the participating institutes, so that they could continue local training. And this process continues until the global model achieves clinical-grade accuracy.
            If one of the hospitals decided it wanted to leave the training team, this would not halt the training of the model, as it’s not reliant on any specific data. Similarly, a new hospital could choose to join the initiative at any time.
          </p>
          <p>
            {' '}
            <strong> Implementation</strong>
          </p>
          <p>While a vast amount of valuable medical data is available in institutions distributed across states and countries, the patients’ privacy requirements are strong barriers against utilizing the patient’s data for improving medical diagnostic models. We propose Dopamine, a system that enables privacy-preserving training of deep neural networks on distributed medical images. Dopamine introduces a customization of differentially-private stochastic gradient descent for federated learning that, in combination with secure multi-party aggregation, achieves accurate model training while providing well bounded privacy guarantees for patients. Experimental results on the diabetic retinopathy task shows that Dopamine provides a similar differential privacy guarantee as the centralized training counterpart, while achieving a better classification accuracy than federated learning with parallel differential privacy.</p>
          <p>The Following Graph shows comparison of classification accuracy vs. differential privacy bound on Diabetic Retinopathy dataset. The graph shows that Dopamine achieves a very close differential privacy bound, of the centralized training counterpart, while it also gets better classification accuracy than federated learning with parallel differential privacy.</p>
          <center>
            <Grid container alignItems="center">
              <Grid item xs={12} sm={6} md={6}>
                <img width="100%" src={federatedLearningMedicalData_sol2} alt="" />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <img width="100%" src={federatedLearningMedicalData_sol3} alt="" />
              </Grid>
            </Grid>
          </center>
          <p>
            {' '}
            <strong> Data</strong>
          </p>
          <p>Dataset related to Diabetic Retinopathy (DR) is used to simulate Federated Learning. The Dataset contains 3600 Images of which around 3000 images are used for training purpose. These images are labelled in five classes which are - No DR, Mild DR, Moderate DR, Severe DR and Proliferative DR. For federated scenarios, the dataset is divided among 10 hospitals in equal manner, thus each hospital owns about 300 images. Each hospital performs 5 local epochs at each global epoch, and at each round 50% of the hospitals are randomly chosen to participate. </p>
          <p>The Below diagram shows the entire Federated Learning taking the example of two hospitals at a time. The models are first trained locally by the hospitals and then it is sent to the global server where secure aggregation of model weights takes place</p>
          <center>
            <Grid item xs={10} sm={8} md={8}>
              <img width="100%" src={federatedLearningMedicalData_sol4} alt="" />
            </Grid>
          </center>
          <p>
            {' '}
            <strong> Shapley</strong>
          </p>
          <p>Shapley is an algorithm to quantify the value of each training data to the predictor performance. It identifies and mathematically reduces noise from large training datasets. The datapoints having negative Shapley values are treated having noise and are removed from training set and model is retrained with remaining data. In this use case, around 35% of the training data is removed that had negative Shapley values and model is retrained and there was a slight increase in accuracy as compared to the initial model. </p>
          <LinkContainer>
            <Grid container spacing={2}>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/Federated%20Learning/Federated_Learning.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Paragraph><strong>A global agent contains the model used for diagnosis. When you upload your scan, the agent sends its model to this app and inference/prediction is performed. This method keeps your data on your own device, and hence private.</strong></Paragraph>
          <Grid container spacing={2} direction="row" justify="center" alignItems="center">
            <Grid item xs={10} sm={6} md={4}>
              <Paper elevation={3}>
                <ImagePaper>
                  <Image src={federatedLearningMedicalData_sample1} />
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={getsample1}
                  >
                    Sample1
                  </StyledButton>
                </ImagePaper>
              </Paper>
            </Grid>
            <Grid item xs={10} sm={6} md={4}>
              <Paper elevation={3}>
                <ImagePaper>
                  <Image src={federatedLearningMedicalData_sample2} />
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={getsample2}
                  >
                    Sample2
                  </StyledButton>
                </ImagePaper>
              </Paper>
            </Grid>
            <Grid item xs={10} sm={10} md={4}>
              <Paper elevation={3}>
                <ImagePaper>
                  {startCapture ? (
                    <>
                      <Image src={uploadfile} alt="" />
                      <StyledButton
                        variant="contained"
                        color="primary"
                        onClick={resetCapture}
                      >
                        Try Different Image
                      </StyledButton>
                    </>
                  ) : (
                    <section>
                      <br />
                      <br />
                      <br />
                      <br />
                      <Typography variant="h6">
                        <FontAwesomeIcon icon={faInfoCircle} />
                        {' '}
                        Upload Image
                      </Typography>
                      <HiddenInput
                        name="uploadedFile"
                        onChange={onChangeFields}
                        id="icon-button-file"
                        type="file"
                      />
                      <label
                        variant="contained"
                        color="primary"
                        htmlFor="icon-button-file"
                      >
                        <UploadIcon fontSize="large" />
                      </label>
                    </section>
                  )}
                </ImagePaper>
              </Paper>
            </Grid>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
          <Grid container XS={12}>
            <Grid item xs={12} md={12}>
              <Grow in={result} timeout={1500}>
                <ResultDiv>
                  <Result>
                    <strong>Result : </strong>
                    <h3>{result}</h3>
                  </Result>
                </ResultDiv>
              </Grow>
            </Grid>
          </Grid>
          )}
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(FederatedLearningMedicalData);

const ImagePaper = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  @media only screen and (max-width: 450px) {
    padding-top: 0;
    height: auto;
    max-height: 300px;
    padding-bottom: 10px;
    .MuiTypography-root {
      font-size: 1rem;
    }
  }
`;

const HiddenInput = styled.input`
  display: none;
`;
const UploadIcon = styled(CloudUploadIcon)`
  cursor: pointer;
  margin-top: 20px;
`;

const Image = styled.img`
  width: 250px;
  height: 200px;
  padding: 3% 3% 3% 3%;
  @media only screen and (max-width: 900px) {
    width: auto;
    max-width: 240px;
  }  @media only screen and (max-width: 450px) {
    width: auto;
    max-width: 200px;
  }
`;
const ResultDiv = styled.div`
 margin-top: 5%;
`;
