import React, { memo, useState, useEffect } from "react";
import { Grid, Typography, TextField } from "@material-ui/core";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  Heading4,
} from "../../../styles/common";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import styled from "styled-components";

// import local components
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";

// user id
import getUserData, {getUploadToken} from "../../../utils";

const ProteinDeepLearning = () => {
  const initialData = {
    seq: "",
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [result, SetResultData] = useState("");
  const [userId, setUserId] = useState(getUserData()?.token?.emailid);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { postQueryResult } = CommonService("z1dApps", "proteinDeepLearning");
  const Token = getUploadToken()

  const handleInputs = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    // const re = /^[A-Za-z]+$/;
    let reg = /[^!@#$%^&*()bjouxzBJOUXZ0-9]+$/;

    if (value === "" || reg.test(value)) {
      setSampleData({
        ...sampleData,
        [name]: value.toUpperCase(),
      });
    }
  };

  const showResult = () => {
    const NewDataSet = {
      seq: "MRIILLGAPGAGKGTQAQFIMEKYGIPQISTGDMLRAAVKSGSELGKQAKDIMDAGKLVTDELVIALVKERIAQEDCRNGFLLDGLPRTIPQADAMKEAGINVDYVLEFDVPDELIVDRIVGRRVHAPSGRVYHVKFNPPKVEGKDDVTGEELTTRKDDQEETVRKRLVEYHQMTAPLIGYYSKEAEAGNTKYAKVDGTKPVAEVRADLEKILG",
    };
    setSampleData(NewDataSet);
  };
  const showResult2 = () => {
    const NewDataSet = {
      seq: "TEFKAGSAKKGLTLFKTRCLQCHTVEKGGPHKVGPNLHGIFGRHSGQAEGYSYTDANIKKNVLWDENNMSEYLTNPKKYIPGTKMAFGGLKKEKDRNDLITYLKKACE",
    };
    setSampleData(NewDataSet);
  };
  const submitHandler = () => {
    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;

    setSpinner(true);

    const { seq } = sampleData;

    const Seq = `${seq}`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });
    if (areValuesEmpty) {
      setSpinner(false);
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      
      postQueryResult("getResult", {
        token: Token.token,
        Context_param: {
          Application_name: "Melting Temperature - Protein Sequence",
          Execution_time: date,
          UserID: userId,
        },
        Content_param: {},
        seq: Seq,
      })
        .then((res) => {
          setNotification({ open: "success", message: res.Console });
          console.log(res.Result);
          SetResultData(res.Result);
          setSpinner(false);
        })
        .catch((err) => {
          setNotification({ open: "error", message: err.message });
          setSpinner(false);
        });
    }
  };

  const resetHandler = () => {
    setSampleData(initialData);
    SetResultData(false);
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            A protein's melting point is a crucial trait that is used for many
            things, including the production of drugs. Currently, a variety of
            laboratory techniques, including Differential Scanning Calorimetry,
            Circular Dichroism, Fourier transform infrared spectroscopy, and
            others, are used to measure the melting temperature of proteins.
            These procedures are pricy and time-consuming. So, based on the
            amino acid sequence of a protein, we offer a unique deep learning
            tool for estimating protein melting temperature. Designing stable
            proteins for specific use at a desired temperature involves an
            understanding of the factors that will affect protein stability. The
            melting temperature of these proteins represents a very large
            spectrum and varies between 25°C and 113°C. Therefore, by taking a
            considerably large dataset of protein that includes experimental
            information on 1626-point mutations that have been introduced in
            about 90 proteins.
          </p>
          <p>
            Proteins of both hyperthermophilic and mesophilic microorganisms
            generally constitute the same 20 amino acids. A hyperthermophile is
            an organism that thrives in extremely hot environments—from 60 °C
            upwards. A mesophile is an organism that grows best in moderate
            temperatures, neither too hot nor too cold, with an optimum growth
            range from 20 to 45 °C. The extent of a protein's temperature
            tolerance, however, depends on the amino acid sequence of that
            protein. The temperature tolerance or the melting temperature of
            protein is important because it’s a crucial trait that is used for
            many things, including the production of various drugs development.{" "}
          </p>
          <p>
            Currently predicting the melting temperature of protein using a
            variety of laboratory techniques, including Differential Scanning
            Calorimetry, Circular Dichroism, Fourier transform infrared
            spectroscopy and others. These laboratory techniques are costly and
            sometimes time-consuming. As for the solution, TSPDL is delivering
            using bioinformatics and deep learning technology to develop a model
            to predict the melting temperature of proteins based on a given
            protein's amino acid sequence. This also allowed the designing of
            stable proteins for specific use at the desired temperature involves
            an understanding of the factors that will affect protein stability.{" "}
          </p>
          <p>
            The melting temperature of these proteins represents a very large
            spectrum and varies between 25°C and 113°C. Therefore, by taking a
            considerably large dataset of proteins that includes experimental
            information on 1626-point mutations that have been introduced in
            about 90 proteins.
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/ThermalStabProtein%20/TSPDL%20Notebook.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.bioinformatics.org/sms/iupac.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  More Info
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://aip.scitation.org/doi/10.1063/1.4947493"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Dataset
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container direction="row" justify="center" spacing={2}>
            <Grid item xs={12}>
              <br />
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <TextField
                rows="8"
                multiline
                variant="outlined"
                name="seq"
                fullWidth
                type="text"
                onChange={handleInputs}
                // onKeyDown={disableLetters}
                label="Please Input the Protein Sequence"
                value={sampleData.seq}
              />
            </Grid>

            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={showResult}
              >
                Sample Data 1
              </StyledButton>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={showResult2}
              >
                Sample Data 2
              </StyledButton>

              <StyledButton
                id="btn1"
                variant="contained"
                color="secondary"
                data-sample="sample1"
                onClick={submitHandler}
              >
                Submit
              </StyledButton>

              <StyledButton
                id="btn1"
                variant="contained"
                color="secondary"
                data-sample="sample1"
                onClick={resetHandler}
              >
                Reset
              </StyledButton>
            </Grid>
          </Grid>

          {result && (
            <Result>
              <strong>Result</strong>
              <Typography>{result} </Typography>
            </Result>
          )}

          {showSpinner && <Spinner text="Loading..." />}
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(ProteinDeepLearning);
