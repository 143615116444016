import React, { memo, useState } from 'react';
import { Grid, TextField, FormControl, MenuItem } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

// local components

import Highcharts from 'highcharts';
import Collapse from '../../common/Collapse';
import SolutionContainer from '../../common/SolutionContainerWrapper';
import Spinner from '../../common/loading/Spinner';
import CommonService from '../../../utils/services/CommonService';
import {
  Paragraph,
  StyledButton,
  HighchartContainer,
  DemoContainer,
  LinkContainer,
  Result,
} from '../../../styles/common';
import getUserData, { getUploadToken } from '../../../utils';

const BreastCancerProteomes = () => {
  const initialData = {
    XP_003960579: '',
    NP_057395: '',
    NP_001186814: '',
    NP_001121369: '',
    NP_056087: '',
    NP_057572: '',
    NP_976075: '',
  };

  const [parameters, setParameters] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState('');

  const { getQueryResult } = CommonService(
    'clinicalTrials',
    'breastCancerProteomes'
  );
  const Token = getUploadToken();

  const formHighChart = (data) => {
    Highcharts.chart('container_1', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      title: {
        text: 'MAJOR CONTRIBUTING FACTORS',
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          },
        },
      },
      series: [
        {
          name: 'feature importance',
          colorByPoint: true,
          data: [
            {
              name: data[0][0],
              y: data[0][1],
            },
            {
              name: data[1][0],
              y: data[1][1],
            },
            {
              name: data[2][0],
              y: data[2][1],
            },
            {
              name: data[3][0],
              y: data[3][1],
            },
            {
              name: data[4][0],
              y: data[4][1],
            },
            {
              name: data[5][0],
              y: data[5][1],
            },
            {
              name: data[6][0],
              y: data[6][1],
            },
          ],
        },
      ],
    });
  };

  const showResult = () => {
    const NewDataSet = {
      NP_057395: '-2.460747',
      NP_001186814: '-2.724811',
      NP_001121369: '-1.869631',
      NP_056087: '-1.869631',
      NP_057572: '-2.756305',
      XP_003960579: '-5.847549',
      NP_976075: '-3.635710',
    };
    setParameters(NewDataSet);
  };
  const showResult2 = () => {
    const NewDataSet = {
      NP_057395: '-0.047050',
      NP_001186814: '-0.722276',
      NP_001121369: '1.015439',
      NP_056087: '1.015439',
      NP_057572: '0.770504',
      XP_003960579: '1.048538',
      NP_976075: '-0.047050',
    };
    setParameters(NewDataSet);
  };
  const getResult = () => {
    setResultData('');

    const {
      XP_003960579,
      NP_057395,
      NP_001186814,
      NP_001121369,
      NP_056087,
      NP_057572,
      NP_976075,
    } = parameters;

    const params = `${NP_057395},${NP_001186814},${NP_001121369},${NP_056087},${NP_057572},${XP_003960579},${NP_976075},${Token.token}`;
    let areValuesEmpty = false;
    Object.keys(parameters).forEach((key) => {
      if (parameters[key] === '') {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setNotification({ open: 'error', message: 'Fields are empty' });
    } else {
      setSpinner(true);

      getQueryResult('output', params)
        .then((res) => {
          setSpinner(false);
          setResultData(res.result);
          console.log(res);
          formHighChart(res.importance);
        })
        .catch((err) => {
          setSpinner(false);
          console.log(err);
          setNotification({ open: 'error', message: err.message });
        });
    }
  };

  const onChangeFields = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setParameters({
      ...parameters,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            <p>
              The dataset used in this application contains published iTRAQ
              proteome profiling of 77 breast cancer samples generated by the
              Clinical Proteomic Tumor Analysis Consortium (NCI/NIH). It
              contains expression values for ~12.000 proteins for each sample,
              with missing values present when a given protein could not be
              quantified in a given sample.
              <br />
              <br />
              The data is used to assess how the mutations in the DNA are
              affecting the protein expression landscape in breast cancer. Genes
              in our DNA are first transcribed into RNA molecules which then are
              translated into proteins. Changing the information content of DNA
              has impact on the behavior of the proteome, which is the main
              functional unit of cells, taking care of cell division, DNA
              repair, enzymatic reactions and signaling etc. Performed K-means
              clustering on the protein data to divide the breast cancer
              patients into sub-types, each having unique protein expression
              signature. Found that the best clustering was achieved using 3
              clusters (original PAM50 gene set yields four different subtypes
              using RNA data).
            </p>
          </p>
          <p>
            The dataset used in this application contains published iTRAQ
            proteome profiling of 77 breast cancer samples generated by the
            Clinical Proteomic Tumor Analysis Consortium (NCI/NIH). It contains
            expression values for ~12.000 proteins for each sample, with missing
            values present when a given protein could not be quantified in a
            given sample.
            <br />
            <br />
            The data is used to assess how the mutations in the DNA are
            affecting the protein expression landscape in breast cancer. Genes
            in our DNA are first transcribed into RNA molecules which then are
            translated into proteins. Changing the information content of DNA
            has impact on the behavior of the proteome, which is the main
            functional unit of cells, taking care of cell division, DNA repair,
            enzymatic reactions and signaling etc. Performed K-means clustering
            on the protein data to divide the breast cancer patients into
            sub-types, each having unique protein expression signature. Found
            that the best clustering was achieved using 3 clusters (original
            PAM50 gene set yields four different subtypes using RNA data).
          </p>
          <p>
            Here, the data is used to assess how the mutation in the DNA is
            affecting the protein expression landscape in breast cancer.
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Breast_Cancer_Proteomes"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Breast_Cancer_Proteomes/Data"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>

      <Collapse text="Demo">
        <DemoContainer>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormControl minWidth="120" varient="outlined" fullWidth>
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="ATPase inhibitor, mitochondrial isoform 1 precursor"
                  name="NP_057395"
                  value={parameters.NP_057395}
                  onChange={onChangeFields}
                />
                <br />

                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Huntingtin-interacting protein K isoform 2"
                  name="NP_001186814"
                  value={parameters.NP_001186814}
                  onChange={onChangeFields}
                />
                <br />

                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Protein fantom isoform b"
                  name="NP_001121369"
                  value={parameters.NP_001121369}
                  onChange={onChangeFields}
                />
                <br />

                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Protein fantom isoform a"
                  name="NP_056087"
                  value={parameters.NP_056087}
                  onChange={onChangeFields}
                />
                <br />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl minWidth="120" varient="outlined" fullWidth>
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Periphilin-1 isoform 1"
                  name="NP_057572"
                  value={parameters.NP_057572}
                  onChange={onChangeFields}
                />
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Peptidyl-prolyl cis-trans isomerase A-like"
                  name="XP_003960579"
                  value={parameters.XP_003960579}
                  onChange={onChangeFields}
                />
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="CD59 glycoprotein preproprotein"
                  name="NP_976075"
                  value={parameters.NP_976075}
                  onChange={onChangeFields}
                />
                <br />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <br />
            <StyledButton
              variant="contained"
              color="primary"
              onClick={showResult}
            >
              Sample 1
            </StyledButton>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={showResult2}
            >
              Sample 2
            </StyledButton>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={getResult}
            >
              Execute
            </StyledButton>
          </Grid>

          <Grid item xs={12}>
            <section>
              <Grid item xs={12}>
                {showSpinner && <Spinner text="Loading..." />}

                {resultData && (
                  <section>
                    <Result>
                      <strong>Result:</strong>
                      {`${resultData}`}
                    </Result>
                    <Grid container direction="row" justify="center">
                      <Grid item xs={12}>
                        <HighchartContainer id="container_1" display />
                      </Grid>
                    </Grid>
                  </section>
                )}
              </Grid>
            </section>
          </Grid>
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(BreastCancerProteomes);
