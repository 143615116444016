import React, { useRef, useState } from "react";
import {
  Button,
  TextField,
  Grid,
  Radio,
  MenuItem,
  Divider,
} from "@material-ui/core";
import styled from "styled-components";
import {
  Paragraph,
  StyledBackdrop,
  StyledButton,
} from "../../../styles/common";

import CommonService from "../../../utils/services/CommonService";
const { postQueryResult } = CommonService("z1dApps", "textgenNlp");
import SolutionContainer from "../../common/SolutionContainerWrapper";
import Spinner from "../../common/loading/Spinner";
import { jsPDF } from "jspdf";
import JoditEditor from "jodit-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faFileWord, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { TextFieldToolTip } from "./vnet/components/InfoToolTip";

export default function TxtGenStepper() {
  const [fields, setFields] = React.useState({});
  const [radioVal, setRadioVal] = React.useState("");
  const [observations, setObservations] = React.useState(null);
  const [rteValue, setrteValue] = useState("");
  const [finalReportStr, setfinalReportStr] = useState("");
  const [showSpinner, setSpinner] = useState(false);
  const [showDraft, setDraft] = useState(false);
  const [parameter, setParameter] = useState(false);
  const [notification, setNotification] = useState({});
  const [fieldError, setFieldError] = useState({});
  const Token = getUploadToken()
  const modelData = {
    "GPT-2": {
      fields: [
        {
          label: "No. of options to generate",
          name: "num",
          placeHolder: "Ex: 2",
          info: "Numbers of texts to generate (Min 1 – Max 5)",
          placeHolder: "(Min 1 – Max 5)",
        },
        {
          label: "No. of words to generate",
          name: "length",
          info: "Maximum length for the generated text (Min 100 – Max 1000)",
        },
      ],
      defaultValue: { num: 2, length: 200 },
      validate: {
        num: {
          min: 1,
          max: 5,
        },
        length: {
          min: 100,
          max: 1000,
        },
      },
    },
    "GPT-3": {
      fields: [
        {
          label: "No. of options to generate",
          name: "num",
          info: "Numbers of texts to generate (Min 1 – Max 5)",
          placeHolder: "(Min 1 – Max 5)",
        },
        {
          label: "No. of words to generate",
          name: "tokens",
          info: "The maximum number of tokens to generate in the completion (Min 1024 – Max 4096)",
        },
      ],
      defaultValue: { num: 2, tokens: 1024 },
      validate: {
        num: {
          min: 1,
          max: 5,
        },
        tokens: {
          min: 1024,
          max: 4096,
        },
      },
    },
    ChatGPT: {
      fields: [
        {
          label: "No. of options to generate",
          name: "num",
          info: "Numbers of texts to generate (Min 1 – Max 5)",
          placeHolder: "(Min 1 – Max 5)",
        },
        {
          label: "No. of words to generate",
          name: "tokens",
          info: "The maximum number of tokens to generate in the completion (Min 1024 – Max 4096)",
        },
      ],
      defaultValue: { num: 2, tokens: 1024 },
      validate: {
        num: {
          min: 1,
          max: 5,
        },
        tokens: {
          min: 1024,
          max: 4096,
        },
      },
    },
    "MIMIC GPT-2": {
      fields: [
        {
          label: "No. of options to generate",
          name: "num",
          info: "Numbers of texts to generate (Min 1 – Max 5)",
          placeHolder: "(Min 1 – Max 5)",
        },
        {
          label: "No. of words to generate",
          name: "length",
          info: "Maximum length for the generated text (Min 100 – Max 1000)",
        },
      ],
      defaultValue: { num: 2, length: 200 },
      validate: {
        num: {
          min: 1,
          max: 5,
        },
        length: {
          min: 100,
          max: 1000,
        },
      },
    },
    "MS Marco GPT-2": {
      fields: [
        {
          label: "No. of options to generate",
          name: "num",
          info: "Numbers of texts to generate (Min 1 – Max 5)",
          placeHolder: "(Min 1 – Max 5)",
        },
        {
          label: "No. of words to generate",
          name: "length",
          info: "Maximum length for the generated text (Min 100 – Max 1000)",
        },
      ],
      defaultValue: { num: 2, length: 200 },
      validate: {
        num: {
          min: 1,
          max: 5,
        },
        length: {
          min: 100,
          max: 1000,
        },
      },
    },
    "PubMed GPT-2": {
      fields: [
        {
          label: "No. of words to generate",
          name: "length",
          info: "Maximum length for the generated text (Min 100 – Max 1000)",
        },
      ],
      defaultValue: { length: 200 },
      validate: {
        length: {
          min: 100,
          max: 1000,
        },
      },
    },
  };

  const validateOnBlur = (e) => {
    const fieldData = modelData[fields?.model]?.validate;
    const value = e.target.value;
    const name = e.target.name;
    let isError = false;

    if (value > fieldData[name].max) {
      isError = true;
    }
    if (value < fieldData[name].min) {
      isError = true;
    }
    if (isError) {
      setFieldError({
        ...fieldError,
        [name]: `(Min ${fieldData[name].min} – Max  ${fieldData[name].max})`,
      });
    } else {
      setFieldError({ ...fieldError, [name]: false });
    }
  };
  //test jodit
  const editorRef = useRef(null);
  const finalReportEditorRef = useRef(null);

  const config = {
    style: {
      maxHeight: "400px",
      color: "#2F4F4F",
    },
  };

  const appendFinalizedReport = () => {
    setfinalReportStr(finalReportStr + rteValue);
  };

  const handleRadioChange = (e) => {
    setRadioVal(e.target.value);
  };

  function handleChange(e) {
    setFields({ ...fields, [e.target.name]: e.target.value });
    if (e.target.name == "model") {
      setParameter(modelData?.[e.target.value]?.defaultValue);
    }
  }
  function handleParameters(e) {
    setParameter({ ...parameter, [e.target.name]: e.target.value });
  }

  const generateObservation = (e) => {
    setSpinner(true);
    postQueryResult("generateObsrvn", {
      token: Token.token,
      Content_param: {
        Prompt: fields.input_text,
        Model: fields.model,
        Parameters: parameter,
      },
    })
      .then((res) => {
        setSpinner(false);
        if (res?.Error_Flag) {
          setNotification({ open: "error", message: res.Error_UI });
          return;
        }
        let obj = {};
        res?.Result?.forEach((ele, idx) => {
          obj[`option ${idx + 1}`] = ele;
        });
        setObservations(obj);
        setNotification({ open: "success", message: res.Console });
      })
      .catch((err) => {
        setNotification({ open: "error", message: err.message });
        setSpinner(false);
      });
  };

  const toggleDraft = () => {
    setDraft(true);
    let markupStr = `<p style="text-align: left;font-size: 20px;max-width: 98%;"><strong>${fields.input_text}</strong></br></br>${observations[radioVal]}</p>`;
    setrteValue(markupStr);
  };
  const clearInput = () => {
    setFields({ input_text: "", model: "" });
    setRadioVal("");
  };
  const docDownload = () => {
    let el = document.getElementsByClassName("jodit-wysiwyg");
    el[1].setAttribute("id", "pdf-download");
    let preHtml =
      "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
    let postHtml = "</body></html>";
    let html =
      preHtml + document.getElementById("pdf-download").innerHTML + postHtml;
    let blob = new Blob(["\ufeff", html], { type: "application/msword" }); // Specify link url
    let url =
      "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html); // Specify file name
    let filename ="document.doc"; // Create download link element
    let downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);
    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      // Create a link to the file
      downloadLink.href = url; // Setting the file name
      downloadLink.download = filename; //triggering the function
      downloadLink.click();
    }
    document.body.removeChild(downloadLink);
  };

  const pdfDownload = (e) => {
    e.preventDefault();
    let el = document.getElementsByClassName("jodit-wysiwyg");
    el[1].setAttribute("id", "pdf-download");
    let doc = new jsPDF("p", "pt", "A4");
    doc.html(document.getElementById("pdf-download"), {
      html2canvas: { scale: 0.43 },
      callback: (pdf) => {
        doc.setFontSize(8);
        const pageCount = doc.internal.getNumberOfPages();
        console.log(pageCount);
        // For each page, print the page number and the total pages
        for (let i = 1; i <= pageCount; i++) {
          // Go to page i
          doc.setPage(i);
          //Print Page 1 of 4 for example
          console.log(doc.internal.pageSize.height);
          doc.setFontSize(13);
          //title for each page
          doc.text(
            String(fields?.report_title || "Report"),
            doc.internal.pageSize.width / 2,
            30,
            null,
            null,
            "right"
          );
          //border for each page
          doc.setFontSize(8);
          doc.rect(
            20,
            40,
            doc.internal.pageSize.width - 40,
            doc.internal.pageSize.height - 60
          );
          doc.setFontSize(10);

          //page number
          doc.text(
            String(i) + "/" + String(pageCount),
            doc.internal.pageSize.width - 20,
            doc.internal.pageSize.height - 10,
            null,
            null,
            "right"
          );
        }
        //cover page
        doc.addPage();
        doc.setFontSize(14);
        doc.setTextColor(105, 105, 105);
        {
          fields?.report_title &&
            doc.text(
              String("Title: " + fields?.report_title),
              doc.internal.pageSize.width / 2 - 40,
              doc.internal.pageSize.height / 4,
              null,
              null
            );
        }
        {
          fields?.author &&
            doc.text(
              String("Author: " + fields?.author),
              doc.internal.pageSize.width / 2 - 40,
              doc.internal.pageSize.height / 4 + 50,
              null,
              null
            );
        }
        {
          fields?.dep_name &&
            doc.text(
              String("Department: " + fields?.dep_name),
              doc.internal.pageSize.width / 2 - 40,
              doc.internal.pageSize.height / 4 + 25,
              null,
              null
            );
        }
        {
          fields?.report_date &&
            doc.text(
              String("Date: " + fields?.report_date),
              doc.internal.pageSize.width / 2 - 40,
              doc.internal.pageSize.height / 4 + 75,
              null,
              null
            );
        }
        {
          fields?.report_objective &&
            doc.text(
              String("Objective: " + fields?.report_objective),
              30,
              doc.internal.pageSize.height / 4 + 100,
              { maxWidth: doc.internal.pageSize.width - 60 },
              null
            );
        }

        {
          fields?.logo_url &&
            doc.addImage(
              fields?.logo_url,
              "PNG",
              doc.internal.pageSize.width / 2 - 40,
              doc.internal.pageSize.height / 4 - 100,
              90,
              60
            );
        }
        doc.rect(
          20,
          20,
          doc.internal.pageSize.width - 40,
          doc.internal.pageSize.height - 40
        );
        doc.setFillColor(247, 139, 139);
        doc.rect(0, 0, doc.internal.pageSize.width, 40, "F");

        doc.setLineWidth(1);
        doc.setDrawColor(237, 139, 139);
        doc.setFillColor(237, 139, 139);
        doc.triangle(
          0,
          doc.internal.pageSize.height,
          0,
          doc.internal.pageSize.height - 250,
          doc.internal.pageSize.width + 40,
          doc.internal.pageSize.height,
          "FD"
        );
        doc.movePage(pageCount + 1, 1);
        console.log(doc.output('blob'));
        doc.save(`Report_${new Date().toLocaleString()}`);
      },

      margin: [40, 30, 40, 30],
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <ContentContainer>
        <div>
          <HeaderContainer>
            <StyledHeading></StyledHeading>
            <strong>
              <FontAwesomeIcon icon={faInfoCircle} /> Report Details
            </strong>
            <StyledHeading></StyledHeading>
          </HeaderContainer>
          <Grid container spacing={2} justifyContent="left">
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                size="small"
                label="Report Title"
                name="report_title"
                required
                value={fields?.report_title}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                size="small"
                label="Report Date"
                name="report_date"
                value={fields?.report_date}
                onChange={handleChange}
                variant="outlined"
                required
                InputLabelProps={{
                  shrink: true,
                }}
                type="date"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                size="small"
                label="Organization Name"
                name="org_Name"
                required
                value={fields?.org_Name}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                size="small"
                label="Department Name"
                name="dep_name"
                required
                value={fields?.dep_name || ""}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                size="small"
                label="Author"
                name="author"
                value={fields?.author || ""}
                onChange={handleChange}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                size="small"
                label="Logo"
                name="logo_url"
                value={fields?.logo_url || ""}
                onChange={handleChange}
                variant="outlined"
                required
                placeholder="image url (.png format)"
                helperText="*.png images only"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                size="small"
                multiline
                rows={3}
                required
                label="Objective"
                name="report_objective"
                value={fields?.report_objective || ""}
                onChange={handleChange}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <HeaderContainer>
            <StyledHeading></StyledHeading>
            <strong>
              <FontAwesomeIcon icon={faInfoCircle} /> Model Details
            </strong>
            <StyledHeading></StyledHeading>
          </HeaderContainer>
          <Grid container spacing={2} justifyContent="left">
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                size="small"
                name="model"
                label="Model"
                value={fields?.model || ""}
                onChange={handleChange}
                variant="outlined"
                required
                select
              >
                <MenuItem disabled>
                  <strong>
                    <em>----Pretrained----</em>
                  </strong>
                </MenuItem>
                <MenuItem value="GPT-2">GPT-2</MenuItem>
                <MenuItem value="GPT-3">GPT-3</MenuItem>
                <MenuItem value="ChatGPT">ChatGPT</MenuItem>
                <MenuItem disabled>
                  <strong>
                    <em>----Custom----</em>
                  </strong>
                </MenuItem>
                <MenuItem value="MIMIC GPT-2">MIMIC GPT-2</MenuItem>
                <MenuItem value="MS Marco GPT-2">MS Marco GPT-2</MenuItem>
                <MenuItem value="PubMed GPT-2">PubMed GPT-2</MenuItem>
              </TextField>
            </Grid>
          </Grid>
          {fields?.model && (
            <>
              <Grid container spacing={2} justifyContent="left">
                {modelData[fields?.model].fields?.map((fieldData) => (
                  <Grid item xs={12} md={4}>
                    <TextFieldToolTip info={fieldData?.info}>
                      <TextField
                        fullWidth
                        size="small"
                        label={fieldData.label}
                        name={fieldData.name}
                        value={
                          (fieldData?.name && parameter[fieldData?.name]) || ""
                        }
                        onChange={handleParameters}
                        variant="outlined"
                        type="number"
                        placeholder={fieldData?.placeHolder}
                        InputProps={{ inputProps: { min: 0, max: 1 } }}
                        onBlur={validateOnBlur}
                        error={fieldError[fieldData.name]}
                        helperText={fieldError[fieldData.name]}
                      />
                    </TextFieldToolTip>
                  </Grid>
                ))}
              </Grid>
              <br />
              <Divider />
              <br />
              <Grid container spacing={2} justifyContent="left">
                <Grid item xs={12} md={8}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Prompt Your Ask"
                    name="input_text"
                    value={fields?.input_text || ""}
                    onChange={handleChange}
                    variant="outlined"
                    select={
                      ["MIMIC GPT-2", "MS Marco GPT-2", "PubMed GPT-2"].indexOf(
                        fields?.model
                      ) > -1
                    }
                    placeholder="What is Cancer"
                    disabled={!fields?.model}
                  >
                    <MenuItem value="Heart Transplant">
                      Heart Transplant
                    </MenuItem>
                    <MenuItem value="Uses of Tylenol">Uses of Tylenol</MenuItem>
                    <MenuItem value="Symptoms of Colon Cancer">
                      Symptoms of Colon Cancer
                    </MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} md={4}>
                  <StyledButton
                    onClick={generateObservation}
                    variant="contained"
                    color="primary"
                    disabled={!fields?.model}
                  >
                    Generate Observation
                  </StyledButton>
                  <StyledButton variant="contained" onClick={clearInput}>
                    clear
                  </StyledButton>
                </Grid>

                {observations &&
                  Object.entries(observations)?.map((obsrv) => (
                    <>
                      <Grid item xs={12} md={6}>
                        <ObsrvnContainer>
                          <Radio
                            checked={radioVal === obsrv[0]}
                            value={obsrv[0]}
                            onChange={handleRadioChange}
                            name="obsrvn"
                          />
                          <Fieldset>
                            <Legend>{obsrv[0]}</Legend>
                            <Paragraph>{obsrv[1]}</Paragraph>
                          </Fieldset>
                        </ObsrvnContainer>
                      </Grid>
                    </>
                  ))}

                {radioVal && (
                  <>
                    <Grid item xs={12}>
                      <StyledButton
                        variant="contained"
                        color="primary"
                        onClick={toggleDraft}
                      >
                        Choose Observation
                      </StyledButton>
                    </Grid>
                    {showDraft && (
                      <>
                        <Grid item xs={12}>
                          <Label>Observation</Label>
                          <FlexContainer>
                            <StyledEditor
                              ref={editorRef}
                              value={rteValue}
                              config={config}
                              tabIndex={1} // tabIndex of textarea
                              onBlur={(newContent) => setrteValue(newContent)} // preferred to use only this option to update the content for performance reasons
                              onChange={(newContent) => {}}
                            />
                          </FlexContainer>
                        </Grid>
                        <Grid item xs={12}>
                          <StyledButton
                            variant="contained"
                            color="primary"
                            onClick={appendFinalizedReport}
                          >
                            Finalize &#38; Append{" "}
                          </StyledButton>
                        </Grid>
                        <Grid item xs={12}>
                          <FlexContainer>
                            <StyledEditor
                              ref={finalReportEditorRef}
                              value={finalReportStr}
                              config={config}
                              tabIndex={1} // tabIndex of textarea
                              onBlur={(newContent) =>
                                setfinalReportStr(newContent)
                              } // preferred to use only this option to update the content for performance reasons
                              onChange={(newContent) => {}}
                            />
                          </FlexContainer>
                          <StyledButton
                            variant="contained"
                            color="primary"
                            onClick={pdfDownload}
                          >
                            Download as pdf &nbsp;{" "}
                            <FontAwesomeIcon icon={faFilePdf} />
                          </StyledButton>
                          <StyledButton
                            variant="contained"
                            color="primary"
                            onClick={docDownload}
                          >
                            Download as Doc &nbsp;{" "}
                            <FontAwesomeIcon icon={faFileWord} />
                          </StyledButton>
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </Grid>
            </>
          )}
        </div>
      </ContentContainer>
      <StyledBackdrop open={showSpinner}>
        <Spinner />
      </StyledBackdrop>
    </SolutionContainer>
  );
}

const ContentContainer = styled.div`
  width: 100%;

  #pdf-download {
    @media print {
      font-size: 10px;
      margin: 10px;
      overflow-wrap: break-word;
    }
  }
`;

const Label = styled.div`
  font-size: 12px;
  padding: 4px;
`;
const FlexContainer = styled.div`
  display: flex;
  position: relative;
`;
const StyledEditor = styled(JoditEditor)`
  .jodit-workplace {
    max-height: 400px;
  }
`;
const ObsrvnContainer = styled.div`
  display: inline-flex;
  align-items: start;
  height: 90%;
  width: 100%;
  max-width: 100%;
`;
const HeaderContainer = styled(Paragraph)`
  display: flex;
  align-items: center;
  justify-content: center;
  strong {
    color: #2941b7;
  }
`;
const StyledHeading = styled.div`
  width: 35%;
  margin: 0px 1rem;
  height: 3px;
  background: #fc7703;
  color: #2941b7;
  @media only screen and (max-width: 450px) {
    width: 20%;
  }
`;
const Legend = styled.legend`
  background-color: #5c8fe0;
  border-radius: 10%;
  padding: 4px;
  display: flex;
  align-items: center;
  color: #ffff;
  .PrivateSwitchBase-root-8 {
    padding: 2px;
  }
  .MuiCheckbox-colorSecondary,
  .Mui-checked {
    color: #ffff;
  }
`;
const Fieldset = styled.fieldset`
  font-size: 10px;
  border: 1px solid #9eb6de;
  border-radius: 10px;
  margin: 10px;
  text-align: justify;
  height: 100%;
  width: 100%;
  max-width: 100%;
  overflow: auto;
  max-height: 400px;
`;
