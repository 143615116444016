import React, { useState, useRef } from "react";
import styled from "styled-components";
import SolutionContainerWrapper from "../../common/SolutionContainerWrapper";
import Collapse from "../../common/Collapse";
import {
  DemoContainer,
  Paragraph,
  StyledButton,
  Result,
  LinkContainer,
} from "../../../styles/common";
import Spinner from "../../common/loading/Spinner";
import {
  AppBar,
  Tab,
  Paper,
  Grid,
  TextField,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  OutlinedInput,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  FormControlLabel,
  Radio,
  Input,
  Checkbox,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import CommonService from "../../../utils/services/CommonService";
import Notification from "../../common/Notification";
import getUserData from "../../../utils";
import { resetWarningCache } from "prop-types";
import { StyledRadioGroup } from "./NlpToolkit";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faClipboard,
  faCopyright,
  faPlus,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import jsPDF from "jspdf";
import JoditEditor from "jodit-react";
import copy from "copy-to-clipboard";
import { html } from "d3";
import { getUploadToken } from "../../../utils";

export const MedCodeDemo = () => {
  //file upload
  const { uploadFilesWithBody } = CommonService("pyod1", "pyod1");
  const { postQueryResult } = CommonService("z1dAppsv1", "medCode");
  const [userId, setUserId] = useState(getUserData()?.token?.emailid);
  const [notification, setNotification] = useState("");
  const [showSpinner, setSpinner] = useState(false);
  const Token = getUploadToken()

  const initialData = {
    note: "",
    disease: "",
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [path, setPath] = useState("");

  //file upload
  const [selectedFile, setSelectedFile] = useState("");
  const [fileName, setselectedFileName] = useState("");

  const [isTextInput, setIsTextInput] = useState(false);
  const [isFileInput, setIsFileInput] = useState(false);

  // radio button
  const [radiovalue, setRadioValue] = useState("");
  const [radioName, setRadioName] = useState("");

  //new changes
  const [result, setResult] = useState("");
  const [fileResult, setFileResult] = useState("");

  const [icdCodes, setICDCodes] = useState("");
  const [icdCodesFileType, setICDCodesFileType] = useState("");

  const [reports, setReports] = useState("");
  const [reportsFileType, setReportsFileType] = useState("");

  const [noteParam, setNoteParam] = useState("");

  //Checkbox in table row
  const [tableRadiovalue, setTableRadioValue] = useState({ lst: [], obj: {} });

  const [count, setCount] = useState(0);
  const [countFileType, setCountFileType] = useState(0);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //Text Editor
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [contentFileType, setContentFileType] = useState("");

  ///////////////////////////////////////////////////////////////////////
  const handleRadioChange = (e) => {
    setRadioValue(e.target.value);
    setRadioName(e.target.name);
    if (e.target.value === "Text Input") {
      setIsTextInput(true);
      setIsFileInput(false);
      setSampleData({ note: "", disease: "" });
      setFileResult("");

      //file input
      setSelectedFile("");
      setselectedFileName("");
      setPath("");
    } else {
      setIsTextInput(false);
      setIsFileInput(true);
      setSampleData({ note: "", disease: "" });
      setResult("");
    }
  };

  const handleOnChange = (e) => {
    const { name } = e.target;
    const { value } = e.target;

    setSampleData({
      ...sampleData,
      [name]: value,
    });

    setICDCodes("");
    setICDCodesFileType("");

    if (name === "note") {
      setResult("");
      setFileResult("");
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const filename = e.target.files[0].name;
    if (file) {
      setSelectedFile(file);
      setselectedFileName(filename);
    }
  };

  //add disease
  const handleAdd = (e) => {
    setICDCodes("");
    setICDCodesFileType("");
    setSampleData("");

    if (radioName === "Text Input") {
      setResult([sampleData?.disease, ...result]);
      setCount(count + 1);
    } else {
      setFileResult([sampleData?.disease, ...fileResult]);
      setCountFileType(countFileType + 1);
    }
  };

  //checkbox in table row
  const handleTableRadioChange = (event, data, key) => {
    console.log("data--->", key);

    if (event.target.checked) {
      if (!tableRadiovalue?.obj[data[0]]) {
        setTableRadioValue({
          lst: [...tableRadiovalue?.lst, data[0]],
          obj: { ...tableRadiovalue?.obj, [data[0]]: [key, data[0], data[1]] },
        });
      }
    } else {
      if (tableRadiovalue?.lst.indexOf(data[0]) > -1) {
        let val = tableRadiovalue;
        delete val.obj[data[0]];
        val?.lst.splice(val?.lst.indexOf(data[0]), 1);

        setTableRadioValue({
          obj: { ...val.obj },
          lst: [...val?.lst],
        });
      }
    }
  };

  const handleSampleText = (e) => {
    setSampleData({
      note: `"John Doe, a 45-year-old male, was admitted on November 15, 2023. He has a medical history that includes CKD diagnosed five years ago, managed with Farxiga 5mg  daily. Additionally, he has been dealing with type 2 diabetes mellitus for seven years, which is controlled through insulin therapy and dietary adjustments. Three years ago, he was diagnosed with hyperlipidemia and has been taking Atorvastatin 20mg daily to regulate cholesterol levels."`,
      disease: "",
    });
  };

  //////////////////////////////////////////////////////////////////////////////////
  // download text
  const handleDownloadResult = (e) => {
    e.preventDefault();
    let el = document.getElementsByClassName("jodit-wysiwyg");
    el[0].setAttribute("id", "pdf-download");
    let doc = new jsPDF("p", "pt", "A4");
    doc.html(document.getElementById("pdf-download"), {
      html2canvas: { scale: 0.53 },
      callback: (pdf) => {
        doc.save(`Report_${new Date().toLocaleString()}`);
      },

      margin: [40, 30, 40, 30],
    });
  };

  // copy text
  const [copyText, setCopyText] = useState("");
  const [copyTextFileType, setCopyTextFileType] = useState("");

  const handleCopyResult = (text, e) => {
    let htmlRegexG = /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g;
    let plainText = text.replace(htmlRegexG, "").trim();

    if (radioName === "Text Input") {
      copy(plainText);

      setCopyText(true);
      setCopyTextFileType(false);
    } else {
      copy(plainText);

      setCopyTextFileType(true);
      setCopyText(false);
    }
  };
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //file upload api integration
  const handleFileUpload = () => {
    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;

    setSpinner(true);

    uploadFilesWithBody("upload", {
      file: selectedFile,
      Context_param: JSON.stringify({
        Application_name: "med-code",
        UserID: userId,
        Execution_time: date,
      }),
      Content_param: JSON.stringify({ Dataset_Name: fileName }),
      file: selectedFile,
    })
      .then((res) => {
        setSpinner(false);
        const response = res?.Result.path;
        setPath(response);
        console.log("response--->", response);
        setNotification({ open: "success", message: res?.Console });
      })
      .catch((err) => {
        setNotification({ open: "error", message: err.message });
        setSpinner(false);
      });
  };

  //api integration
  const handleExtractDiseases = (e) => {
    setSpinner(true);
    setResult("");
    setFileResult("");
    setICDCodes("");
    setICDCodesFileType("");
    setReports("");
    setReportsFileType("");

    if (radioName === "Text Input") {
      postQueryResult("extractDiseases", {
        token:Token.token,
        Context_param: {
          Application_name: "med-code",
          UserID: userId.toLowerCase(),
        },
        Content_param: {
          note: sampleData?.note,
        },
      })
        .then((res) => {
          const response = res?.Result;
          setResult(response?.diseases);
          setFileResult("");
          setNoteParam(res?.Result.note);
          setSpinner(false);
          setNotification({ open: "success", message: res?.Console });
          console.log("response-->", response);
        })
        .catch((err) => {
          setNotification({ open: "error", message: err });
          setSpinner(false);
        });
    } else {
      postQueryResult("extractDiseases", {
        token:Token.token,
        Context_param: {
          Application_name: "med-code",
          UserID: userId.toLowerCase(),
        },
        Content_param: {
          note: path,
        },
      })
        .then((res) => {
          const response = res?.Result;
          setResult("");
          setFileResult(response?.diseases);
          setNoteParam(res?.Result.note);
          console.log("response-->", res?.Result);
          setSpinner(false);
          setNotification({ open: "success", message: res?.Console });
        })
        .catch((err) => {
          setNotification({ open: "error", message: err });
          setSpinner(false);
        });
    }
  };

  const handleGenerateICDCodes = (e) => {
    setSpinner(true);
    setICDCodes("");
    setICDCodesFileType("");
    setReports("");
    setReportsFileType("");

    if (radioName === "Text Input") {
      postQueryResult("extractICD", {
        token:Token.token,
        Context_param: {
          Application_name: "med-code",
          UserID: userId.toLowerCase(),
        },
        Content_param: {
          note: noteParam,
          diseases: result,
        },
      })
        .then((res) => {
          const response = res?.Result;
          setICDCodes(response);
          setICDCodesFileType("");
          setNoteParam(res?.Result.note);
          setSpinner(false);
          setNotification({ open: "success", message: res?.Console });
          console.log("response-->", response);
          setTableRadioValue({ lst: [], obj: {} });
        })
        .catch((err) => {
          setNotification({ open: "error", message: err });
          setSpinner(false);
        });
    } else {
      postQueryResult("extractICD", {
        token:Token.token,
        Context_param: {
          Application_name: "med-code",
          UserID: userId.toLowerCase(),
        },
        Content_param: {
          note: noteParam,
          diseases: fileResult,
        },
      })
        .then((res) => {
          const response = res?.Result;
          setICDCodes("");
          setICDCodesFileType(response);
          setNoteParam(res?.Result.note);
          setSpinner(false);
          setNotification({ open: "success", message: res?.Console });
          console.log("response-->", response);
          setTableRadioValue({ lst: [], obj: {} });
        })
        .catch((err) => {
          setNotification({ open: "error", message: err });
          setSpinner(false);
        });
    }
  };

  const handleGenerateReport = (e) => {
    setSpinner(true);
    setReports("");
    setReportsFileType("");

    if (radioName === "Text Input") {
      postQueryResult("generateReport", {
        token:Token.token,
        Context_param: {
          Application_name: "med-code",
          UserID: userId.toLowerCase(),
        },
        Content_param: {
          note: noteParam,
          details: tableRadiovalue?.obj,
        },
      })
        .then((res) => {
          const response = res?.Result;
          setReports(response);
          setContent(response);
          setReportsFileType("");
          setSpinner(false);
          setNotification({ open: "success", message: res?.Console });
          console.log("response-->", response);
        })
        .catch((err) => {
          setNotification({ open: "error", message: err });
          setSpinner(false);
        });
    } else {
      postQueryResult("generateReport", {
        token:Token.token,
        Context_param: {
          Application_name: "med-code",
          UserID: userId.toLowerCase(),
        },
        Content_param: {
          note: noteParam,
          details: tableRadiovalue?.obj,
        },
      })
        .then((res) => {
          const response = res?.Result;
          setReports("");
          setReportsFileType(response);
          setContentFileType(response);
          setSpinner(false);
          setNotification({ open: "success", message: res?.Console });
          console.log("response-->", response);
        })
        .catch((err) => {
          setNotification({ open: "error", message: err });
          setSpinner(false);
        });
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <SolutionContainerWrapper snackbar={notification}>
      <Collapse text="Demo">
        <DemoContainer>
        <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/med-code/architecture.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>

          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Paragraph>
                <strong>Choose the clinical notes</strong>
              </Paragraph>
            </Grid>
            <Grid item xs={12}>
              <StyledRadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name={radioName}
                value={radiovalue}
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  value="Text Input"
                  name="Text Input"
                  control={<Radio />}
                  label="Text Input"
                />
                <FormControlLabel
                  value="File Input"
                  name="File Input"
                  control={<Radio />}
                  label="File Input"
                />
              </StyledRadioGroup>
            </Grid>
          </Grid>

          {isTextInput && (
            <>
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={6} sm={7} md={4}>
                  <TextField
                    type="text"
                    color="primary"
                    variant="outlined"
                    fullWidth
                    label="Text Input"
                    name="note"
                    value={sampleData?.note}
                    onChange={handleOnChange}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>

                <Grid item xs={6} sm={6} md={2}>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    data-sample="sample"
                    onClick={handleSampleText}
                    disabled={sampleData?.note}
                  >
                    Sample Text
                  </StyledButton>
                </Grid>

                <Grid item xs={8} sm={6} md={6}>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    data-sample="sample"
                    onClick={handleExtractDiseases}
                    disabled={sampleData?.note === ""}
                  >
                    Extract Diseases from clinical note
                  </StyledButton>
                </Grid>
              </Grid>

              {result && (
                <Grid
                  container
                  xs={12}
                  spacing={2}
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <Result>
                      <strong>Result : </strong>{" "}
                      <Paragraph>The Extracted Diseases</Paragraph>
                      {Object.values(result).map((item, idx) => (
                        <p style={{ alignItems: "left" }}>
                          <li> {item} </li>
                        </p>
                      ))}
                    </Result>
                  </Grid>
                  {/* <Grid item xs={12} sm={12} md={12}>
                    {Object.values(result).map((item, idx) => (
                      <p style={ {alignItems: "left"}}>
                      <li> {item} </li>
                    </p>
                    ))}
                  </Grid> */}

                  <Grid item xs={6} sm={7} md={5}>
                    <TextField
                      type="text"
                      color="primary"
                      variant="outlined"
                      fullWidth
                      label="Add more diseases"
                      name="disease"
                      value={sampleData?.disease}
                      onChange={handleOnChange}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={8} sm={7}>
                    {" "}
                  </Grid>
                  <Grid item xs={6} sm={7} md={5}>
                    {" "}
                    <StyledButton
                      variant="contained"
                      color="primary"
                      data-sample="sample"
                      onClick={handleAdd}
                      disabled={count === 10}
                    >
                      Add More Disease
                    </StyledButton>{" "}
                  </Grid>

                  <Grid item xs={8} sm={7}>
                    <StyledButton
                      variant="contained"
                      color="primary"
                      data-sample="sample"
                      onClick={handleGenerateICDCodes}
                    >
                      Identify ICD 10 codes
                    </StyledButton>
                  </Grid>

                  {icdCodes && (
                    <>
                      <Grid item xs={12} sm={12} md={10}>
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <HeaderCol>Name of the Disease </HeaderCol>
                                <HeaderCol>ICD 10 code </HeaderCol>
                                <HeaderCol>Description</HeaderCol>
                                <HeaderCol>Status</HeaderCol>
                                <HeaderCol>Select Code</HeaderCol>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {Object.keys(icdCodes.details).map(
                                (key, index) => (
                                  <TableRow>
                                    <TableCell> {key} </TableCell>
                                    <TableCell colSpan={12}>
                                      {Object.entries(
                                        icdCodes.details[key]
                                      )?.map((data) => (
                                        <TableRow>
                                          <TableCell>{data[0]}</TableCell>
                                          <TableCell
                                            style={{
                                              maxWidth: "200px",
                                            }}
                                          >
                                            {" "}
                                            {data[1].description}{" "}
                                          </TableCell>
                                          <TableCell>
                                            {data[1].status}
                                          </TableCell>

                                          <TableCell>
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  onChange={(e) => {
                                                    handleTableRadioChange(
                                                      e,
                                                      data,
                                                      key
                                                    );
                                                  }}
                                                  checked={
                                                    tableRadiovalue?.lst?.indexOf(
                                                      data[0]
                                                    ) > -1
                                                      ? true
                                                      : false
                                                  }
                                                />
                                              }
                                            />
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    </TableCell>
                                  </TableRow>
                                )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>

                      <Grid item xs={8} sm={7}>
                        <StyledButton
                          variant="contained"
                          color="primary"
                          data-sample="sample"
                          onClick={handleGenerateReport}
                          disabled={tableRadiovalue?.lst?.length === 0}
                        >
                          Generate Report
                        </StyledButton>
                      </Grid>

                      {reports && (
                        <>
                          <Grid item xs={12} sm={12} md={12}>
                            <Result>
                              {" "}
                              <strong>Medical Note: </strong>{" "}
                            </Result>
                            <JoditEditor
                              ref={editor}
                              value={content}
                              onBlur={(newContent) => setContent(newContent)}
                            />
                          </Grid>

                          <Grid item xs={12} sm={5} md={6}>
                            <StyledButton
                              variant="outlined"
                              color="primary"
                              size="large"
                              startIcon={<OpenInNewIcon />}
                              onClick={handleDownloadResult}
                            >
                              Download Note
                            </StyledButton>
                          </Grid>
                          <Grid item xs={12} sm={5} md={6}>
                            {copyText ? (
                              <StyledButton
                                variant="outlined"
                                color="primary"
                                size="large"
                              >
                                Copied &nbsp;
                                <FontAwesomeIcon
                                  icon={faCheckCircle}
                                  color="#00C642"
                                />
                              </StyledButton>
                            ) : (
                              <StyledButton
                                variant="outlined"
                                color="primary"
                                size="large"
                                onClick={handleCopyResult.bind(this, content)}
                              >
                                Copy Note &nbsp;
                                <FontAwesomeIcon
                                  id="content"
                                  icon={faClipboard}
                                />
                              </StyledButton>
                            )}
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                </Grid>
              )}
            </>
          )}

          {isFileInput && (
            <>
              <Grid
                container
                spacing={2}
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item xs={8} sm={5} md={4}>
                  <Input
                    type="file"
                    color="primary"
                    name="uploadedFile"
                    onChange={handleFileChange}
                  />
                </Grid>
                <Grid item xs={4} sm={2} md={2}>
                  <StyledButton
                    id="btn2"
                    variant="contained"
                    color="primary"
                    helperText="Please select a txt file only"
                    onClick={handleFileUpload}
                    disabled={selectedFile === ""}
                  >
                    Upload
                  </StyledButton>
                </Grid>
                <Grid item xs={12} sm={5} md={4}>
                  <StyledButton
                    variant="outlined"
                    color="primary"
                    size="large"
                    startIcon={<OpenInNewIcon />}
                  >
                    <a
                      href="https://material.vlifevirtusa.com/med-code/mock_text(1).txt"
                      download
                      rel="noopener noreferrer"
                    >
                      Download Sample File
                    </a>
                  </StyledButton>
                </Grid>

                <Grid item xs={8} sm={7}>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    data-sample="sample"
                    onClick={handleExtractDiseases}
                    disabled={path === ""}
                  >
                    Extract Diseases from clinical note
                  </StyledButton>
                </Grid>
              </Grid>

              {fileResult && (
                <>
                  <Grid
                    container
                    xs={12}
                    spacing={2}
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <Result>
                        <strong>Result : </strong>{" "}
                        <Paragraph>The Extracted Diseases</Paragraph>
                        {Object.values(fileResult).map((item, idx) => (
                          <div style={{ alignItems: "left" }}>
                            <li> {item} </li>
                          </div>
                        ))}
                      </Result>
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={12}>
                      {Object.values(fileResult).map((item, idx) => (
                        <div style={{ alignItems: "left" }}>
                          <li> {item} </li>
                        </div>
                      ))}
                    </Grid> */}

                    <Grid item xs={6} sm={7} md={5}>
                      <TextField
                        type="text"
                        color="primary"
                        variant="outlined"
                        fullWidth
                        label="Add more diseases"
                        name="disease"
                        value={sampleData?.disease}
                        onChange={handleOnChange}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={8} sm={7}>
                      {" "}
                    </Grid>
                    <Grid item xs={8} sm={7} md={5}>
                      {" "}
                      <StyledButton
                        variant="contained"
                        color="primary"
                        data-sample="sample"
                        onClick={handleAdd}
                        disabled={countFileType === 10}
                      >
                        Add More Disease
                      </StyledButton>{" "}
                    </Grid>

                    <Grid item xs={8} sm={7}>
                      <StyledButton
                        variant="contained"
                        color="primary"
                        data-sample="sample"
                        onClick={handleGenerateICDCodes}
                      >
                        Identify ICD 10 codes
                      </StyledButton>
                    </Grid>

                    {icdCodesFileType && (
                      <>
                        <Grid item xs={12} sm={12} md={10}>
                          <TableContainer component={Paper}>
                            <Table
                              sx={{ minWidth: 650 }}
                              aria-label="simple table"
                            >
                              <TableHead>
                                <TableRow>
                                  <HeaderCol>Name of the Disease </HeaderCol>
                                  <HeaderCol>ICD 10 code </HeaderCol>
                                  <HeaderCol>Description</HeaderCol>
                                  <HeaderCol>Status</HeaderCol>
                                  <HeaderCol>Select Code</HeaderCol>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {Object.keys(icdCodesFileType.details).map(
                                  (key, index) => (
                                    <TableRow>
                                      <TableCell> {key} </TableCell>
                                      <TableCell colSpan={12}>
                                        {Object.entries(
                                          icdCodesFileType.details[key]
                                        )?.map((data) => (
                                          <TableRow>
                                            <TableCell>{data[0]}</TableCell>
                                            <TableCell
                                              style={{ maxWidth: "200px" }}
                                            >
                                              {" "}
                                              {data[1].description}{" "}
                                            </TableCell>
                                            <TableCell>
                                              {data[1].status}
                                            </TableCell>

                                            <TableCell>
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    onChange={(e) => {
                                                      handleTableRadioChange(
                                                        e,
                                                        data,
                                                        key
                                                      );
                                                    }}
                                                    checked={
                                                      tableRadiovalue?.lst?.indexOf(
                                                        data[0]
                                                      ) > -1
                                                        ? true
                                                        : false
                                                    }
                                                  />
                                                }
                                              />
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableCell>
                                    </TableRow>
                                  )
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>

                        <Grid item xs={8} sm={7}>
                          <StyledButton
                            variant="contained"
                            color="primary"
                            data-sample="sample"
                            onClick={handleGenerateReport}
                            disabled={tableRadiovalue?.lst?.length === 0}
                          >
                            Generate Report
                          </StyledButton>
                        </Grid>

                        {reportsFileType && (
                          <>
                            <Grid item xs={12} sm={12} md={12}>
                              <Result>
                                <strong>Medical Note: </strong>{" "}
                              </Result>
                              <JoditEditor
                                ref={editor}
                                value={contentFileType}
                                onBlur={(newData) =>
                                  setContentFileType(newData)
                                }
                              />
                            </Grid>

                            <Grid item xs={12} sm={5} md={6}>
                              <StyledButton
                                variant="outlined"
                                color="primary"
                                size="large"
                                startIcon={<OpenInNewIcon />}
                                onClick={handleDownloadResult}
                              >
                                Download Note
                              </StyledButton>
                            </Grid>
                            <Grid item xs={12} sm={5} md={6}>
                              {copyTextFileType ? (
                                <StyledButton
                                  variant="outlined"
                                  color="primary"
                                  size="large"
                                >
                                  Copied &nbsp;
                                  <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    color="#00C642"
                                  />
                                </StyledButton>
                              ) : (
                                <StyledButton
                                  variant="outlined"
                                  color="primary"
                                  size="large"
                                  onClick={handleCopyResult.bind(
                                    this,
                                    contentFileType
                                  )}
                                >
                                  Copy Note &nbsp;
                                  <FontAwesomeIcon
                                    id="content"
                                    icon={faClipboard}
                                  />
                                </StyledButton>
                              )}
                            </Grid>
                          </>
                        )}
                      </>
                    )}
                  </Grid>
                </>
              )}
            </>
          )}

          {showSpinner && <Spinner text="Loading..." />}
        </DemoContainer>
      </Collapse>
    </SolutionContainerWrapper>
  );
};

export default function MedCodeDesc() {
  return (
    <SolutionContainerWrapper>
      <Collapse text="Description">
        <Paragraph>
          <p>
            ICD-10 codes play a critical role in medical billing processes,
            providing a standardized system for classifying and coding medical
            diagnoses and procedures. ICD-10 codes ensure that medical services
            and procedures are accurately documented and billed. These codes
            represent specific diagnoses, symptoms, and medical conditions,
            allowing healthcare providers to bill insurance companies or
            patients for the appropriate services rendered.
          </p>
          <p>
            For the development of this use case, the following steps have been
            undertaken: The clinical text is provided as input. It can either be
            raw text or a .txt file. From this text by using an NER (Name entity
            recognition) model, the diseases present have been extracted. Then
            there is a search for any matches between disease names and their
            abbreviations from a repository. Any extracted diseases which are as
            abbreviations are replaced with their full forms. These diseases are
            now passed to a clinical BERT model which predicts the top 5 most
            probable ICD-10 codes along with a short description and their
            billable/non-billable status. The user now has the flexibility to
            select the most optimal ICD code-10 and a final report gets
            generated with their selected ICD codes.
          </p>
          <p>
            <strong>SOLUTION APPROACH: </strong>
            <ul>
              <li>
                Recognize the diseases present in the clinical notes by using
                Named Entity Recognition (NER) models taken from Stanford NLP
                repository.{" "}
              </li>
              <li>
                Replacing short forms of the diseases with their full forms
                using a repository from GitHub containing pairs of full forms
                and short forms.{" "}
              </li>
              <li>
                Each disease is associated with an ICD-10 code.
                ICD-10-Prediction transformer model is used to identify the
                probable ICD-10 codes associated with the identified diseases.{" "}
              </li>
            </ul>
          </p>

          <p>
            {" "}
            <strong>KEY FEATURES: </strong>
            <ul>
              <li>Easy, Simple and Intuitive User Interface</li>
              <li>Natural Language Processing of Unstructured Data</li>
              <li>Multi-Code Predictions (Multi-label Classification)</li>
              <li>Prediction Probabilities for each predicted code</li>
              <li>Deployable on the Cloud (Kubernetes & Docker)</li>
            </ul>
          </p>

          <p>
            {" "}
            <strong>KEY BENEFITS: </strong>
            <ul>
              <li>Reduced Efforts for Medical Coders</li>
              <li>Improves Coding Accuracy</li>
              <li>Reduce Denials & Accelerate Denial Handling</li>
              <li>Cuts Operational Costs</li>
              <li>Reduce A/R time from Payers</li>
            </ul>
          </p>
        </Paragraph>
      </Collapse>
    </SolutionContainerWrapper>
  );
}

const HeaderCol = styled(TableCell)`
  background-color: #3f51b5;
  color: white;
  height: 5px;
`;
