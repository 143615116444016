import React, { memo, useState } from "react";
import { Grid, TextField, FormControl } from "@material-ui/core";
import styled from "styled-components";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components
import Highcharts from "highcharts";
import Collapse from "../../common/Collapse";

import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import CommonService from "../../../utils/services/CommonService";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  HighchartContainer,
  Result,
} from "../../../styles/common";
import getUserData, { getUploadToken } from '../../../utils';

const BreastCancerClassification = () => {
  const initialData = {
    radius_mean: "",
    texture_mean: "",
    concavity_mean: "",
    fractal_dimension_mean: "",
    radius_se: "",
    compactness_se: "",
    concavity_se: "",
    concave_points_se: "",
    fractal_dimension_se: "",
    smoothness_worst: "",
    concavity_worst: "",
    symmetry_worst: "",
    fractal_dimension_worst: "",
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService("bioInformatics", "breastCancerClassification"); 
  const Token = getUploadToken()
  
  const formChart = (data) => {
    const params = [];
    data.map((arr, index) => params.push({
      name: arr[0],
      y: arr[1],
    }));
    Highcharts.chart("container", {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
      },
      title: {
        text: "Major Contributing factors",
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
        },
      },
      series: [
        {
          name: "Brands",
          colorByPoint: true,
          data: params,
        },
      ],
    });
  };

  const showResult = () => {
    const NewDataSet = {
      radius_mean: "17.99",
      texture_mean: "10.38",
      concavity_mean: "0.3001",
      fractal_dimension_mean: "0.07871",
      radius_se: "1.095",
      compactness_se: "0.04904",
      concavity_se: "0.05372999999999999",
      concave_points_se: "0.01587",
      fractal_dimension_se: "0.006193",
      smoothness_worst: "0.1622",
      concavity_worst: "0.7119",
      symmetry_worst: "0.4601",
      fractal_dimension_worst: "0.1189",
    };
    setSampleData(NewDataSet);
  };

  const showResult2 = () => {
    const NewDataSet = {
      radius_mean: "10.17",
      texture_mean: "14.88",
      concavity_mean: "0.01084",
      fractal_dimension_mean: "0.0696",
      radius_se: "0.5158",
      compactness_se: "0.01099",
      concavity_se: "0.007665000000000001",
      concave_points_se: "0.008193",
      fractal_dimension_se: "0.005953",
      smoothness_worst: "0.1275",
      concavity_worst: "0.02168",
      symmetry_worst: "0.3557",
      fractal_dimension_worst: "0.0802",
    };
    setSampleData(NewDataSet);
  };

  const getResult = () => {
    const {
      radius_mean,
      texture_mean,
      concavity_mean,
      fractal_dimension_mean,
      radius_se,
      compactness_se,
      concavity_se,
      concave_points_se,
      fractal_dimension_se,
      smoothness_worst,
      concavity_worst,
      symmetry_worst,
      fractal_dimension_worst,
    } = sampleData;

    const params = `${radius_mean},${texture_mean},${concavity_mean},${fractal_dimension_mean},${radius_se},${compactness_se},${concavity_se},${concave_points_se},${fractal_dimension_se},${smoothness_worst},${concavity_worst},${symmetry_worst},${fractal_dimension_worst},${Token.token}`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("output", params)
        .then((result) => {
          setSpinner(false);
          setResultData(result.result);
          console.log("result", result);
          formChart(result.importance);
        })
        .catch((err) => {
          setSpinner(false);
          console.log(err);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const onChangeFields = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };
  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <section>
            <p>
              Breast cancer is the most commonly occuring cancer amongst women
              according to the statistics presented by The Global Cancer
              Observatory (GCO). 25% of all cancers occurring in women is breast
              cancer, 14% of which results in death. However, statistics show
              that if the cancer is detected and treated early, thus inhibiting
              the cancer from progressing into further, more fatal stages; it
              can ensure a 90% 5-year survival rate for women with invasive
              breast cancer, as well as an 83% 10-year survival rate.
            </p>
            <p>
              Random Forest classifier is used to determine the malignancy of
              the tumor-cells of a patient. The model is trained on "Breast
              Cancer Wisconsin (Diagnostic) Data Set" obtained from UCI Machine
              Learning repository wherein the features are computed from a
              digitized image of a fine needle aspirate (FNA) of a breast mass.
              The features describe characteristics of the cell nuclei present
              in the image.
            </p>

            <p>Ten real-valued features are computed for each cell nucleus:</p>
            <ol type="1 ">
              <li>
                Radius (mean of distances from center to points on the
                perimeter)
              </li>
              <li> Texture (standard deviation of gray-scale values)</li>
              <li> Perimeter</li>
              <li> Area</li>
              <li> Smoothness (local variation in radius lengths)</li>
              <li> Compactness (perimeter^2 / area - 1.0)</li>
              <li> Concavity (severity of concave portions of the contour)</li>
              <li>
                Concave points (number of concave portions of the contour)
              </li>
              <li> Symmetry</li>
              <li> Fractal dimension ("coastline approximation" - 1)</li>
            </ol>
            <p>
              The mean, standard error and "worst" or largest (mean of the three
              largest values) of these features were computed for each image,
              resulting in 30 features. We have identified the below features
              which are significant factors for classification
            </p>
          </section>
          <section />
          <br />
          <LinkContainer>
            <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Breast_cancer/data"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Data source
                </a>
              </StyledButton>
            </Grid>

              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Breast_cancer"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <section>
            <h5>Please provide the following data inputs</h5>
          </section>
          <br />
          <section>
            <Grid container direction="row" justify="center" spacing={2}>
              <Grid item xs={12} md={6}>
                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Radius Mean"
                          name="radius_mean"
                          value={sampleData.radius_mean}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Texture Mean"
                          name="texture_mean"
                          value={sampleData.texture_mean}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Concativity Mean"
                          name="concavity_mean"
                          value={sampleData.concavity_mean}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Fractal Dimension Mean"
                          name="fractal_dimension_mean"
                          value={sampleData.fractal_dimension_mean}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Radius "
                          name="radius_se"
                          value={sampleData.radius_se}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Compactness"
                          name="compactness_se"
                          value={sampleData.compactness_se}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Concavity"
                          name="concavity_se"
                          value={sampleData.concavity_se}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Symmetry Worst"
                          name="symmetry_worst"
                          value={sampleData.symmetry_worst}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Concave Point"
                          name="concave_points_se"
                          value={sampleData.concave_points_se}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Fractional Dimensional"
                          name="fractional_dimensional_se"
                          value={sampleData.fractal_dimension_se}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Smoothness Worst"
                          name="smoothness_worst"
                          value={sampleData.smoothness_worst}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Concavity Worst"
                          name="concavity_worst"
                          value={sampleData.concavity_worst}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>
                <br />
                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Fractal Dimension Worst"
                          name="fractal_dimension_worst"
                          value={sampleData.fractal_dimension_worst}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>
                <br />
                <section>
                  <BorderGray>
                    Not sure about valid data inputs? Try any of the following
                    sample data...
                    <div align="right">
                      <StyledButton
                        variant="contained"
                        color="secondary"
                        onClick={showResult}
                      >
                        Sample 1
                      </StyledButton>

                      <StyledButton
                        variant="contained"
                        color="secondary"
                        onClick={showResult2}
                      >
                        Sample 2
                      </StyledButton>
                      <StyledButton
                        variant="contained"
                        color="primary"
                        onClick={getResult}
                      >
                        Submit
                      </StyledButton>
                    </div>
                  </BorderGray>
                </section>
              </Grid>
              <Grid item xs={12} md={6}>
                <section>
                  <h3>Output</h3>
                </section>
                {showSpinner && <Spinner text="Loading..." />}
                {resultData && (
                  <section>
                    <Result>
                      <strong>Result:</strong>
                      {`${resultData}`}
                    </Result>
                    <Grid container direction="row" justify="center">
                      <Grid item xs={12}>
                        <HighchartContainer id="container" display />
                      </Grid>
                    </Grid>
                  </section>
                )}
              </Grid>
            </Grid>
          </section>
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(BreastCancerClassification);

const BorderGray = styled.div`
  border: 1px #d6d6d6 solid;
  padding: 20px;
`;
