import React, { memo, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TablePagination,
  Paper,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  TextField,
  Grid,
  FormControl,
  MenuItem,

} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import styled from "styled-components";
import NearMeIcon from "@material-ui/icons/NearMe";

// import local components
import Collapse from "../../common/Collapse";
import ImageAnalysisInput from "../../common/ImageAnalysisInput";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  SolutionContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";
import PresignedS3Link from "../../common/PresignedS3Link";

const LegalDocParser = () => {
  const rowsPerPage = 5;

  const initialData = {
    name: "",
    data: "",
    tableData: "",
    list: "",
  };

  const [response, setResponse] = useState("");
  const [sampleData, setSampleData] = useState(initialData);
  const [attributes, setAttributes] = useState("");
  const [dropDownValues, setDropDownValues] = useState(response);
  const [page, setPage] = useState(0);

  const getResponse = (response) => {
    if (response === "") {
      setResponse(response);
      setAttributes("");
    }
    if (response) {
      setAttributes(true);
      const dropDownValues = Object.entries(response).map((arr) => arr[0]);
      setDropDownValues(dropDownValues);
    }
    setResponse(response);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onChangeFields = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    let text; let tableData; let
      list;
    if (
      response[value] == "[]"
      || (Array.isArray(response[value]) && response[value].length === 0)
    ) {
      text = "No Data Available";
    } else if (value === "Constraints" || value === "Conditions") {
      tableData = response[value];
    } else if (value === "Regulations") {
      list = response[value].map((arr) => arr.join(": "));
    } else if (value === "Acts") {
      list = response[value].map((arr) => arr.act_name + (arr.year ? `, ${arr.year}` : ""));
    } else if (
      value === "URLs"
      || value === "Dates"
      || value === "Trademarks"
    ) {
      list = response[value];
    } else if (value === "Personally Identifiable Information") {
      list = response[value].map((arr) => arr.join(": "));
    } else if (
      value === "File name"
      || value === "Title"
      || value === "Entity name"
    ) {
      text = response[value];
    } else if (value === "Ratios") {
      list = response[value].map((arr) => `${arr[0]}/${arr[1]}`);
    } else if (value === "Money") {
      list = response[value].map((arr) => arr.join(" "));
    } else if (value === "Copyrights") {
      list = response[value].map((arr) => arr.join(" "));
    } else if (value === "Percents and Rates") {
      list = response[value].map((arr) => `${arr[1]} % and ${arr[2]}`);
    }

    setSampleData({
      ...sampleData,
      [name]: value,
      data: text,
      tableData,
      list,
    });
  };

  return (
    <SolutionContainer>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Legal Documents like contracts, health insurance policies or
            clinical trial procedures are long text documents having huge
            information. Many a times, one needs to extract out basic
            information from such documents such as
          </p>
          <ul>
            <li>Monetary amounts, non-monetary amounts, percentages, ratios</li>
            <li>
              Conditional statements and constraints like "less than" or "later
              than"
            </li>
            <li>Dates, recurring dates and durations</li>
            <li>Courts, regulations and citations</li>
          </ul>
          <p>
            LexNLP is a library for working with real, unstructured legal text,
            including contracts, plans, policies, procedures and other
            materials.
          </p>
          <p>
            LexNLP contains methods that allow for the extraction of structured
            data from unstructured textual sources. Supported data types include
            a wide range of facts relevant to contract or document analysis,
            including dates, amounts, proper noun types, and conditional
            statements. Importing the right functions from LexNLP is the key to
            using the library properly.
          </p>
          <p>
            In this Solution LexNLP is used to extract specific types of data
            from legal documents such as Entity Names, Citations, Regulations,
            Acts, Amount, Conditions, Constraints, Dates, Money, Percent, PII,
            Ratios, Trademarks and URLs.
          </p>
          <p>
            <strong> Applications :</strong>
            {' '}
            The solution can be widely used by
            various agencies and institutions to extract out quick information
            from policies and legal documents e.g. :
          </p>
          <ul>
            <li>Payers can use it for insurance policies</li>
            <li>
              Pharmaceutical Companies and research institutes during clinical
              trials
            </li>
            <li>Medical students and professionals doing research work</li>
          </ul>
          <LinkContainer>
            <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://policy.ucop.edu/doc/1100168/LegalMedicalRecord"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                 Data Source
                </a>
              </StyledButton>
            </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/LexNLP/LexNLP.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <PresignedS3Link
                    href="TextTranscriber/managed-care-sample-contract.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Sample Data 1
                  </PresignedS3Link>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://policy.ucop.edu/doc/1100168/LegalMedicalRecord"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View Sample Data 2
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <ImageAnalysisInput
            url={["z1dApps", "legalDocParser"]}
            result={getResponse}
          />
          {response && (
            <Grid
              container
              xs={12}
              spacing={2}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TableMarginTextField
                    id="plots"
                    select
                    fullWidth
                    variant="outlined"
                    label="Attributes"
                    name="attributes"
                    value={sampleData.attributes}
                    onChange={onChangeFields}
                  >
                    {Array.isArray(dropDownValues)
                      && dropDownValues.map((option) => (
                        <MenuItem
                          key={option.value || option}
                          value={option.value || option}
                        >
                          {option.label || option}
                        </MenuItem>
                      ))}
                  </TableMarginTextField>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                {attributes && (
                  <section>
                    {sampleData.data && (
                      <Result>
                        {sampleData.data}
                      </Result>
                    )}

                    {sampleData.tableData && (
                      <TableMarginPaper>
                        <TableContainer>
                          <Table aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <StyledTableHead key="condition" align="left">
                                  {sampleData.attributes}
                                </StyledTableHead>
                                <StyledTableHead key="statement" align="left">
                                  Statement
                                </StyledTableHead>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {sampleData.tableData
                                .slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage,
                                )
                                .map((row) => (
                                  <TableRow key={row.index}>
                                    {row.map((field) => (
                                      <TableCell align="left">
                                        {field}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 15, 20]}
                          component="div"
                          count={sampleData.tableData.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onChangePage={handleChangePage}
                        />
                      </TableMarginPaper>
                    )}
                    {sampleData.list && (
                      <List aria-labelledby="nested-list-subheader">
                        {sampleData.list.map((list) => (
                          <ListItem disablePadding>
                            <ListItemIcon>
                              <NearMeIcon />
                            </ListItemIcon>
                            <ListItemText primary={list} />
                            <Divider />
                          </ListItem>
                        ))}
                      </List>
                    )}
                  </section>
                )}
              </Grid>
            </Grid>
          )}
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(LegalDocParser);

const TableMarginPaper = styled(Paper)`
  margin: 8px;
`;

const TableMarginTextField = styled(TextField)`
  margin: 8px;
`;
const StyledTableHead = styled(TableCell)`
  background-color: #3c40af;
  color: white;
  @media only screen and (max-width: 900px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 450px) {
    font-size: 12px;
  }
`;
