import React, { memo, useState } from "react";
import {
  Tab,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core";
import styled from "styled-components";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import fairness_overview from "../../../../assets/images/app/responsibleAI/Fairness overview.webp";
// local components
import Collapse from "../../../common/Collapse";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBackward,
  faForward,
} from '@fortawesome/free-solid-svg-icons';
import SolutionContainer from "../../../common/SolutionContainerWrapper";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  List,
  ListItem,
} from "../../../../styles/common";
import DataSetDetails from "./DataSetDetails";
import Spinner from "../../../common/loading/Spinner";
import staticObj from "./constants";
import SolutionContainerWrapper from "../../../common/SolutionContainerWrapper";
export const ResponsibleUIDemo = () => {
  const [notification, setNotification] = useState("");
  const [showSpinner, setSpinner] = useState(false);

  // radio button
  const [datasetVal, setDataset] = useState("");
  const [mitigateVal, setMitigateVal] = useState("");

  //stepper Function
  const steps = ["Data", "Check", "Mitigate ", "Compare"];
  const [activeStep, setActiveStep] = useState(0);

  //initial
  const [data, setData] = useState(true);

  const handleRadioChange = (event) => {
    setDataset(event.target.value);
  };

  const handleMitigationRadioChange = (event) => {
    setMitigateVal(event.target.value);
  };
  const isDisabled =
    datasetVal === "" || (mitigateVal === "" && activeStep === 2);

  const nextStep = (e) => {
    setSpinner(true);
    setTimeout(() => {
      if (activeStep < 3) {
        setActiveStep((currentstep) => currentstep + 1);
      }
      setSpinner(false);
    }, 2000);

    console.log("datasetVal:", datasetVal);
    console.log("mitigateVal:", mitigateVal);
    if (datasetVal === " " || mitigateVal === " ") {
      setNotification({
        open: "error",
        message: "Please select option",
      });
    }
  };

  const preStep = (e) => {
    console.log("pre button", e.target.value);
    setSpinner(true);
    setTimeout(() => {
      setActiveStep((currentstep) => currentstep - 1);

      setSpinner(false);
    }, 2000);

    if (activeStep === 1) {
      setData(true);
    }
  };

  return (
    <div>
      <Grid
        container
        spacing={2}
        xs={12}
        direction="row"
        justifyContent="center"
      >
        <Grid item xs={12} sm={12} md={12}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          
          <StyledButton
            id="sample1"
            variant="contained"
            color="primary"
            data-sample="sample1"
            disabled={activeStep === 0}
            onClick={preStep}
            value={datasetVal}
          >
            <FontAwesomeIcon icon={faBackward} /> &nbsp; Back
          </StyledButton>

          <StyledButton
            id="sample1"
            variant="contained"
            color="primary"
            value={datasetVal}
            data-sample="sample1"
            onClick={nextStep}
            disabled={activeStep === 3 || isDisabled}
          >
            {/* {activeStep === 3 ? "Finish" : "Next"} */}
            Next &nbsp; <FontAwesomeIcon icon={faForward} />
          </StyledButton>
        </Stepper>
        </Grid>
      </Grid>
      {showSpinner && <Spinner text="Loading.. Please wait for a minute..." />}
      <TabPanel index={0} value={activeStep}>
        <LeftAlignedGrid item xs={12}>
          <h3>1. Choose sample dataset</h3>
          <p>
            Bias might present in the dataset used to train a model. We have
            provided four sample datasets that you can use to explore bias
            checking and mitigation. Each dataset contains attributes that
            should be protected to avoid bias.
          </p>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            value={datasetVal}
            onChange={handleRadioChange}
          >
            <StyledFormControlLabel
              value="adultDataset"
              name="Adult Dataset"
              control={<Radio />}
              label={
                <span>
                  <strong>Adult Dataset:</strong> Predict whether income exceeds
                  50K dollars per year.
                </span>
              }
            />
            {/* Predict whether income exceeds 50K dollars per year. */}
            <ul>
              <li>
                <strong>Protected Attributes:</strong>
                <ul>
                  <li>
                    Ethnic group,
                    <ul>
                      <li>
                        <span>
                          <strong> privileged: </strong> Asian-Pac-Islander
                        </span>
                      </li>
                      <li>
                        <span>
                          <strong> unprivileged: </strong> Other
                        </span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <FormControlLabel
              value="creditCard"
              name="Credit Card"
              control={<Radio />}
              label={
                <span>
                  <strong>Credit Card Dataset:</strong> Predict whether a customer can make default payment next month.
                </span>
              }
            />
            {/* Predict whether a customer can make default payment next month. */}
            <ul>
              <li>
                <strong>Protected Attributes:</strong>
                <ul>
                  <li>
                    Sex,
                    <ul>
                      <li>
                        <span>
                          <strong> privileged:</strong> Female
                        </span>
                      </li>
                      <li>
                        <span>
                          <strong> unprivileged:</strong> Male
                        </span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <FormControlLabel
              value="diabetic"
              name="Diabetic"
              control={<Radio />}
              label={
                <span>
                  <strong>Diabetic Dataset:</strong> Predict whether the patient
                  was readmitted within 30 days.
                </span>
              }
            />
            {/* Predict whether the patient was readmitted within 30 days. */}
            <ul>
              <li>
                <strong>Protected Attributes:</strong>
                <ul>
                  <li>
                    Race,
                    <ul>
                      <li>
                        <span>
                          <strong> privileged:</strong> Unknown
                        </span>
                      </li>
                      <li>
                        {" "}
                        <span>
                          <strong> unprivileged:</strong> Asian
                        </span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <FormControlLabel
              value="indianLiverPatientDataset"
              name="Indian Liver Patient Dataset"
              control={<Radio />}
              label={<span><strong>Indian Liver Patient Dataset:</strong> Predict the probability of Liver patient getting a stroke.
              </span>}
            />
            {/* Predict the probability of Liver patient getting a stroke. */}
            <ul>
              <li>
                <strong>Protected Attributes:</strong>
                <ul>
                  <li>
                    Gender,
                    <ul>
                      <li>
                        <span>
                          <strong> privileged:</strong> Male
                        </span>{" "}
                      </li>
                      <li>
                        <span>
                          <strong> unprivileged:</strong> Female
                        </span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </RadioGroup>
        </LeftAlignedGrid>
      </TabPanel>
      <TabPanel index={1} value={activeStep}>
        <h3>2. Check the bias metrics in the dataset:</h3>
        <p>
          The following are the bias metrics which are majorly used for
          observing the bias.
        </p>
        <p>
          <b>Demographic Parity/Statistical Parity:</b> Likelihood of a positive
          outcome should be the same regardless of whether the person is in the
          protected group.
        </p>
        <p>
          <b>Equal Opportunity:</b> probability of a person
          in a positive class being assigned to a positive outcome should be
          equal for both protected and unprotected group members. Same true
          positive rate for all groups.
        </p>
        <p>
          <b>Equalized odds:</b> Probability of a person in the positive class
          being correctly assigned a positive outcome and the probability of a
          person in a negative class being incorrectly assigned a positive
          outcome should both be same for the protected and unprotected (male
          and female) group members. Same true postive and false positive rates.
        </p>
        <p>
          <b>Fairness Metrics:</b>
          <ul>
            <li>Disparate impact ratio = Pr(Y=1|D=unprivileged)/Pr(Y=1|D=privileged). Ideal Range - (0.8 to 1.25)</li>
            <li>Statistical parity difference = Pr(Y=1|D=unprivileged) - Pr(Y=1|D=privileged). Ideal Range - (-0.1 to 0.1)</li>
            <li>Equal opportunity difference = TPR|D=unprivileged - TPR|D=privileged. Ideal Range - (-0.1 to 0.1)</li>
            <li>Equalized odds difference/average odd difference =
          (FPR|D=unprivileged - FPR|D=privileged)/2 + (TPR|D=unprivileged -
          TPR|D=privileged)/2. Ideal Range - (-0.1 to 0.1)</li>
          </ul>
        </p>
        <hr />
        <DataSetDetails data={staticObj[datasetVal]} />
      </TabPanel>
      <TabPanel index={2} value={activeStep}>
        <Grid
          container
          spacing={2}
          xs={12}
          direction="row"
          justifyContent="center"
        >
          <LeftAlignedGrid item xs={12}>
            <h3>3. Choose bias mitigation algorithm</h3>
            <p>
              A variety of algorithms can be used to mitigate bias. The choice
              of which to use depends on whether you want to fix the data
              (pre-process), the classifier (in-process), or the predictions
              (post-process)
            </p>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              // name={radioName}
              value={mitigateVal}
              onChange={handleMitigationRadioChange}
            >
              <ul>
                {" "}
                <li>
                  {" "}
                  <strong>Pre-Processing Algorithms: </strong>
                  <br/>
                  <FormControlLabel
                    value="reweighing"
                    name="Reweighing"
                    control={<Radio />}
                    label={
                      <span>
                        {" "}
                        <strong>Reweighing:</strong> Weights the example in each
                        (group, label) combination differently to ensure
                        fairness before classification.
                      </span>
                    }
                  />
                  <br/>
                  <FormControlLabel
                    value="correlationRemover"
                    name="Correlation Remover"
                    control={<Radio />}
                    label={
                      <span>
                        <strong> Correlation Remover​:</strong>If the dataset
                        has limited features and most numerical features or most
                        features are having linear dependency on the target
                        feature then user can opt for Correlation Remover.{" "}
                      </span>
                    }
                  />
                </li>{" "}
                <br />
                <li>
                  {" "}
                  <strong>In-Processing Algorithms:</strong>
                  <br/>
                  <FormControlLabel
                    value="exponentialGradient"
                    name="Exponential Gradient:"
                    control={<Radio />}
                    label={
                      <span>
                        {" "}
                        <strong>Exponential Gradient: </strong>This can be used
                        when user want to try optimization in obtaining signed
                        weights when fitting the classifier.{" "}
                      </span>
                    }
                  />
                  <br/>
                  <FormControlLabel
                    value="gridSearch"
                    name="Grid Search"
                    control={<Radio />}
                    label={
                      <span>
                        {" "}
                        <strong> Grid Search:</strong>This can be used when the
                        grid limit number of iterations can be ran in optimizing
                        the signed weights.{" "}
                      </span>
                    }
                  />
                  <br/>
                  <FormControlLabel
                    value="adversarialFairnessClassifier"
                    name="Adversarial Fairness Classifier"
                    control={<Radio />}
                    label={
                      <span>
                        {" "}
                        <strong>Adversarial Fairness Classifier: </strong> When
                        the user want to try the neural networks in the
                        inprocessing stage, AdversarialFairnessClassifier can be
                        used. When pytorch is used as backend, the number of
                        categories in the sensitive feature should be at max
                        two.{" "}
                      </span>
                    }
                  />
                </li>{" "}
                <br />
                <li>
                  {" "}
                  <strong>Post-Processing Algorithm: </strong>
                  <br/>
                  <FormControlLabel
                    value="thresholdOptimizer"
                    name="Threshold Optimizer"
                    control={<Radio />}
                    label={
                      <span>
                        {" "}
                        <strong>Threshold Optimizer: </strong> Changes
                        predictions from a classifier to make them fairer.
                        Provides favorable outcomes to unprivileged groups and
                        unfavorable outcomesto privileged groups by setting the
                        different threshold value for different groups in the
                        sensitive feature.{" "}
                      </span>
                    }
                  />
                  <br/>
                </li>{" "}
              </ul>
            </RadioGroup>
          </LeftAlignedGrid>
        </Grid>
      </TabPanel>
      <TabPanel index={3} value={activeStep}>
        <h3>4. Compare original vs. mitigated results</h3>
        <DataSetDetails
          data={staticObj[datasetVal]?.["mitigation"]?.[mitigateVal]}
        />
      </TabPanel>
    </div>
  );
};

export default function ResponsibleUIDesc() {
  return (
    <SolutionContainerWrapper>
      <Collapse text="Description">
        <Paragraph>
          <strong>Responsible AI - Fairness:</strong>
          <p>
            This is an approach to assessing, developing, and deploying AI
            systems in a safe, trustworthy, and ethical manner. This includes
            the field of explainable and interpretable machine learning,
            fairness and bias in machine learning, law regulations as well as
            the aspect of user experience and human centralized AI.This is a
            cross-disciplinary field which includes both the field of computer
            science and social science.​
          </p>
          <strong>Fairness:</strong>
          <p>
            Machine learning (ML) becomes more effective and widespread it is
            becoming more prevalent in systems with real-life impact, from loan
            recommendations to medical expenditure decisions. With the growing
            usage comes the risk of bias — biased training data could lead to
            biased ML algorithms, which in turn could perpetuate discrimination
            and bias in society. The ultimate goal of building AI technologies
            based on responsible principles is to avoid dramatic negative
            consequences on human and societal well-being.​
          </p>
          <p>
            <strong>Key Pillars of Responsible AI - Fairness:​</strong>
            <ul>
              <li>
                <span>
                  {" "}
                  <b>Interpretable & Explainable AI : </b> Intuitive and inner
                  working of model & Post-hoc analysis​{" "}
                </span>
              </li>

              <li>
                <span>
                  {" "}
                  <b>Secure and Private AI:</b> Counter measures against threats
                  and privacy preserving model building techniques.
                </span>
              </li>
              <li>
                <span>
                  <b>Compliance and Ethical AI:</b>Keeping up with regulatory
                  changes and sociological fairness in model predictions.{" "}
                </span>
              </li>
              <li>
                <span>
                  <b>Reproducible AI: </b> Tracking model parameters and dataset
                  changes.​
                </span>
              </li>
              <li>
                <span>
                  <b>Accessible AI: </b> Expert-In-loop​
                </span>
              </li>
            </ul>
          </p>
          <p>
            <strong>Most common type of biases:​</strong>
            <ol>
              <li>
                <strong>Bias in training data:</strong>
                <ul>
                  <li>
                    <span>
                      {" "}
                      <b>Sampling Bias: </b>This occurs when some members of a
                      population are systematically more likely to be selected
                      in a sample than others.
                    </span>
                  </li>
                  <li>
                    <span>
                      {" "}
                      <b>Bias: </b>This is the bias introduced by the selection
                      of individuals, groups, or data for analysis in such a way
                      that proper randomization is not achieved.​
                    </span>
                  </li>
                  <li>
                    <span>
                      {" "}
                      <b>Reporting Bias: </b>This occurs when the dissemination
                      of research findings is influenced by the nature and
                      direction of results.​
                    </span>
                  </li>
                  <li>
                    <span>
                      {" "}
                      <b> Simpsons Paradox: </b>This is a statistical phenomenon
                      where an association between two variables in a population
                      emerges, disappears or reverses when the population is
                      divided into subpopulations. For instance, two variables
                      may be positively associated in a population, but be
                      independent or even negatively associated in all
                      subpopulations.
                    </span>
                  </li>
                </ul>
              </li>
              <br />
              <li>
                <strong>Implementation bias:</strong>
                <ul>
                  <li>
                    <span>
                      <b> Over-generalization:</b>It is the act of drawing
                      conclusions that are too broad because they exceed what
                      could be logically concluded from the available
                      information.
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>Correlation Fallacy:</b>
                      Correlation doesn’t imply causation.​
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>Automation Bias:</b>
                      It is the propensity for humans to favor suggestions from
                      automated decision-making systems and to ignore
                      contradictory information made without automation, even if
                      it is correct.
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>Labeling Errors:</b>
                      This occurs when the set of labeled data is not fully
                      representative of the entire universe of potential
                      labels.​
                    </span>
                  </li>
                </ul>
              </li>
            </ol>
          </p>
          <p>
            <center>
              <img src={fairness_overview} alt="" width="90%" />
            </center>
          </p>
        </Paragraph>
        <LinkContainer>
        <LinkContainer>
              <Grid container>
                <Grid item>
                  <StyledButton
                    variant="outlined"
                    color="primary"
                    size="large"
                    startIcon={<OpenInNewIcon />}
                  >
                    <a
                      href="https://material.vlifevirtusa.com/vResponsibleAI-fairness/vResponsible-Fairness_AdultDataset.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Notebook 1
                    </a>
                  </StyledButton>
                </Grid>
                <Grid item>
                  <StyledButton
                    variant="outlined"
                    color="primary"
                    size="large"
                    startIcon={<OpenInNewIcon />}
                  >
                    <a
                      href="https://material.vlifevirtusa.com/vResponsibleAI-fairness/vResponsible-Fairness_Credit%20card%20dataset.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Notebook 2
                    </a>
                  </StyledButton>
                </Grid>
                <Grid item>
                  <StyledButton
                    variant="outlined"
                    color="primary"
                    size="large"
                    startIcon={<OpenInNewIcon />}
                  >
                    <a
                      href="https://apc01.safelinks.protection.outlook.com/?url=https%3A%2F%2Farchive.ics.uci.edu%2Fdataset%2F2%2Fadult&data=05%7C01%7Cprathaptummala%40virtusa.com%7Cf4be39525b4e4ce4131408db83b3b740%7C0d85160c589944caacc8db1501b993b6%7C0%7C0%7C638248580197126242%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=iV%2BmL4YRqxeN3ld0b2qINOdeWHRgrkjYui19tRo4x40%3D&reserved=0"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Dataset 1
                    </a>
                  </StyledButton>
                </Grid>
                <Grid item>
                  <StyledButton
                    variant="outlined"
                    color="primary"
                    size="large"
                    startIcon={<OpenInNewIcon />}
                  >
                    <a
                      href="https://apc01.safelinks.protection.outlook.com/?url=http%3A%2F%2Farchive.ics.uci.edu%2Fdataset%2F144%2Fstatlog%2Bgerman%2Bcredit%2Bdata&data=05%7C01%7Cprathaptummala%40virtusa.com%7Cf4be39525b4e4ce4131408db83b3b740%7C0d85160c589944caacc8db1501b993b6%7C0%7C0%7C638248580197126242%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=2fXoI5vsQY%2Fu308FwK7OLGdDdv1JDYSSM2ZoTrAyPdo%3D&reserved=0"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Dataset 2
                    </a>
                  </StyledButton>
                </Grid>
                <Grid item>
                  <StyledButton
                    variant="outlined"
                    color="primary"
                    size="large"
                    startIcon={<OpenInNewIcon />}
                  >
                    <a
                      href="https://apc01.safelinks.protection.outlook.com/?url=https%3A%2F%2Farchive.ics.uci.edu%2Fdataset%2F296%2Fdiabetes%2B130-us%2Bhospitals%2Bfor%2Byears%2B1999-2008&data=05%7C01%7Cprathaptummala%40virtusa.com%7Cf4be39525b4e4ce4131408db83b3b740%7C0d85160c589944caacc8db1501b993b6%7C0%7C0%7C638248580197126242%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=nOWGw1eJAJ%2FXWE53e8hhXRsFL6MkasGTVBxHSmLwPOU%3D&reserved=0"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Dataset 3
                    </a>
                  </StyledButton>
                </Grid>
                <Grid item>
                  <StyledButton
                    variant="outlined"
                    color="primary"
                    size="large"
                    startIcon={<OpenInNewIcon />}
                  >
                    <a
                      href="https://apc01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.kaggle.com%2Fdatasets%2Fjeevannagaraj%2Findian-liver-patient-dataset&data=05%7C01%7Cprathaptummala%40virtusa.com%7Cf4be39525b4e4ce4131408db83b3b740%7C0d85160c589944caacc8db1501b993b6%7C0%7C0%7C638248580197126242%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=3zNwdxwuljd2OdHGvVJ4mnd%2BwNJWx6fn3dyRH3MIvxs%3D&reserved=0"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Dataset 4
                    </a>
                  </StyledButton>
                </Grid>
              </Grid>
            </LinkContainer>
        </LinkContainer>
      </Collapse>
    </SolutionContainerWrapper>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`a11y-tabpanel-${index}`}
      aria-labelledby={`a11y-tab-${index}`}
      {...other}
    >
      {value === index && <Paragraph p={3}>{children}</Paragraph>}
    </div>
  );
}
const LeftAlignedGrid = styled(Grid)`
  text-align: left;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    &.MuiTypography-body1 {
      font-weight: 600;
    }
  }
`;
