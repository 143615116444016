import React, { memo, useState } from "react";
import styled from "styled-components";
import { Grid, Paper } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// import local components

import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import Spinner from "../../common/loading/Spinner";

// images
import footWoundImageSegmentation_sol1 from "../../../assets/images/app/footWoundImageSegmentation_sol1.webp";
import footWoundImageSegmentation_sample1 from "../../../assets/images/app/footWoundImageSegmentation_sample1.webp";
import footWoundImageSegmentation_sample2 from "../../../assets/images/app/footWoundImageSegmentation_sample2.webp";
import footWoundImageSegmentation_sample3 from "../../../assets/images/app/footWoundImageSegmentation_sample3.webp";
import footWoundImageSegmentation_sample4 from "../../../assets/images/app/footWoundImageSegmentation_sample4.webp";
import PresignedS3Link from "../../common/PresignedS3Link";

const FootWoundImageSegmentation = () => {
  const [showSpinner, setSpinner] = useState(false);
  const [selectedImage1, setSelectedImage1] = useState(false);
  const [selectedImage2, setSelectedImage2] = useState(false);
  const [notification] = useState("");

  const selectedImageFunc1 = () => {
    setSpinner(true);
    setSelectedImage2(false);
    setSelectedImage1(true);
    setSpinner(false);
  };
  const selectedImageFunc2 = () => {
    setSpinner(true);
    setSelectedImage2(true);
    setSelectedImage1(false);
    setSpinner(false);
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            <b>Background:</b>
          </p>
          <p>
            Foot ulceration is the most common complication of diabetes and
            represents a major health problem all over the world. If these
            ulcers are not adequately treated in an early stage, they may lead
            to lower limb amputation. Considering the low cost and prevalence of
            smartphones with a high-resolution camera, Diabetic Foot Ulcer (DFU)
            healing assessment by image analysis becomes an attractive option to
            help clinicians for more accurate and objective management of the
            ulcer. Image Segmentation is the process of dividing an image into
            different regions based on the characteristics of pixels to identify
            objects or boundaries to simplify an image and more efficiently
            analyze it.
          </p>
          <p>
            <b> Model Architecture:</b>
          </p>
          <p>
            This application makes use of a U-net model (CNN network
            architecture) to read grayscale images of foot wounds and produce a
            mask image as an output, which can be then used to segment the wound
            area from the input image i.e. image segmentation. The U-net
            architecture is symmetric and consists of two major parts — the left
            part is called the contracting path, which comprises the general
            convolutional process; the right part is the expansive path, which
            comprises the transposed 2d convolutional layers.
          </p>
          <center>
            <img src={footWoundImageSegmentation_sol1} alt="" />
          </center>
          <p>
            A U-formed design comprises of a particular encoder-decoder plot;
            the encoder decreases the spatial measurements in each layer and
            expands the channels, while the decoder builds the spatial darkens
            while lessening the channels. The tensor that is passed in the
            decoder is generally called a bottleneck. Eventually, the spatial
            diminishes are reestablished to make a forecast for every pixel in
            the information picture. These sorts of models are amazingly used in
            true applications.
            <br />
            <br />
            Before settling on a U-net model a couple of options were considered
            such as Mask-RCNN, Faster RCNN, and faster ai. Ultimately, U-net was
            chosen because it gave us the best results in comparison to any of
            the other models that were implemented. The U-net model is able to
            predict our training masks with __% accuracy and our validation
            images with __% accuracy. This accuracy is calculated using dice
            coefficients.
          </p>
          <p>
            <b>Dataset:</b>
          </p>
          <p>
            The dataset used to train this model consists of approximately 600
            images of foot wounds and their corresponding masks. In order to
            train the model, the images in the training dataset are preprocessed
            using techniques such as grayscale conversion, denoising, applying
            filters, and cropping any unnecessary information that was present
            in the images. The model is then tested using a set of validation
            images.
          </p>
          <LinkContainer>
            <Grid container spacing={2}>

              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <PresignedS3Link
                    href="WoundSeg/FootWoundSegmentation.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Notebook
                  </PresignedS3Link>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <br />
        <DemoContainer>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12} md={4}>
              <MaskPaper elevation={3}>
                <Image src={footWoundImageSegmentation_sample1} />
                <br />
                <MarginButton
                  variant="contained"
                  color="primary"
                  onClick={selectedImageFunc1}
                >
                  Sample 1
                </MarginButton>
              </MaskPaper>
            </Grid>
            <Grid item xs={12} md={4}>
              <MaskPaper>
                <Image src={footWoundImageSegmentation_sample2} />
                <br />
                <MarginButton
                  variant="contained"
                  color="primary"
                  onClick={selectedImageFunc2}
                >
                  Sample 2
                </MarginButton>
              </MaskPaper>
            </Grid>
          </Grid>

          <section>
            <div>
              <br />
              {showSpinner && <Spinner text="Loading..." />}
            </div>
          </section>
          {selectedImage1 && (
            <section>
              <section>
                <Result>
                  The identified wound is highlighted in the image. The wound
                  area is calculated to be 806.73 mm2
                </Result>
                <br />
                <br />
                <img src={footWoundImageSegmentation_sample3} alt="" />
              </section>
            </section>
          )}
          {selectedImage2 && (
            <section>
              <br />

              <section>
                <Result variant="outlined" color="primary">
                  The identified wound is highlighted in the image. The wound
                  area is calculated to be 137.09 mm2
                </Result>
                <br />
                <br />
                <img src={footWoundImageSegmentation_sample4} alt="" />
              </section>
            </section>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(FootWoundImageSegmentation);
const MaskPaper = styled(Paper)`
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  @media only screen and (max-width: 450px) {
    padding-top: 0;
    height: auto;
    max-height: 300px;
    padding-bottom: 10px;
    .MuiTypography-root {
      font-size: 1rem;
    }
  }
`;
const Image = styled.img`
  width: 100%;
  max-width: 300px;
  max-height: 230px;
`;
const MarginButton = styled(StyledButton)`
  margin-right: 10px;
  margin-left: 10px;
`;
