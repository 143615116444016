import React, { memo, useState } from 'react';
import styled from 'styled-components';
import { Grid, TextField } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Grow from '@material-ui/core/Grow';
import Input from '@material-ui/core/Input';
import GetAppIcon from '@material-ui/icons/GetApp';

// import local components
import CommonService from '../../../utils/services/CommonService';

import Collapse from '../../common/Collapse';
import Spinner from '../../common/loading/Spinner';
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  List,
  FormControl,
  ListItem,
} from '../../../styles/common';
import SolutionContainer from '../../common/SolutionContainerWrapper';

// images
import eegClassification_sol1 from '../../../assets/images/app/eeg_main.webp';
import eegClassification_sol2 from '../../../assets/images/app/sleep_eeg3.webp';
import eegClassification_sol3 from '../../../assets/images/app/sleep_eeg4.webp';

const { getQueryResult, uploadFilesWithBody } = CommonService(
  'z1dApps',
  'eegClassification',
);

const EEGClassification = () => {
  const [showSpinner, setSpinner] = useState(false);
  const [result, setResult] = useState('');
  const [multipleFiles, setMultipleFiles] = useState('');
  const [imageResult, setImageResult] = useState('');
  const [notification, setNotification] = useState('');

  const getsampleResult = (e) => {
    setSpinner(true);
    setResult('');
    const selectedSample = e.currentTarget.getAttribute('data-sample');
    getQueryResult('sample', selectedSample)
      .then((res) => {
        setSpinner(false);
        setResult(true);
        setImageResult(res.image_url);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: 'error', message: err.message });
      });
  };

  const getUploadResult = (e) => {
    setSpinner(true);
    setResult('');
    console.log(multipleFiles);
    const files = { file1: multipleFiles[0], file2: multipleFiles[1] };
    uploadFilesWithBody('upload', files)
      .then((res) => {
        setSpinner(false);
        setResult(true);
        setImageResult(res.image_url);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: 'error', message: err.message });
      });
  };

  const MultipleFileChange = (e) => {
    const MAX_LENGTH = 2;
    if (Array.from(e.target.files).length > MAX_LENGTH) {
      e.preventDefault();
      setNotification({
        open: 'error',
        message: 'Cannot upload more than 2 Files',
      });
      return;
    }
    setMultipleFiles(e.target.files);
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Quality Sleep is an important part of a healthy lifestyle as lack of
            it can cause a list of issues like a higher risk of cancer and
            chronic fatigue. This means that having the tools to automatically
            and easily monitor sleep can be powerful to help people sleep
            better.
          </p>
          <p>
            Doctors use a recording of a signal called EEG which measures the
            electrical activity of the brain using an electrode to understand
            sleep stages of a patient and make a diagnosis about the quality of
            their sleep.
          </p>
          <p>
            In this tool, a neural network is used to perform the sleep stage
            classification automatically from Electroencephalograms(EEGs).
          </p>
          <p>
            <strong>Data: </strong>
          </p>
          {' '}
          <p>
            This tool is based on a publicly available EEG Sleep data (
            <a
              href=" https://www.physionet.org/content/sleep-edfx/1.0.0/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Sleep-EDF
            </a>
            ) that was done on 20 subject, 19 of which have 2 full nights of
            sleep.
          </p>
          <p>
            <strong>Model Description: </strong>
          </p>
          {' '}
          <p>
            The approach used here is a sub-model that encodes each epoch into a
            1D vector of fixed size and then a second sequential sub-model that
            maps each epoch’s vector into a class from "W", "N1", "N2", "N3",
            "REM".
          </p>
          <Grid
            container
            xs={12}
            spacing={2}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <center>
              <Grid item xs={12} sm={12} md={12}>
                <Image src={eegClassification_sol2} />
              </Grid>
            </center>
            <center>
              <Grid item xs={12} sm={12} md={12}>
                <Image src={eegClassification_sol3} />
              </Grid>
            </center>
          </Grid>
          <p>
            Sleep Stages - There are two basic types of sleep: rapid eye
            movement (REM) sleep and non-REM sleep (which has three different
            stages). Each is linked to specific brain waves and neuronal
            activity.
            {' '}
          </p>
          <p>
            <strong> N1 - Stage 1</strong>
            {' '}
            non-REM sleep is the changeover from
            wakefulness to sleep. Your brain waves begin to slow from their
            daytime wakefulness patterns.
            {' '}
          </p>
          <p>
            <strong>N2 - Stage 2</strong>
            {' '}
            non-REM sleep is a period of light
            sleep before you enter deeper sleep. Your heartbeat and breathing
            slow, and muscles relax even further. Brain wave activity slows but
            is marked by brief bursts of electrical activity.
            {' '}
          </p>
          <p>
            <strong>N3 - Stage 3</strong>
            {' '}
            non-REM sleep is the period of deep
            sleep that you need to feel refreshed in the morning. Brain waves
            become even slower.
            {' '}
          </p>
          <p>
            <strong>REM sleep</strong>
            {' '}
            first occurs about 90 minutes after
            falling asleep. Your eyes move rapidly from side to side behind
            closed eyelids. Mixed frequency brain wave activity becomes closer
            to that seen in wakefulness.
          </p>
          <center>
            <Grid item xs={10} sm={8} md={6}>
              <img width="60%" src={eegClassification_sol1} />
            </Grid>
          </center>
          <p>Input to the application (.edf files) : </p>
          <List>
            <ListItem>
              Raw data – *PSG.edf files which are whole-night polysmnographic
              sleep recordings containing EEG data.
            </ListItem>
            <ListItem>
              *Hypnogram.edf files containing annotations of the sleep patterns
              that correspond to the PSGs. These patterns (hypnograms) consist
              of sleep stages. (used for data preprocessing and removing
              unwanted sleep patterns from raw data)
            </ListItem>
          </List>
          <p>
            Here a 1D CNN is used to encode each Epoch and then another 1D CNN
            that labels the sequence of epochs to create the final hypnogram.
            The CNN-CNN-CRF model performs well because the CRF helps learn the
            transition probabilities between classes. The result is a final
            hypnogram which tells the predicted sleeping stage of the person.
          </p>
          <LinkContainer>
            <Grid container spacing={2}>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://apc01.safelinks.protection.outlook.com/GetUrlReputation"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/EEG/Electroencephalography.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<GetAppIcon />}
                >
                  <a
                    href="https://www.physionet.org/files/sleep-edfx/1.0.0/sleep-cassette/SC4001E0-PSG.edf?download"
                    download
                  >
                    Sample Raw Input
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<GetAppIcon />}
                >
                  <a
                    href="https://www.physionet.org/files/sleep-edfx/1.0.0/sleep-cassette/SC4001EC-Hypnogram.edf?download"
                    download
                  >
                    Sample Hypnogram
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid
            container
            direction="row"
            spacing={2}
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={4} md={2}>
              <StyledButton
                variant="contained"
                color="primary"
                data-sample="sample1/"
                onClick={getsampleResult}
              >
                Sample1
              </StyledButton>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <StyledButton
                variant="contained"
                color="primary"
                data-sample="sample2/"
                onClick={getsampleResult}
              >
                Sample2
              </StyledButton>
            </Grid>
            <Grid item xs={12} sm={12} md={1}>
              <h3>OR</h3>
            </Grid>
            <Grid item xs={12} sm={5} md={3}>
              <input
                type="file"
                multiple
                name="uploadedFile"
                onChange={(e) => MultipleFileChange(e)}
              />
              <Paragraph style={{ fontSize: '12px', padding: '0px 20px' }}>
                Select 2 files and upload
                <br />
                For raw data - *PSG.edf
                <br />
                For Hypnogram - *Hypnogram.edf
              </Paragraph>
            </Grid>
            <Grid item xs={6} sm={3} md={2}>
              <StyledButton
                style={{ marginTop: '0' }}
                variant="contained"
                color="primary"
                onClick={getUploadResult}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
            <Grow in={result} timeout={1500}>
              <div>
                <section>
                  <Result>
                    <strong>Result : </strong>
                  </Result>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} sm={10} md={8}>
                      <Resultimage
                        width="80%"
                        height="350px"
                        src={imageResult}
                        alt=""
                      />
                    </Grid>
                    <ResultGrid item xs={12} sm={2} md={4}>
                      <strong>
                        <List type="none">
                          <strong>Classes</strong>
                          <ListItem>0 : W</ListItem>
                          <ListItem>1 : N1</ListItem>
                          <ListItem>2 : N2</ListItem>
                          <ListItem>3 : N3</ListItem>
                          <ListItem>4 : REM</ListItem>
                        </List>
                      </strong>
                    </ResultGrid>
                  </Grid>
                </section>
              </div>
            </Grow>
          )}
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(EEGClassification);

const Image = styled.img`
  height: 350px;
  width: 350px;
  @media only screen and (max-width: 900px) {
    height: auto;
    max-height: 300px;
    width: auto;
    max-width: 300px;
  }
  @media only screen and (max-width: 450px) {
    height: auto;
    max-height: 250px;
    width: auto;
    max-width: 250px;
  }
`;
const Resultimage = styled.img`
  @media only screen and (max-width: 450px) {
    height: auto;
    max-height: 300px;
    width: auto;
    max-width: 300px;
  }
`;

const ResultGrid = styled(Grid)`
  @media only screen and (max-width: 600px) {
    margin-left: 40%;
  }
  @media only screen and (max-width: 450px) {
    margin-left: 35%;
  }
`;

const StyledTextField = styled(TextField)`
  padding: 0;
`;
