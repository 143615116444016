import React, { memo, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

// import local components
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Collapse from '../../common/Collapse';
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import ImageAnalysisInput from '../../common/ImageAnalysisInput';
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  SolutionContainer,
  LinkContainer,
  Result,
} from '../../../styles/common';

// images
import deepNuclie_sol1 from '../../../assets/images/app/deepNuclie_sol1.webp';

const DeepNuclie = () => {
  const [response, setResponse] = useState(false);
  const [notification, setNotification] = useState('');

  const getResponse = (response) => {
    const imageResponse =
      typeof response === 'object' ? response.result : response;
    setResponse(imageResponse);
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <b>What is Deep Nuclei?</b>
          <br />
          <p>
            The deep cerebellar nuclei (DCN) are the sole output channel of the
            cerebellum and form part of the cerebellar system of closed loops
            connected to the sensorimotor region, the associative cortices, and
            the limbic system.
          </p>
          <p>
            The deep cerebellar nuclei (DCN) consist of three nuclei: the
            fastigial (medial) nucleus, the interposed nucleus and the dentate
            (lateral) nucleus. Together they form the sole output of the
            cerebellum.
          </p>
          <i>
            <b>Functions Deep Nuclei:</b>
          </i>
          <br />
          <p>
            It receives input from the vermis and from cerebellar afferents that
            carry vestibular, proximal somatosensory, auditory, and visual
            information.
          </p>
          <p>
            An Algorithm using advanced Analytics concepts of Image segmentation
            to automate the process of nucleus detection.
          </p>
          <p>
            Automated Nucleus detection could help unlock faster cures – from
            rare disorders, like the covid 19 disease to a common cold
          </p>
          <b>Why Deep Nulcei?</b>
          <p>
            Identifying the cells’ nuclei is the starting point for most
            analyses because most of the human body’s 30 trillion cells contain
            a nucleus full of DNA, the genetic code that programs each cell.
            Identifying nuclei allows researchers to identify each individual
            cell in a sample, and by measuring how cells react to various
            treatments, the researcher can understand the underlying biological
            processes at work.
          </p>
          <center>
            <img src={deepNuclie_sol1} />
          </center>
          <br />
          <b>Technique and Functions used </b>
          <p>
            The images are initially cleaned and resized, converted to gray
            scale and then passed in a customised UNet Model to deduce an output
          </p>
          <p>
            The model requires the user to upload image of a blood sample
            containing multiple images and then the model segments the image to
            identify all the nucleus present in the image
          </p>
          <p>
            The model takes image of a blood sample as an input and the
            algorithm then identifies the nuclei present in the image
          </p>
          <p>
            <LinkContainer>
              <Grid container spacing={2}>
                <Grid item>
                  <StyledButton
                    variant="outlined"
                    color="primary"
                    size="large"
                    startIcon={<OpenInNewIcon />}
                  >
                    <a
                      href="https://www.kaggle.com/competitions/data-science-bowl-2018/overview"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Data Source
                    </a>
                  </StyledButton>
                </Grid>
                <Grid item>
                  <StyledButton
                    variant="outlined"
                    color="primary"
                    size="large"
                    startIcon={<OpenInNewIcon />}
                  >
                    <a
                      href="https://www.kaggle.com/competitions/data-science-bowl-2018/code"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Notebook
                    </a>
                  </StyledButton>
                </Grid>
              </Grid>
            </LinkContainer>
          </p>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <ImageAnalysisInput
            img1="deepNuclie_sample2.webp"
            img2="deepNuclie_sample1.webp"
            url={['bioInformatics', 'deepNuclie']}
            sample1url="sample_image1"
            sample2url="sample_image2"
            result={getResponse}
          />
          {response && (
            <section>
              <Result>
                <strong>Result:</strong>
              </Result>
              <ResponsiveTypography align="center" variant="h6" gutterBottom>
                <FontAwesomeIcon icon={faInfoCircle} />
                white spots represent the identified nuclei in the image
              </ResponsiveTypography>
              <center>
                <Image width="250px" height="250px" src={response} alt="" />
              </center>
            </section>
          )}
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(DeepNuclie);
const Image = styled.img`
  @media only screen and (max-width: 1100px) {
    max-width: 130px;
    max-height: 130px;
  }
`;
const ResponsiveTypography = styled(Typography)`
  @media only screen and (max-width: 900px) {
    font-size: 15px;
  }
  @media only screen and (max-width: 700px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 700px) {
    font-size: 12px;
  }
`;
