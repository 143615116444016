import React, { memo, useState, useEffect } from "react";
import {
  Grid, TextField, FormControl, MenuItem,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components
import styled from "styled-components";

import SolutionContainer from "../../common/SolutionContainerWrapper";
import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";
import { getUploadToken } from "../../../utils";

const DrugRecommendation = () => {
  const prescribedMedicationList = [
    "Drug #1",
    "GABAPENTIN",
    "LAMOTRIGINE",
    "METHOTREXATE",
    "IBUPROFEN",
    "RENVELA",
    "RANITIDINE HCL",
    "PEG-3350 AND ELECTROLYTES",
    "AZITHROMYCIN",
    "CARBIDOPA-LEVODOPA",
    "OXYCODONE HCL",
    "MELOXICAM",
    "OMEPRAZOLE",
    "HYDROCODONE-ACETAMINOPHEN",
    "LISINOPRIL",
    "SYNTHROID",
    "BD ULTRA-FINE PEN NEEDLE",
    "SERTRALINE HCL",
    "BENZTROPINE MESYLATE",
    "KETOCONAZOLE",
    "SPIRIVA",
    "MODAFINIL",
    "SUBOXONE",
    "WARFARIN SODIUM",
    "LOVAZA",
    "LEVETIRACETAM",
    "NAMENDA",
    "OMEPRAZOLE",
    "NORVIR",
    "ESTRADIOL",
    "WARFARIN SODIUM",
    "BUPROPION XL",
    "DEXAMETHASONE",
    "ANASTROZOLE",
    "CLOPIDOGREL",
    "SIMVASTATIN",
    "CARISOPRODOL",
    "ECONAZOLE NITRATE",
    "LORAZEPAM",
    "CEPHALEXIN",
    "AMMONIUM LACTATE",
    "FLUTICASONE PROPIONATE",
    "ALPRAZOLAM",
    "AMOXICILLIN",
    "LACTULOSE",
    "AMLODIPINE BESYLATE",
    "BUSPIRONE HCL",
    "TAMSULOSIN HCL",
    "ATORVASTATIN CALCIUM",
    "MIRTAZAPINE",
  ];
  const specialitysList = [
    {
      value: 0,
      label: "General Practice",
    },
    {
      value: 1,
      label: "Psychiatry",
    },
    {
      value: 2,
      label: "Family",
    },
    {
      value: 3,
      label: "Gastroenterology",
    },
    {
      value: 4,
      label: "Adult Health",
    },
    {
      value: 5,
      label: "Cardiovascular Disease",
    },
    {
      value: 6,
      label: "Medical",
    },
    {
      value: 7,
      label: "Neurology",
    },
    {
      value: 8,
      label: "Pulmonary Disease",
    },
    {
      value: 9,
      label: "Psych/Mental Health",
    },
    {
      value: 10,
      label: "Infectious Disease",
    },
    {
      value: 11,
      label: "Nephrology",
    },
    {
      value: 12,
      label: "Periodontics",
    },
    {
      value: 13,
      label: "Endocrinology, Diabetes & Metabolism",
    },
    {
      value: 14,
      label: "Surgical Technologist",
    },
    {
      value: 15,
      label: "Endodontics",
    },
    {
      value: 16,
      label: "Geriatric Medicine",
    },
    {
      value: 17,
      label: "Foot & Ankle Surgery",
    },
    {
      value: 18,
      label: "Emergency Medical Services",
    },
    {
      value: 19,
      label: "Child & Adolescent Psychiatry",
    },
    {
      value: 20,
      label: "Sports Medicine",
    },
    {
      value: 21,
      label: "Oral and Maxillofacial Surgery",
    },
    {
      value: 22,
      label: "Pain Medicine",
    },
    {
      value: 23,
      label: "Pediatric Cardiology",
    },
    {
      value: 24,
      label: "Foot and Ankle Surgery",
    },
    {
      value: 25,
      label: "Rheumatology",
    },
    {
      value: 26,
      label: "Hand Surgery",
    },
    {
      value: 27,
      label: "Clinical Cardiac Electrophysiology",
    },
    {
      value: 28,
      label: "Critical Care Medicine",
    },
    {
      value: 29,
      label: "Medical Oncology",
    },
    {
      value: 30,
      label: "Psych/Mental Health, Adult",
    },
    {
      value: 31,
      label: "Hematology",
    },
    {
      value: 32,
      label: "Plastic and Reconstructive Surgery",
    },
    {
      value: 33,
      label: "Womens Health",
    },
    {
      value: 34,
      label: "Hospice and Palliative Medicine",
    },
    {
      value: 35,
      label: "Gynecology",
    },
    {
      value: 36,
      label: "Radiation Oncology",
    },
  ];

  const initialData = {
    speciality: "",
    expertise: "",
    medicationprescribed: "",
  };

  const [parameters, setParameters] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [samplevalue, setSample] = useState("");
  const [notification, setNotification] = useState("");

  const sampleResult = {
    sample: [
      "LOSARTAN POTASSIUM",
      "ATENOLOL",
      "ALENDRONATE SODIUM",
      "LOVASTATIN",
    ],
  };

  const sample = {
    speciality: "0",
    expertise: "2",
    medicationprescribed: "SIMVASTATIN",
  };

  const { getQueryResult } = CommonService(
    "commercialAnalytics",
    "drugRecommendation",
  );
  const Token = getUploadToken()

  const loadSample1Data = (e) => {
    setParameters(sample);
    setSample(sample);
  };

  const onChangeFields = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSample("");
    setParameters({
      ...parameters,
      [name]: value,
    });
  };

  const getPrediction = () => {
    const { speciality, expertise, medicationprescribed } = parameters;
    setResultData("");
    if (samplevalue) {
      setResultData(sampleResult.sample);
      return;
    }
    const params = `${speciality},${expertise},${medicationprescribed},${Token.token}`;
    let areValuesEmpty = false;
    Object.keys(parameters).forEach((key) => {
      if (parameters[key] === "") {
        areValuesEmpty = true;
      }
    });
    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("sample", params)
        .then((res) => {
          setSpinner(false);
          setResultData(res.PFC);
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          setSpinner(false);
          setNotification({ open: "error", message: err.message });
        });
    }
  };
  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            <b>Overview :</b>
            {' '}
            Physicians are considered to be the most important
            audience in pharmaceutical sales since they prescribe the medicines
            to the patients. Hence, its important that the Pharma companies
            target physicians to increase their sales. So, The Drug
            manufacturing companies always want to win medical practitioner
            because of their influence on the decision-making of the broader
            medical community.
            <br />
            <br />
            <b>Problem:</b>
            {' '}
            Even though patients are the ultimate consumers of
            the drugs, Physicians and surgeons are viewed as the customers by
            the pharma companies. The challenge for the providers is to identify
            the right set of physicians who can help them boost the sales and
            place the drug precisely for the suitable patients.
            <br />
            <br />
            <b>Approach:</b>
            {' '}
            A ML model is trained with features such as area of
            expertise, years of experience and drug in contention to predict the
            most probable drugs that can be referred to the concerned
            practitioner.
          </p>
          <LinkContainer>
            <Grid container spacing={2}>

              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Drug_recommendation_for_practitioner"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Drug_recommendation_for_practitioner/Data"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Data source
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <br />
      <Collapse text="Demo">
        <br />
        <DemoContainer>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl varient="outlined" fullWidth>
                <TextField
                  id="outlined-adornment-weight"
                  select
                  variant="outlined"
                  label="Speciality*"
                  name="speciality"
                  // error=
                  // helperText=
                  value={parameters.speciality}
                  onChange={onChangeFields}
                >
                  {specialitysList.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  select
                  variant="outlined"
                  label="Medication Prescribed*"
                  name="medicationprescribed"
                  // error=
                  // helperText=
                  value={parameters.medicationprescribed}
                  onChange={onChangeFields}
                >
                  {prescribedMedicationList.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              {" "}
              <FormControl fullWidth>
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Year of Expertise*"
                  name="expertise"
                  // error=
                  // helperText=
                  value={parameters.expertise}
                  onChange={onChangeFields}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <br />
              <StyledButton
                variant="contained"
                color="primary"
                onClick={loadSample1Data}
              >
                Sample Data
              </StyledButton>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={getPrediction}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          <br />
          <Grid item xs={12}>
            {showSpinner && <Spinner text="Loading..." />}

            {resultData && (
              <Result>
                <strong>Result</strong>
                {`Other medications that can be positioned for physician’s consideration are : ${resultData}`}
              </Result>
            )}
          </Grid>
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(DrugRecommendation);
const StyledFigure = styled.figure`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #274c77;
`;
const Image = styled.img`
  border-radius: 3px;
`;
