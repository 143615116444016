import React, { memo, useState } from 'react';
import {
  Grid,
  TextField,
  Divider,
  ListItem,
  List,
  ListItemText,
  FormControl,
  MenuItem,
  Typography,
  Button,
} from '@material-ui/core';
import styled from 'styled-components';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
//import { ReactMic } from '@cleandersonlobo/react-mic';

// local components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileAudio,
  faFileUpload,
  faMicrophoneAlt,
} from '@fortawesome/free-solid-svg-icons';
import Collapse from '../../common/Collapse';

import Spinner from '../../common/loading/Spinner';
import SolutionContainer from '../../common/SolutionContainerWrapper';
import CommonService from '../../../utils/services/CommonService';
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  HighchartContainer,
  Result,
} from '../../../styles/common';

const PredictParkinsonDisease = () => {
  const [notification, setNotification] = useState({});
  const [showUpload, setUpload] = useState(true);
  const [showRecord, setShowRecord] = useState(false);
  const [showSample, setSample] = useState(false);
  const [uploadUrl, setUploadUrl] = useState('');
  const [sample1, setsample1] = useState('');
  const [sample2, setsample2] = useState('');
  const [isLoading, setLoading] = useState('');
  const [response, setResponse] = useState('');
  const [selectedFile, setselectedFile] = useState('');
  const [openRecord, setOpenRecord] = useState(false);
  const [mic, setMic] = useState(false);
  const [audioBlob, setAudioBlob] = useState(false);
  const [record, setRecord] = useState(false);
  const [uploadedAudioFile, setUploadedAudioFile] = useState('');

  const { getQueryResult, uploadFile } = CommonService(
    'z1dApps',
    'parkinsonDisease'
  );

  const openUpload = () => {
    setUpload(true);
    setSample(false);
    setsample1(false);
    setsample2(false);
    setOpenRecord('');
    setResponse('');
  };

  const openSample = () => {
    setUpload(false);
    setSample(true);
    setsample1(false);
    setsample2(false);
    setOpenRecord('');
    setResponse('');
  };

  const openRecords = () => {
    setOpenRecord(true);
    setMic(true);
    setUpload(false);
    setSample(false);
    setsample1(false);
    setsample2(false);
    setResponse('');
  };

  const startRecording = () => {
    setRecord(true);
  };

  const stopRecording = () => {
    setRecord(false);
  };

  const onStop = (recordedBlob) => {
    setAudioBlob(recordedBlob.blobURL);
    setMic(false);
    getBlobData(recordedBlob.blobURL);
  };

  const getBlobData = async (blobURL) => {
    const file = await fetch(blobURL)
      .then((r) => r.blob())
      .then(
        (blobFile) => new File([blobFile], 'file.wav', { type: 'audio/wav' })
      );
    setRecodedaudio(file);
  };

  const setRecodedaudio = (selectedFile) => {
    setUploadedAudioFile(selectedFile);
  };

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.name.split('.')[1] !== 'wav') {
        setNotification({ open: 'error', message: 'please upload .wav file' });
        return;
      }
      setselectedFile(file);
      setResponse('');
      setUploadUrl(URL.createObjectURL(file));
    } else {
      setResponse('');
      setUploadUrl('');
    }
  };

  const getuploadResult = () => {
    if (!selectedFile) {
      setNotification({ open: 'error', message: 'please upload a file' });
      return;
    }
    setLoading(true);
    uploadFile('upload', selectedFile)
      .then((res) => {
        getQueryResult('predict')
          .then((result) => {
            setLoading(false);
            setResponse(result.results[0].val);
          })
          .catch((err) => {
            setLoading(false);
            setNotification({ open: 'error', message: err.message });
          });
      })
      .catch((err) => {
        setNotification({ open: 'error', message: err.message });
        setLoading(false);
      });
  };
  const loadSample1Data = () => {
    setLoading(true);
    setResponse('');
    getQueryResult('sample', 'test_true.wav')
      .then((resp) => {
        setLoading(false);
        setsample1(true);
        setSample(false);
      })
      .catch((err) => {
        setNotification({ open: 'error', message: err.message });
        setLoading(false);
      });
  };
  const loadSample2Data = () => {
    setLoading(true);
    setResponse('');
    getQueryResult('sample', 'test1.wav')
      .then((resp) => {
        setLoading(false);
        setsample2(true);
        setSample(false);
      })
      .catch((err) => {
        setNotification({ open: 'error', message: err.message });
        setLoading(false);
      });
  };

  const uploadSample = () => {
    setLoading(true);
    setResponse('');
    getQueryResult('predict')
      .then((result) => {
        setLoading(false);
        setResponse(result.results[0].val);
      })
      .catch((err) => {
        setLoading(false);
        setNotification({ open: 'error', message: err.message });
      });
  };

  const getUploadAudioRes = () => {
    if (!uploadedAudioFile) {
      setAlert('warning', 'Please choose the file');
      setResponse('');
    } else {
      setLoading(true);
      setResponse('');
      // uploadFile("sample", uploadedAudioFile)
      //   .then((res) => {
      //     setLoading(false);
      //     setResponse(res.result);
      //     console.log(res);
      //   })
      uploadFile('upload', uploadedAudioFile)
        .then((res) => {
          getQueryResult('predict')
            .then((result) => {
              setLoading(false);
              setResponse(result.results[0].val);
            })
            .catch((err) => {
              setLoading(false);
              setNotification({ open: 'error', message: err.message });
            });
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          setNotification({ open: 'error', message: err.message });
        });
    }
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <p>
          Disease state analysis in Parkinson’s patient is an automated method
          to predict whether the patient has Parkinson’s disease or not based on
          patients voice features.
        </p>
        <p>
          <b>Context:</b>
          <br />
          PD is a progressive nervous system disorder that affects movement.
          Symptoms start gradually, sometimes with a barely noticeable tremor in
          just one hand or speech changes. Although PD can’t be cured,
          medications might significantly improve the health. The signs and
          symptoms can be different for everyone where Early signs may be mild
          enough to go unnoticed and may include tremor, slowed movement, rigid
          muscles, impaired posture and balance, and speech and writing changes.
          PD is the second most common neurodegenerative disorder in the United
          States following Alzheimer’s disease. The burden of chronic conditions
          such as PD is projected to grow substantially over the next few
          decades as the size of the elderly population grows.
        </p>
        <p>
          <b>Application Offerings:</b>
          <br />
          In this proof of concept, we use Advanced Machine Learning concepts to
          diagnose whether a patient is suffering from PD by analysing patient’s
          voice data. Research suggests that speech production can be modeled as
          a nonlinear dynamical system, wherein small perturbations in the
          interaction of its parts give rise to chaotic yet deterministic
          behavior.
        </p>
        <p>
          <b>Data:</b>
          <br />
          Parkinson Multiple Sound Recording datasetis available in vLife™. The
          data belongs to 20 patients with PD (6 males and 14 females) and 20
          healthy subjects (10 male and 10 female). Multiple types of sound
          recordings are taken from each subject. Praat software is used to
          calculate features like shimmer, jitter, harmonic to noise (HTN), and
          pitch from each subject’s voice.
        </p>
        <b>Working with Disease state analysis in Parkinson’s patient</b>
        <Paragraph>
          <ul>
            <li>
              User can upload his/her voice note as an input for this model.
            </li>
            <li>
              After uploading the voice note model will extract the voice
              features from that note and will predict whether the patient has
              Parkinson’s disease of not.
            </li>
          </ul>
        </Paragraph>

        <LinkContainer>
          <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Parkinson's_Disease"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Parkinson's_Disease/Data"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Data source
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container alignItems="center">
            <Grid item xs={12} md={3}>
              <List>
                <Divider />
                <StyledListItem
                  button
                  onClick={openUpload}
                  isSelected={showUpload}
                >
                  <FontAwesomeIcon icon={faFileUpload} />{' '}
                  <ListItemText primary="UPLOAD" href="#simple-list" />
                </StyledListItem>
                <Divider />
                <StyledListItem
                  button
                  onClick={openRecords}
                  isSelected={openRecord}
                >
                  <FontAwesomeIcon icon={faMicrophoneAlt} />
                  <ListItemText primary="RECORD A VOICE" />
                </StyledListItem>
                <Divider />
                <StyledListItem
                  button
                  onClick={openSample}
                  isSelected={showSample || sample1 || sample2}
                >
                  <FontAwesomeIcon icon={faFileAudio} />
                  <ListItemText primary="SAMPLE CLIPS" />
                </StyledListItem>
                <Divider />
              </List>
            </Grid>

            <Grid item xs={12} md={6}>
              {showUpload && (
                <UploadContainer>
                  <Typography gutterBottom variant="caption">
                    Please upload an audio clip of your voice (of file type
                    .wav)
                  </Typography>
                  <form encType="multipart/form-data">
                    <input
                      accept="audio/*"
                      id="upload-audio"
                      type="file"
                      onChange={handleChange}
                      name="file"
                    />
                    <label
                      variant="contained"
                      color="primary"
                      htmlFor="upload-audio"
                      id="file-label"
                    >
                      <CloudUploadIcon fontSize="large" />
                      {selectedFile?.name}
                    </label>
                  </form>

                  {uploadUrl && (
                    <audio controls>
                      <source src={uploadUrl} type="audio/wav" />
                    </audio>
                  )}

                  <StyledButton
                    variant="outlined"
                    size="large"
                    color="primary"
                    onClick={getuploadResult}
                  >
                    Submit
                  </StyledButton>
                </UploadContainer>
              )}
              {showSample ? (
                <SampleContainer>
                  <Typography gutterBottom>
                    Try one of our sample audio clips
                  </Typography>
                  <>
                    <StyledButton
                      variant="contained"
                      color="primary"
                      onClick={loadSample1Data}
                    >
                      Sample Input 1
                    </StyledButton>
                    <StyledButton
                      variant="contained"
                      color="primary"
                      onClick={loadSample2Data}
                    >
                      Sample Input 2
                    </StyledButton>
                  </>
                </SampleContainer>
              ) : sample1 ? (
                <SampleContainer>
                  <audio controls>
                    <source
                      src="https://material.vlifevirtusa.com/Parkinson/test_true.wav"
                      type="audio/wav"
                    />
                  </audio>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={openSample}
                  >
                    Try a different sample
                  </StyledButton>
                  <StyledButton
                    variant="outlined"
                    size="large"
                    onClick={uploadSample}
                  >
                    {' '}
                    Submit
                  </StyledButton>
                </SampleContainer>
              ) : (
                sample2 && (
                  <SampleContainer>
                    <audio controls>
                      <source
                        src="https://material.vlifevirtusa.com/Parkinson/Myrecording.wav"
                        type="audio/wav"
                      />
                    </audio>
                    <StyledButton
                      variant="contained"
                      color="primary"
                      onClick={openSample}
                    >
                      Try a different sample
                    </StyledButton>
                    <StyledButton
                      variant="outlined"
                      size="large"
                      onClick={uploadSample}
                    >
                      {' '}
                      Submit
                    </StyledButton>
                  </SampleContainer>
                )
              )}
              {
                // OpenRecord
                openRecord && mic ? (
                  <StyledDiv>
                    <Typography gutterBottom>
                      If you do not have an audio clip of your voice, you can
                      record yourself here and upload your clip.
                    </Typography>
                    {/* <StyledReactMic
                      record={record}
                      onStop={onStop}
                      strokeColor="white"
                      backgroundColor="black"
                    /> */}
                    <AudioBtn
                      variant="contained"
                      color="primary"
                      onClick={startRecording}
                      type="StyledButton"
                    >
                      Start
                    </AudioBtn>
                    <AudioBtn
                      variant="contained"
                      color="primary"
                      onClick={stopRecording}
                      type="StyledButton"
                    >
                      Stop
                    </AudioBtn>
                  </StyledDiv>
                ) : openRecord && audioBlob ? (
                  <StyledDiv>
                    <Typography gutterBottom>
                      If you do not have an audio clip of your voice, you can
                      record yourself here and upload your clip.
                    </Typography>
                    <audio controls>
                      <source src={audioBlob} type="audio/wav" />
                    </audio>
                    <br />
                    <AudioBtn
                      variant="contained"
                      color="primary"
                      onClick={openRecords}
                      type="StyledButton"
                    >
                      Record Again
                    </AudioBtn>
                    <br />
                    <StyledButton
                      variant="contained"
                      color="primary"
                      onClick={getUploadAudioRes}
                    >
                      Execute
                    </StyledButton>
                  </StyledDiv>
                ) : (
                  ''
                )
              }
            </Grid>
          </Grid>
          {response && (
            <Result>
              <strong>Result: </strong>
              <Typography gutterBottom>
                Parkinson's Disease:
                {response}
              </Typography>
            </Result>
          )}
        </DemoContainer>
        {isLoading && <Spinner />}
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(PredictParkinsonDisease);

const StyledListItem = styled(ListItem)`
  &.MuiListItem-root {
    color: ${(props) => (props.isSelected ? '#FE654F' : '#54577c')};
    svg {
      font-size: larger;
      padding-right: 5px;
    }
  }
`;
const UploadContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  min-height: 30vh;
  #upload-audio {
    display: none;
  }
  #file-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    .MuiSvgIcon-root {
      color: #fe654f;
      padding-right: 3px;
    }
  }
  audio {
    margin: 10px 0;
  }
`;
const SampleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  audio {
    margin: 10px 0;
  }
`;

// const StyledReactMic = styled(ReactMic)`
//   border-radius: 50px;
//   padding: 10px;
// `;

const StyledDiv = styled.div`
  margin-top: 16px;
`;

const AudioBtn = styled(StyledButton)`
  margin: 10px;
`;
