import React, { memo, useState, useEffect } from 'react'
import { Grid, MenuItem, TextField, Tooltip } from '@material-ui/core';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setInputData } from '../../../../../../store/actions/vnet';
import { isObjKeyhasval } from '../../util';
import { StyledButton } from '../../../../../../styles/common';
import { OpenInNew } from '@material-ui/icons';

export const DataShapleyForm = memo((props) => {
  const [modelInfo, setModelInfo] = useState({ modelType: "" });
  const [historyData, setHistoryData] = useState({});
  const [shapleyData, setShapleyData] = useState(null);
  const [fieldError, setFieldError] = useState(false);
  const { userInputData } = useSelector(state => state.vnet);


  const dispatch = useDispatch();
  const handleOnChange = async (e) => {
    const { value, name } = e.target;
    let modelDataObj = {};
    switch (name) {
      case "modelType":
        modelDataObj[name] = value;
        modelDataObj["model"] = null;
        break;
      case "model":
        modelDataObj[name] = value;
        modelDataObj["modelName"] = null;
        break;
      case "modelName":
        modelDataObj[name] = value;
        modelDataObj["Model_RunID"] = null;
        break;
      case "threshold":
        dispatch(setInputData({
          ...modelInfo,
          Threshold: value
        }))
        break;
      case "Model_RunID":
        modelDataObj['Model_RunID'] = value;
        let contextData = {
          Actions: "valShap",
        };
        let contentData = {
          "model": modelInfo.model,
          "modelName": modelInfo.modelName,
          "Model_RunID": value,
          "modelType": modelInfo?.modelType
        }
        let result = await props.getCommonAPIData(contextData, contentData)
        setShapleyData({ info: result.Console, ...result.Result });
        dispatch(setInputData({
          ...modelInfo,
          ...modelDataObj,
          model: modelInfo?.model,
        }))
        break;

      default:
        break;
    }

    setModelInfo({
      ...modelInfo,
      ...modelDataObj
    });
    if (fieldError[name]) {
      setFieldError({ ...fieldError, [name]: false })
    }
  }
  const handleBlur = (e) => {
    if (shapleyData?.Threshold?.indexOf(e.target.value) > -1) {
      setFieldError({ ...fieldError, ['Threshold']: "value already selected, choose different threshold" })
    }
    else {
      setFieldError({ ...fieldError, ['Threshold']: false })
    }

  }
  const handleFormReset = () => {
    setModelInfo({ modelType: "" });
    setShapleyData(null);
  }

  const isFormValid = () => {
    let error = { ...fieldError };
    Object.keys(modelInfo).forEach(key => {
      if (!modelInfo?.[key]) {
        error[key] = "required field"
      }
    });
    setFieldError(error);

    return true;
  }
  useEffect(async () => {
    let contextData = {
      "StageID": "Common_API",
      "FeatureID": "Common_API",
      "FunctionID": "resumeStage",
      "Actions": "",
      "ProductID": "vNet"
    }
    const res = await props.getCommonAPIData(contextData);
    setHistoryData(res?.Result?.historyData)
  }, [])

  return (
    <ShapleyContainer>
      <Grid container justifyContent="flex-start" alignItems="center" spacing={3} id="modelcontainer">
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="modelType"
            label="Model Type"
            variant="outlined"
            onChange={handleOnChange}
            select
            size="small"
            fullWidth
            value={modelInfo.modelType}
            error={fieldError["modelType"]}
            helperText={fieldError["modelType"]}
          >
            {historyData && Object.keys(historyData).map(value => (
              <MenuItem key={value} value={value} disabled={['Regression', 'AutoML'].indexOf(value) > -1}>
                {value}
              </MenuItem>
            ))}

          </TextField>
        </Grid>
        {modelInfo.modelType && <>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              name="model"
              label="Model"
              variant="outlined"
              onChange={handleOnChange}
              select
              size="small"
              fullWidth
              value={modelInfo.model}
              error={fieldError["model"]}
              helperText={fieldError["model"]}
            >
              {historyData && Object.keys(historyData?.[modelInfo.modelType]).map(value => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}

            </TextField>
          </Grid>
          {modelInfo.model && <>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                name="modelName"
                label="Model Name"
                variant="outlined"
                onChange={handleOnChange}
                select
                size="small"
                fullWidth
                value={modelInfo?.modelName}
                error={fieldError["modelName"]}
                helperText={fieldError["modelName"]}
              >
                {historyData && Object.keys(historyData?.[modelInfo.modelType]?.[modelInfo.model]).map(value => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}

              </TextField>
            </Grid>
            {modelInfo?.modelName && <Grid item xs={12} sm={6} md={3}>
              <TextField
                name="Model_RunID"
                label="Model Version"
                variant="outlined"
                minWidth="100%"
                onChange={handleOnChange}
                select
                size="small"
                fullWidth
                value={modelInfo?.Model_RunID}
                error={fieldError["Model_RunID"]}
                helperText={fieldError["Model_RunID"]}
              >
                {historyData?.[modelInfo.modelType]?.[modelInfo.model]?.[modelInfo.modelName]?.map(value => (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                ))}

              </TextField>
            </Grid>}
          </>}
        </>}
      </Grid>
      {shapleyData && <div>
        {shapleyData?.isAvailable ? <>
          <InfoText success={true}><FontAwesomeIcon icon={faInfoCircle} /> {shapleyData?.info}</InfoText>
          <Grid container justifyContent="flex-start" alignItems="center" spacing={3} >
            {shapleyData?.Scores && Object.entries(shapleyData?.Scores)?.map(score => (
              <Grid item xs={12} sm={4} md={3}>
                <strong>{score[0]}</strong> : {score[1]}
              </Grid>))}
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                name="threshold"
                label="Threshold"
                variant="outlined"
                minWidth="100%"
                onChange={handleOnChange}
                onBlur={handleBlur}
                size="small"
                fullWidth
                value={userInputData?.Threshold}
                error={fieldError['Threshold']}
                helperText={fieldError['Threshold'] || "!= selected thresholds"}

              >
              </TextField>
            </Grid>
            {shapleyData?.Threshold?.length > 0 && <Grid item xs={12} md={3}>
              <Tooltip title={
                <React.Fragment>{shapleyData?.Threshold.map(val => val + ',')}</React.Fragment>
              }
                arrow
                placement="bottom">
                <span><FontAwesomeIcon icon={faInfoCircle} /> selected thresholds</span>
              </Tooltip>
            </Grid>}
          </Grid>
        </>
          :
          shapleyData.inProgress ?
            shapleyData?.Running_Version ? <InfoText success={true}><FontAwesomeIcon icon={faInfoCircle} />  Data Shapley is under progress for modelversion: {shapleyData?.Running_Version}, please try after sometime </InfoText> : <InfoText success={true}><FontAwesomeIcon icon={faInfoCircle} />  Data Shapley for selected model version is under progress </InfoText>
            :
            <InfoText success={false}><FontAwesomeIcon icon={faInfoCircle} />  Please click on save and execute to generate shapley results</InfoText>
        }
      </div>}

      <br />
      <br />
      <StyledButton
        variant="outlined"
        color="primary"
        size="small"
        startIcon={<OpenInNew />}
      >
        <a
          href="https://arxiv.org/abs/1904.02868"
          target="_blank"
          rel="noopener noreferrer"
        >
          Stanford AI: Citations
        </a>
      </StyledButton>
      {props.render({ handleFormReset: handleFormReset, isFormValid: isFormValid, isSaveDisabled: shapleyData?.inProgress })}

    </ShapleyContainer >
  )
});

const ShapleyContainer = styled.section`
width: 100%
`;
export const InfoText = styled.p`
color: ${props => props.success ? '#2a9d8f' : '#f28482'};
`;