import React, { memo, useState } from "react";
import styled from "styled-components";
import {
  Grid,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Paper from '@material-ui/core/Paper';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

// import local components
import CommonService from "../../../utils/services/CommonService";

import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";
import SolutionContainer from "../../common/SolutionContainerWrapper";

// images
import colonPathology_sample1 from "../../../assets/images/app/colon-pathology.webp";

const { getQueryResult } = CommonService("patientEngagementProduction", "colonPathology");

const ColonPathology = () => {
  const [showSpinner, setSpinner] = useState(false);
  const [result, setResult] = useState("");
  const [notification, setNotification] = useState("");

  const getsampleResult = () => {  
    setSpinner(true);
    setResult("");
    getQueryResult("output")
      .then((res) => {
        setSpinner(false);
        setResult(res);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>Colorectal carcinoma is one of the most common cancers and one of the leading causes of cancer-related death in the United States. Pathologic examination of biopsy, polypectomy and resection specimens is crucial to appropriate patient management, prognosis assessment and family counseling. Molecular testing plays an increasingly important role in the era of personalized medicine.</p>
          <p>In this solution a collection of textures in histological images of human colorectal cancer is studied.  Histological samples are fully anonymized images of formalin-fixed paraffin-embedded human colorectal adenocarcinomas (primary tumors). The solution involves analysis of tissue taken from a biopsy or surgery. The image is classified into the 8 classes :  stroma, debris, adipose, mucosa, tumor, lympho, complex and empty. Probability of each class is computed based on the neural network model.</p>
          <LinkContainer>
            <Grid container spacing={2}>

              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://www.kaggle.com/kmader/colorectal-histology-mnist"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/colon_pathology/Colon%20Pathology%20Classification.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12} sm={6} md={3}>
              <Image src={colonPathology_sample1} />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <StyledButton
                variant="contained"
                color="primary"
                data-sample="sample/"
                onClick={getsampleResult}
              >
                Sample
              </StyledButton>
            </Grid>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
            <Grid container XS={12}>
              <Grid item xs={12} md={12}>
                <div>
                  <section>
                    <Result><strong>Result : </strong></Result>
                    <Grid container direction="row" justify="center" alignItems="center">
                      <Grid item xs={12} sm={12} md={6}>
                        <Paper>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <StyledTableHead>
                                  <h3>Classes</h3>
                                </StyledTableHead>
                                <StyledTableHead>
                                  <h3>Probability</h3>
                                </StyledTableHead>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {Object.entries(result).map((value) => (
                                <TableRow>
                                  <TableCell>{value[0]}</TableCell>
                                  <TableCell>{value[1]}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Paper>
                      </Grid>
                    </Grid>
                  </section>
                </div>
              </Grid>
            </Grid>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(ColonPathology);

const Image = styled.img`
  width: 80%;
  height: 150px;
  max-width: 200px;
  @media only screen and (max-width: 900px) {
    width: auto;
    max-width: 200px;
  }  @media only screen and (max-width: 450px) {
    width: auto;
    max-width: 150px;
  }
`;
const StyledTableHead = styled(TableCell)`
  background-color: #3c40af;
  color: white;
  font-weight: bold;
`;
