import React, { memo, useRef, useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  InputAdornment,
  Paper,
  Button,
  AppBar,
  Tab,
  Tabs,
} from '@material-ui/core';

import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// local components
import styled from 'styled-components';
import Collapse from '../../common/Collapse';
import CommonService from '../../../utils/services/CommonService';
import Spinner from '../../common/loading/Spinner';
import SolutionContainer from '../../common/SolutionContainerWrapper';
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  List,
  ListItem,
} from '../../../styles/common';
import ContactUsDialog from '../../common/ContactusDialog';
import InfoToolTip from './vnet/components/InfoToolTip';
import getUserData, { getUploadToken } from '../../../utils';

// user id

// images
import ColbertAnalysis_soln1 from '../../../assets/images/app/colbertAnalysiss_1.webp';
import ColbertAnalysis_soln2 from '../../../assets/images/app/colbertAnalysiss_2.webp';
import ColbertAnalysis_soln3 from '../../../assets/images/app/colbertAnalysiss_3.webp';
import ColbertAnalysis_sol1 from '../../../assets/images/app/colbertAnalysis_1.webp';
import ColbertAnalysis_sol2 from '../../../assets/images/app/colbertAnalysis_2.webp';
import ColbertAnalysis_sol3 from '../../../assets/images/app/colbertAnalysis_3.webp';
import ColbertAnalysis_sol4 from '../../../assets/images/app/colbertAnalysis_4.webp';
import ColbertAnalysis_sol5 from '../../../assets/images/app/colbertAnalysis_5.webp';
import ColbertAnalysis_sol6 from '../../../assets/images/app/colbertAnalysis_6.webp';
import ColbertAnalysis_sol7 from '../../../assets/images/app/colbertAnalysis_7.webp';
import ColbertAnalysis_sol8 from '../../../assets/images/app/colbertAnalysis_8.webp';
import ColbertAnalysis_sol9 from '../../../assets/images/app/colbertAnalysis_9.webp';
import ColbertAnalysis_sol10 from '../../../assets/images/app/colbertAnalysis_10.webp';
import ColbertAnalysis_sol11 from '../../../assets/images/app/colbertAnalysis_11.webp';
import config from '../../../assets/images/app/sys_config.webp';
import byod from '../../../assets/images/app/boyd_architecture.webp';

const ColbertAnalysis = () => {
  // for selecting sample query
  const [selectedQuery, setQuery] = useState('');
  const [queryresult, setResult] = useState({ text: '' });
  // tab1
  const [result, SetResultData] = useState('');
  const [result1, SetResultData1] = useState(false);
  const [userId, setUserId] = useState(getUserData()?.token?.emailid);
  const [bert, SetBertData] = useState(false);
  const [bert1, SetBertData1] = useState(false);
  const [bert2, SetBertData2] = useState(false);
  const [button, setButton] = useState(false);
  // tab2
  const [datasetResult, setDatasetResult] = useState(false);
  const [datasetResult1, setDatasetResult1] = useState(false);
  // tab3
  const [checkpointModel, setCheckpointModel] = useState(false);
  const [viewmycol, setViewmycol] = useState(false);
  const [mycollection, setMyCollection] = useState(false);
  const [converationalButton, setConverationalButton] = useState(false);
  const [chatbot, setChatBot] = useState(false);
  // spinners
  const [showSpinner, setSpinner] = useState(false);
  const [showSpinner1, setSpinner1] = useState(false);
  const [showSpinner2, setSpinner2] = useState(false);
  const [showSpinner3, setSpinner3] = useState(false);
  const [showSpinner4, setSpinner4] = useState(false);
  const [notification, setNotification] = useState('');
  // tab value
  const [value, setValue] = useState(0);
  // for image popup
  const [imgUrl, setImgUrl] = useState('');
  const [openDialog, setOpenDialog] = useState(false);

  // upload file
  const [selectedFile, setselectedFile] = useState('');
  const [isLoading, setLoading] = useState('');
  const [fileName, setFileName] = useState('');
  const [Desc, setDesc] = useState('');

  const initialData = {
    desc: '',
  };
  const handleInputQuery = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setResult({
      ...queryresult,
      [name]: value,
    });
  };

  const [sampleData, setSampleData] = useState(initialData);

  const handleInputs = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  // radio button
  const [radiovalue, setRadioValue] = useState('msmarco');
  const [tableradiovalue, setTableRadioValue] = useState('');

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value);
  };

  const handleTableRadioChange = (event) => {
    setTableRadioValue(event.target.value);
  };

  const imgData = {
    config: '../../../assets/images/app/sys_config.webp',
    byod: '../../../assets/images/app/boyd_architecture.webp',
  };

  const showImageDialog = (e) => {
    const video = e.currentTarget.getAttribute('img');
    setOpenDialog(true);
    setImgUrl(imgData[video]);
  };
  const closeCommonDialog = () => {
    setOpenDialog(false);
  };

  // for contact us
  const [dialogContact, setOpenDialogContact] = useState(false);

  const openDialogContact = () => {
    setOpenDialogContact(true);
  };
  const closeDialogContact = () => {
    setOpenDialogContact(false);
  };

  // for conversation AI
  const [chatbotList, setChatList] = useState([]);
  const [chatbotQuery, setChatbotquery] = useState('');
  const refToScroll = useRef(null);
  const enterQuery = () => {
    getQueryresult(chatbotQuery);
    setChatbotquery('');
  };
  const enterByodQuery = () => {
    getByodQueryresult(chatbotQuery);
    setChatbotquery('');
  };
  const handleChatbotQuery = (e) => {
    setChatbotquery(e.target.value);
  };
  const setQuestion = (data) => {
    if (data) {
      setChatList((prevState) => [...prevState, { data, type: 'question' }]);
    }
  };
  const setAnswer = (data) => {
    setChatList((prevState) => [...prevState, { data, type: 'answer' }]);
  };

  const { getQueryResult, uploadFilesWithBody } = CommonService(
    'z1dApps',
    'colbertAnalysis'
  );
  const { postQueryResultColbert } = CommonService(
    'z1dApps',
    'colbertAnalysis'
  );
  const Token = getUploadToken()
  
  useEffect(() => {
    refToScroll.current &&
      refToScroll.current.scrollTo(0, refToScroll.current.scrollHeight + 100);
  }, [chatbotList]);

  // for tab values
  const tabhandleChange = (event, newValue) => {
    setValue(newValue);
  };

  // for tab1 sample query
  const sampleQuery = {
    sample1: {
      text: 'What condition can people who are allergic to bee stings suffer if stung?',
    },
    sample2: {
      text: 'Which metal is found in hemoglobin, the oxygen-carrying protein that makes blood red?',
    },
    sample3: {
      text: 'Which fat-soluble vitamin present in green leafy vegetables was given its name because it is required for coagulation of the blood to take place?',
    },
    sample4: {
      text: 'The auditory, or acoustic, nerve connects the brain with the ear. Its two parts have separate functions. One of the functions is hearing. What is the other?',
    },
  };

  const setSampleQuery = (e) => {
    const selectedQuery = e.currentTarget.getAttribute('data-sample');
    setResult({ text: sampleQuery[selectedQuery].text });
    setQuery(selectedQuery);
  };

  // tab1
  const getResult = () => {
    // const params = sampleQuery[selectedQuery].text;

    const { text } = queryresult;

    const params = `${text},${Token.token}`;

    SetResultData('');
    SetBertData('');
    setSpinner(true);

    getQueryResult('colbert', params)
      .then((res) => {
        setSpinner(false);
        setButton(true);
        SetResultData(res.time);
        SetResultData1(res.passages);
        SetBertData2(res.answer);
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
        setNotification({ open: 'error', message: err.message });
      });
  };

  const getBert = () => {
    const params = sampleQuery[selectedQuery].text;

    setSpinner1(true);
    setButton(false);

    getQueryResult('bert', params)
      .then((res) => {
        setSpinner1(false);
        SetBertData(res.bert_time);
        SetBertData1(res.bert_passages);
      })
      .catch((err) => {
        console.log(err);
        setSpinner1(false);
        setNotification({ open: 'error', message: err.message });
      });
  };

  // tab2
  const showArteryResult = () => {
    const text = 'What is Artery?';

    setSpinner1(false);
    setSpinner2(true);
    setQuestion(text);
    // setDatasetResult('');

    getQueryResult('medical', text)
      .then((res) => {
        setSpinner2(false);
        setDatasetResult(res.time);
        setDatasetResult1({
          passages: res.passages,
          highlighted_passage_index: res.highlighted_passage_index,
        });
        setAnswer(res.answer);
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
        setNotification({ open: 'error', message: err.message });
      });
  };

  const getQueryresult = (text) => {
    setQuestion(text);
    setDatasetResult('');
    setDatasetResult1('');
    setSpinner2(true);

    getQueryResult('medical', text)
      .then((res) => {
        setSpinner2(false);
        setDatasetResult(res.time);
        setDatasetResult1({
          passages: res.passages,
          highlighted_passage_index: res.highlighted_passage_index,
        });
        setAnswer(res.answer);
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
        setNotification({ open: 'error', message: err.message });
      });
  };

  const getByodQueryresult = (text) => {
    setQuestion(text);
    setDatasetResult('');
    setDatasetResult1('');
    setSpinner2(true);

    getQueryResult('retrieve', text)
      .then((res) => {
        setSpinner2(false);
        setDatasetResult(res.time);
        setDatasetResult1({
          passages: res.passages,
          highlighted_passage_index: res.highlighted_passage_index,
        });
        setAnswer(res.answer);
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
        setNotification({ open: 'error', message: err.message });
      });
  };

  // reset
  const getReset = () => {
    setChatList([]);
    SetResultData('');
    SetBertData('');
    setDatasetResult('');
    setButton(false);
    queryresult.text = '';
  };

  const onChangeFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      setselectedFile(file);
      // setResponse('');
    } else {
      // setResponse('');
    }
  };

  const getUploadResult = () => {
    const { desc } = sampleData;

    const Desc = `${desc}`;

    if (!selectedFile) {
      setNotification({ open: 'error', message: 'please upload a file' });
      return;
    }
    setLoading(true);
    setMyCollection('');
    setChatBot('');
    setConverationalButton('');

    uploadFilesWithBody('upload', {
      file: selectedFile,
      Context_param: JSON.stringify({
        UserID: userId,
        Collection_description: Desc,
      }),
    })
      .then((res) => {
        setLoading(false);
        setCheckpointModel(true);
        setFileName(res.File_name);
        setDesc(res.Collection_description);
        setNotification({ open: 'success', message: res.Console });
      })
      .catch((err) => {
        setNotification({ open: 'error', message: err.message });
        setLoading(false);
      });
  };

  const getCollectionResult = () => {
    setLoading(true);
    setMyCollection('');
    setViewmycol('');
    setCheckpointModel('');
    setChatBot('');
    setConverationalButton('');

    postQueryResultColbert('collections', {
      Context_param: JSON.stringify({ UserID: userId }),
    })
      .then((res) => {
        setLoading(false);
        if (
          res ===
          'Please upload a file containing passages and proceed for the indexing step.'
        ) {
          setNotification({ open: 'error', message: res });
          setMyCollection('');
        } else {
          setMyCollection(res);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setNotification({ open: 'error', message: err.message });
      });
  };

  const getStartIndex = () => {
    setSpinner3(true);
    postQueryResultColbert('startindex', {
      Context_param: JSON.stringify({
        UserID: userId,
        Actions: radiovalue,
        File_name: fileName,
        Collection_description: Desc,
      }),
    })
      .then((res) => {
        setSpinner3(false);
        setViewmycol(true);
        setNotification({ open: 'success', message: res });
      })
      .catch((err) => {
        console.log(err);
        setSpinner3(false);
        setNotification({ open: 'error', message: err.message });
      });
  };

  const getLoadIndex = () => {
    setSpinner4(true);
    postQueryResultColbert('loadindex', {
      Context_param: JSON.stringify({
        UserID: userId,
        execution_time: tableradiovalue,
      }),
    })
      .then((res) => {
        setSpinner4(false);
        setNotification({ open: 'success', message: res });
        setConverationalButton(true);
      })
      .catch((err) => {
        console.log(err);
        setSpinner4(false);
        setNotification({ open: 'error', message: err.message });
      });
  };

  const getChatBot = () => {
    setChatBot(true);
    setLoading(true);
    const text = 'What is Heart Attack?';
    setQuestion(text);

    getQueryResult('retrieve', text)
      .then((res) => {
        setLoading(false);
        setDatasetResult(res.time);
        setDatasetResult1({
          passages: res.passages,
          highlighted_passage_index: res.highlighted_passage_index,
        });
        setAnswer(res.answer);
        setMyCollection('');
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setNotification({ open: 'error', message: err.message });
      });
  };

  // tab3 reset
  const tab3Reset = () => {
    setSampleData(initialData);
    setViewmycol('');
    setDatasetResult('');
    setMyCollection('');
    setChatBot('');
    setChatList([]);
    queryresult.text = '';
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Our goal with ColBERT is to build a Conversational AI Chatbot that
            could answer any domain specific queries. The user provides a query
            to the chatbot. ColBERT as a retriever fetches top-k passages from
            the corpus. And BERT as a reader then extracts a specific answer
            from the top-k passages based on the query. The platform provides
            the answer back to the user and keeps the conversation going.
          </p>
          <center>
            <Grid item xs={12} sm={10} md={8}>
              <img width="60%" src={ColbertAnalysis_soln1} alt="" />
            </Grid>
          </center>
          <p>
            Over the past few years, the Information Retrieval (IR) community
            has witnessed the introduction of a host of neural ranking models,
            including DRMM, KNRM, and Duet. In contrast to prior
            learning-to-rank methods that rely on hand-crafted features, these
            models employ embedding-based representations of queries and
            documents and directly model local interactions (i.e., fine-granular
            relationships) between their contents. Among them, a recent approach
            has emerged that fine-tunes deep pre-trained language models (LMs)
            like ELMo and BERT for estimating relevance. By computing
            deeply-contextualized semantic representations of query–document
            pairs, these LMs help bridge the pervasive vocabulary mismatch
            between documents and queries. However, the remarkable gains
            delivered by these LMs come at a steep increase in computational
            cost. To reconcile efficiency and contextualization in IR, ColBERT,
            a ranking model based on contextualized late interaction over BERT,
            was introduced.
          </p>
          <center>
            <Grid item xs={12} sm={10} md={8}>
              <img width="80%" src={ColbertAnalysis_soln2} alt="" />
            </Grid>
          </center>
          <p>
            ColBERT introduces a late interaction architecture that
            independently encodes the query and the document using BERT and then
            employs a cheap yet powerful interaction step that models their
            fine-grained similarity. By delaying and yet retaining this
            fine-granular interaction, ColBERT can leverage the expressiveness
            of deep LMs while simultaneously gaining the ability to pre-compute
            document representations offline, considerably speeding up query
            processing. The late interaction effectively leverages the
            precomputation of document representations of representation-based
            similarity models and the fine-grained matching of interaction-based
            models.
          </p>
          <center>
            <Grid item xs={12} sm={10} md={8}>
              <img width="90%" src={ColbertAnalysis_soln3} alt="" />
            </Grid>
          </center>
          <p>
            We extensively evaluated ColBERT on two datasets. And the results
            clearly states that the ColBERT is equally effective but much faster
            information retrieval model compared to BERT.
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="http://ir.dcs.gla.ac.uk/resources/test_collections/npl/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/ColBERT/ColBERT.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://cs.stanford.edu/~matei/papers/2020/sigir_colbert.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Stanford AI : Citations
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container xs={12} spacing={2} direction="row">
            <Grid item xs={12}>
              <AppBar position="static" color="default">
                <StyledTabs
                  value={value}
                  onChange={tabhandleChange}
                  variant="fullWidth"
                  aria-label="simple tabs example"
                >
                  <Tab label="ColBERT using MS-Marco Dataset" />
                  <Tab label="ColBERT using Simulated Medical Dataset" />
                  <Tab label="ColBERT for Custom Dataset (BYOD)" />
                </StyledTabs>
              </AppBar>
            </Grid>
          </Grid>
          <TabPanel value={value} index={0}>
            <section>
              <Grid container xs={12} spacing={2} direction="row">
                <Paragraph>
                  <p>
                    MS Marco (Microsoft Machine Reading Comprehension) is a
                    large-scale dataset focussed on machine reading
                    comprehension, question answering and passage ranking. We
                    have trained ColBERT on MS-Marco to retrieve top-5 passages
                    from the dataset based on the query asked. We have also
                    trained BERT on MS-Marco to perform the same operation and
                    compare the performance with ColBERT Model.
                  </p>
                  <p>
                    It is seen that ColBERT retrieves passages in less than a
                    second whereas BERT takes approximately 35 seconds. This
                    showcases the computation power of ColBERT over BERT.{' '}
                  </p>
                  <p>
                    Below are some sample queries which can be used to test the
                    performance of ColBERT and BERT. The user can also input his
                    own query if needed.
                  </p>
                </Paragraph>
                <Grid item xs={6} sm={3} md={3}>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    data-sample="sample1"
                    onClick={setSampleQuery}
                  >
                    Sample Query 1
                  </StyledButton>
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    data-sample="sample2"
                    onClick={setSampleQuery}
                  >
                    Sample Query 2
                  </StyledButton>
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    data-sample="sample3"
                    onClick={setSampleQuery}
                  >
                    Sample Query 3
                  </StyledButton>
                </Grid>
                <Grid item xs={6} sm={3} md={3}>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    data-sample="sample4"
                    onClick={setSampleQuery}
                  >
                    Sample Query 4
                  </StyledButton>
                </Grid>
              </Grid>
              <br />
              <Grid
                container
                xs={12}
                spacing={2}
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={10} md={8}>
                  <TextField
                    rows="2"
                    multiline
                    variant="outlined"
                    name="text"
                    fullWidth
                    label="Type your Query"
                    onChange={handleInputQuery}
                    value={queryresult.text}
                  />
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                  <StyledButton
                    variant="contained"
                    color="secondary"
                    onClick={getResult}
                  >
                    Execute
                  </StyledButton>
                </Grid>
              </Grid>
            </section>
            {showSpinner && <Spinner text="Loading..." />}
            <Grid container xs={12} spacing={1} direction="row">
              {result && (
                <Grid item xs={12} sm={12} md={6}>
                  <h3>ColBERT</h3>
                  <p>
                    Top 5 passages retrieved in
                    <b>{`${result}`} ms</b>
                  </p>
                  <Paragraph>
                    <List>
                      <ListItem>{`${result1[0]}`}</ListItem>{' '}
                      <ListItem>{`${result1[1]}`}</ListItem>{' '}
                      <ListItem>{`${result1[2]}`}</ListItem>{' '}
                      <ListItem>{`${result1[3]}`}</ListItem>{' '}
                      <ListItem>{`${result1[4]}`}</ListItem>
                    </List>
                  </Paragraph>
                </Grid>
              )}
              {button && (
                <Grid item xs={10} sm={10} md={3}>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={getBert}
                  >
                    Compare with BERT
                  </StyledButton>
                </Grid>
              )}
              {showSpinner1 && (
                <b>
                  <Spinner
                    color="#ff0055"
                    text="Passage Retrieval via BERT in Progress ..."
                  />
                </b>
              )}
              {bert && (
                <Grid item xs={12} sm={12} md={6}>
                  <h3>BERT</h3>
                  <p>
                    Top 5 passages retrieved in
                    <b>{`${bert}`} ms</b>
                  </p>
                  <Paragraph>
                    <List>
                      <ListItem>{`${bert1[0]}`}</ListItem>{' '}
                      <ListItem>{`${bert1[1]}`}</ListItem>{' '}
                      <ListItem>{`${bert1[2]}`}</ListItem>{' '}
                      <ListItem>{`${bert1[3]}`}</ListItem>{' '}
                      <ListItem>{`${bert1[4]}`}</ListItem>
                    </List>
                  </Paragraph>
                </Grid>
              )}
              {result && (
                <Grid
                  container
                  xs={12}
                  spacing={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={7} md={7}>
                    <h4>Answer of the Query</h4>
                    <TextField
                      multiline
                      variant="outlined"
                      name="text"
                      fullWidth
                      value={`${bert2}`}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4}>
                    <StyledButton
                      variant="contained"
                      color="primary"
                      onClick={getReset}
                    >
                      RESET
                    </StyledButton>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <section>
              <Grid container xs={12} spacing={2} direction="row">
                <Paragraph>
                  <p>
                    We have trained ColBERT on a medical dataset of 1 million
                    passages. Using ColBERT, we have developed an AI
                    Conversational platform where a user can ask any medical
                    related query and get the answer from the dataset.
                  </p>
                  <p>
                    Below is a sample query to start the conversational
                    platform:
                  </p>
                </Paragraph>
                <Grid item xs={12}>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={showArteryResult}
                  >
                    What is Artery?
                  </StyledButton>
                </Grid>
              </Grid>
            </section>

            <Grid
              container
              xs={12}
              spacing={4}
              direction="row"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <Grid item xs={12} sm={12} md={7}>
                <Paper>
                  <h3>ColBERT</h3>
                  <center>
                    {' '}
                    <p>
                      Top 5 passages retrieved in
                      <b>{`${datasetResult || ''}`} ms</b>
                    </p>
                  </center>
                  {showSpinner2 && <Spinner text="Loading..." />}
                  {datasetResult && (
                    <Paragraph>
                      <List>
                        {datasetResult1?.passages?.map((passage, ind) => (
                          <PassageListItem
                            highlight={
                              ind == datasetResult1.highlighted_passage_index
                            }
                          >
                            {passage}
                          </PassageListItem>
                        ))}
                      </List>
                    </Paragraph>
                  )}
                </Paper>
              </Grid>

              <Grid item xs={12} sm={12} md={5}>
                {/* Chatbot code */}
                <ChatBot>
                  <ChatHeader>
                    <h3>Conversational AI</h3>
                  </ChatHeader>
                  <ChatBotContainer ref={refToScroll}>
                    {chatbotList.map((chatData) => (
                      <p id={chatData.type}>{chatData.data}</p>
                    ))}
                  </ChatBotContainer>
                  <InputContainer>
                    <TextField
                      fullWidth
                      placeholder="Ask a Query "
                      variant="outlined"
                      value={chatbotQuery}
                      onChange={handleChatbotQuery}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={enterQuery}
                            >
                              Send
                            </Button>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </InputContainer>
                </ChatBot>
                {/* Chatbot code end */}
                <br />
                <br />
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={getReset}
                >
                  Reset
                </StyledButton>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <h4>
                  To Build a Conversational AI Platform for your own dataset
                  <StyledButton
                    variant="contained"
                    color="secondary"
                    onClick={openDialogContact}
                  >
                    Contact Us
                  </StyledButton>
                </h4>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Buttondiv>
              <StyledButton
                variant="contained"
                color="default"
                onClick={showImageDialog}
                img="byod"
              >
                User Guide
              </StyledButton>
              {/* <StyledButton
                                variant="contained"
                                color="default"
                                img={"config"}
                                onClick={showImageDialog}
                            >
                                System Configuration
                            </StyledButton> */}
            </Buttondiv>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={10} sm={5} md={3}>
                <strong>
                  <InfoToolTip
                    title="Please click on 'View Collections' if you have already created the indexes for your file. If not, please upload a file containing passages from which your top passages will be retrieved based on your given query. Once you select the ColBERT model, you need to index your file to permit fast retrieval."
                    info=" "
                  />
                </strong>
                <StyledButton
                  variant="contained"
                  color="secondary"
                  btnColor="#FC7703"
                  onClick={getCollectionResult}
                >
                  View  Collections
                </StyledButton>
              </Grid>
              <Grid item xs={12} sm={12} md={1}>
                <h3>OR</h3>
              </Grid>
              <Grid item xs={9} sm={5} md={3}>
                <TextField
                  type="file"
                  onChange={onChangeFile}
                  helperText="Upload a TSV File upto 3MB size only "
                />
                <br />
                <a
                  href="https://material.vlifevirtusa.com/ColBERT/sample_passages.tsv"
                  download
                  rel="noopener noreferrer"
                >
                  Sample Passages TSV File Download
                </a>
                <br />
                <br />
                <TextField
                  variant="outlined"
                  label="Description"
                  name="desc"
                  fullWidth
                  required
                  onChange={handleInputs}
                  value={sampleData.desc}
                />
              </Grid>
              <Grid item xs={12} sm={3} md={2}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={getUploadResult}
                >
                  Execute
                </StyledButton>
              </Grid>
            </Grid>
            {showSpinner && (
              <b>
                <Spinner color="#ff0055" />
              </b>
            )}
            {checkpointModel && (
              <Grid
                container
                spacing={2}
                justify="center"
                alignItems="center"
                alignContent="center"
              >
                <Grid item xs={12} sm={12} md={12}>
                  <p>
                    {' '}
                    Please select a checkpoint model to index your data (For
                    more information, please refer to the User-guide) :
                  </p>
                </Grid>
                <Grid item xs={4} sm={4} md={4}>
                  <StyledRadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={radiovalue}
                    onChange={handleRadioChange}
                  >
                    <strong>
                      <InfoToolTip
                        title="MS MARCO(Microsoft Machine Reading Comprehension) Passage Retrieval is a large scale dataset focused on passage ranking task. There are around 9 million passages and the goal is to rank based on their relevance."
                        info=" "
                      />
                    </strong>{' '}
                    <FormControlLabel
                      value="msmarco"
                      control={<Radio />}
                      label="MS MARCO"
                    />
                    <strong>
                      <InfoToolTip
                        title="Natural Questions is a open-domain question answering dataset based on real user queries issued to Google search, and answers found from Wikipedia by annotators."
                        info=" "
                      />
                    </strong>{' '}
                    <FormControlLabel
                      value="nq"
                      control={<Radio />}
                      label="Natural Questions"
                    />
                  </StyledRadioGroup>
                </Grid>
                <Grid item xs={2} sm={2} md={2}>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={getStartIndex}
                  >
                    Start Indexing
                  </StyledButton>
                </Grid>
              </Grid>
            )}
            {showSpinner3 && (
              <b>
                <Spinner
                  color="#ff0055"
                  text="Indexing Started. Please wait for a minute"
                />
              </b>
            )}
            <br />
            {viewmycol && (
              <section>
                <StyledButton
                  variant="contained"
                  color="primary"
                  btnColor="#FC7703"
                  onClick={getCollectionResult}
                >
                  View my collections
                </StyledButton>
              </section>
            )}
            {mycollection && (
              <section>
                <p>
                  Please select an option to start your Conversational AI
                  platform on the respected file.
                </p>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={12} md={12}>
                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={tableradiovalue}
                      onChange={handleTableRadioChange}
                    >
                      <TablePaper>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <StyledTableHead align="left">
                                Select Option
                              </StyledTableHead>
                              <StyledTableHead align="left">
                                Execution Date-time
                              </StyledTableHead>
                              <StyledTableHead align="left">
                                File Name
                              </StyledTableHead>
                              <StyledTableHead align="left">
                                Description
                              </StyledTableHead>
                              <StyledTableHead align="left">
                                Checkpoint
                              </StyledTableHead>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {mycollection?.collections.map((value, index) => (
                              <>
                                <TableRow>
                                  <TableCell>
                                    {' '}
                                    <FormControlLabel
                                      value={mycollection.collections[index][0]}
                                      control={<Radio />}
                                    />
                                  </TableCell>
                                  <TableCell>
                                    {mycollection.collections[index][0]}
                                  </TableCell>
                                  <TableCell>
                                    {mycollection.collections[index][2]}
                                  </TableCell>
                                  <TableCell>
                                    {mycollection.collections[index][5]}
                                  </TableCell>
                                  <TableCell>
                                    {mycollection.collections[index][6]}
                                  </TableCell>
                                </TableRow>
                              </>
                            ))}
                          </TableBody>
                        </Table>
                      </TablePaper>
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={8} sm={8} md={8}>
                    <StyledButton
                      variant="contained"
                      color="primary"
                      onClick={getLoadIndex}
                    >
                      Load Index
                    </StyledButton>
                  </Grid>
                </Grid>
                {showSpinner4 && (
                  <b>
                    <Spinner
                      color="#ff0055"
                      text="Loading Indexes. Please wait for a minute"
                    />
                  </b>
                )}
                {converationalButton && (
                  <section>
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={12} sm={12} md={12}>
                        <StyledButton
                          variant="contained"
                          color="secondary"
                          btnColor="#FC7703"
                          onClick={getChatBot}
                        >
                          Start the conversational Ai platform
                        </StyledButton>
                      </Grid>
                    </Grid>
                  </section>
                )}
              </section>
            )}
            {chatbot && (
              <section>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  alignItems="flex-end"
                >
                  <Grid xs={10}>&nbsp;</Grid>
                  <Grid xs={2}>
                    <StyledButton
                      variant="contained"
                      color="secondary"
                      btnColor="#FC7703"
                      onClick={tab3Reset}
                    >
                      Reset
                    </StyledButton>
                  </Grid>
                </Grid>
                <Grid
                  container
                  xs={12}
                  spacing={4}
                  direction="row"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <Grid item xs={12} sm={12} md={7}>
                    <Paper>
                      <h3>ColBERT</h3>
                      <center>
                        {' '}
                        <p>
                          Top 5 passages retrieved in
                          <b>{`${datasetResult || ''}`} ms</b>
                        </p>
                      </center>
                      {datasetResult && (
                        <Paragraph>
                          <List>
                            {datasetResult1?.passages?.map((passage, ind) => (
                              <PassageListItem
                                highlight={
                                  ind ==
                                  datasetResult1.highlighted_passage_index
                                }
                              >
                                {passage}
                              </PassageListItem>
                            ))}
                          </List>
                        </Paragraph>
                      )}
                    </Paper>
                  </Grid>
                  <Grid item xs={10} sm={10} md={5}>
                    <ChatBot>
                      <ChatHeader>
                        <h3>Conversational AI</h3>
                      </ChatHeader>
                      <ChatBotContainer ref={refToScroll}>
                        {chatbotList.map((chatData) => (
                          <p id={chatData.type}>{chatData.data}</p>
                        ))}
                      </ChatBotContainer>
                      <InputContainer>
                        <TextField
                          fullWidth
                          placeholder="Ask a Query "
                          variant="outlined"
                          value={chatbotQuery}
                          onChange={handleChatbotQuery}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  onClick={enterByodQuery}
                                >
                                  Send
                                </Button>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </InputContainer>
                    </ChatBot>
                  </Grid>
                </Grid>
              </section>
            )}
          </TabPanel>
        </DemoContainer>
      </Collapse>
      <section>
        <Dialog
          onClose={closeCommonDialog}
          aria-labelledby="diabetes-dialog-title"
          open={openDialog}
          maxWidth="lg"
        >
          <DialogTitle id="diabetes-dialog-title" onClose={closeCommonDialog}>
            {/* {this.state.dialogTitle} */}
            <IconButton aria-label="Close" onClick={closeCommonDialog}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Grid
              container
              xs={12}
              spacing={2}
              direction="row"
              justify="center"
            >
              <section>
                <Grid item xs={12}>
                  <Paragraph>
                    <p>
                      User can bring his/her own data, train ColBERT Model,
                      generate checkpoint files, index data and retrieve top
                      passages to the query asked.
                    </p>
                    <strong>Dataset Requirement –</strong>
                    <p>
                      The file to be trained should be in query, positive
                      passage, negative passage format. But if the negative
                      passages are not supplied with the query and positive
                      passages then we can generate them using BM25 (a fast but
                      less effective retriever). If a query has k positive
                      passages, we generate top(k+20) passages from BM25 and
                      take top(k+1) till top(k+20) as negative passages for the
                      same query. So, for each query, we create around 20
                      negatives for training (e.g., if we have 1000 queries, and
                      each query has 5 positives, we can use 5x1000x20 triples
                      for training).
                    </p>
                  </Paragraph>
                  <section>
                    <center>
                      <Grid item xs={12} sm={10} md={10}>
                        <img width="80%" src={ColbertAnalysis_sol1} alt="" />
                      </Grid>
                    </center>
                    <section>
                      <Grid item xs={12}>
                        <Paragraph>
                          <center>
                            <Grid item xs={10} sm={8} md={6}>
                              <img
                                width="60%"
                                height="100%"
                                src={ColbertAnalysis_sol2}
                                alt=""
                              />
                            </Grid>
                          </center>
                          <br />
                          <center>
                            <Grid item xs={12} sm={12} md={12}>
                              <img
                                width="90%"
                                height="100%"
                                src={ColbertAnalysis_sol3}
                                alt=""
                              />
                            </Grid>
                          </center>
                          <strong>Training – </strong>
                          <p>
                            Training attempts to increase the difference in the
                            similarity scores of positive and negative passages
                            w.r.t query as much as it can. The dataset is split
                            into 80:20 ratio of two sets for training and
                            validation respectively. For every 2000th batch, the
                            training creates a checkpoint file. Based on the
                            number of batches, multiple checkpoint files will be
                            generated.
                          </p>
                          <strong>Validation – </strong>
                          <p>
                            Here, we validate and compare the generated
                            checkpoint files to find the best among them. We
                            provide the user some of the standard metrices to
                            evaluate a checkpoint–{' '}
                          </p>
                          <center>
                            <Grid item xs={8} sm={5} md={6}>
                              <img
                                width="40%"
                                src={ColbertAnalysis_sol4}
                                alt=""
                              />
                            </Grid>
                          </center>
                          <p>
                            <b>MRR@10 (Mean Reciprocal Rank):</b> The reciprocal
                            rank of a query response is the multiplicative
                            inverse of the rank of the first correct answer.
                            Then we take the mean of all the reciprocal ranks.
                          </p>
                          <center>
                            <Grid item xs={8} sm={6} md={6}>
                              <img
                                width="50%"
                                src={ColbertAnalysis_sol5}
                                alt=""
                              />
                            </Grid>
                          </center>
                          <p>
                            <b>R@1 (Recall at 1):</b> The fraction of the number
                            of successful relevant passages retrieved at topmost
                            position.
                          </p>
                          <center>
                            <Grid item xs={12} sm={10} md={8}>
                              <img
                                width="80%"
                                src={ColbertAnalysis_sol6}
                                alt=""
                              />
                            </Grid>
                          </center>
                          <p>
                            <b>R@5 (Recall at 5):</b> The fraction of the number
                            of successful relevant passages retrieved from top 5
                            positions.
                          </p>
                          <p>
                            The validation results would be similar to the ones
                            displayed below. And by comparing the checkpoint
                            files on these parameters, we’ll take the best
                            checkpoint for further processes.
                          </p>
                          <center>
                            <Grid item xs={12} sm={10} md={8}>
                              <img
                                width="90%"
                                src={ColbertAnalysis_sol7}
                                alt=""
                              />
                            </Grid>
                          </center>
                          <strong>Indexing –</strong>
                          <p>
                            After choosing the best checkpoint, we start the
                            indexing on the positive passages from the file
                            provided by the user for training. For faster
                            retrieval, colBERT create and save the indexes of
                            the passages locally.
                          </p>
                          <p>Below is the sample from the passages file.</p>
                          <center>
                            <Grid item xs={12} sm={10} md={10}>
                              <img
                                width="60%"
                                src={ColbertAnalysis_sol8}
                                alt=""
                              />
                            </Grid>
                          </center>
                          <strong>Retrieval –</strong>
                          <p>
                            The top 5 relevant passages related to the searched
                            query is retrieved along with the short-answer.
                          </p>
                          <center>
                            <Grid item xs={12} sm={10} md={8}>
                              <img
                                width="80%"
                                src={ColbertAnalysis_sol9}
                                alt=""
                              />
                            </Grid>
                          </center>
                          <center>
                            <Grid item xs={12} sm={10} md={10}>
                              <img
                                width="80%"
                                src={ColbertAnalysis_sol10}
                                alt=""
                              />
                            </Grid>
                          </center>
                          <center>
                            <Grid item xs={12} sm={10} md={8}>
                              <img
                                width="80%"
                                src={ColbertAnalysis_sol11}
                                alt=""
                              />
                            </Grid>
                          </center>
                        </Paragraph>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <strong>BOYD Architecture -</strong>
                        <br />
                        <img width="100%" src={byod} alt="" />
                      </Grid>
                      <br />
                      <Grid item xs={6} sm={6} md={6}>
                        <strong>System Configuration -</strong>
                        <br />
                        <img width="100%" src={config} alt="" />
                      </Grid>
                    </section>
                  </section>
                </Grid>
              </section>
            </Grid>
          </DialogContent>
        </Dialog>
      </section>

      {isLoading && <Spinner />}

      {dialogContact && (
        <ContactUsDialog open={openDialogContact} close={closeDialogContact} />
      )}
    </SolutionContainer>
  );
};

function TabPanel(props) {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tablepanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <PanelContainer>{children}</PanelContainer>}
    </div>
  );
}

export default memo(ColbertAnalysis);

const PanelContainer = styled(Paper)`
  &.MuiPaper-root {
    padding: 15px;
  }
`;

export const StyledTabs = styled(Tabs)`
.MuiTab-root{
    text-transform: none;
}

.Mui-selected{
    background-color: #ff0055;
    color: white;
    font-weight: bold;
    text-transform: none;
  }

@media only screen and (max-width: 950px) {
  .MuiTabs-flexContainer{
    flex-wrap: wrap;
    .PrivateTabIndicator-colorPrimary-7{
      display: none;
    }
    .Mui-selected{
        border: 1px solid #3f51b5;
        background-color: #ff0055;
        color: white;
      }
  }
}
@media only screen and (max-width: 650px) {
    background-color: #ffff;
    font-size: 12px;
    .MuiTabs-flexContainer{
      flex-direction: column;
      .Mui-selected{
        border: 1px solid #3f51b5;
        background-color: #ff0055;
        color: white;
      }
    }
`;

const ChatBot = styled(Paper)`
  &.MuiPaper-root {
    height: 450px;
    padding: 10px;
    overflow: hidden;
    margin-top: 10%;
  }
`;
const ChatBotContainer = styled.section`
  color: #ffff;
  display: flex;
  flex-direction: column;
  height: 320px;
  overflow-y: auto;
  #question {
    background-color: #ff9900;
    text-align: left;
    border-radius: 0 10px 10px 0;
    margin-right: auto;
    max-width: 90%;
  }
  #answer {
    background-color: #2941b7;
    text-align: right;
    border-radius: 10px 0 0 10px;
    margin-left: auto;
    max-width: 90%;
  }
  p {
    padding: 5px;
    margin: 5px 0;
    max-width: 100%;
    position: relative;
  }
  span {
    height: 50px;
  }
`;
const InputContainer = styled.div`
  position: sticky;
  width: 100%;
  left: 0;
  bottom: 0;
  background: #ffff;
`;
const ChatHeader = styled.div`
  width: 100%;
  text-align: center;
  border: 1px solid gray;
  background: black;
  color: #ffff;
`;

const Buttondiv = styled.div`
  float: right;
`;
const PassageListItem = styled(ListItem)`
  background-color: ${(props) => props.highlight && '#FFFF00'};
`;

const StyledTableHead = styled(TableCell)`
  &.MuiTableCell-root {
    background-color: #3c40af;
    color: white;
    font-weight: bold;
    font-size: smaller;
    @media only screen and (max-width: 450px) {
      font-size: x-small;
    }
  }
`;
const TablePaper = styled.div`
  overflow: auto;
  width: 99%;
  height: auto;
`;
export const StyledRadioGroup = styled(RadioGroup)`
  display: block;
`;
