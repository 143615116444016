import React, { useEffect, useState } from "react";
import { Paper, Grid } from "@material-ui/core";
import styled from "styled-components";
import { Link } from "react-router-dom";
import NavbarConstants from "../../constants/NavbarContent";

export default function DomainContent({ transform, close }) {
  const [content, setContent] = useState(null);

  useEffect(() => {
    // by default first category(bio-pharma) is selected
    const domainObj = NavbarConstants.Domain;
    const categoryKeys = Object.keys(domainObj);
    const selectedSubmenu = categoryKeys[0];
    const subCategoryList = domainObj[selectedSubmenu].sections;
    const subCategoryKeys = Object.keys(subCategoryList);

    setContent({
      domainObj,
      categoryKeys,
      selectedSubmenu,
      subCategoryList,
      subCategoryKeys,
    });
  }, []);

  // change content based on primary category
  const setSubmenu = (e) => {
    const selectedSubmenu = e.target.getAttribute("data-value");
    const subCategoryList = content.domainObj[selectedSubmenu].sections;
    const subCategoryKeys = Object.keys(subCategoryList);
    setContent({
      ...content,
      selectedSubmenu,
      subCategoryList,
      subCategoryKeys,
    });
  };

  return (
    <DomainConatiner transform={transform}>
      <Tab>
        {content
          && content.categoryKeys.map((key) => (
            <TabMenu
              onClick={setSubmenu}
              data-value={key}
              selected={content.selectedSubmenu === key}
            >
              {content.domainObj[key].heading}
            </TabMenu>
          ))}
      </Tab>
      {content && (
        <Fieldset>
          <Legend>
            <strong>
              {content.domainObj[content.selectedSubmenu]
                && content.domainObj[content.selectedSubmenu].heading}
            </strong>
          </Legend>
          <Grid container alignItems="flex-start">
            {content.subCategoryKeys
              && content.subCategoryKeys.map((key) => (
                <Grid item xs={12} sm={6} md={3}>
                  <h3>
                    <HedingLink>
                      {content.subCategoryList[key].heading}
                    </HedingLink>
                  </h3>

                  {content.subCategoryList[key].category
                    && content.subCategoryList[key].category.map((obj) => (obj.externalLink ? (
                      <MaterialLink target="_blank" rel="noopener noreferrer">
                        {obj.heading}
                      </MaterialLink>
                    ) : (
                      <LinkItem to={obj.path} onClick={close}>
                        {obj.heading}
                      </LinkItem>
                    )))}
                </Grid>
              ))}
          </Grid>
        </Fieldset>
      )}
    </DomainConatiner>
  );
}

const DomainConatiner = styled(Paper)`
  margin: 0 10px;
  position: fixed;
  right: 0;
  left: 0;
  top: 65px;
  z-index: 2;
  width: auto;
  max-height: 350px;
  overflow: hidden;
  padding: 20px;
  background: ${(props) => (props.transform ? "#05334E" : "white")};
  border-radius: 5px;
  transition: background 0.6s ease-in-out;
  a,
  legend {
    color: ${(props) => (props.transform ? "#ffff" : "#5d5b60")};
  }
  animation: slide 0.5s ease-out;
  @keyframes slide {
    from {
      opacity: 0;
      transform: translateX(-30px);
    }
    to {
      opacity: 1;
      transform: translateX(0px);
    }
  }
  @media only screen and (max-width: 900px) {
    overflow-y: scroll;
    font-size: smaller;
    padding: 0;
    margin: 2px;
    max-height: 80%;
  }
`;
const Legend = styled.legend`
  text-align: left;
  padding: 0 20px;
  border: 2px solid #fc7703;
`;
const HedingLink = styled(Link)`
  text-align: left;
  width: max-content;
  font-size: 15px;
  padding: 5px;
  &:hover: {
    color: #fc7703;
  }
`;
const Tab = styled.div`
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;
const TabMenu = styled.div`
  padding: 5px;
  text-align: center;
  background: ${(props) => (props.selected ? "#fc7703" : "rgb(52, 62, 190)")};
  color: white;
  min-width: 250px;
  cursor: pointer;
  margin: 3px;
  @media only screen and (max-width: 900px) {
    width: auto;
    margin: 5px;
  }
`;
const Fieldset = styled.fieldset`
  border: 3px solid #fc7703;
  border-radius: 10px;
  margin: 10px 41px 0px 17px;
  @media only screen and (max-width: 900px) {
    margin: 10px;
  }
`;

const LinkItem = styled(Link)`
  text-align: left;
  text-decoration: none;
  display: block;
  width: max-content;
  font-size: 14px;
  padding: 5px;
  &:hover: {
    color: #fc7703;
    textdecoration: none;
  }
`;
