import React, { memo, useState } from "react";
import styled from "styled-components";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Grow from "@material-ui/core/Grow";
import Highcharts from "highcharts";
import Input from "@material-ui/core/Input";

// import local components
import CommonService from "../../../utils/services/CommonService";

import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  HighchartContainer,
  Result,
} from "../../../styles/common";
import SolutionContainer from "../../common/SolutionContainerWrapper";

// images
import retinaDamageClassification_sample1 from "../../../assets/images/app/retinaDamageClassification_sample1.webp";

const { getQueryResult, uploadFile } = CommonService(
  "z1dApps",
  "retinaDamageClassification",
);

const RetinaDamageClassification = () => {
  const [showSpinner, setSpinner] = useState(false);
  const [result, setResult] = useState("");
  const [uploadfile, setUploadfile] = useState("");
  const [notification, setNotification] = useState("");

  const getsampleResult = () => {
    setSpinner(true);
    setResult("");
    getQueryResult("sample")
      .then((res) => {
        console.log(JSON.parse(res));
        const datasplit = JSON.parse(res);
        setSpinner(false);
        setResult(datasplit);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const formHighChart = (datasplit) => {
    let i = 0;
    const dataset = [];
    console.log(datasplit);
    for (i = 0; i < Object.keys(datasplit.Classes).length; i++) {
      dataset.push([datasplit.Classes[i], datasplit.Probability[i]]);
    }
    console.log(dataset);
    // code for highcharts bar implements
    Highcharts.chart("container", {
      chart: {
        type: "column",
      },
      title: {
        text: "Probability Distribution for Predicted Classes",
      },
      subtitle: {
        text: "Hover to Show Class Probability",
      },
      xAxis: {
        type: "category",
      },
      yAxis: {
        title: {
          text: "Probability",
        },
      },

      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: "{point.y:.1f}%",
          },
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b><br/>',
      },
      series: [
        {
          name: "Percentage",
          colorByPoint: true,
          data: dataset,
        },
      ],
    });
  };

  const getUploadResult = () => {
    setSpinner(true);
    setResult("");
    uploadFile("upload", uploadfile)
      .then((res) => {
        const parsedRes = JSON.parse(res);
        setSpinner(false);
        setResult(parsedRes);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const onChangeFile = (e) => {
    const file = e.currentTarget.files[0];
    setUploadfile(file);
  };

  const formTable = (responseData) => {
    console.log(responseData);
    formHighChart(responseData);
    return (
      <Paper>
        <ScrollableTable>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <h3>Classes</h3>
                </TableCell>
                <TableCell>
                  <h3>Probability</h3>
                </TableCell>
                <TableCell>
                  <h3>Description</h3>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {["0", "1", "2", "3"].map((value) => (
                <TableRow>
                  <TableCell>{responseData.Classes[value]}</TableCell>
                  <TableCell>{responseData.Probability[value]}</TableCell>
                  <TableCell>{responseData.Description[value]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </ScrollableTable>
      </Paper>
    );
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Retinal optical coherence tomography (OCT) is an imaging technique
            used to capture high-resolution cross sections of the retinas of
            living patients. Approximately 30 million OCT scans are performed
            each year, and the analysis and interpretation of these images takes
            up a significant amount of time. For this application, the
            state-of-the-art VGG16 pretrained Pytorch-based model has been used
            as a base to train an algorithm that identifies and classifies
            damages caused to human retina from Retinal OCT images. The results
            also show the probabilities for each predicted type.
          </p>
          <strong>Dataset Description </strong>
          <ul>
            <li>
              Optical coherence tomography (OCT) images (Spectralis OCT, Heidelberg Engineering, Germany) were selected from retrospective cohorts of adult patients from the Shiley Eye Institute of the University of California San Diego, the California Retinal Research Foundation, Medical Center Ophthalmology Associates, the Shanghai First People’s Hospital, and Beijing Tongren Eye Center between July 1, 2013 and March 1, 2017
            </li>
            <li>
              The dataset is organized into 3 folders (train, test, val) and contains subfolders for each image category (NORMAL,CNV,DME,DRUSEN).
            </li>
            <li>
              There are 84,495 X-Ray images (JPEG) and 4 categories (NORMAL,CNV,DME,DRUSEN).
            </li>
            <li>
              Images are labeled as (disease)-(randomized patient ID)-(image number by this patient) and split into 4 directories: CNV, DME, DRUSEN, and NORMAL.
            </li>
          </ul>
          <p>
            Upload the image of cross section of Retina to check the status if
            the image falls into below categories
          </p>
          <p>The four categories can be summarized as follows:</p>
          <ul>
            <li>
              <b>Drusen - </b>
              Drusen, from the German word for node or geode (singular, "Druse"), are tiny yellow or white accumulations of extracellular material that build up between Bruch's membrane and the retinal pigment epithelium of the eye.
            </li>
            <li>
              <b>CNV - </b>
              Choroidal neovascularization (CNV) involves the growth of new blood vessels that originate from the choroid through a break in the Bruch membrane into the sub–retinal pigment epithelium (sub-RPE) or subretinal space. CNV is a major cause of visual loss.
            </li>
            <li>
              <b>Diabetic Macular Edema (DME)– </b>
              It is an accumulation of fluid in the macula part of the retina that controls our most detailed vision abilities—due to leaking blood vessels
            </li>
            <li>
              <b>Normal – </b>
              You are completely fit and doing well!
            </li>
          </ul>
          <LinkContainer>
            <Grid container spacing={2}>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://www.kaggle.com/datasets/paultimothymooney/kermany2018"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Human_Retina_Classification"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <br />
        <DemoContainer>
          <Grid
            container
            xs={12}
            spacing={1}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={10} sm={5} md={2}>
              <Image src={retinaDamageClassification_sample1} />
              <br />
              <StyledButton
                variant="contained"
                color="primary"
                data-sample="sample_image1"
                onClick={getsampleResult}
              >
                Sample
              </StyledButton>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={2}>
              <h3>OR</h3>
            </Grid>
            <Grid item xs={10} sm={5} md={3}>
              <Input
                type="file"
                color="primary"
                name="uploadedFile"
                onChange={onChangeFile}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={getUploadResult}
              >
                Execute
              </StyledButton>
            </Grid> */}
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}

          <Grow in={result} timeout={1500}>
            <div>
              <br />
              <section>
                <Result>
                  <strong>Result : </strong>
                </Result>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={12} md={6}>
                    {result && formTable(result)}
                  </Grid>
                </Grid>
              </section>
            </div>
          </Grow>

          <HighchartContainer id="container" display={result} />
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(RetinaDamageClassification);

const Image = styled.img`
  width: 100%;
  height: 150px;
  @media only screen and (max-width: 900px) {
    width: auto;
    max-width: 200px;
  }
  @media only screen and (max-width: 450px) {
    width: auto;
    max-width: 200px;
  }
`;
const ScrollableTable = styled.div`
  overflow: scroll;
  max-height: 500px;
  .MuiTableCell-root {
    @media only screen and (max-width: 900px) {
      font-size: 14px;
    }
    @media only screen and (max-width: 450px) {
      font-size: 12px;
    }
  }
`;
