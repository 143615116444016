import React, { memo } from "react";
import { Grid, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components
import styled from "styled-components";
import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  SolutionContainer,
  LinkContainer,
} from "../../../styles/common";

const WHOSuicideStatistics = () => {
  const data = [
    [
      "Sex-Age Suicide Distribution by years in the USA",
      "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/SuicideCountbySEX/Sheet4",
    ],
    [
      "Suicide Count by sex in comparison to Year",
      "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/SuicideAnalysis_16185079667620/Dashboard1",
    ],
    [
      "Distribution of Total Suicides in all the countries from 1985 -2016",
      "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/CountrywiseDistributionfrom1979-2016Trendflow/Sheet2",
    ],
  ];

  return (
    <SolutionContainer>
      <Collapse text="Description">
        <Paragraph>
          <p>
            <b>About:</b>
            <br />
            Mental health is one of the major concerns around the globe. With
            these visualizations it is aimed to know about the number of
            suicides that have been attempted over the past few years and also
            the gender wise distribution to know which is more prone to
            attempting suicides. The data gives an wide range of input and the
            most concerning factor is the age group distribution. This can give
            relevant authorities an insight on the mental health of the citizens
            in the country and help taking impactful decisions that may help in
            reducing the number of suicides being attempted.
          </p>
          <p>
            This Visualization show that there is an concerning growth in the
            number of suicides attempted which also concludes that "Male" gender
            are more prone to suicides than the "Females". There is a noticeable
            drop in the year 1984 in the number of suicides and the consequent
            year has an exponential growth in the suicides attempted which do
            not seem to dip until 2016.
          </p>
          <p>
            <b>Data Description:</b>
            <br />
            The data used in this usecase is the data collected by the WHO
            (World Health Organization) on how many suicides have been attempted
            over the years across 141 countries.
          </p>
          <p>The data consists of 6 columns namely:</p>
          <ul>
            <li>Country - Name of the country</li>
            <li>Year - Year of data (i.e. 1979-2016)</li>
            <li>Sex - Gender of the individuals</li>
            <li>Age - Age range of the individual who attempted suicide</li>
            <li>Suicide_no - Total Number of suicides attempted</li>
            <li>Population - Total population of the country in that year</li>
          </ul>
          <p>
            The data is widely distributed by year and age. The 'age' column is
            divided into 6 ranges i.e. 5-14 yrs , 15-24yrs , 25-34 yrs, 35-54
            yrs, 55-74 yrs and 75+ yrs.
          </p>
          <p>
            Here, The visualizations performed give an insight about the number
            of suicides being attempted in each country over the time, The
            distribution of suicide being attempted age wise and the proportion
            of gender in it, The Distribution of gender.
          </p>
          <LinkContainer>
            <Grid container spacing={2}>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://github.com/sharmaroshan/WHO-Suicide-Statistics/blob/master/who_suicide_statistics.csv"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://github.com/sharmaroshan/WHO-Suicide-Statistics"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <br />
        <Typography variant="h6" gutterBottom>
          <FontAwesomeIcon icon={faInfoCircle} />
          {' '}
          Visualizations
        </Typography>
        <br />
        <Grid container xs={12} spacing={5} direction="row">
          {data.map((data) => (
            <Grid item xs={12} sm={6} md={3}>
              <A target="_blank" href={data[1]}>
                <StyledCard>{data[0]}</StyledCard>
              </A>
            </Grid>
          ))}
        </Grid>
        <br />
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(WHOSuicideStatistics);
const A = styled.a`
  text-decoration: none;
`;
const StyledCard = styled.div`
  height: 100%;
  text-align: center;
  background-color: #0080ff;
  color: white;
  border-radius: 4px;
  margin-left: 40px;
  padding: 12px;
  flex-grow: 0;
  flex-basis: 25%;
  margin-bottom: 0.35rem;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075rem;
`;
