import React, { memo } from "react";
import { Grid } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
// import local components

import styled from "styled-components";
import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  SolutionContainer,
  LinkContainer,
} from "../../../styles/common";

// images
import tabularData_sol1 from "../../../assets/images/app/tabularData_sol1.webp";

const TabularData = () => (
  <SolutionContainer>
    <Collapse text="Description">
      <Grid item xs={12} md={12}>
        <Paragraph>
          CTGAN is a GAN-based method to model tabular data distribution and
          sample rows from the distribution. CTGAN has the mode-specific
          normalization to overcome the non-Gaussian and multimodal
          distribution. Also, the designing of a conditional generator and
          training-by-sampling is performed to deal with the imbalanced
          discrete columns. And then fully connected networks and several
          recent techniques are used to train a high-quality model. Properly
          representing the data is critical in training neural networks.
          Discrete values can naturally be represented as one-hot vectors,
          while representing continuous values with arbitrary distribution is
          non-trivial. In CTGAN, a mode-specific normalization is designedg to
          deal with columns with complicated distributions. In this method,
          each column is processed independently. Each value is represented as
          a one-hot vector indicating the mode, and a scalar indicating the
          value within the mode. CTGANs has the following features
          <ul>
            <li>
              <b>Preprocessing:</b>
              {' '}
              CTGAN uses sophisticated Variational
              Gaussian Mixture Model to detect modes of continuous columns.
            </li>
            <li>
              <b>Network structure:</b>
              {' '}
              CTGAN uses Fully-connected networks
              which is more efficient than LSTM to generate synthetic data.
              {" "}
            </li>
            <li>
              <b>Features to prevent mode collapse:</b>
              {' '}
              A conditional
              generator is used and the training data is resampled to prevent
              model collapse on discrete columns. WGANGP and PacGAN has been
              used to stabilize the training of GAN.
            </li>
          </ul>
        </Paragraph>
      </Grid>
      <Grid item xs={12} md={12}>
        <center>
          <img width="60%" src={tabularData_sol1} />
        </center>
      </Grid>
      <Grid item xs={12} md={12}>
        <Paragraph>
          For more info please refer whitepaper
          {" "}
          <a href="https://arxiv.org/pdf/1907.00503.pdf" target="_blank" rel="noopener noreferrer">
            Click Here
          </a>
          <br />
          For Blog on Tabular Data using GAN Please refer
          {" "}
          <a
            href="https://towardsdatascience.com/review-of-gans-for-tabular-data-a30a2199342?gi=44903ce6c94d"
            target="_blank"
            rel="noopener noreferrer"
          >
            Click Here
          </a>
          <br />
          For Presentation on Tabular Data Synthesizer Please refer
          {" "}
          <a
            href="https://material.vlifevirtusa.com/TabularDataSynthesizer/TabularDataSynthesizer.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Click Here
          </a>
        </Paragraph>
      </Grid>
      <LinkContainer>
        <Grid container spacing={2}>
          <Grid item>
            <StyledButton
              variant="outlined"
              color="primary"
              size="large"
              startIcon={<OpenInNewIcon />}
            >
              <a
                href="https://arxiv.org/pdf/1907.00503.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Data Source
              </a>
            </StyledButton>
          </Grid>
          <Grid item>
            <StyledButton
              variant="outlined"
              color="primary"
              size="large"
              startIcon={<OpenInNewIcon />}
            >
              <a
                href="https://github.com/Diyago/GAN-for-tabular-data"
                target="_blank"
                rel="noopener noreferrer"
              >
                Notebook
              </a>
            </StyledButton>
          </Grid>
        </Grid>
      </LinkContainer>
    </Collapse>
    <Collapse text="Demo">
      <Grid item xs={12} md={12}>
        <center>
          <StyledButton
            color="primary"
            variant="contained"
            onClick={() => {
              window.open("https://apps.vlifevirtusa.com:8501/", "_blank");
            }}
          >
            Try Demo
          </StyledButton>
        </center>
      </Grid>
    </Collapse>

  </SolutionContainer>
);

export default memo(TabularData);
