import APIDATA from "./api-config";
// servicelines
const APIList = {
  commercialAnalytics: {
    url: APIDATA.COMMERCIAL_ANALYTICS,
    solutions: {
      competitorAnalyzer: {
        sample: "competitoranalysis/",
      },
      forecastingExcellence: {
        medicineList: "forecastingexcellence/",
        drugData: "forecastingexcellence/featuretoforecast/",
      },
      frequentPharmacyVisitor: {
        idList: "frequentpharmacyvisitor/ListEnrollId/",
        frequency: "frequentpharmacyvisitor/Prediction/",
      },
      drugRecommendation: {
        sample: "drugrecommendation/",
      },
      predictingAdClick: {
        adClickRate: "predictadclick/",
      },
      customerSegmentation: {
        sampleFileList: "customersegmentation",
        sampleFileData: "customersegmentation/",
      },
      drugReviewAnalyzer: {
        sample: "drugreviewanalyzer?&userValue[]=",
      },
      medicalAppointmentNoShow: {
        output: "noshowprediction/",
      },
      freightcostprediction: {
        output: "freightcostprediction/",
      },
      insuranceClaimPrediction: {
        output: "insuranceclaimsprediction/",
      },
      SurgicalSite: {
        withParams: "SurgicalSiteInfection/",
      },
      FetalHealth: {
        withParams: "FetalHealthClassification/",
      },
    },
  },
  bioInformatics: {
    url: APIDATA.BIO_INFORMATICS,
    solutions: {
      geneticVariantClassifier: {
        output: "Genetic_Variant_Classifier/",
      },
      antibioticResistancePrediction: {
        output: "antibioticresistance/",
      },
      deepNuclie: {
        output: "deep_nuclei/",
        uploadResult: "deep_nuclei/",
      },
      geneExpressionAnalysis: {
        output: "GeneExpressionAnalysis/",
      },
      breastCancerClassification: {
        output: "BreastCancerClassification/",
      },
      predictOligomericState: {
        output: "",
      },
      genomePhenotype: {
        sample: "SampleGeno/",
        upload: "Genotype",
      },
      miceProteinClassification: {
        output: "micelearningclassification/viz/",
      },
      gastrointestinalCancer: {
        upload: "uploadimgmss/",
        sample: "msscancer/",
      },
      prostate_cancer: {
        upload: "PrCancer/fileupload/",
        sample: "PrCancer/",
      },
      geneticAlgorithm: {
        getDataset: "showdata/",
        chooseModel: "baselinemodel/",
        retrainModel: "deapalgo/",
      },
      cryoTherapyAnalysis: {
        output: "cryotherapy/",
      },
      cervical_cancer: {
        output: "cervical_cancer/",
      },
      brainWave: {
        output: "brainwave/",
      },
      diabetic: {
        upload: "diabetic_retinopathy/index/file",
        sample: "diabetic_retinopathy/",
      },
      sequenceGenerator: {
        output: "SequenceGenerator/",
      },
    },
  },
  patientEngagement: {
    url: APIDATA.PATIENT_ENGAGEMENT,
    solutions: {
      timelinessOfCare: {
        output:
          "eliminatingwaittimefromhospitals/TimelinessOfCareNationalComparison/",
      },
      strokePrediction: {
        output: "heartstroke/",
      },
      LungCancerComorbidities: {
        sampleResult: "lungcancer/",
        uploadResult: "lungcancer/uploadaudio/",
      },
      liverDisease: {
        output: "liverdiagnosis/LiverDiseasePrediction/",
      },
      deepScap: {
        output: "DeepScap/",
      },
      cancerLectins: {
        output: "cancerlectins/",
      },
      behaviouralAnalysis: {
        output: "banalysis/",
      },
      rom: {
        upload: "videoanalysis/uploadvid/",
        sample: "videoanalysis/rom/sample/",
      },
      arrhythmiaClassification: {
        output: "ArrhythmiaClassification/arrhythmiaclassification/",
      },
      bloodcell: {
        output: "bloodcellinception/",
        uploadResult: "bloodcellinception/uploadIMG/",
      },
      pneumoniadiagnosis: {
        output: "pneumoniadiagnosis/",
        uploadResult: "pneumoniadiagnosis/uploadIMG/",
      },
      intracranialHemorrhage: {
        output: "intracranial_hemorrhage/",
        uploadResult: "intracranial_hemorrhage/upload_image",
      },
      uhgbreastcancerprediction: {
        sampleData: "BreastCancerClassificationGans/data",
      },
      adverseFood: {
        withParams: "adverse_food_event/",
      },
      OCRDeathCertificate: {
        sample: "DeathOCR/sample",
        upload: "DeathOCR/upload",
      },
    },
  },
  payerProvider: {
    url: APIDATA.PAYER_PROVIDER,
    solutions: {
      handGesture: {
        sample1: "PPServices/HandGesture/sample1/",
        sample2: "PPServices/HandGesture/sample2/",
        upload: "PPServices/HandGesture/upload/",
      },
      mentalHealth: {
        sample: "PPServices/MentalHealthTreatmentPrediction/",
      },
      insuranceComplaints: {
        output: "PPServices/complainstatus/",
      },
      costEstimate: {
        output: "PPServices/claims/",
      },
      usUninsured: {
        getChartDetails: "PPServices/us_uninsured/",
      },
      lifeinsurance: {
        withParams: "PPServices/lifeinsurance/",
      },
      insuranceChurnPrediction: {
        withParams: "PPServices/InsuranceChurnPrediction/",
      },
      fraudClaims: {
        withParams: "PPServices/frauddetection/",
      },
      drug_moa: {
        withParams: "PPServices/MoA/",
      },
      contactUs: {
        sendMail: "PPServices/Contact_Us/",
      },
      suicideProbability: {
        output: "PPServices/SuicidePreventionPrediction/",
      },
      dementia: {
        withParams: "PPServices/dementia/",
      },
      insurancePremium: {
        withParams: "PPServices/insurancepremium/",
      },
      diagnosticPrediction: {
        output: "PPServices/nlpdiseasediagnosis?&userValue[]=",
      },
      heathcare_insurance: {
        withParams: "PPServices/heathcare_insurance/",
      },
      heartattackanalysis: {
        output: "PPServices/HeartAttack/",
      },
      sdoh: {
        withParams: "PPServices/ChronicConditionPredictionusingSDOH/",
      },
      lengthstayhospital: {
        withParams: "PPServices/length_stay/",
      },
      shadowRemoval: {
        sample: "PPServices/portraitshadowremoval/sample/",
        sample1: "PPServices/portraitshadowremoval/sample1/",
      },
      stressDetectionUsingHRV: {
        output: "PPServices/HRVInputTable/data",
      },
    },
  },
  clinicalTrials: {
    url: APIDATA.CLINICAL_TRIALS,
    solutions: {
      drugCascading: {
        sample: "Polypharmacy/",
      },
      predictingADR: {
        output: "AdverseDrugReaction/",
      },
      predictingTimePeriod: {
        output: "TimePeriod/",
      },
      geneVariations: {
        output: "GeneVariations/",
      },
      predictingEligibility: {
        output: "Eligibility_Cancer?userValue[]=",
      },
      sideEffects: {
        output: "PredictingSideEffect/",
      },
      clinicalNotesPreprocessor: {
        output: "ClinicalNotesPreprocessor/",
      },
      feasibilityAnalysis: {
        sample: "FeasibilityAnalysis?userValue[]=/",
      },
      mortalityEffect: {
        sample: "PredictingDeath/",
      },
      breastCancerProteomes: {
        output: "BreastCancerProteomes/",
      },
      activationMetastasis: {
        sample: "Histopathologic_cancer/",
        upload: "Histopathologic_cancer/UploadImg",
      },
    },
  },
  sailApps: {
    url: APIDATA.SAIL,
    solutions: {
      shapleyEMR: {
        sampleData: "shapleyemr/SampleData/",
        retrainModel: "shapleyemr/ShapleyModelBuilding/",
        shapleyModel: "shapleyemr/BaseMetrics/",
      },
      shapleyImages: {
        sampleData: "shapleyimage/data/",
        cnnModel: "shapleyimage/originalmodel/",
        shapleyModel: "shapleyimage/shapleymodel/",
      },
      cpca: {
        sampleData: "cpca/SampleData/",
        withParams: "cpca/ClassInput/",
        upload: "cpca/uploadCSV/",
      },
      cpcaImages: {
        withParams: "cpca_images/SampleData/",
      },
    },
  },
  z1dApps: {
    url: APIDATA.Z1D,
    solutions: {
      imageSim: {
        upload: ":8602/imgSim/upload",
      },
      contextualSearch: {
        upload: "/cbs/cbs_upload",
        search: "/cbs/cbs_search",
      },
      periodontalChar: {
        upload: ":8016/pd_extraction/UploadPDExtractor",
      },
      encryptedTextData: {
        validate: ":8519/ValidateData/validateData",
        encrypt: ":8519/GetEncryptedData/EncryptData",
        predict: ":8519/IndividualPredict/individualPredict",
        DecryptData: ":8519/DecryptData/decryptData",
      },
      colbertAnalysis: {
        bert: ":8001/colbert_app/bert/",
        colbert: ":8001/colbert_app/colbert/",
        medical: ":8001/colbert_app/med-colbert/",
        upload: ":8001/colbert_app/upload_tsv",
        collections: ":8001/colbert_app/view_my_collections",
        startindex: ":8001/colbert_app/index_tsv",
        loadindex: ":8001/colbert_app/load_indexes",
        retrieve: ":8001/colbert_app/retrieve/",
      },
      maskDetection: {
        sampleResult: ":5006/DetectMaskImage/detect_mask_image/",
        uploadResult: ":5006/DetectMaskImage/detect_mask_image/upload/",
      },
      emotionDetection: {
        videoResult: ":5017/EmotionRecognition/uploadvideo/",
        uploadResult: ":5017/EmotionRecognition/uploadimage/",
      },
      mirth: {
        withParams: ":5020/Mirth/csvtohl7/?info=",
      },
      chestXRaysCovid19: {
        output: ":5026/",
      },
      pulmonaryEmbolism: {
        output: ":5032/PulmonaryEmbolismDetection/",
        uploadResult: ":5032/PulmonaryEmbolismDetection/index/file",
      },
      multiAccuracy: {
        sampleData: ":5012/MultiAccuracy/SampleData/",
        ModelBuilding: ":5012/MultiAccuracy/ModelBuilding/",
      },
      caloriePredictor: {
        upload: ":5011/Calorie/calorie_prediction/upload_image",
        sample: ":5011/Calorie/calorie_prediction/",
      },
      cervicalCancer: {
        upload: ":5002/CervicalCancerScreening/cervicalcancerscreening/upload/",
        sample: ":5002/CervicalCancerScreening/cervicalcancerscreening/",
      },
      heartSound: {
        withParams: ":5029/HeartSoundClassification/",
        upload: ":5029/HeartSoundClassification/index/file",
      },
      socialDistance: {
        upload: ":5005/SocialDistanceDetector/upload/",
        sample: ":5005/SocialDistanceDetector/sample/",
      },
      retweetcount: {
        output: ":5030/textweet/",
      },
      KoreanMortality: {
        output: ":5079/KoreanMortality/korean_mortality/",
      },
      aclTear: {
        output: ":5035/MRI/",
      },
      retinaDamageClassification: {
        upload: ":5003/uploadimg/",
        sample: ":5003/retinadamagevgg/",
      },
      imageColorization: {
        sample:
          ":5004/Black%20and%20White%20Image%20Colorization/imagecolorizationsample/",
        upload:
          ":5004/Black%20and%20White%20Image%20Colorization/image_upload/",
      },
      hostAppForm: {
        sendMail: ":5099/SendMail/send_mail/",
      },
      askForModel: {
        sendMail: ":5031/Form/form/",
      },
      KneeOsteoarthritis: {
        sample: ":5037/KneeOsteoarthritisSeverity/",
        upload: ":5037/KneeOsteoarthritisSeverity/index/file",
      },
      eegClassification: {
        upload: ":5010/EEG_classification/upload/",
        sample: ":5010/EEG_classification/",
      },
      eegOnSensoryTask: {
        withParams: ":5038/SensoryTaskEEG/MentalHealthTreatmentPrediction/",
      },
      marketscan: {
        sample: ":5009/medcr/",
      },
      confirmedCoronaCases: {
        result: ":5076/CoronaConfirmed/corona_confirmed/",
      },
      recoveryCases: {
        result: ":5078/CoronaRecovery/corona_recovery/",
      },
      mortalityCasesWorldwide: {
        result: ":5077/CoronaDeaths/corona_deaths/",
      },
      federatedLearning: {
        upload: ":5045/FL/upload",
      },
      asl: {
        upload: ":5050/ASL/index/file",
      },
      bowelPreparation: {
        output: ":5042/NerthusColoscopy-BowelMovement/",
        uploadResult: ":5042/NerthusColoscopy-BowelMovement/index/file",
      },
      dcGan: {
        upload: ":8005/DCGAN/data?&userValue[]=",
      },
      sinGan: {
        upload: ":8007/SinGAN/upload/",
        getMetaData: ":8007/SinGAN/metadata_user_image/",
        getSinganResult: ":8007/SinGAN/singan/",
      },
      ocrCheckvalidation: {
        upload: ":5013/OCR/upload/",
        sample: ":5013/OCR/",
        refresh: ":5013/OCR/refresh/",
      },
      legalDocParser: {
        output: ":5008/LexNLP/sample/",
        uploadResult: ":5008/LexNLP/upload/",
      },
      jakInhibitors: {
        sample: ":5081/Classifier1/random_forest/",
      },
      parkinsonDisease: {
        upload: ":5025/Parkinson/parkinsonfileep",
        sample: ":5025/Parkinson/parkinsondemofile/",
        predict: ":5025/Parkinson/parkinson/prediction",
      },
      medicareLocator: {
        getQuery: ":5021/MedicareLocator/",
      },
      hls: {
        output: ":5100/HLSRevenue/hls_revenue/",
      },
      sentimentAnalysis: {
        sample:
          ":5043/MonitoringVisitReport/monitoring_visit_report/sample_data/",
        upload:
          ":5043/MonitoringVisitReport/monitoring_visit_report/data_custom/",
      },
      genomicDataPreprocessor: {
        fastqSample: ":5018/Adam/adam2fastq/sample/",
        adamUpload: ":5018/Adam/actions/upload/",
        fastqUpload: ":5018/Adam/adam2fastq/upload/",
        withParams: ":5018/Adam",
      },
      clinicaleventspatient: {
        result: ":5051/DoctorAI/sampledata/",
        predictData: ":5051/DoctorAI/prediction/",
      },
      textBasedEmotion: {
        output: ":5015/TextEmotionClassification/TextEmotionPrediction/",
      },
      textgeneration: {
        result: ":5016/MedicalTextGenerator/medicalTextGen/",
      },
      pyod: {
        upload: ":6003/FileUpload/upload",
        sampleupload: ":6003/SampleUpload/upload",
      },
      differentialPrivacy: {
        validate: ":8514/ValidateData/validate",
        generateSynthetic: ":8514/DPSyntheticData/generate",
        generateScore: ":8514/GenerateNumericalLRScore/generate",
        generateNonDPModel: ":8512/DPVSNonDPModel/generate",
        generateNonDPModelImg: ":8512/DPModel/generate",
      },
      encryptedTextData: {
        validate: ":8519/ValidateData/validateData",
        encrypt: ":8519/GetEncryptedData/EncryptData",
        predict: ":8519/IndividualPredict/individualPredict",
        DecryptData: ":8519/DecryptData/decryptData",
      },
      bern: {
        sample: ":8517/Bern/plain",
      },
      bioMed: {
        sample: ":3002/Bern/plain",
        getPMCIds: ":3002/bioMedKG_app/BioMedKG",
        getArticle: ":3002/bioMedKG_app/setSingleArticle/",
      },
      imagemodel: {
        upload: ":8509/imagemodel/generate",
        scoreimg: ":8509/imageprivacymeter/generate",
        infer: ":8509/imageinfer/generate",
      },
      toxPrediction: {
        getResult: ":8518/GetToxicity/toxicity",
      },
      nameMatcher: {
        getResult: ":8522/GetNameMatches/nameMatch",
        output: ":8522/GetNameSearches/nameSearch",
        explainability: ":8522/GetExplainability/explainability",
      },
      questionGeneration: {
        upload: ":8601/vLifeQG/QG",
      },

      proteinDeepLearning: {
        getResult: ":8500/TSPDL/proteinSequence",
      },
      aqueoussolubility: {
        upload: ":8516/FindSolubility/solubility",
        getsoln: ":8516/GetSolubility/solubility",
      },
      multimodalSingleCell: {
        sample: ":8002/MultimodalSingleCellIntegration/processing",
      },
      visualQna: {
        sample1: ":8515/Sample/sample1",
        sample2: ":8515/Sample/sample2",
        upload: ":8515/VQA/upload",
      },
      knowledgegraph: {
        queryResult: ":3002/bioMedKG_app/BioMedKG",
        articleResult: ":3002/bioMedKG_app/SemRep/",
      },
      textgenNlp: {
        generateObsrvn: ":8523/TextGen/generate",
      },
      dptext: {
        upload: ":8520/dp_text/upload",
        entities: ":8520/dp_text/generateEntities",
        options: ":8520/dp_text/generateNames",
        canary: ":8520/dp_text/generateCanary",
        insertcanary: ":8520/dp_text/canarydataset",
        trainmodal: ":8520/dp_text/trainmodel",
        modalload: ":8520/dp_text/modelload",
        getoutput: ":8520/dp_text/generatetext",
      },
      annotatedcsv: {
        rawtextoutput: ":8527/sentimentPred/sentiment",
        execute: ":8527/sentimentPred/upload",
        result: ":8527/sentimentPred/datatrend",
      },
      responsibleAIModelExplainability: {
        tabularData: ":8608/TabularExplainability/explainability",
        imageData: ":8608/ImageExplainability/explainability",
        imageSummary: ":8608/ImageExplainability/summary",
      },
      nlpToolkit: {
        output: ":8603/vMedNLP/Entitylist",
        textResult: ":8603/vMedNLP/vmednlp",
      },
      priorAuthorization: {
        output: ":8526/PAAccept/paaccept",
        forecast: ":8526/VolumePredict/volume",
      },
      insuranceFraudDetection: {
        sampleData: ":8605/icfd/sample_data",
        buildModel: ":8605/icfd/build_model",
        extractedFeatures: ":8605/icfd/extracted_features",
        retrainModel: ":8605/icfd/retrain_model",
        sampleInput1: ":8605/icfd/sample_input1",
        sampleInput2: ":8605/icfd/sample_input2",
        gnnGraph: ":8605/icfd/gnn_graph",
      },
    },
  },
  z1dAppsv1: {
    url: APIDATA.Z1DV1,
    solutions: {
      medCode: {
        extractDiseases: "icd/extractDiseases",
        extractICD: "icd/extractICD",
        generateReport: "icd/generateReport",
      },
      contextualSearch: {
        upload: "cbs/cbs_upload",
        search: "cbs/cbs_search",
      },
      imageSim: {
        upload: "imgSim/upload",
      },
      annotatedcsv: {
        rawtextoutput: "sentimentPred/sentiment",
        execute: "sentimentPred/upload",
        result: "sentimentPred/datatrend",
      },
      responsibleAIModelExplainability: {
        tabularData: "modelExp/TabularExplainability/explainability",
        imageData: "modelExp/ImageExplainability/explainability",
        imageSummary: "modelExp/ImageExplainability/summary",
      },
      nlpToolkit: {
        output: "vMedNLP/Entitylist",
        textResult: "vMedNLP/vmednlp",
      },
      priorAuthorization: {
        output: "priorAuth/PAAccept/paaccept",
        forecast: "priorAuth/VolumePredict/volume",
      },
      insuranceFraudDetection: {
        sampleData: "icfd/sample_data",
        buildModel: "icfd/build_model",
        extractedFeatures: "icfd/extracted_features",
        retrainModel: "icfd/retrain_model",
        sampleInput1: "icfd/sample_input1",
        sampleInput2: "icfd/sample_input2",
        gnnGraph: "icfd/gnn_graph",
      },
    },
  },
  mlModels: {
    url: APIDATA.CLIENT_USE_CASE,
    solutions: {
      retrieveRheumatoidArthritisPrediction: {
        output: "rapredict/",
      },
      retrieveHeartDiseasePrediction: {
        output: "hdpredict/",
      },
      retrieveT2DPrediction: {
        output: "t2d-predict/",
      },
      retrieveStrokePrediction: {
        output: "strokepredicted/",
      },
      chronicKidneyDisease: {
        output: "ckdpredict/",
      },
    },
  },
  patientEngagementProduction: {
    url: APIDATA.PATIENT_ENGAGEMENT_VENV,
    solutions: {
      melanoma: {
        output: "melanoma/",
        uploadResult: "uploadimgmelanoma/",
      },
      textSummarizer: {
        output: "",
      },
      predictBioMechanical: {
        output: "predictbio/",
      },
      skinCancerAnalysis: {
        sample: "skincancer/",
        upload: "uploadimg/",
      },
      imagedeIdentification: {
        sample: "image_de_identification_",
        upload: "image_de_identification_user_data/",
      },
      colonPathology: {
        output: "colorectalhistology/",
      },
    },
  },
  portalAPI: {
    url: APIDATA.PORTAL_API,
    solutions: {
      register: {
        getPrimaryUser: "auth/PreSignUpCheck",
        signUp: "auth/signUp",
      },
      login: {
        verifyUser: "auth/verifyUser",
        login: "auth/login",
        updateLoginTime: "auth/updateUserInDB",
        getTokenForPwdReset: "auth/forgotpassword",
        gettkn: 'auth/getToken',
      },
      tokenVerification: {
        resendToken: "auth/resendToken",
        signUpVerify: "auth/signupverifyuser",
        updateUser: "auth/updateUserInDB",
      },
      subscribe: {
        updatePlan: "auth/updateUser",
      },
      forgotPwd: {
        forgotpwd: "auth/forgotpassword",
        verifyUser: "auth/verifyforgotpwdUser",
      },
      resetPwd: {
        resetPwd: "auth/resetpassword",
        resendToken: "auth/forgotpassword",
        updateUser: "auth/updateUserInDB",
      },
      adminDashboard: {
        getUsersList: "auth/getAdminReport",
        getStats: "auth/user/stat",
        forceReset: "auth/forcePasswordReset",
        updateUser: "auth/updateUserAccount",
        createGroup: "auth/createGroup",
        addMemberToGroup: "auth/addMemberToGroup",
        bulkUpdate: "auth/bulkOperation",
        updateGuestPwd: "auth/GuestResetPwd",
      },
      checkout: {
        orders: "orders",
      },
    },
  },
  corona: {
    url: APIDATA.COVID19_APPS,
    solutions: {
      genomeFrequencyAnalysis: {
        sample: "sampleforganalysis",
        upload: "genome_frequency/file",
      },
      geneSimilarityService: {
        sample: "sampleforgenesim",
        upload: "gene_sim/file",
      },
      covid19CountryWise: {
        countryList: "country_list/",
        result: "confirm_cases/",
      },
      covid19Simulation: {
        result: "COVID19/",
      },
    },
  },
  comparetool: {
    url: APIDATA.COMPARE_TOOL,
    solutions: {
      patternAnalyzer: {
        output: "v2.0/compare?",
      },
    },
  },
  ibri: {
    url: APIDATA.IBRI,
    solutions: {
      digitalTwins: {
        getData: "DigitalTwins/diseases/progression/indiana/state/timeline/",
        person_url: "DigitalTwins/diseases/progression/indiana/state/filter/",
        UsState_url: "DigitalTwins/diseases/progression/indiana",
        personname_url: "DigitalTwins/filter/person_name",
      },
      selfService: {
        getData: "selfService/tableListRetrieval/",
        getTablename: "selfService/recordsRetrieval/",
        columSplit: "aas/columnsplit/",
        preProcessed: "aas/preprocessedData/",
      },
    },
  },
  pyod1: {
    url: APIDATA.PYOD1,
    solutions: {
      pyod1: {
        upload: "FileUpload/upload",
        sampleupload: "SampleUpload/upload",
        signedurl: "signedurl/url",
        uploadAuth: "Auth/login"
      },
    },
  },
  pyod2: {
    url: APIDATA.PYOD2,
    solutions: {
      pyod2: {
        getfeature: "Features/getfeatures",
        getfindinlier: "Findinlier/inlier",
      },
    },
  },
  nlpToolkit2: {
    url: APIDATA.NLPTOOLKIT2,
    solutions: {
      nlpToolkit2: {
        fileupload: "FileUpload/upload",
      },
    },
  },
  cancerCellDetection: {
    url: APIDATA.TEST_CCDL,
    solutions: {
      cancerCellDetection: {
        upload: "CCDLImageQuantification/upload",
      },
    },
  },
  medicalReport: {
    url: APIDATA.TEST_MEDICAL_REPORT,
    solutions: {
      medicalReport: {
        upload: "MedicalReportGenerator/upload",
      },
    },
  },
  medtech: {
    url: APIDATA.Z1DV1,
    solutions: {
      medtech: {
        cbssummary: "genai/cbssummary",
        cbsstudy: "genai/cbsstudies",
        cbskey: "genai/cbskeypoints",
        cbs: "genai/cbs",
        fileupload: "genai/upload",
        summarize: "genai/summarize",
        paragraphgen: "genai/paragraphgen",
        riskanalyis: "genai/riskanalysis",
      },
    },
  },
  docSearch: {
    url: APIDATA.Z1DV1,
    solutions: {
      docSearch: {
        docfileupload: "KnowledgeSearch/upload",
        docsearch: "KnowledgeSearch/search",
        userPrevFile: "KnowledgeSearch/user/file",
        fileUpdate: "KnowledgeSearch/user/fileUpdate",
        addUserFile: "KnowledgeSearch/user/fileAdd",
        deleteFile: "KnowledgeSearch/user/fileDelete",
        knowledgeBaseUpdate: "KnowledgeSearch/user/kbupdate",
      },
    },
  },
  docSearchTest: {
    url: "http://10.104.44.96:8009",
    solutions: {
      docSearch: {
        docsearch: "/KnowledgeSearch/search",
      },
    },
  },
  priorAuth: {
    url: APIDATA.Z1DV1,
    solutions: {
      priorAuth: {
        priorauth: "PriorAuthPOC/priorauth",
      },
    },
  },
  baSdlcReq: {
    url: APIDATA.SDLC,
    solutions: {
      baSdlcReq: {
        readAll: "/application/readAll",
        createAppMgmt: "/application/create",
        readOne: "/application/readOne",
        updateAppMgmt: "/application/update",
        funcReqReadAll: "/usecase/readAll",
        funcReqCreate: "/usecase/create",
        funcReqGenerate: "/generate/functionRequirement",
        funcReqUsecaseCreate: "/requirement/create",
        funcReqUsecaseReadAll: "/requirement/readAll",
        generateTestCase: "/generate/testcase",
        addGenerateTestcase: "/testcase/create",
        generateCode: "/code/generate",
        codeDeploy: "/code/deploy",
      },
    },
  },
  llm: {
    url: APIDATA.Z1DV1,
    solutions: {
      llm: {
        LLMMetricsllmmetrics: 'LLMMetrics/llm/metrics',
      },
    },
  },
  nba: {
    url: APIDATA.Z1DV1,
    solutions: {
      nba: {
        NBATerritory: 'NBA/territory',
        NBAsalesrep: 'NBA/salesrep',
        NBAhcp: 'NBA/hcp',
        NBAproducts: 'NBA/products',
        NBAsuggest: 'NBA/suggest'
      },
    },
  },

};
export default APIList;