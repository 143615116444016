import React, { memo, useState } from "react";
import styled from "styled-components";
import { TextField, Grid, MenuItem } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components
import Collapse from "../../common/Collapse";
import CommonService from "../../../utils/services/CommonService";
import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
    Paragraph,
    StyledButton,
    DemoContainer,
    LinkContainer,
    Result,
    List,
    ListItem
} from "../../../styles/common";
import getUserData, { getUploadToken } from '../../../utils';

const TextGeneration = () => {
    const initialData = {
        text: "",
        words: "",
        rows: "",
    };

    const [sampleData, setSampleData] = useState(initialData);
    const [result, SetResultData] = useState(false);
    const [showSpinner, setSpinner] = useState(false);
    const [notification, setNotification] = useState("");

    const { getQueryResult } = CommonService(
        "z1dApps", "textgeneration",
    );
    const Token = getUploadToken()

    const words = [
        { name: "50", value: 50 },
        { name: "100", value: 100 },
        { name: "200", value: 200 },
        { name: "300", value: 300 },
    ];

    const rows = [
        { name: "1", value: 1 },
        { name: "2", value: 2 },
        { name: "3", value: 3 },
        { name: "4", value: 4 },
    ];

    const handleInputs = (e) => {
        const { name } = e.target;
        const { value } = e.target;
        setSampleData({
            ...sampleData,
            [name]: value,
        });
    };

    const showSample1 = () => {
        SetResultData('');
        const NewDataSet = {
            text: "heart attack",
            words: "100",
            rows: "3",
        };

        setSampleData(NewDataSet);
    };

    const showSample2 = () => {
        SetResultData('');
        const NewDataSet = {
            text: "blood clotting",
            words: "200",
            rows: "4",
        };

        setSampleData(NewDataSet);
    };

    const getResult = () => {
        const {
            text,
            words,
            rows
        } = sampleData;

        const params = `${text},${words},${rows},${Token.token}`;

        let areValuesEmpty = false;
        Object.keys(sampleData).forEach((key) => {
            if (sampleData[key] === "") {
                areValuesEmpty = true;
            }
        });
        if (areValuesEmpty) {
            setNotification({ open: "error", message: "Fields are empty" });
        } else {
            SetResultData('');
            setSpinner(true);

            getQueryResult("result", encodeURIComponent(params))
                .then((res) => {
                    setSpinner(false);
                    SetResultData(res);
                })
                .catch((err) => {
                    console.log(err);
                    setSpinner(false);
                    setNotification({ open: "error", message: err.message });
                });
        }
    };

    return (
        <SolutionContainer snackbar={notification}>
            <Collapse text="Description">
                <Paragraph>
                    <p>
                    GPT-2 is a transformer model pretrained on a very large corpus of English data in a self-supervised fashion. This means it was pretrained on raw text only, with no humans labelling them in any way (which is why it can use lots of publicly available data) with an automatic process to generate inputs and labels from those texts. More precisely, it was trained to guess the next word in sentences. In addition to this, the inputs are sequences of continuous text of a certain length and the targets are of the same sequence, shifted one token (word or piece of word) to the right. Internally, the model uses a mask-mechanism to make sure the predictions for the token ‘i’ only uses the inputs from 1 to ‘i’ , but not the future tokens.
                    </p>
                    <p>
                    This way, the model learns an inner representation of the English language that can then be used to extract features useful for downstream tasks. However, the model is best at what it was pretrained for, which is generating text from a prompt.
                    </p>
                    <p>
                    This Med-info Guide has been built using the GPT2 transformer model, which is able to generate medical text. The model has been trained on custom medical dataset to get a meaningful explanation of medical terms. The input of this application can be any word or a sentence. The application would then return a set of generated sentences related to the given input.
                    </p>
                    <p>
                        <strong>Input : </strong>
                        This application can take a sentence or a few words (Medical terms) as an input, the number of words required in each output paragraph, and number of paragraphs in output.
                    </p>
                    <p>
                        <strong> Output : </strong>
                        The application will return a set of generated paragraphs related to the given input.
                    </p>
                </Paragraph>
                <LinkContainer>
                    <Grid container spacing={2}>
                        <Grid item>
                            <StyledButton
                                variant="outlined"
                                color="primary"
                                size="large"
                                startIcon={<OpenInNewIcon />}
                            >
                                <a
                                    href="https://material.vlifevirtusa.com/text_generation/text-generation.html"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Notebook
                                </a>
                            </StyledButton>
                        </Grid>
                    </Grid>
                </LinkContainer>
            </Collapse>
            <Collapse text="Demo">
                <DemoContainer>
                    <Grid container direction="row" justify="center" spacing={2}>
                        <Grid item xs={12} sm={8} md={4}>
                            <TextField
                                variant="outlined"
                                name="text"
                                fullWidth
                                onChange={handleInputs}
                                label="Enter Text "
                                value={sampleData.text}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                variant="outlined"
                                select
                                fullWidth
                                name="words"
                                label="Enter no of Words"
                                value={sampleData.words}
                                onChange={handleInputs}
                            >
                                {words.map((data) => (
                                    <MenuItem value={data.value}>{data.name}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                variant="outlined"
                                select
                                fullWidth
                                name="rows"
                                label="Enter no of Rows"
                                value={sampleData.rows}
                                onChange={handleInputs}
                            >
                                {rows.map((data) => (
                                    <MenuItem value={data.value}>{data.name}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <StyledButton
                                variant="contained"
                                color="primary"
                                btnColor="#023e8a"
                                data-sample="sample1"
                                onClick={showSample1}
                            >
                                Sample 1
                            </StyledButton>

                            <StyledButton
                                variant="contained"
                                color="primary"
                                btnColor="#023e8a"
                                data-sample="sample2"
                                onClick={showSample2}
                            >
                                Sample 2
                            </StyledButton>

                            <StyledButton
                                variant="contained"
                                btnColor="#023e8a"
                                color="primary"
                                onClick={getResult}
                                endIcon={<ArrowForwardIcon />}
                            >
                                Execute
                            </StyledButton>
                        </Grid>
                    </Grid>
                    <br />
                    {showSpinner && <Spinner text="Loading..." />}
                    {result && (
                        <section>
                            <Result>
                                <strong>Result:</strong>
                            </Result>
                            <Paragraph>
                                <List>
                                    {result?.map((results) => (
                                        <ListItem>
                                            {results.generated_text}
                                        </ListItem>
                                    ))}
                                </List>
                            </Paragraph>
                        </section>
                    )}
                </DemoContainer>
            </Collapse>

        </SolutionContainer>
    );
};

export default memo(TextGeneration);
