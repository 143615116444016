import React, { memo, useState } from "react";
import styled from "styled-components";
import {
  Grid,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Grow from "@material-ui/core/Grow";
import Highcharts from "highcharts";
import Input from "@material-ui/core/Input";

// import local components
import CommonService from "../../../utils/services/CommonService";

import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";
import SolutionContainer from "../../common/SolutionContainerWrapper";

// images
import gastrointestinal_cancer_sample1 from "../../../assets/images/app/msscancer.webp";

const { getQueryResult, uploadFile } = CommonService("bioInformatics", "gastrointestinalCancer");

const GastrointestinalCancer = () => {
  const [showSpinner, setSpinner] = useState(false);
  const [result, setResult] = useState("");
  const [uploadfile, setUploadfile] = useState("");
  const [notification, setNotification] = useState("");

  const getsampleResult = () => {
    setSpinner(true);
    setResult("");
    getQueryResult("sample")
      .then((res) => {
        setSpinner(false);
        setResult(res[0]);
        formHighChart(JSON.parse(res[1]));
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const formHighChart = (datasplit) => {
    let i = 0;
    const dataset = [];
    for (i = 0; i < Object.keys(datasplit.Class).length; i++) {
      dataset.push([datasplit.Class[i], datasplit.Probability[i]]);
    }
    console.log(dataset);
    Highcharts.chart("container", {
      chart: {
        type: "column",
      },
      title: {
        text: "Probability Distribution for Predicted Classes",
      },
      subtitle: {
        text: "Hover to Show Class Probability",
      },
      xAxis: {
        type: "category",
      },
      yAxis: {
        title: {
          text: "Probability",
        },
      },

      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: "{point.y:.1f}%",
          },
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b><br/>',
      },
      series: [
        {
          name: "Percentage",
          colorByPoint: true,
          data: dataset,
        },
      ],
    });
  };

  const getUploadResult = () => {
    setSpinner(true);
    setResult("");
    uploadFile("upload", uploadfile)
      .then((res) => {
        setSpinner(false);
        setResult(res[0]);
        formHighChart(JSON.parse(res[1]));
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const onChangeFile = (e) => {
    const file = e.currentTarget.files[0];
    setUploadfile(file);
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>Gastrointestinal cancer refers to malignant conditions of the gastrointestinal tract (GI tract) and accessory organs of digestion, including the esophagus, stomach, biliary system, pancreas, small intestine, large intestine, rectum and anus. The symptoms relate to the organ affected and can include obstruction (leading to difficulty swallowing or defecating), abnormal bleeding or other associated problems. The diagnosis often requires endoscopy, followed by biopsy of suspicious tissue. The treatment depends on the location of the tumor, as well as the type of cancer cell and whether it has invaded other tissues or spread elsewhere. These factors also determine the prognosis.</p>
          <p>All Gastrointestinal cancers must know their MSS or MSI biomarker status. That is where you have the inherited kind (of the disease), but it doesn’t necessarily have to come from your parents, you can acquire this (biomarker). Microsatellite instability (MSI) is the term given to deletions or insertions of short repeat nucleotides sequence in the genome. MSS stands for “Microsatellite Stable.” Approximately 80-85% of colorectal cancer patients are not MSI-H/MMR deficient and are instead classified as MSS. MSS tumors have been referred to as “cold” tumors. In terms of the number of tumor genetic mutations, they are one of the most highly mutated tumor types.</p>

          <LinkContainer>
            <Grid container spacing={2}>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://www.kaggle.com/datasets/linjustin/train-val-test-tcga-coad-msi-mss"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/msi_prediction/msi_mss_classification.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <br />
        <DemoContainer>
          <Grid container xs={12} spacing={1} direction="row" justify="center" alignItems="center">
            <Grid item xs={10} sm={5} md={2}>
              <Image src={gastrointestinal_cancer_sample1} />
              <br />
              <StyledButton
                variant="contained"
                color="primary"
                data-sample="sample_image1"
                onClick={getsampleResult}
              >
                Sample
              </StyledButton>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <h3>OR</h3>
            </Grid>
            <Grid item xs={10} sm={5} md={3}>
              <Input
                type="file"
                color="primary"
                name="uploadedFile"
                onChange={onChangeFile}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <StyledButton
                style={{ marginTop: "0" }}
                variant="contained"
                color="primary"
                onClick={getUploadResult}
              >
                Execute
              </StyledButton>
            </Grid>

          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
            <Grid container XS={12}>
              <Grid item xs={12} md={12}>
                <Grow in={result} timeout={1500}>
                  <div>
                    <br />
                    <section>
                      <Result>
                        <strong>Result : </strong>
                        {result}
                      </Result>
                      <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={12} sm={12} md={6}>
                          <div id="container" />
                        </Grid>
                      </Grid>
                    </section>
                  </div>
                </Grow>
              </Grid>
            </Grid>
          )}
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(GastrointestinalCancer);

const Image = styled.img`
  width: 100%;
  height: 150px;
  @media only screen and (max-width: 900px) {
    width: auto;
    max-width: 200px;
  }  @media only screen and (max-width: 450px) {
    width: auto;
    max-width: 200px;
  }
`;
