import React, { memo, useState } from "react";
import { TextField, Grid } from "@material-ui/core";

import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Highcharts from "highcharts";
import highChartcloud from 'highcharts/modules/wordcloud';

// local components

import Collapse from "../../common/Collapse";
import CommonService from "../../../utils/services/CommonService";
import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  HighchartContainer,
} from "../../../styles/common";
import getUserData, { getUploadToken } from '../../../utils';
highChartcloud(Highcharts);

const RetweetCountPredictor = () => {
  const initialData = {
    retweet: "",
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [result, SetResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "z1dApps", "retweetcount",
  );
  const Token = getUploadToken()

  const formHighChart = (text) => {
    const lines = text.split(/[,\. ]+/g);
    const data = Highcharts.reduce(lines, (arr, word) => {
      let obj = Highcharts.find(arr, (obj) => obj.name === word);
      if (obj) {
        obj.weight += 1;
      } else {
        obj = {
          name: word,
          weight: 1,
        };
        arr.push(obj);
      }
      return arr;
    }, []);

    Highcharts.chart('container', {
      accessibility: {
        screenReaderSection: {
          beforeChartFormat: '<h5>{chartTitle}</h5>'
            + '<div>{chartSubtitle}</div>'
            + '<div>{chartLongdesc}</div>'
            + '<div>{viewTableButton}</div>',
        },
      },
      series: [{
        type: 'wordcloud',
        data,
        name: 'Occurrences',
      }],
      title: {
        text: 'The below image represent a word cloud of prominent words that contributed to retweeting the original tweet',
      },
    });
  };

  const showSample1 = () => {
    SetResultData('');
    const NewDataSet = {
      retweet: "However, the #coronavirus is something we’re trying to get ahead of, due to the many lives that have already been impacted. Let’s be thankful our government is doing the best they can to help us get ahead of this disease. Prayers to all of those affected. Stay safe. #COVID19",
    };

    setSampleData(NewDataSet);
  };

  const showSample2 = () => {
    SetResultData('');
    const NewDataSet = {
      retweet: "We've entered the punishment stage\nIt follows a sinister age\nOur criminal greed\nNow finds us in need\nOf a #COVID19 macrophage",
    };

    setSampleData(NewDataSet);
  };

  const getResult = () => {
    const {
      retweet,
    } = sampleData;

    const params = `${retweet},${Token.token}`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });
    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      SetResultData('');
      setSpinner(true);

      getQueryResult("output", encodeURIComponent(params))
        .then((res) => {
          setSpinner(false);
          SetResultData(res.isretweet);
          formHighChart(res.wordcloud);
        })
        .catch((err) => {
          setSpinner(false);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const handleInputs = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Social media is one of the fastest ways to spread information among vast audience. Be it a positive message or a fake information. Twitter has led the way in reaching global corners. In this application we identify the predicting power of a tweet that consists of words/phrases around coronavirus.
            <br />
            <br />
            By identifying the number of a times a tweet might be re-tweeted an estimation can be made how far the information can travel and how quickly. The dataset contains variables associated with Twitter: the text of various tweets and the accounts that tweeted them, the hashtags used and the locations of the accounts.
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.kaggle.com/smid80/coronavirus-covid19-tweets"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Dataset
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container direction="row" justify="center" spacing={2}>
            <Grid item xs={12}>
              <br />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                rows="6"
                multiline
                variant="outlined"
                name="retweet"
                fullWidth
                onChange={handleInputs}
                label="Enter Text To Retweet Count:"
                value={sampleData.retweet}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="primary"
                btnColor="#023e8a"
                data-sample="sample1"
                onClick={showSample1}
              >
                Sample 1
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                btnColor="#023e8a"
                data-sample="sample2"
                onClick={showSample2}
              >
                Sample 2
              </StyledButton>

              <StyledButton
                variant="contained"
                btnColor="#023e8a"
                color="primary"
                onClick={getResult}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          <br />
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
          <section>
            <Result>
              <strong>Result:</strong>
              {`${result}`}
            </Result>
            <center>
              <Grid item xs={12} sm={12} md={9}>
                <HighchartContainer
                  id="container"
                  display={result}
                />
              </Grid>
            </center>
          </section>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(RetweetCountPredictor);
