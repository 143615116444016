import APIDATA from "../../../utils/services/api-config";

const z1d = APIDATA.Z1D;
// const url = process.env["REACT_APP_API_URL"];
const url = APIDATA.PORTAL_API;
const cohortSenseURL = `${z1d}:5001`;

const MatrixService = {

  retrieveMatrixAttributes() {
    const getRequestConfig = {
      method: "GET",
      cache: "no-cache",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // "Authorization": window.sessionStorage.getItem('token')
      },
    };

    return WSClientWrapper.wrappedFetch(`${url}matrix/attributes`, getRequestConfig);
  },

  retrieveMatrixAttributeValues(columns) {
    const postRequestConfig = {
      method: "POST",
      cache: "no-cache",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        columns,
      }),
    };

    return WSClientWrapper.wrappedFetch(`${url}matrix/attribute-values`, postRequestConfig);
  },

  initiateDataPreparation(params) {
    const getRequestConfig = {
      method: "GET",
      cache: "no-cache",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // "Authorization": window.sessionStorage.getItem('token')
      },
    };

    return WSClientWrapper.wrappedFetch(`${cohortSenseURL}/datapreparation/${params}`, getRequestConfig);
  },
};

export default MatrixService;
