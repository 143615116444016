import React, { memo, useState } from "react";
import { Grid, TextField, MenuItem } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Highcharts from "highcharts";

// local components
import Collapse from "../../common/Collapse";

import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  HighchartContainer,
} from "../../../styles/common";
import getUserData, { getUploadToken } from '../../../utils';

const HealthCareCostEstimation = () => {
  const initialData = {
    age: "",
    stayLength: "",
    admission_location: "",
    admission_type: "",
    ethnicity: "",
    insurance: "",
    marital: "",
    religion: "",
    gender: "",
    bypass_3: "",
    mam_cor: "",
    extracorporeal: "",
    left_heart: "",
    angiocardiogram: "",
    bypas_2: "",
    card_cath: "",
    arterogr_2: "",
    cell_transfusion: "",
    venous: "",
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [result, SetResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService("payerProvider", "costEstimate");
  const Token = getUploadToken();

  const admission_location = [{ name: 'Transfer From Hosp/Extram', value: '0' }, { name: 'Clinic Refferal/Premature', value: '1' }, { name: 'Emergency Room Admit', value: '2' }, { name: 'Transfer From Other Health', value: '3' }, { name: 'PHYS Referral/Normal DELI', value: '4' }];
  const admission_type = [{ name: 'Emergency', value: '0' }, { name: 'Urgent', value: '1' }, { name: 'Elective', value: '2' }];
  const ethnicity = [{ name: 'Hispanic', value: '0' }, { name: 'Others', value: '1' }, { name: 'Black', value: '2' }, { name: 'White', value: '3' }];
  const insurance = [{ name: 'Private', value: '0' }, { name: 'Self Pay', value: '1' }, { name: 'Government', value: '2' }, { name: 'Medicaid', value: '3' }, { name: 'Medicare', value: '4' }];
  const marital = [{ name: 'Single', value: '0' }, { name: 'Divorced', value: '1' }, { name: 'Widowed', value: '2' }, { name: 'Separated', value: '3' }, { name: 'Unknown', value: '4' }, { name: 'Married', value: '5' }];
  const religion = [{ name: 'Protestant Quaker', value: '0' }, { name: 'Jewish', value: '1' }, { name: 'Not Specified', value: '2' }, { name: 'Other', value: '3' }, { name: 'Catholic', value: '4' }];
  const gender = [{ name: 'Female', value: '0' }, { name: 'Male', value: '1' }];
  const common = [{ name: 'No', value: '0' }, { name: 'Yes', value: '1' }];

  const formHighChart = (data) => {
    Highcharts.chart('container', {
      chart: {
        type: 'column',
      },
      title: {
        text: 'Cost Distribution',
      },
      subtitle: {
        text: '',
      },
      xAxis: {
        categories: [""],
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Amount',
        },

      },
      tooltip:
            {
              valueSuffix: '',
              headerFormat: '',
            },
      plotOptions: {
        column: {
          pointPadding: 0.3,
          borderWidth: 0,
        },
      },
      series: [{
        name: "Total Cost",
        data: data[1][0],
      }, {
        name: "Paid by Insurance",
        data: data[1][1],
      }, {
        name: "Paid by Claimant",
        data: data[1][2],
      }],
    });
  };

  const showSample1 = () => {
    const NewDataSet = {
      age: "32",
      stayLength: "10",
      admission_location: "4",
      admission_type: "2",
      ethnicity: "3",
      insurance: "4",
      marital: "5",
      religion: "4",
      gender: "1",
      bypass_3: "0",
      mam_cor: "0",
      extracorporeal: "0",
      left_heart: "0",
      angiocardiogram: "0",
      bypas_2: "0",
      card_cath: "0",
      arterogr_2: "0",
      cell_transfusion: "0",
      venous: "0",
    };

    setSampleData(NewDataSet);
  };

  const getResult = () => {
    const {
      age,
      stayLength,
      admission_location,
      admission_type,
      ethnicity,
      insurance,
      marital,
      religion,
      gender,
      bypass_3,
      mam_cor,
      extracorporeal,
      left_heart,
      angiocardiogram,
      bypas_2,
      card_cath,
      arterogr_2,
      cell_transfusion,
      venous,
    } = sampleData;

    const params = `${age},${stayLength},${admission_location},${admission_type},${ethnicity},${insurance},${marital},
        ${religion},${gender},${bypass_3},${mam_cor},${extracorporeal},${left_heart},${angiocardiogram},${bypas_2},
        ${card_cath},${arterogr_2},${cell_transfusion},${venous},${Token.token}`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });
    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("output", params)
        .then((res) => {
          console.log(res);
          setSpinner(false);
          SetResultData(res[0]);
          formHighChart(res);
        })
        .catch((err) => {
          console.log(err);
          setSpinner(false);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const handleInputs = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>According to one of the Consumer sentiment surveys conducted in the United States, around 9% of the consumers understand the basic health insurance terms. From the same survey, it came to light that some people overestimate the cost of common medical tests, many underestimate the cost of surgical procedures.</p>
          <p>
            Now the question arises:
            <strong><i>“How can one calculate the estimated total cost and the out-of-pocket expense for the medical assistance?”</i></strong>
          </p>
          <p>This application caters to that very concern of the users. An input regarding the type of medical procedure/test is taken, and an estimate of the total expense for that procedure/test along with the claim amount and out-of-pocket expense is predicted with the help of a trained Multilayer Perceptron (MLP) model. This can aid patients or users to plan their medical procedures/tests accordingly</p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Insurance_claims_prediction"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                 Data Source
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Insurance_claims_prediction"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container direction="row" justify="center" spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="Age*"
                name="age"
                fullWidth
                value={sampleData.age}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                fullWidth
                select
                name="gender"
                label="Gender"
                value={sampleData.gender}
                onChange={handleInputs}
              >
                {gender.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                fullWidth
                name="stayLength"
                label="Expected Length of Stay"
                value={sampleData.stayLength}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="admission_location"
                label="Admission Location*"
                value={sampleData.admission_location}
                onChange={handleInputs}
              >
                {admission_location.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="admission_type"
                label="Admission Type*"
                value={sampleData.admission_type}
                onChange={handleInputs}
              >
                {admission_type.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="ethnicity"
                label="Ethnicity"
                value={sampleData.ethnicity}
                onChange={handleInputs}
              >
                {ethnicity.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="insurance"
                label="Insurance"
                value={sampleData.insurance}
                onChange={handleInputs}
              >
                {insurance.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="marital"
                label="Marital Status"
                value={sampleData.marital}
                onChange={handleInputs}
              >
                {marital.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="religion"
                label="Religion"
                value={sampleData.religion}
                onChange={handleInputs}
              >
                {religion.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="venous"
                label="Venous cath NEC*"
                value={sampleData.venous}
                onChange={handleInputs}
              >
                {common.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="cell_transfusion"
                label="Packed cell transfusion*"
                value={sampleData.cell_transfusion}
                onChange={handleInputs}
              >
                {common.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="bypass_3"
                label="Aortocor bypas-3 cor art*"
                value={sampleData.bypass_3}
                onChange={handleInputs}
              >
                {common.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="1 int mam-cor art bypass*"
                label="mam_cor"
                value={sampleData.mam_cor}
                onChange={handleInputs}
              >
                {common.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="extracorporeal"
                label="Extracorporeal circulat*"
                value={sampleData.extracorporeal}
                onChange={handleInputs}
              >
                {common.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="Left heart cardiac cath*"
                label="left_heart"
                value={sampleData.left_heart}
                onChange={handleInputs}
              >
                {common.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="Lt heart angiocardiogram*"
                label="angiocardiogram"
                value={sampleData.angiocardiogram}
                onChange={handleInputs}
              >
                {common.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="bypas_2"
                label="Aortocor bypas-2 cor art*"
                value={sampleData.bypas_2}
                onChange={handleInputs}
              >
                {common.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="card_cath"
                label="Rt/left heart card cath*"
                value={sampleData.card_cath}
                onChange={handleInputs}
              >
                {common.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="arterogr_2"
                label="Coronar arteriogr-2 cath* "
                value={sampleData.arterogr_2}
                onChange={handleInputs}
              >
                {common.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <br />
            <br />
            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={showSample1}
              >
                Sample Data
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={getResult}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          {' '}
          <br />
          <br />
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
          <Result>
            <strong>Result:</strong>
            {`${result[0]}`}
          </Result>
          )}
          <HighchartContainer
            id="container"
            display={result}
          />
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(HealthCareCostEstimation);
