import React, { memo, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  TextField,
  Button,
  FormControl,
  Typography,
  AppBar,
  Tab,
  Card,
  CardActionArea,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Highcharts from 'highcharts';
import styled from 'styled-components';

// import local components

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Collapse from '../../common/Collapse';
import {
  LinkContainer,
  Paragraph,
  StyledButton,
  Result,
  SolutionContainer,
  HighchartContainer,
  Heading4,
} from '../../../styles/common';
import Spinner from '../../common/loading/Spinner';
import CommonService from '../../../utils/services/CommonService';
import { HeaderCell } from '../../common/JsonDataTable';
import { StyledTabs } from './IOTRecipesView';

// images
import jakInhibitors_sol1 from '../../../assets/images/app/jakInhibitors_sol1.webp';
import jakInhibitors_sol2 from '../../../assets/images/app/jakInhibitors_sol2.webp';
import jakInhibitors_sample1 from '../../../assets/images/app/jakInhibitors_sample1.webp';
import jakInhibitors_sample2 from '../../../assets/images/app/jakInhibitors_sample2.webp';
import sentimentAnalysis from '../../../assets/images/app/sentimentAnalysis.webp';
import wordCloud from '../../../assets/images/app/wordCloud.webp';
import jak3 from '../../../assets/images/app/jak3.webp';
import jak4 from '../../../assets/images/app/jak4.webp';
import { getUploadToken } from '../../../utils';

const useStyles = makeStyles({
  root: {
    height: '100%',
    textAlign: 'left',
    backgroundColor: '#0080FF',
    color: 'white',
  },
  link: {
    textDecoration: 'none',
    color: 'white',
  },
  textCenter: {
    textAlign: 'center',
  },
});

const JakInhibitors = () => {
  const [value, setValue] = useState(0);
  const [sentimentalAnalysisImage, setSentimentalAnalysisImage] =
    useState(null);
  const [isLoading, setLoading] = useState(false);
  const { getQueryResult } = CommonService('z1dApps', 'jakInhibitors');
  const Token = getUploadToken()
  
  const [fields, setPredictionFields] = useState({
    hcp_group: '',
    hcp_code: '',
    prescription_count: '',
    hcp_city: '',
    referral_status: '',
    drug_prescription: '',
    hcp_state: '',
    frequency: '',
    hcp_type: '',
  });
  const [predictionResult, setpredictionResult] = useState(null);
  const data = [
    [
      'Frequency of Speciality Group of HCPs Prescribing Dupixent',
      'Source – Dupixent AD and JAK inhibitor Sample',
      'https://public.tableau.com/profile/gaurav1588#!/vizhome/FrequencyofSpecialityGroupofHCPsPrescribingDupixent/Sheet1',
    ],
    [
      'Speciality Group Distribution among Attitudinal Segments',
      'Source – Dupixent AD and Status Data',
      'https://public.tableau.com/profile/akhil3783#!/vizhome/Statuscombined/Sheet7',
    ],
    [
      'AD HCP Distribution Pie',
      'Source – Dupixent AD Sample',
      'https://public.tableau.com/profile/gaurav1588#!/vizhome/ADHCPDistributionPie/Dashboard1',
    ],
    [
      'Prescriber Speciality group',
      'Source – Claims data',
      'https://public.tableau.com/profile/gaurav1588#!/vizhome/Claimsdataviz/Dashboard3?publish=yes',
    ],
    [
      'Geographical Distribution of HCPs that prescribe both JAK Inhibitors and Dupixent for AD',
      'Source – Dupixent AD and JAK inhibitor Sample',
      'https://public.tableau.com/profile/gaurav1588#!/vizhome/HCPprescribingbothDupixentandJAKinhibitor/Sheet3',
    ],
  ];
  const data1 = [
    [
      'Product Form, Strength and Prescription Count',
      'Source – Claims data',
      'https://public.tableau.com/profile/gaurav1588#!/vizhome/Claimsdataviz/Productstrengthandprescription',
    ],
    [
      'Form of Product Administration for Various Indications',
      'Source – Claims data',
      'https://public.tableau.com/profile/gaurav1588#!/vizhome/Claims_productForm/Productform',
    ],
    [
      'Month, Quarter, and Year-Wise Count of Prescriptions (Nbrx) ',
      'Source – Dupixent AD Sample',
      'https://public.tableau.com/profile/gaurav1588#!/vizhome/PrescriptionDashboard/Dashboard2',
    ],
    [
      'Reasons for Discontinuation of Dupixent ',
      'Source – Dupixent AD and Status Data',
      'https://public.tableau.com/profile/akhil3783#!/vizhome/Statuscombined/Sheet5?publish=yes',
    ],
    [
      'Authorized Refills Distribution ',
      'Source – Dupixent AD and Status Data',
      'https://public.tableau.com/profile/akhil3783#!/vizhome/Statuscombined/Sheet4',
    ],
  ];
  const data2 = [
    [
      'Distribution of patient age and cases for Dupixent',
      'Source – Claims data',
      'https://public.tableau.com/profile/gaurav1588#!/vizhome/Claimsdataviz/Geographicaldistribution',
    ],
    [
      'Current Referral Status of Dupixent ',
      'Source – Status data',
      'https://public.tableau.com/profile/akhil3783#!/vizhome/Statuscombined/Sheet1',
    ],
    [
      'Periodic Therapy Status of Patient Consuming Xeljanz',
      'Source – Therapy Data',
      'https://public.tableau.com/profile/akhil3783#!/vizhome/therapy_combined/Sheet3',
    ],
    [
      'Periodic Therapy Status of Patient Consuming Dupixent',
      'Source – Therapy Data',
      'https://public.tableau.com/profile/akhil3783#!/vizhome/therapy_combined/Sheet4',
    ],
    [
      'Gender and age distribution among patients',
      'Source – Status data',
      'https://public.tableau.com/profile/ashwathy.menon2465#!/vizhome/PatientDistributionBasedonAgeandGender/PatientDistributionBasedonAgeandGender',
    ],
  ];
  const sampleData = {
    sample1: ['DERM', 'WARREN', 'MI', 'D', 'DISCONTINUED', 172, 489, 0, 1],
    sample2: ['DERM', 'TROY', 'MI', 'D', 'PENDING', 331, 223, 0, 1],
    sample3: ['DERM', 'ESSEX', 'CT', 'D', 'ACTIVE', 101, 26, 0, 1],
  };
  const severity = {
    TRAILBLAZER: 'high',
    TRADITIONALIST: 'low',
    SIMPLIFIER: 'moderate',
  };
  const baseModel = {
    accuracy: 0.88,
    model_recall: 0.92,
    model_precision: 0.87,
    roc_auc_score_model: 0.88,
  };
  const shapleyModel = {
    accuracy: 0.94,
    model_recall: 0.95,
    model_precision: 0.95,
    roc_auc_score_model: 0.94,
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const setSample = (e) => {
    const sample = e.currentTarget.id;
    const data = sampleData[sample];
    setPredictionFields({
      hcp_group: data[0],
      hcp_city: data[1],
      hcp_state: data[2],
      hcp_code: data[3],
      referral_status: data[4],
      frequency: data[5],
      prescription_count: data[6],
      drug_prescription: data[7],
      hcp_type: data[8],
    });
  };
  const onChangeFields = (e) => {
    setPredictionFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };

  const getSampleResult = () => {
    const {
      hcp_group,
      hcp_city,
      hcp_state,
      hcp_code,
      referral_status,
      frequency,
      prescription_count,
      drug_prescription,
      hcp_type,
    } = fields;
    const params = `${hcp_group},${hcp_city},${hcp_state},${hcp_code},${referral_status},${frequency},${prescription_count},${drug_prescription},${hcp_type},${Token.token} `;
    setLoading(true);
    setPredictionFields({});
    getQueryResult('sample', params)
      .then((res) => {
        setpredictionResult({
          response: res.result,
          showChart: false,
          severity: severity[res.result.toUpperCase()],
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const showChart = () => {
    setpredictionResult({
      ...predictionResult,
      showChart: true,
    });
  };

  const formHighChart = (data, div, title) => {
    Highcharts.chart(div, {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      title: {
        text: `<b>${title}</b>`,
      },
      tooltip: {
        pointFormat: '{series.name}: <h4>{point.percentage:.1f}%</h4>',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<h4>{point.name}</h4>: {point.percentage:.1f} %',
          },
        },
      },
      series: [
        {
          name: 'Values',
          colorByPoint: true,
          data,
        },
      ],
    });
  };
  const formTable = (state, header) =>
    state && (
      <div>
        <p>{header}</p>
        <Table>
          <TableHead>
            <TableRow>
              <HeaderCell>Metric</HeaderCell>
              <HeaderCell>Value</HeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key="0">
              <TableCell align="left">Accuracy</TableCell>
              <TableCell align="left">{state.accuracy}</TableCell>
            </TableRow>
            <TableRow key="1">
              <TableCell align="left">AUC</TableCell>
              <TableCell align="left">{state.roc_auc_score_model}</TableCell>
            </TableRow>
            <TableRow key="2">
              <TableCell align="left">Precision</TableCell>
              <TableCell align="left">{state.model_precision}</TableCell>
            </TableRow>
            <TableRow key="3">
              <TableCell align="left">Recall</TableCell>
              <TableCell align="left">{state.model_recall}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );

  useEffect(() => {
    const chartData = [
      {
        name: 'Positive',
        y: 72.6,
        color: '#bdef9e',
      },
      {
        name: 'Negative',
        y: 27.4,
        color: '#ef6e6e',
      },
    ];
    predictionResult?.showChart &&
      formHighChart(chartData, 'container_1', 'Shapley Value Split');
  }, [predictionResult?.showChart]);

  useEffect(() => {
    const pieChart = [
      {
        name: 'dupixent_pres_count',
        y: 0.29513,
      },
      {
        name: 'Ims_freq',
        y: 0.260579,
      },
      {
        name: 'ims_prescriber_city',
        y: 0.208914,
      },
      {
        name: 'ims_prescriber_state',
        y: 0.152332,
      },
      {
        name: 'ims_prescriber_specialty_code ',
        y: 0.0317762,
      },
      {
        name: 'comp_drug_pres_flag',
        y: 0.0202066,
      },
      {
        name: 'current_referral_status',
        y: 0.0163346,
      },
      {
        name: 'Specgrp (M5Phyterr Full) ',
        y: 0.0146741,
      },
      {
        name: 'Entity_Type  es_flag',
        y: 5.3841e-5,
      },
    ];
    value === 1 && formHighChart(pieChart, 'container', 'Feature Importance');
  }, [value]);

  return (
    <SolutionContainer>
      <section className="App">
        <div>
          <section>
            <center>
              <img width="100%" src={jakInhibitors_sol1} alt="" />
              <Grid item xs={12}>
                <h4>DUPIXENT</h4>
                <h4> JAK INHIBITOR EARLY ADOPTER PREDICTION </h4>
              </Grid>
            </center>
            <section>
              <div>
                <Collapse text="Description">
                  <Paragraph>
                    <Grid container direction="row">
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="h4">
                          <H4>Our Understanding</H4>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid containerdirection="row">
                      <Paragraph>
                        <b>Dupixent </b>
                        <br />
                        It was launched in March 28, 2017 by Regeneron
                        Pharmaceuticals and was used to treat people aged 6
                        years and older with moderate to severe Atopic
                        Dermatitis (eczema) that is not well controlled with
                        prescription therapies used on the skin. Dupixent ruled
                        the AD market since 2017 because it is the only therapy
                        that targets the IL-4/IL-13 pathway, a key driver of
                        allergies and type 2 inflammation.
                      </Paragraph>
                      <Paragraph>
                        <b>JAK Inhibitors </b>
                        <br />
                        They belong to a family of medicines called DMARDs
                        (Disease-Modifying Antirheumatic Drugs). Three JAK
                        Inhibitors (Olumiant, Xeljanz and Rinvoq) are approved
                        by FDA to treat Rheumatoid Arthritis. JAK Inhibitors
                        have recently seen a major advancement in the treatment
                        of Atopic Dermatitis (Eczema) and is expected to launch
                        in 2021.
                      </Paragraph>
                    </Grid>
                    <Grid container xs={12} direction="row">
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="h4">
                          <H4>Business Challenge</H4>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container xs={12} direction="row">
                      <Paragraph>
                        As JAK Inhibitors are expected to enter AD (Atopic
                        Dermatitis) market in 2021, Dupixent’s market share is
                        expected to decrease. For Dupixent to maintain its
                        foothold in the market, it is important to know:
                        <ul>
                          <li>
                            Who are the customers likely to adopt JAK inhibitors
                            at launch?
                          </li>
                          <li>
                            What would be the factors responsible for early
                            adoption of JAK inhibitors among patients?
                          </li>
                          <li>
                            What kind of patients are likely to adopt the newly
                            launched drugs?
                          </li>
                        </ul>
                      </Paragraph>
                    </Grid>
                    <Grid container direction="row" alignItems="center">
                      <Grid item xs={12} sm={6} md={4}>
                        <Typography variant="h4">
                          <H4>Our Approach</H4>
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      alignItems="center"
                      spacing={3}
                    >
                      <Grid item xs={12} md={7}>
                        <Paragraph>
                          <b>AI Prediction Model</b>
                          <ul>
                            <li>
                              With the help of Machine Learning algorithms, the
                              Attitudinal Segment of Healthcare providers (HCPs)
                              that are likely or unlikely to adopt JAK
                              inhibitors at launch are identified. This can help
                              the salesforce and marketing teams to identify
                              specific HCPs and have a focused interaction with
                              them.
                            </li>
                            <li>
                              Data Shapley, a principled framework to address
                              data valuation, is implemented on the given
                              dataset to remove noisy data-points and boost the
                              accuracy of the machine learning model.
                            </li>
                          </ul>
                        </Paragraph>

                        <Paragraph>
                          <b>Data Visualization</b>
                          <br />
                          Data visualization is an effective way of telling
                          stories by representing data in a form that is easier
                          to understand, while highlighting the data trends and
                          outliers. Several visualizations have been created
                          using the different Dupixent datasets to understand
                          the data better, as well as to derive insights to
                          explain the three main challenges listed above.
                          <p>
                            <LinkContainer>
                              <Grid container spacing={2}>
                                <Grid item>
                                  <StyledButton
                                    variant="outlined"
                                    color="primary"
                                    size="large"
                                    startIcon={<OpenInNewIcon />}
                                  >
                                    <a
                                      href="https://material.vlifevirtusa.com/regeneron/Renegenron_Commercial_Hackathon_Virtusa.pdf"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Presentation Link
                                    </a>
                                  </StyledButton>
                                </Grid>
                              </Grid>
                            </LinkContainer>
                          </p>
                        </Paragraph>
                      </Grid>
                      <Grid item xs={12} md={5} justify="center">
                        <img width="100%" src={jakInhibitors_sol2} alt="" />
                      </Grid>
                    </Grid>
                  </Paragraph>
                </Collapse>
                <Grid item xs={12}>
                  <AppBar position="static" color="default">
                    <StyledTabs
                      value={value}
                      onChange={handleChange}
                      aria-label="simple tabs example"
                    >
                      <Tab label="Exploratory Data Analysis" />
                      <Tab label="Prediction Model" />
                      <Tab label="Sentiment Analysis" />
                    </StyledTabs>
                  </AppBar>
                </Grid>
                <br />
                <TabPanel value={value} index={0}>
                  <Paragraph>
                    <Typography variant="h5">
                      Customers Who are More Likely to Adopt JAK Inhibitors at
                      Launch
                    </Typography>
                    <br />
                    <Grid container spacing={3} direction="row">
                      {data.map((data) => (
                        <Grid item xs={12} sm={4} md={4}>
                          <StyledCard
                            name={data[0]}
                            utility={data[1]}
                            cardAsLink={data[2]}
                          />
                        </Grid>
                      ))}
                    </Grid>
                    <br />
                    <Typography variant="h5">
                      Factors Responsible for the Early Adoption of JAK
                      Inhibitors
                    </Typography>
                    <br />
                    <Grid container spacing={3} direction="row">
                      {data1.map((data1) => (
                        <Grid item xs={12} sm={4} md={4}>
                          <StyledCard
                            name={data1[0]}
                            utility={data1[1]}
                            cardAsLink={data1[2]}
                          />
                        </Grid>
                      ))}
                    </Grid>
                    <br />
                    <Typography variant="h5">
                      Patients More Likely to Adopt the Newly Launched Drugs
                    </Typography>
                    <br />
                    <Grid container spacing={3} direction="row">
                      {data2.map((data2) => (
                        <Grid item xs={12} sm={4} md={4}>
                          <StyledCard
                            name={data2[0]}
                            utility={data2[1]}
                            cardAsLink={data2[2]}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Paragraph>
                </TabPanel>

                <TabPanel value={value} index={1}>
                  <Paragraph>
                    <h2>Predicting the Attitudinal Segment of an HCP </h2>
                    <Grid
                      container
                      direction="row"
                      spacing={3}
                      justify="center"
                    >
                      <Grid item xs={12} sm={6} md={3}>
                        <H3>Datasets Used : </H3>
                        <ListContainer type="none">
                          <List>JAK Sample </List>
                          <List>Dupixent AD Sample</List>
                          <List>Status Data</List>
                          <List>Dispense Data</List>
                          <List>Claims Data</List>
                          <List>Competitor Data</List>
                        </ListContainer>
                      </Grid>

                      <Grid item xs={12} sm={6} md={3}>
                        <H3>Attributes Used : </H3>
                        <ListContainer type="none">
                          <List>Speciality Group of HCP </List>
                          <List>HCP Residing City</List>
                          <List>HCP Residing State</List>
                          <List>HCP Speciality Code</List>
                          <List>Referral Status</List>
                          <List>Patient Frequency</List>
                          <List>Dupixent Prescription Count </List>
                          <List>Competitor Drug Prescription</List>
                          <List>HCP Provider Type</List>
                        </ListContainer>
                      </Grid>

                      <Grid item xs={12} sm={6} md={2}>
                        <H3> Model Trained: </H3>
                        <ListContainer type="none">
                          <List>Random Forest</List>
                          <List>Light GB Model</List>
                          <List>XG Boost Classifier</List>
                          <List>Naive Bayes</List>
                          <List>ADA Boost Classifier</List>
                          <List>Neural Network</List>
                        </ListContainer>
                        <H3>Model Selected: </H3>
                        <ListContainer type="none">
                          <List>Random Forest</List>
                        </ListContainer>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <H3>Target Variable:</H3>
                        <img
                          height="120px"
                          width="100%"
                          src={jakInhibitors_sample1}
                          alt=""
                        />
                        <h3>NoteBook Link : </h3>
                        <LinkContainer>
                          <Grid container spacing={2}>
                            <Grid item>
                              <StyledButton
                                variant="outlined"
                                color="primary"
                                startIcon={<OpenInNewIcon />}
                              >
                                <a
                                  href="https://material.vlifevirtusa.com/regeneron/hackathon/Predicting+The+Attitudinal+Segment+Of+the+HCPs+That+Are+Likely+To+Adopt+JAK+Inhibitors.html"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Model Link
                                </a>
                              </StyledButton>
                              <StyledButton
                                variant="outlined"
                                color="primary"
                                startIcon={<OpenInNewIcon />}
                              >
                                <a
                                  href="https://material.vlifevirtusa.com/regeneron/hackathon/Shapley_deepfit_package.html"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Shapley Link
                                </a>
                              </StyledButton>
                            </Grid>
                          </Grid>
                        </LinkContainer>
                      </Grid>
                    </Grid>

                    <HighchartContainer id="container" display />
                    <h3>Enter the Details of HCP Below:</h3>
                    <FormControl>
                      <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} md={3}>
                          <TextField
                            id="outlined-adornment-weight"
                            variant="outlined"
                            label="Speciality Group of HCP"
                            name="hcp_group"
                            fullWidth
                            value={fields.hcp_group}
                            onChange={onChangeFields}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            id="outlined-adornment-weight"
                            variant="outlined"
                            label="HCP Speciality Code"
                            name="hcp_code"
                            fullWidth
                            value={fields.hcp_code}
                            onChange={onChangeFields}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            id="outlined-adornment-weight"
                            variant="outlined"
                            label="Dupixent Prescription Count"
                            name="prescription_count"
                            fullWidth
                            value={fields.prescription_count}
                            onChange={onChangeFields}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            id="outlined-adornment-weight"
                            variant="outlined"
                            label="HCP Residing City"
                            name="hcp_city"
                            fullWidth
                            value={fields.hcp_city}
                            onChange={onChangeFields}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            id="outlined-adornment-weight"
                            variant="outlined"
                            label="Referral Status"
                            name="referral_status"
                            fullWidth
                            value={fields.referral_status}
                            onChange={onChangeFields}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            id="outlined-adornment-weight"
                            variant="outlined"
                            label="Competitor Drug Prescription"
                            name="drug_prescription"
                            fullWidth
                            value={fields.drug_prescription}
                            onChange={onChangeFields}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            id="outlined-adornment-weight"
                            variant="outlined"
                            label="HCP Residing State"
                            name="hcp_state"
                            fullWidth
                            value={fields.hcp_state}
                            onChange={onChangeFields}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            id="outlined-adornment-weight"
                            variant="outlined"
                            label="Patient Frequency"
                            name="frequency"
                            fullWidth
                            value={fields.frequency}
                            onChange={onChangeFields}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <TextField
                            id="outlined-adornment-weight"
                            variant="outlined"
                            label="HCP Provider Type"
                            name="hcp_type"
                            fullWidth
                            value={fields.hcp_type}
                            onChange={onChangeFields}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </FormControl>
                    <Grid
                      container
                      direction="column"
                      spacing={2}
                      alignContent="center"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={12} sm={6}>
                        <StyledButton
                          color="primary"
                          variant="contained"
                          id="sample1"
                          onClick={setSample}
                        >
                          Sample 1
                        </StyledButton>
                        <StyledButton
                          color="primary"
                          variant="contained"
                          id="sample2"
                          onClick={setSample}
                        >
                          Sample 2
                        </StyledButton>
                        <StyledButton
                          color="primary"
                          variant="contained"
                          id="sample3"
                          onClick={setSample}
                        >
                          Sample 3
                        </StyledButton>
                        <StyledButton
                          color="primary"
                          variant="contained"
                          onClick={getSampleResult}
                        >
                          Execute
                        </StyledButton>
                      </Grid>
                      <Grid item xs={6}>
                        {isLoading && <Spinner />}
                      </Grid>
                    </Grid>

                    {predictionResult?.response && (
                      <section>
                        <Result>
                          <strong>Result:</strong>
                        </Result>

                        <section>
                          <Button variant="outlined" color="primary">
                            {`Based on the details of HCP provided, the HCP is likely to fall under the “ ${predictionResult.response}” Attitudinal Segment. This indicates that the HCP has ${predictionResult.severity} chances of adopting JAK inhibitors at launch.`}
                          </Button>
                          <h3> How Data Shapley adds value to our model </h3>

                          <ul>
                            <li>
                              Data Shapley is a mathematical algorithm that
                              scores data at row level
                            </li>
                            <li>
                              It evaluates data points of the training data
                              based on the learning algorithm and performance
                              metric used
                            </li>
                            <li>
                              A Shapley value is assigned to every data point in
                              the dataset. A higher Shapley value indicates that
                              the data point is contributing positively towards
                              the performance of the model, while a
                              comparatively lower Shapley value indicates that
                              the data point does not add much value to the
                              performance of model.
                            </li>
                            <li>
                              To know more about Data Shapley, refer to
                              Shapley.pdf
                              <a
                                href="https://vlife.virtusa.com/static/media/shapley.9e2b2a65.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Shapley.pdf
                              </a>
                            </li>
                          </ul>
                          <Grid
                            container
                            direction="row"
                            spacing={3}
                            alignItems="center"
                          >
                            <Grid item xs={12} md={5}>
                              <img width="80%" src={jak3} alt="" />
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <img src={jak4} alt="" />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={showChart}
                              >
                                Display Data shapley Performance
                              </Button>
                            </Grid>
                          </Grid>
                          {predictionResult?.showChart && (
                            <section>
                              <Grid
                                container
                                direction="row"
                                spacing={2}
                                alignContent="center"
                                justify="center"
                              >
                                <Grid item xs={12} sm={5}>
                                  <HighchartContainer
                                    id="container_1"
                                    display={predictionResult.showChart}
                                  />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  {formTable(baseModel, 'Base Model Metrics')}
                                </Grid>
                                <Grid item xs={12} md={3}>
                                  {formTable(
                                    shapleyModel,
                                    'Shapley Model Metrics'
                                  )}
                                </Grid>
                              </Grid>
                            </section>
                          )}
                        </section>
                      </section>
                    )}
                  </Paragraph>
                </TabPanel>

                <TabPanel value={value} index={2}>
                  <Paragraph>
                    <Typography>
                      Sentiment analysis (or opinion mining) is a natural
                      language processing technique used to determine whether
                      data is positive, negative or neutral. Sentiment analysis
                      is often performed on textual data to help businesses
                      monitor brand and product sentiment in customers’ feedback
                      and understand their needs.
                      <br />
                      <br />
                      Since customers express their thoughts and feelings more
                      openly now than ever before, sentiment analysis is
                      becoming an essential tool to monitor and understand that
                      sentiment. Automatically analyzing customer feedback
                      expressed through public means such as social media
                      conversations, allows brands to learn what makes the
                      customers happy or frustrated so that the products and
                      services can be tailored to meet their needs.
                    </Typography>
                    <br />
                    <Typography variant="h6" display="block">
                      How Does Sentiment Analysis Work?
                    </Typography>
                    <center>
                      <Grid item xs={12}>
                        <img src={jakInhibitors_sample2} />
                      </Grid>
                    </center>
                    <Typography>
                      In the training process (a), a machine learning model
                      learns to associate a particular input (i.e., a piece of
                      textual data) to the corresponding output (tag) based on
                      the test samples used for training. A feature extractor
                      transfers the text input into a feature vector. Pairs of
                      feature vectors and tags (e.g., positive, negative, or
                      neutral) are fed into the machine learning algorithm to
                      generate a model. In the prediction process (b), the
                      feature extractor is used to transform unseen text inputs
                      into feature vectors. These feature vectors are then fed
                      into the model, which generates predicted tags (positive,
                      negative, neutral and others).
                    </Typography>
                    <br />
                    <Typography variant="h6" display="block">
                      Method
                    </Typography>
                    <Typography>
                      {' '}
                      In this application, sentiment analysis is performed on
                      Twitter data. Tweets are extracted using Twitter API and
                      the developed model is run on top of it. The output
                      provides us different sentiments such as joy,
                      anticipation, sadness etc. based on the ‘sentiment scores’
                      obtained. The keyword used for the extraction of Tweets is
                      ‘Dupixent’ and a total of 470 Tweets were received. In
                      addition to this, a word cloud visualization that shows
                      the frequently appearing words in those Tweets has been
                      built.
                    </Typography>
                    <LinkContainer>
                      <Grid container spacing={2}>
                        <Grid item>
                          <StyledButton
                            variant="outlined"
                            color="primary"
                            size="large"
                            startIcon={<OpenInNewIcon />}
                          >
                            <a
                              href="https://material.vlifevirtusa.com/Sentiment_Analysis/TwitterSentimentAnalysis.html"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Notebook
                            </a>
                          </StyledButton>
                        </Grid>
                      </Grid>
                    </LinkContainer>
                    <br />
                    <Typography variant="h6" display="block">
                      Output:
                    </Typography>
                    <br />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        setSentimentalAnalysisImage(sentimentAnalysis)
                      }
                    >
                      Sentiment Analysis
                    </Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setSentimentalAnalysisImage(wordCloud)}
                    >
                      Word Cloud
                    </Button>
                    <center>
                      <Grid item xs={12}>
                        {sentimentalAnalysisImage && (
                          <img src={sentimentalAnalysisImage} alt="" />
                        )}
                      </Grid>
                    </center>
                  </Paragraph>
                </TabPanel>
              </div>
            </section>
          </section>
        </div>
      </section>
    </SolutionContainer>
  );
};

export default memo(JakInhibitors);
const ListContainer = styled.ul`
  padding-left: 0;
`;

const H4 = styled.h4`
  text-align: left;
  border-bottom: 3px solid #3175eb;
  font-weight: 400;
  margin: 10px 0;
  padding: 10px 0;
  width: 100%;

  @media only screen and (max-width: 960px) {
    font-size: 1.6rem;
  }
  @media only screen and (max-width: 450px) {
    font-size: 1.2rem;
  }
`;
const StyledTitle = styled(Heading4)`
  color: #f5a76c;
`;
const PanelContainer = styled(Paper)`
  &.MuiPaper-root {
    padding: 15px;
  }
`;
const ListItem = styled.li`
  text-align: justify;
`;
function StyledCard({ name, utility, cardAsLink }) {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <CardActionArea
        className={classes.link}
        component={cardAsLink && 'a'}
        href={cardAsLink}
        target="_blank"
      >
        <CardContent>
          {name && (
            <Typography
              className={classes.textCenter}
              gutterBottom
              variant="h6"
              component="h5"
            >
              {name}
            </Typography>
          )}
          {utility && (
            <Typography
              gutterBottom
              variant="h6subtitle1"
              className={classes.textCenter}
              component="p"
            >
              {utility}
              <br />
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export function TabPanel(props) {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tablepanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <PanelContainer>{children}</PanelContainer>}
    </div>
  );
}

export function List({ children, icon = faCheck }) {
  return (
    <ListItem>
      <FontAwesomeIcon icon={icon} color="#e6aa6a" />
      &nbsp;&nbsp;
      {children}
    </ListItem>
  );
}

function H3({ children }) {
  return <StyledTitle>{children}</StyledTitle>;
}
