import React, { memo, useState } from "react";
import styled from "styled-components";
import { Grid } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Highcharts from "highcharts";

// local components
import Collapse from "../../common/Collapse";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  HighchartContainer,
  List,
  ListItem,
} from "../../../styles/common";

const DistributionalShapley = () => {
  const tableData = [
    ["6", "148", "72", "35", "0", "33.6", "0.627", "50", "1"],
    ["1", "85", "66", "29", "0", "26.6", "0.351", "31", "0"],
    ["8", "183", "64", "0", "0", "23.3", "0.672", "32", "1"],
    ["1", "89", "66", "23", "94", "28.1", "0.167", "21", "0"],
    ["0", "137", "40", "35", "168", "43.1", "2.288", "33", "1"],
  ];

  const [sample, setSample] = useState(false);
  const [result1, setResult1] = useState(false);
  const [notification, setNotification] = useState("");

  const getSample = () => {
    setResult1('');
    setSample(true);
  };

  const getResult1 = () => {
    setResult1(true);
    setTimeout(() => {
      formHighChart2();
    }, 0);
    setTimeout(() => {
      formHighChart1();
    }, 0);
  };

  const formHighChart1 = () => {
    Highcharts.chart('container_1', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      title: {
        text: 'Distributional Shapley for Sample',
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.0f}%</b>',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.0f} %',
          },
        },
      },
      series: [{
        name: 'Probability',
        colorByPoint: true,
        data: [{
          name: 'Negative value',
          y: 34.5,

        }, {
          name: 'Positive value',
          y: 65.5,
          sliced: true,

        }],
      }],
    });
  };
  const formHighChart2 = () => {
    Highcharts.chart('container_2', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      title: {
        text: 'Distributional Shapley for population',
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.0f}%</b>',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.0f} %',
          },
        },
      },
      series: [{
        name: 'Probability',
        colorByPoint: true,
        data: [{
          name: 'Negative value ',
          y: 31.6,

        }, {
          name: 'Positive value',
          y: 68.4,
          sliced: true,

        }],
      }],
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Shapley value is a classic notion from game theory, historically used to quantify the contributions of individuals within groups, and more recently applied to assign values to data points when training machine learning models. Despite its foundational role, a key limitation of the data Shapley framework is that it only provides valuations for points within a fixed data set. It does not account for statistical aspects of the data and does not give a way to reason about points outside the data set. To address these limitations, a novel framework known as “Distributional Shapley” is introduced where the value of a point is defined in the context of an underlying data distribution.
            At a high-level, prior works on Data Shapley require three ingredients: (1) a fixed training data set of m points; (2) a learning algorithm; and (3) a performance metric that measures the overall value of a trained model. The goal of Distributional Shapley is to significantly reduce the dependency on the first ingredient. While convenient, formulating the value based on a fixed data set disregards crucial statistical consideration and, thus, poses significant practical limitations. It means that the value of a data point computed within one data set may not make sense when the point is transferred to a new data set. If there is buying and selling of data, then it is important that the value of a given data point represents some intrinsic quality of the datum within the distribution. For example, a data seller might determine that the data has high value based on the Shapley values and sells data to a buyer at a high price. Even if the buyer’s data set Bb is drawn from a similar distribution as Bs, the existing data Shapley framework provides no guarantee of consistency between Shapley value computed within Bs and within Bb. This inconsistency may be especially pronounced in the case when the buyer has significantly less data than the seller. To overcome all these problems, Distributional Shapley can be used.
          </p>
          <p>The main advantages of using Distributional Shapley are as follow:</p>
          <List>
            <ListItem>In data sell and purchase setting, buyer already has idea about the quality of data which is going to be purchased by looking at the Shapley value distribution of Seller's data</ListItem>
            <ListItem>Data privacy is maintained for buyer's and seller's data as they don't have to share the entire data but only the Shapley parameters for an agreement</ListItem>
            <ListItem>Execution time for running Shapley is reduced</ListItem>
          </List>
          <p>
            <strong>Method</strong>
            {' '}
            - Distributional Shapley value is taken to be the expected Data Shapley value, where the data set is drawn i.i.d. (independent and identically distributed) from the underlying data distribution. A novel sampling-based algorithm, D-Shapley calculates Shapley values on this data and comes with strong formal approximation guarantees. As the Shapley values are calculated on a sample of data, therefore it helps in reducing the computational costs and execution time while maintaining the quality of estimations.
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/Distribution_Shapley/Distribution_Shapley.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://arxiv.org/abs/2002.12334"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Stanford AI : Citations
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid item xs={12}>
            <Paragraph>
              {' '}
              <p>
                Dataset containing information about patients w.r.t. diabetes is used for this demo. There are a total of 768 rows and 9 columns in the data. Distributional Shapley values are first calculated on whole data (population) and then on the subset of data (sample) drawn i.i.d.
              </p>
            </Paragraph>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={getSample}
            >
              Input Sample
            </StyledButton>
          </Grid>
          {sample && (
          <section>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableHead align="left">Pregnancies</StyledTableHead>
                    <StyledTableHead align="left">Glucose</StyledTableHead>
                    <StyledTableHead align="left">BloodPressure</StyledTableHead>
                    <StyledTableHead align="left">SkinThickness</StyledTableHead>
                    <StyledTableHead align="left">Insulin</StyledTableHead>
                    <StyledTableHead align="left">BMI</StyledTableHead>
                    <StyledTableHead align="left">DiabetesPedigreeFunction</StyledTableHead>
                    <StyledTableHead align="left">Age</StyledTableHead>
                    <StyledTableHead align="left">Outcome</StyledTableHead>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((row, index) => (
                    <TableRow key={index}>
                      {row.map((field) => (
                        <TableCell align="left">{field}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={getResult1}
            >
              Execute D-Shapley
            </StyledButton>
            {result1 && (
            <section>
              <Grid container xs={12} spacing={2} direction="row" justify="center" alignItems="center">
                <Grid item xs={12} sm={12} md={5}>
                  <HighchartContainer
                    id="container_2"
                    display={result1}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={1} />
                <Grid item xs={12} sm={12} md={5}>
                  <HighchartContainer
                    id="container_1"
                    display={result1}
                  />
                </Grid>
              </Grid>

              <Grid container xs={12} spacing={2} direction="row" justify="center" alignItems="center">
                <Grid item xs={10} sm={8} md={5}>
                  <div>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableHead align="left">Metrics</StyledTableHead>
                          <StyledTableHead align="left">Value</StyledTableHead>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key="0">
                          <TableCell align="left">Count of data point</TableCell>
                          <TableCell align="left">768</TableCell>
                        </TableRow>
                        <TableRow key="1">
                          <TableCell align="left">Execution Time</TableCell>
                          <TableCell align="left">1.67 Hours</TableCell>
                        </TableRow>
                        <TableRow key="2">
                          <TableCell align="left">Model Family</TableCell>
                          <TableCell align="left">Logistic</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={1} />
                <Grid item xs={10} sm={8} md={5}>
                  <div>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableHead align="left">Metrics</StyledTableHead>
                          <StyledTableHead align="left">Value</StyledTableHead>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key="0">
                          <TableCell align="left">Count of data point</TableCell>
                          <TableCell align="left">200</TableCell>
                        </TableRow>
                        <TableRow key="1">
                          <TableCell align="left">Execution Time</TableCell>
                          <TableCell align="left">0.66 Hours</TableCell>
                        </TableRow>
                        <TableRow key="2">
                          <TableCell align="left">Model Family</TableCell>
                          <TableCell align="left">Logistic</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Paragraph>
                    <b>The fraction of negative values in the sample data is very close to the fraction of values in population data which suggests that Distributional Shapley helps in determining the entire data quality by using only the subset of data.</b>
                  </Paragraph>
                </Grid>
              </Grid>
            </section>
            )}
          </section>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};
export default memo(DistributionalShapley);

const Paper = styled.div`
  overflow: auto;
  margin-bottom: 3%;
`;

const StyledTableHead = styled(TableCell)`
  background-color: #3c40af;
  color: white;
  font-weight: bold;
`;
