import React, { memo, useState, useEffect } from "react";
import {
  Grid, MenuItem, TextField, FormControl,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components
import Highcharts from "highcharts";
import SolutionContainer from "../../common/SolutionContainerWrapper";

import Collapse from "../../common/Collapse";
import CommonService from "../../../utils/services/CommonService";
import Spinner from "../../common/loading/Spinner";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  HighchartContainer,
} from "../../../styles/common";

const ForecastingExcellence = () => {
  const [spinner, setSpinner] = useState(false);
  const [medicineList, setMedicineList] = useState([]);
  const [notification, setNotification] = useState("");

  useEffect(() => {
    setSpinner(true);
    getQueryResult("medicineList")
      .then((res) => {
        setSpinner(false);
        setMedicineList(res.columns);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
      });
  }, []);

  const { getQueryResult } = CommonService(
    "commercialAnalytics",
    "forecastingExcellence",
  );

  const formHighChart = (data) => {
    Highcharts.chart("container", {
      title: {
        text: "Medication Trend Over The Years",
      },

      subtitle: {
        text: "",
      },

      yAxis: {
        title: {
          text: "",
        },
      },
      legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "middle",
      },

      xAxis: {
        title: {
          text: "Years",
        },
        categories: data[0],
      },

      series: [
        {
          name: "",
          data: data[1],
        },
      ],

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: "horizontal",
                align: "center",
                verticalAlign: "bottom",
              },
            },
          },
        ],
      },
    });
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setSpinner(true);
    getQueryResult("drugData", value)
      .then((res) => {
        setSpinner(false);
        console.log(res);
        formHighChart(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            <b>Problem :</b>
            {' '}
            Every successfully run business has to possess a
            fool proof demand planner. With complexity and dynamism associated
            with Pharmaceutical industry, a simple forecasting solution cannot
            be reliable. Generic forecasting solutions take into account only
            one variable (Sales/Consumption of previous 3 years) to predict next
            3 years. They miss out on other underlying factors that impact the
            industry – Disease trends, Competition, Changing Technology and so
            on. The dynamic factors have to be accommodated in to predict future
            consumption.
            <br />
            <br />
            <b>Solution:</b>
            {' '}
            EMR data gives a new perspective of drug
            consumption from provider’s and payer’s side who are the end
            consumers. vLife™ has an integrated data lake that can combine data
            from both silos and augment the data dimensionality to predict the
            consumption of drugs.
            <br />
            <br />
            <b>Approach:</b>
            All the drug data from an EMR is tactically combined with payer’s
            perspective resulting in a multi dimensional high fidelity
            information. This data is then passed onto a machine learning model
            to learn the impact of various factors on yearly drug consumption.
            The solution is trained on more than 330 Mn of simulated data and is
            capable to predict a span of 30 years. Another striking feature is
            that the solution is not limited to predicting one drug. It can
            predict the consumption patterns of 100+ drugs.
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Drug_forecasting_Excellence"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Drug_forecasting_Excellence/data"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          {medicineList.length > 0 && (
            <Grid container direction="row" justify="center">
              <Grid item xs={12} sm={6} md={3}>
                <FormControl minWidth="120" varient="outlined" fullWidth>
                  <TextField
                    labelId="outlined-adornment-weight-label"
                    transform="translate(14px, 20px) scale(1)"
                    id="outlined-adornment-weight"
                    label="Select Medication"
                    variant="outlined"
                    select
                    onChange={handleChange}
                  >
                    {medicineList.map((medicine) => (
                      <MenuItem value={medicine}>{medicine}</MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
            </Grid>
          )}
          {spinner && <Spinner text="Loading..." />}
          <Grid container direction="row" justify="center">
            <Grid item xs={12} sm={6}>
              <HighchartContainer id="container" display />
            </Grid>
          </Grid>
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(ForecastingExcellence);
