import React, { memo, useState } from "react";
import {
  Grid, TextField, MenuItem, FormControl,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components

import Highcharts from "highcharts";
import styled from "styled-components";
import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import CommonService from "../../../utils/services/CommonService";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  HighchartContainer,
} from "../../../styles/common";
import getUserData, { getUploadToken } from '../../../utils';

// images
import strokePrediction_sol1 from "../../../assets/images/app/strokePrediction_sol1.webp";

const StrokePrediction = () => {
  // This is the initial state of the DOM
  const initialData = {
    Age: "",
    Gender: "",
    hypertension: "",
    heart_disease: "",
    bmi: "",
    ever_married: "",
    work_type: "",
    smoking_status: "",
    residence_type: "",
    response: "",
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "patientEngagement",
    "strokePrediction",
  );
  const Token = getUploadToken()
  const formHighChart = (data) => {
    Highcharts.chart("container_1", {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
      },
      title: {
        text: "Major Contributing factors",
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
        },
      },
      series: [
        {
          name: "feature importance",
          colorByPoint: true,
          data: [
            {
              name: data[1][0][0],
              y: data[1][0][1],
            },
            {
              name: data[1][1][0],
              y: data[1][1][1],
            },
            {
              name: data[1][2][0],
              y: data[1][2][1],
            },
            {
              name: data[1][3][0],
              y: data[1][3][1],
            },
            {
              name: data[1][4][0],
              y: data[1][4][1],
            },
            {
              name: data[1][5][0],
              y: data[1][5][1],
            },
            {
              name: data[1][6][0],
              y: data[1][6][1],
            },
            {
              name: data[1][7][0],
              y: data[1][7][1],
            },
            {
              name: data[1][8][0],
              y: data[1][8][1],
            },
            {
              name: data[1][9][0],
              y: data[1][9][1],
            },
          ],
        },
      ],
    });
  };

  const showResult = () => {
    const NewDataSet = {
      Age: "58",
      Gender: "Male",
      hypertension: "0",
      heart_disease: "1",
      bmi: "18.0",
      ever_married: "No",
      work_type: "Self-employed",
      smoking_status: "never smoked",
      residence_type: "Rural",
    };
    setSampleData(NewDataSet);
  };
  const showResult2 = () => {
    const NewDataSet = {
      Age: "80",
      Gender: "Male",
      hypertension: "0",
      heart_disease: "1",
      bmi: "18.0",
      ever_married: "Yes",
      work_type: "Self-employed",
      smoking_status: "yes",
      residence_type: "Urban",
    };
    setSampleData(NewDataSet);
  };

  const getResult = () => {
    setResultData("");

    const {
      Age,
      Gender,
      hypertension,
      heart_disease,
      bmi,
      ever_married,
      work_type,
      smoking_status,
      residence_type,
    } = sampleData;

    const params = `${Age},${hypertension},${heart_disease},${bmi},${Gender},${ever_married},${work_type},${smoking_status},${residence_type},${Token.token}`;
    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("output", params)
        .then((res) => {
          setSpinner(false);
          setResultData(res);
          console.log(res);
          formHighChart(res);
        })
        .catch((err) => {
          setSpinner(false);
          console.log(err);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const onChangeFields = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            <b>Problem Addressed</b>
            {' '}
            – Prediction of stroke in a patient by
            checking various health indicators and demographic factors
          </p>
          <p>
            <b>Data</b>
            {' '}
            – The dataset provided by the National Cardiovascular
            Disease Surveillance System. It is organized by location (national,
            regional, state, and selected sites) and indicator, and they include
            CVDs (e.g., heart failure) and risk factors (e.g., hypertension).
          </p>
          <p>
            <b>Analytics Functions Used</b>
            {' '}
            – Since the Data was highly
            imbalanced and it would have given unreliable results (no stroke for
            all patients) if Machine Learning techniques were applied directly
            to it, ROSE method was used to generate Artificial Data and make the
            dataset balanced.
          </p>
          <p>
            Random Forest Algorithm predicts if a patient has stroke and the
            factors affecting it.
          </p>
          <p>
            The Results of ML model were then forecasted on validation dataset
            to plot the AUC-ROC graph.
          </p>
          <Image width="80%" src={strokePrediction_sol1} />
          <br />
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Heart_strokes"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>

            <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Heart_strokes/data"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>


          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container direction="row" justify="center" spacing={1}>
            <Grid item xs={12} md={3}>
              <StyledFormControl fullWidth minWidth="120" varient="outlined">
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Age"
                  name="Age"
                  value={sampleData.Age}
                  onChange={onChangeFields}
                />
                <br />
                <TextField
                  select
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Hypertension"
                  name="hypertension"
                  value={sampleData.hypertension}
                  onChange={onChangeFields}
                >
                  <MenuItem key={0} value={0}>
                    No
                  </MenuItem>
                  <MenuItem key={1} value={1}>
                    Yes
                  </MenuItem>
                </TextField>
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Bmi"
                  name="bmi"
                  value={sampleData.bmi}
                  onChange={onChangeFields}
                />
                <br />
              </StyledFormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <StyledFormControl fullWidth minWidth="120" varient="outlined">
                <TextField
                  id="outlined-adornment-weight"
                  select
                  variant="outlined"
                  label="Gender"
                  name="Gender"
                  value={sampleData.Gender}
                  onChange={onChangeFields}
                >
                  <MenuItem key={0} value="Male">
                    Male
                  </MenuItem>
                  <MenuItem key={1} value="Female">
                    Female
                  </MenuItem>
                </TextField>
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  select
                  variant="outlined"
                  label="Heart_disease"
                  name="heart_disease"
                  value={sampleData.heart_disease}
                  onChange={onChangeFields}
                >
                  <MenuItem key={0} value={0}>
                    No
                  </MenuItem>
                  <MenuItem key={1} value={1}>
                    Yes
                  </MenuItem>
                </TextField>
                <br />
              </StyledFormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <StyledFormControl fullWidth minWidth="120" varient="outlined">
                <TextField
                  select
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Work Type"
                  name="work_type"
                  value={sampleData.work_type}
                  onChange={onChangeFields}
                >
                  <MenuItem key={0} value="Govt-job">
                    Govt Job
                  </MenuItem>
                  <MenuItem key={1} value="Never-worked">
                    Never Worked
                  </MenuItem>
                  <MenuItem key={2} value="private">
                    Private
                  </MenuItem>
                  <MenuItem key={3} value="Self-employed">
                    Self Employed
                  </MenuItem>
                  <MenuItem key={14} value="children">
                    Children
                  </MenuItem>
                </TextField>
                <br />
                <TextField
                  select
                  id="outlined-adornment-weight"
                  variant="outlined"
                  label="Smoking Status"
                  name="smoking_status"
                  value={sampleData.smoking_status}
                  onChange={onChangeFields}
                >
                  <MenuItem key={0} value="never smoked">
                    Never Smoked
                  </MenuItem>
                  <MenuItem key={1} value="yes">
                    Yes
                  </MenuItem>
                </TextField>
                <br />
              </StyledFormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <StyledFormControl fullWidth minWidth="120" varient="outlined">
                <TextField
                  id="outlined-adornment-weight"
                  select
                  variant="outlined"
                  label="Residence Type"
                  name="residence_type"
                  value={sampleData.residence_type}
                  onChange={onChangeFields}
                >
                  <MenuItem key={0} value="Urban">
                    Urban
                  </MenuItem>
                  <MenuItem key={1} value="Rural">
                    Rural
                  </MenuItem>
                </TextField>
                <br />
                <TextField
                  id="outlined-adornment-weight"
                  select
                  variant="outlined"
                  label="Married"
                  name="ever_married"
                  value={sampleData.ever_married}
                  onChange={onChangeFields}
                >
                  <MenuItem key={0} value="Yes">
                    Yes
                  </MenuItem>
                  <MenuItem key={1} value="No">
                    No
                  </MenuItem>
                </TextField>
                <br />
              </StyledFormControl>
            </Grid>

            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="secondary"
                onClick={showResult}
              >
                Sample 1
              </StyledButton>

              <StyledButton
                variant="contained"
                color="secondary"
                onClick={showResult2}
              >
                Sample 2
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={getResult}
              >
                Submit
              </StyledButton>
            </Grid>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {resultData && (
            <section>
              <Result>
                <strong>Result:</strong>
                {`${resultData[0]}`}
              </Result>
              <Grid container direction="row" justify="center">
                <Grid item xs={12}>
                  <HighchartContainer id="container_1" display />
                </Grid>
              </Grid>
            </section>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(StrokePrediction);

const Image = styled.img`
  text-align: center;
`;
const StyledFormControl = styled(FormControl)`
  @media only screen and (min-width: 900px) {
    width:75%
  }
`;
