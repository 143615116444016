import React, { memo, useState } from "react";
import { Grid } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import DataSourceIcon from "@material-ui/icons/GetApp";

// import local components
import Highcharts from "highcharts";
import Collapse from "../../common/Collapse";
import ImageAnalysisInput from "../../common/ImageAnalysisInput";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  SolutionContainer,
  HighchartContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";

const MelanomaClassification = () => {
  const getResponse = (response) => {
    if (Array.isArray(response)) {
      setResponse(response[0]);
      formHighChart(JSON.parse(response[1]));
    } else {
      setResponse(response);
    }
  };

  const [response, setResponse] = useState(false);

  const formHighChart = (datasplit) => {
    let i = 0;
    const dataset = [];
    for (i = 0; i < Object.keys(datasplit.Class).length; i++) {
      dataset.push([datasplit.Class[i], datasplit.Probability[i]]);
    }
    console.log(dataset);
    Highcharts.chart("container", {
      chart: {
        type: "column",
      },
      title: {
        text: "Probability Distribution for Predicted Classes",
      },
      subtitle: {
        text: "Hover to Show Class Probability",
      },
      xAxis: {
        type: "category",
      },
      yAxis: {
        title: {
          text: "Probability",
        },
      },

      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: "{point.y:.1f}%",
          },
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b><br/>',
      },
      series: [
        {
          name: "Percentage",
          colorByPoint: true,
          data: dataset,
        },
      ],
    });
  };

  return (
    <SolutionContainer>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Melanoma, the most serious type of skin cancer, develops in the
            cells (melanocytes) that produce melanin — the pigment that gives
            your skin its color.
          </p>
          <p>
            The risk of melanoma seems to be increasing in people under 40,
            especially women. Knowing the warning signs of skin cancer can help
            ensure that cancerous changes are detected and treated before the
            cancer has spread. Melanoma can be treated successfully if it is
            detected early
          </p>
          <p>
            In this usecase , aim is to identify melanoma in images of skin
            lesions. In particular, we will use images within the same patient
            and determine which are likely to represent a melanoma. Using
            patient-level contextual information may help the development of
            image analysis tools, which could better support clinical
            dermatologists.Melanoma is a deadly disease, but if caught early,
            most melanomas can be cured with minor surgery. Image analysis tools
            that automate the diagnosis of melanoma will improve dermatologists'
            diagnostic accuracy. Better detection of melanoma has the
            opportunity to positively impact millions of people.Data is taken
            from ISIC archives it contains the largest publicly available
            collection of quality-controlled dermoscopic images of skin lesions.
          </p>
          <LinkContainer>
            <Grid container spacing={2}>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/Melanoma_classification/melanoma_classification.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://www.kaggle.com/c/siim-isic-melanoma-classification/data"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <ImageAnalysisInput
            img1="melanomaClassification_sample1.webp"
            img2="melanomaClassification_sample2.webp"
            url={["patientEngagementProduction", "melanoma"]}
            sample1url="sample1"
            sample2url="sample2"
            result={getResponse}
          />
          {response && (
            <section>
              <Result>
                <strong>Result:</strong>
                {response}
              </Result>
            </section>
          )}
          <Grid container direction="row" justify="center">
            <Grid item xs={12} md={6}>
              <HighchartContainer id="container" display={response} />
            </Grid>
          </Grid>
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(MelanomaClassification);
