import React, { memo, useState } from "react";
import { Grid } from "@material-ui/core";

// local components
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  List,
  ListItem,
} from "../../../styles/common";

// images
import ProviderNetworkAnalysis_sol1 from "../../../assets/images/app/ProviderNetworkAnalysis.webp";

const ProviderNetworkAnalysis = () => {
  const [notification, setNotification] = useState("");

  return (

    <SolutionContainer snackbar={notification}>
      <Paragraph>
        <Grid container xs={12} direction="row" justify="center">
          <Grid item xs={12} sm={10} md={6}>
            <center><img src={ProviderNetworkAnalysis_sol1} alt="" width="90%" /></center>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <p> A provider network is a list of health-care providers who are contracted by an insurance company and provide medical care to those enrolled in plans offered by that insurance company. Broadly speaking, a network is a group of health care providers including doctors, specialists, hospitals, labs, and pharmacies that have a partnership with your health insurance company to be a part of your insurance plan. </p>
            <p>Provider Network Analysis tool uses market scan data to project insight on top Provider available based on US states and Diagnosis. The Providers are ranked based on Claim amount. The Bar Plot and Choropleth Mapbox give us a broader picture of Providers across the United States.</p>
            <List>
              <ListItem>Tables showing top Providers in a particular state based on condition and diagnosis.</ListItem>
              <ListItem>Bar plot which shows the respective claim amount for top providers.</ListItem>
              <ListItem>Choropleth Map for Top Diagnosis across the United States.</ListItem>
            </List>
            <center>
              <StyledButton
                variant="contained"
                color="primary"
                size="large"
                textColor="white"
              >
                <a
                  href="https://apps.vlifevirtusa.com:5040/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Try Demo
                </a>
              </StyledButton>
            </center>
          </Grid>
        </Grid>
      </Paragraph>
    </SolutionContainer>
  );
};
export default memo(ProviderNetworkAnalysis);
