import React, { memo, useState } from "react";
import styled from "styled-components";
import {
  Grid,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Grow from "@material-ui/core/Grow";
import Input from "@material-ui/core/Input";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Highcharts from "highcharts";

// import local components
import CommonService from "../../../utils/services/CommonService";

import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  HighchartContainer,
} from "../../../styles/common";
import SolutionContainer from "../../common/SolutionContainerWrapper";

const { getQueryResult, uploadFile } = CommonService("bioInformatics", "genomePhenotype");

const GenomePhenotype = () => {
  const sampleFileData = [
    ['rs12564807', '1', '734462', 'AA'],
    ['rs3131972', '1', '752721', 'AG'],
    ['rs148828841', '1', '760998', 'AC'],
    ['rs12124819', '1', '776546', 'AA'],
    ['rs115093905', '1', '787173', 'GG'],
  ];

  const [showSpinner, setSpinner] = useState(false);
  const [uploadfile, setUploadfile] = useState("");
  const [result, setResult] = useState("");
  const [notification, setNotification] = useState("");

  const getsampleResult = (e) => {
    setSpinner(true);
    setResult("");
    getQueryResult("sample")
      .then((res) => {
        setSpinner(false);
        const result = res['THE COUNT OF EACH UNIQUE GENOME IS:'];
        setResult(result);
        formHighChart(result);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const getUploadResult = (e) => {
    setSpinner(true);
    setResult("");
    uploadFile("upload", uploadfile)
      .then((res) => {
        setSpinner(false);
        const result = res['THE COUNT OF EACH UNIQUE GENOME IS:'];
        setResult(result);
        formHighChart(result);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const formHighChart = (data) => {
    const categories = []; const
      seriesData = [];
    Object.entries(data).map((arr) => {
      categories.push(arr[0]);
      seriesData.push(arr[1]);
    });

    Highcharts.chart('container', {
      chart: {
        type: 'column',
      },
      title: {
        text: 'Genotype Frequency',
      },
      xAxis: {
        title: {
          text: 'Genotypes',
        },
        categories,
      },
      yAxis: {
        min: 0,
        labels: {
          overflow: 'justify',
        },
      },
      series: [{
        name: "Count",
        data: seriesData,
        animation: false,
      }],
      legend: {
        enabled: false,
      },
      animation: false,
    });
  };

  const onChangeFile = (e) => {
    const file = e.currentTarget.files[0];
    setUploadfile(file);
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>Genomics is a branch of molecular biology that involves structure, function, variation, evolution and mapping of genomes. There are several companies offering next generation sequencing of human genomes from complete 3 billion base-pairs to a few thousand Phenotype SNPs. Here genome data from 23andMe (using Illumina HumanOmniExpress-24) is used for a certain person's DNA’s Phenotype SNPs. The raw data is used to show the distribution of genotypes and chromosomes. </p>
          <p>A Genotype is an organism’s complete set of heritable genes, or genes that can be passed down from parents to offspring. These genes help encode the characteristics that are physically expressed (phenotype) in an organism. Enter the genome data of an individual and help identify the patterns and frequencies of chromosome and genotypes in that data set.</p>

          <LinkContainer>
            <Grid container spacing={2}>

              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://www.kaggle.com/zusmani/mygenome"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/GenomePhenotypeSNPs/SNP.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container direction="row" spacing={1} justify="center" alignItems="center">
            <Grid item xs={12} sm={12} md={2}>
              <StyledButton
                variant="contained"
                color="primary"
                data-sample="sample_image1"
                onClick={getsampleResult}
              >
                Sample
              </StyledButton>
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <h3>OR</h3>
            </Grid>
            <Grid item xs={10} sm={5} md={3}>
              <Input
                type="file"
                color="primary"
                name="uploadedFile"
                onChange={onChangeFile}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <StyledButton
                style={{ marginTop: "0" }}
                variant="contained"
                color="primary"
                onClick={getUploadResult}
              >
                Submit
              </StyledButton>
            </Grid>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
          <Grid container XS={12}>
            <Grid item xs={12} md={12}>
              <Grow in={result} timeout={1500}>
                <div>
                  <section>
                    <Grid container spacing={3} direction="row" justify="center">
                      <Grid item xs={12} sm={12} md={5}>
                        <Title1>Sample Data </Title1>
                        <TablePaper>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell key="0" align="left"><strong>RSID</strong></TableCell>
                                <TableCell key="1" align="left"><strong>Chromosome</strong></TableCell>
                                <TableCell key="2" align="left"><strong>Position</strong></TableCell>
                                <TableCell key="3" align="left"><strong>Genotype</strong></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {sampleFileData.map((row, index) => (
                                <TableRow key={index}>
                                  {row.map((field) => (
                                    <TableCell align="left">{field}</TableCell>
                                  ))}
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TablePaper>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Title2>Result  </Title2>
                        <HighchartContainer
                          id="container"
                          display={result}
                        />
                      </Grid>
                    </Grid>
                  </section>
                </div>
              </Grow>
            </Grid>
          </Grid>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(GenomePhenotype);

const Title1 = styled.div`
margin-top: 6%;
margin-bottom: 2%;
font-size: 25px;
text-align: left;
padding-bottom: 10px;
border-bottom: 1px solid #bfbfbf;
width: 80%;
@media only screen and (max-width: 900px) {
    font-size: 23px;
  }
  @media only screen and (max-width: 450px) {
    font-size: 20px;
  }
`;
const Title2 = styled.div`
margin-top: 5%;
margin-bottom: 2%;
font-size: 25px;
text-align: left;
padding-bottom: 10px;
border-bottom: 1px solid #bfbfbf;
width: 80%;
@media only screen and (max-width: 900px) {
    font-size: 23px;
  }
  @media only screen and (max-width: 450px) {
    font-size: 20px;
  }
`;
const TablePaper = styled.div`
  overflow: auto;
`;
