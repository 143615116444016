import React, { memo, useState } from "react";
import { Grid } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import styled from "styled-components";

// import local components
import Collapse from "../../common/Collapse";
import ImageAnalysisInput from "../../common/ImageAnalysisInput";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  SolutionContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";

// images
import pneumoniaDiagnosis_sol1 from "../../../assets/images/app/pneumoniaDiagnosis_sol1.webp";

const PneumoniaDiagnosis = () => {
  const getResponse = (response) => {
    const res = typeof response === "object" ? response.result : response;
    setResponse(res);
  };

  const [textResponse, setResponse] = useState(false);

  return (
    <SolutionContainer>
      <Collapse text="Description">
        <Paragraph>
          <h4>Context </h4>

          <p>
            Understanding and analyzing an X-ray has always been a
            doctor/professional’s job. AI/ML has evolved and extended its
            capabilities to really amazing ones such as reading medical data
            like X-rays, sonography charts and ECG matrices, and drawing
            conclusions from them. Detecting and predicting human diseases from
            images as well as analyzing them by using advanced machine learning
            and artificial intelligence adds icing to the cake.
          </p>
          <p>
            This application makes use of a trained Convolution Neural Network
            to analyze a chest x-ray image of a child to detect the presence of
            pneumonia.
          </p>
          <h4>How does this application work? </h4>
          <p>
            The algorithm used behind this application has been trained over a
            complex neural network consisting of thousands of neurons and
            connections to take multiple images to understand how different is a
            pneumonia x-ray from a healthy one. Once the algorithm detects the
            differences with a reasonable accuracy, it then takes up new images
            (different from the ones which were used for training) to validate
            the results.
          </p>
          <p>
            A Convolution Neural Network is trained over the given image dataset
            to predict the results with best possible accuracy, the technical
            and functional details of which are as follows:
          </p>
          <ul type="">
            <li>
              This CNN has 2 convolutional layers and a pooling layer is
              introduced using max pooling after every layer
            </li>
            <li>
              These 2 fully connected layers take the feature map of an image to
              make predictions. The Adam optimization function was used to
              optimize the rate at which the model learns to classify the images
              correctly
            </li>
            <li>
              Once the predictions are made, the results are then validated
              against a validation set
            </li>
          </ul>
          <h4>What does this application offer? </h4>
          <p>
            The world of medical imaging is ripe for a revolution in terms of
            deploying CNN based technologies. The task of reading
            <b>
              &nbsp; medical image data like X-rays, sonography charts and ECG
              matrices &nbsp;
            </b>
            are incredibly menial and repetitive. Those are two things that AI
            technologies are great at. With the help of this application,
            doctors and healthcare providers can take a back seat while the
            algorithm will be left to ponder over these images and gauge
            conclusions from them.
          </p>
          <h4>Sample chest x-rays: </h4>
          <p>
            The normal chest X-ray (left panel) depicts clear lungs without any
            areas of abnormal opacification in the image. Bacterial pneumonia
            (middle) typically exhibits a focal lobar consolidation, in this
            case in the right upper lobe (white arrows), whereas viral pneumonia
            (right) manifests with a more diffuse ‘‘interstitial’’ pattern in
            both lungs.
          </p>
          <center>
            <Image1 src={pneumoniaDiagnosis_sol1} />
          </center>
          <LinkContainer>
            <Grid container spacing={2}>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://www.kaggle.com/datasets/paultimothymooney/chest-xray-pneumonia"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Pneumonia_diagnosis_cnn"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <ImageAnalysisInput
            img1="pneumoniaDiagnosis_sample1.webp"
            img2="pneumoniaDiagnosis_sample2.webp"
            url={["patientEngagement", "pneumoniadiagnosis"]}
            sample1url="SampleImage/"
            sample2url="SampleImage2/"
            result={getResponse}
          />
          {textResponse && (
            <Result>
              <strong>Result:</strong>
              {textResponse}
            </Result>
          )}
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(PneumoniaDiagnosis);
const Image1 = styled.img`
  @media only screen and (min-width: 900px) {
    width: 80%;
    height: 250px;
  }
`;
