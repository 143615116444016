import React, { useState } from "react";
import {
  Typography, Grid, Button, TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

// import local components
import styled from "styled-components";
import LazyHero from "react-lazy-hero";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import Spinner from "../common/loading/Spinner";
import MainLayout from "../common/MainLayout";
import Footer from "../views/home/Footer";

// images
import vLifeLogin from "../../assets/images/app/vLifeLogin.webp";
import forgotPassword from "../../assets/images/app/forgotPassword.webp";
import TransformedLogo from "../../assets/logos/vlifeLogo4.webp";
import { validateEmail } from "../../utils";
import commonServices from "../../utils/services/CommonService";
import { USER_MANAGEMENT } from "../../constants";
import { StyledBackdrop } from "../../styles/common";

const ForgotPassword = ({ location }) => {
  const [showSpinner, setSpinner] = useState(false);
  const [email, setEmail] = useState(location?.state?.user);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [redirectData, setRedirect] = useState("");
  const { postQueryResult } = commonServices("portalAPI", "forgotPwd");

  const onHandleForgotPasswordClick = () => {
    const {
      CONFIRMED, GUEST_RESET_ERR, UNVERIFIED_USER, UN_REGISTERD, EMAIL_REQ, UNCONFIRMED, INV_EMAIL, GUEST, USER_PENDING_INFO, RESET_ROUTE, FORGOT_PWD_SUCCESS,
    } = USER_MANAGEMENT;
    setErrorMessage("");
    setSuccessMessage("");
    if (email == '') {
      setErrorMessage(EMAIL_REQ);
    } else if (email === GUEST) {
      setErrorMessage(GUEST_RESET_ERR);
    } else if (!validateEmail(email)) {
      setErrorMessage(INV_EMAIL);
    } else {
      setSpinner(true);
      let params = {
        mail_id: email,
      };
      postQueryResult('verifyUser', params)
        .then((user) => {
          // if (user.UserStatus == CONFIRMED) {
          // if (user.UserApprovalStatus == false) {
          //   setErrorMessage(USER_PENDING_INFO);
          //   setSpinner(false);
          //   return;
          // }
          params = {
            username: email,
          };

          postQueryResult('forgotpwd', params)
            .then((response) => {
              console.log(response);
              setSuccessMessage(FORGOT_PWD_SUCCESS);
              setTimeout(() => {
                setRedirect({ pathname: RESET_ROUTE, state: { user: email, destination: response?.data?.CodeDeliveryDetails?.Destination } });
              }, 5000);
              setSpinner(false);
            })
            .catch((err) => {
              setSpinner(false);
              console.log(err);
              setErrorMessage(err.message);
            });
          // } else if (user.UserStatus == UNCONFIRMED) {
          //   setErrorMessage(UNVERIFIED_USER);
          // } else {
          //   setErrorMessage(UN_REGISTERD);
          // }
          setSpinner(false);
        })
        .catch((err) => {
          setSpinner(false);
          setErrorMessage(err.message);
        });
    }
  };
  const onHandleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <MainLayout>
      {redirectData && <Redirect to={redirectData} />}
      <LazyHero
        imageSrc={vLifeLogin}
        parallaxOffset="150"
        minHeight="80vh"
        opacity="0.8"
        color="#283caa"
      >
        <LogoContainer id="logo" item xs={1}>
          <Link to="/">
            <LogoImage id="nav-logo" src={TransformedLogo} alt="" />
          </Link>
        </LogoContainer>
        <Grid container justify="center" alignItems="center" spacing={3}>
          <Grid item xs={12}>
            {errorMessage && (
              <ErrorAlert severity="error" variant="outlined">
                {errorMessage}
              </ErrorAlert>
            )}
          </Grid>
          <Grid item xs={12}>
            <img width="90px" src={forgotPassword} />
            <br />
            <WhiteTypography variant="h5" gutterbutton>
              Forgot Your Password?
            </WhiteTypography>
            <br />
            <br />
            <Grid
              container
              spacing={3}
              direction="column"
              alignItems="center"
              justify="center"
            >
              <Grid item xs={12}>
                <CustomTextField
                  fullWidth
                  id="email"
                  label="Email Address"
                  // autoComplete="new-password"
                  autoComplete="off"
                  required
                  value={email}
                  // disabled={true}
                  errorText="Email is required."
                  onChange={onHandleEmailChange}
                />
              </Grid>
              <Grid item xs={12}>
                <br />

                <Btn
                  variant="contained"
                  color="primary"
                  disabled={showSpinner}
                  onClick={onHandleForgotPasswordClick}
                >
                  Reset Password
                </Btn>
              </Grid>
              <Grid item xs={12}>
                {successMessage && (
                  <SuccessAlert severity="success">
                    {successMessage}
                  </SuccessAlert>
                )}
              </Grid>
            </Grid>

            <br />

          </Grid>
        </Grid>
      </LazyHero>
      <StyledBackdrop open={showSpinner}>
        <Spinner />
      </StyledBackdrop>
      <Footer hideSocialShare />
    </MainLayout>
  );
};
export default ForgotPassword;

const Btn = styled(Button)`
&.MuiButton-root{
  color: #ffffff;
  background-color: #fc7703 !important;
  & hover {
    background-color: #fc7703;
    transform: scale(1.1);
  }
}
`;
const CustomTextField = styled(TextField)`
&.MuiTextField-root{
  color: #ffffff !important;
  border: 0;
  margin: 0px 85px 11px 10px;
  padding: 0;
  position: relative;
  flex-direction: column;
  vertical-align: top;

  @media only screen and (max-width: 585px) {
    margin: -8px !important;
  }
}

  & label.MuiInputLabel-root {
    color: #ffffff;
  }
  & .MuiInput-underline:before {
    border-bottom-color: #ffffff;
  }
  & .MuiInputBase-input.MuiInput-input {
    color: #fc7703;
  }
  & label.Mui-focused {
    color: #fc7703;
  }
  & .MuiInput-underline:after {
    border-bottom-color: #fc7703;
  }
  & .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom-color: #fc7703;
  }
`;
const SuccessAlert = styled(Alert)`
  &.MuiAlert-root {
    color: #ffff;
    background: transparent;
  }
`;
const ErrorAlert = styled(Alert)`
&.MuiAlert-root {
  color: white;
  border: 2px solid #fc7703;
  margin-top: 10px;
}
`;
const WhiteTypography = styled(Typography)`
  color: white;
`;
const LogoContainer = styled.div``;
const LogoImage = styled.img`
  height: 30px;
`;
const CartImg = styled.img`
  width: 30px;
  @media only screen and (max-width: 450px) {
    width: 28px;
  }
  @media only screen and (max-width: 450px) {
    width: 25px;
  }
  @media only screen and (max-width: 450px) {
    width: 22px;
  }
`;
