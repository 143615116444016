import React, { memo, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import styled from "styled-components";

// import local components
import Collapse from "../../common/Collapse";
import ImageAnalysisInput from "../../common/ImageAnalysisInput";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  SolutionContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";

const IntracranialHemorrhageDetection = () => {
  const getResponse = (response) => {
    const imageResponse = typeof response === "object" ? response.result : response;
    setResponse(imageResponse);
  };
  const [response, setResponse] = useState(false);

  return (
    <SolutionContainer>
      <Collapse text="Description">
        <Paragraph>
          <b>Abstract</b>
          <p>
            Intracranial hemorrhage, bleeding that occurs inside the cranium, is
            a serious health problem requiring rapid and often intensive medical
            treatment. For example, intracranial hemorrhages account for
            approximately 10% of strokes in the U.S., where stroke is the
            fifth-leading cause of death. Identifying the location and type of
            any hemorrhage present is a critical step in treating the patient.
          </p>
          <p>
            Diagnosis requires an urgent procedure. When a patient shows acute
            neurological symptoms such as severe headache or loss of
            consciousness, highly trained specialists review medical images of
            the patient’s cranium to look for the presence, location and type of
            hemorrhage. The process is complicated and often time consuming.
          </p>
          <b>Data Summary</b>
          <ul>
            <li>82 patients in total</li>
            <li>36 patients with intracranial hemorrhage</li>
            <li>46 normal patients</li>
            <li>
              Approx. 30 CT slices for each patient (e.g. 34 for patient 58)
            </li>
            <li>46 males</li>
            <li>36 females</li>
            <li>
              318 of 2500 images have masks that show intracranial hemorrhage
            </li>
            <li>Images: 650x650x1</li>
            <li>Masks: 650x650x1</li>
            <li>
              Two kinds of windowed images are available - brain window and bone
              window
            </li>
          </ul>
          <b>Algorithm Details and Approach followed</b>
          <p>
            This solution is developed on a rich image dataset provided by the
            Radiological Society of North America (RSNA®) in collaboration with
            members of the American Society of Neuroradiology and MD.ai.
          </p>
          <p>
            This Algorithm will help the medical community identify the
            presence, location and type of hemorrhage in order to quickly and
            effectively treat affected patients.
          </p>
          <b>Approach followed</b>
          <ul>
            <li>
              The brain window images are initially converted to 3 channel
              images to suit the model.
            </li>
            <li>Images and Masks are then resized to 256x256</li>
            <li>
              Algorithms used - Keras Densenet121 encoder with a Unet decoder -
              Adam optimizer and dice loss.
            </li>
            <li>
              Since The dataset is quite small therefore, data augmentation
              techniques have been used to reduce overfitting and to help the
              model generalize better.
            </li>
          </ul>
          <b>How to use the Application?</b>
          <p>
            Upload a CT Scan picture of the brain and the app will automatically
            detect haemorrhage in it
          </p>
          <b>Useful Resources</b>
          <LinkContainer>
            <Grid container spacing={2}>
            <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://www.kaggle.com/competitions/rsna-intracranial-hemorrhage-detection/data"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/HemorrhageAnalyzer/brain_hemorrhage.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://www.kaggle.com/c/rsna-intracranial-hemorrhage-detection/discussion/109335"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CT Scan Basics
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://github.com/qubvel/segmentation_models"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Segmentation_Models package by Pavel Yakubovskiy
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://www.kaggle.com/c/severstal-steel-defect-detection/discussion/103367"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Write up on segmentation models package by Chris Deotte
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <ImageAnalysisInput
            img1="intracranialHemorrhageDetection_sample1.webp"
            img2="intracranialHemorrhageDetection_sample2.webp"
            url={["patientEngagement", "intracranialHemorrhage"]}
            sample1url="sample_image1"
            sample2url="sample_image2"
            result={getResponse}
          />
          {response && (
            <section>
              <Result>
                <strong>Result:</strong>
              </Result>
              <Typography align="center" variant="h6" gutterBottom>
                {response[1]}
              </Typography>
              <center>
                <Image width="300px" height="300px" src={response[0]} alt="" />
              </center>
            </section>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(IntracranialHemorrhageDetection);
const Image = styled.img`
  @media only screen and (max-width: 1100px) {
    max-width: 130px;
    max-height: 130px;
  }
`;
