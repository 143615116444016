import React, { memo, useState } from "react";
import styled from "styled-components";
import { Grid, TextField, MenuItem } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

// local components

import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import Collapse from "../../common/Collapse";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";

import drugCascading_sol1 from "../../../assets/images/app/d-d1.webp";
import drugCascading_sol2 from "../../../assets/images/app/d-d2.webp";
import { getUploadToken } from "../../../utils";

const DrugCascading = () => {
  const initialData = {
    drug2: "",
    drug1: "",
  };

  const drug1 = [
    {
      value: "ampicillin",
      name: "Ampicillin",
    },
    {
      value: "fentanyl",
      name: "Fentanyl",
    },
    {
      value: "sevoflurane",
      name: "Sevoflurane",
    },
    {
      value: "aminophylline",
      name: "Aminophylline",
    },
    {
      value: "linezolid",
      name: "Linezolid",
    },
    {
      value: "naproxen",
      name: "Naproxen",
    },
    {
      value: "ribavirin",
      name: "Ribavirin",
    },
    {
      value: "spironolactone",
      name: "Spironolactone",
    },
    {
      value: "medroxyprogesterone",
      name: "Medroxyprogesterone",
    },
    {
      value: "orphenadrine",
      name: "Orphenadrine",
    },
  ];

  const drug2 = [
    {
      value: "diazepam",
      name: "Diazepam",
    },
    {
      value: "retinoic_acid",
      name: "Retinoic Acid",
    },
    {
      value: "methylprednisolone",
      name: "Methylprednisolone",
    },
    {
      value: "dexamethasone",
      name: "Dexamethasone",
    },
    {
      value: "omeprazole",
      name: "Omeprazole",
    },
    {
      value: "pantoprazole",
      name: "Pantoprazole",
    },
    {
      value: "aspirin",
      name: "Aspirin",
    },
    {
      value: "ofloxacin",
      name: "Ofloxacin",
    },
    {
      value: "nystatin",
      name: "Nystatin",
    },
    {
      value: "paroxetine",
      name: "Paroxetine",
    },
  ];

  const [sampleData, setFieldValues] = useState(initialData);
  const [result, SetResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [samplevalue, setSample] = useState("");
  const [notification, setNotification] = useState("");

  const sampleResult = {
    sample1: [
      "AGORAPHOBIA",
      "MAJOR_DEPRESSION",
      "ADJUSTMENT_DISORDER",
      "POST_TRAUMATIC_STRESS_DISORDER",
      "HYPERALIMENTATION",
      "PANIC_ATTACK",
      "TENSION_HEADACHE",
      "ATTEMPTED_SUICIDE",
      "DRUG_ADDICTION",
      "EXCESSIVE_SLEEPINESS",
    ],
    sample2: [
      "CARBUNCLE",
      "IRREGULAR_MENSTRUAL_CYCLE",
      "AMENORRHEA",
      "ALTERED_BOWEL_FUNCTION",
      "PREMATURE_MENOPAUSE",
      "FURUNCLE",
      "HISTOPLASMOSIS",
      "ESOPHAGEAL_SPASM",
      "RAYNAUDS_PHENOMENON",
      "ANTINUCLEAR_ANTIBODY_POSITIVE",
    ],
  };
  const data = {
    sample1: {
      drug1: "ampicillin",
      drug2: "diazepam",
    },
    sample2: {
      drug1: "fentanyl",
      drug2: "nystatin",
    },
  };

  const { getQueryResult } = CommonService("clinicalTrials", "drugCascading");
  const Token = getUploadToken()

  const setSampleData = (e) => {
    const sample = e.currentTarget.getAttribute("data-id");
    setFieldValues(data[sample]);
    setSample(sample);
  };
  const handleInputs = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSample("");
    setFieldValues({
      ...sampleData,
      [name]: value,
    });
  };

  const getResult = () => {
    const { drug1, drug2 } = sampleData;
    SetResultData("");
    if (samplevalue) {
      SetResultData(sampleResult[samplevalue]);
      return;
    }

    const params = `${drug1},${drug2},${Token.token}`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });
    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("sample", params)
        .then((res) => {
          setSpinner(false);
          SetResultData(res.result);
        })
        .catch((err) => {
          setSpinner(false);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            There is limited knowledge of drug interactions, mainly because of
            their unobserved complex relationships during clinical trials. Due
            to this, the implications of polypharmacy side effects in patient
            mortality and morbidity remain highly prevalent. This can be
            minimized by prediction and careful analysis of possible internal
            interactions between drugs.
          </p>
          <p>
            One approach to handle multi-relational link prediction in
            multimodal networks is by creating convolutional graph networks.
            This application makes use of the same approach to construct
            multimodal graphs, thereby visualizing all possible protein-protein
            interactions, drug-protein target interactions, ultimately
            predicting the polypharmacy side effects, which are represented by
            drug-drug interactions. Unlike other approaches that are limited to
            predicting simple drug-drug interaction values, this approach can
            predict the exact side effect manifested clinically through a given
            drug combination, if any.
          </p>
          <p>
            This opens opportunities to leverage pharmacogenomic and
            patient-population data to identify and prioritize the effects and
            possible polypharmacy side-effects during clinical trials, thereby
            assisting proper follow-up care, as well as adding value to future
            formal pharmacological studies.
          </p>
          <p>
            A typical drug-drug interaction graph network is represented by the
            following diagram.
          </p>
          <center>
            <Grid xs={12} sm={6} md={6}>
              <StyledFigure>
                <Image src={drugCascading_sol1} alt="" />
              </StyledFigure>
            </Grid>
          </center>
          <strong> Approach : </strong>
          <p>
            A graph convolutional neural network (GCN) model is a general
            approach developed for handling multi-relational link prediction in
            any multimodal network. This solution makes use of a GCN model
            trained on multimodal graphs of molecular and patient data to
            analyze, characterize, and predict polypharmacy side effects.
          </p>
          <center>
            <Grid xs={12} sm={6} md={6}>
              <StyledFigure>
                <Image src={drugCascading_sol2} alt="" width="100%" height="100%" />
              </StyledFigure>
            </Grid>
          </center>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
          <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/mims-harvard/decagon"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Data source
                </a>
              </StyledButton>
            </Grid>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/polypharmacy/polypharmacy.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="http://snap.stanford.edu/decagon/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Literature
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container direction="row" justify="center" spacing={2}>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="drug1"
                label="Drug1"
                value={sampleData.drug1}
                onChange={handleInputs}
              >
                {drug1.map((drug) => (
                  <MenuItem value={drug.value}>{drug.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="drug2"
                label="Drug2"
                value={sampleData.drug2}
                onChange={handleInputs}
              >
                {drug2.map((drug) => (
                  <MenuItem value={drug.value}>{drug.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <br />
          <Grid item xs={12}>
            <StyledButton
              variant="contained"
              color="primary"
              data-id="sample1"
              onClick={setSampleData}
            >
              Sample1
            </StyledButton>

            <StyledButton
              variant="contained"
              color="primary"
              data-id="sample2"
              onClick={setSampleData}
            >
              Sample2
            </StyledButton>

            <StyledButton
              variant="contained"
              color="primary"
              onClick={getResult}
            >
              Execute
            </StyledButton>
          </Grid>
          <br />

          {showSpinner && <Spinner text="Loading..." />}
          {result && (
            <section>
              <Result>
                <strong>Result:</strong>
                <Paragraph>
                  Possible Side Effects from the drug combination are :
                </Paragraph>
              </Result>
              <List>
                {result.map((drug) => (
                  <ListItem>
                    <ListItemIcon>
                      <ArrowForwardIosIcon />
                    </ListItemIcon>
                    <ListItemText primary={drug.split("_").join(" ")} />
                  </ListItem>
                ))}
              </List>
            </section>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};
export default memo(DrugCascading);
const StyledFigure = styled.figure`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const Image = styled.img`
  border-radius: 3px;
`;
