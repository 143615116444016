import React, { memo, useState } from "react";
import { Grid, FormControl, TextField } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components
import styled from "styled-components";
import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import CommonService from "../../../utils/services/CommonService";
import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";
import getUserData, { getUploadToken } from '../../../utils';

const TimePeriod2CompleteClinicalTrial = () => {
  const initialData = {
    phase: "",
    enrollment: "",
    mesh_term: "",
    count: "",
    response: "",
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "clinicalTrials",
    "predictingTimePeriod",
  );
  const Token = getUploadToken()
  const showResult = () => {
    const NewDataSet = {
      phase: "Phase 2",
      mesh_term: "Leukemia",
      enrollment: "17",
      count: "1",
    };
    setSampleData(NewDataSet);
  };
  const showResult2 = () => {
    const NewDataSet = {
      phase: "Phase 1",
      mesh_term: "Neoplasms",
      enrollment: "5",
      count: "1310",
    };
    setSampleData(NewDataSet);
  };

  const getResult = () => {
    const {
      phase, enrollment, mesh_term, count,
    } = sampleData;

    const params = `${phase},${mesh_term},${enrollment},${count},${Token.token}`;
    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("output", params)
        .then((res) => {
          setSpinner(false);
          setResultData(res.result);
        })
        .catch((err) => {
          setSpinner(false);
          console.log(err);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const onChangeFields = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            <p>
              Clinical trials are research studies that include human
              volunteers. Before a
              {" "}
              <a
                href="https://www.cancer.net/research-and-advocacy/clinical-trials/about-clinical-trials"
                target="_blank"
                rel="noopener noreferrer"
              >
                clinical trial
              </a>
              {" "}
              is initiated, doctors must prove there is a chance that the new
              treatment will work better than the one available now. Continuous
              research and laboratory tests done on animals are performed until
              the theory is proved. This is done to make sure that it is safe to
              perform the tests on humans.
            </p>
            <p>
              Once the clinical trials are approved for initiation, a series of
              steps, called “phases”, must be followed in a certain order. These
              phases are designed to keep volunteers safe. Making sure all the
              steps are performed helps to protect patients and give accurate
              results for the specific clinical trial.
            </p>
            <p>
              Each phase in a clinical trial may last for several months, and
              sometimes up to a year. Using historical data of all phases of
              clinical trials, and with the help of AI/ML, it is possible to
              predict the time period for a particular phase in a clinical
              trial. This will help researchers in making better decisions
              regarding clinical trial phases and procedures.
            </p>
            <br />
            <br />
          </p>
          <LinkContainer>
            <Grid container spacing={2}>

              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Predicting_time_period_of_clinical_trails/notebook"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Predicting_time_period_of_clinical_trails/data"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <section>
            <Grid
              container
              xs={12}
              direction="row"
              justify="center"
              spacing={1}
            >
              <Grid item xs={12} sm={6}>
                <FormControl>
                  <StyledTextBox

                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Phase"
                    name="phase"
                    onChange={onChangeFields}
                    value={sampleData.phase}
                  />
                  <br />
                  <StyledTextBox
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Disease Name"
                    name="mesh_term"
                    onChange={onChangeFields}
                    value={sampleData.mesh_term}
                  />
                  <br />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl>
                  <StyledTextBox
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Enrollment"
                    name="enrollment"
                    onChange={onChangeFields}
                    value={sampleData.enrollment}
                  />
                  <br />
                  <StyledTextBox
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Withdrawl Count"
                    name="count"
                    onChange={onChangeFields}
                    value={sampleData.count}
                  />
                  <br />
                </FormControl>
              </Grid>
            </Grid>
            <br />
            <StyledButton
              variant="contained"
              color="primary"
              onClick={showResult}
            >
              Sample Data 1
            </StyledButton>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={showResult2}
            >
              Sample Data 2
            </StyledButton>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={getResult}
            >
              Execute
            </StyledButton>
          </section>
          <br />
          <br />
          {showSpinner && <Spinner text="Loading..." />}
          {resultData && (
            <Result>
              <strong>Result:</strong>
              {resultData}
            </Result>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(TimePeriod2CompleteClinicalTrial);

const StyledTextBox = styled(TextField)`
  .MuiOutlinedInput-root {
    padding: 0px 75px;
  }
`;
