import React, { useState } from "react";
import SolutionContainerWrapper from "../../common/SolutionContainerWrapper";
import Collapse from "../../common/Collapse";
import FileDownloadIcon from "@material-ui/icons/GetApp";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import {
  DemoContainer,
  Paragraph,
  StyledButton,
  Result,
  LinkContainer
} from "../../../styles/common";
import Spinner from "../../common/loading/Spinner";
import {
  Grid,
  Typography,
  AppBar,
  Tab,
  Input,
  TableContainer,
  TableRow,
  Table,
  TableBody,
  TableHead,
  Paper,
  TableCell,
  FormControl,
  TextField,
  MenuItem,
  OutlinedInput,
  Select,
  Checkbox,
  ListItemText,
  InputLabel,
} from "@material-ui/core";
import getUserData from "../../../utils";
import commonServices from "../../../utils/services/CommonService";
import styled from "styled-components";
import { CheckBox } from "@material-ui/icons";
import downloadsample1 from "../../../assets/images/app/imageSim_img1.png";
import downloadsample2 from "../../../assets/images/app/imageSim_img2.png";

export const ImageSimDemo = () => {
  const [userId, setUserId] = useState(getUserData()?.token?.emailid);

  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");
  //upload file
  const [selectedFile2, setSelectedFile2] = useState("");
  const [selectedFile1, setSelectedFile1] = useState("");
  const [fileName2, setSelectedFileName2] = useState("");
  const [fileName1, setSelectedFileName1] = useState("");
  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [result, setResult] = useState();
  const [metric, setMetric] = useState([]);

  //const { uploadFilesWithBody } = commonServices("imageSim", "imageSim");
  const { uploadFilesWithBody } = commonServices("z1dAppsv1", "imageSim");

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const options = [
    "MSE",
    "PSNR",
    "MAE",
    "SSIM",
    "UQI",
    "NCC",
    "CC",
    "ED",
    "CS",
    "HI",
    "IoU",
    "BD",
    "KLD",
    "JSD",
    "DSC",
    "MD",
  ];

  const handleSelectOption = (event) => {
    setResult(null);

    const value = event.target.value;
    if (value[value.length - 1] === "None") {
      setMetric(metric.length === options.length ? [] : options);
      return;
    }
    setMetric(value);
  };

  const handleSelectImage1 = (e) => {
    setResult(null);

    const file = e.target.files[0];
    const fileName = e.target.files[0].name;
    if (e.target.files && e.target.files[0]) {
      setImage1(URL.createObjectURL(e.target.files[0]));
      setSelectedFile1(file);
      setSelectedFileName1(fileName);
    }
  };
  const handleSelectImage2 = (e) => {
    setResult(null);

    const file = e.target.files[0];
    const fileName = e.target.files[0].name;
    if (e.target.files && e.target.files[0]) {
      setImage2(URL.createObjectURL(e.target.files[0]));
      setSelectedFile2(file);
      setSelectedFileName2(fileName);
    }
  };

  const handleCompareImage = () => {
    setSpinner(true);
    setResult(null);

    if (!selectedFile1) {
      setNotification({ open: "error", message: "please upload a file" });
      setSpinner(false);
      return;
    }
    if (!selectedFile2) {
      setNotification({ open: "error", message: "please upload a file" });
      setSpinner(false);
      return;
    }

    const Context_param = JSON.stringify({
      UserID: userId.toLowerCase(),
    });
    const Content_param = JSON.stringify({});
    const img1 = selectedFile1;
    const img2 = selectedFile2;

    uploadFilesWithBody("upload", {
      Context_param: Context_param,
      Content_param: Content_param,
      img1: img1,
      img2: img2,
      function: JSON.stringify({
        metric: metric.length === options.length ? "None" : metric.toString(),
      }),
    })
      .then((res) => {
        setSpinner(false);
       
        setResult(res);
      })
      .catch((err) => {
        setNotification({ open: "error", message: `${err.message}` });
        setSpinner(false);
        console.log(err);
      });
  };

  return (
    <SolutionContainerWrapper snackbar={notification}>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid
            container
            spacing={2}
            xs={12}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={12} sm={3} md={4}>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<FileDownloadIcon />}
              >
                <a href={downloadsample1} download target="_blank">
                  Download Sample Image 1
                </a>
              </StyledButton>
            </Grid>

            <Grid item xs={12} sm={3} md={4}>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<FileDownloadIcon />}
              >
                <a href={downloadsample2} download target="_blank">
                  Download Sample Image 2
                </a>
              </StyledButton>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              {" "}
              <Paragraph>
                <strong> Choose the images to be compared: </strong>
              </Paragraph>
            </Grid>
          </Grid>
          <br />

          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={6} sm={6} md={6}>
              <Input
                type="file"
                color="primary"
                name="first_image"
                onChange={handleSelectImage1}
                variant="outlined"
                Checkbox
              />
              <br />
              <Typography>Image 1</Typography>
            </Grid>

            <Grid item xs={6} sm={6} md={6}>
              <Input
                type="file"
                color="primary"
                name="second_image"
                onChange={handleSelectImage2}
                variant="outlined"
              />
              <br />
              <Typography>Image 2</Typography>
            </Grid>
          </Grid>
          <br />
          <br />
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={6} sm={6} md={6}>
              {image1 && (
                <img
                  src={image1}
                  alt={image1}
                  width="100"
                  height="100"
                  style={{ margin: "2", padding: "2" }}
                />
              )}
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              {image2 && (
                <img
                  src={image2}
                  alt={image2}
                  width="100"
                  height="100"
                  style={{ margin: "2", padding: "2" }}
                />
              )}
            </Grid>
          </Grid>

          <br />
          <br />

          {selectedFile1 && selectedFile2 && (
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Grid item xs={6} sm={4} md={4}>
                <Typography>Select the metrices to be calculated:</Typography>
              </Grid>

              <Grid item xs={6} sm={4} md={4}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="demo-multiple-checkbox-label">
                    Metric
                  </InputLabel>
                  <Select
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    name="metric"
                    input={<OutlinedInput label="Name" />}
                    value={metric}
                    onChange={handleSelectOption}
                    renderValue={(selected) => selected.join(",")}
                    MenuProps={MenuProps}
                  >
                    {/* select All */}
                    <MenuItem value="None">
                      <Checkbox
                        checked={
                          options.length > 0 && metric.length === options.length
                        }
                        indeterminate={
                          metric.length > 0 && metric.length < options.length
                        }
                      />

                      <ListItemText primary="Select All" />
                    </MenuItem>

                    {options.map((item) => (
                      <MenuItem key={item} value={item}>
                        <Checkbox checked={metric.indexOf(item) > -1} />
                        <ListItemText primary={item} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          )}


          <br />
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Grid item xs={12} sm={12}>
              <StyledButton
                id="btn2"
                variant="contained"
                color="primary"
                onClick={handleCompareImage}
              >
                Compare
              </StyledButton>
            </Grid>
          </Grid>

          {showSpinner && <Spinner text="Loading..." />}

          {result && (
            <>
              <Result>
                <strong>Result: </strong>
              </Result>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <HeaderCell align="left">Metric</HeaderCell>
                      <HeaderCell align="left">Value</HeaderCell>
                      <HeaderCell align="left">Statement</HeaderCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(result)?.map((key) => (
                      <TableRow>
                        <TableCell align="left">{key}</TableCell>
                        <TableCell align="left">{result[key]?.value}</TableCell>
                        <TableCell align="left">
                          {result[key]?.statement}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </DemoContainer>
      </Collapse>
    </SolutionContainerWrapper>
  );
};

export default function ImageSimDesc() {
  return (
    <SolutionContainerWrapper>
      <Collapse text="Description">
        <Paragraph>
          <p>
            ImageSim is a versatile toolkit designed to analyze and evaluate
            image similarity using a wide range of metrics. With ImageSim, you
            can easily assess the similarity between two images based on your
            specific requirements. Whether you're conducting image recognition,
            content-based image retrieval, or any other visual similarity task,
            ImageSim empowers you with the tools to make accurate evaluations.
          </p>
          <p>
            By taking two images as input and selecting from a variety of
            available metrics, ImageSim allows you to compare and measure the
            similarity between them. You have the flexibility to choose the most
            suitable metrics based on your desired criteria, such as pixel
            intensity, structural information, statistical properties, or other
            relevant factors.
          </p>
          <p>
            <strong>The metrics available in ImageSim include: </strong>
            <ul>
              <li>Mean Squared Error (MSE)</li>
              <li>Peak Signal-to-Noise Ratio (PSNR)</li>
              <li>Mean Absolute Error (MAE)</li>
              <li>Structural Similarity Index (SSIM)</li>
              <li>Universal Quality Index (UQI)</li>
              <li>Normalized Cross-Correlation (NCC)</li>
              <li>Correlation Coefficient (CC)</li>
              <li>Euclidean Distance</li>
              <li>Cosine Similarity</li>
              <li>Histogram Intersection</li>
              <li>Intersection over Union (IoU)</li>
              <li>Bhattacharyya Distance (BD)</li>
              <li>Manhattan Distance</li>
              <li>Kullback-Leibler Divergence (KL-Divergence)</li>
              <li>Jensen-Shannon Distance (JSD)</li>
              <li>Dice Similarity Coefficient</li>
            </ul>
          </p>
          <p>
            With ImageSim, you can streamline your image analysis workflow and
            gain valuable insights into the degree of similarity between images.
            Whether you're a researcher, developer, or data analyst, ImageSim
            provides a comprehensive solution to meet your image evaluation
            needs.
          </p>
          <p>
            Experience the power of ImageSim and unlock a new level of precision
            in understanding image similarity. Explore, assess, and evaluate
            images effortlessly with this powerful toolkit at your disposal
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/ImageSimilarityToolkit /ImageSimilarity_V1.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook 1
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/ImageSimilarityToolkit /ImageSImilarity_V2.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook 2
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/ImageSimilarityToolkit /supportDoc.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Reference Document
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
    </SolutionContainerWrapper>
  );
}

const HeaderCell = styled(TableCell)`
  background-color: #00afb9;
  color: white;
  min-width: 100px;
  height: 5px;
  text-align: left;
`;
const Cell = styled(TableCell)`
  text-align: center;
`;
