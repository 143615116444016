import React, { memo, useState } from "react";
import { Grid } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// import local components

import styled from "styled-components";
import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  SolutionContainer,
  LinkContainer,
} from "../../../styles/common";

// images
import ultraSoundImageSegmentation_sol1 from "../../../assets/images/app/ultraSoundImageSegmentation_sol1.webp";
import ultraSoundImageSegmentation_sol2 from "../../../assets/images/app/ultraSoundImageSegmentation_sol2.webp";
import ultraSoundImageSegmentation_sample1 from "../../../assets/images/app/ultraSoundImageSegmentation_sample1.webp";
import ultraSoundImageSegmentation_sample2 from "../../../assets/images/app/ultraSoundImageSegmentation_sample2.webp";
import ultraSoundImageSegmentation_sample3 from "../../../assets/images/app/ultraSoundImageSegmentation_sample3.webp";
import ultraSoundImageSegmentation_sample4 from "../../../assets/images/app/ultraSoundImageSegmentation_sample4.webp";
import PresignedS3Link from "../../common/PresignedS3Link";

const UltraSoundImageSegmentation = () => {
  const [showInput, setShowInput] = useState(false);
  const [showOutput, setShowOutput] = useState(false);
  const [showSubmit, setShowSubmit] = useState(false);
  const [clearScreen, setClearScreen] = useState(false);
  const [clearVideo, setClearVideo] = useState(false);
  const [playVideo, setPlayVideo] = useState(false);
  const [showplayVideo, setShowplayVideo] = useState(false);

  const onShowInputClick = () => {
    setShowInput(true);
    setShowSubmit(true);
    setClearScreen(true);
    setShowOutput(false);
    setClearVideo(false);
    setPlayVideo(false);
    setShowplayVideo(false);
  };
  const onSubmitClick = () => {
    setShowOutput(true);
    setClearScreen(true);
    setShowInput(false);
    setShowplayVideo(true);
    setShowSubmit(false);
    setClearScreen(false);
    setClearVideo(false);
  };
  const onPlayVideo = () => {
    setPlayVideo(true);
    setShowOutput(true);
    setClearScreen(false);
    setShowInput(false);
    setShowSubmit(false);
    setPlayVideo(true);
    setClearVideo(true);
  };
  const onClearScreenClick = () => {
    setShowInput(false);
    setShowOutput(false);
    setClearScreen(false);
    setShowSubmit(false);
    setShowPlayVideo(false);
    setPlayVideo(false);
  };

  return (
    <SolutionContainer>
      <Collapse text="Description">
        <Paragraph>
          <p>
            <b>Ultrasound imaging (sonography)</b>
            {' '}
            is a diagnostic medical
            procedure that uses high-frequency sound waves to produce dynamic
            visual images of organs, tissues or blood flow inside the body. The
            sound waves are transmitted to the area to be examined and the
            returning echoes are captured to provide the physician with a ‘live’
            image of the area. Ultrasound is based on sonar, and uses a machine
            with a computer processor to create Ultrasound images. A transducer
            is placed on the area of the body to be visualized. Returning sound
            waves, or echoes, are processed through the computer and converted
            into images.
          </p>
          <p>
            The Dataset consisted of 100 Ultrasound images of neck. These images
            were manually annotated by Doctors. The annotated labels are ASM,
            SCM, MSM and BP. Description of each label is given below:
          </p>
          <ul>
            <li>
              The
              {' '}
              <b>Anterior Scalene Muscle (ASM)</b>
              {' '}
              is one of the lateral
              muscles of the neck, belonging to the scalene group. It is deeply
              placed, lying behind the Sternocleidomastoid. It is located
              between the subclavian vein and the subclavian artery. The roots
              of the brachial plexus pass posterior to it.
            </li>
            <li>
              <b>Sternocleidomastoid (SCM)</b>
              {' '}
              is a paired superficial muscle in
              the anterior portion of the neck. The sternocleidomastoid muscle
              (SCM) is an important landmark in the neck which divides it into
              an anterior and a posterior triangle. This muscle binds the skull
              to the sternum and clavicle. It protects the vertical
              neurovascular bundle of neck, branches of cervical plexus, deep
              cervical lymph nodes and soft tissues of neck from damage.
            </li>
            <li>
              The
              {' '}
              <b>Brachial Plexus (BP)</b>
              {' '}
              passes from the neck to the axilla
              and supplies the upper limb. The brachial plexus is responsible
              for cutaneous and muscular innervation of the entire upper limb,
              with two exceptions: the trapezius muscle innervated by the spinal
              accessory nerve and an area of skin near the axilla innervated by
              the intercostobrachial nerve.
            </li>
            <li>
              <b>Middle Scalene Muscle (MSM)</b>
              {' '}
              is the largest and longest
              muscle in the scalene group of lateral neck muscles. Often
              penetrated by the dorsal scapular and long thoracic nerves, it is
              deeply placed, lying behind Sternocleidomastoid.
            </li>
          </ul>
          <p>
            <b>Image segmentation</b>
            {' '}
            is the process of partitioning a digital
            image into multiple segments (sets of pixels, also known as image
            objects). The goal of segmentation is to simplify and/or change the
            representation of an image into something that is more meaningful
            and easier to analyze. Image segmentation is typically used to
            locate objects and boundaries (lines, curves, etc.) in images. More
            precisely, image segmentation is the process of assigning a label to
            every pixel in an image such that pixels with the same label share
            certain characteristics.
          </p>
          <p>
            Image Segmentation Techniques are broadly divided into two types:
          </p>
          <ul>
            <li>
              Semantic Segmentation: It is the is the task of assigning a class
              to every pixel in a given image.
            </li>
            <li>
              Instance Segmentation: Different instances of the same class are
              segmented individually in instance segmentation. In other words,
              the segments are instance-aware.
            </li>
          </ul>
          <b>Approach: </b>
          <p>
            Dataset consisted of 100 ultrasound images of neck that are
            annotated manually by doctors. Since the size of the dataset was
            small, Data Augmentation techniques were applied to expand the size
            of training dataset by creating modified versions of images in the
            dataset. Techniques such as Horizontal flipping, Random Brightness
            Augmentation, Random Zoom Augmentation, Horizontal and vertical
            shift Augmentation were applied. After expanding the dataset, these
            images were passed through various filters to increase the contrast
            and sharpen the edges of the image. The filters used were CLAHE,
            Histogram Equalized and Median filter.
            <br />
            <br />
            To perform Image segmentation of Ultrasound images, various machine
            learning models were considered like Mask RCNN, Faster RCNN, FastAI
            and UNET. After understanding the pros and cons of every model, two
            base models were finalized; UNET and FastAI. These models were
            finalized because they could predict non-linear shapes and also gave
            fair results on validation set. After evaluating the base models,
            UNET gave better predictions than FastAI and was considered to the
            final model to perform the image segmentation.
            <br />
            <br />
            Four different model files were built for four different classes and
            then a pipeline was created to predict these four classes. The final
            image generated after the pipeline will have 4 classes labelled in
            the image.
            <br />
            <br />
            The concept of DATA SHAPELY from STANFORD is also implemented to
            evaluate every image in the training dataset. The output of Data
            Shapely is a positive or negative score. Positive score indicates
            that the image is contributing positively to the model whereas a
            negative score indicates that the image in the dataset is reducing
            the accuracy of the model. This helps in improving the overall
            accuracy of the model.
          </p>
          <b>UNET Model Overview:</b>
          <p>
            UNet, evolved from the traditional convolutional neural network, was
            first designed and applied in 2015 to process biomedical images. As
            a general convolutional neural network focuses its task on image
            classification, where input is an image and output is one label, but
            in biomedical cases, it requires us not only to distinguish whether
            there is a disease, but also to localise the area of abnormality.
            <br />
            <br />
            UNet is dedicated to solving this problem. The reason it is able to
            localise and distinguish borders is by doing classification on every
            pixel, so the input and output share the same size.
          </p>
          <center>
            <img width="50%" src={ultraSoundImageSegmentation_sol1} />
          </center>
          <p>
            The architecture has a “U” shape. It is symmetric and consists of
            two major parts — the left part is called contracting path, which is
            constituted by the general convolutional process; the right part is
            expansive path, which is constituted by transposed 2d convolutional
            layers.
          </p>
          <b>Model Validation:</b>
          <center>
            <img width="50%" src={ultraSoundImageSegmentation_sol2} />
          </center>
          <p>
            The model is validated using the Dice Coefficient Comparison across
            the classes. The above figure displays the Dice coefficient for all
            the four classes with two different input image sizes ( 128 x 128
            and 64 x 64) on the training set and test set. Some of the key
            observations were:
          </p>
          <Grid container>
            <Grid xs={5}>
              <p>DC values are observed best in SCM Class</p>
              <ul>
                <li>SCM is very prominent in all 3 subgroupings</li>
                <li>Filter performance was highest on SCM class</li>
              </ul>
            </Grid>
            <Grid xs={5}>
              <p>ASM class suffers from overfitting </p>
              <ul>
                <li>Varying positions of the nerve across images</li>
                <li>Difficult to map a localized function </li>
              </ul>
            </Grid>
          </Grid>
          <p>
            <LinkContainer>
              <Grid container spacing={2}>
                <Grid item>
                  <StyledButton
                    variant="outlined"
                    color="primary"
                    size="large"
                    startIcon={<OpenInNewIcon />}
                  >
                    <PresignedS3Link
                      href="FinalPipeline/Final_pipeline_V1.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Notebook
                    </PresignedS3Link>
                  </StyledButton>
                </Grid>
                <Grid item>
                  <StyledButton
                    variant="outlined"
                    color="primary"
                    size="large"
                    startIcon={<OpenInNewIcon />}
                  >
                    <a
                      href="https://towardsdatascience.com/understanding-semantic-segmentation-with-unet-6be4f42d4b47"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      To know more about UNET architecture and Data Shapely
                    </a>
                  </StyledButton>
                </Grid>
                <Grid item>
                  <StyledButton
                    variant="outlined"
                    color="primary"
                    size="large"
                    startIcon={<OpenInNewIcon />}
                  >
                    <a
                      href="../../../assets/files/shapley.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Data Shapely
                    </a>
                  </StyledButton>
                </Grid>
                &nbsp;&nbsp;&nbsp;
                <Grid item>
                  <StyledButton
                    variant="outlined"
                    color="primary"
                    size="large"
                    startIcon={<OpenInNewIcon />}
                  >
                    <a
                      href="../../../assets/files/ImageSegmentationCaseStudy.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Find the complete case study
                    </a>
                  </StyledButton>
                </Grid>
                &nbsp;&nbsp;
              </Grid>
            </LinkContainer>
          </p>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container>
            <Grid item xs={12} alignItems="center">
              <StyledButton
                variant="contained"
                color="primary"
                onClick={onShowInputClick}
              >
                Image Analysis
              </StyledButton>
            </Grid>
          </Grid>
          <br />
          <br />
          {showInput && (
            <section>
              <Grid
                container
                xs={12}
                direction="row"
                alignItems="center"
                justify="center"
              >
                <StyledGrid item xs={12} md={5}>
                  <img width="100%" src={ultraSoundImageSegmentation_sample1} />
                  <b>Original</b>
                </StyledGrid>
                <StyledGrid item xs={12} md={5}>
                  <img width="100%" src={ultraSoundImageSegmentation_sample2} />
                  <b>Original</b>
                </StyledGrid>
              </Grid>
            </section>
          )}
          {showOutput && (
            <section>
              <Grid
                container
                xs={12}
                direction="row"
                alignItems="center"
                justify="center"
              >
                <StyledGrid item xs={12} md={5}>
                  <ResponsiveImg
                    width="100%"
                    src={ultraSoundImageSegmentation_sample3}
                  />
                  <b>Segmented</b>
                </StyledGrid>
                <StyledGrid item xs={12} md={5}>
                  <ResponsiveImg
                    width="100%"
                    src={ultraSoundImageSegmentation_sample4}
                  />
                  <b>Segmented</b>
                </StyledGrid>
              </Grid>
            </section>
          )}
          <Grid container spacing={0}>
            {showSubmit && (
              <Grid item xs={12} md={6} alignItems="right">
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={onSubmitClick}
                >
                  Submit
                </StyledButton>
              </Grid>
            )}

            {clearScreen && (
              <Grid item xs={12} md={6} alignItems="left">
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={onClearScreenClick}
                >
                  Clear
                </StyledButton>
              </Grid>
            )}
            {showplayVideo && (
              <Grid item xs={12} sm={12} md={12}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={onPlayVideo}
                >
                  Video Analysis
                </StyledButton>
              </Grid>
            )}
          </Grid>
          <br />
          {playVideo && (
            <section>
              <center>
                <Grid
                  container
                  xs={12}
                  direction="row"
                  alignItems="center"
                  justify="center"
                >
                  <StyledGrid item xs={12} md={5}>
                    <ResponsiveVideo
                      height="350px"
                      width="350px"
                      controls
                      autoPlay
                    >
                      <source
                        src="../../../assets/files/sample_video.mp4"
                        type="video/mp4"
                      />
                    </ResponsiveVideo>
                    <br />

                    <b>Original</b>
                  </StyledGrid>
                  <StyledGrid item xs={12} md={5}>
                    <ResponsiveVideo
                      height="350px"
                      width="350px"
                      controls
                      autoPlay
                    >
                      <source
                        src="../../../assets/files/output.mp4"
                        type="video/mp4"
                      />
                    </ResponsiveVideo>
                    <br />
                    <b>Segmented</b>
                  </StyledGrid>
                </Grid>
              </center>
            </section>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(UltraSoundImageSegmentation);

const ResponsiveVideo = styled.video`
  @media only screen and (max-width: 450px) {
    width: 100%;
    height: 100%;
    margin: -4% 0% 4% 8%;
  }
  @media only screen and (max-width: 1200px) {
    margin: 0% 0% 0% 0%;
  }
  /* margin: -4% 0% 4% 8%; */
`;
const ResponsiveImg = styled.img`
  @media only screen and (max-width: 450px) {
    width: 100%;
    height: 100%;
  }
`;
const StyledGrid = styled(Grid)`
  margin:20px;
`;
