import React, { memo, useState } from "react";
import {
  Grid,
  FormControl,
  TextField,
  MenuItem,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components
import styled from "styled-components";

import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import CommonService from "../../../utils/services/CommonService";
import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";

const SideEffects = () => {
  const initialData = {
    id: "",
    weight: "",
    drug: "",
    purpose: "",
    gender: "",
  };

  const genders = [
    {
      value: 1,
      label: "Male",
    },
    {
      value: 2,
      label: "Female",
    },
  ];

  const [sampleData, setSampleData] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService("clinicalTrials", "sideEffects");

  const showResult = () => {
    const NewDataSet = {
      id: "Patient 1736",
      drug: "PREDNISONE",
      purpose: "LOWER RESPIRATORY TRACT INFECTION",
      gender: "1",
      weight: "74",
    };
    setSampleData(NewDataSet);
  };
  const showResult2 = () => {
    const NewDataSet = {
      id: "Patient 1736",
      drug: "OMALIZUMAB",
      purpose: "ASTHMA",
      gender: "1",
      weight: "74",
    };
    setSampleData(NewDataSet);
  };

  const getResult = () => {
    setResultData("");

    const {
      id, drug, purpose, gender, weight,
    } = sampleData;

    const params = `${id},${drug},${purpose},${gender},${weight}`;
    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("output", params)
        .then((res) => {
          setSpinner(false);
          setResultData(res.result);
          console.log(res);
        })
        .catch((err) => {
          setSpinner(false);
          console.log(err);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const onChangeFields = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            <p>
              Side effects are unwanted symptoms caused by medical treatment.
              All medicines can cause side effects, particularly if not used as
              advised. This includes prescribed medicines, as well as
              over-the-counter medicines.
              <br />
              <br />
              This tool predicts whether a patient will experience any side
              effects after consuming a particular drug. This will help the
              patient to know whether it is safe for them to consume a
              particular drug or not. The user provides the name of the drug
              being consumed, the purpose for taking the drug, age, sex, and
              weight in order to get a prediction of the possibility of side
              effects.
            </p>
          </p>
          <LinkContainer>
            <Grid container spacing={2}>

              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Predicting_side_effects_of_drugs"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>

              <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Predicting_side_effects_of_drugs/data"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Data source
                </a>
              </StyledButton>
            </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <section>
            <Grid
              container
              xs={12}
              direction="row"
              justify="center"
            >
              <Grid item xs={12} md={6}>
                <StyledFormControl fullWidth>
                  <h4>Patient Details</h4>
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Patient ID"
                    name="id"
                    value={sampleData.id}
                    onChange={onChangeFields}
                    type="text"
                  />
                  <br />
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Purpose of using Drug"
                    name="purpose"
                    value={sampleData.purpose}
                    onChange={onChangeFields}
                    type="text"
                  />
                  <br />
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Weight"
                    name="weight"
                    value={sampleData.weight}
                    onChange={onChangeFields}
                    type="text"
                  />
                </StyledFormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledFormControl fullWidth>
                  <h4>Drug Details</h4>
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Name of Drug"
                    name="drug"
                    value={sampleData.drug}
                    onChange={onChangeFields}
                    type="text"
                  />
                  <br />
                  <TextField
                    id="outlined-adornment-weight"
                    select
                    variant="outlined"
                    label="Gender"
                    name="gender"
                    value={sampleData.gender}
                    onChange={onChangeFields}
                  >
                    {genders.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>

                </StyledFormControl>
              </Grid>
            </Grid>
            <br />
            <StyledButton
              variant="contained"
              color="primary"
              onClick={showResult}
            >
              Sample Data 1
            </StyledButton>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={showResult2}
            >
              Sample Data 2
            </StyledButton>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={getResult}
            >
              Execute
            </StyledButton>
          </section>
          <br />
          <br />
          {showSpinner && <Spinner text="Loading..." />}
          {resultData && (
            <section>
              <Result>
                <strong>Result:</strong>
                {`${resultData}`}
              </Result>
              <br />
            </section>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(SideEffects);
const StyledFormControl = styled(FormControl)`
  .MuiFormControl-root {
    margin: 0px 50px;
    padding: 0px 10px;

    @media only screen and (max-width: 900px) {
      padding: 0px 10px;
    }
  }
  .MuiFormLabel-root {
    @media only screen and (max-width: 900px) {
      font-size: 15px;
    }
    @media only screen and (max-width: 450px) {
      font-size: 14px;
    }
    @media only screen and (max-width: 300px) {
      font-size: 12px;
    }
  }
`;
