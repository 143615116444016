import React, { useEffect, useState } from 'react';
import {
  TableRow, TableHead, TableCell, TableBody, Table, TableSortLabel, Paper,
} from "@material-ui/core";
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

export default function JsonDataTable({ fields, dataRows, id }) {
  return (
    <TablePaper elevation={3} id={id}>
      <Table>
        <TableHead>
          <TableRow>
            {fields?.map((field) => (
              <HeaderCell key={field.name} align="left"><strong>{field.name}</strong></HeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataRows?.map((row) => (
            <TableRow key={row.index}>
              {fields.map((field) => (
                <StyledCell align="left">
                  {field.name === "index"
                    ? isNaN(row[field.name]) ? row[field.name] : row[field.name] + 1
                    : row[field.name]}
                </StyledCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TablePaper>
  );
}

export function SimpleTable({ headerData = [], bodyData = [] }) {
  return (
    <TablePaper elevation={3}>
      <Table>
        <TableHead>
          <TableRow>
            {headerData.map((field) => (
              <HeaderCell key={field} align="left"><strong>{field}</strong></HeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {bodyData.map((field) => (
              <StyledCell align="left">
                {field}
              </StyledCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TablePaper>
  );
}
export function PCATable({ headerData = [], rowData = [], bodyData = {} }) {
  return (
    <TablePaper elevation={3}>
      <Table>
        <TableHead>
          <TableRow>
            <HeaderCell key={'None'} align="left"> New Cols </HeaderCell>
            {headerData.map((field) => (
              <HeaderCell key={field} align="left"><strong>{field}</strong></HeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rowData.map((field) => (
            <TableRow>
              <StyledCell align="left">
                {field}
              </StyledCell>
              {headerData.map((headerkey) => (
                <StyledCell key={field} align="left">{bodyData[headerkey][field]}</StyledCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TablePaper>
  );
}
export function PastActivitySortTable({
  headerData = [], bodyData = [], colKeys = [], downloadModel,
}) {
  const [order, setOrder] = useState("asc");
  const [tableData, setTableData] = useState([]);

  const getComparator = (property) => {
    let comparator;
    if (property[1] === "date") {
      comparator = (a, b) => dateComparator(a, b, property[0]);
    } else if (property[1] === "string") {
      comparator = order === "desc"
        ? (a, b) => descendingComparator(a, b, property[0])
        : (a, b) => -descendingComparator(a, b, property[0]);
    }
    return comparator;
  };
  const getSignedurl = (e) => {
    downloadModel(e.target.id);
  };

  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const dateComparator = (a, b, orderBy) => {
    const date1 = new Date(a[orderBy]);
    const date2 = new Date(b[orderBy]);
    return a && b && (order === "desc" ? date1 - date2 : date2 - date1);
  };

  const handleSort = (e) => {
    setOrder(order == "asc" ? "desc" : "asc");
    const comparator = getComparator([e.currentTarget.id, e.currentTarget.dataset.type]);
    const sortedData = bodyData && bodyData.sort(comparator);
    setTableData(sortedData);
  };

  useEffect(() => {
    setOrder(order == "asc" ? "desc" : "asc");
    const comparator = getComparator(["Current Date", 'date']);
    const sortedData = bodyData && bodyData.sort(comparator);
    setTableData(sortedData);
  }, [bodyData]);

  return (
    <TablePaper elevation={3}>
      <Table aria-label="sticky table">
        <TableHead>
          <TableRow>
            {bodyData.length > 0 && headerData && headerData.map((cellData) => (
              cellData?.sortKey
                ? (
                  <HeaderCell
                    align="center"
                    onClick={handleSort}
                    id={cellData?.sortKey}
                    data-type={cellData?.type || "string"}
                  >
                    <TableSortLabel
                      active
                      direction={order}
                    >
                      &nbsp;&nbsp;
                      {cellData.label}
                    </TableSortLabel>
                  </HeaderCell>
                )
                : <HeaderCell align="center">{cellData?.label}</HeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody disablePadding>
          {tableData.length > 0 && tableData.map((row) => (
            <TableRow tabIndex={-1} key={row.ExecutionTime}>
              {colKeys.map((colKey) => (
                <StyledCell align="left">
                  {colKey == 'Response' ? (
                    <p id={row[colKey]} className="downloadBtn" onClick={getSignedurl} target="_blank" rel="noopener noreferrer">
                      Download
                      <FontAwesomeIcon icon={faDownload} />
                    </p>
                  ) : row[colKey]}
                </StyledCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TablePaper>
  );
}
export function CustomTable({ headerData = [], bodyData = {}, padding }) {
  return (
    <TablePaper elevation={3}>
      <Table aria-label="sticky table">
        <TableHead>
          <TableRow>
            {headerData.map((cellData) => (
              <HeaderCell align="left" padding={padding}>{cellData}</HeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody disablePadding>
          {Object.entries(bodyData).map((row) => (
            <TableRow tabIndex={-1}>
              {row.map((col) => (
                <StyledCell align="left" padding={padding}>
                  {col}
                </StyledCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TablePaper>
  );
}
const TablePaper = styled(Paper)`
&.MuiPaper-root{
  overflow: auto;
  width: max-content;
  max-width: 100%;
  .MuiTableSortLabel-root {
    color: #ffff;
  }
}
`;
const StyledCell = styled(TableCell)`
  &.MuiTableCell-root{
    padding: ${(props) => (props.padding ? props.padding : '10px')};
    border-right: 1px solid rgba(224, 224, 224, 1);
    .downloadBtn{
      cursor: pointer;
    }
    @media only screen and (max-width: 650px) {
      padding: 8px;
    }
  }
`;
export const HeaderCell = styled(StyledCell)`
&.MuiTableCell-root{
  background-color: #00afb9;
  color: #ffff;
}
`;
