import React, { memo, useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import DataSourceIcon from "@material-ui/icons/GetApp";

// local components
import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import CommonService from "../../../utils/services/CommonService";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";

// images
import eyesStatePrediction_sol1 from "../../../assets/images/app/eyesStatePrediction_sol1.webp";
import { getUploadToken } from "../../../utils";

const EyesStatePrediction = () => {
  // This is the initial state of the DOM
  const initialData = {
    AF3: "",
    F7: "",
    F3: "",
    FC5: "",
    T7: "",
    P7: "",
    O1: "",
    O2: "",
    P8: "",
    T8: "",
    FC6: "",
    F4: "",
    F8: "",
    AF4: "",
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService("bioInformatics", "brainWave");
  const Token = getUploadToken();

  const showResult = () => {
    const NewDataSet = {
      AF3: "4317.95",
      F7: "4014.87",
      F3: "4284.1",
      FC5: "4142.05",
      T7: "4355.9",
      P7: "4619.49",
      O1: "4064.62",
      O2: "4629.23",
      P8: "4208.72",
      T8: "4245.64",
      FC6: "4217.95",
      F4: "4288.72",
      F8: "4628.21",
      AF4: "4384.62",
    };

    setSampleData(NewDataSet);
    setResultData("");
  };
  const showResult2 = () => {
    const NewDataSet = {
      AF3: "4282.05",
      F7: "3987.69",
      F3: "4261.03",
      FC5: "4104.1",
      T7: "4335.9",
      P7: "4621.54",
      O1: "4070.26",
      O2: "4619.49",
      P8: "4204.62",
      T8: "4225.64",
      FC6: "4204.1",
      F4: "4276.92",
      F8: "4602.05",
      AF4: "4336.41",
    };
    setSampleData(NewDataSet);
    setResultData("");
  };

  const getResult = () => {
    const {
      AF3, F7, F3, FC5, T7, P7, O1, O2, P8, T8, FC6, F4, F8, AF4,
    } = sampleData;

    const params = `${AF3},${F7},${F3},${FC5},${T7},${P7},${O1},${O2},${P8},${T8},${FC6},${F4},${F8},${AF4},${Token.token}`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Field(s) are empty" });
    } else {
      setSpinner(true);

      getQueryResult("output", params)
        .then((res) => {
          setSpinner(false);
          setResultData(res.result);
        })
        .catch((err) => {
          setSpinner(false);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const handleInputs = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Taking human input by measuring brain activity by way of
            electroencephalography (EEG) can be of substantial benefit.
            Predicting eye movement by training a machine learning model on the
            EEG data can be one such use case. Electroencephalography (EEG)
            recording was made of a single person for 117 seconds (just under
            two minutes) while the subject opened and closed their eyes, which
            was recorded via a video camera. The open/closed state was then
            recorded against each time step in the EEG trace manually.
            {" "}
          </p>
          <p>
            The data consists of 14977 instances with 15 attributes each (14
            attributes representing the values of the electrodes and the eye
            state). The instances are stored in the corpus in chronological
            order to be able to analyze temporal dependencies. The 14 electrodes
            are AF3, F7, F3, FC5, T7, P7, O1, O2, P8, T8, FC6, F4, F8, AF4 which
            are used to capture the brain waves.
          </p>
          <center>
            <img height="400px" src={eyesStatePrediction_sol1} alt="" />
          </center>
          <p>
            <strong>Applications: </strong>
            {' '}
            EEG differences are observed between
            eyes closed and eyes-open conditions. This information can be used
            by paramedics for correct and quick diagnosis of any psychiatric
            disorder or related symptoms. The solution can also be used for the
            study of Alpha waves. Alpha waves are neural oscillations in the
            frequency range of 8–12 Hz arising from the synchronous and coherent
            (in phase or constructive) electrical activity of thalamic pacemaker
            cells in humans.
          </p>
          <p>
            They are also called Berger's waves after the founder of EEG. They
            predominantly originate from the occipital lobe during wakeful
            relaxation with closed eyes. Alpha waves are reduced with open eyes,
            drowsiness and sleep. Occipital alpha waves during periods of eyes
            closed are the strongest EEG brain signals.
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://archive.ics.uci.edu/ml/datasets/EEG+Eye+State"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/Brain_wave/brain_wave.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="AF3"
                name="AF3"
                value={sampleData.AF3}
                onChange={handleInputs}
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="F7"
                name="F7"
                value={sampleData.F7}
                onChange={handleInputs}
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="F3"
                name="F3"
                value={sampleData.F3}
                onChange={handleInputs}
                type="number"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="FC5"
                name="FC5"
                value={sampleData.FC5}
                onChange={handleInputs}
                type="number"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="T7"
                name="T7"
                value={sampleData.T7}
                onChange={handleInputs}
                type="number"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="P7"
                name="P7"
                value={sampleData.P7}
                onChange={handleInputs}
                type="number"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="O1"
                name="O1"
                value={sampleData.O1}
                onChange={handleInputs}
                type="number"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="O2"
                name="O2"
                value={sampleData.O2}
                onChange={handleInputs}
                type="number"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="P8"
                name="P8"
                value={sampleData.P8}
                onChange={handleInputs}
                type="number"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="T8"
                name="T8"
                value={sampleData.T8}
                onChange={handleInputs}
                type="number"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="FC6"
                name="FC6"
                value={sampleData.FC6}
                onChange={handleInputs}
                type="number"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="F4"
                name="F4"
                value={sampleData.F4}
                onChange={handleInputs}
                type="number"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="F8"
                name="F8"
                value={sampleData.F8}
                onChange={handleInputs}
                type="number"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="AF4"
                name="AF4"
                value={sampleData.AF4}
                onChange={handleInputs}
                type="number"
              />
            </Grid>
            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="secondary"
                onClick={showResult}
              >
                Sample 1
              </StyledButton>

              <StyledButton
                variant="contained"
                color="secondary"
                onClick={showResult2}
              >
                Sample 2
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={getResult}
              >
                Submit
              </StyledButton>
            </Grid>
          </Grid>

          {showSpinner && <Spinner text="Loading..." />}
          {resultData && (
            <Result>
              <strong>Result:</strong>
              {resultData}
            </Result>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(EyesStatePrediction);
