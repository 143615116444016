import React, { memo, useState } from "react";
import {
  Tab,
  Grid,
  AppBar,
  Table,
  TableBody,
  TableCell,
  Paper,
  TableRow,
  TableContainer,
  TableHead,
} from "@material-ui/core";
import Input from "@material-ui/core/Input";
import styled from "styled-components";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";

// local components
import Collapse from "../../common/Collapse";
import CommonService from "../../../utils/services/CommonService";
import { StyledTabs } from "./IOTRecipesView";
import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  List,
  ListItem,
} from "../../../styles/common";

// image components
import clinicalnotespreprocessor_solution1 from "../../../assets/images/app/clinicalnotespreprocessor.webp";
import clinicalnotespreprocessor_sample1 from "../../../assets/images/app/periodontal-chart.png";
import clinicalnotespreprocessor_sample2 from "../../../assets/images/app/periodontal-chart2.png";
import clinicalnotespreprocessor_sample3 from "../../../assets/images/app/periodontal-chart3.png";
import clinicalnotespreprocessor_sample4 from "../../../assets/images/app/periodontal-chart4.png";

// user id
import getUserData from "../../../utils";

export const DentalRadiographsDemo = memo(() => {
  const { uploadFilesWithBody } = CommonService(
    "periodontalChar",
    "periodontalChar"
  );

  const [userId, setUserId] = useState(getUserData()?.token?.emailid);

  const [notification, setNotification] = useState("");
  const [value, setValue] = useState(0);
  const [data, setData] = useState({});
  const [showSpinner, setSpinner] = useState(false);

  // upload file
  const [selectedFile, setselectedFile] = useState("");
  const [btnEnable, setBtnEnable] = useState(true);
  const [fileName, setSelectedFileName] = useState("");

  const [metaData, setMetaData] = useState({
    isDataValid: false,
    isSample: false,
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setData({});
  };

  const fileSelectedHandler = (e) => {
    const file = e.target.files[0];
    const fileName = e.target.files[0].name;

    if (e.target.files && e.target.files[0]) {
      setselectedFile(file);
      setBtnEnable(false);
      setSelectedFileName(fileName);
    }
  };

  const setSampleData = (e) => {
    setSpinner(true);
    setData({});
    const id = e.currentTarget.id;

    setTimeout(function () {
      setSpinner(false);
      getData(id);
    }, 3000);
  };

  const getData = (data) => {
    fetch(data, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        console.log(response);
        return response.json();
      })
      .then(function (myJson) {
        console.log(myJson);
        setData({
          ...data,
          result: myJson,
        });
      });
  };

  const getUploadResult = () => {
    setData({});
    if (!selectedFile) {
      setNotification({ open: "error", message: "please upload a image" });
      return;
    }
    setSpinner(true);
    uploadFilesWithBody("upload", {
      file: selectedFile,
      Context_param: JSON.stringify({
        UserID: userId,
      }),
    })
      .then((res) => {
        setSpinner(false);
        setData({
          ...data,
          result: res?.FinalData,
        });
        setNotification({ open: "success", message: res.Console });
      })
      .catch((err) => {
        setNotification({ open: "error", message: err.message });
        setSpinner(false);
      });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AppBar position="static" color="default">
                <StyledTabs
                  value={value}
                  onChange={handleChange}
                  aria-label="simple tabs example"
                >
                  <Tab label="Sample" />
                  <Tab label="BYOD" />
                </StyledTabs>
              </AppBar>
            </Grid>
          </Grid>
          <TabPanel value={value} index={0}>
            <Paragraph>
              Please select a sample periodontal chart to generate a table
              containing information regarding each tooth from the uploaded
              chart with their respective Probing Depth values for both Buccal
              and Lingual section
            </Paragraph>
            <Grid
              container
              xs={12}
              spacing={2}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={6} sm={3} md={3}>
                <img
                  src={clinicalnotespreprocessor_sample1}
                  height="300"
                  width="250"
                />
                <br />
                <div style={{ textAlign: "center" }}>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    data-sample="sample1"
                    onClick={setSampleData}
                    value="sample1"
                    id="../../../assets/files/sample1_output.json"
                    name="sample1"
                  >
                    Sample 1
                  </StyledButton>
                </div>
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <img
                  src={clinicalnotespreprocessor_sample2}
                  height="300"
                  width="250"
                />
                <br />
                <div style={{ textAlign: "center" }}>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    data-sample="sample1"
                    onClick={setSampleData}
                    value="sample2"
                    id="../../../assets/files/sample2_output.json"
                    name="sample2"
                  >
                    Sample 2
                  </StyledButton>
                </div>
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <img
                  src={clinicalnotespreprocessor_sample3}
                  height="300"
                  width="250"
                />
                <br />
                <div style={{ textAlign: "center" }}>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    data-sample="sample3"
                    onClick={setSampleData}
                    value="sample3"
                    name="sample3"
                    id="../../../assets/files/sample3_output.json"
                  >
                    Sample 3
                  </StyledButton>
                </div>
              </Grid>
              <Grid item xs={6} sm={3} md={3}>
                <img
                  src={clinicalnotespreprocessor_sample4}
                  height="300"
                  width="250"
                />
                <br />
                <div style={{ textAlign: "center" }}>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    data-sample="sample4"
                    onClick={setSampleData}
                    value="sample4"
                    name="sample4"
                    id="../../../assets/files/sample4_output.json"
                  >
                    Sample 4
                  </StyledButton>
                </div>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid
              container
              xs={12}
              spacing={2}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={10} md={12}>
                <Paragraph>
                  Please upload your own image or download a sample image from
                  the given link and upload
                </Paragraph>
              </Grid>
              <Grid item xs={10} sm={3} md={3}>
                <StyledButton variant="outlined" color="primary">
                  <a
                    href={clinicalnotespreprocessor_sample1}
                    download
                    target="blank"
                  >
                    Download Sample
                  </a>
                </StyledButton>
              </Grid>
              <Grid item xs={8} sm={5} md={3}>
                <Input
                  type="file"
                  color="primary"
                  name="uploadedFile"
                  onChange={fileSelectedHandler}
                />
              </Grid>
              {selectedFile && (
                <>
                  <Grid item xs={12}>
                    <img
                      src={URL.createObjectURL(selectedFile)}
                      height="300"
                      width="250"
                    />
                  </Grid>
                  <Paragraph>
                    Please click on 'Execute' to generate a table containing
                    information regarding each tooth from the uploaded chart
                    with their respective Probing Depth values for both Buccal
                    and Lingual section
                  </Paragraph>
                  <Grid item xs={12} md={3}>
                    <StyledButton
                      id="btn2"
                      variant="contained"
                      color="primary"
                      helperText="Please select a csv file only"
                      onClick={getUploadResult}
                      disabled={btnEnable}
                    >
                      Execute
                    </StyledButton>
                  </Grid>
                </>
              )}
            </Grid>
          </TabPanel>
          <br />
          <br />
          {showSpinner && (
            <Spinner text="Loading.. Please wait for a minute..." />
          )}
          {data?.result && (
            <>
              <Grid
                container
                xs={12}
                spacing={2}
                direction="row"
                justifyContent="space-evenly"
                alignItems="left"
              >
                <Grid item xs={12} sm={8} md={10}>
                  <TableContainers>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <HeaderCell rowspan={2} align="left">
                            <strong></strong>
                          </HeaderCell>
                          <HeaderCell rowspan={2} align="center">
                            <strong>Teeth Name</strong>
                          </HeaderCell>
                          <HeaderCell rowspan={2} align="left">
                            <strong>Teeth Missing</strong>
                          </HeaderCell>
                          <HeaderCell colSpan={2} align="center">
                            <strong>Pocket Depths</strong>
                          </HeaderCell>
                        </TableRow>
                        <TableRow>
                          <HeaderCell align="left">
                            <strong>Buccal</strong>
                          </HeaderCell>
                          <HeaderCell align="left">
                            <strong>Lingual</strong>
                          </HeaderCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.keys(data?.result).map((key) => (
                          <TableRow>
                            <TableCell align="left">{key}</TableCell>
                            <TableCell align="left">
                              {data?.result[key]?.Teeth_Name}
                            </TableCell>
                            <TableCell align="left">
                              <FontAwesomeIcon
                                color={
                                  data?.result[key]?.Teeth_Missing
                                    ? "#4caf50"
                                    : "#d13f48"
                                }
                                icon={
                                  data?.result[key]?.Teeth_Missing
                                    ? faCheckCircle
                                    : faTimes
                                }
                              />
                            </TableCell>
                            <TableCell align="left">
                              {data?.result[key]?.Buccal?.PD == null
                                ? "Nan"
                                : data?.result[key]?.Buccal?.PD}
                            </TableCell>
                            <TableCell align="left">
                              {data?.result[key]?.Lingual?.PD == null
                                ? "Nan"
                                : data?.result[key]?.Lingual?.PD}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainers>
                </Grid>
              </Grid>
            </>
          )}
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
});

function TabPanel(props) {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tablepanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <PanelContainer>{children}</PanelContainer>}
    </div>
  );
}

export default function DentalRadiographsDesc() {
  return (
    <SolutionContainer>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Using deep learning methods like segmentation for dental
            complication detection is a novel approach gaining popularity in
            dentistry. This method involves using computer vision and image
            processing techniques to analyze digital images of teeth and gums,
            such as X-rays or digital photographs. The deep learning model is
            trained on a dataset of images to learn how to segment and identify
            different structures in the images, such as teeth structure, and any
            signs of dental complications.
          </p>
          <p>
            Once the model is trained, it automatically detects and highlights
            any potential dental complications, such as cavities, tooth decay,
            or diseases using segmented images. This can assist dental
            professionals in quickly and accurately identifying issues that may
            require further examination or treatment. Additionally, this
            approach can also be used to track the progression of dental
            complications over time, which can be useful for monitoring
            treatment outcomes.
          </p>
          <p>
            This application takes in a panoramic dental x-ray image and
            automatically performs segmentation to identify missing teeth, if
            any, as well as detect cavities and caries.
          </p>
          <p>
            <b>Dataset:</b> This dataset consists of anonymized and
            de-identified panoramic dental X-rays of 116 patients.
          </p>
          Link:
          <a
            href="https://data.mendeley.com/datasets/hxt48yk462/1"
            target="_blank"
            rel="noopener noreferrer"
          >
            Panoramic Dental X-rays With Segmented Mandibles - Mendeley Data
          </a>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/encrypted-data/sample/EncryptedTabularData.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
    </SolutionContainer>
  );
}

const PanelContainer = styled(Paper)`
  &.MuiPaper-root {
    padding: 15px;
  }
`;

const HeaderCell = styled(TableCell)`
  &.MuiTableCell-root {
    background-color: #00afb9;
    padding: 2px;
    color: #ffff;
  }
`;
const TableContainers = styled.div`
  width: 100%;
  height: 450px;
  overflow: auto;
`;
