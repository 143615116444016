import React, { memo, useState } from "react";
import styled from 'styled-components';
import { Grid, AppBar, Tab } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components
import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import Input from '@material-ui/core/Input';
import { StyledTabs } from './IOTRecipesView';
import { TabPanel } from './vnet/components/ResultPanel';
import {
    Paragraph,
    StyledButton,
    DemoContainer,
    LinkContainer,
    List,
    ListItem,
} from "../../../styles/common";
import Plot from '../../views/solutions/vnet/components/Plot';
import JsonDataTable from '../../common/JsonDataTable';

// user id
import getUserData, { getUploadToken } from '../../../utils';
import { SettingsBluetoothTwoTone } from "@material-ui/icons";


const AqueousSolubility = () => {

    const { postQueryResult } = CommonService('z1dApps','aqueoussolubility');
    const Token = getUploadToken()

    const { uploadFilesWithBody } = CommonService(
        'pyod1',
        'pyod1'
    );

    const [notification, setNotification] = useState('');
    const [showSpinner, setSpinner] = useState(false);

    const [userId, setUserId] = useState(getUserData()?.token?.emailid);

    // tab value
    const [Antvalue, setAntValue] = useState(0);

    // upload file
    const [selectedFile, setselectedFile] = useState('');
    const [FileName, setselectedFileName] = useState('');
    const[btnEnable, setBtnEnable] = useState(true)

    //data
    const [result1, setResult1] = useState('');
    const [result2, setResult2] = useState('');
    const [result3, setResult3] = useState('');
    const [path1, setPath1] = useState('');
    const [path2, setPath2] = useState('');
    const [graph, SetGraph] = useState('');

    const getUploadResult = () => {
        setResult1("");
        setResult2("");
        setResult3("");

        const current = new Date();
        const date = `${current.getDate()}/${current.getMonth() + 1
            }/${current.getFullYear()}`;

        if (!selectedFile) {
            setNotification({ open: 'error', message: 'please upload a csv file' });
            return;
        }
        setSpinner(true);
        uploadFilesWithBody('upload', {
            file: selectedFile,
            Context_param: JSON.stringify({
                Application_name: 'aqueous-solubility',
                UserID: userId,
                Execution_time: date,
            }),
            Content_param: JSON.stringify({ Dataset_Name: FileName }),
        })
            .then((res) => {
                setSpinner(false);
                setNotification({ open: 'success', message: res.Console });
                setResult1(res.Result);
                setPath1(res.Result.path)
            })
            .catch((err) => {
                setNotification({ open: 'error', message: err.message });
                setSpinner(false);
            });
    };

    const getUploadResult1 = () => {
        setResult2("");
        setResult3("");

        const current = new Date();
        const date = `${current.getDate()}/${current.getMonth() + 1
            }/${current.getFullYear()}`;

        setSpinner(true);
        postQueryResult('upload', {
            token: Token.token,
            Context_param: {
                Application_name: 'aqueous-solubility',
                UserID: userId,
                Execution_time: date,
            },
            Content_param: { s3Path: path1 },
        })
            .then((res) => {
                setSpinner(false);
                setNotification({ open: 'success', message: res.Console });
                setResult2(res.Result);
                setPath2(res.Result.path);
            })
            .catch((err) => {
                setNotification({ open: 'error', message: err.message });
                setSpinner(false);
            });
    };

    const getResult = () => {

        setResult3("");

        const current = new Date();
        const date = `${current.getDate()}/${current.getMonth() + 1
            }/${current.getFullYear()}`;

        setSpinner(true);

        postQueryResult('getsoln', {
            token:Token.token,
            Context_param: {
                Application_name: 'aqueous-solubility',
                Execution_time: date,
                UserID: userId,
            },
            Content_param: { s3Path: path2 },
        })
            .then((res) => {
                setNotification({ open: 'success', message: res.Console });
                setSpinner(false);
                SetGraph(res.Result.graphData);
                setResult3(res.Result);
            })
            .catch((err) => {
                setNotification({ open: 'error', message: err.message });
                setSpinner(false);
            });
    };

    const handleChange = (event, newValue) => {
        setAntValue(newValue);
    };

    const onChangeFile = (e) => {
        const file = e.target.files[0];
        const filename = e.target.files[0].name;
        if (file) {
            setselectedFile(file);
            setselectedFileName(filename);

            // setResponse('');
        } else {
            // setResponse('');
        }
        if (['csv'].indexOf(file?.name?.split('.')[1]?.toLowerCase()) == -1) {
            setNotification({ open: 'error', message: "please upload only csv format" });
            setselectedFile("");
            setselectedFileName("");
            setBtnEnable(true)
            return false;
        }
        setBtnEnable(false)

    };


    return (
        <SolutionContainer snackbar={notification}>
            <Collapse text="Description">
                <Paragraph>
                    <p>
                        Solubility is the property of a solid, liquid, or gaseous chemical substance called solute to dissolve in a solid, liquid, or gaseous solvent to form a homogeneous solution of the solute in the solvent. The solubility of a substance fundamentally depends on the solvent used as well as on temperature and pressure. The extent of solubility of a substance in a specific solvent is measured as the saturation concentration where adding more solute does not increase its concentration in the solution.
                    </p>
                    <p>
                        Aqueous solubility is one of the major physiochemical properties to be optimized in drug discovery. It is related to absorption and distribution in ADME-Tox (Absorption, Distribution, Metabolism, Excretion, and Toxicity). The aqueous solubility of a compound significantly affects its absorption and distribution characteristics. Typically, a low solubility goes along with a bad absorption and therefore the general aim is to avoid poorly soluble compounds. Our estimated logS value is a unit stripped logarithm (base 10) of the solubility measured in mol/liter. Aqueous solubility and membrane permeability are the two key factors that affect a drugs oral bioavailability. Because of the importance of aqueous solubility, a lot of efforts have been spent on developing reliable models to predict this physiochemical property.
                    </p>
                    <p>
                        Aqueous solubility is a function of both lipophilicity and crystal packing forces. In Yalkowsky 2001 paper he defined the General Solubility Equation (GSE).
                    </p>
                    <p><strong><center>logS = 0.5 - 0.01(MP – 25) - log Kow</center></strong></p>
                    <p>
                        Where logS is the aqueous solubility, MP is the melting point in degree celcius and log Kow is the log of the octanol-water partition coefficient (LogP) of the un-ionized species.
                    </p>
                    <p>
                        Here We need to predict solubility is predict LogP and melting point. Having a large body of experimental LogP data on drug-like compounds, and it appears to be quite capable of building reliable models. For predicting melting point, we don’t have reliable methods of predicting melting points from chemical structure. In order to efficiently predict melting point, we would probably have to know molecule stacks in a crystal structure. Some of the best crystal structure prediction methods can require months to generate a prediction for a single molecule. The problem is further compounded by the fact that molecules can be crystalized in different crystal forms, better known as polymorphs. These polymorphs can have dramatically different melting points and as a result, very different solubilities.
                    </p>
                    <p>
                        We can’t easily use the GSE to predict the solubility of new compounds where we don’t have the melting point. There is quite a bit of solubility data in the literature, most of which was originally curated by the Yalkowsky group in their AquaSol database. Several groups have used public datasets to generate models for aqueous solubility. As Example see the work of Delaney, Huuskonen and Jorgensen. These groups have used a wide variety of descriptors and modeling methods to generate models with impressive correlations.
                    </p>
                    <p>
                        ESOL (Estimated Solubility), which is a relatively simple empirical model for aqueous solubility published by Delaney in 2004, The model, which was built on literature data augmented by data collect at Syngenta, is represented by this relatively simple equation.
                    </p>
                    <p><strong><center>LogS = 0.16 - 0.63 cLogP – 0.0062 MW + 0.066 RB – 0.74 AP </center></strong></p>
                    <p>
                        To predict LogS(log of aqueous solubility), The study by Delaney makes use of 4molecular descriptors:
                    </p>
                    <List>
                        <ListItem>
                            <strong>
                                cLogP
                            </strong>
                            (Octanal-water partition coefficient)
                        </ListItem>
                        <ListItem>
                            <strong>
                                MW
                            </strong>
                            (Molecular weight)
                        </ListItem>
                        <ListItem>
                            <strong>
                                RB
                            </strong>
                            (Number of rotatable bonds)
                        </ListItem>
                        <ListItem>
                            <strong>
                                AP
                            </strong>
                        </ListItem>
                    </List>
                    <p><strong>Description for 4 Descriptor Values - </strong></p>
                    <p><strong>MolLogP - </strong>Molecular logp is the ratio between the solubility of a molecule in octanol and water, logP will be one of the descriptors to predict the logS (Solubility in Water). </p>
                    <p><strong>MolWt  - </strong>Molecular Weight is the summation of the individual atomic weight. </p>
                    <p><strong>NumRotatableBonds - </strong>Number of Rotatable Bonds in a molecule means how many bonds can be rotated (atoms will move) </p>
                    <p>Ex - double bonds can be rotated.</p>
                    <p><strong>AromaticProportion - </strong>Is the ratio between aromatic atoms and number of heavy atoms. AP (Aromatic proportion = number of aromatic atoms / numbers of heavy atoms)</p>
                    <p><center><strong>AP</strong>(Aromatic proportion = number of aromatic atoms / numbers of heavy atoms)</center></p>
                    <p><strong>What is SMILE -</strong></p>
                    <p><strong>SMILE (Simplified Molecular Input Entry Line System) </strong> essentially compressing the chemical structure information to a 1-dimensional form.</p>
                    <p>Ex.- F: Fluorine, C: Carbon, N: Nitrogen, O: Oxygen, = is Double bond, (): Parenthesis will tell us it is attached to a particular atom.</p>
                    <p>We compute the first 3. As for the AP descriptor, we will calculate this by manually computing the ratio of the number of aromatic atoms to the total number of heavy atoms.</p>
                    <p><strong>Analysis of solubility values -  </strong></p>
                    <p>Compounds can be classified according to solubility values (LogS) Compounds with 0 and higher solubility value are highly soluble, those in the range of 0 to -2 are soluble, those in the range of -2 to -4 are slightly soluble and insoluble if less than –4. </p>
                    <p><strong>Benefits Include - </strong></p>
                    <p>Dissolution of drug is the rate determining step for oral absorption of the poorly water-soluble drugs and solubility is the basic requirement for the absorption of the drug. The various techniques can be used to enhance the solubility of the drugs. Proper selection of solubility enhancement method is the key to ensure the goals of a good formulation like good oral bioavailability, reduce frequency of dosing and better patient compliance combined with a low cost of production. Selection of method for solubility enhancement depends upon drug characteristics like solubility, chemical nature, melting point, absorption site, physical nature, pharmacokinetic behavior and so forth, dosage form requirement like tablet or capsule formulation, strength, immediate, or modified release and so forth, and regulatory requirements like maximum daily dose of any excipients and/or drug, approved excipients, analytical accuracy and so forth. </p>
                    <p><strong>Application in drug discovery in development -  </strong></p>
                    <List>
                        <ListItem>
                            Compound characterization in during drug discovery and development.
                        </ListItem>
                        <ListItem>
                            <strong>For bioassay optimization  </strong>In recent years there has been a growing awareness of solubility related limitations on many aspects of drug discovery. Initially, the focus was on the role of compound solubility in drug absorption and pharmacokinetics. Recent concerns have extended this focus to the effect of compound solubility in biological assays. As a result, minimum solubility levels were recommended for candidates, based on their permeability and therapeutic dose, and fundamental properties, such as solubility, were regularly measured.
                        </ListItem>
                        <ListItem>
                            To avoid a bottleneck between lead optimization and the entry into human (EIH):
                            Due to patent protection to pharmaceutical compound the pharma industries try to reduce delay in entry of drug into the market. The initial selection of the lead compound is depending on the solubility characteristics to reduce the failure in subsequent stage of drug development.
                        </ListItem>
                        <ListItem>
                            Focus of solubility in different stages of the drug discovery and development.
                        </ListItem>
                        <ListItem>
                            Used in quality control and bioequivalence studies and preparation of biowaiver. The concept underlying the Biopharmaceutics Classification System (BCS) finally lead to introducing the possibility of waiving in vivo bioequivalence studies in favor of specific comparative in vitro testing in order to conclude bioequivalence of oral immediate release products with systemic actions. This approach is meant to reduce unnecessary in vivo bioequivalence studies however, is restricted to non-critical drug substances in terms of solubility, permeability, and therapeutic range, and to non-critical pharmaceutical forms.
                        </ListItem>
                    </List>
                    <p><strong>How To Use ChEMBL -  </strong></p>
                    <p>ChEMBL is a manually curated database of bioactive molecules with drug-like properties. Using The ChEMBL link you can go through the Compound ID which also consist molecular weight and structure of compound and SMILES data of each compound. </p>
                    <p><strong>Input of the use case: </strong> The input file is of .csv format consist of <strong>SMILES (Simplified Molecular-Input Line-Entry System)</strong> data, Compound/Chemical name, solubility(mol/l).</p>
                </Paragraph>
                <LinkContainer>
                    <Grid container spacing={2}>
                        <Grid item>
                            <StyledButton
                                variant="outlined"
                                color="primary"
                                size="large"
                                startIcon={<OpenInNewIcon />}
                            >
                                <a
                                    href="https://material.vlifevirtusa.com/AqueousSolubility/AqueousSolubility.html"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Notebook
                                </a>
                            </StyledButton>
                        </Grid>
                        <Grid item>
                            <StyledButton
                                variant="outlined"
                                color="primary"
                                size="large"
                                startIcon={<OpenInNewIcon />}
                            >
                                <a
                                    href="https://www.ebi.ac.uk/chembl/g/#search_results/all"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Sample Inputs
                                </a>
                            </StyledButton>
                        </Grid>
                    </Grid>
                </LinkContainer>
            </Collapse>

            <Collapse text="Demo">
                <DemoContainer>
                    <Grid
                        container
                        spacing={2}
                        direction='row'
                        justify='center'
                        alignItems='center'
                    >
                        <Grid item xs={12} sm={12} md={12}>
                            <Paragraph>Please upload your own dataset or download a sample dataset from the given link and upload.</Paragraph>
                        </Grid>
                        <Grid item xs={9} sm={7} md={4}>
                            <Input
                                type='file'
                                color='primary'
                                name='uploadedFile'
                                onChange={onChangeFile}
                            />
                        </Grid>
                        <Grid item xs={3} sm={5} md={3}>
                            <StyledButton
                                style={{ marginTop: '0' }}
                                helperText="Please select a csv file only"
                                variant='contained'
                                color='primary'
                                onClick={getUploadResult}
                                disabled={btnEnable}
                            >
                                Upload
                            </StyledButton>
                        </Grid>
                        <Grid item xs={10} sm={10} md={4}>
                            <a
                                href='https://material.vlifevirtusa.com/AqueousSolubility/sample/sample.csv'
                                download
                                rel='noreferrer'
                            >
                                Download a Sample file
                            </a>
                        </Grid>
                    </Grid>
                    {result1 && (
                        <section>
                            <br /><br />
                            <Grid
                                container
                                spacing={2}
                                xs={12}
                                direction='row'
                                justify='center'
                                alignItems='center'
                            >
                                <Grid item xs={12} sm={12} md={12}>
                                    <AppBar position='static' color='default'>
                                        <StyledTabs
                                            value={Antvalue}
                                            onChange={handleChange}
                                            aria-label='simple tabs example'
                                            fullWidth
                                            style={{
                                                background: 'black',
                                                color: 'white',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            <Tab label='Data Sample' />
                                            <Tab label='Data Statistics' />
                                            <Tab label='Data Overview' />
                                        </StyledTabs>
                                    </AppBar>
                                </Grid>
                            </Grid>
                            <TabPanel value={Antvalue} index={0}>
                                <Grid
                                    container
                                    spacing={2}
                                    xs={12}
                                    direction='row'
                                    justify='center'
                                    alignItems='center'
                                >
                                    <Grid item xs={12} sm={12} md={12}>
                                        <JsonDataTable
                                            fields={result1?.dfHead?.schema?.fields}
                                            dataRows={result1?.dfHead?.data}
                                        />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={Antvalue} index={1}>
                                <Grid
                                    container
                                    spacing={2}
                                    xs={12}
                                    direction='row'
                                    justify='center'
                                    alignItems='center'
                                >
                                    <Grid item xs={12} sm={12} md={12}>
                                        <JsonDataTable
                                            fields={result1?.dfDescribe?.schema?.fields}
                                            dataRows={result1?.dfDescribe?.data}
                                        />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value={Antvalue} index={2}>
                                <Grid
                                    container
                                    spacing={2}
                                    xs={12}
                                    direction='row'
                                    justify='center'
                                    alignItems='center'
                                >
                                    <Grid item xs={12} sm={12} md={12}>
                                        <OverViewList tyee='none'>
                                            {Object.entries(result1.dfOverview).map((data) => (
                                                <ListItem>
                                                    {' '}
                                                    <FontAwesomeIcon icon={faCheckCircle} />
                                                    {`${data[0]} : ${data[1]}`}
                                                </ListItem>
                                            ))}
                                        </OverViewList>
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <Grid
                                container
                                spacing={2}
                                xs={12}
                                direction='row'
                                justify='center'
                                alignItems='center'
                            >
                                <Grid item xs={12} sm={12} md={12}>
                                    <Paragraph>Using SMILES data from the given input dataset we will calculate the 4 descriptor values which are - MolLogP, MolWt, NumRotatableBonds, AromaticProportion which are further used to predict solubility.</Paragraph>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <StyledButton
                                        variant='contained'
                                        color='primary'
                                        onClick={getUploadResult1}
                                    >
                                        Execute
                                    </StyledButton>
                                </Grid>
                            </Grid>
                        </section>
                    )}
                    {result2 && (
                        <section>
                            <br /><br />
                            <Grid
                                container
                                spacing={2}
                                xs={12}
                                direction='row'
                                justify='center'
                                alignItems='center'
                            >
                                <Grid item xs={12} sm={12} md={12}>
                                    <TableContainers>
                                        <JsonDataTable
                                            fields={result2?.data?.schema?.fields}
                                            dataRows={result2?.data?.data}
                                        />
                                    </TableContainers>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Paragraph>Using the derived 4 descriptor values we will calculate LogS value (aqueous solubility) of each smile data provided to predict the solubility of molecules.</Paragraph>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <StyledButton
                                        variant='contained'
                                        color='primary'
                                        onClick={getResult}
                                    >
                                        Predict Solubility
                                    </StyledButton>
                                </Grid>
                            </Grid>
                        </section>
                    )}
                    {result3 && (
                        <section>
                            <br />
                            <Grid
                                container
                                spacing={2}
                                xs={12}
                                direction='row'
                                justify='center'
                                alignItems='center'
                            >
                                <Grid item xs={12} sm={12} md={12}>
                                    <TableContainers>
                                        <JsonDataTable
                                            fields={result3?.data?.schema?.fields}
                                            dataRows={result3?.data?.data}
                                        />
                                    </TableContainers>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Plot data={graph} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <strong>Scatter plot for LogS value</strong>
                                </Grid>
                            </Grid>
                        </section>
                    )}
                    {showSpinner && <Spinner text="Loading.. Please wait for a minute..." />}
                </DemoContainer>
            </Collapse>

        </SolutionContainer>
    );
};

export default memo(AqueousSolubility);

const TableContainers = styled.div`
     width: 100%;
     height: 450px;
     display: flex;
                `;
const OverViewList = styled(List)`
                list-style: none;
                .svg-inline--fa {
                    margin - right: 3px;
                color: #2a9d8f;
  }
                `;