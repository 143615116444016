import React, { useState } from "react";
import styled from "styled-components";
import SolutionContainerWrapper from "../../common/SolutionContainerWrapper";
import Collapse from "../../common/Collapse";
import {
  DemoContainer,
  Paragraph,
  StyledButton,
  Result,
  LinkContainer,
  CustomTextField,
} from "../../../styles/common";
import CommonService from "../../../utils/services/CommonService";
import getUserData from "../../../utils";
import Notification from "../../common/Notification";
import Spinner from "../../common/loading/Spinner";
import {
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import cbsImg from "../../../assets/images/solutions/cbs_architecture.webp";

export const ContextualSearchDemo = () => {
  const { uploadFilesWithBody } = CommonService(
    "z1dAppsv1",
    "contextualSearch"
  );

  const [userId, setUserId] = useState(getUserData()?.token?.emailid);
  const [notification, setNotification] = useState("");
  const [showSpinner, setSpinner] = useState(false);

  const initialData = {
    user_query: "",
    user_role: "",
  };

  const [radioValue, setRadioValue] = useState();
  const [radioName, setRadioName] = useState("");
  const [sampleField, setSampleField] = useState(initialData);
  const [result, setResult] = useState("");

  const handleOnChange = (e) => {
    setSampleField({
      ...sampleField,
      [e.target.name]: e.target.value,
    });
  };

  const handleRadioChange = (e) => {
    setRadioName(e.target.name);
    setRadioValue(e.target.value);
    setResult("")
  };

  const handleSample1 = (e) => {
    setResult("")
    setSampleField({
      ...sampleField,
      user_query: "What are the causes of swollen legs?",
      user_role: "Nephrologist",
    });
  };
  const handleSample2 = (e) => {
    setResult("")
    setSampleField({
      ...sampleField,
      user_query: "What are the causes of swollen legs?",
      user_role: "Cardiologist",
    });
  };

  const handleOnExecute = () => {
    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;

    setSpinner(true);
    setResult("");

    uploadFilesWithBody("search", {
      Context_param: JSON.stringify({
        app_name: "cbs",
        user_id: userId,
        Execution_time: date,
      }),
      Content_param: JSON.stringify({
        ...sampleField,
        search_content: radioValue,
      }),
    })
      .then((res) => {
        setSpinner(false);
        const response = res?.Result;
        console.log("search api response--->", response);
        setResult(response);
        setNotification({ open: "success", message: res?.Console });
      })
      .catch((err) => {
        setNotification({ open: "error", message: err.message });
        setSpinner(false);
      });
  };

  return (
    <SolutionContainerWrapper snackbar={notification}>
      <Collapse text="Demo">
        <DemoContainer>
        <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/ContextBasedSearch/ContextBasedSearch.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{marginTop:"10px"}}
          > 
            <Grid item xs={8} sm={5} md={6}>
              <TextField
                name="user_query"
                label="Query"
                variant="outlined"
                fullWidth
                value={sampleField?.user_query}
                onChange={handleOnChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={8} sm={4} md={3}>
              <TextField
                name="user_role"
                label="Role"
                variant="outlined"
                fullWidth
                value={sampleField?.user_role}
                onChange={handleOnChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={8} sm={3} md={3}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name={radioName}
                  value={radioValue}
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    value="web"
                    name="web"
                    control={<Radio />}
                    label="web"
                  />
                  <FormControlLabel
                    value="doc"
                    name="doc"
                    control={<Radio />}
                    label="doc"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              {" "}
            </Grid>
            <Grid item xs={8} sm={4} md={4}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={handleSample1}
              >
                Sample 1
              </StyledButton>
            </Grid>
            <Grid item xs={8} sm={4} md={4}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={handleSample2}
              >
                Sample 2
              </StyledButton>
            </Grid>

            <Grid item xs={8} sm={4} md={4}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={handleOnExecute}
                disabled={
                 ( sampleField == "") || radioValue == null
                }
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            {" "}
          </Grid>

          {result && (
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={12} md={12}>
                <Result>
                  <Paragraph>
                    {" "}
                    <strong>Result: </strong> <p>{result} </p>{" "}
                  </Paragraph>
                </Result>
              </Grid>
            </Grid>
          )}
          {showSpinner && <Spinner text="Loading..." />}
        </DemoContainer>
      </Collapse>
    </SolutionContainerWrapper>
  );
};
export default function ContextualSearchDesc() {
  return (
    <SolutionContainerWrapper>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Context-Based Search (CBS) is a search methodology that takes into
            consideration the context or specific circumstances surrounding a
            user's query to provide more relevant and accurate search results.
            Unlike traditional search engines that primarily rely on keywords,
            CBS analyzes the broader context of the search query, including user
            intent, location, preferences, and other contextual factors.
          </p>
          <p>
            <strong>How it is different from normal search? </strong>
            <ul>
              <li>
                <strong>Contextual Understanding: </strong> CBS goes beyond
                keyword matching and understands the context of a query. It
                takes into account the user's history, preferences, and the
                specific situation in which the search is conducted.
              </li>
              <li>
                <strong>Personalization: </strong> CBS tailors search results
                based on individual user profiles, offering a more personalized
                experience. It considers factors such as previous search
                behavior, location, and user preferences to refine results.
              </li>
              <li>
                <strong>Intent Recognition: </strong> CBS aims to understand the
                user's intent behind the search query, providing results that
                are more aligned with what the user is looking for in a given
                context.
              </li>
              <li>
                <strong>Dynamic Adaptation: </strong> CBS is dynamic and adapts
                to changes in user behavior and context over time. It
                continuously refines its understanding of context to improve the
                relevance of search results.
              </li>
            </ul>
          </p>
          <p>
            {" "}
            <strong>Our Approach – </strong>{" "}
          </p>
          <p>
            {" "}
            <img src={cbsImg} alt="CBS Architechture" />{" "}
          </p>
          <p>
            <ol>
              <li>
                A user initiates a query with the CBS Engine, seeking
                information or assistance.{" "}
              </li>
              <li>
                The query undergoes a personalized modification process,
                incorporating user-specific details to enhance relevance.{" "}
              </li>
              <li>
                Should the user opt for a 'Web' search, the adapted query is
                transmitted to the DuckDuckGo search API, leveraging its
                capabilities to retrieve web-based results.{" "}
              </li>
              <li>
                In the case of a 'Doc' search preference, the modified query
                takes a different route, heading towards a Vector DB enriched
                with pre-uploaded data chunks from PDF files. The system
                intelligently extracts the top three most pertinent passages
                related to the query, presenting them as results.{" "}
              </li>
              <li>
                The Vector DB serves as a repository for chunks of data
                previously uploaded by the administrator, ensuring a
                comprehensive and organized source for document-related
                information.{" "}
              </li>
              <li>
                The response derived from the preceding search method becomes
                pivotal as it forms the contextual foundation to enhance answer
                generation from the Large Language Model (LLM).{" "}
              </li>
              <li>
                The LLM prompt is meticulously crafted, incorporating: a. The
                top three relevant past queries and their responses from the
                Vector DB, functioning as historical data. b. User-specific
                details to tailor the response further. c. The response provided
                by the search method, serving as additional contextual input.{" "}
              </li>
              <li>
                If the input prompt size exceeds the LLM limit, the system
                employs the Map-Reduce method. This involves using two prompts:
                the initial one (map function) summarizes each data chunk, and a
                different prompt (combine or reduce function) combines these
                summaries into the final, coherent prompt.{" "}
              </li>
              <li>
                Alternatively, if the input prompt is within the LLM limit, the
                'stuff' method is employed. This straightforward approach passes
                the entire prompt directly to the LLM for processing.{" "}
              </li>
              <li>
                The resultant LLM-generated response, along with the original
                query, is stored in the Vector DB, enriching the historical data
                repository.{" "}
              </li>
              <li>
                Simultaneously, the LLM-generated response is delivered to the
                user, providing a tailored and informed answer based on the
                collaborative intelligence of the CBS Engine's search and
                language model capabilities.{" "}
              </li>
            </ol>
          </p>
        </Paragraph>
      </Collapse>
    </SolutionContainerWrapper>
  );
}
