import React, { memo, useState } from "react";
import styled from "styled-components";
import {
  Grid,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Grow from "@material-ui/core/Grow";
import Input from "@material-ui/core/Input";

// import local components
import CommonService from "../../../utils/services/CommonService";

import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  List,
  ListItem,
} from "../../../styles/common";
import SolutionContainer from "../../common/SolutionContainerWrapper";

// images
import prostateCancerDetection_sample1 from "../../../assets/images/app/prostate1.webp";
import prostateCancerDetection_sample2 from "../../../assets/images/app/prostate2.webp";
import prostateCancerDetection_sol1 from "../../../assets/images/app/prostate-1.webp";
import prostateCancerDetection_sol2 from "../../../assets/images/app/prostate-2.webp";
import prostateCancerDetection_sol3 from "../../../assets/images/app/prostate-3.webp";

const { getQueryResult, uploadFile } = CommonService("bioInformatics", "prostate_cancer");

const ProstateCancerDetection = () => {
  const [showSpinner, setSpinner] = useState(false);
  const [result, setResult] = useState("");
  const [uploadfile, setUploadfile] = useState("");
  const [notification, setNotification] = useState("");

  const getsampleResult = (e) => {
    setSpinner(true);
    setResult("");
    const selectedSample = e.currentTarget.getAttribute("data-sample");
    getQueryResult("sample", selectedSample)
      .then((res) => {
        setSpinner(false);
        setResult(res);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const getUploadResult = () => {
    setSpinner(true);
    setResult("");
    uploadFile("upload", uploadfile)
      .then((res) => {
        setSpinner(false);
        setResult(res);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const onChangeFile = (e) => {
    const file = e.currentTarget.files[0];
    setUploadfile(file);
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>This models detects PCa on images of prostate tissue samples, and estimate severity of the disease using the most extensive multi-center dataset on Gleason grading yet available.</p>
          <center>
            <Grid item xs={12} sm={8} md={8}>
              <img width="100%" src={prostateCancerDetection_sol1} alt="" />
            </Grid>
          </center>
          <p>Figure 1: An illustration of the Gleason grading process for an example biopsy containing prostate cancer. The most common (blue outline, Gleason pattern 3) and second most common (red outline, Gleason pattern 4) cancer growth patterns present in the biopsy dictate the Gleason score (3+4 for this biopsy), which in turn is converted into an ISUP grade (2 for this biopsy) following guidelines of the International Society of Urological Pathology. Biopsies not containing cancer are represented by an ISUP grade of 0</p>
          <strong>ISUP Score : </strong>
          <p>
            In 2014, the International Society of Urological Pathology released supplementary guidance and a revised prostate cancer grading system called the ISUP Grade Groups. The ISUP Grade Group system is simpler, with just five grades, 1 to 5.
            <br />
            <br />
            Your consultant may report your score either as a Gleason Score or an ISUP Grade Group, or you may receive both scores.
          </p>
          <center>
            <Grid item xs={6} sm={6} md={6}>
              <img width="100%" src={prostateCancerDetection_sol2} alt="" />
            </Grid>
          </center>
          <br />
          <br />
          <strong>Gleason Score</strong>
          <br />
          <p>This is given after a pathologist has examined under a microscope cancerous tissue obtained from the needle biopsy. The cells identified are given a grade number from 1 to 5, depending on the abnormality of the cells, 1 being the lowest, 5 the highest. The grades of the two most common patterns are added together to give a score from 2 to 10. The higher the score, the more aggressive and fast-growing the cancer. Scores totalling 5 or less are insignificant and are not reported.</p>
          <List>
            <ListItem>A Gleason score of 6 (cells are well differentiated) is ‘favourable’</ListItem>
            <ListItem>A Gleason score of 7 (cells are moderately differentiated) is ‘average’</ListItem>
            <ListItem>A Gleason Score of 8–10 (cells are poorly differentiated) is ‘adverse’</ListItem>
          </List>
          <center>
            <Grid item xs={6} sm={5} md={5}>
              <img width="100%" src={prostateCancerDetection_sol3} alt="" />
            </Grid>
          </center>
          <p>The consultant will give you a total score out of 10, which should be split down as two numbers out of 5: for example, 4+3. The first number is the predominant grade, so a score of 4+3=7, for example, is likely to prove slightly more aggressive than a score of 3+4=7.</p>

          <LinkContainer>
            <Grid container spacing={2}>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://www.kaggle.com/c/prostate-cancer-grade-assessment"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/ProstateCancerDetection/ProstateCancerDetection.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <br />
        <DemoContainer>
          <Grid container spacing={2} direction="row" justify="center" alignItems="center">
            <Grid item xs={10} sm={5} md={3}>
              <Image src={prostateCancerDetection_sample1} />
              <br />
              <StyledButton
                variant="contained"
                color="primary"
                data-sample="sample/"
                onClick={getsampleResult}
              >
                Sample1
              </StyledButton>
            </Grid>
            <Grid item xs={10} sm={5} md={3}>
              <Image src={prostateCancerDetection_sample2} />
              <br />
              <StyledButton
                variant="contained"
                color="primary"
                data-sample="sample1/"
                onClick={getsampleResult}
              >
                Sample2
              </StyledButton>
            </Grid>
            <Grid item xs={12} sm={12} md={1}>
              <h3>OR</h3>
            </Grid>
            <Grid item xs={9} sm={5} md={3}>
              <Input
                type="file"
                color="primary"
                name="uploadedFile"
                onChange={onChangeFile}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <StyledButton
                style={{ marginTop: "0" }}
                variant="contained"
                color="primary"
                onClick={getUploadResult}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
          <Grid container spacing={2} xs={12}>
            <Grid item xs={12} md={12}>
              <Grow in={result} timeout={1500}>
                <div>
                  <section>
                    <Result>
                      <strong>Result : </strong>
                      <h3>{result['RESULT:']}</h3>
                    </Result>
                    <StyledButton variant="outlined" color="primary">
                      <strong> ISUP GRADE : </strong>
                      {result['ISUP GRADE']}
                    </StyledButton>
                  </section>
                </div>
              </Grow>
            </Grid>
          </Grid>
          )}
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(ProstateCancerDetection);

const Image = styled.img`
  width: 200px;
  height: 200px;
  @media only screen and (max-width: 900px) {
    width: auto;
    max-width: 220px;
  }  @media only screen and (max-width: 450px) {
    width: auto;
    max-width: 250px;
  }
`;
