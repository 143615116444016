import React, { memo, useState, useEffect } from "react";
import { Grid, FormControl, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import DataSourceIcon from "@material-ui/icons/GetApp";
// local components
import Highcharts from "highcharts";
import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import CommonService from "../../../utils/services/CommonService";
import {
  CONCLUSION,
  COMPANY,
  COVERAGE,
  REASON,
  SUBREASON,
  DISPOSITION,
} from "../../../constants/InsuranceComplaints";
import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  HighchartContainer,
  Result,
} from "../../../styles/common";
import { getUploadToken } from "../../../utils";
const InsuranceComplaintsResolution = () => {
  const initialData = {
    company: "",
    coverage: "",
    reason: "",
    subReason: "",
    disposition: "",
    conclusion: "",
    recovery: "",
    openDate: "",
    closeDate: "",
  };
  useEffect(() => {
    setConclusionOptions(conclusionOptions);
    setCompanyOptions(companyOptions);
    setCoverageOptions(coverageOptions);
    setReasonOptions(reasonOptions);
    setSubReasonOptions(subReasonOptions);
    setDispositionOptions(dispositionOptions);
  }, []);
  const formatData = (obj) => Object.entries(obj).map((obj) => ({
    label: obj[0],
    value: obj[1].toString(),
  }));
  const cityList = [
    {
      name: "Jordantown",
      value: "317",
    },
    {
      name: "New Steve",
      value: "530",
    },
    {
      name: "Butlerfort",
      value: "61",
    },
    {
      name: "New Daniellefort",
      value: "487",
    },
    {
      name: "Angelhaven",
      value: "21",
    },
  ];
  const gender = [
    { name: "select", value: "Select Gender" },
    { name: "Female", value: 1 },
    { name: "Male", value: 0 },
  ];
  const [sampleData, setSampleData] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");
  const [companyOptions, setCompanyOptions] = useState(formatData(COMPANY));
  const [conclusionOptions, setConclusionOptions] = useState(formatData(CONCLUSION));
  const [coverageOptions, setCoverageOptions] = useState(formatData(COVERAGE));
  const [reasonOptions, setReasonOptions] = useState(formatData(REASON));
  const [subReasonOptions, setSubReasonOptions] = useState(formatData(SUBREASON));
  const [dispositionOptions, setDispositionOptions] = useState(formatData(DISPOSITION));
  const { getQueryResult } = CommonService(
    "payerProvider",
    "insuranceComplaints",
  );
  const Token = getUploadToken();
  const showResult = () => {
    const NewDataSet = {
      company: "American Commerce Insurance Company",
      coverage: "Auto Liability",
      reason: "Unfair Claims Practice",
      subReason: "Actual Cash Value Dispute",
      disposition: "Company Position Upheld",
      conclusion: "Cross Reference Only",
      recovery: "0.0",
      openDate: "2018-03-02",
      closeDate: "2018-03-16",
    };
    setSampleData(NewDataSet);
  };
  const showResult2 = () => {
    const NewDataSet = {
      company: "Trumbull Insurance Company",
      coverage: "Homeowners",
      reason: "Unfair Claims Practice",
      subReason: "Denial of Claim",
      disposition: "Contract Provision",
      conclusion: "Unjustified",
      recovery: "0.0",
      openDate: "2018-03-23",
      closeDate: "2018-04-04",
    };
    setSampleData(NewDataSet);
  };
  const getResult = () => {
    const {
      company,
      coverage,
      reason,
      subReason,
      disposition,
      conclusion,
      recovery,
      openDate,
      closeDate,
    } = sampleData;
    const params = `${COMPANY[company]},${COVERAGE[coverage]},${REASON[reason]},${SUBREASON[subReason]},${DISPOSITION[disposition]},${CONCLUSION[conclusion]},${recovery},${openDate},${closeDate},${Token.token}`;
    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });
    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);
      getQueryResult("output", params)
        .then((res) => {
          setSpinner(false);
          setResultData(res.result);
          const percentages = Object.entries(res.pro_col[0]);
          const params = [];
          percentages.map((arr) => {
            params.push({
              name: arr[0],
              y: arr[1],
            });
          });
          Highcharts.chart("prediction-chart", {
            chart: {
              plotShadow: false,
              type: "pie",
              options3d: {
                enabled: true,
                alpha: 45,
                beta: 0,
              },
            },
            title: {
              text: "MAJOR CONTRIBUTING FACTORS",
            },
            tooltip: {
              pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: "pointer",
                depth: 35,
                dataLabels: {
                  enabled: true,
                  format: "<b>{point.name}</b>: {point.percentage:.1f} %",
                },
              },
            },
            credits: {
              enabled: false,
            },
            series: [
              {
                colorByPoint: true,
                data: params,
              },
            ],
          });
        })
        .catch((err) => {
          setSpinner(false);
          console.log(err);
          setNotification({ open: "error", message: err.message });
        });
    }
  };
  const onChangeFields = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };
  const onChangeDropdowns = (name, newValue) => {
    setSampleData({
      ...sampleData,
      [name]: newValue,
    });
  };
  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Filing a complaint about health insurance can be a daunting process
            for consumers. Health stakeholders including health advocates,
            health care providers, and enrolment assisters can help consumers to
            file a complaint. It is the job of consumers to verify that health
            care providers and facilities are in-network. In-network health care
            providers have contracted with the insurer to accept certain
            negotiated (discounted) rates. Out-of-network providers have not
            agreed to discounted rates. However, the complexity of the health
            care system and emergency situations leave many consumers with
            unanticipated bills.
          </p>
          <p>
            Machine learning can help one determine the status of the Health
            insurance complaints. Hence In this Solution, a Predictive Model
            that aims to predict the status of Health Insurance Complains is
            built. Model is Multiclass Classification with a Logistic Regression
            algorithm embedded in sklearn Pipeline.
          </p>
          <p>
            Below are some of the Complain status which the ML Model can
            predict-
            <ul>
              <li>Closed</li>
              <li>Open</li>
              <li>Pre-Closed</li>
              <li>Re-Opened</li>
              <li>Do not Purge</li>
            </ul>
          </p>
          <LinkContainer>
            <Grid container spacing={2}>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<DataSourceIcon />}
                >
                  <a
                    href="https://www.kaggle.com/shashwatwork/insurance-company-complaints"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/Insurance_complains/insurance-complaint-prediction.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <section>
            <Grid
              container
              xs={12}
              direction="row"
              justify="center"
              spacing={3}
            >
              <Grid item xs={12} sm={4}>
                <FormControl minWidth="120" varient="outlined" fullWidth>
                  <Autocomplete
                    value={sampleData.company}
                    onChange={(e, val) => onChangeDropdowns('company', val)}
                    name="company"
                    disableClearable
                    id="company"
                    options={companyOptions.map((option) => option.label)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Company"
                        variant="outlined"
                        name="company"
                        data-id="company"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl minWidth="120" varient="outlined" fullWidth>
                  <Autocomplete
                    value={sampleData.conclusion}
                    onChange={(e, val) => onChangeDropdowns('conclusion', val)}
                    disableClearable
                    id="conclusion"
                    options={conclusionOptions.map((option) => option.label)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Conclusion"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl minWidth="120" varient="outlined" fullWidth>
                  <Autocomplete
                    value={sampleData.coverage}
                    onChange={(event, newValue) => {
                      onChangeDropdowns('coverage', newValue)
                    }}
                    disableClearable
                    id="controllable-states-demo"
                    options={coverageOptions.map((option) => option.label)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Coverage"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl minWidth="120" varient="outlined" fullWidth>
                  <Autocomplete
                    value={sampleData.reason}
                    onChange={(event, newValue) => {
                      onChangeDropdowns('reason', newValue)
                    }}
                    disableClearable
                    id="controllable-states-demo"
                    options={reasonOptions.map((option) => option.label)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Reason"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl minWidth="120" varient="outlined" fullWidth>
                  <Autocomplete
                    value={sampleData.subReason}
                    onChange={(event, newValue) => {
                      onChangeDropdowns('subReason', newValue)
                    }}
                    disableClearable
                    id="controllable-states-demo"
                    options={subReasonOptions.map((option) => option.label)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Sub Reason"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl minWidth="120" varient="outlined" fullWidth>
                  <Autocomplete
                    value={sampleData.disposition}
                    onChange={(event, newValue) => {
                      onChangeDropdowns('disposition', newValue)
                    }}
                    disableClearable
                    id="controllable-states-demo"
                    options={dispositionOptions.map((option) => option.label)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Disposition"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl minWidth="120" varient="outlined" fullWidth>
                  <TextField
                    id="recovery"
                    variant="outlined"
                    name="recovery"
                    label="Recovery"
                    value={sampleData.recovery}
                    onChange={onChangeFields}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl minWidth="120" varient="outlined" fullWidth>
                  <TextField
                    id="date"
                    label="Open Date"
                    variant="outlined"
                    type="date"
                    onChange={onChangeFields}
                    name="openDate"
                    value={sampleData.openDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl minWidth="120" varient="outlined" fullWidth>
                  <TextField
                    id="date"
                    label="Close Date"
                    variant="outlined"
                    type="date"
                    onChange={onChangeFields}
                    name="closeDate"
                    value={sampleData.closeDate}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={showResult}
                >
                  Sample1
                </StyledButton>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={showResult2}
                >
                  Sample2
                </StyledButton>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={getResult}
                >
                  Execute
                </StyledButton>
              </Grid>
            </Grid>
          </section>
          <br />
          <br />
          {showSpinner && <Spinner text="Loading..." />}
          {resultData && (
            <Result>
              <strong>Result:</strong>
              {resultData}
            </Result>
          )}
          <Grid item xs={12}>
            <HighchartContainer id="prediction-chart" display />
          </Grid>
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};
export default memo(InsuranceComplaintsResolution);