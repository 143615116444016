import React, { memo, useState } from "react";
import styled from "styled-components";
import {
  Grid,
  Typography,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Grow from "@material-ui/core/Grow";
import Input from "@material-ui/core/Input";
import Highcharts from "highcharts";
import Highcharts3d from "highcharts/highcharts-3d";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

// import local components
import CommonService from "../../../utils/services/CommonService";

import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  List,
  ListItem,
  HighchartContainer,
} from "../../../styles/common";
import SolutionContainer from "../../common/SolutionContainerWrapper";

// images
import skinCancerAnalysis_sample1 from "../../../assets/images/app/skinCancer.webp";

Highcharts3d(Highcharts);

const { getQueryResult, uploadFile } = CommonService("patientEngagementProduction", "skinCancerAnalysis");

const SkinCancerAnalysis = () => {
  const [showSpinner, setSpinner] = useState(false);
  const [result, setResult] = useState("");
  const [imageResult, setImageResult] = useState("");
  const [uploadfile, setUploadfile] = useState("");
  const [notification, setNotification] = useState("");

  const formHighChart = (datasplit) => {
    let i = 0;
    const dataset = [];
    for (i = 0; i < Object.keys(datasplit.Classes).length; i++) {
      dataset.push([datasplit.Classes[i], datasplit.Probability[i]]);
    }
    console.log(dataset);
    // code for highcharts bar implements
    Highcharts.chart("container", {
      chart: {
        renderTo: 'container',
        type: 'column',
        options3d: {
          enabled: true,
          alpha: 0,
          beta: 30,
          depth: 100,
          viewDistance: 25,
        },
      },
      title: {
        text: "Probability Distribution for Predicted Classes",
      },
      subtitle: {
        text: "Hover to Show Class Probability",
      },
      xAxis: {
        type: "category",
      },
      yAxis: {
        title: {
          text: "Probability",
        },
      },

      legend: {
        enabled: false,
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: "{point.y:.1f}%",
          },
        },
      },
      tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat:
          '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b><br/>',
      },
      series: [
        {
          name: "Percentage",
          colorByPoint: true,
          data: dataset,
        },
      ],
    });
  };

  const getsampleResult = (e) => {
    setSpinner(true);
    setResult("");
    getQueryResult("sample")
      .then((res) => {
        setSpinner(false);
        setResult(JSON.parse(res[0]));
        formHighChart(JSON.parse(res[0]));
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const getUploadResult = () => {
    setSpinner(true);
    setResult("");
    uploadFile("upload", uploadfile)
      .then((res) => {
        setSpinner(false);
        setResult(JSON.parse(res[0]));
        formHighChart(JSON.parse(res[0]));
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const onChangeFile = (e) => {
    const file = e.currentTarget.files[0];
    setUploadfile(file);
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>Skin cancer is the most common human malignancy, is primarily diagnosed visually, beginning with an initial clinical screening and followed potentially by dermoscopic analysis, a biopsy and histopathological examination. Automated classification of skin lesions using images is a challenging task owing to the fine-grained variability in the appearance of skin lesions.</p>
          <p>
            <strong>HAM10000 ("Human Against Machine with 10000 training images")</strong>
            {' '}
            dataset is used for analysis. It consists of 10015 dermatoscopic images which are released as a training set for academic machine learning purposes and are publicly available through the ISIC archive.
          </p>
          <p>It has 7 different classes of skin cancer which are listed below : </p>
          <List>
            <ListItem>Melanocytic nevi</ListItem>
            <ListItem>Melanoma</ListItem>
            <ListItem>Benign keratosis-like lesions</ListItem>
            <ListItem>Basal cell carcinoma</ListItem>
            <ListItem>Actinic keratoses</ListItem>
            <ListItem>Vascular lesions</ListItem>
            <ListItem>Dermatofibroma</ListItem>
          </List>
          <p>The Solution detects 7 different classes of skin cancer using Convolution Neural Network with keras and tensorflow in backend.</p>
          <LinkContainer>
            <Grid container spacing={2}>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://www.kaggle.com/datasets/kmader/skin-cancer-mnist-ham10000"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://github.com/Virtusa-vLife/Use-Cases/blob/master/Skin_cancer/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <br />
        <DemoContainer>
          <Grid container spacing={2} direction="row" justify="center" alignItems="center">
            <Grid item xs={10} sm={5} md={3}>
              <Grid container spacing={2} direction="row" justify="center" alignItems="center">
                <Grid item xs={12} sm={12} md={12}>
                  <Image src={skinCancerAnalysis_sample1} />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={getsampleResult}
                  >
                    Sample1
                  </StyledButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={1}>
              <h3>OR</h3>
            </Grid>
            <Grid item xs={9} sm={5} md={3}>
              <Input
                type="file"
                color="primary"
                name="uploadedFile"
                onChange={onChangeFile}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <StyledButton
                style={{ marginTop: "0" }}
                variant="contained"
                color="primary"
                onClick={getUploadResult}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
            <Grow in={result} timeout={1500}>
              <div>
                <section>
                  <Result><strong>Result : </strong></Result>
                </section>
                <br />
                <Grid container spacing={2} xs={12} justify="center" alignItems="center">
                  <Grid xs={10} sm={8} md={5}>
                    <Paper>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <StyledTableHead>
                              <h3>Classes</h3>
                            </StyledTableHead>
                            <StyledTableHead>
                              <h3>Probability</h3>
                            </StyledTableHead>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {["0", "1", "2", "3", "4", "5", "6"].map((value) => (
                            <TableRow>
                              <TableCell>{result.Classes[value]}</TableCell>
                              <TableCell>{result.Probability[value]}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6}>
                    <HighchartContainer
                      id="container"
                      display={result}
                    />
                  </Grid>
                </Grid>
              </div>
            </Grow>
          )}
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(SkinCancerAnalysis);

const Image = styled.img`
  width: 200px;
  height: 200px;
  @media only screen and (max-width: 900px) {
    width: auto;
    max-width: 220px;
  }  @media only screen and (max-width: 450px) {
    width: auto;
    max-width: 250px;
  }
`;
const StyledTableHead = styled(TableCell)`
  background-color: #3c40af;
  color: white;
  font-weight: bold;
`;
