import React, { memo, useState } from "react";
import styled from "styled-components";
import {
  Grid,
  Typography,
  Paper,
  TextField,
  AppBar,
  Tab,
  Tabs,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

// import local components
import Highcharts from "highcharts";
import CommonService from "../../../utils/services/CommonService";
import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  HighchartContainer,
} from "../../../styles/common";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import Spinner from "../../common/loading/Spinner";
import "react-html5-camera-photo/build/css/index.css";

// images
import faceEmotionRecognizer_sol1 from "../../../assets/images/app/faceEmotionRecognizer_sol1.webp";
import faceEmotionRecognizer_sample1 from "../../../assets/images/app/faceEmotionRecognizer_sample1.webp";
import faceEmotionRecognizer_sample2 from "../../../assets/images/app/faceEmotionRecognizer_sample2.webp";

function TabPanel(props) {
  const { children, value, index } = props;
  return (
    <div role="tabpanel" hidden={value !== index}>
      {children}
    </div>
  );
}

const FaceEmotionRecognizer = () => {
  const [showSpinner, setSpinner] = useState(false);
  const [uploadedFile, setUploadedFile] = useState("");
  const [notification, setNotification] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [videoResult, setVideoResult] = useState("");
  const [showChart, setShowChart] = useState(false);
  const [text, setText] = useState("");
  const [outputImage, setOutputImage] = useState("");

  const url = ["nonServiceLine", "faceEmotionRecognition"];
  const staticOutput = {
    sample1: {
      result: "Emotion type is happy",
      output: "https://vlife.virtusa.com/EmotionRecognition/output1_fer.jpg",
    },
    sample2: {
      result: "Emotion type is surprise",
      output: "https://vlife.virtusa.com/EmotionRecognition/output2_fer.jpg",
    },
  };

  const { uploadFile } = CommonService("z1dApps", "emotionDetection");

  const formHighchart = (data1) => {
    const seriesData = [];
    data1[0].forEach((data, index) => {
      seriesData.push({
        name: data1[0][index].split(0)[0],
        data: [data1[1][index]],
      });
    });
    Highcharts.chart("container", {
      chart: {
        type: "column",
      },
      title: {
        text: "Emotion Distribution",
      },
      xAxis: {
        categories: ["<b>Emotion Type</b>"],
        crosshair: true,
      },
      yAxis: {
        min: 0,
        title: {
          text: "<b>Percentage</b>",
        },
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
      series: seriesData,
    });
  };
  const showUploadOption = () => {
    setUploadedFile("");
  };

  const onChangeFields = (e) => {
    const value = e.target.files[0];
    console.log(value);
    setSpinner(true);
    setText("");
    setUploadedFile(URL.createObjectURL(value));
    setOutputImage("");
    uploadFile("uploadResult", value)
      .then((result) => {
        console.log(result);
        setText(result.result);
        setOutputImage(result.output);
        setSpinner(false);
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
      });
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getUploadResult = (e) => {
    const value = e.target.files[0];

    setSpinner(true);
    setText("");
    setVideoResult("");
    setShowChart(false);
    uploadFile("videoResult", value)
      .then((res) => {
        setSpinner(false);
        setVideoResult(res.result);
        setShowChart(true);
        formHighchart(res.highchart);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const getVideoResult = () => {
    setSpinner(true);
    setVideoResult("");
    setShowChart(false);
    const highchart = [
      ["angry0", "disgust0", "fear0", "neutral0", "sad0", "surprise0"],
      [4.4, 0.01, 15.82, 29.63, 33.91, 14.28, 1.95],
    ];
    setTimeout(() => {
      setSpinner(false);
      setVideoResult(
        "https://vlife.virtusa.com/EmotionRecognition/final_output__.mp4",
      );
      setShowChart(true);
      formHighchart(highchart);
    }, 5000);
  };

  const getImageResult = (sample) => {
    setSpinner(true);
    setText("");
    setShowChart(false);
    setOutputImage("");
    setTimeout(() => {
      setText(staticOutput[sample].result);
      setOutputImage(staticOutput[sample].output);
      setSpinner(false);
    }, 3000);
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Face Emotion Recognizer is an application to classify the
            expressions on face into various categories such as anger, fear,
            surprise, sadness, happiness and so on. Python library called FER is
            used to recognize human face expressions. Face Emotion Recognizer
            (FER) is implemented with a deep neural network using Tensorflow and
            Keras libraries in python. The tool takes input an image or a video
            and it then classifies each facial expression to an emotion based on
            the training done on human expressions with an overall accuracy &gt;
            99%.
          </p>
          <center>
            <img width="70%" src={faceEmotionRecognizer_sol1} alt="" />
          </center>
          <br />
          <p>
            <b>Applications:</b>
            {' '}
            The tool can be used in
            <b>remote health monitoring and virtual care</b>
            {' '}
            to identify the
            mental state of a person. It can be an initial application to filter
            out the patients remotely having some mental issues or not just by
            going through videos of their facial expressions.
          </p>
          <LinkContainer>
            <Grid container spacing={2}>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://analyticsindiamag.com/face-emotion-recognizer-in-6-lines-of-code/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/EmotionRecognizer/FaceEmotionRecognizer.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid item xs={12}>
            <AppBar position="static" color="primary">
              <Tabs
                value={tabValue}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab label="Image Expression" />
                <Tab label="Video Expression" />
              </Tabs>
            </AppBar>
          </Grid>
          <br />
          <TabPanel value={tabValue} index={1}>
            <Grid
              container
              xs={12}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid xs={12} md={5} alignItems="center">
                <video height="380px" width="380px" controls>
                  <source
                    src="https://vlife.virtusa.com/EmotionRecognition/emotion.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
                <br />
                <center>

                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={getVideoResult}
                  >
                    Sample Video
                  </StyledButton>
                </center>
              </Grid>
              <Grid item xs={12} md={2}>
                <h3>OR</h3>
              </Grid>
              <Grid item xs={12} md={5} alignItems="center">
                <TextField
                  type="file"
                  color="primary"
                  onChange={getUploadResult}
                //   helperText={fileNames}
                />
              </Grid>
            </Grid>
            <center>

              <section>{showSpinner && <Spinner text="Loading..." />}</section>
            </center>
            {videoResult && (
              <section>
                <section>
                  <Result>
                    <strong>Result:</strong>
                  </Result>
                  <center>
                    <video height="400px" width="400px" controls autoPlay>
                      <source
                        src={videoResult}
                        type="video/mp4"
                        autoPlay="autoplay"
                      />
                      Your browser does not support the video tag.
                    </video>
                    <br />
                    <Grid item xs={12} sm={6} justify="center">
                      <HighchartContainer id="container" display />
                    </Grid>
                  </center>
                </section>
              </section>
            )}
          </TabPanel>
          <TabPanel value={tabValue} index={0}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={12} md={4}>
                <Paper elevation={3}>
                  <MaskPaper>
                    <Image
                      src={faceEmotionRecognizer_sample1}

                    />
                    <br />
                    <StyledButton
                      variant="contained"
                      color="primary"
                      onClick={getImageResult.bind(this, "sample1")}
                    >
                      Sample 1
                    </StyledButton>
                  </MaskPaper>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper elevation={3}>
                  <MaskPaper>
                    <Image src={faceEmotionRecognizer_sample2} />
                    <br />
                    <StyledButton
                      variant="contained"
                      color="primary"
                      onClick={getImageResult.bind(this, "sample2")}
                    >
                      Sample 2
                    </StyledButton>
                  </MaskPaper>
                </Paper>
              </Grid>
              <Grid item xs={12} md={4}>
                <Paper elevation={3}>
                  <MaskPaper>
                    {uploadedFile ? (
                      <section>
                        <center>
                          <Image
                            style={{ width: "300px", height: "230px" }}
                            src={uploadedFile}
                            alt=""
                          />
                          <br />
                          <br />
                          <StyledButton
                            variant="contained"
                            color="primary"
                            onClick={showUploadOption}
                          >
                            Upload Different Image
                          </StyledButton>
                        </center>
                      </section>
                    ) : (
                      <section>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <center>
                          <Typography variant="h6">
                            <FontAwesomeIcon icon={faInfoCircle} />
                            {' '}
                            Upload Image
                          </Typography>

                          <HiddenInput
                            style={{ display: "none" }}
                            name="uploadedFile"
                            onChange={onChangeFields}
                            id="icon-button-file"
                            type="file"
                          />
                          <label
                            variant="contained"
                            color="primary"
                            htmlFor="icon-button-file"
                          >
                            <CloudUploadIcon
                              style={{ cursor: "pointer", marginTop: "20px" }}
                              fontSize="large"
                            />
                          </label>
                        </center>
                      </section>
                    )}
                  </MaskPaper>
                </Paper>
              </Grid>
              <section>{showSpinner && <Spinner text="Loading..." />}</section>
            </Grid>

            {text && (
              <section>
                <section>
                  <center>
                    <Result>
                      <strong>Result:</strong>
                      {text}
                    </Result>
                    {outputImage && (
                      <Image
                        width="400px"
                        height="350px"
                        src={outputImage}
                        alt=""
                      />
                    )}
                  </center>
                </section>
              </section>
            )}
          </TabPanel>
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(FaceEmotionRecognizer);
const MaskPaper = styled.div`
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px; ;

  @media only screen and (max-width:450px){
    padding-top: 0;
    height:auto;
    max-height: 300px;
    padding-bottom: 10px;
    .MuiTypography-root{
      font-size:1rem;
    }
  }
`;
const Image = styled.img`
  width: 100%;
  height:230px;
  max-height: 230px;
  max-width:300px;
`;
const UploadIcon = styled(CloudUploadIcon)`
  cursor: pointer;
  margin-top: 20px;
`;
const HiddenInput = styled.input`
  display: none;
`;
const StyledCaptureBTN = styled(StyledButton)`
  margin-top: 20px;
`;
