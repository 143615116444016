import React, { memo } from "react";
import { Grid } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components
import Collapse from "../../common/Collapse";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
    Paragraph,
    StyledButton,
    DemoContainer,
    LinkContainer,
    List,
    ListItem,
} from "../../../styles/common";

const ConVnet = () => {

    return (
        <SolutionContainer>
            <Collapse text="Description">
                <Paragraph>
                    <p>
                        Convolutional neural networks are a specialized type of artificial neural networks that use a mathematical operation called convolution in place of general matrix multiplication in at least one of their layers. They are specifically designed to process pixel data and are used in image recognition and processing.
                    </p>
                    <p>
                        A CNN is a neural network: an algorithm used to recognize patterns in data. Neural Networks in general are composed of a collection of neurons that are organized in layers, each with their own learnable weights and biases. Basic building blocks of CNN are:
                    </p>
                    <List>
                        <ListItem>
                            A <strong>tensor</strong> can be thought of as an n-dimensional matrix.
                        </ListItem>
                        <ListItem>
                            A <strong>neuron</strong> can be thought of as a function that takes in multiple inputs and yields a single output. The outputs of neurons below are represented above as the red → blue <strong>activation maps.</strong>
                        </ListItem>
                        <ListItem>
                            A <strong>layer</strong> is simply a collection of neurons with the same operation, including the same hyperparameters.
                        </ListItem>
                        <ListItem>
                            <strong>Kernel weights and biases</strong>, while unique to each neuron, are tuned during the training phase, and allow the classifier to adapt to the problem and dataset provided. They are encoded in the visualization with a yellow → green diverging color scale. The specific values can be viewed in the Interactive Formula View by clicking a neuron or by hovering over the kernel/bias in the Convolutional Elastic Explanation View.
                        </ListItem>
                        <ListItem>
                            A CNN conveys a <strong>differentiable score function</strong>, which is represented as <strong>class scores</strong> in the visualization on the output layer.
                        </ListItem>
                    </List>
                    <p>
                        CNNs utilize a special type of layer, a convolutional layer, that makes them well-positioned to learn from image and image-like data.  CNNs can be used for many different computer vision tasks, such as image processing, classification, segmentation, and object detection.
                    </p>
                    <p>
                        In CNN Explainer, we see how a simple CNN can be used for image classification. It uses TensorFlow.js, an in-browser GPU-accelerated deep learning library to load the pretrained model for visualization. The entire interactive system is written in Javascript using Svelte as a framework and D3.js for visualizations.
                    </p>
                </Paragraph>
                <LinkContainer>
                    <Grid container spacing={2}>

                        <Grid item>
                            <StyledButton
                                variant="outlined"
                                color="primary"
                                size="large"
                                startIcon={<OpenInNewIcon />}
                            >
                                <a
                                    href="https://poloclub.github.io/cnn-explainer/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Source
                                </a>
                            </StyledButton>
                        </Grid>
                    </Grid>
                </LinkContainer>
            </Collapse>

            <Collapse text="Demo">
                <DemoContainer>
                    <center>
                        <iframe src="https://poloclub.github.io/cnn-explainer/" width="100%" height="650"></iframe>
                    </center>
                </DemoContainer>
            </Collapse>

        </SolutionContainer>
    );
};

export default memo(ConVnet);
