import React, { memo, useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components

import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import CommonService from "../../../utils/services/CommonService";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";
import getUserData, { getUploadToken } from '../../../utils';

const GeneExpressionAnalysis = () => {
  // This is the initial state of the DOM
  const initialData = {
    M25079_s_at: "",
    hum_alu_at: "",
    X00437_s_at: "",
    M91036_rna1_at: "",
    D86974_at: "",
    X57351_s_at: "",
    M10098_5_at: "",
    D49824_s_at: "",
    D79205_at: "",
    HT4589_at: "",
    V00594_s_at: "",
    M84711_at: "",
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "bioInformatics",
    "geneExpressionAnalysis",
  );
  const Token = getUploadToken()

  const showResult = () => {
    const NewDataSet = {
      M25079_s_at: "0.144218452",
      hum_alu_at: "0.736470388",
      X00437_s_at: "0.183766453",
      M91036_rna1_at: "0.002024664",
      D86974_at: "0.176912923",
      X57351_s_at: "0.006162042",
      M10098_5_at: "0.026770458",
      D49824_s_at: "0.76522784",
      D79205_at: "0.601024474",
      HT4589_at: "0.388975783",
      V00594_s_at: "0.005650621",
      M84711_at: "0.563080919",
    };

    setSampleData(NewDataSet);
  };
  const showResult2 = () => {
    const NewDataSet = {
      M25079_s_at: "0.034679913768863056",
      hum_alu_at: "0.6638444520081688",
      X00437_s_at: "0.1304421194735066",
      M91036_rna1_at: "0.9999999999999999",
      D86974_at: "0.26232887886922857",
      X57351_s_at: "0.6051920171742069",
      M10098_5_at: "0.05050313271311942",
      D49824_s_at: "0.29020704316480267",
      D79205_at: "0.07973819009675587",
      HT4589_at: "0.2941588911061971",
      V00594_s_at: "0.03382376459299536",
      M84711_at: "0.5787998906506288",
    };
    setSampleData(NewDataSet);
    setResultData("");
  };

  const getResult = () => {
    const {
      M25079_s_at,
      hum_alu_at,
      X00437_s_at,
      M91036_rna1_at,
      D86974_at,
      X57351_s_at,
      M10098_5_at,
      D49824_s_at,
      D79205_at,
      HT4589_at,
      V00594_s_at,
      M84711_at,
    } = sampleData;

    const params = `${M25079_s_at},${hum_alu_at},${X00437_s_at},${M91036_rna1_at},${D86974_at},${X57351_s_at},${M10098_5_at},${D49824_s_at},${D79205_at},${HT4589_at},${V00594_s_at},${M84711_at},${Token.token}`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Field(s) are empty" });
    } else {
      setSpinner(true);

      getQueryResult("output", params)
        .then((res) => {
          setSpinner(false);
          setResultData(res);
        })
        .catch((err) => {
          setSpinner(false);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const handleInputs = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Cancer starts when cells in a part of the body begin to grow out of
            control. There are many kinds of cancer.
          </p>
          <b>What is Leukemias? </b>
          <p>
            Leukemias are cancers that start in cells that would normally
            develop into different types of blood cells. Most often, leukemia
            starts in early forms of white blood cells, but some leukemias start
            in other blood cell types.
          </p>
          <p>
            There are several types of leukemia, which are divided based mainly
            on whether the leukemia is acute (fast growing) or chronic (slower
            growing), and whether it starts in myeloid cells or lymphoid cells.
          </p>
          <p>
            <b>Acute myeloid leukaemia (AML): </b>
            {' '}
            It starts in the bone marrow
            (the soft inner part of certain bones, where new blood cells are
            made), but most often it quickly moves into the blood, as well. It
            can sometimes spread to other parts of the body including the lymph
            nodes, liver, spleen, central nervous system (brain and spinal
            cord), and testicles.
          </p>
          <p>
            About 370 people are diagnosed each year. Of these, more than 200
            are children under 15. ALL mostly occurs in children 1–4 years old.
            Chances of recovering from ALL are high as compared to AML
          </p>
          <p>
            <b>Acute lymphoblastic leukaemia (ALL): </b>
            {' '}
            Acute lymphoblastic
            leukemia (also called ALL) is a cancer of blood cells. It affects a
            type of white blood cell called a lymphocyte. ALL is also known as
            lymphoblastic lymphoma when the disease primarily involves lymph
            nodes rather than the blood and bone marrow. "Acute" means that it
            develops and advances quickly, and requires urgent treatment.
          </p>
          <p>
            About 370 people are diagnosed each year. Of these, more than 200
            are children under 15. ALL mostly occurs in children 1–4 years old.
            Chances of recovering from ALL are high as compared to AML
          </p>
          <p>
            The application shows how new cases of cancer could be classified by
            gene expression monitoring (via DNA microarray) and thereby provides
            a general approach for identifying new cancer classes and assigning
            tumors to known classes. These data were used to classify patients
            with acute myeloid leukemia (AML) and acute lymphoblastic leukemia
            (ALL).
          </p>
          <p>
            <strong>Data: </strong>
            There are three datasets, two of which
            contain the initial (training, 38 samples) and independent (test, 34
            samples) datasets and they are having information about gene
            expression for individual patients. Third dataset classifies
            patients in AML and ALL. These datasets contain measurements
            corresponding to ALL and AML samples from Bone Marrow and Peripheral
            Blood. Intensity values have been re-scaled such that overall
            intensities for each chip are equivalent.
          </p>
          <p>
            <strong>Method: </strong>
            The train and test samples contain 7129
            gene expressions as features. Principal component analysis (PCA) is
            used to reduce the dimensionality of the sample and reduced it to
            only 12 features without losing much information for model building.
            The primary benefit of using PCA is that we can still classify
            patients into different classes using substantially less number of
            features which very much helps for analysing the results.
            Subsequently, logistic regression modelling technique is used to
            classify test sample in AML or ALL.
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

          <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.kaggle.com/datasets/crawford/gene-expression"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Data source
                </a>
              </StyledButton>
            </Grid>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/Gene+Expressions/Gene+Expression+Analysis.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>

          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container direction="row" justify="center" spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="M25079_s_at"
                name="M25079_s_at"
                value={sampleData.M25079_s_at}
                onChange={handleInputs}
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="hum_alu_at"
                name="hum_alu_at"
                value={sampleData.hum_alu_at}
                onChange={handleInputs}
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="X00437_s_at"
                name="X00437_s_at"
                value={sampleData.X00437_s_at}
                onChange={handleInputs}
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="M91036_rna1_at"
                name="M91036_rna1_at"
                value={sampleData.M91036_rna1_at}
                onChange={handleInputs}
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="D86974_at"
                name="D86974_at"
                value={sampleData.D86974_at}
                onChange={handleInputs}
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="X57351_s_at"
                name="X57351_s_at"
                value={sampleData.X57351_s_at}
                onChange={handleInputs}
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="AFFX_HUMRGE /M10098_5_at"
                name="M10098_5_at"
                value={sampleData.M10098_5_at}
                onChange={handleInputs}
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="D49824_s_at"
                name="D49824_s_at"
                value={sampleData.D49824_s_at}
                onChange={handleInputs}
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="D79205_at"
                name="D79205_at"
                value={sampleData.D79205_at}
                onChange={handleInputs}
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="HG4319-HT4589_at"
                name="HT4589_at"
                value={sampleData.HT4589_at}
                onChange={handleInputs}
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="V00594_s_at"
                name="V00594_s_at"
                value={sampleData.V00594_s_at}
                onChange={handleInputs}
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="M84711_at"
                name="M84711_at"
                value={sampleData.M84711_at}
                onChange={handleInputs}
                type="number"
              />
            </Grid>

            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={showResult}
              >
                Sample 1
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={showResult2}
              >
                Sample 2
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={getResult}
              >
                Submit
              </StyledButton>
            </Grid>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {resultData && (
            <Result>
              <strong>Result:</strong>
              {Object.entries(resultData)}
            </Result>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(GeneExpressionAnalysis);
