import React, { memo, useState } from "react";
import styled from "styled-components";
import { Grid } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components
import Collapse from "../../common/Collapse";

import {
  Paragraph,
  StyledButton,
  DemoContainer,
  SolutionContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";

import shoulderImplant_sol1 from "../../../assets/images/app/shoulderImplant.webp";

const ShoulderImplantTracker = () => {
  const [videoResult, setVideo] = useState(false);
  const [imgResult, setImage] = useState(false);

  const showImage = () => {
    setImage(true);
    setVideo(false);
  };
  const showVideo = () => {
    setImage(false);
    setVideo(true);
  };

  return (
    <SolutionContainer>
      <Collapse text="Description">
        <Paragraph>
          Total Shoulder Arthroplasty (TSA) is a type of surgery in which the
          damaged ball of the shoulder is replaced with a prosthesis. In the US,
          the most commonly used types of shoulder prostheses are
          <strong>1st generation prostheses</strong>
          (Monoblock, non-modular),
          <strong> 2nd generation prostheses</strong>
          (modular),
          <strong> 3rd generation prostheses</strong>
          (modular and adaptable variable version, offset and inclination), and
          <strong> 4th generation prostheses</strong>
          (modular and in situ adjustability).
        </Paragraph>
        <Paragraph>
          This application detects the prosthesis in an X-ray image or video by
          using the YOLO model. The output image consists of the detected
          shoulder implant with a boundary box around it for easy
          identification. This can help in further identifying the specific type
          of shoulder prosthesis used, in cases where a follow-up or a revision
          arthroplasty is required.
        </Paragraph>
        <strong> Dataset: </strong>
        <Paragraph>
          Images were collected by Maya Stark at BIDAL Lab at SFSU for her MS
          thesis project. They are from The UW Shoulder Site, manufacturer
          websites, and Feeley Lab at UCSF. The original collection included 605
          X-ray images. Eight images that appeared to have been taken from the
          same patients were removed, resulting in the final 597 images. The
          images are in 8-bit grayscale jpeg format with varying dimensions.
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/Shoulder_implant/implant.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://archive.ics.uci.edu/ml/datasets/Shoulder+Implant+X-Ray+Manufacturer+Classification#"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Dataset
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid
            container
            xs={12}
            direction="row"
            justify="space-around"
            alignItems="center"
            alignContent="center"
          >
            <Grid xs={12} sm={6} md={3}>
              <StyledFigure>
                <video height="200px" width="200px" controls>
                  <source
                    src="https://vlife.virtusa.com/shoulder_implant/sample_video_output/input.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={showVideo}
                >
                  Sample Video
                </StyledButton>
              </StyledFigure>
            </Grid>
            <Grid xs={12} sm={6} md={3}>
              <StyledFigure>
                <Image
                  src={shoulderImplant_sol1}
                  alt=""
                />
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={showImage}
                >
                  Sample Image
                </StyledButton>
              </StyledFigure>
            </Grid>
          </Grid>
          {imgResult && (
            <Grid item xs={12}>
              <Result>
                <strong>Result: </strong>
                The detected shoulder prosthesis is shown below
              </Result>
              <ResultImage
                src="https://vlife.virtusa.com/shoulder_implant/sample_image_output/0.jpg"
                alt=""
              />
            </Grid>
          )}
          {videoResult && (
            <Grid item xs={12}>
              <Result>
                <strong>Result: </strong>
                The detected shoulder prosthesis is shown below
              </Result>
              <ResultVideo controls>
                <source
                  src="https://vlife.virtusa.com/shoulder_implant/sample_video_output/output.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </ResultVideo>
            </Grid>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};
export default memo(ShoulderImplantTracker);
const StyledFigure = styled.figure`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const Image = styled.img`
  width: 200px;
  height: 200px;
  @media only screen and (max-width: 900px) {
    width: auto;
    max-width: 200px;
    min-width: 200px;
  }  @media only screen and (max-width: 450px) {
    width: auto;
    max-width: 200px;
    min-width: 200px;
  }
`;
const ResultImage = styled.img`
height: 400px;
width: 550px;
  @media only screen and (max-width: 900px) {
    width: auto;
    max-width: 450px;
  }  @media only screen and (max-width: 450px) {
    width: auto;
    height: auto;
    max-width: 300px;
    min-width: 250px;
    max-height: 300px;
  }
`;
const ResultVideo = styled.video`
height: 400px;
width:400px;
  @media only screen and (max-width: 900px) {
    width: auto;
    max-width: 400px;
  }  @media only screen and (max-width: 450px) {
    width: auto;
    height: auto;
    max-width: 250px;
    min-width: 250px;
    max-height: 250px;
  }
`;
