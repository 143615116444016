import React, { memo, useState } from "react";
import {
  Grid, Table, RadioGroup, Tabs, TableHead, TableRow, TableBody, TableCell, Paper, AppBar, Tab, Radio, FormControlLabel
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// import local components

import styled from "styled-components";
import LazyHero from "react-lazy-hero";
import Collapse from "../../common/Collapse";
import {
  StyledButton,
  SolutionContainer,
  LinkContainer,
  Paragraph,
} from "../../../styles/common";
import CommonService from "../../../utils/services/CommonService";
import { TabPanel } from "./JakInhibitors";
import { StyledTabs } from "./ColbertAnalysis";
import getUserData, { getUploadToken } from "../../../utils";
import Spinner from "../../common/loading/Spinner";
import JsonDataTable from "../../common/JsonDataTable";

// images
import multimodal_sol1 from "../../../assets/images/app/multimodal_sol1.webp";
import multimodal_sol2 from "../../../assets/images/app/multimodal_sol2.webp";
import multimodal_sol3 from "../../../assets/images/app/multimodal_sol3.webp";
import multimodal_sample1 from "../../../assets/images/app/multimodal_sample1.webp";
import multimodal_sample2 from "../../../assets/images/app/multimodal_sample2.webp";
import multimodal_sample3 from "../../../assets/images/app/multimodal_sample3.webp";
import citeseq_sample1 from "../../../assets/images/app/citeseq_sample1.webp";
import citeseq_sample2 from "../../../assets/images/app/citeseq_sample2.webp";
import citeseq_sample3 from "../../../assets/images/app/citeseq_sample3.webp";

const { postQueryResult } = CommonService(
  "z1dApps",
  "multimodalSingleCell",
);
const Token = getUploadToken();

const MultimodalSingleCellIntegration = () => {
  const [showSpinner, setSpinner] = useState(false);
  const [metaData, setMetaData] = useState({
    tabName: "multiome",
    datasetName: "",
    action: ""
  });
  const [notification, setNotification] = useState("");
  const [preProcessingResult, setPreProcessingResult] = useState({
    multiome: "",
    citeseq: ""
  });
  const [executeResult, setExecuteResult] = useState({
    multiome: "",
    citeseq: ""
  });
  const [tabVal, setTabVal] = useState(0);
  const [radioValue, setRadioValue] = useState('');
  const [Value, setValue] = useState('');
  const [images, setImages] = useState('');
  const [userId, setUserId] = useState(getUserData()?.token?.emailid);

  const handleTabChange = (event, newValue) => {

    setTabVal(newValue);
    setRadioValue('');
    setExecuteResult({});
    setPreProcessingResult({});
    setValue("");
    setMetaData({
      ...metaData,
      tabName: newValue == 0 ? 'multiome' : 'citeseq'
    })
  };

  const handleRadioChange = (e) => {

    setRadioValue(e.target.value);
    setValue("");
    setSpinner(true);

    setTimeout(() => {
      setSpinner(false);
      setValue(e.target.value);
      setImages(e.target.name);
      setMetaData({
        ...metaData,
        datasetName: e.target.value
      })
    }, 1000);
  };

  const commonPostReq = async (contentData = {}) => {
    let params = {
      token: Token.token,
      "Context_param": {
        "userID": userId,
        "application_name": "multimodal_single_cell_integration"
      },
      "Content_param": {
        ...contentData
      }
    }
    try {
      let result = await postQueryResult("sample", params);
      setSpinner(false);
      if (result.Error_Flag) {
        setNotification({ open: "error", message: result.Error_UI });
      } else {
        setNotification({ open: "success", message: result.Console });
        return result;
      }
    }
    catch (err) {
      setSpinner(false);
      setNotification({ open: "error", message: err?.message });
      return false;
    }
  }
  const getPreprocessingResult = async () => {
    setSpinner(true);
    let contentData = {
      "datasetName": metaData.datasetName,
      "type": metaData.tabName,
      "action": "preprocessing"
    }
    let res = await commonPostReq(contentData);
    if (res) {
      setPreProcessingResult({
        ...preProcessingResult,
        [metaData.tabName]: res.df && JSON.parse(res.df)
      })
    }

  };
  const getOutputResult = async () => {
    setSpinner(true);
    let contentData = {
      "datasetName": metaData.datasetName,
      "type": metaData.tabName,
      "action": "output"
    }
    let res = await commonPostReq(contentData);
    if (res) {
      let df = JSON.parse(res.df);
      setExecuteResult({
        ...executeResult,
        [metaData.tabName]: df
      })
    }

  };


  return (
    <SolutionContainer snackbar={notification}>
      <section>
        <div>
          <Collapse text="Description">
            <Grid container xs={12} direction="row">
              <Grid item xs={12} md={12}>
                <Paragraph>
                  <p>
                    The recent advanced technologies allowed to study biology at a minute scale. Some of the major outcomes are study of early embryonic development, the discovery of new disease-associated cell types, and cell-targeted therapeutic interventions.
                  </p>
                  <p>
                    Genetic information flows from DNA to RNA to proteins. DNA must be accessible (ATAC data) to produce RNA (GEX data), and RNA in turn is used as a template to produce protein (ADT data). These processes are regulated by feedback: for example, a protein may bind DNA to prevent the production of more RNA. This genetic regulation is the foundation for dynamic cellular processes that allow organisms to develop and adapt to changing environments. Proteins are responsible for carrying out biological functions in the cell, such as metabolizing glucose to create energy for the cell .
                  </p>
                  <center><img width="80%" src={multimodal_sol1} /></center>
                  <p>
                    <b>1. Multiome: Prediction of Gene Expression (RNA) from DNA (chromatin accessibility).</b>
                    <br />
                    <b>Data:</b> This is generated using 10X Genomics Single-Cell Multiome ATAC + Gene Expression kit(Multiome Kit) which measures the chromatin accessibility and RNA expression. When biological cells are fed to this kit, this will generate the chromatin accessibility and gene expressions.
                    <br />
                    The ATAC-seq peak counts transformed with TF-IDF using the default log(TF) * log(IDF) output (chromatin accessibility), with rows corresponding to cells and columns corresponding to the location of the genome whose level of accessibility is measured
                    <br />
                    The RNA gene expression levels are library-size normalized and log1p-transformed counts for the same cells.
                  </p>
                  <p><b>2. CITESeq: Prediction of Protein from Gene expression(RNA)</b>
                    <br />
                    <b>Data:</b> This data is generated using 10x Genomics Single Cell Gene Expression with Feature Barcoding technology which measures the gene expression and Cell Surface Protein. When biological cells are fed to this kit, this will generate the gene expression and cell surface protein.
                    <br />
                    The library-size normalized and log1p transformed counts of RNA (gene expression levels), with rows corresponding to cells and columns corresponding to genes are given by {`{gene_name}_{gene_ensemble-ids}`}
                    <br />The surface protein levels for the same cells have been dsb normalized.
                  </p>
                  <h3>Terminology:</h3>
                  <ul>
                    <li><b>library-size normalized:</b> Normalize each cell by total counts over all genes, so that every cell has the same total count after normalization. For each cell it will sum up the data and takes the median of it and assign it to all cells.</li>
                    <li><b>log1p transformed:</b> Computes X=log(X+1), where log denotes the natural logarithm unless a different base is given.</li>
                    <li><b>dsb normalized:</b> It is denoised and scaled by background. DSB normalization improves separation between positive and negative populations for each protein, centers the negative-staining population around zero, and can improve unbiased protein expression-based clustering.</li>
                    <li><b>chromatin accessibility:</b> It is level of physical force applied on chromatin which is a complex formed by DNA and associated proteins.</li>
                    <li><b>Gene expression:</b> It is the process by which a gene gets turned on in a cell to make RNA and proteins.</li>
                    <li><b>Protein abundance:</b> It is the number of copies of a protein molecules in a cell.</li>
                  </ul>
                  <h3>Further Implementations:</h3>
                  <p>Researchers can make use of AI models to better understand the individual response to a particular treatment and, as a result, create more personalized treatments. This can also help in identifying underlying genetic factors for certain diseases by looking for genetic patterns amongst people with similar medical issues. Further it can identify the primary kind of cancer from liquid biopsy which tests for pieces of DNA from tumor cells that are in the blood. The usage of deep learning helps to improve the function of gene editing tools such as CRISPR which edits genes by precisely cutting DNA and then letting natural DNA repair processes to take over. We can even determine the age and biological sex prediction by human RNA sequence data, if any blood samples collected from anonymous donors.</p>

                </Paragraph>
              </Grid>
            </Grid>
            <LinkContainer>
              <Grid container spacing={2}>
                <Grid item>
                  <StyledButton
                    variant="outlined"
                    color="primary"
                    size="large"
                    startIcon={<OpenInNewIcon />}
                  >
                    <a
                      href="https://www.kaggle.com/competitions/open-problems-multimodal/data"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Dataset
                    </a>
                  </StyledButton>
                </Grid>
                <Grid item>
                  <StyledButton
                    variant="outlined"
                    color="primary"
                    size="large"
                    startIcon={<OpenInNewIcon />}
                  >
                    <a
                      href="https://www.kaggle.com/code/ambrosm/msci-multiome-quickstart"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Reference 1
                    </a>
                  </StyledButton>
                </Grid>
                <Grid item>
                  <StyledButton
                    variant="outlined"
                    color="primary"
                    size="large"
                    startIcon={<OpenInNewIcon />}
                  >
                    <a
                      href="https://www.kaggle.com/code/ambrosm/msci-citeseq-keras-quickstart"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Reference 2
                    </a>
                  </StyledButton>
                </Grid>
                <Grid item>
                  <StyledButton
                    variant="outlined"
                    color="primary"
                    size="large"
                    startIcon={<OpenInNewIcon />}
                  >
                    <a
                      href="https://material.vlifevirtusa.com/multimodal_single_cell_integration/Multimodal_Single_Cell_Integration_data_dictionary.xlsx"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Data Dictionary
                    </a>
                  </StyledButton>
                </Grid>
              </Grid>
            </LinkContainer>
          </Collapse>
          <Collapse text="Demo">
            <br />
            <AppBar position='static' color='default'>
              <StyledTabs
                value={tabVal}
                onChange={handleTabChange}
                variant='fullWidth'
                aria-label='simple tabs example'
              >
                <Tab label='Multiome (DNA to RNA conversion)' />
                <Tab label='CITESeq(RNA to Protein conversion)' />
              </StyledTabs>
              <TabPanel value={tabVal} index={0}>
                <Grid container>
                  <Grid item xs={6}>
                    <p>Predicting gene expression when chromatin accessibility is provided</p>
                    <p><b>chromatin accessibility:</b> It is level of physical force applied on chromatin which is a complex formed by DNA and associated proteins.</p>
                    <p><b>Gene expression:</b> It is the process by which a gene gets turned on in a cell to make RNA and proteins.</p>
                    <p><b>Please select one among the three sample datasets provided.</b> These have chromatin accessibility levels, which are measured using Multiome Kit and data in hdf5 file format.</p>
                    <RadioGroup
                      aria-labelledby='demo-controlled-radio-buttons-group'
                      value={radioValue}
                      onChange={handleRadioChange}
                      column
                    >
                      <FormControlLabel
                        value='sample1'
                        name={multimodal_sample1}
                        control={<Radio />}
                        label='sample1'

                      />
                      <FormControlLabel
                        value='sample2'
                        name={multimodal_sample2}
                        control={<Radio />}
                        label='sample2'
                      />
                      <FormControlLabel
                        value='sample3'
                        name={multimodal_sample3}
                        control={<Radio />}
                        label='sample3'
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={6}>
                    <center><img width="90%" src={multimodal_sol2} /></center>
                  </Grid>
                </Grid>

                {Value && <section>
                  <img width="100%" src={images} />
                  <p>The first step is to pre-process the data sample. This involves the following steps:</p>
                  <ul>
                    <li>The training data consists of around 2,28,942 features, taken from the 10X Genomics Single-Cell Multiome ATAC. Using the Gene Expression kit(Multiome Kit), the chromatin accessibility and RNA expression can be measured.</li>
                    <li>Out of the whole sample, 4000 features that have high correlation have been considered in this process</li>
                    <li>Finally, PCA technique has been applied to reduce dimensionality</li>
                  </ul>
                  <p>Please click on preprocessing button to proceed further.</p>
                  <center> <StyledButton variant='contained' color='primary' onClick={getPreprocessingResult}>Preprocessing</StyledButton></center>

                  {preProcessingResult.multiome &&
                    <section>
                      <p>The following are the features that have been obtained after performing PCA on the data. The data has been optimized to be contained within four principal components. </p>
                      
                      <center>  <JsonDataTable fields={preProcessingResult.multiome?.schema?.fields} dataRows={preProcessingResult.multiome?.data} /></center>
                      
                      <p>The next step is to predict RNA sequence values from the initial DNA sequence inputs.</p>
                      <ul>
                        <li>The gene expression from the target data has been measured by making use of 10X Genomics Single-Cell Multiome ATAC and the Gene Expression kit(Multiome Kit). The gene expression outcome from the kit is library - size normalized and log1p-transformed counts for the same cells </li>
                        <li>The data has then been trained on a ridge regression model with the help of 5-fold cross validation technique.</li>
                      </ul>
                      <p>Please click on execute button to proceed further.</p>
                      <center> <StyledButton variant='contained' color='primary' onClick={getOutputResult}>Execute</StyledButton></center>

                      {executeResult.multiome &&
                        <section>
                          <p>Below is the gene expression predicted for the sample DNA(chromatin accessibility data) . The output consists of 23,418 features:</p>
                          <Paper>
                            <JsonDataTable fields={executeResult.multiome?.schema?.fields} dataRows={executeResult.multiome?.data} />
                          </Paper>
                          <p>It can be observed that without the complex use of Multiome Kit,  library - size normalized and log1p transformation, this model can directly predict the gene expression given chromatin accessibility. That is one cell state can be predicted from another cell state.</p>
                            <b>Final Outcome:</b>
                            <p>This work will accelerate innovation in methods of mapping genetic information across layers of cellular state. If we can predict one modality from another, we may expand our understanding of the rules governing these complex regulatory processes.</p>
                            <p>Among the outcomes have been detailed maps of early human embryonic development, the discovery of new disease-associated cell types, and cell-targeted therapeutic interventions.</p>
                            <p>Being able to solve the prediction problems over time may yield new insights into how gene regulation influences differentiation as blood and immune cells mature.</p>
                        </section>}
                    </section>}
                </section>}
              </TabPanel>
              <TabPanel value={tabVal} index={1}>
                <Grid container>
                  <Grid item xs={6}>
                    <p>Predicting Protein abundance when gene <b>expression is provided.</b></p>
                    <p><b>Gene expression:</b> It is the process by which a gene gets turned on in a cell to make RNA and proteins.</p>
                    <p><b>Protein abundance:</b> It is the number of copies of a protein molecules in a cell.</p>
                    <p><b>Please select one among the three sample datasets provided. </b> The datasets consists of gene expressions that are measured using CITESeq Kit. The data is available in hdf5 file format.</p>
                    <RadioGroup
                      aria-labelledby='demo-controlled-radio-buttons-group'
                      value={radioValue}
                      onChange={handleRadioChange}
                      column
                    >
                      <FormControlLabel
                        value='sample1'
                        name={citeseq_sample1}
                        control={<Radio />}
                        label='sample1'

                      />
                      <FormControlLabel
                        value='sample2'
                        name={citeseq_sample2}
                        control={<Radio />}
                        label='sample2'
                      />
                      <FormControlLabel
                        value='sample3'
                        name={citeseq_sample3}
                        control={<Radio />}
                        label='sample3'
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={6}>
                    <center><img width="90%" src={multimodal_sol3} /></center>
                  </Grid>
                </Grid>
                {Value && <section>
                  <img width="100%" src={images} />
                  <p>The first step is to pre-process the data sample. This involves the following steps: </p>
                  <ul>
                    <li>The training data consists of around 22,050 features, taken from the 10x Genomics Single Cell Gene Expression with Feature Barcoding technology. Using CITESeq, the gene expression (RNA) and surface protein levels can be measured. </li>
                    <li>Out of the whole sample, features that have high correlation have been considered in this process</li>
                    <li>144 features that share common names as part of the input RNA and target protien have been subjected to truncated SVD technique to reduce dimensionality </li>
                    <li>Finally, the outcome of truncated SVD, as well as the highly correlated features have been concatenated to obtain 208 features</li>
                  </ul>
                  <p>Please click on preprocessing button to proceed further.</p>
                  <center><StyledButton variant='contained' color='primary' onClick={getPreprocessingResult}>Preprocessing</StyledButton></center>

                  {preProcessingResult.citeseq &&
                    <section>
                      The following are the features that have been obtained after preprocessing the data. The data consists of 208 features:
                      <Paper>
                        <JsonDataTable fields={preProcessingResult.citeseq?.schema?.fields} dataRows={preProcessingResult.citeseq?.data} />
                      </Paper>
                      <p>The next step is to predict protein sequence values from the initial RNA sequence inputs. </p>
                      <ul>
                        <li>The protein abundance from the target data has been measured by making use of 10x Genomics Single Cell Gene Expression with Feature Barcoding technology (CITE Seq Kit). The protein abundance outcome from the kit is dsb normalized. </li>
                        <li>The data has then been trained on a Tensorflow model by taing the best hyperparameters in consideration, as well as retraining using 3-fold cross validation technique. </li>
                      </ul>
                     <p>Please click on execute button to proceed further.</p>
                      <center> <StyledButton variant='contained' color='primary' onClick={getOutputResult}>Execute</StyledButton></center>

                      {executeResult.citeseq &&
                        <section>
                          <p>Below are the protein levels predicted for the sample RNA(gene expression) :</p>
                          <Paper>
                            <JsonDataTable fields={executeResult.citeseq?.schema?.fields} dataRows={executeResult.citeseq?.data} />
                          </Paper>
                          <p>It can be observed that without the complex use of CITE seq Kit,  bsb normalization, this model can directly predict the protein for a given gene expression.  The protein level predicted has 140 features.</p>
                          <p><b>Final Outcome:</b>
                            <br />
                            This work will accelerate innovation in methods of mapping genetic information across layers of cellular state. If we can predict one modality from another, we may expand our understanding of the rules governing these complex regulatory processes.</p>
                          <p>Among the outcomes have been detailed maps of early human embryonic development, the discovery of new disease-associated cell types, and cell-targeted therapeutic interventions.</p>
                          <p>Being able to solve the prediction problems over time may yield new insights into how gene regulation influences differentiation as blood and immune cells mature.</p>
                        </section>}
                    </section>}
                </section>}

              </TabPanel>
            </AppBar>


          </Collapse>
          {showSpinner && <Spinner text="Loading..." />}
        </div>
      </section>
    </SolutionContainer>
  );
};

export default memo(MultimodalSingleCellIntegration);
const SubSectiontitle = styled.div`
        border-bottom: 3px solid #3175eb;
        text-align: left;
        margin-bottom: 20px;
        padding: 20px 0 10px 0;
        `;
