import React, { memo, useEffect, useState } from "react";
import styled from "styled-components";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Grid, Typography } from "@material-ui/core";
import Highcharts from "highcharts";

// local components

import Collapse from "../../common/Collapse";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  ListItem,
  List,
  HighchartContainer,
  Result,
} from "../../../styles/common";

// image components
import BreastCancerMammography_sample1 from "../../../assets/images/app/breastnormal.webp";
import BreastCancerMammography_sample2 from "../../../assets/images/app/breastbenign.webp";
import BreastCancerMammography_sample3 from "../../../assets/images/app/breastmalignant.webp";
import BreastCancerMammography_sample4 from "../../../assets/images/app/breastsyntheticnormal.webp";
import BreastCancerMammography_sample5 from "../../../assets/images/app/breastsyntheticbenign.webp";
import BreastCancerMammography_sample6 from "../../../assets/images/app/breastsyntheticmalignant.webp";
import BreastCancerMammography_sample7 from "../../../assets/images/app/breastcancersample1.webp";
import BreastCancerMammography_sample8 from "../../../assets/images/app/breastcancersample2.webp";

const BreastCancerMammography = () => {
  const [sample, setSample] = useState(false);
  const [results, setResults] = useState(false);
  const [result, setResult] = useState(false);
  const [shapleychart, setShapleychart] = useState(true);
  const [shapley, setretrainwithShapley] = useState(false);
  const [notification, setNotification] = useState("");

  const formHighChart = () => {
    Highcharts.chart('container_1', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      title: {
        text: 'Distribution of Synthetic and Real Images',
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.0f}%</b>',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.0f} %',
          },
        },
      },
      series: [{
        name: 'Probability',
        colorByPoint: true,
        data: [{
          name: 'Real Images',
          y: 176,

        }, {
          name: 'Synthetic Images',
          y: 80,
          sliced: true,

        }],
      }],
    });
  };

  const getSample = () => {
    setResult('');
    setResults('');
    setSample(true);
    setShapleychart(true);

    setTimeout(() => {
      formHighChart();
    }, 0);
  };

  const showResult = () => {
    setretrainwithShapley('');
    setResults('');
    setResult(true);
  };

  const getretrainwithShapley = () => {
    setResults('');
    setretrainwithShapley(true);
    setSpinner1(true);
  };
  const sample1 = () => {
    setResults({
      text: "The mammogram has been classified as 'Normal'",
    });
  };
  const sample2 = () => {
    setResults({
      text: "The mammogram has been classified as having a 'Malignant' tumor ",
    });
  };
  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            According to the World Health Organization, breast cancer is the most common form of cancer that affect women worldwide. Statistics show that 1 in 8 women in the United States will develop breast cancer in their lifetime, and 81% of the cases will be invasive, or infiltrative. Although breast cancer used to be referred to as a single disease, it is now considered to be a group of diseases, consisting of 4 major molecular subtypes and a minimum of 21 distinct histological subtypes that differ in presentation, risk factors, response to treatment, and outcomes. Typically, breast cancer shows no symptoms when the tumor is small, which is when it is the easiest to treat. Regular screening sessions are important for early detection and treatment. Breast cancer can typically be detected during a screening session, before the symptoms start showing, or after a lump is observed. If breast cancer is suspected, a biopsy is conducted to identify whether the tumors are non-cancerous or ‘benign’, or whether they are cancerous or ‘malignant’.
          </p>
          <p>
            A great deal of research goes into using deep learning to aid in medical diagnosis, but lack of availability of training data becomes a limiting factor. The MIAS dataset is a public dataset which consists of a very small set of mammography images that can be classified as Normal or having Benign or Malignant Tumors. As the size of the dataset is pretty small, to increase its size, synthetic images are generated from the original images using SinGAN and combined with the original dataset. This larger dataset is then used to train a CNN model to classify a mammography scan as normal or abnormal, and to determine whether those abnormalities are benign or malignant.
          </p>
          <p>
            In addition to this, a principled framework to address data valuation in the context of supervised machine learning is showcased. Monte Carlo and Gradient-based methods are implemented to efficiently estimate data Shapley values for each of the data points in the dataset to identify and eliminate noisy data. The CNN model is then trained again on the modified dataset to produce better results.
          </p>
          <Typography><strong>The Original Dataset</strong></Typography>
          <p>
            The MIAS dataset is a small dataset by the Mammographic Image Analysis Society. The dataset consists of 330 images of mammography scans, classified into normal and abnormal, with the abnormalities classified further as benign or malignant. The size of all the images is 1024 pixels x 1024 pixels and have been centered in the matrix.
          </p>
          <Typography><strong>SinGAN</strong></Typography>
          <p>
            The small size of the dataset makes it unsuitable for training an efficient CNN which will be capable of making accurate predictions. A technique called Synthetic Data Generation is used to generate a set of synthetic images from the existing dataset to overcome this challenge. This is achieved using a tool called SinGAN. SinGAN is a variant of GAN (Generative Adversarial Networks) in which the network learns from a single natural image to generate images that are very realistic. Three images from each class are taken and fed into SinGAN to obtain 90 synthetically generated images (10 synthetic images from each original image). The newly generated images are then combined with the original dataset. This newly created dataset is preprocessed and made model-ready.
          </p>
          <Typography><strong>ConvNet</strong></Typography>
          <p>A simple CNN (Convolutional Neural Network) model, consisting of stacked 3x3 convolutional layers alternating with max pools followed by three fully connected layers is built and trained on the newly modified dataset. The model is trained to identify whether the mammography image is Normal or Abnormal, and to determine whether those abnormalities are Benign or Malignant.</p>
          <Typography><strong>Data Shapley</strong></Typography>
          <p>Although the CNN model gives us fairly good performance results, it is undeniable that the dataset used for training may have noisy data. Quantifying the value of each data point towards the final performance of an AI/ML model can help us identify those data points that contribute more positively and those that bring down the model performance. This can be achieved by using Data Shapley. Data Shapley makes use of a principled framework to address the quantification of data in the context of machine learning. Truncated Monte Carlo (TMC) and Gradient-based methods are implemented to efficiently estimate the Shapley values for each data point in the dataset.</p>
          <Typography>
            <strong>Why use Data Shapley?</strong>
            {' '}
          </Typography>
          <List>
            <ListItem>It is more powerful and effective than generic methods like leave-one-out or leverage-score sampling algorithms</ListItem>
            <ListItem>Data Shapley calculates and assigns a “Shapley value” to each data point in the dataset</ListItem>
            <ListItem>Data points with relatively low Shapley values can be considered as outliers or corrupted data. Those with higher Shapley values can be used to retrain an AI/ML model to obtain increased performance</ListItem>
          </List>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.kaggle.com/datasets/kmader/mias-mammography"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/UHGMammography/UHGMammogram.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid item xs={12}>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={getSample}
            >
              Sample Data
            </StyledButton>
          </Grid>
          {sample && (
            <section>
              <Grid container spacing={2} xs={12} sm={12} md={12} direction="row" justify="center" alignItems="center">
                <Grid xs={12}>
                  <h4>Real Samples</h4>
                </Grid>
                <Grid xs={10} sm={4} md={4}>
                  <Image src={BreastCancerMammography_sample1} />
                  <p>Fig 1.1 Normal</p>
                </Grid>
                <Grid xs={10} sm={4} md={4}>
                  <Image src={BreastCancerMammography_sample2} />
                  <p>Fig 1.2 Benign</p>
                </Grid>
                <Grid xs={10} sm={4} md={4}>
                  <Image src={BreastCancerMammography_sample3} />
                  <p>Fig 1.3 Malignant</p>
                </Grid>
              </Grid>
              <Grid container spacing={2} xs={12} sm={12} md={12} direction="row" justify="center" alignItems="center">
                <Grid xs={12}>
                  <h4>Synthetic Samples</h4>
                </Grid>
                <Grid xs={10} sm={4} md={4}>
                  <Image src={BreastCancerMammography_sample4} />
                  <p>Fig 2.1 Synthetic Normal</p>
                </Grid>
                <Grid xs={10} sm={4} md={4}>
                  <Image src={BreastCancerMammography_sample5} />
                  <p>Fig 2.2 Synthetic Benign</p>
                </Grid>
                <Grid xs={10} sm={4} md={4}>
                  <Image src={BreastCancerMammography_sample6} />
                  <p>Fig 2.3 Synthetic Malignant</p>
                </Grid>
              </Grid>
              <Grid container spacing={2} direction="row" justify="center" alignItems="center">
                <Grid item xs={12} sm={12} md={6}>
                  <strong><p>Distribution of Synthetic and Real Images</p></strong>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <StyledTableHead align="left">Class</StyledTableHead>
                        <StyledTableHead align="left">Original Image Count</StyledTableHead>
                        <StyledTableHead align="left">Synthetic Image Count</StyledTableHead>
                        <StyledTableHead align="left">Total</StyledTableHead>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key="0">
                        <TableCell align="left">Class 0 - Normal</TableCell>
                        <TableCell align="left">70</TableCell>
                        <TableCell align="left">22</TableCell>
                        <TableCell align="left">92</TableCell>
                      </TableRow>
                      <TableRow key="1">
                        <TableCell align="left">Class 1 - Benign</TableCell>
                        <TableCell align="left">54</TableCell>
                        <TableCell align="left">28</TableCell>
                        <TableCell align="left">82</TableCell>
                      </TableRow>
                      <TableRow key="2">
                        <TableCell align="left">Class 2 - Malignant</TableCell>
                        <TableCell align="left">52</TableCell>
                        <TableCell align="left">30</TableCell>
                        <TableCell align="left">82</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <HighchartContainer id="container_1" display={shapleychart} />
                </Grid>
              </Grid>
              <br />
              <br />
              <StyledButton variant="outlined" color="primary" style={{ textTransform: 'none' }}>A simple CNN model is trained on the above-displayed dataset to classify mammography images as Normal or as having Benign or Malignant tumors.</StyledButton>
              <br />
              <br />
              <StyledButton
                variant="contained"
                color="primary"
                onClick={showResult}
              >
                SHOW RESULTS
              </StyledButton>

              {result
                && (
                  <section>
                    <Grid container xs={12} spacing={2} direction="row" justify="center" alignItems="center">
                      <Grid item xs={12} sm={6} md={6}>
                        <strong><p>Metrics Table</p></strong>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <StyledTableHead align="left">Metric</StyledTableHead>
                              <StyledTableHead align="left">Value</StyledTableHead>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow key="0">
                              <TableCell align="left">Accuracy</TableCell>
                              <TableCell align="left">72.0</TableCell>
                            </TableRow>
                            <TableRow key="1">
                              <TableCell align="left">Recall</TableCell>
                              <TableCell align="left">72.0</TableCell>
                            </TableRow>
                            <TableRow key="2">
                              <TableCell align="left">Precision</TableCell>
                              <TableCell align="left">67.25</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <StyledButton
                          variant="contained"
                          color="primary"
                          onClick={getretrainwithShapley}
                        >
                          Retrain with shapley
                        </StyledButton>
                      </Grid>
                    </Grid>
                    {shapley
                      && (
                        <Grid container xs={12} spacing={2} direction="row" justify="center" alignItems="center">
                          <Grid item xs={10} sm={7} md={7}>
                            <strong><p>Metrics Table</p></strong>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <StyledTableHead align="left">Class</StyledTableHead>
                                  <StyledTableHead align="left">Value</StyledTableHead>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow key="0">
                                  <TableCell align="left">Accuracy</TableCell>
                                  <TableCell align="left">76.0</TableCell>
                                </TableRow>
                                <TableRow key="1">
                                  <TableCell align="left">Recall</TableCell>
                                  <TableCell align="left">76.0</TableCell>
                                </TableRow>
                                <TableRow key="2">
                                  <TableCell align="left">Precision</TableCell>
                                  <TableCell align="left">72.65</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Grid>
                          <br />
                          <br />
                          <Grid item xs={12} sm={6} md={6}>
                            <Image src={BreastCancerMammography_sample7} />
                            <br />
                            <StyledButton
                              variant="contained"
                              color="primary"
                              onClick={sample1}
                            >
                              Sample1
                            </StyledButton>
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <Image src={BreastCancerMammography_sample8} />
                            <br />
                            <StyledButton
                              variant="contained"
                              color="primary"
                              onClick={sample2}
                            >
                              Sample2
                            </StyledButton>
                          </Grid>
                        </Grid>
                      )}
                    {results && (
                      <section>
                        <Result>
                          <strong>Result:</strong>
                          <Paragraph>{results.text}</Paragraph>
                        </Result>
                      </section>
                    )}
                  </section>
                )}
            </section>
          )}

        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};
export default memo(BreastCancerMammography);

const Image = styled.img`
  border-radius: 3px;
  height: 200px;
  width: 200px;
  margin-bottom: 3%;
  @media only screen and (max-width: 900px) {
    width: auto;
    height: 150px;
    max-width: 150px;
  }
  @media only screen and (max-width: 450px) {
    width: auto;
    height: 180px;
    max-width: 180px;
  }
`;
const StyledTableHead = styled(TableCell)`
  background-color: #3c40af;
  color: white;
  font-weight: bold;
`;
