import React, { memo, useState } from "react";
import styled from "styled-components";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { Typography, Grid, Button } from "@material-ui/core";

// local components

import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  SolutionContainer,
  LinkContainer,
} from "../../../styles/common";

import handwrittenOCR_sol1 from "../../../assets/images/app/handwritten-ocr.webp";

const OCRHandwritten = () => {
  const [result, setResult] = useState(false);
  const [result1, setResult1] = useState(false);
  const [result2, setResult2] = useState(false);

  const sample = () => {
    setResult1('');
    setResult2('');
    setResult(true);
  };
  const imageProcessing = () => {
    setResult2('');
    setResult1(true);
  };
  const predictDigits = () => {
    setResult2(true);
  };

  return (

    <SolutionContainer>
      <Collapse text="Description">
        <Paragraph>
          <Grid container xs={12} direction="row" spacing={1}>
            <Grid item xs={12} sm={12} md={8}>
              <p>
                Cheque system in banks is one of the most powerful transaction tool but due to its
                considerable complexities in cheque clearing process and with immense growth in technology,
                the electronic-transactions have created a big shadow on the cheque system to cater the need
                of the banking sector. Therefore, it is important to have a mechanism to make the entire
                process of bank cheque verification not only fast but also reliable by using deep learning
                methods and image processing techniques.
              </p>
              <p>
                Optical Character Recognition (OCR) is an automated text reading process from images that
                includes machine printed (typographic) or handwritten character recognition and numeral recognition.
                Image processing is a process of analysing a digitised image for performing operations such as
                enhancement, compression, segmentation, or editing. The fundamental stages of image processing
                which almost all the image has to go through include the following components which are sequential
                in nature: acquiring the image, pre-processing of the image, segmenting the image, interpreting
                the image, and recognising the image. There are many ways to interpret any image. One effective
                way is using convolution neural networks (CNN). The deep learning approach is an advancement of
                machine learning with multiple layers allowing it to achieve higher level of accuracy for pattern
                recognition.
              </p>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <center><Image src={handwrittenOCR_sol1} alt="" /></center>
            </Grid>
          </Grid>
          <p>
            This Application has been implemented and tested on cheque samples of different Indian banks which
            follow the standard guidelines of the Reserve Bank of India (RBI).
          </p>
          <p>
            Using OCR, all the relevant data from the checks are extracted and then it is passed to a
            verification system where the details are verified. After the verification stage is completed,
            the cheque can be passed for clearance.The cheque dimensions of any bank in a country are
            standard and are followed by the norms decided by the government financial body. In India,
            RBI monitors and controls the functioning of all Indian banks. A sample cheque leaf with it’s
            standard dimensions regulated and decided by RBI, is shown below. All Indian banks are bound
            to follow these standards and geometric dimensions strictly.
          </p>

        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/OCR-Handwritten/OCR_HandwrittenCheckValidation.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="http://yann.lecun.com/exdb/mnist/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container spacing={2} direction="row" justify="center">
            <StyledFigure>
              <ResultImage src="https://vlife.virtusa.com/ocr-in/Cheque.PNG" alt="" />
              <StyledButton variant="contained" color="primary" onClick={sample}>
                Sample
              </StyledButton>
            </StyledFigure>
          </Grid>
          {result
                        && (
                        <section>
                          <strong>Segment wise Check Details</strong>
                          <Grid item xs={12}>
                            <Grid container xs={12} direction="row" justify="center" alignItems="center" alignContent="space-between">
                              <Grid item xs={10} sm={5} md={5}>
                                <p>Date</p>
                                <ResultImage src="https://vlife.virtusa.com/ocr-in/date.png" alt="" />
                              </Grid>
                              <Grid item xs={10} sm={5} md={5}>
                                <p>Amount</p>
                                <ResultImage src="https://vlife.virtusa.com/ocr-in/amount.png" alt="" />
                              </Grid>
                              <Grid item xs={10} sm={5} md={5}>
                                <p>Payee</p>
                                <ResultImage src="https://vlife.virtusa.com/ocr-in/payee.png" height="50px" alt="" />
                              </Grid>
                              <Grid item xs={10} sm={5} md={5}>
                                <p>Account Number</p>
                                <ResultImage src="https://vlife.virtusa.com/ocr-in/accno.png" alt="" />
                              </Grid>
                            </Grid>
                            <StyledButton variant="contained" color="primary" onClick={imageProcessing}>
                              Image Processing
                            </StyledButton>
                          </Grid>

                            {result1
                                && (
                                <section>
                                  <Grid container xs={12} direction="row" justify="center" alignItems="center" alignContent="space-between">
                                    <Grid item xs={12} sm={12} md={4}>
                                      <p>Image Dilation</p>
                                      <ResultImage1 src="https://vlife.virtusa.com/ocr-in/image_dilation.png" alt="" />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                      <p>Image Erosion</p>
                                      <ResultImage1 src="https://vlife.virtusa.com/ocr-in/image_erosion.png" alt="" />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                      <p>Remove Horizontal and Vertical Lines</p>
                                      <ResultImage1 src="https://vlife.virtusa.com/ocr-in/ver-hor.PNG" alt="" />
                                    </Grid>
                                  </Grid>
                                  <Grid container xs={12} spacing={2} direction="row" justify="center" alignItems="center" alignContent="center">
                                    <Grid item xs={12}>
                                      <h4>Individual digits extracted from the image which will be then passed to a model:</h4>
                                    </Grid>
                                    <Grid item xs={6} sm={3} md={1}>
                                      <ResultDigit src="https://vlife.virtusa.com/ocr-in/ROI_0.png" alt="" />
                                      <p>1st digit </p>

                                    </Grid>
                                    <Grid item xs={6} sm={3} md={1}>

                                      <ResultDigit src="https://vlife.virtusa.com/ocr-in/ROI_1.png" alt="" />
                                      <p>2nd digit </p>

                                    </Grid>
                                    <Grid item xs={6} sm={3} md={1}>

                                      <ResultDigit src="https://vlife.virtusa.com/ocr-in/ROI_2.png" alt="" />
                                      <p>3rd digit </p>

                                    </Grid>
                                    <Grid item xs={6} sm={3} md={1}>

                                      <ResultDigit src="https://vlife.virtusa.com/ocr-in/ROI_3.png" alt="" />
                                      <p>4th digit </p>

                                    </Grid>
                                    <Grid item xs={6} sm={3} md={1}>

                                      <ResultDigit src="https://vlife.virtusa.com/ocr-in/ROI_4.png" alt="" />
                                      <p>5th digit </p>

                                    </Grid>
                                    <Grid item xs={6} sm={3} md={1}>

                                      <ResultDigit src="https://vlife.virtusa.com/ocr-in/ROI_5.png" alt="" />
                                      <p>6th digit </p>

                                    </Grid>
                                    <Grid item xs={6} sm={3} md={1}>

                                      <ResultDigit src="https://vlife.virtusa.com/ocr-in/ROI_6.png" alt="" />
                                      <p>7th digit </p>

                                    </Grid>
                                    <Grid item xs={6} sm={3} md={1}>

                                      <ResultDigit src="https://vlife.virtusa.com/ocr-in/ROI_7.png" alt="" />
                                      <p>8th digit </p>

                                    </Grid>
                                  </Grid>
                                  <ResultText>A CNN Model has been built using MNIST dataset to predict digits. Once the digits are extracted from the check, these digits are passed to the model one by one for digit prediction.</ResultText>
                                  <StyledButton variant="contained" color="primary" onClick={predictDigits}>
                                    Predict Digits
                                  </StyledButton>
                                  {result2
                                        && (
                                        <section>
                                          <Button variant="outlined" color="primary">Predicted Date is 06-06-2009</Button>
                                          <ResultText>Similarly, other digits/texts can be extracted from the check and after the prediction, these text can be validated using banking records and can be sent for further processing. This will help in reducing the processing time of checks.</ResultText>
                                        </section>
                                        )}
                                </section>
                                )}
                        </section>
                        )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};
export default memo(OCRHandwritten);
const StyledFigure = styled.figure`
            display: flex;
            align-items: center;
            flex-direction: column;
            `;
const Image = styled.img`
         width: 350px;
        @media only screen and (max-width: 900px) {
         width: auto;
         max-width: 350px;
    }   @media only screen and (max-width: 450px) {
         width: auto;
         max-width: 250px;
}
`;
const ResultDigit = styled.img`
           align: center;
           width: 80px;
           @media only screen and (max-width: 900px) {
            width: auto;
            max-width: 60px
       }   @media only screen and (max-width: 450px) {
            width: auto;
            max-width: 70px
   }
   `;
const ResultImage = styled.img`
            width: 60%;
           @media only screen and (max-width: 900px) {
            width: auto;
            max-width: 85%;
       }   @media only screen and (max-width: 450px) {
            width: auto;
            max-width: 75%;
   }
   `;
const ResultImage1 = styled.img`
            width: 70%;
           @media only screen and (max-width: 900px) {
            width: auto;
            max-width: 65%;
       }   @media only screen and (max-width: 450px) {
            width: auto;
            max-width: 75%;
   }
   `;
const ResultText = styled(Typography)`
         margin-top: 3%;
         margin-bottom: 3%;
            `;
