import React, { memo, useState } from "react";
import styled from "styled-components";
import {
  Grid,
  TextField,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Grow from "@material-ui/core/Grow";
import Paper from '@material-ui/core/Paper';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

// import sample components
import literatureValue from "../../../constants/maps/literatureValue";

// import local components
import CommonService from "../../../utils/services/CommonService";

import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  List,
  ListItem,
} from "../../../styles/common";
import SolutionContainer from "../../common/SolutionContainerWrapper";

const { getQueryResult, uploadFile } = CommonService("corona", "geneSimilarityService");

const GeneSimilarity = () => {
  const [showSpinner, setSpinner] = useState(false);
  const [result, setResult] = useState("");
  const [samplevalue, setSamplevalue] = useState("");
  const [sampleData, setSampleData] = useState("");
  const [uploadfile, setUploadfile] = useState("");
  const [notification, setNotification] = useState("");

  const getsampleResult = (e) => {
    setSpinner(true);
    setResult("");
    const selectedSample = e.currentTarget.getAttribute("data-sample");
    setSamplevalue(selectedSample);
    getQueryResult("sample", selectedSample)
      .then((res) => {
        setSpinner(false);
        const result = res['GENE SIMILARITY:'];
        setResult(result);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const showSample1 = (e) => {
    const NewDataSet = {
      sample: literatureValue.frequencyAnalysisSample1,
    };
    setSampleData(NewDataSet);
    getsampleResult(e);
  };

  const showSample2 = (e) => {
    const NewDataSet = {
      sample: literatureValue.geneSimilarity,
    };
    setSampleData(NewDataSet);
    getsampleResult(e);
  };

  const getUploadResult = (e) => {
    setSpinner(true);
    setResult("");
    uploadFile("upload", uploadfile)
      .then((res) => {
        setSpinner(false);
        const result = res['GENE SIMILARITY:'];
        setResult(result);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const onChangeFile = (e) => {
    const file = e.currentTarget.files[0];
    setUploadfile(file);
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <List>
            <ListItem> A covid-19 virus infected DNA sequence is studied and compared to other earlier known pandemics and virus DNA sequences such as SARS, MERS, Bat_SL_CoV, Civet_SL_CoV genome, Malaria genome and HIV genome. </ListItem>
            <ListItem> The comparison is based on the study of the dna sequences done earlier for development of vaccines and medicines for these pandemics. </ListItem>
            <ListItem> A fasta file in taken as an input which has the dna gene sequence in the form of ATCG nucleotides or a protein sequence. These compared with the dna sequences of earlier known pandemics. A comparability score is calculated and displayed. </ListItem>
          </List>
          <p>The score is calculated based on the following rules :</p>
          <List>
            <ListItem>For every match in the nucleotide it gives a score of +1 </ListItem>
            <ListItem>For every mismatch it gives a -1 </ListItem>
            <ListItem>For every open gap it gives -0.5 </ListItem>
            <ListItem>For every extended gap it gives -0.1 </ListItem>
          </List>
          <LinkContainer>
            <Grid container spacing={2}>

              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://ngdc.cncb.ac.cn/gwh/browse/virus/coronaviridae"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://github.com/Virtusa-vLife/Use-Cases/blob/master/Gene_similarity/Gene%20Similarity%20of%20Pandemics.ipynb"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <br />
        <DemoContainer>
          {samplevalue != ""
                        && (
                        <section>
                          <Grid container direction="row" justify="center" alignItems="center">
                            <Grid item xs={12} sm={12} md={8}>
                              <TextField
                                rows="8"
                                multiline
                                variant="outlined"
                                name="sample"
                                fullWidth
                                label="Sample Data"
                                value={sampleData.sample}
                              />
                              <br />
                              <br />
                            </Grid>
                          </Grid>
                        </section>
                        )}
          <Grid container spacing={2} direction="row" justify="center" alignItems="center">
            <Grid item xs={5} sm={4} md={2}>
              <StyledButton
                variant="contained"
                color="primary"
                data-sample="1/"
                onClick={showSample1}
              >
                Sample1
              </StyledButton>
            </Grid>
            <Grid item xs={5} sm={4} md={2}>
              <StyledButton
                variant="contained"
                color="primary"
                data-sample="2/"
                onClick={showSample2}
              >
                Sample2
              </StyledButton>
            </Grid>
            <Grid item xs={12} sm={12} md={1}>
              <h3>OR</h3>
            </Grid>
            <Grid item xs={10} sm={5} md={3}>
              <TextField
                type="file"
                color="primary"
                name="uploadedFile"
                onChange={onChangeFile}
                helperText="Please upload a FASTA file"
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={getUploadResult}
              >
                Submit
              </StyledButton>
            </Grid>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
          <Grid container XS={12}>
            <Grid item xs={12} md={12}>
              <Grow in={result} timeout={1500}>
                <div>
                  <section>
                    <Result>
                      <strong>Result : </strong>
                      The similarity scores between COVID-19 and other viruses :
                    </Result>
                    <Grid container direction="row" justify="center" alignItems="center">
                      <Grid item xs={12} sm={12} md={6}>
                        <center>
                          <Tablesize>
                            <Paper>
                              <center>
                                <Table>
                                  <TableHead>
                                    <TableRow>
                                      <StyledTableHead align="left">Virus</StyledTableHead>
                                      <StyledTableHead align="left">Similarity</StyledTableHead>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {result[3].split("\n").map((i, key) => (
                                      <TableRow key={key}>
                                        {i.split('COVID-19 &')[1].split('genome sequences:').map((value, key) => (
                                          <TableCell align="left">{value}</TableCell>
                                        ))}
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </center>
                            </Paper>
                          </Tablesize>
                        </center>
                      </Grid>
                    </Grid>
                  </section>
                </div>
              </Grow>
            </Grid>
          </Grid>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(GeneSimilarity);

const Tablesize = styled.div`
  width: 400px;
  @media only screen and (max-width: 450px) {
    width: 250px;
  }
`;
const StyledTableHead = styled(TableCell)`
  background-color: #3c40af;
  color: white;
  font-weight: bold;
`;
