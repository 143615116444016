import React, { memo } from "react";
import { Grid } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components
import Collapse from "../../common/Collapse";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
    Paragraph,
    StyledButton,
    DemoContainer,
    LinkContainer,
} from "../../../styles/common";

const EXBERT = () => {

    return (
        <SolutionContainer>
            <Collapse text="Description">
                <Paragraph>
                    <p>
                        Large Transformer-based language models can route and reshape complex information via their multi-headed attention mechanism. It can exhibit recognizable patterns following linguistic or positional information. Analyzing the learned representations and attentions is paramount to furthering our understanding of the inner workings of these models. A tool is introduced to support analysis for a wide variety of models, EXBERT. It is a tool to help humans conduct flexible, interactive investigations and formulate hypotheses for the model-internal reasoning process. EXBERT provides insights into the meaning of the contextual representations and attention by matching a human specified input to similar contexts in large annotated datasets. By aggregating the annotations of the matched contexts, EXBERT can quickly replicate findings from literature and extend them to previously not analyzed models.
                    </p>
                    <p>
                        EXBERT is a tool that combines the advantages of static analyses with a dynamic and intuitive view into both the attentions and internal representations of the underlying model. EXBERT provides these insights for any user-specified model and corpus by probing whether the representations capture meaningful information.  It is open-source, extensible, and compatible with many current Transformer architectures, both autoregressive and masked language models. EXBERT is available at exbert.net.
                    </p>
                    <p>
                        An overview of the different components of the tool. Users can ener a sentence and modify the attention view through selections. Self attention is displayed), with attentions directed as coming from the left column and pointing to the right. The blue matrix on the left shows a head’s attention (column) out of a token (row), whereas the right-hand matrix shows attention into each token by each head.
                    </p>
                    <p><strong>Application :  </strong></p>
                    <p>
                        EXBERT can replicate research that explores what attentions and representations learn and detect biases in text inputs. EXBERT can effectively narrow the scope and refine hypotheses through quick testing iterations.
                    </p>
                </Paragraph>
                <LinkContainer>
                    <Grid container spacing={2}>
                        <Grid item>
                            <StyledButton
                                variant="outlined"
                                color="primary"
                                size="large"
                                startIcon={<OpenInNewIcon />}
                            >
                                <a
                                    href="https://huggingface.co/exbert/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Source
                                </a>
                            </StyledButton>
                        </Grid>
                        <Grid item>
                            <StyledButton
                                variant="outlined"
                                color="primary"
                                size="large"
                                startIcon={<OpenInNewIcon />}
                            >
                                <a
                                    href="https://aclanthology.org/2020.acl-demos.22.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Read more
                                </a>
                            </StyledButton>
                        </Grid>
                    </Grid>
                </LinkContainer>
            </Collapse>
            <Collapse text="Demo">
                <DemoContainer>
                    <center>
                        <iframe src="https://huggingface.co/exbert/?model=bert-base-cased&modelKind=bidirectional&sentence=The%20girl%20ran%20to%20a%20local%20pub%20to%20escape%20the%20din%20of%20her%20city.&layer=0&heads=..0,1,2,3,4,5,6,7,8,9,10,11&threshold=0.7&tokenInd=null&tokenSide=null&maskInds=..&hideClsSep=true" width="100%" height="650" />
                    </center>
                </DemoContainer>
            </Collapse>
        </SolutionContainer>
    );
}

export default memo(EXBERT);
