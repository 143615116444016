import React, { memo, useState } from "react";
import styled from "styled-components";
import { Grid, TextField, MenuItem } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Highcharts from "highcharts";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

// local components
import Collapse from "../../common/Collapse";

import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  HighchartContainer,
  ListItem,
  List,
} from "../../../styles/common";
import getUserData, { getUploadToken } from '../../../utils';

const LifeInsurance = () => {
  const initialData = {
    product: "",
    age: "",
    wt: "",
    bmi: "",
    insuredinfo: "",
    medicalhistory4: "",
    medicalhistory6: "",
    medicalhistory23: "",
    medicalhistory39: "",
    medicalkeyword3: "",
    medicalkeyword15: "",
    medicalkeyword48: "",
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [result, SetResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService("payerProvider", "lifeinsurance");
  const Token = getUploadToken();

  const descriptionRows = [['Id', 'A unique identifier associated with an application'], ['Product_Info_1-7', 'A set of normalized variables relating to the product applied for'], ['Ins_Age', 'Normalized age of applicant'], ['Ht', 'Normalized height of applicant'], ['Wt', 'Normalized weight of applicant'], ['BMI', 'Normalized BMI of applicant'], ['Employment_Info_1-6', 'A set of normalized variables relating to the employment history of the applicant'], ['InsuredInfo_1-6', 'A set of normalized variables providing information about the applicant.'],
    ['Insurance_History_1-9', 'A set of normalized variables relating to the insurance history of the applicant.'], ['Family_Hist_1-5', 'A set of normalized variables relating to the family history of the applicant.'], ['Medical_History_1-41', 'A set of normalized variables relating to the medical history of the applicant'], ['Medical_Keyword_1-48', 'A set of dummy variables relating to the presence of/absence of a medical keyword being associated with the application.'], ['Response', 'This is the target variable, an ordinal variable relating to the final decision associated with an application']];

  const showSample1 = () => {
    const NewDataSet = {
      product: "0.08",
      age: "0.27",
      wt: "0.26",
      bmi: "0.6",
      insuredinfo: "2.0",
      medicalhistory4: "1.0",
      medicalhistory6: "3.0",
      medicalhistory23: "3.0",
      medicalhistory39: "3.0",
      medicalkeyword3: "0.0",
      medicalkeyword15: "0.0",
      medicalkeyword48: "0.0",
    };

    setSampleData(NewDataSet);
  };

  const showSample2 = () => {
    const NewDataSet = {
      product: "0.23",
      age: "0.49",
      wt: "0.37",
      bmi: "0.54",
      insuredinfo: "1.0",
      medicalhistory4: "2.0",
      medicalhistory6: "3.0",
      medicalhistory23: "3.0",
      medicalhistory39: "3.0",
      medicalkeyword3: "0.0",
      medicalkeyword15: "0.0",
      medicalkeyword48: "0.0",
    };

    setSampleData(NewDataSet);
  };

  const getResult = () => {
    const {
      product, age, wt, bmi, insuredinfo, medicalhistory4, medicalhistory6, medicalhistory23, medicalhistory39,
      medicalkeyword3, medicalkeyword15, medicalkeyword48,
    } = sampleData;

    const params = `${product},${age},${wt},${bmi},${insuredinfo},${medicalhistory4},${medicalhistory6},${medicalhistory23},
        ${medicalhistory39},${medicalkeyword3},${medicalkeyword15},${medicalkeyword48},${Token.token}`;
    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });
    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("withParams", params)
        .then((res) => {
          console.log(res);
          setSpinner(false);
          SetResultData(res.result);
          // formHighChart(res.importance);
          const percentages = res.importance;
          const params = [];

          percentages.map((arr) => {
            params.push({
              name: arr[0],
              y: arr[1],
            });
          });

          Highcharts.chart('prediction-chart', {
            chart: {
              plotShadow: false,
              type: 'pie',
            },
            title: {
              text: 'MAJOR CONTRIBUTING FACTORS',
            },
            tooltip: {
              pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                  style: {
                    color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
                  },
                },
              },
            },
            credits: {
              enabled: false,
            },
            series: [{
              colorByPoint: true,
              data: params,
            }],
          });
        })
        .catch((err) => {
          console.log(err);
          setSpinner(false);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const handleInputs = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>Risk Assessment is the methodology used by insurers to evaluate and assess the risks associated with an insurance policy. The same aids in the calculation of the right premium for an insured.</p>
          <p>In the United States, Medicare’s Health Risk Assessment (HRA) enquires about senior citizens’ ability to perform daily activities, while Medicaid’s assessments enquire about health-care access, availability of food, and living conditions. Most HRAs capture information related to:</p>
          <List>
            <ListItem>Demographic characteristics – age, sex </ListItem>
            <ListItem>Lifestyle – exercise, smoking, alcohol intake, diet </ListItem>
            <ListItem>Personal and family medical history </ListItem>
            <ListItem>Physiological data – weight, height, blood pressure, cholesterol </ListItem>
            <ListItem>Attitudes and willingness to change behaviour in order to improve health </ListItem>
          </List>
          <Grid container xs={12} direction="row" justify="center">
            <Grid xs={12} sm={8} md={6}>
              <Paper>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableHead align="left">Variable</StyledTableHead>
                      <StyledTableHead align="left">Description</StyledTableHead>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {descriptionRows.map((column, key) => (
                      <TableRow key={key}>
                        {column.map((value, key) => (
                          <TableCell align="left">{value}</TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>
          <p>Using the data obtained from the data source, feature selection techniques are applied to capture the 12 most important features. In this use case, the level of risk is identified using an ordinal variable which ranges from 1 to 8; 1-4 being of low risk and 5-8 of high risk. </p>
          <p>The main objectives of an HRA are to: </p>
          <List>
            <ListItem>Assess health status</ListItem>
            <ListItem>Estimate the level of health risk</ListItem>
            <ListItem>Inform and provide feedback to participants to motivate behaviour change to reduce health risks</ListItem>
          </List>
          {' '}

        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.kaggle.com/c/prudential-life-insurance-assessment/data"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/Life_Insurance_Risk_Assesment/life_insurance.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container direction="row" justify="center" spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="Product"
                name="product"
                fullWidth
                value={sampleData.product}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="Age"
                name="age"
                fullWidth
                value={sampleData.age}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="Wt"
                name="wt"
                fullWidth
                value={sampleData.wt}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="Bmi"
                name="bmi"
                fullWidth
                value={sampleData.bmi}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="Insuredinfo"
                name="insuredinfo"
                fullWidth
                value={sampleData.insuredinfo}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="Medicalhistory4"
                name="medicalhistory4"
                fullWidth
                value={sampleData.medicalhistory4}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="Medicalhistory6"
                name="medicalhistory6"
                fullWidth
                value={sampleData.medicalhistory6}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="Medicalhistory23"
                name="medicalhistory23"
                fullWidth
                value={sampleData.medicalhistory23}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="medicalhistory39"
                name="medicalhistory39"
                fullWidth
                value={sampleData.medicalhistory39}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="Medicalkeyword3"
                name="medicalkeyword3"
                fullWidth
                value={sampleData.medicalkeyword3}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="Medicalkeyword15"
                name="medicalkeyword15"
                fullWidth
                value={sampleData.medicalkeyword15}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                label="Medicalkeyword48"
                name="medicalkeyword48"
                fullWidth
                value={sampleData.medicalkeyword48}
                onChange={handleInputs}
              />
            </Grid>

            <br />
            <br />
            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={showSample1}
              >
                Sample 1
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={showSample2}
              >
                Sample 2
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={getResult}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          {' '}
          <br />
          <br />
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
          <Result>
            <strong>Result:</strong>
            {`${result}`}
          </Result>
          )}
          <HighchartContainer
            id="prediction-chart"
            display={result}
          />
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(LifeInsurance);

const Paper = styled.div`
  border: 2px solid black;
`;
const StyledTableHead = styled(TableCell)`
  background-color: #3c40af;
  color: white;
  font-weight: bold;
`;
