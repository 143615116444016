import React from 'react';
import PropTypes from "prop-types";
import Paper from '@material-ui/core/Paper';

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from '@material-ui/core/Divider';
import {
  Typography, List, ListItem, ListItemIcon, ListItemText, Select, InputLabel, Input, Checkbox, FormControlLabel,
} from '@material-ui/core';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import fasterAIService from './fasterAIService';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import { ToastContainer, toast } from 'react-toastify';

import NearMeIcon from '@material-ui/icons/NearMe';
import Alert from '@material-ui/lab/Alert';
import CheckIcon from '@material-ui/icons/Check';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ReactHtmlParser from 'react-html-parser';
import DataPanel from './DataPanel';
import Panel from './Panel';
import Category, {
  CONTENT, API, OPTIONS_MAPPING, MODELOPTIONS,
} from './fasterAiConstants';

import Highcharts from 'highcharts';
import APIDATA from '../../../utils/services/api-config';
import Navbar from '../../common/Navbar';
import 'react-toastify/dist/ReactToastify.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartBar, faChartPie, faDownload, faExclamation, faExternalLinkAlt, faFolderOpen, faPlayCircle, faProjectDiagram, faThumbsUp, faUndo, faUpload,
} from '@fortawesome/free-solid-svg-icons';
import downArrow from '../../../assets/images/fasterai/downArrow.webp';
import Spinner from '../../common/loading/Spinner';
import { PageTitle } from '../../../styles/common';
import MainViewStyle from "./style";
import architecture from '../../../assets/images/fasterai/faster-architecture.webp';

function FlowChartElement({ value, showForm, mainElement }) {
  const useStyles = makeStyles((theme) => ({
    element: {
      textAlign: 'center',
      cursor: 'pointer',
      color: 'black',
      transition: "200ms all cubic-bezier(.4,0,.2,1)",
      '&:hover': {
        transform: "scale(1.05)",
      },
    },
    invertImageColor: { filter: 'invert(1)' },
    mainElement: {
      width: '100%', minHeight: '20px', border: '1px solid #f5c48c', backgroundColor: '#a6c1ed', color: 'black', borderRadius: '4px', fontSize: '13px', lineHeight: 'normal', padding: '3px',
    },
    body: {
      width: '100%', minHeight: '20px', border: '1px solid #f5c48c', backgroundColor: '#f5c48c', borderRadius: '4px', fontSize: '13px', lineHeight: 'normal', padding: '3px',
    },
  }));
  const classes = useStyles();
  return (
    mainElement
      ? (
        <div onClick={showForm.bind(this, value)} className={classes.mainElement}>
          <span>
            {value}
            {' '}
          </span>
        </div>
      )
      : (
        <div className={classes.element} onClick={showForm.bind(this, value)} title="Click to show form">
          <img width="22px" className={classes.invertImageColor} height="30px" src={downArrow} alt="" />
          <div id="content" className={classes.body}>
            <span>
              {value}
              {' '}
            </span>
          </div>
        </div>
      )

  );
}
function Line() {
  return (
    <div style={{
      width: '1px',
      height: '65px',
      color: '#fff',
      backgroundColor: '#fff',
      margin: 'auto',
    }}
    />
  );
}
class FasterAi extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: '',
      droppedData: null,
      droppedDataArr: [],
      response: "",
      page: 0,
      rowsPerPage: 10,
      selectedDataOptions: '',
      promptAlert: null,
      promptAlertArr: [],
      openModal: false,
      tabvalue: 0,
      options: 'All Data',
      logTransformParams: [],
      selectedEnsembleModel: [],
      selectedBlendModel: [],
      selectedStackModel: [],
      dataElements: [],
      edaElements: [],
      dataPreElements: [],
      modelElements: [],
      runSetupElements: [],
      flag: 0,
      openDialog: false,
      targetOptions: null,
      sweetvizparams: 'Survived',
      genAllParams: 'Survived',
      mlType: 'cls',

    };
    this.myDivToFocus = React.createRef();
  }

  formTable = (fields, dataRows, classes) => (
    <Paper className={classes.tableMargin}>
      <TableContainer style={{ maxHeight: '500px' }}>
        <Table aria-label="sticky table">
          <TableHead>
            <TableRow>
              {fields && fields.map((field) => (
                <TableCell key={field.name} align="left">{field.name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRows.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => (
              <TableRow key={row.index}>
                {fields && fields.map((field) => (
                  <TableCell align="left">
                    {row[field.name]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20]}
        component="div"
        count={this.dataRows.length}
        rowsPerPage={this.state.rowsPerPage}
        page={this.state.page}
        onChangePage={this.handleChangePage}
        onChangeRowsPerPage={this.handleChangeRowsPerPage}
      />
      {this.state.dataShape}
    </Paper>
  )

  getResult = () => {
    const { selectedOption } = this.state;
    const callBack = selectedOption && OPTIONS_MAPPING[selectedOption].callBack;

    if (!selectedOption) {
      toast.error(<div>
        <FontAwesomeIcon icon={faExclamation} />
        {' '}
        Select data or drag data to execute
        {' '}
      </div>);
      return;
    }

    this.clearResult();
    callBack && this[callBack]();
  }

  clearProcessPad = () => {
    this.setState({
      edaElements: [], dataPreElements: [], modelElements: [], runSetupElements: [], dataElements: [], droppedDataArr: [], droppedData: null, isModelSetupDone: false, showMLFlowLink: false,
    });
  }

  validateFields = () => {
    const { selectedOption } = this.state;
    const { fields } = OPTIONS_MAPPING[selectedOption];
    let isValid = true;
    fields && fields.forEach((field) => {
      if (!field.optional) {
        if (!this.state[field.name] || (Array.isArray(this.state[field.name]) && this.state[field.name].length === 0)) {
          this.setState({ [`${field.name}_error`]: true });
          isValid = false;
        }
        if (field.isMultiSelect && this.state[field.name].length < 2) {
          this.setState({ [`${field.name}_error`]: true });
          isValid = false;
          toast.error(<div>
            <FontAwesomeIcon icon={faExclamation} />
            {' '}
            select atleast 2 options
            {' '}
          </div>);
        }
      }
    });
    isValid && this.closeCommonDialog();
  }

  onChangeFields = (e) => {
    const { name } = e.target;
    const value = (e.target.files && e.target.files[0]) || e.target.checked || e.target.value;

    this.setState({ [name]: value, [`${name}_error`]: false });
    if (name === 'ageBinningParams') {
      const noOfInputs = [];
      for (let i = 1; i <= value; i++) {
        noOfInputs.push(i);
      }
      this.setState({ noOfInputs });
    }
    if (name === 'selectedmethod') {
      const modifiedFields = this.state.modelEvaluationFields;
      if (value === 'Bagging') {
        if (this.state.mlType === 'cls') {
          modifiedFields[4].options = [
            { label: 'Decision Tree Classifier', value: 'dt' },
            { label: 'Random Forest Classifier', value: 'rf' },
            { label: 'Extra Trees Classifier', value: 'et' },
          ];
        } else {
          modifiedFields[4].regOptions = [
            { label: 'Decision Tree Regressor', value: 'dt' },
            { label: 'Random Forest Regressor', value: 'rf' },
          ];
        }
      } else if (this.state.mlType === 'cls') {
        modifiedFields[4].options = [
          { label: 'Ada Boost Classifier', value: 'ada' },
          { label: 'Gradient Boosting Classifier', value: 'gbc' },
          { label: 'Extreme Gradient Boosting', value: 'xgboost' },
        ];
      } else {
        modifiedFields[4].regOptions = [{ label: 'Gradient Boosting Regressor', value: 'gbr' },
          { label: 'Extreme Gradient Boosting', value: 'xgboost' }];
      }
      this.setState({ modelEvaluationFields: modifiedFields });
    }
  }

  openCommonDialog = () => {
    this.setState({
      openDialog: true,
    });
    this.clearForm();
  };

  clearForm = () => {
    this.setState({
      multiSelect: '', noOfInputs: '', setupModelFields: '', modelEvaluationFields: '',
    });
  }

  clearResult = () => {
    this.setState({
      pandasprofileingUrl: '',
      featureList: '',
      setupModelFields: '',
      downloadModel: '',
      showTabularData: false,
      showModelString: false,
      imagePlots: '',
      dataShape: '',
      modelString: '',
      resultPanelName: '',
      showChart: false,
      showObjectLabel: '',
      showMLFlowLink: false,
      imageDataUrl: '',
    });
    this.fields = '';
  }

  catchBlock = (err) => {
    this.isLoading(false);
    toast.error(<div>
      <FontAwesomeIcon icon={faExclamation} />
      {' '}
      {err.message}
                </div>);
  }

  showForm = (droppedData) => {
    const optionsObject = OPTIONS_MAPPING[droppedData];
    const fieldName = optionsObject && optionsObject.params;
    this.clearForm();
    this.setState({ selectedOption: droppedData }, () => {
      switch (droppedData) {
        case 'Log Transform':
          this.setLogtransformOptions(fieldName);
          break;
        default:
          this.setModelForms();
          break;
      }
    });
  }

  fromHighChart = (divId, titleText, data) => {
    const params = [];
    Object.entries(data).forEach((values) => {
      params.push({
        name: values[0],
        y: values[1],
      });
    });
    Highcharts.chart(divId, {
      chart: {
        plotShadow: false,
        type: 'pie',
      },
      title: {
        text: titleText,
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{y}</b>',
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {y}',
            style: {
              color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
            },
          },
        },
      },
      credits: {
        enabled: false,
      },
      series: [{
        colorByPoint: true,
        data: params,
      }],
    });
  }

  setupModelBuiliding = () => {
    const {
      sweetvizparams, normalizedMethod, trainSize, flag, ignoreFeatures, percentage, mlType,
    } = this.state;
    const params = `?&userValue[]=${sweetvizparams},${normalizedMethod},${trainSize},${flag ? `${flag},${percentage}` : flag},${ignoreFeatures || 'None'}`;

    this.isLoading(true, CONTENT.Loading.setupModel);

    fasterAIService.getResult(`${API[mlType].setupModel}${params}`)
      .then((res) => {
        console.log(res);
        this.parseAndSetTable(res[0][0]);
        const modelData = JSON.parse(res[0][1]);
        this.setState({ setupModelFields: modelData.schema.fields, modelSetupDatarows: modelData.data, isModelSetupDone: true });
        this.setPromptAlert(`Model setup Completed!, ${res[1]}`);
      })
      .catch((err) => {
        this.catchBlock(err);
      });
  }

  imageModelTraining = () => {
    const { imageDataName, imageModelTraining } = this.state;
    const params = `${imageModelTraining},${imageDataName}`;

    this.isLoading(true, CONTENT.Loading.imageModelTraining);

    fasterAIService.getResult(`${API.imageModelTraining}${params}`)
      .then((res) => {
        console.log(res);
        // TODO remove once api resturns correct response
        if (this.state.sampleImageOption === 'sample1') {
          let sampleRes = {};
          switch (this.state.imageModelTraining) {
            case 'Resnet':
              sampleRes = "{\"schema\":{\"fields\":[{\"name\":\"index\",\"type\":\"integer\"},{\"name\":\"Prediction\",\"type\":\"string\"},{\"name\":\"Probability\",\"type\":\"string\"}],\"primaryKey\":[\"index\"],\"pandas_version\":\"0.20.0\"},\"data\":[{\"index\":0,\"Prediction\":\"PNEUMONIA\",\"Probability\":\"22.512011229991913\"},{\"index\":1,\"Prediction\":\"NORMAL\",\"Probability\":\"77.48798727989197\"}]}";
              break;
            case 'Inception':
              sampleRes = "{\"schema\":{\"fields\":[{\"name\":\"index\",\"type\":\"integer\"},{\"name\":\"Prediction\",\"type\":\"string\"},{\"name\":\"Probability\",\"type\":\"string\"}],\"primaryKey\":[\"index\"],\"pandas_version\":\"0.20.0\"},\"data\":[{\"index\":0,\"Prediction\":\"PNEUMONIA\",\"Probability\":\"0.09793748613446951\"},{\"index\":1,\"Prediction\":\"NORMAL\",\"Probability\":\"99.90205764770508\"}]}";
              break;
            case 'SqueezeNet':
              sampleRes = "{\"schema\":{\"fields\":[{\"name\":\"index\",\"type\":\"integer\"},{\"name\":\"Prediction\",\"type\":\"string\"},{\"name\":\"Probability\",\"type\":\"string\"}],\"primaryKey\":[\"index\"],\"pandas_version\":\"0.20.0\"},\"data\":[{\"index\":0,\"Prediction\":\"PNEUMONIA\",\"Probability\":\"21.762987971305847\"},{\"index\":1,\"Prediction\":\"NORMAL\",\"Probability\":\"78.23700904846191\"}]}";
              break;
            case 'Densenet':
              sampleRes = "{\"schema\":{\"fields\":[{\"name\":\"index\",\"type\":\"integer\"},{\"name\":\"Prediction\",\"type\":\"string\"},{\"name\":\"Probability\",\"type\":\"string\"}],\"primaryKey\":[\"index\"],\"pandas_version\":\"0.20.0\"},\"data\":[{\"index\":0,\"Prediction\":\"PNEUMONIA\",\"Probability\":\"1.8476329743862152\"},{\"index\":1,\"Prediction\":\"NORMAL\",\"Probability\":\"98.15236926078796\"}]}";
              break;
            default:
          }
          this.parseAndSetTable(sampleRes);
        } else {
          this.parseAndSetTable(res);
        }
        this.setPromptAlert(`Image Model Running Completed!`);
      })
      .catch((err) => {
        this.catchBlock(err);
      });
  }

  uploadimageModelTraining = () => {
    const {
      imageModelTraining, classCount, epochCount, augmentation, batchSize,
    } = this.state;
    const params = `${imageModelTraining},${classCount},${epochCount},${augmentation},${batchSize}`;

    this.isLoading(true, CONTENT.Loading.imageModelTraining);

    fasterAIService.getResult(`${API.trainUploadedImage}${params}`)
      .then((res) => {
        console.log(res);
        this.setState({ isModelSetupDone: true, options: 'Upload Image Data' });
        this.setPromptAlert(res, true);
      })
      .catch((err) => {
        this.catchBlock(err);
      });
  }

  getUploadImgPrediction = () => {
    const { imageModelTraining, imagesCount, testSetLable } = this.state;
    const params = `${imageModelTraining},${imagesCount},${testSetLable}`;

    this.isLoading(true, CONTENT.Loading.uploadImagePred);

    fasterAIService.getResult(`${API.uploadedImagePred}${params}`)
      .then((res) => {
        console.log(res);
        this.setState({ options: 'After Image Prediction' });
        this.setPromptAlert(res, true);
      })
      .catch((err) => {
        this.catchBlock(err);
      });
  }

  runShapeley = () => {
    const {
      sweetvizparams, modelName, dataPointsCount, flag, percentage, selectedOption, multiaccuracyCategoricalFeature,
    } = this.state;
    let params = `?&userValue[]=${modelName},${dataPointsCount},${sweetvizparams},${flag ? `${flag},${percentage}` : flag}`;
    const { args } = OPTIONS_MAPPING[selectedOption];
    if (selectedOption === 'Multiaccuracy') {
      params = `?&userValue[]=${sweetvizparams},${multiaccuracyCategoricalFeature},${flag ? `${flag},${percentage}` : flag}`;
    }
    this.isLoading(true, CONTENT.Loading[args]);
    fasterAIService.getResult(`${API[args]}${params}`)
      .then((res) => {
        console.log(res);

        switch (selectedOption) {
          case 'Shapley':
            this.parseAndSetTable(res.result);
            this.setState({ downloadModel: res.url, downloadBtnName: 'Download Shapley Result', showChart: true });
            this.fromHighChart('beforeMetrics', res.info[1][0].name, res.info[1][0].metrics);
            this.fromHighChart('afterMetrics', res.info[1][1].name, res.info[1][1].metrics);
            this.setPromptAlert(res.info[0]);

            break;
          case 'Multiaccuracy':
            this.setState({ downloadModel: res.result[2], downloadBtnName: 'Download Multiaccuracy Result', showChart: true });
            this.fromHighChart('beforeMetrics', res.result[1][0].name, res.result[1][0].metrics);
            this.fromHighChart('afterMetrics', res.result[1][1].name, res.result[1][1].metrics);
            this.setPromptAlert(res.result[0]);
            break;
          default:
        }
      })
      .catch((err) => {
        this.catchBlock(err);
      });
  }

  showPlotsDropdown = (imageArr) => {
    let objArr;
    const imagePlotsList = imageArr.map((imageObj) => {
      objArr = Object.entries(imageObj);
      return { label: objArr[0][0].split('.')[0], value: objArr[0][1] };
    });
    this.setState({ imagePlots: imagePlotsList });
  }

  getModelEvaluationResult = () => {
    const selectedOption = OPTIONS_MAPPING[this.state.selectedOption];
    const { fields } = selectedOption;
    const { args } = selectedOption;
    let droppedValue = this.state.selectedOption;
    const modelsArr = ['Ensemble Models', 'Blend Models', 'Stack Models', 'Hyper Parameter Tune Model'];
    const params = fields.map((field) => this.state[field.name] && this.state[field.name]);
    selectedOption.flag && params.unshift(selectedOption.flag);

    this.isLoading(true, CONTENT.Loading[args]);

    fasterAIService.getResult(`${API[this.state.mlType][args]}${params}`)
      .then((res) => {
        console.log(res);
        this.setState({ showMLFlowLink: true });
        if (modelsArr.indexOf(droppedValue) > -1) {
          droppedValue = 'Model Download';
        }
        this.parseAndSetTable(Array.isArray(res) ? res[0] : res);
        switch (droppedValue) {
          case 'Model Download':
            this.setPromptAlert(res[2]);
            this.setState({
              ModelString: res[1], downloadModel: res[3], downloadBtnName: 'Download Model', showObjectLabel: (this.state.selectedOption === 'Hyper Parameter Tune Model' ? 'Show Tuned Model Object' : ''),
            });
            break;
          case 'Model Selection':
            this.setPromptAlert(res[1]);
            this.setState({ downloadModel: res[2], downloadBtnName: 'Download Plots', imagePlots: res[3] });
            this.showPlotsDropdown(res[3]);
            break;
          case 'Compare All Model Metrics':
            this.setPromptAlert(res[1]);
            this.setState({ downloadModel: res[2], downloadBtnName: 'Download Model' });
            break;
          case 'Select Ensemble Models':
            this.setPromptAlert(res[2]);
            this.setState({ downloadModel: res[3] });
            break;
          default:
            this.isLoading(false);
            break;
        }
      })
      .catch((err) => {
        this.catchBlock(err);
      });
  }

  setModelForms = () => {
    const { fields } = OPTIONS_MAPPING[this.state.selectedOption];
    if (fields) {
      this.openCommonDialog();
      this.setState({ modelEvaluationFields: fields });
    }
  }

  setPromptAlert = (alertText, tabChangeNotReq, resultPanelName) => {
    const { promptAlertArr } = this.state;
    const { selectedOption } = this.state;
    this.isLoading(false);
    toast.success(<div>
      <FontAwesomeIcon icon={faThumbsUp} />
      {' '}
      {'success'}
                  </div>);
    promptAlertArr.push(alertText);
    this.setState({ prompAlert: promptAlertArr, promptAlertArr });
    if (!tabChangeNotReq) {
      this.setState({ resultPanelName: resultPanelName || OPTIONS_MAPPING[selectedOption].resultText || selectedOption });
      this.scrollToResult();
    }
  }

  scrollToResult = () => {
    this.myDivToFocus.current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  }

  closeCommonDialog = () => {
    this.setState({ openDialog: false });
  };

  getImageDataResult = () => {
    this.isLoading(true, CONTENT.Loading.sampleImage);

    fasterAIService.getResult(API.sampleImage + this.state.sampleImageOption)
      .then((res) => {
        console.log(res);
        this.setState({ options: 'Image Data', imageDataDetails: res, imageDataName: res[1] });
        this.setPromptAlert('Image Data Loaded', true);
      })
      .catch((err) => {
        this.catchBlock(err);
      });
  }

  showImageData = () => {
    const { imageDataDetails } = this.state;
    this.setState({ imageDataUrl: imageDataDetails[0] });
    this.setPromptAlert('Image Data Loaded', false, this.state.sampleImageOption === 'sample1' ? 'Normal Chest Xray' : 'Pneumonia Chest Xray');
  }

  showUploadedImageData = () => {
    const { uploadImageClass } = this.state;
    this.isLoading(true, '');

    fasterAIService.getResult(`${API.showUploadedImage}${uploadImageClass}`)
      .then((res) => {
        console.log(res);
        this.setState({ imageDataUrl: res[0] });
        this.setPromptAlert(`Image Url: ${res[0]}`, false);

        this.isLoading(false);
      })
      .catch((err) => {
        this.catchBlock(err);
      });
  }

  setLogtransformOptions = (fieldName) => {
    const { fields } = OPTIONS_MAPPING[this.state.selectedOption];
    fasterAIService.getResult(`${API.showFetaures}0`)
      .then((res) => {
        console.log(res);
        fields[0].options = res.map((option) => ({ label: option, value: option }));
        this.openCommonDialog();
        this.setState({ modelEvaluationFields: fields });
      })
      .catch((err) => {
        this.catchBlock(err);
      });
  }

  getEDAResult = () => {
    const { args, fields } = OPTIONS_MAPPING[this.state.selectedOption];
    this.isLoading(true, CONTENT.Loading[args]);

    fasterAIService.getResult(`${API[args]}${fields ? this.state[fields[0].name] : ''}`)
      .then((res) => {
        if (this.state.selectedOption === "Generate EDA Charts" && this.state.mlType === 'reg') {
          toast.error(<div>
            <FontAwesomeIcon icon={faExclamation} />
            {' '}
            {`${res}`}
                      </div>);
          this.isLoading(false);
        } else {
          this.setPromptAlert(res.result, true);
        }
      })
      .catch((err) => {
        this.catchBlock(err);
      });
  }

  getShowFeaturesResults = () => {
    this.isLoading(true, CONTENT.Loading.showFetaures);

    fasterAIService.getResult(`${API.showFetaures}${this.state.showfeaturesParams}`)
      .then((res) => {
        console.log(res);
        this.setState({ featureList: res });
        this.setPromptAlert(`Feature Result`);
      })
      .catch((err) => {
        this.catchBlock(err);
      });
  }

  isLoading = (loading, text) => {
    this.setState({
      isLoading: loading,
      loadingText: text,
    });
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
    this.setState({ page: 0 });
  };

  dragstart = (e) => {
    e.dataTransfer.setData("drag-item", e.target.textContent);
  }

  isDropped = (itemsArr, data) => {
    const isAlreadyDropped = itemsArr.indexOf(data) > -1;
    if (isAlreadyDropped) {
      toast.error(<div>
        <FontAwesomeIcon icon={faExclamation} />
        {' '}
        {`${data} is already dropped, remove it to add again`}
                  </div>);
    }
    return isAlreadyDropped;
  }

  parseAndSetTable = (tableResponse) => {
    const previewData = JSON.parse(tableResponse);
    this.fields = previewData.schema.fields;
    this.dataRows = previewData.data;
  }

  getSampleData = () => {
    this.isLoading(true, CONTENT.Loading.sampleData);

    fasterAIService.getResult(API[this.state.mlType].sampleData)
      .then((res) => {
        console.log(res);
        this.parseAndSetTable(res[0]);
        this.setState({
          options: 'Tabular Data', dataShape: `Total Rows: ${res[2][0][0]} , Total Columns: ${res[2][0][1]}`, targetOptions: res[1], sweetvizparams: this.state.mlType === 'reg' ? 'charges' : 'Survived',
        });
        this.setPromptAlert('Sample Data Loaded');
        this.getFeatureResult();
      })
      .catch((err) => {
        this.catchBlock(err);
      });
  }

  getUploadFileData = () => {
    this.isLoading(true, CONTENT.Loading.uploadData);

    fasterAIService.uploadImage(this.state.fileUpload, API.uploadData)
      .then((res) => {
        console.log(res);
        this.parseAndSetTable(res.result[0]);
        this.setState({
          options: 'Tabular Data', dataShape: `Total Rows: ${res.result[2][0][0]} , Total Columns: ${res.result[2][0][1]}`, targetOptions: res.result[1], sweetvizparams: 'No-show',
        });
        this.setPromptAlert('File upload success!');
        this.getFeatureResult();
      })
      .catch((err) => {
        this.catchBlock(err);
      });
  }

  getUploadImageData = () => {
    this.setState({ options: 'Upload Image Data' });

    this.isLoading(true, CONTENT.Loading.uploadData);

    fasterAIService.uploadImage(this.state.fileUpload, API.uploadImage)
      .then((res) => {
        this.setPromptAlert(res.result, true);
      })
      .catch((err) => {
        this.catchBlock(err);
      });
  }

  getFeatureScalingResult = () => {
    const apiParams = `${API[this.state.mlType].featureScaling}${this.state.sweetvizparams},${this.state.featureScalingMethod},0.7,0,None`;
    this.isLoading(true, CONTENT.Loading.featureScaling);

    fasterAIService.getResult(apiParams)
      .then((res) => {
        console.log(res);
        this.parseAndSetTable(res[0][1]);
        this.setPromptAlert('Feature Scaling Result');
      })
      .catch((err) => {
        this.catchBlock(err);
      });
  }

  getDataProcessingResult = () => {
    const { selectedOption } = this.state;
    const {
      fields, args, flag, isMultipleParams,
    } = OPTIONS_MAPPING[selectedOption];
    let paramsValue = fields && fields.map((field) => this.state[field.name] && this.state[field.name]);
    let apiParams = fields ? `${API[args]}${paramsValue}` : API[args];

    this.isLoading(true, CONTENT.Loading[args]);

    if (selectedOption === 'Feature Extraction') {
      const binsParms = [paramsValue];
      this.state.noOfInputs.filter((value) => {
        binsParms.push(this.state[`ageBinningParams${value}`]);
      });
      apiParams = `${API[args]}${binsParms}`;
      paramsValue = binsParms;
    }

    fasterAIService.getResult(apiParams)
      .then((res) => {
        console.log(res);
        this.parseAndSetTable(res);
        this.setPromptAlert(selectedOption);
        if (flag) {
          if (isMultipleParams) paramsValue += ',$';
          this.setState({ flag, percentage: paramsValue });
        }
      })
      .catch((err) => {
        this.catchBlock(err);
      });
  }

  itemDropped = (data, isNotFile) => {
    const { data_processing, eda, model_training } = Category['All Data'];
    const {
      edaElements, dataPreElements, modelElements, runSetupElements, dataElements, droppedDataArr,
    } = this.state;
    const dataElementseArr = ['Sample Tabular', 'Sample Image', 'Upload Tabular Data', 'Sample Tabular Reg', 'Upload Image Data'];

    if ((dataElementseArr.indexOf(droppedDataArr[0]) > -1) && (dataElementseArr.indexOf(data) > -1)) {
      toast.error(<div>
        <FontAwesomeIcon icon={faExclamation} />
        {' '}
        data is already selected.
        {' '}
      </div>);
      return;
    }
    if (!(dataElementseArr.indexOf(data) > -1)) {
      if (!(dataElementseArr.indexOf(droppedDataArr[0]) > -1)) {
        toast.error(<div>
          <FontAwesomeIcon icon={faExclamation} />
          {' '}
          Drag Sample Data or upload data to continue.
          {' '}
        </div>);
        return;
      }
      if (!this.state.targetOptions && !(droppedDataArr[0] === 'Sample Image')) {
        toast.error(<div>
          <FontAwesomeIcon icon={faExclamation} />
          {' '}
          execute Sample data to continue/
          {' '}
        </div>);
        return;
      }
    }

    if (isNotFile) {
      if (!this.isDropped(droppedDataArr, data)) {
        droppedDataArr.push(data);
        (dataElementseArr.indexOf(data) > -1) ? dataElements.push(data)
          : (eda.indexOf(data) > -1) ? edaElements.push(data)
            : (data_processing.indexOf(data) > -1) ? dataPreElements.push(data)
              : (model_training.indexOf(data) > -1) ? runSetupElements.push(data)
                : ([...MODELOPTIONS, 'Shapley', 'Multiaccuracy'].indexOf(data) > -1) && modelElements.push(data);
        this.setState({
          droppedData: droppedDataArr,
          droppedDataArr,
          selectedOption: data,
          edaElements,
          dataPreElements,
          dataElements,
          runSetupElements,
          modelElements,

        }, () => {
          this.showForm(data);
        });
      }
    } else {
      droppedDataArr.push(data.name);

      this.setState({ droppedData: droppedDataArr, droppedDataArr });
      this.uploadFile(data);
    }
  }

  dragOver = (ev) => {
    ev.preventDefault();
  }

  removeDroppedItem = (data) => {
    console.log(data);
    const itemsArr = this.state.droppedDataArr.filter((value) => (
      (value !== data)
    ));
    const previousSelectedOption = itemsArr[itemsArr.length - 1];
    this.setState({ droppedData: itemsArr, droppedDataArr: itemsArr, selectedOption: previousSelectedOption });
  }

  drop = (ev) => {
    ev.preventDefault();
    if (ev.dataTransfer.items) {
      for (let i = 0; i < ev.dataTransfer.items.length; i++) {
        if (ev.dataTransfer.items[i].kind === 'file') {
          const file = ev.dataTransfer.items[i].getAsFile();
          console.log(`... file[${i}].name = ${file.name}`);
          this.itemDropped(file);
        } else {
          const droppedItem = ev.dataTransfer.getData("drag-item");
          if (droppedItem) {
            this.itemDropped(droppedItem, true);
          }
        }
      }
    }
  }

  getFeatureResult = () => {
    fasterAIService.getResult('EDA/showfeatures/1')
      .then((res) => {
        this.setState({ categoricalFeatures: res });
      });
  }

  onLoad = (reactFlowInstance) => {
    reactFlowInstance.fitView();
  }

  render() {
    const { classes } = this.props;
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };
    const {
      options, isModelSetupDone, edaElements, dataElements, dataPreElements, modelElements, runSetupElements,
      droppedData, isLoading, loadingText,
      prompAlert, showTabularData, ModelString,
      showModelString, setupModelFields, modelSetupDatarows,
      pandasprofileingUrl, featureList, downloadModel, downloadBtnName, mlType, showMLFlowLink,
    } = this.state;

    return (
      <section className="App">

        <div className={classes.fasterAiRoot}>
          <Navbar changeColorOnScroll="false" />
          <div className={classes.fasterAiContainer}>

            <section style={{ textAlign: 'left', marginLeft: '20px' }}>
              <PageTitle color="#ffff">Faster AI</PageTitle>
              <Typography>Faster AI - A No Code AI platform to build Machine Learning model pipeline with just drag and drop. It is an Automated Machine Learning platform in which any data science enthusiast, data scientist, Researchers can come with their own data and build a complete Machine Learning pipeline starting with Data Ingestion, EDA, Model Training, and Evaluation. They can also check data valuation using tools like Shapley and Multiaccuracy.  Faster AI works for both classification and regression tasks on tabular data.</Typography>
              <p>
                {' '}
                <FontAwesomeIcon icon={faExternalLinkAlt} />
                {' '}
                <a href="https://material.vlifevirtusa.com/faster_ai/faster_ai_v03.pdf" target="_blank" style={{ color: '#FFF' }} rel="noopener noreferrer">
                  {' '}
                  <b>Read More</b>
                  {' '}
                </a>
                {' '}
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <FontAwesomeIcon icon={faExternalLinkAlt} />
                {' '}
                <a href={architecture} target="_blank" style={{ color: '#FFF' }} rel="noopener noreferrer">
                  {' '}
                  <b>Architecture</b>
                  {' '}
                </a>
&nbsp;&nbsp;
                <FontAwesomeIcon icon={faExternalLinkAlt} />
                {' '}
                <a href={`${APIDATA.Z1D}:8506`} target="_blank" style={{ color: '#FFF' }} rel="noopener noreferrer">
                  {' '}
                  <b>ML Flow</b>
                  {' '}
                </a>
              </p>
            </section>

            <Grid container xs={12} spacing={2}>
              <Grid item xs={12} sm={4} md={3}>
                <Panel headerTitle="Analytics Lens" minHeight="300px" icon={<FontAwesomeIcon icon={faChartBar} />}>
                  <DataPanel onDragStart={this.dragstart} options={options} disableDPOptions={options === 'Image Data' || options === 'Upload Image Data'} isModelSetupDone={isModelSetupDone} />
                </Panel>
                <Grid container xs={12} spacing={1}>
                  <Grid item xs={12} sm={8}>
                    <Button variant="contained" className={classes.fasterAiBtn} onClick={this.getResult}>
                      <FontAwesomeIcon icon={faPlayCircle} />
&nbsp;&nbsp; Execute &nbsp;
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Button variant="contained" className={classes.fasterAiBtn} onClick={this.clearProcessPad}>
                      <FontAwesomeIcon icon={faUndo} />
&nbsp;Refresh
                    </Button>
                  </Grid>
                </Grid>

                <Panel headerTitle="Prompt" minHeight="200px" icon={<FontAwesomeIcon icon={faFolderOpen} />}>
                  {prompAlert
                    && prompAlert.map((value) => (
                      <section>
                        <Alert variat="outlined" icon={<CheckIcon fontSize="inherit" />} severity="Success">
                          {Array.isArray(value) ? value[0] : value}
                          {Array.isArray(value) && (
                          <a className={classes.downloadIcon} href={value[1]} download>
                            <CloudDownloadIcon />
                          </a>
                          )}
                        </Alert>
                        <Divider />
                      </section>
                    ))}
                </Panel>
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                <Panel headerTitle="ProcessPad" minHeight="588px" icon={<FontAwesomeIcon icon={faProjectDiagram} />}>
                  <div style={{ minHeight: '250px', padding: '20px' }} onDragOver={this.dragOver} onDrop={this.drop} onItemDropped={this.itemDropped}>
                    <Grid container direction="column" alignItems="center" justify="center">

                      {droppedData
                        ? (
                          <Grid container xs={12} sm={5} direction="row" justify="space-around">
                            {dataElements.length > 0 && (
                            <Grid xs={12}>
                              {dataElements.map((value) => (
                                <FlowChartElement value={value} mainElement showForm={this.showForm} />
                              ))}
                            </Grid>
                            )}
                            {edaElements.length > 0 && (
                            <Grid xs={12} sm={5}>
                              {edaElements.map((value) => (
                                <FlowChartElement value={value} showForm={this.showForm} />
                              ))}
                              {(runSetupElements.length > 0) && ((dataPreElements.length - edaElements.length) > 0) && Array(dataPreElements.length - edaElements.length).fill(1).map((no) => (
                                <Line />
                              ))}

                            </Grid>
                            )}

                            {dataPreElements.length > 0 && (
                            <Grid xs={12} sm={5}>
                              {dataPreElements.map((value) => (
                                <FlowChartElement value={value} showForm={this.showForm} />
                              ))}
                              {(runSetupElements.length > 0) && ((edaElements.length - dataPreElements.length) > 0) && Array(edaElements.length - dataPreElements.length).fill(1).map((no) => (
                                <Line />
                              ))}
                            </Grid>
                            )}
                            {runSetupElements.length > 0 && runSetupElements.map((value) => (

                              <Grid container xs={12} direction="row" justify="center">
                                {(dataPreElements.length && edaElements.length)
                                  ? (
                                    <>
                                      <Grid xs={6}>
                                        <img width="22px" height="30px" className={classes.invertImageColor} src={downArrow} alt="" />
                                      </Grid>
                                      <Grid xs={6}>
                                        <img width="22px" height="30px" className={classes.invertImageColor} src={downArrow} alt="" />
                                      </Grid>
                                    </>
                                  )
                                  : (
                                    <Grid xs={12}>
                                      <img width="22px" height="30px" className={classes.invertImageColor} src={downArrow} alt="" />
                                    </Grid>
                                  )}
                                <Grid xs={12}>
                                  <FlowChartElement value={value} mainElement showForm={this.showForm} />
                                </Grid>
                              </Grid>
                            ))}
                            {modelElements.length > 0 && (
                            <Grid xs={12} sm={5}>
                              {modelElements.map((value) => (
                                <FlowChartElement value={value} showForm={this.showForm} />
                              ))}
                            </Grid>
                            )}
                          </Grid>
                        )

                        : (
                          <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>Drop Here!</Typography>
                            <FontAwesomeIcon icon={faUpload} />
                          </Grid>
                        )}
                    </Grid>
                  </div>
                  {isLoading
                    && (
                    <section>
                      <Spinner text={loadingText} />
                    </section>
                    )}
                </Panel>
                <br />
              </Grid>
            </Grid>
            <div ref={this.myDivToFocus}>

              <Grid containerspacing={1} justify="center">

                <Grid item>
                  <Panel headerTitle="Result" minHeight="225px" noOverflow icon={<FontAwesomeIcon icon={faChartPie} />}>
                    <Paper>
                      <Typography variant="h6" className={classes.resultTitle}>{this.state.resultPanelName}</Typography>
                      <Grid container direction="column" alignItems="center" justify="center">
                        <Grid item xs={12}>
                          {this.fields && (
                            <section>
                              {this.formTable(this.fields, this.dataRows, classes)}
                              {setupModelFields && (
                                <FormControlLabel
                                  control={<Checkbox checked={showTabularData} onChange={this.onChangeFields} name="showTabularData" />}
                                  label="Show Train Data"
                                />
                              )}
                              {ModelString && (
                                <section>
                                  <FormControlLabel
                                    control={<Checkbox checked={showModelString} onChange={this.onChangeFields} name="showModelString" />}
                                    label={this.state.showObjectLabel || "Show Best Model Object"}
                                  />
                                  {showModelString
                                    && (
                                    <section className={classes.modelString}>
                                      <h3>Best Model Object</h3>
                                      <Typography>{ModelString}</Typography>
                                    </section>
                                    )}

                                </section>
                              )}

                            </section>
                          )}

                          {(setupModelFields && showTabularData) && (
                            this.formTable(setupModelFields, modelSetupDatarows, classes)
                          )}
                          {this.state.imagePlots
                            && (
                            <Grid container xs={12} spacing={2} direction="row" justify="center" alignItems="center">
                              <Grid item xs={12} sm={6}>

                                <FormControl className={classes.width80}>
                                  <TextField
                                    className={[classes.leftText, classes.tableMargin]}
                                    id="plots"
                                    select
                                    variant="outlined"
                                    label="Plots"
                                    name="selectedImagePlot"
                                    value={this.state.selectedImagePlot}
                                    onChange={this.onChangeFields}
                                  >
                                    {this.state.imagePlots.map((option) => (
                                      <MenuItem key={option.value || option} value={option.value || option}>
                                        {' '}
                                        {option.label || option}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                </FormControl>

                              </Grid>
                              <Grid item xs={12}>
                                {this.state.selectedImagePlot && <img width="600px" height="600px" src={this.state.selectedImagePlot} alt="" />}
                              </Grid>
                            </Grid>
                            )}
                          {this.state.imageDataUrl && <img width="400px" height="400px" src={this.state.imageDataUrl} alt="" />}
                          {pandasprofileingUrl && <iframe src={pandasprofileingUrl} height="500" width="100%" title="Pandas Profiling" />}
                          {featureList
                            && Array.isArray(featureList)
                            ? (
                              <List aria-labelledby="nested-list-subheader">
                                {featureList.map((list) => (
                                  <ListItem disablePadding>
                                    <ListItemIcon>
                                      <NearMeIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={list} />
                                    <Divider />
                                  </ListItem>
                                ))}
                              </List>
                            )
                            : (
                              <section>
                                {ReactHtmlParser(featureList)}
                              </section>
                            )}
                          {downloadModel && (
                          <section style={{ padding: '10px' }}>
                            <a className={classes.linkButton} href={downloadModel} download>
                              <Button variant="contained" color="primary">
                                {' '}
                                {downloadBtnName}
                                {' '}
&nbsp;
                                <FontAwesomeIcon icon={faDownload} />
                              </Button>
                            </a>
                          </section>
                          )}
                          {showMLFlowLink && (
                          <section className={classes.margin10}>
                            {' '}
                            <a className={classes.linkButton} href={`${APIDATA.Z1D}:8506`} target="_blank" rel="noopener noreferrer">
                              <Button variant="contained" color="primary">
                                {' '}
                                For more details checkout ML Flow &nbsp;
                                <FontAwesomeIcon icon={faExternalLinkAlt} />
                              </Button>
                            </a>
                          </section>
                          )}
                        </Grid>
                      </Grid>

                    </Paper>
                    <br />
                    <Grid container xs={12} spacing={3} direction="row" justify="center" alignItems="center">
                      <Grid item xs={12} sm={6}>
                        <div style={{ display: this.state.showChart ? ' block' : 'none' }} id="beforeMetrics" />

                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div style={{ display: this.state.showChart ? ' block' : 'none' }} id="afterMetrics" />

                      </Grid>
                    </Grid>
                  </Panel>
                </Grid>
              </Grid>
            </div>

          </div>
          <section>
            <Dialog
              onClose={this.closeCommonDialog}
              aria-labelledby="dialog-title"
              open={this.state.openDialog}
              fullWidth="md"
              disableBackdropClick
              disableEscapeKeyDown
            >
              <DialogTitle id="dialog" onClose={this.closeCommonDialog}>
                {this.state.selectedOption}
                <IconButton
                  aria-label="Close"
                  className={classes.closeButton}
                  onClick={this.closeCommonDialog}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <DialogContent dividers>
                {OPTIONS_MAPPING[this.state.selectedOption] && OPTIONS_MAPPING[this.state.selectedOption].popupHtml
                  && (
                  <div>
                    {ReactHtmlParser(OPTIONS_MAPPING[this.state.selectedOption].popupHtml)}
                  </div>
                  )}
                <Typography gutterBottom>
                  <section style={{ paddingTop: '20px' }}>
                    <Grid container xs={12} justify="center" direction="row" alignItems="center">
                      <Grid item xs={8}>
                        {this.state.modelEvaluationFields && (
                          <section>
                            <FormControl className={classes.width100}>
                              {this.state.modelEvaluationFields.map((fields) => (
                                (fields.options
                                  ? fields.isMultiSelect
                                    ? (
                                      <FormControl className={classes.width100}>
                                        <InputLabel id="model-evaluation">{fields.label}</InputLabel>
                                        <Select
                                          id={fields.name}
                                          multiple
                                          variant="outlined"
                                          name={fields.name}
                                          value={this.state[fields.name]}
                                          onChange={this.onChangeFields}
                                          input={<Input />}
                                          renderValue={(selected) => selected.join(', ')}
                                          MenuProps={MenuProps}
                                          error={this.state[`${fields.name}_error`] && 'Required Field'}
                                          helperText={this.state[`${fields.name}_error`] && 'Required Field'}
                                        >
                                          {fields.options && fields[(mlType == 'reg' && fields.regOptions) ? 'regOptions' : 'options'].map((option) => (
                                            <MenuItem key={option.label} value={option.value}>
                                              <Checkbox checked={this.state[fields.name].indexOf(option.value) > -1} />
                                              <ListItemText primary={option.label} />
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    )
                                    : (
                                      <TextField
                                        className={[classes.leftText, classes.tableMargin]}
                                        id={fields.name}
                                        select
                                        variant="outlined"
                                        required
                                        label={fields.label}
                                        defaultValue={fields.setOptions ? mlType === 'cls' ? 'Survived' : 'charges' : ''}
                                        name={fields.name}
                                        value={this.state[this.state[fields.name]]}
                                        onChange={this.onChangeFields}
                                        error={this.state[`${fields.name}_error`] && 'Required Field'}
                                        helperText={this.state[`${fields.name}_error`] && 'Required Field'}
                                      >
                                        {fields.setOptions ? this.state[fields.setOptions] && this.state[fields.setOptions].map((option) => (
                                          <MenuItem key={option.value || option} value={option.value || option}>
                                            {' '}
                                            {option.label || option}
                                          </MenuItem>
                                        ))
                                          : fields[(mlType === 'reg' && fields.regOptions) ? 'regOptions' : 'options'].map((option) => (
                                            <MenuItem key={option.value || option} value={option.value || option}>
                                              {' '}
                                              {option.label || option}
                                            </MenuItem>
                                          ))}
                                      </TextField>
                                    ) : (
                                      <TextField
                                        className={[classes.leftText, classes.tableMargin]}
                                        id={fields.name}
                                        variant="outlined"
                                        label={fields.label}
                                        name={fields.name}
                                        type={fields.type || 'text'}
                                        placeholder={fields.placeholder}
                                        value={this.state[this.state[fields.name]]}
                                        error={this.state[`${fields.name}_error`]}
                                        onChange={this.onChangeFields}
                                        helperText={fields.helperText || (this.state[`${fields.name}_error`] && 'Required Field')}
                                      />
                                  ))))}
                            </FormControl>
                          </section>
                        )}

                        {this.state.noOfInputs
                          && (
                          <Grid container xs={12} spacing={2} justify="center" direction="row" alignItems="center">
                            {this.state.noOfInputs.map((value) => (
                              <Grid item xs={6}>
                                <FormControl className={classes.width100}>
                                  <TextField
                                    className={classes.leftText}
                                    id="outlined-adornment-weight"
                                    variant="outlined"
                                    label="Label"
                                    name={`ageBinningParams${value}`}
                                    placeholder={`Example: a${value}`}
                                    value={this.state[`ageBinningParams${value}`]}
                                    onChange={this.onChangeFields}
                                    error={this.state[`ageBinningParams${value}_error`]}
                                    helperText={this.state[`ageBinningParams${value}_error`] && 'Required Field'}
                                  />
                                </FormControl>
                              </Grid>
                            ))}
                          </Grid>
                          )}

                      </Grid>
                    </Grid>
                  </section>
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button variant="contained" color="primary" className={classes.margin2} onClick={this.validateFields}>Validate Fields</Button>
              </DialogActions>
            </Dialog>
          </section>
          <ToastContainer />
        </div>
      </section>

    );
  }
}
FasterAi.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(MainViewStyle)(FasterAi);
