import React, { memo, useState } from "react";
import styled from "styled-components";
import { Grid, Button } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

// import local components
import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  SolutionContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";

// images
import ace_sol2 from "../../../assets/images/app/ace_sol2.webp";
import ace_sol1 from "../../../assets/images/app/ace_sol1.webp";
import ace_sample1 from "../../../assets/images/app/ace_sample1.webp";
import ace_sample2 from "../../../assets/images/app/ace_sample2.webp";
import ace_sample3 from "../../../assets/images/app/ace_sample3.webp";
import ace_sample4 from "../../../assets/images/app/ace_sample4.webp";
import ace_sample5 from "../../../assets/images/app/ace_sample5.webp";
import ace_sample6 from "../../../assets/images/app/ace_sample6.webp";
import ace_sample7 from "../../../assets/images/app/ace_sample7.webp";
import ace_sample8 from "../../../assets/images/app/ace_sample8.webp";
import ace_sample9 from "../../../assets/images/app/ace_sample9.webp";
import ace_sample10 from "../../../assets/images/app/ace_sample10.webp";
import ace_sample11 from "../../../assets/images/app/ace_sample11.webp";
import ace_sample12 from "../../../assets/images/app/ace_sample12.webp";
import ace_sample13 from "../../../assets/images/app/ace_sample13.webp";
import ace_sample14 from "../../../assets/images/app/ace_sample14.webp";
import ace_sample15 from "../../../assets/images/app/ace_sample15.webp";
import ace_sample16 from "../../../assets/images/app/ace_sample16.webp";

const ACE = () => {
  const [resultData, setResult] = useState(null);
  const [selectedSample, setSample] = useState("");
  const sampleData = {
    sample1: {
      image1: ace_sample1,
      image2: ace_sample2,
      image3: ace_sample3,
      image4: ace_sample4,
      image5: ace_sample5,
      image6: ace_sample11,
      image7: ace_sample12,
      image8: ace_sample13,
      finalText:
        "Please find below the concepts /Salient Features form the class Sunflower images",
      imgText:
        "From this we can infer that a particular image has a high probability to belong to the class Sunflower images if it has above Salient Features in it.",
      result:
        "The training data set has 50 Sunflower images. 5 sample images are below:",
    },
    sample2: {
      image1: ace_sample6,
      image2: ace_sample7,
      image3: ace_sample8,
      image4: ace_sample9,
      image5: ace_sample10,
      image6: ace_sample14,
      image7: ace_sample15,
      image8: ace_sample16,
      finalText:
        "Please find below the concepts /Salient Features form the class Face images",
      imgText:
        "From this we can infer that a particular image has a high probability to belong to the class Face images if it has above Salient Features in it.",
      result:
        "The training data set has 50 face images. 5 sample images are below:",
    },
  };

  // set sample value and selected image
  const setSampleData = (e) => {
    const selectedSample = e.currentTarget.getAttribute("data-sample");
    setResult({
      image1: sampleData[selectedSample].image1,
      image2: sampleData[selectedSample].image2,
      image3: sampleData[selectedSample].image3,
      image4: sampleData[selectedSample].image4,
      image5: sampleData[selectedSample].image5,
      result: sampleData[selectedSample].result,
    });
    setSample(selectedSample);
  };

  // show result based on selected sample
  const showResult = () => {
    setResult({
      ...resultData,
      finalText: sampleData[selectedSample].finalText,
      image6: sampleData[selectedSample].image6,
      image7: sampleData[selectedSample].image7,
      image8: sampleData[selectedSample].image8,
      imgText: sampleData[selectedSample].imgText,
      result: sampleData[selectedSample].result,
    });
  };

  return (
    <SolutionContainer>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Machine learning systems are becoming increasingly employed in
            complex high-stakes settings such as medicine (e.g. radiology, drug
            development), financial technology (e.g. stock price prediction,
            digital financial advisor), and even in law (e.g. case
            summarization, litigation prediction). Despite this increased
            utilization, there is still a lack of sufficient techniques
            available to be able to explain and interpret the decisions of these
            deep learning algorithms. This can be very problematic in some areas
            where the decisions of algorithms must be explainable or
            attributable to certain features due to laws or regulations, or
            where accountability is required. The industry is also recognizing
            explainability as one of the main components of responsible use of
            ML
          </p>
          <center>
            <img src={ace_sol1} alt="" />
          </center>
          <p>
            The higher the interpretability of a machine learning model, the
            easier it is for someone to comprehend why certain decisions or
            predictions have been made. Neural networks are powerful models, but
            harder to interpret than simpler and more traditional models.
          </p>
          <p>
            ACE is one such interpretability tool which automatically extract
            visual concepts that are human-meaningful, coherent and important
            for the neural network’s predictions. ACE takes a trained classifier
            and a set of images of a class as input. It then extracts concepts
            present in that class and returns each concept’s importance.
          </p>
          <center>
            <Image2 src={ace_sol2} alt="" />
          </center>
          <p>
            ACE starts with segmentation of the given class images(50 images per
            class). To capture the complete hierarchy of concepts from simple
            fine-grained ones like textures and colors to more complex and
            coarse-grained ones such as parts and objects, each image is
            segmented with multiple resolutions. Then it groups similar segments
            as examples of the same concept. To measure the similarity of
            segments, each segment is then passed through the CNN(GoogleNet is
            used) to be mapped to the activation space. After resizing each
            segment to the standard input size of the model, similar segments
            are clustered in the activation space and outliers of each cluster
            that have low similarity to cluster’s segments are removed to
            increase coherency of clusters. At last, it returns the important
            concepts from the set of extracted concepts using the TCAV
            concept-based importance score.
          </p>
          <LinkContainer>
            <Grid container spacing={2}>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/ACE/ACE.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://arxiv.org/pdf/1902.03129.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Citations
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <p>Dataset containing 50 images of a class are trained on ACE</p>
          <Grid
            container
            xs={12}
            direction="row"
            alignItems="center"
            alignContent="center"
            justifyContent="center"
          >
            <Grid xs={3}>
              <StyledFigure>
                <StyledButton
                  variant="contained"
                  color="primary"
                  btnColor="#023e8a"
                  data-sample="sample1"
                  onClick={setSampleData}
                >
                  Sample 1
                </StyledButton>
              </StyledFigure>
            </Grid>
            <Grid xs={3}>
              <StyledFigure>
                <StyledButton
                  variant="contained"
                  color="primary"
                  btnColor="#023e8a"
                  data-sample="sample2"
                  onClick={setSampleData}
                >
                  Sample 2
                </StyledButton>
              </StyledFigure>
            </Grid>
          </Grid>
          {resultData && (
            <>
              <Grid
                container
                xs={12}
                direction="row"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12}>
                  <Result>{resultData.result}</Result>
                  <StyledFigure>
                    <Grid container xs={12} direction="row" alignItems="center">
                      <Grid item xs={12} md={4}>
                        <Image src={resultData.image1} alt="" />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Image src={resultData.image2} alt="" />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Image src={resultData.image3} alt="" />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Image src={resultData.image4} alt="" />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Image src={resultData.image5} alt="" />
                      </Grid>
                    </Grid>
                  </StyledFigure>
                </Grid>
                <Grid xs={12}>
                  <StyledButton
                    variant="contained"
                    btnColor="#023e8a"
                    color="primary"
                    onClick={showResult}
                  >
                    Execute
                  </StyledButton>
                </Grid>

                {resultData.imgText && (
                  <Grid item xs={12} justify="center">
                    <StyledFigure>
                      <Result>{resultData.finalText}</Result>
                      <Image1 src={resultData.image6} alt="" />
                      <br />
                      <Image1 src={resultData.image7} alt="" />
                      <br />
                      <Image1 src={resultData.image8} alt="" />
                      <Button color="primary" variant="outlined">{resultData.imgText}</Button>
                    </StyledFigure>
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(ACE);

const StyledFigure = styled.figure`
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #274c77;
`;
const Image = styled.img`
  border-radius: 3px;
  padding: 10px;
  width: 200px !important;
	height: 200px;
`;

const Image1 = styled.img`
  width: 75%;
  @media only screen and (max-width: 900px) {
    width: 100%;
  }
`;

const Image2 = styled.img`
  width: -webkit-fill-available;
`;
