import React, { memo, useState } from "react";
import styled from "styled-components";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Grid } from "@material-ui/core";
import Highcharts from "highcharts";

// local components

import Collapse from "../../common/Collapse";
import CommonService from "../../../utils/services/CommonService";
import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  List,
  ListItem,
} from "../../../styles/common";

// images
import geneticAlgorithm_sol1 from "../../../assets/images/app/geneticA.webp";

const GeneticAlgorithm = () => {
  const [fields, setField] = useState([]);
  const [dataRows, setDataRows] = useState([]);
  const [result, setSample] = useState(false);
  const [modelData, setModelData] = useState(false);
  const [multiAccuracy, setMultiAccuracy] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [showSpinner1, setSpinner1] = useState(false);
  const [showSpinner2, setSpinner2] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "bioInformatics", "geneticAlgorithm",
  );

  const formHighChart = (data1) => {
    const color = ['#b679e5', '#bdef9e'];
    const data = data1.map((value, index) => ({
      name: `${value.Name}${index ? '' : ')'}`,
      data: [value.Value],
      color: color[index],
    }));
    Highcharts.chart('container',
      {
        chart:
        {
          type: 'column',
        },
        title:
        {
          text: 'F1 score comparison graph',
        },
        subtitle:
        {

        },
        xAxis: {
          categories: ['model'],
          title: {
            text: null,
          },
        },
        yAxis:
        {
          min: 0,
          title:
          {
            text: 'F1 Score',

          },
          labels:
          {
            overflow: 'justify',
          },
        },
        tooltip:
        {
          valueSuffix: '',
          headerFormat: '',
        },
        legend:
        {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'middle',
        },
        series: data,
      });
  };

  const getSample = () => {
    setSample('');
    setModelData('');
    setSpinner(true);
    getQueryResult("getDataset")
      .then((res) => {
        setSpinner(false);
        setSample(true);
        const previewData = JSON.parse(res);
        const dataRows = previewData.data;
        const { fields } = previewData.schema;
        setField(fields);
        setDataRows(dataRows);
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
        setNotification({ open: "error", message: err.message });
      });
  };

  const getModelData = () => {
    setModelData('');
    setMultiAccuracy('');
    setSpinner1(true);
    getQueryResult("chooseModel")
      .then((res) => {
        setSpinner1(false);
        setSample(true);
        const previewData = JSON.parse(res);
        setModelData(previewData.data);
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
        setNotification({ open: "error", message: err.message });
      });
  };

  const getMultiAccuracy = () => {
    setMultiAccuracy('');
    setSpinner2(true);

    getQueryResult("retrainModel")
      .then((res) => {
        setSpinner2(false);
        const parsedTableData = JSON.parse(res[0]);
        setMultiAccuracy(parsedTableData.data);
        const parsedChartData = JSON.parse(res[1]);
        formHighChart(parsedChartData.data);
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
        setNotification({ open: "error", message: err.message });
      });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>GGGenetic algorithms are inspired by Darwin's theory about evolution. Solution to a problem solved by genetic algorithms is evolved.</p>
          <p>
            Algorithm is started with a set of solutions (represented by chromosomes) called population. Solutions from one population are taken and used to form a new population.
            This is motivated by a hope, that the new population will be better than the old one. Solutions which are selected to form new solutions (offspring)
            are selected according to their fitness - the more suitable they are the more chances they have to reproduce.
          </p>
          <strong>Outline of the Basic Genetic Algorithm</strong>
          <List>
            <ListItem>[Start] Generate random population of n chromosomes (suitable solutions for the problem)</ListItem>
            <ListItem>[Fitness] Evaluate the fitness f(x) of each chromosome x in the population</ListItem>
            <ListItem>[New population] Create a new population by repeating following steps until the new population is complete</ListItem>
            <ListItem>[Selection] Select two parent chromosomes from a population according to their fitness (the better fitness, the bigger chance to be selected)</ListItem>
            <ListItem>[Crossover] With a crossover probability cross over the parents to form a new offspring (children). If no crossover was performed, offspring is an exact copy of parents.</ListItem>
            <ListItem>[Mutation] With a mutation probability mutate new offspring at each locus (position in chromosome).</ListItem>
            <ListItem>[Accepting] Place new offspring in a new population</ListItem>
            <ListItem>[Replace] Use new generated population for a further run of algorithm</ListItem>
            <ListItem>[Test] If the end condition is satisfied, stop, and return the best solution in current population</ListItem>
            <ListItem>[Loop] Go to step 2</ListItem>
          </List>
          <center>
            <img src={geneticAlgorithm_sol1} alt="" />
          </center>
          <p>
            Solution aims to demonstrate the application of Genetic Algorithm on Machine Learning.
            The DEAP (Deep Evolutionary Algorithms in Python) framework which uses GA as base is used for optimizing Logistic Regression.
            DEAP basically outputs fittest or best features from initial population and best hyper parameters for algorithm during mutation.
            F1 Score is used as a performance metrics for benchmarking comparison between Baseline Model and Optimized Model using DEAP.
          </p>
          <strong>
            DEAP is a novel evolutionary computation framework for rapid prototyping and testing of ideas.
            It seeks to make algorithms explicit and data structures transparent. It works in perfect harmony with parallelisation
            mechanism such as multiprocessing and SCOOP. The following documentation presents the key concepts and many features to build your own evolutions.
          </strong>
          <p>
            <strong>Dataset :</strong>
            {' '}
            EMR data used here to Predict the Patients with Type 2 Diabetes. We are using a National Health and Nutrition Examination Survey (NHANES) to predict Type 2 Diabetes from laboratory test results data. we are using medications & labs data. Medications has got diagnostics details where we select the patients with T2D.
          </p>

        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/DEAP/deap"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/Genetic_algorithm/t2d_deap.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid item xs={12}>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={getSample}
            >
              Sample Data
            </StyledButton>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
            <section>
              <Grid container xs={12} spacing={3} direction="row" alignItems="center" justify="center">
                <Grid item xs={12} sm={12} md={12}>
                  <Paper>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {fields.map((field) => (
                            <TableCell key={field.name} align="left">
                              {' '}
                              <strong>{field.name}</strong>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dataRows.map((row) => (
                          <TableRow key={row.index}>
                            {fields.map((field) => (
                              <TableCell align="left">
                                {field.name === "index"
                                  ? row[field.name] + 1
                                  : row[field.name]}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={getModelData}
                  >
                    Logistic Model
                  </StyledButton>
                </Grid>
              </Grid>
              {showSpinner1 && <Spinner text="Loading..." />}
              {modelData
                && (
                  <section>
                    <Grid container xs={12} spacing={3} direction="row" alignItems="center" justify="center">
                      <Grid item xs={10} sm={6} md={4}>
                        <p><strong>Base Model Metrics</strong></p>
                        <TableDiv>
                          <TableHead>
                            <TableRow>
                              <StyledTableHead align="left">Metric</StyledTableHead>
                              <StyledTableHead align="left">Value</StyledTableHead>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow key="0">
                              <TableCell align="left">{modelData[0].Metric}</TableCell>
                              <TableCell align="left">{modelData[0].Value}</TableCell>
                            </TableRow>
                          </TableBody>
                        </TableDiv>
                      </Grid>
                      <Grid item xs={8} sm={6} md={6}>
                        <StyledButton
                          variant="contained"
                          color="primary"
                          onClick={getMultiAccuracy}
                        >
                          Retrain with DEAP
                        </StyledButton>
                      </Grid>
                    </Grid>
                    {showSpinner2 && <Spinner text="Loading..." />}
                    {multiAccuracy
                      && (
                        <section>
                          <Grid container xs={12} spacing={3} direction="row" alignItems="center" justify="center">
                            <Grid item xs={10} sm={6} md={4}>
                              <p><strong>Optimized Model Metrics</strong></p>
                              <TableDiv>
                                <TableHead>
                                  <TableRow>
                                    <StyledTableHead align="left">Metric</StyledTableHead>
                                    <StyledTableHead align="left">Value</StyledTableHead>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow key="0">
                                    <TableCell align="left">{multiAccuracy[0].Metric}</TableCell>
                                    <TableCell align="left">{multiAccuracy[0].Value}</TableCell>
                                  </TableRow>
                                </TableBody>
                              </TableDiv>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6}>
                              <div id="container" />
                            </Grid>
                          </Grid>
                        </section>
                      )}
                  </section>
                )}
            </section>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};
export default memo(GeneticAlgorithm);

const Paper = styled.div`
  overflow: auto;
`;
const StyledTableHead = styled(TableCell)`
  background-color: #3c40af;
  color: white;
  font-weight: bold;
`;
const TableDiv = styled(Table)`
@media only screen and (max-width: 900px) {
    margin-left: 3%;
  }
  @media only screen and (max-width: 450px) {
    margin-left: 4%;
  }
`;
