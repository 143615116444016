import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import copy from 'copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle, faCity, faClipboard, faEye, faMapMarkerAlt, faMapPin, faPhone, faUser,
} from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles({
  root: {
    height: '100%',
    textAlign: 'left',
    backgroundColor: '#0080FF',
    color: 'white',
  },
  link: {
    textDecoration: 'none',
    color: 'white',
  },
  textCenter: {
    textAlign: 'center',
  },
});

export default function ImgMediaCard({
  name, city, address, number, zipcode, rating, utility, userName, password1, link, s3Link, video, btnClick, s3LinkButtonName, cardAsLink,
}) {
  const classes = useStyles();
  const [isUserCopied, copyUser] = useState(false);
  const [isPwdCopied, copyPwd] = useState(false);
  const [s3Url, setS3Url] = useState(false);

  function copyToClipboard(text, e) {
    const name = e.currentTarget.id;
    copy(text);
    if (name === 'userName') {
      copyUser(true);
    } else {
      copyPwd(true);
    }
  }
  function fetchUrl() {
    fetch(s3Link, {
      method: "GET",
      headers: {
        authorization: "",
        "cache-control": "no-cache",
      },
    }).then((res) => res.json())
      .then((res) => {
        console.log(name, res);
        setS3Url(res);
      });
  }
  useEffect(() => {
    s3Link && fetchUrl();
  }, []);

  return (
    <Card className={classes.root}>
      <CardActionArea className={classes.link} component={cardAsLink && 'a'} href={cardAsLink} target="_blank" rel="noopener noreferrer">
        <CardContent>
          {name && (
          <Typography className={classes.textCenter} gutterBottom variant="h6" component="h5">
            {name}
          </Typography>
          )}
          {city && (
          <Typography variant="body2" component="p">
            <FontAwesomeIcon icon={faCity} />
            {' '}
            {city}
          </Typography>
          )}
          {address && (
          <Typography variant="body2" component="p">
            <FontAwesomeIcon icon={faMapMarkerAlt} />
            {' '}
            {address}
          </Typography>
          )}
          {number && (
          <Typography variant="body2" component="p">
            <FontAwesomeIcon icon={faPhone} />
            {' '}
            {number}
          </Typography>
          )}
          {zipcode && (
          <Typography variant="body2" component="p">
            <FontAwesomeIcon icon={faMapPin} />
            {' '}
            {zipcode}
          </Typography>
          )}

          {utility && (
          <Typography gutterBottom variant="h6subtitle1" className={classes.textCenter} component="p">
            {utility}
            <br />
          </Typography>
          )}
          {userName && (
          <Typography variant="body2" component="p">
            <FontAwesomeIcon icon={faUser} />
            {' '}
            {userName}
            {' '}
            {isUserCopied ? <FontAwesomeIcon icon={faCheckCircle} color="#00C642" /> : <FontAwesomeIcon id="userName" onClick={copyToClipboard.bind(this, userName)} icon={faClipboard} />}
          </Typography>
          )}
          {password1 && (
          <Typography variant="body2" component="p">
            <FontAwesomeIcon icon={faEye} />
            {' '}
            {password1}
            {' '}
            {isPwdCopied ? <FontAwesomeIcon icon={faCheckCircle} color="#00C642" /> : <FontAwesomeIcon id="password1" onClick={copyToClipboard.bind(this, password1)} icon={faClipboard} />}
          </Typography>
          )}

          {rating && (
          <Typography variant="body2" component="p">
            <Rating
              name="rating"
              value={rating}
            />
          </Typography>
          )}
          {link && (
          <div className={classes.textCenter}>
            <Button color="default"><a className={classes.link} href={link} target="_blank" rel="noopener noreferrer"> Click Here</a></Button>
          </div>
          )}
          {s3Link && (
          <div className={classes.textCenter}>
            <Button color="default">
              <a className={classes.link} href={s3Url} target="_blank" rel="noopener noreferrer">
                {' '}
                {s3LinkButtonName || 'Download'}
              </a>
            </Button>
          </div>
          )}
          {video && (
          <div className={classes.textCenter}>
            <Button color="default" className={classes.link} onClick={() => btnClick(video[0])}> Video 1</Button>
            <Button color="default" className={classes.link} onClick={() => btnClick(video[1])}> Video 2</Button>
          </div>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
