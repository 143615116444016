import React, { memo } from "react";
import { Grid } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components
import Collapse from "../../common/Collapse";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  List,
  ListItem,
} from "../../../styles/common";

const TurbofanTycoon = () => {
  return (

    <SolutionContainer>
      <Collapse text="Description">
        <Paragraph>
          <p>Federated Learning is a paradigm in which machine learning models are trained on decentralized data. Instead of collecting data on a single server or data lake, it remains in place—on smartphones, industrial sensing equipment, and other edge devices—and models are trained on-device. The trained models are transferred to a central server and combined.   </p>
          <p>Federated learning makes it possible to build machine learning systems without direct access to training data. The data remains in its original location, which helps to ensure privacy and reduces communication costs. </p>
          <p>In the prototype below, the user plays as the owner of a factory that wants to do a better job of maintaining its turbofan engines. The options are: </p>
          <List>
            <ListItem>
              A corrective maintenance strategy (i.e., waiting for the engines to fail)
            </ListItem>
            <ListItem>
              A preventative maintenance strategy (i.e., maintaining each engine at a fixed time, hopefully some time before it fails)
            </ListItem>
            <ListItem>
              A local predictive maintenance machine learning model, trained only on your failed engines
            </ListItem>
            <ListItem>
              A federated predictive maintenance machine learning model, trained on the collective data of 80 factories (including yours), using federated learning
            </ListItem>
          </List>
          <p><strong>Application : </strong></p>
          <p>Federated Learning solves a major issue of privacy of sensitive medical and healthcare data as the data is decentralized and only the model is shared. Though it is still not foolproof, and it can be subjected to adversarial attacks. The usual protection against this attack is differential privacy.</p>
          <p>FL can be used Hospital care management, resource management, disease prediction and disease prevention among many other use cases. </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://turbofan.fastforwardlabs.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Source
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://blog.fastforwardlabs.com/2018/11/14/federated-learning-distributed-machine-learning-with-data-locality-and-privacy.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read more
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>

      <Collapse text='Demo'>
        <DemoContainer>
          <center>
            <iframe
              src='https://turbofan.fastforwardlabs.com/'
              width='100%'
              height='650'
            />
          </center>
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
}

export default memo(TurbofanTycoon);
