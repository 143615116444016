import React, { memo, useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components

import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import SolutionContainer from "../../common/SolutionContainerWrapper";

import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";

// images
import eegOnSensoryTask_sol1 from "../../../assets/images/app/eeg_sensory_task_desc.webp";
import { getUploadToken } from "../../../utils";

const EEGOnSensoryTask = () => {
  const initialData = {
    Fz_N100: '',
    FCz_N100: '',
    Cz_N100: '',
    FC3_N100: '',
    FC4_N100: '',
    C3_N100: '',
    C4_N100: '',
    CP3_N100: '',
    CP4_N100: '',
    Fz_P200: '',
    FCz_P200: '',
    Cz_P200: '',
    FC3_P200: '',
    FC4_P200: '',
    C3_P200: '',
    C4_P200: '',
    CP3_P200: '',
    CP4_P200: '',
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [result, SetResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "z1dApps", "eegOnSensoryTask",
  );
  const Token = getUploadToken()

  const showSample1 = () => {
    const NewDataSet = {
      Fz_N100: '29.3777761904762',
      FCz_N100: '26.0372714285714',
      Cz_N100: '26.9193952380952',
      FC3_N100: '14.0887047619048',
      FC4_N100: '27.3182333333333',
      C3_N100: '14.656380952381',
      C4_N100: '28.1465952380952',
      CP3_N100: '16.7087523809524',
      CP4_N100: '24.2472095238095',
      Fz_P200: '41.312643902439',
      FCz_P200: '37.8490195121951',
      Cz_P200: '33.2521658536585',
      FC3_P200: '23.1558902439024',
      FC4_P200: '23.1788268292683',
      C3_P200: '20.0055804878049',
      C4_P200: '24.9598073170732',
      CP3_P200: '17.204887804878',
      CP4_P200: '19.3004731707317',
    };

    setSampleData(NewDataSet);
  };

  const showSample2 = () => {
    const NewDataSet = {
      Fz_N100: '-2.80133333333333',
      FCz_N100: '-2.74701904761905',
      Cz_N100: '-2.03318571428571',
      FC3_N100: '-1.3987380952381',
      FC4_N100: '0.887633333333333',
      C3_N100: '-3.54975714285714',
      C4_N100: '-0.963752380952381',
      CP3_N100: '-3.22447142857143',
      CP4_N100: '-0.170819047619048',
      Fz_P200: '-15.2495414634146',
      FCz_P200: '-16.6725682926829',
      Cz_P200: '-12.7589682926829',
      FC3_P200: '-9.44300975609756',
      FC4_P200: '-7.77729024390244',
      C3_P200: '-10.7981317073171',
      C4_P200: '-6.22426585365854',
      CP3_P200: '-12.0656902439024',
      CP4_P200: '-4.47586829268293',
    };

    setSampleData(NewDataSet);
  };

  const getResult = () => {
    const {
      Fz_N100, FCz_N100, Cz_N100, FC3_N100, FC4_N100, C3_N100, C4_N100, CP3_N100, CP4_N100, Fz_P200, FCz_P200, Cz_P200, FC3_P200,
      FC4_P200, C3_P200, C4_P200, CP3_P200, CP4_P200,
    } = sampleData;

    const params = `${Fz_N100},${FCz_N100},${Cz_N100},${FC3_N100},${FC4_N100},${C3_N100},${C4_N100},${CP3_N100},
        ${CP4_N100},${Fz_P200},${FCz_P200},${Cz_P200},${FC3_P200},${FC4_P200},${C3_P200},${C4_P200},${CP3_P200},${CP4_P200},${Token.token}`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });
    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      SetResultData("");
      setSpinner(true);

      getQueryResult("withParams", params)
        .then((res) => {
          setSpinner(false);
          SetResultData(res);
        })
        .catch((err) => {
          setSpinner(false);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>Electroencephalography, or EEG, is an electrophysiological, typically non-invasive, monitoring method that is used to record electrical activity in your brain. EEG tests are very effective in diagnosing various medical and health conditions such as brain tumors, seizures, head injuries, epilepsy, etc.  Analysis of brain wave patterns can also help in understanding what physical actions are being performed by a particular person. For example, we can predict whether a patient’s eyes are open or closed by analyzing the brain wave pattern. This can be used to understand a patient’s behavioral pattern, and how this is related to their mental health.</p>
          <p>Whenever a human performs an activity, be it moving a limb or moving their eyes from left to right, the nervous system generates a “corollary discharge” to notify the brain and other sensory organs of the impending motion. This helps the body to adjust to the imminent motor act. This application uses a machine learning model to analyze the pattern created by the N100-P200 auditory sensory gating and classify those signals according to the type of trial conducted on the particular subject which resulted in the brain wave pattern. EEG recording was made for a person subjected to a simple button-pressing task in which subjects either (a) pressed a button to immediately generated a tone, or (b) passively listened to the same tone to study the corollary discharge.</p>
          <p>
            The data consists of nearly 16,000 individual trials. Due to the size of the raw data of all 64 channels for all subjects, the data used only includes event-related potential (ERP) averages for 9 electrodes:  Fz, FCz, Cz, FC3, FC4, C3, C4, CP3, CP4 (pictured below), as analyzed in
            <a href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4059422/" target="_blank" rel="noopener noreferrer">this </a>
            report.
          </p>
          <center>
            <Grid item xs={8} sm={6} md={6}>
              <img width="60%" src={eegOnSensoryTask_sol1} />
            </Grid>
          </center>
          <p>
            {' '}
            <strong> Application:</strong>
            {' '}
          </p>
          <p> EEG differences can be observed between the corollary discharge produced for self-induced auditory stimulation and auditory response to periodically generated noises, which means that such methods can be used in healthcare. Analysis of brain wave patterns can also help in understanding and early identification of certain mental health issues.</p>
          <p>One mental health illness that affects approximately 1% of people globally is Schizophrenia. Although schizophrenia may be caused due to various reasons, one possible explanation for a few of those symptoms is damage or faults with the corollary discharge process in the nervous system. This makes it extremely difficult for schizophrenic patients to differentiate between self-generated or externally generated stimuli. They are mostly observed to not suppress the N100-P200 in cases of self-generated auditory stimulation, as opposed to the comparison controls.</p>
          <p>Therefore, studying this process and its relationship to symptoms in the illness might allow us to better understand abnormal brain processes in patients with this diagnosis.</p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.kaggle.com/broach/button-tone-sz?select=mergedTrialData.csv"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/SensoryEEG/SensoryEEG.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo for Sample">
        <DemoContainer>
          <Grid container direction="row" justify="left" spacing={2}>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                name="Fz_N100"
                label="Fz_N100"
                fullWidth
                value={sampleData.Fz_N100}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                name="FCz_N100"
                label="FCz_N100"
                fullWidth
                value={sampleData.FCz_N100}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                name="Cz_N100"
                label="Cz_N100"
                fullWidth
                value={sampleData.Cz_N100}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                name="FC3_N100"
                label="FC3_N100"
                fullWidth
                value={sampleData.FC3_N100}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                name="FC4_N100"
                label="FC4_N100"
                fullWidth
                value={sampleData.FC4_N100}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                name="C3_N100"
                label="C3_N100"
                fullWidth
                value={sampleData.C3_N100}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                name="C4_N100"
                label="C4_N100"
                fullWidth
                value={sampleData.C4_N100}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                name="CP3_N100"
                label="CP3_N100"
                fullWidth
                value={sampleData.CP3_N100}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                name="CP4_N100"
                label="CP4_N100"
                fullWidth
                value={sampleData.CP4_N100}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                name="Fz_P200"
                label="Fz_P200"
                fullWidth
                value={sampleData.Fz_P200}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                name="FCz_P200"
                label="FCz_P200"
                fullWidth
                value={sampleData.FCz_P200}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                name="Cz_P200"
                label="Cz_P200"
                fullWidth
                value={sampleData.Cz_P200}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                name="FC3_P200"
                label="FC3_P200"
                fullWidth
                value={sampleData.FC3_P200}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                name="FC4_P200"
                label="FC4_P200"
                fullWidth
                value={sampleData.FC4_P200}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                name="C3_P200"
                label="C3_P200"
                fullWidth
                value={sampleData.C3_P200}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                name="C4_P200"
                label="C4_P200"
                fullWidth
                value={sampleData.C4_P200}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                name="CP3_P200"
                label="CP3_P200"
                fullWidth
                value={sampleData.CP3_P200}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                name="CP4_P200"
                label="CP4_P200"
                fullWidth
                value={sampleData.CP4_P200}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={showSample1}
              >
                Sample Data1
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={showSample2}
              >
                Sample Data2
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={getResult}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
          <section>
            <Result>
              <strong>Result:</strong>
              {`${result}`}
            </Result>
          </section>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(EEGOnSensoryTask);
