import React, { memo, useState } from 'react';
import { Grid, TextField, MenuItem, FormControl } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

// local components
import styled from 'styled-components';
import Highcharts from 'highcharts';

import Collapse from '../../common/Collapse';
import Spinner from '../../common/loading/Spinner';
import SolutionContainer from '../../common/SolutionContainerWrapper';
import CommonService from '../../../utils/services/CommonService';
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  HighchartContainer,
} from '../../../styles/common';

// images
import liverDiseasePrediction_sol1 from '../../../assets/images/app/liverDiseasePrediction_sol1.webp';
import { getUploadToken } from '../../../utils';

const LiverDiseasePrediction = () => {
  // This is the initial state of the DOM
  const initialData = {
    Age: '',
    Gender: '',
    Total_Bilirubin: '',
    Direct_Bilirubin: '',
    Alkaline_Phosphotase: '',
    Alamine_Aminotransferase: '',
    Aspartate_Aminotransferase: '',
    Total_Protiens: '',
    Albumin: '',
    Albumin_and_Globulin_Ratio: '',
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState('');

  const { getQueryResult } = CommonService('patientEngagement', 'liverDisease');
  const Token = getUploadToken();

  const formHighChart = (data) => {
    Highcharts.chart('container_1', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      title: {
        text: 'Prediction of Liver Disease',
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          },
        },
      },
      series: [
        {
          name: 'feature importance',
          colorByPoint: true,
          data: [
            {
              name: data[1][0],
              y: data[1][1],
            },
            {
              name: data[2][0],
              y: data[2][1],
            },
            {
              name: data[3][0],
              y: data[3][1],
            },
            {
              name: data[4][0],
              y: data[4][1],
            },
            {
              name: data[5][0],
              y: data[5][1],
            },
            {
              name: data[6][0],
              y: data[6][1],
            },
            {
              name: data[7][0],
              y: data[7][1],
            },
            {
              name: data[8][0],
              y: data[8][1],
            },
            {
              name: data[9][0],
              y: data[9][1],
            },
            {
              name: data[10][0],
              y: data[10][1],
            },
          ],
        },
      ],
    });
  };

  const showResult = () => {
    const NewDataSet = {
      Age: '65',
      Gender: '0',
      Total_Bilirubin: '0.7',
      Direct_Bilirubin: '0.1',
      Alkaline_Phosphotase: '187',
      Alamine_Aminotransferase: '16',
      Aspartate_Aminotransferase: '18',
      Total_Protiens: '6.6',
      Albumin: '3.3',
      Albumin_and_Globulin_Ratio: '0.90',
    };
    setSampleData(NewDataSet);
  };
  const showResult2 = () => {
    const NewDataSet = {
      Age: '25',
      Gender: '0',
      Total_Bilirubin: '0.9',
      Direct_Bilirubin: '0.3',
      Alkaline_Phosphotase: '159',
      Alamine_Aminotransferase: '24',
      Aspartate_Aminotransferase: '25',
      Total_Protiens: '6.9',
      Albumin: '4.4',
      Albumin_and_Globulin_Ratio: '1.70',
    };
    setSampleData(NewDataSet);
  };

  const getResult = () => {
    setResultData('');

    const {
      Age,
      Gender,
      Total_Bilirubin,
      Direct_Bilirubin,
      Alkaline_Phosphotase,
      Alamine_Aminotransferase,
      Aspartate_Aminotransferase,
      Total_Protiens,
      Albumin,
      Albumin_and_Globulin_Ratio,
    } = sampleData;

    const params = `${Age},${Gender},${Total_Bilirubin},${Direct_Bilirubin},${Alkaline_Phosphotase},${Alamine_Aminotransferase},${Aspartate_Aminotransferase},${Total_Protiens},${Albumin},${Albumin_and_Globulin_Ratio},${Token.token}`;
    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === '') {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setNotification({ open: 'error', message: 'Fields are empty' });
    } else {
      setSpinner(true);

      getQueryResult('output', params)
        .then((res) => {
          setSpinner(false);
          setResultData(res);
          console.log(res);
          formHighChart(res);
        })
        .catch((err) => {
          setSpinner(false);
          console.log(err);
          setNotification({ open: 'error', message: err.message });
        });
    }
  };

  const onChangeFields = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            The liver is an organ about the size of a football. It sits just
            under your rib cage on the right side of your abdomen. The liver is
            essential for digesting food and ridding your body of toxic
            substances.
          </p>
          <p>
            Liver disease can be inherited (genetic). Liver problems can also be
            caused by a variety of factors that damage the liver, such as
            viruses, alcohol use and obesity. Over time, conditions that damage
            the liver can lead to scarring (cirrhosis), which can lead to liver
            failure, a life-threatening condition. But early treatment may give
            the liver time to heal.
          </p>
          <p>
            Symptoms of liver diseases include weakness and fatigue, weight
            loss, nausea, vomiting, and yellow discoloration of the skin
            (jaundice).
          </p>
          <p>
            This Machine Learning model helps to evaluate prediction algorithms
            in an effort to reduce burden on doctors
          </p>
          <p>
            This dataset was downloaded from the UCI ML Repository:
            <br />
            Lichman, M.(2013). UCI Machine Learning Repository
            [http://archive.ics.uci.edu/ml]. Irvine, CA: University of
            California, School of Information and Computer Science.
            <br />
            This data set contains 416 liver patient records and 167 non liver
            patient records collected from North East of Andhra Pradesh, India.
            This data set contains 441 male patient records and 142 female
            patient records.
          </p>
          <p>
            The algorithm predicts the chances of a person prone to liver
            diseases by taking chemical and demographic features and shows the
            probability distribution of the features in the prediction
          </p>
          <h4>Exploratory Data Analysis</h4>
          <p>
            The correlation between all the features of the dataset is given by
            the below matrix
          </p>
          <img width="80%" height="400px" src={liverDiseasePrediction_sol1} />
          <br />
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Liver_disease_prediction"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.kaggle.com/datasets/uciml/indian-liver-patient-records"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Data source
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <section>
            <Grid container direction="row" justify="center">
              <Grid item xs={12} md={3}>
                <StyledFormControl minWidth="120" varient="outlined" fullWidth>
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Age"
                    name="Age"
                    value={sampleData.Age}
                    onChange={onChangeFields}
                  />
                  <br />
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Alamine Aminotransferase"
                    name="Alamine_Aminotransferase"
                    value={sampleData.Alamine_Aminotransferase}
                    onChange={onChangeFields}
                  />
                  <br />
                </StyledFormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <StyledFormControl minWidth="120" varient="outlined" fullWidth>
                  <TextField
                    id="outlined-adornment-weight"
                    select
                    variant="outlined"
                    label="Gender"
                    name="Gender"
                    value={sampleData.Gender}
                    onChange={onChangeFields}
                  >
                    <MenuItem key={0} value={0}>
                      Male
                    </MenuItem>
                    <MenuItem key={1} value={1}>
                      Female
                    </MenuItem>
                  </TextField>
                  <br />
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Total Protiens"
                    name="Total_Protiens"
                    value={sampleData.Total_Protiens}
                    onChange={onChangeFields}
                  />
                  <br />
                </StyledFormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <StyledFormControl minWidth="120" varient="outlined" fullWidth>
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Total Bilirubin"
                    name="Total_Bilirubin"
                    value={sampleData.Total_Bilirubin}
                    onChange={onChangeFields}
                  />
                  <br />
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Albumin"
                    name="Albumin"
                    value={sampleData.Albumin}
                    onChange={onChangeFields}
                  />
                  <br />
                </StyledFormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <StyledFormControl minWidth="120" varient="outlined" fullWidth>
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Direct Bilirubin"
                    name="Direct_Bilirubin"
                    value={sampleData.Direct_Bilirubin}
                    onChange={onChangeFields}
                  />
                  <br />
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Alkaline Phosphotase"
                    name="Alkaline_Phosphotase"
                    value={sampleData.Alkaline_Phosphotase}
                    onChange={onChangeFields}
                  />
                  <br />
                </StyledFormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledFormControl minWidth="120" varient="outlined" fullWidth>
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Aspartate Aminotransferase"
                    name="Aspartate_Aminotransferase"
                    value={sampleData.Aspartate_Aminotransferase}
                    onChange={onChangeFields}
                  />
                  <br />
                </StyledFormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledFormControl minWidth="120" varient="outlined" fullWidth>
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Albumin and Globulin Ratio"
                    name="Albumin_and_Globulin_Ratio"
                    value={sampleData.Albumin_and_Globulin_Ratio}
                    onChange={onChangeFields}
                  />
                  <br />
                </StyledFormControl>
              </Grid>
              <Grid item xs={12}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={showResult}
                >
                  Sample 1
                </StyledButton>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={showResult2}
                >
                  Sample 2
                </StyledButton>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={getResult}
                >
                  Execute
                </StyledButton>
              </Grid>
            </Grid>
          </section>
          {showSpinner && <Spinner text="Loading..." />}
          {resultData && (
            <section>
              <Result>
                <strong>Result:</strong>
                {`${resultData[0]}`}
              </Result>
              <Grid container direction="row" justify="center">
                <Grid item xs={12}>
                  <HighchartContainer id="container_1" display />
                </Grid>
              </Grid>
            </section>
          )}
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(LiverDiseasePrediction);

const StyledFormControl = styled(FormControl)`
  @media only screen and (min-width: 900px) {
    width: 75%;
  }
`;
