import React, { memo, useState } from 'react';
import styled from 'styled-components';
import {
 
  LinkContainer,
  
} from '../../../styles/common';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import {
  Grid,
  TextField,
  MenuItem,
  AppBar,
  Input,
  Tab,
  ListItem,
  InputLabel,
  Select,
  Checkbox,
  ListItemText,
  Paper,
} from '@material-ui/core';
import Collapse from '../../common/Collapse';
import Spinner from '../../common/loading/Spinner';
import { Paragraph, StyledButton, DemoContainer } from '../../../styles/common';
import SolutionContainer from '../../common/SolutionContainerWrapper';
import { StyledTabs } from './IOTRecipesView';
import { TabPanel } from './vnet/components/ResultPanel';
import JsonDataTable from '../../common/JsonDataTable';
import commonServices from '../../../utils/services/CommonService';
import getUserData, { getUploadToken } from '../../../utils';
import { OverViewList } from './vnet/components/DataResultTab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faCloud,
  faExclamationCircle,
  faFileUpload,
  faInfoCircle,
  faLightbulb,
  faTable,
} from '@fortawesome/free-solid-svg-icons';
import { StyledFormControl } from './vnet/features/Dataviz/ScatterPlot';
import ZoomImage from '../../common/ZoomImage';

const { postQueryResult: postQueryRes, uploadFilesWithBody } = commonServices(
  'pyod1',
  'pyod1'
);
const Token = getUploadToken()
const { postQueryResult } = commonServices('z1dApps', 'differentialPrivacy');

const metricsData = {
  kstest_score: {
    label: 'KS Test Score',
    desc: 'This metric uses the two-sample Kolmogorov–Smirnov test to compare the distributions of continuous columns using the empirical Cumulative Distribution Function. .K-S score should be a high value (Max =1.0) when the fit is good and a low value (Min = 0.0) when the fit is not good. When the K-S value goes below 0.05, you will be informed that the Lack of fit is significant.',
  },
  gmloglikelihood_score: {
    label: 'GM loglikelihood Score',
    desc: 'The metrics compare the tables by fitting the real data to a probabilistic model and afterwards compute the likelihood of the synthetic data belonging to the learned distribution. The higher the value of the log-likelihood, the better a model fits a dataset. The log-likelihood value for a given model can range from negative infinity to positive infinity.',
  },
  logdetection_score: {
    label: 'Log detection score',
    desc: 'The metric evaluate how hard it is to distinguish the synthetic data from the real data by using a Machine Learning model. The metrics will shuffle the real data and synthetic data together with flags indicating whether the data is real or synthetic, and then cross validate a Machine Learning model that tries to predict this flag.',
  },
  multiclassdecisiontreeclassifier_score: {
    label: 'Multiclassification tree classifier score',
    desc: 'This metrics will evaluate whether it is possible to replace the real data with synthetic data in order to solve a Machine Learning Problem by learning a Machine Learning model on the synthetic data and then evaluating the score which it obtains when evaluated on the real data.',
  },
};

const DifferentialPrivacy = () => {
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState('');
  const [value, setValue] = useState(0);
  const [dpTabvalue, setdpTabvalue] = useState(0);
  const [imgTabvalue, setimgTabvalue] = useState(0);
  const [data, setData] = useState(null);
  const [userId, setUserId] = useState(getUserData()?.token?.emailid);
  const [dpData, setDpData] = useState(null);
  const [score, setScore] = useState(null);
  const [scoreimg, setScoreImg] = useState(null);
  const [metaData, setMetaData] = useState({
    isDataValid: false,
    isSample: false,
  });
  const [fileData, setFileData] = useState({ file: '', name: '' });
  const [fields, setFields] = useState({
    epsilon: '2',
    delta: '0.0001',
    sigma: '4',
  });
  const [dpfields, setDPFields] = useState({
    epsilon: '5',
    noOfShadowModel: '2',
    epochs: '10',
    train_test_sp: '0.2',
  });

  const tabhandleChange = (event, newValue) => {
    setValue(newValue);
  };
  const dphandletabChange = (event, newValue) => {
    setdpTabvalue(newValue);
  };
  const imghandletabChange = (event, newValue) => {
    setimgTabvalue(newValue);
  };
  const handleOnChange = (e) => {
    setFields({
      ...fields,
      [e.target.name]: e.target.value,
    });
  };
  const handledpOnChange = (e) => {
    setDPFields({
      ...dpfields,
      [e.target.name]: e.target.value,
    });
  };

  const getSampleResult = () => {
    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;
    setSpinner(true);
    setData(null);
    setMetaData(null);
    postQueryRes('sampleupload', {
      token: Token.token,
      Context_param: {
        Application_name: 'dp_data',
        Execution_time: date,
        UserID: userId,
      },
      Content_param: {
        Dataset_Name: 'heartattack.csv',
        s3Path: 'dp_data/sample/heartattack.csv',
      },
    })
      .then((res) => {
        setSpinner(false);
        if (res.Error_Flag) {
          setNotification({ open: 'error', message: res.message });
          return;
        }
        setNotification({ open: 'success', message: res.Console });
        setData({ ...res?.Result, Application_name: 'dp_data' });
      })
      .catch((err) => {
        setNotification({ open: 'error', message: err.message });
        setSpinner(false);
      });
  };
  const onChangeFile = (e) => {
    const file = e.target.files[0];
    const filename = e.target.files[0].name;
    setFileData({ file: file, name: filename });
  };

  const generateDpData = () => {
    setSpinner(true);
    let params = {
      token: Token.token,
      Context_param: {
        Application_name: data.Application_name,
        UserID: userId,
      },
      Content_param: {
        s3Path: data.path,
        Dataset_Name: data.Dataset_Name,
        target_column_name: fields.target,
        epsilon: fields.epsilon,
        sigma: fields.sigma,
        delta: fields.delta,
      },
    };
    console.log(params);
    postQueryResult('generateSynthetic', params)
      .then((res) => {
        setSpinner(false);
        if (res.Error_Flag) {
          setNotification({ open: 'error', message: res.message });
          return;
        }
        setDpData(res?.Result);
      })
      .catch((err) => {
        setNotification({ open: 'error', message: err.message });
        setSpinner(false);
      });
  };
  const generateLRScore = () => {
    setSpinner(true);
    let params = {
      token: Token.token,
      Context_param: {
        Application_name: data.Application_name,
        UserID: userId,
      },
      Content_param: {
        synthetics3Path: dpData.synthetics3Path,
        Dataset_Name: data?.Dataset_Name,
        sensitivefield: fields.sensitivefield,
        keyfields: fields.keyfields,
      },
    };

    postQueryResult('generateScore', params)
      .then((res) => {
        setSpinner(false);
        if (res.Error_Flag) {
          setNotification({ open: 'error', message: res.message });
          return;
        }
        setDpData({ ...dpData, lrScore: res.Result });
      })
      .catch((err) => {
        setNotification({ open: 'error', message: err.message });
        setSpinner(false);
      });
  };

  const getUploadResult = () => {
    const current = new Date();
    const date = `${current.getDate()}/${
      current.getMonth() + 1
    }/${current.getFullYear()}`;

    if (!fileData.file) {
      setNotification({ open: 'error', message: 'please upload a file' });
      return;
    }
    setData(null);
    setSpinner(true);
    setMetaData(null);

    uploadFilesWithBody('upload', {
      file: fileData.file,
      Context_param: JSON.stringify({
        Application_name: 'Differntial Privacy',
        UserID: userId,
        Execution_time: date,
      }),
      Content_param: JSON.stringify({ Dataset_Name: fileData.name }),
    })
      .then((res) => {
        setSpinner(false);
        if (res.Error_Flag) {
          setNotification({ open: 'error', message: res.message });
          return;
        }
        setNotification({ open: 'success', message: res.Console });
        setData({ ...res?.Result, Application_name: 'Differntial Privacy' });
      })
      .catch((err) => {
        setNotification({ open: 'error', message: err.message });
        setSpinner(false);
      });
  };
  const validateData = () => {
    setSpinner(true);
    let params = {
      token: Token.token,
      Context_param: {
        Application_name: data.Application_name,
        UserID: userId,
      },
      Content_param: { s3Path: data.path, target_column_name: fields.target },
    };
    postQueryResult('validate', params)
      .then((res) => {
        setSpinner(false);
        setMetaData({
          ...metaData,
          isDataValid: !res.Error_Flag,
          validInfo: res?.Result,
        });
      })
      .catch((err) => {
        setNotification({ open: 'error', message: err.message });
        setSpinner(false);
      });
  };
  const getModelScore = () => {
    setSpinner(true);
    setScore('');
    setScoreImg('');
    let params = {
      token: Token.token,
      Context_param: {
        Application_name: data.Application_name,
        UserID: userId,
      },
      Content_param: {
        s3Path: data.path,
        Dataset_Name: data.Dataset_Name,
        target_column_name: fields.target,
        epsilon: dpfields.epsilon,
        noOfShadowModel: dpfields.noOfShadowModel,
        epochs: dpfields.epochs,
        train_test_sp: dpfields.train_test_sp,
      },
    };
    console.log(params);
    postQueryResult('generateNonDPModel', params)
      .then((res) => {
        setSpinner(false);
        if (res.Error_Flag) {
          setNotification({ open: 'error', message: res.message });
          return;
        }
        setScore(res?.Result);
      })
      .catch((err) => {
        setNotification({ open: 'error', message: err.message });
        setSpinner(false);
      });
  };
  const getScoreImg = () => {
    setSpinner(true);
    setScoreImg('');
    let params = {
      token: Token.token,
      Context_param: {
        Application_name: data.Application_name,
        UserID: userId,
      },
      Content_param: {
        s3Path: data.path,
        Dataset_Name: data.Dataset_Name,
        target_column_name: fields.target,
        epsilon: dpfields.epsilon,
        noOfShadowModel: dpfields.noOfShadowModel,
        epochs: dpfields.epochs,
        train_test_sp: dpfields.train_test_sp,
      },
    };
    console.log(params);
    postQueryResult('generateNonDPModelImg', params)
      .then((res) => {
        setSpinner(false);
        if (res.Error_Flag) {
          setNotification({ open: 'error', message: res.message });
          return;
        }
        setScoreImg(res?.Result);
      })
      .catch((err) => {
        setNotification({ open: 'error', message: err.message });
        setSpinner(false);
      });
  };
  const handleMultiple = (e) => {
    setFields({
      ...fields,
      keyfields:
        typeof e.target.value === 'string'
          ? e.target.value.split(',')
          : e.target.value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Maintaining an individual’s privacy is a major concern when
            collecting sensitive information from groups or organizations. A
            formalization of privacy, known as differential privacy, has become
            the gold standard with which to protect information from malicious
            agents. Differential privacy offers some of the most stringent known
            theoretical privacy guarantees. Intuitively, for some query on some
            dataset, a differentially private algorithm produces an output,
            regulated by a privacy parameter (Epsilon), that is statistically
            indistinguishable from the same query on the same dataset had any
            one individual’s information been removed. This powerful tool has
            been adopted by researchers and industry leaders, and has become
            particularly interesting to machine learning practitioners, who hope
            to leverage privatized data in training predictive models.
          </p>
          <p>
            Because differential privacy often depends on adding noise, the
            results of differentially private algorithms can come at the cost of
            data accuracy and utility. However, differentially private machine
            learning algorithms have shown promise across a number of domains.
            These algorithms can provide tight privacy guarantees while still
            producing accurate predictions. A drawback to most methods, however,
            is in the one-off nature of training: once the model is produced,
            the privacy budget for a real dataset can be entirely consumed. The
            differentially private model is therefore inflexible to retraining
            and difficult to share/verify: the output model is a black box.
          </p>
          <p>
            This can be especially disadvantageous in the presence of high
            dimensional data that require rigorous training techniques like
            dimensionality reduction or feature selection. With limited budget
            to spend, data scientists cannot exercise free range over a dataset,
            thus sacrificing model quality. In an effort to remedy this, and
            other challenges faced by traditional differentially private methods
            for querying, we can use differentially private techniques for
            synthetic data generation, investigate the privatized data, and
            train informed supervised learning model.
          </p>
          <p>
            <b>Differentially Private Synthetic Dataset:</b>
            The concept of synthetic data seems to be having “a moment” in the
            privacy world as a promising approach to sharing data while
            protecting privacy. A synthetic dataset is a stand-in for some
            original dataset that has the same format, and accurately reflects
            the statistical properties of the original dataset but contains only
            “fake” records. Intuitively, a synthetic dataset can be used as if
            it were the real data, compute summary statistics from it, train
            models from it but because the records do not correspond to “real”
            people, we don’t have to worry about protecting privacy. But simply
            making data “synthetic” does not guarantee privacy in any meaningful
            sense of the word and we need to be careful about what it actually
            means to generate private synthetic data.
          </p>
          <p>
            <b>DP-GAN:</b>
            Generative Adversarial Network (GANs) and its variants provide a
            promising direction in the studies where data availability is
            limited. One common issue in GANs is that the density of the learned
            generative distribution could concentrate on the training data
            points, meaning that they can easily remember training samples due
            to the high model complexity of deep networks. This becomes a major
            concern when GANs are applied to private or sensitive data such as
            patient medical records, and the concentration of distribution may
            divulge critical patient information. To address this issue,
            differentially private GAN (DPGAN) model was introduced, in which we
            achieve differential privacy in GANs by adding carefully designed
            noise to gradients during the learning procedure.
          </p>
          <p>
            <b>
              For more details, please refer:
              <a href="https://arxiv.org/pdf/1802.06739.pdf">
                https://arxiv.org/pdf/1802.06739.pdf
              </a>
            </b>
          </p>
        </Paragraph>

        <LinkContainer>
          <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/stefanrmmr/differentially_private_synthetic_data/blob/main/dpwgan_borealis_heart_disease.ipynb"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.kaggle.com/datasets/nareshbhat/health-care-data-set-on-heart-attack-possibility?select=heart.csv"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <DemoHeader>
            <h4>
              {' '}
              <FontAwesomeIcon icon={faInfoCircle} color="#4caf50" />{' '}
              &nbsp;Dataset -Requisite
            </h4>
            <ul>
              <li>Dataset should be in csv format</li>
              <li>Dataset must not contain missing values</li>
              <li>Dataset should not have categorical columns</li>
              <li>The target column must be binary</li>
              <li>Dataset should be continuous</li>
            </ul>
            <h4>
              <FontAwesomeIcon icon={faTable} /> &nbsp; Choose Dataset
            </h4>
            <p>
              Please Upload your own dataset or Click on the "Select Sample
              File" to continue
            </p>
            <Grid container>
              <Grid item xs={10} sm={6} md={3}>
                <Input
                  type="file"
                  color="primary"
                  name="uploadedFile"
                  onChange={onChangeFile}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={getUploadResult}
                >
                  Upload
                </StyledButton>
              </Grid>
              <Grid item xs={12} sm={12} md={1}>
                <h3>OR</h3>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={getSampleResult}
                >
                  Select Sample File
                </StyledButton>
              </Grid>
            </Grid>
            {data?.dfHead && (
              <Grid container xs={12} spacing={2} direction="row">
                <Grid item xs={12}>
                  <AppBar position="static" color="default">
                    <StyledTabs
                      value={value}
                      onChange={tabhandleChange}
                      aria-label="simple tabs example"
                    >
                      <Tab label="Data Sample" />
                      <Tab label="Data Overview" />
                      <Tab label="Data Statistics" />
                    </StyledTabs>
                  </AppBar>
                  <TabPanel value={value} index={0}>
                    <JsonDataTable
                      fields={data?.dfHead?.schema?.fields}
                      dataRows={data?.dfHead?.data}
                    />
                  </TabPanel>

                  <TabPanel value={value} index={1}>
                    {data?.dfDescribe && (
                      <JsonDataTable
                        fields={data?.dfDescribe?.schema?.fields}
                        dataRows={data?.dfDescribe?.data}
                      />
                    )}
                  </TabPanel>

                  <TabPanel value={value} index={2}>
                    {data?.dfOverview && (
                      <OverViewList tyee="none">
                        {Object.entries(data.dfOverview).map((data) => (
                          <ListItem>
                            {' '}
                            <FontAwesomeIcon icon={faCheckCircle} />
                            {`${data[0]} : ${data[1]}`}
                          </ListItem>
                        ))}
                      </OverViewList>
                    )}
                  </TabPanel>
                </Grid>
              </Grid>
            )}
            <hr />
            {data?.columnNames && (
              <>
                <p>
                  Please select an intended target column for model prediction
                  and click on "Validate Uploaded file"
                </p>
                <Grid container xs={12} spacing={2} direction="row">
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name="target"
                      label="Choose target"
                      variant="outlined"
                      onChange={handleOnChange}
                      select
                      size="small"
                      fullWidth
                      value={fields?.target}
                    >
                      <MenuItem key="modelType" value="">
                        Choose Target
                      </MenuItem>
                      {data?.columnNames?.map((value) => (
                        <MenuItem key={value} value={value}>
                          {value}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <StyledButton
                      variant="contained"
                      color="secondary"
                      onClick={validateData}
                    >
                      Validate Uploaded file
                    </StyledButton>
                  </Grid>
                </Grid>
              </>
            )}
            <ValidInfoContainer>
              {metaData?.validInfo &&
                Object.keys(metaData?.validInfo).map((key) => (
                  <span>
                    <FontAwesomeIcon
                      color={
                        metaData?.validInfo[key]?.error ? '#d13f48' : '#4caf50'
                      }
                      icon={
                        metaData?.validInfo[key]?.error
                          ? faExclamationCircle
                          : faCheckCircle
                      }
                    />
                    &nbsp;&nbsp;{metaData?.validInfo[key]?.message}
                  </span>
                ))}
            </ValidInfoContainer>
            {metaData?.isDataValid && (
              <>
                <AppBar position="static" color="default">
                  <StyledTabs
                    value={value}
                    onChange={tabhandleChange}
                    aria-label="simple tabs"
                  >
                    <Tab label="DP on Data" />
                    <Tab label="DP on Model" />
                  </StyledTabs>
                </AppBar>
                <TabPanel value={value} index={0}>
                  <ul>
                    <li>
                      <strong>Epsilon:</strong> Epsilon is your quantitative
                      privacy guarantee. It gives a ceiling on how much the
                      probability of a particular output can increase if you
                      were to add or remove a single training example. Stringent
                      privacy needs usually require an epsilon value of less
                      than one. Since this is a capability demonstration of DP
                      on vlife, we have restricted the values of Epsilon between
                      1 to 5.
                    </li>
                    <li>
                      <strong>Delta:</strong> Delta is a bound on the external
                      risk that won’t be restricted by epsilon. External risk is
                      that which inherently exists no matter what you do with
                      your dataset. Since this is a capability demonstration of
                      DP on vlife, we have restricted the values of Delta
                      between 0.0001 to 0.1.
                    </li>
                    <li>
                      <strong>Sigma:</strong> Sigma is Gaussian noise variance
                      multiplier. A larger sigma will make the model train for
                      longer epochs for the same privacy budget Since this is a
                      capability demonstration of DP on vlife, we have
                      restricted the values of Sigma between 2 to 6.
                    </li>
                  </ul>
                  <Grid
                    container
                    xs={12}
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                  >
                    <Grid item xs={12} sm={3}>
                      <TextField
                        name="epsilon"
                        label="Choose Epsilon"
                        variant="outlined"
                        onChange={handleOnChange}
                        size="small"
                        select
                        fullWidth
                        value={fields.epsilon}
                        placeholder="Ex: 2"
                      >
                        {['1', '2', '3', '4', '5'].map((value) => (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        name="delta"
                        label="Choose Delta"
                        variant="outlined"
                        onChange={handleOnChange}
                        size="small"
                        fullWidth
                        select
                        placeholder="Ex: 0.0001"
                        value={fields.delta}
                      >
                        {['0.0001', '0.001', '0.01'].map((value) => (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        name="sigma"
                        label="Choose Sigma"
                        variant="outlined"
                        onChange={handleOnChange}
                        size="small"
                        fullWidth
                        value={fields.sigma}
                        placeholder="Ex: 4"
                        select
                      >
                        {['2', '3', '4', '5', '6'].map((value) => (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <StyledButton
                        variant="contained"
                        color="secondary"
                        onClick={generateDpData}
                      >
                        Generate DP Dataset
                      </StyledButton>
                    </Grid>
                  </Grid>
                  {dpData && (
                    <>
                      <p>
                        Please find below the Differentially Private Synthetic
                        Data generated using the algorithm DP-WGAN.
                      </p>
                      <Grid container xs={12} spacing={3}>
                        <Grid item xs={12}>
                          <AppBar position="static" color="default">
                            <StyledTabs
                              value={dpTabvalue}
                              onChange={dphandletabChange}
                              aria-label="simple tabs example"
                            >
                              <Tab label="Data Sample" />
                              <Tab label="Data Overview" />
                              <Tab label="Data Statistics" />
                            </StyledTabs>
                          </AppBar>
                          <TabPanel value={dpTabvalue} index={0}>
                            {dpData?.dfHead && (
                              <JsonDataTable
                                fields={dpData?.dfHead?.schema?.fields}
                                dataRows={dpData?.dfHead?.data}
                              />
                            )}
                          </TabPanel>

                          <TabPanel value={dpTabvalue} index={1}>
                            {dpData?.dfDescribe && (
                              <JsonDataTable
                                fields={dpData?.dfDescribe?.schema?.fields}
                                dataRows={dpData?.dfDescribe?.data}
                              />
                            )}
                          </TabPanel>

                          <TabPanel value={dpTabvalue} index={2}>
                            {dpData?.dfOverview && (
                              <OverViewList tyee="none">
                                {Object.entries(dpData.dfOverview).map(
                                  (data) => (
                                    <ListItem>
                                      {' '}
                                      <FontAwesomeIcon icon={faCheckCircle} />
                                      {`${data[0]} : ${data[1]}`}
                                    </ListItem>
                                  )
                                )}
                              </OverViewList>
                            )}
                          </TabPanel>
                        </Grid>
                      </Grid>
                      <h4>Metrics</h4>
                      <Grid container xs={12} spacing={3}>
                        {dpData?.metrics &&
                          Object.entries(dpData?.metrics)?.map((data) => (
                            <Grid item xs={12} sm={6}>
                              <Metrics elevation={3}>
                                <strong id="title">
                                  <FontAwesomeIcon
                                    icon={faLightbulb}
                                    color="#4caf50"
                                  />{' '}
                                  {metricsData[data[0]]?.label}
                                </strong>
                                <p id="label">{metricsData[data[0]]?.desc}</p>
                                <strong id="label"> Score: {data[1]}</strong>
                              </Metrics>
                            </Grid>
                          ))}
                        {dpData?.roc_img_url && (
                          <Grid item xs={12} sm={6}>
                            <StyledFigure>
                              <figcaption>
                                ROC curves for Original data and Synthetic Data
                              </figcaption>
                              <ZoomImage
                                src={dpData?.roc_img_url}
                                width="100%"
                                height="auto"
                              />
                            </StyledFigure>
                          </Grid>
                        )}
                        {dpData?.corr_img_url && (
                          <Grid item xs={12} sm={6}>
                            <StyledFigure>
                              <figcaption>
                                Correlation matrix for Original Data and
                                Synthetic Data
                              </figcaption>
                              <ZoomImage
                                src={dpData?.corr_img_url}
                                width="100%"
                                height="auto"
                              />
                            </StyledFigure>
                          </Grid>
                        )}
                      </Grid>
                      <br />
                      <h4>
                        <FontAwesomeIcon icon={faInfoCircle} color="#4caf50" />{' '}
                        &nbsp;Privacy Metrics
                      </h4>
                      <p>
                        Please select a column which is considered to be
                        "Sensitive" and can be predicted using "Key Fields". It
                        is basically to understand if an attacker can predict
                        sensitive attributes in the real dataset. Please make
                        sure that feature selected in "Sensitive field" is not
                        present in "Key fields".
                      </p>
                      <Grid container xs={12} spacing={2} direction="row">
                        <Grid item xs={12} sm={3}>
                          <TextField
                            name="sensitivefield"
                            label="Sensitive Field"
                            variant="outlined"
                            onChange={handleOnChange}
                            select
                            fullWidth
                            value={fields?.sensitivefield}
                          >
                            {dpData?.columnNames?.map((value) => (
                              <MenuItem key={value} value={value}>
                                {value}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <StyledFormControl>
                            <InputLabel id="model-evaluation">
                              Key Fields
                            </InputLabel>
                            <Select
                              multiple
                              variant="outlined"
                              name="keyfields"
                              value={fields?.keyfields || []}
                              onChange={handleMultiple}
                              input={<Input />}
                              fullWidth
                              required
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 48 * 4.5 + 8,
                                    width: 250,
                                  },
                                },
                              }}
                              renderValue={(selected) => selected.join(', ')}
                            >
                              {dpData?.columnNames?.map((value) => (
                                <MenuItem
                                  key={value}
                                  value={value}
                                  disabled={value == fields?.sensitivefield}
                                >
                                  <Checkbox
                                    checked={
                                      fields?.keyfields?.indexOf(value) > -1
                                    }
                                  />
                                  <ListItemText primary={value} />
                                </MenuItem>
                              ))}
                            </Select>
                          </StyledFormControl>
                        </Grid>

                        <Grid item xs={12} sm={3}>
                          <StyledButton
                            variant="contained"
                            color="secondary"
                            onClick={generateLRScore}
                          >
                            Compare privacy
                          </StyledButton>
                        </Grid>
                        {dpData?.lrScore && (
                          <Grid item xs={12}>
                            <Metrics elevation={3}>
                              <p>
                                {' '}
                                <strong id="title">
                                  <FontAwesomeIcon
                                    icon={faLightbulb}
                                    color="#4caf50"
                                  />{' '}
                                  &nbsp;Privacy Metrics
                                </strong>
                              </p>
                              <p id="label">
                                This metrics measures the privacy of a synthetic
                                dataset by posting the question: given the
                                synthetic data, can an attacker predict
                                sensitive attributes in the real dataset? These
                                models accomplish this by fitting an adversarial
                                attacker model on the synthetic data to predict
                                sensitive attributes from “key” attributes and
                                then evaluating its accuracy on the real data. A
                                privacy score of 1 indicates that the dataset is
                                highly private whereas a score of 0 indicates
                                that the dataset is not private.
                              </p>
                              <span id="label">
                                {' '}
                                <strong>Score on Original Data: </strong>
                                {dpData?.lrScore['numericalLRRealVSReal_score']}
                              </span>
                              <br />
                              <span id="label">
                                <strong>
                                  {' '}
                                  Score on Differentially Private Synthetic Data
                                  :
                                </strong>{' '}
                                {
                                  dpData?.lrScore[
                                    'numericalLRRealVSSynth_score'
                                  ]
                                }
                              </span>
                            </Metrics>
                          </Grid>
                        )}
                      </Grid>
                    </>
                  )}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <br />
                  <ul>
                    <li>
                      <strong>Epsilon:</strong> Epsilon is your quantitative
                      privacy guarantee. It gives a ceiling on how much the
                      probability of a particular output can increase if you
                      were to add or remove a single training example. Stringent
                      privacy needs usually require an epsilon value of less
                      than one. Since this is a capability demonstration of DP
                      on vlife, we have restricted the values of Epsilon between
                      1 to 5.
                    </li>
                    <li>
                      <strong>Shadow Models:</strong> Shadow Model has the
                      similar architecture as your target model. The training
                      datasets of the target and shadow models have the same
                      format but are disjoint. The training datasets of the
                      shadow models may overlap. All models' internal parameters
                      are trained independently. Due to capability demonstration
                      of DP on vlife, we have restricted the values of no of
                      shadow models between 2 to 8.
                    </li>
                    <li>
                      <strong>Epochs:</strong> Epoch is number of times the
                      entire training data will be passed to the machine
                      learning algorithm. The value of epochs has been
                      restricted to 10,20 and 50.
                    </li>
                    <li>
                      <strong>Train test split:</strong> It is the ratio you
                      want to divide your dataset into training data and test
                      data. For example : a 0.2 split will mean 20% of data is
                      saved for test and 80% goes for training the model. The
                      value of train test split is restricted between 0.2, 0.25
                      and 0.3
                    </li>
                  </ul>
                  <br />
                  <Grid
                    container
                    xs={12}
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                  >
                    <Grid item xs={12} sm={3}>
                      <TextField
                        name="epsilon"
                        label="Choose Epsilon"
                        variant="outlined"
                        onChange={handledpOnChange}
                        size="small"
                        select
                        fullWidth
                        value={dpfields.epsilon}
                        placeholder="Ex: 2"
                      >
                        {['1', '2', '3', '4', '5'].map((value) => (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        name="noOfShadowModel"
                        label="No Of ShadowModel"
                        variant="outlined"
                        onChange={handledpOnChange}
                        size="small"
                        fullWidth
                        select
                        placeholder="Ex: 1"
                        value={dpfields.noOfShadowModel}
                      >
                        {['2', '3', '4', '5', '6', '7', '8'].map((value) => (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        name="epochs"
                        label="Epochs"
                        variant="outlined"
                        onChange={handledpOnChange}
                        size="small"
                        fullWidth
                        value={dpfields.epochs}
                        placeholder="Ex: 10"
                        select
                      >
                        {['10', '20', '50'].map((value) => (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        name="train_test_sp"
                        label="Train test split"
                        variant="outlined"
                        onChange={handledpOnChange}
                        size="small"
                        select
                        fullWidth
                        value={dpfields.train_test_sp}
                        placeholder="Ex: 0.2"
                      >
                        {['0.2', '0.25', '0.3'].map((value) => (
                          <MenuItem key={value} value={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <p>
                      In non private model, target model is normal deep learning
                      ANN model. After training the model with training data.
                      Accuracy of model is calculated on test data.
                    </p>
                    <p>
                      In differentially private model, target model is
                      architecture is same as non-private model. But here
                      differential privacy is added while training the model.
                      Accuracy of model is calculated on test data.
                    </p>

                    <Grid item xs={12} sm={3}>
                      <StyledButton
                        variant="contained"
                        color="secondary"
                        onClick={getModelScore}
                      >
                        Generate Model Score
                      </StyledButton>
                    </Grid>
                  </Grid>
                  {score && (
                    <>
                      <Grid
                        container
                        xs={12}
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                      >
                        <Grid item xs={12} sm={12}>
                          <p>
                            {' '}
                            <strong id="title">
                              <FontAwesomeIcon
                                icon={faLightbulb}
                                color="#4caf50"
                              />{' '}
                              &nbsp;Model Score
                            </strong>
                          </p>
                          <span id="label">
                            {' '}
                            <strong>
                              <u>Non Private Model Score:</u>{' '}
                            </strong>
                            {score['non_privatemodel_score']}
                          </span>
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <span id="label">
                            <strong>
                              <u>Differentially Private Model Score :</u>
                            </strong>{' '}
                            {score['privatemodel_score']}
                          </span>
                        </Grid>
                        <p>
                          As we can see, when privacy is added; the accuracy of
                          model is compromised. In other words, if we keep
                          increasing privacy budget (i.e., epsilon) accuracy of
                          model will start going down. Hence a tradeoff between
                          epsilon and accuracy is required.
                        </p>
                        <Grid item xs={12} sm={3}>
                          <StyledButton
                            variant="contained"
                            color="secondary"
                            onClick={getScoreImg}
                          >
                            Generate Privacy Metrics
                          </StyledButton>
                        </Grid>
                      </Grid>
                      {scoreimg && (
                        <>
                          <Grid container xs={12} spacing={3}>
                            <Grid item xs={12}>
                              <p>
                                The privacy of a machine learning model will be
                                evaluated using the ML privacy meter package. It
                                uses membership inference attack, where given a
                                data point it is predicted if the data point is
                                used in target model training or not (i.e.,
                                member or non-member).
                              </p>
                            </Grid>
                            <Grid item xs={12}>
                              <AppBar position="static" color="default">
                                <StyledTabs
                                  value={imgTabvalue}
                                  onChange={imghandletabChange}
                                  aria-label="simple tabs example"
                                >
                                  <Tab label="Confusion Matrix" />
                                  <Tab label="ROC Curve" />
                                  <Tab label="Signal Histogram" />
                                </StyledTabs>
                              </AppBar>
                              <TabPanel value={imgTabvalue} index={0}>
                                <Grid container xs={12} spacing={3}>
                                  <Grid item xs={12} sm={12}>
                                    <center>
                                      <strong>
                                        Confusion matrix plot, shows the
                                        performace of the classification model.
                                        It compares the actual target values
                                        with those predicted by the machine
                                        learning model. This gives us a holistic
                                        view of how well our classification
                                        model is performing and what kinds of
                                        errors it is making. Data point part of
                                        training data is <u>member class.</u>{' '}
                                        Data point not part of training data is{' '}
                                        <u>non-member class.</u>
                                      </strong>
                                    </center>
                                  </Grid>
                                  <Grid item xs={6} sm={6}>
                                    <StyledFigure>
                                      <figcaption>Non Private Model</figcaption>
                                      <ZoomImage
                                        src={
                                          scoreimg?.nonPrivate?.confusion_matrix
                                        }
                                        width="100%"
                                        height="auto"
                                      />
                                    </StyledFigure>
                                  </Grid>
                                  <Grid item xs={6} sm={6}>
                                    <StyledFigure>
                                      <figcaption>
                                        Differentially Private Model
                                      </figcaption>
                                      <ZoomImage
                                        src={
                                          scoreimg?.Private?.confusion_matrix
                                        }
                                        width="100%"
                                        height="auto"
                                      />
                                    </StyledFigure>
                                  </Grid>
                                </Grid>
                              </TabPanel>
                              <TabPanel value={imgTabvalue} index={1}>
                                <Grid container xs={12} spacing={3}>
                                  <br />
                                  <center>
                                    <strong>
                                      ROC curve (receiver operating
                                      characteristic curve) shows the
                                      performance of the classification model
                                      (binary classifier). Higher the AUC (area
                                      under the curve) score, greater the data
                                      leakage from model is. A random guess has
                                      an AUC score of 0.5 i.e., chances of being
                                      a member or non-member is 50 per cent.
                                    </strong>
                                  </center>
                                  <Grid item xs={6} sm={6}>
                                    <StyledFigure>
                                      <figcaption>Non Private Model</figcaption>
                                      <ZoomImage
                                        src={scoreimg?.nonPrivate?.roc_curve}
                                        width="100%"
                                        height="auto"
                                      />
                                    </StyledFigure>
                                  </Grid>
                                  <Grid item xs={6} sm={6}>
                                    <StyledFigure>
                                      <figcaption>
                                        Differentially Private Model
                                      </figcaption>
                                      <ZoomImage
                                        src={scoreimg?.Private?.roc_curve}
                                        width="100%"
                                        height="auto"
                                      />
                                    </StyledFigure>
                                  </Grid>
                                </Grid>
                              </TabPanel>
                              <TabPanel value={imgTabvalue} index={2}>
                                <Grid container xs={12} spacing={3}>
                                  <br />
                                  <center>
                                    <strong>
                                      In histogram plot, there is blue and
                                      orange histogram representing member and
                                      non-member respectively. Higher the
                                      difference between the height of these two
                                      histogram, greater the data leakage from
                                      the model is.
                                    </strong>
                                  </center>
                                  <Grid item xs={6} sm={6}>
                                    <StyledFigure>
                                      <figcaption>Non Private Model</figcaption>
                                      <ZoomImage
                                        src={
                                          scoreimg?.nonPrivate?.signal_histogram
                                        }
                                        width="100%"
                                        height="auto"
                                      />
                                    </StyledFigure>
                                  </Grid>
                                  <Grid item xs={6} sm={6}>
                                    <StyledFigure>
                                      <figcaption>
                                        Differentially Private Model
                                      </figcaption>
                                      <ZoomImage
                                        src={
                                          scoreimg?.Private?.signal_histogram
                                        }
                                        width="100%"
                                        height="auto"
                                      />
                                    </StyledFigure>
                                  </Grid>
                                </Grid>
                              </TabPanel>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                </TabPanel>
              </>
            )}
            {showSpinner && (
              <Spinner text="Loading.. Please wait for a minute..." />
            )}
          </DemoHeader>
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};
export default memo(DifferentialPrivacy);

const ValidInfoContainer = styled.div`
  text-align: left;
  padding-bottom: 15px;
  span {
    display: block;
    padding: 5px;
  }
`;
const Metrics = styled(Paper)`
  &.MuiPaper-root {
    padding: 10px;
    text-align: justify;
    height: 100%;
    #title {
      padding-bottom: 4px;
      border-bottom: 1px solid #4caf50;
    }
    #label {
      font-size: small;
    }
  }
`;
const DemoHeader = styled.div`
  text-align: left;
  h4 {
    border-bottom: 1px solid;
  }
`;
const StyledFigure = styled.figure`
  font-size: smaller;
  text-align: center;
`;
