import React, { memo, useState } from "react";
import { Grid, TextField, MenuItem } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Highcharts from "highcharts";

// local components
import Collapse from "../../common/Collapse";

import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  HighchartContainer,
} from "../../../styles/common";
import { getUploadToken } from "../../../utils";

const MedicalExpensePrediction = () => {
  const initialData = {
    age: '',
    bmi: '',
    children: '',
    region: '',
    smoker: '',
    gender: '',
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [result, SetResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService("payerProvider", "insurancePremium");
  const Token = getUploadToken();

  const gender = [
    { name: "Female", value: "female" },
    { name: "Male", value: "male" },
  ];

  const boolean = [
    { name: "Yes", value: "yes" },
    { name: "No", value: "no" },
  ];

  const regionOptions = [
    { name: 'North East', value: 'northeast' },
    { name: 'North West', value: 'northwest' },
    { name: 'South East', value: 'southeast' },
    { name: 'South West', value: 'southwest' },
  ];

  const showSample1 = () => {
    const NewDataSet = {
      age: "35",
      bmi: "27.9",
      children: "1",
      smoker: "yes",
      region: "southwest",
      gender: "male",
    };

    setSampleData(NewDataSet);
  };

  const showSample2 = () => {
    const NewDataSet = {
      age: "33",
      bmi: "27.7",
      children: "0",
      smoker: "no",
      region: "northwest",
      gender: "female",
    };

    setSampleData(NewDataSet);
  };

  const getResult = () => {
    SetResultData(false);

    const {
      age, bmi, children, smoker, region, gender,
    } = sampleData;

    const params = `${age},${bmi},${children},${smoker},${region},${gender},${Token.token}`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });
    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("withParams", params)
        .then((res) => {
          console.log(res);
          setSpinner(false);
          SetResultData(res.result[0]);

          const percentages = Object.entries(res.result[1]);
          const params = [];

          percentages.map((arr) => (
            params.push([arr[0], arr[1]])
          ));
          Highcharts.chart('container', {
            chart: {
              type: 'column',
            },
            title: {
              text: "Feature Importance Scores",

            },
            xAxis: {
              type: "category",
              title: {
                text: "Feature",
              },
            },
            yAxis: {
              title: {
                text: "Feature Importance Scores",
              },
            },
            legend: {
              enabled: false,
            },
            plotOptions: {
              series: {
                borderWidth: 0,
                dataLabels: {
                  enabled: true,
                  format: "{point.y:.1f}",
                },
              },
            },
            tooltip: {
              headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
              pointFormat:
                                '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}</b><br/>',
            },
            series: [
              {
                name: "Percentage",
                colorByPoint: true,
                data: params,
              },
            ],
          });
        })
        .catch((err) => {
          console.log(err);
          setSpinner(false);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const handleInputs = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>A specific amount, known as premium, must be paid by the policy-holder to the health-plan provider to invest in health coverage.</p>
          <p>
            The premium for each individual is calculated by estimating the overall risk posed by that particular insured individual taking into account
            various features such as age, gender, lifestyle, medical history, and job, among others.
          </p>
          <p>
            In this POC, a Linear Regression model is built to predict the total medical expense of an individual by taking into account several features
            (such as age, physical condition, family conditions, area of living etc.), using which medical insurance providers can calculate the premium to be charged.
          </p>
          <p>The dataset used is obtained from the Machine Learning course website by Professor Eric Suess (Spring 2017).</p>
          <strong>Application : </strong>
          <p>The predicted medical expenses can be used by insurance companies to determine the customized insurance premiums of the individuals.</p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/medicalexpenseprediction/MedicalExpensePrediction.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.kaggle.com/noordeen/insurance-premium-prediction"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>

      <Collapse text="Demo">
        <DemoContainer>
          <Grid container direction="row" justify="left" spacing={2}>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                label="Age"
                name="age"
                fullWidth
                onChange={handleInputs}
                value={sampleData.age}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                label="BMI"
                name="bmi"
                fullWidth
                onChange={handleInputs}
                value={sampleData.bmi}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                label="Children"
                name="children"
                fullWidth
                onChange={handleInputs}
                value={sampleData.children}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="region"
                label="Region"
                value={sampleData.region}
                onChange={handleInputs}
              >
                {regionOptions.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="smoker"
                label="Smoker"
                value={sampleData.smoker}
                onChange={handleInputs}
              >
                {boolean.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                select
                fullWidth
                name="gender"
                label="Gender"
                value={sampleData.gender}
                onChange={handleInputs}
              >
                {gender.map((data) => (
                  <MenuItem value={data.value}>{data.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={showSample1}
              >
                Sample Data1
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={showSample2}
              >
                Sample Data2
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={getResult}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          {' '}
          <br />
          <br />
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
          <Result>
            <strong>Result:</strong>
            {`${result}`}
          </Result>
          )}
          <HighchartContainer
            id="container"
            display={result}
          />
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(MedicalExpensePrediction);
