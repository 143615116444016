import React, { memo, useState } from "react";
import { Grid, TextField, FormControl } from "@material-ui/core";
import styled from "styled-components";

// local components

import Highcharts from "highcharts";
import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import CommonService from "../../../utils/services/CommonService";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  HighchartContainer,
  Result,
} from "../../../styles/common";
import getUserData, { getUploadToken } from '../../../utils';

const PredictingCerebralInfarction = () => {
  const initialData = {
    age: "",
    dailyLivingScore: "",
    qualityOfLifeScore: "",
    physicalFunctioningScore: "",
    rolephysicalScore: "",
    bodilyPainScore: "",
    generalHealthScore: "",
    vitalityScore: "",
    socialFunctioningScore: "",
    roleEmotionScore: "",
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "mlModels",
    "retrieveStrokePrediction",
  );
  const Token = getUploadToken()

  const showResult = () => {
    const NewDataSet = {
      age: "58",
      dailyLivingScore: "50.15",
      qualityOfLifeScore: "71.15",
      physicalFunctioningScore: "3.76",
      rolephysicalScore: "6.31",
      bodilyPainScore: "2.75",
      generalHealthScore: "3.23",
      vitalityScore: "3.11",
      socialFunctioningScore: "3.11",
      roleEmotionScore: "4.86",
    };
    setSampleData(NewDataSet);
  };

  const showResult2 = () => {
    const NewDataSet = {
      age: "107",
      dailyLivingScore: "57.85",
      qualityOfLifeScore: "16.3",
      physicalFunctioningScore: "4.57",
      rolephysicalScore: "7.38",
      bodilyPainScore: "3.16",
      generalHealthScore: "2.96",
      vitalityScore: "4.11",
      socialFunctioningScore: "3.15",
      roleEmotionScore: "5.8",
    };
    setSampleData(NewDataSet);
  };

  const getResult = () => {
    const {
      age,
      dailyLivingScore,
      qualityOfLifeScore,
      physicalFunctioningScore,
      rolephysicalScore,
      bodilyPainScore,
      generalHealthScore,
      vitalityScore,
      socialFunctioningScore,
      roleEmotionScore,
    } = sampleData;

    const params = `${age},${dailyLivingScore},${qualityOfLifeScore},${physicalFunctioningScore},${rolephysicalScore},${bodilyPainScore},${generalHealthScore},${vitalityScore},${socialFunctioningScore},${roleEmotionScore},${Token.token}`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("output", params)
        .then((res) => {
          console.log(res);
          setSpinner(false);
          setResultData(res);
          const params = [];
          const percentages = res.percentage[0];
          for (const propertyName in percentages) {
            params.push({
              name: propertyName,
              y: percentages[propertyName],
            });
          }

          Highcharts.chart("prediction-chart", {
            chart: {
              plotShadow: false,
              type: "pie",
            },
            title: {
              text: "MAJOR CONTRIBUTING FACTORS",
            },
            tooltip: {
              pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                  enabled: true,
                  format: "<b>{point.name}</b>: {point.percentage:.1f} %",
                  style: {
                    color:
                      (Highcharts.theme
                        && Highcharts.theme.contrastTextColor)
                      || "black",
                  },
                },
              },
            },
            credits: {
              enabled: false,
            },
            series: [
              {
                colorByPoint: true,
                data: params,
              },
            ],
          });
        })
        .catch((err) => {
          setSpinner(false);
          console.log(err);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const onChangeFields = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };
  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Infarction refers to the death of tissue. A cerebral infarction, or
            stroke, is a brain lesion in which a cluster of brain cells die when
            they don't get enough blood. Major risk factors for cerebral
            infarction include Atherosclerosis, high blood pressure, Diabetes
            mellitus, tobacco smoking, obesity, and dyslipidemia. In small
            vessels, the most common cause is lipohyalinosis. Lipohyalinosis is
            when high blood pressure and aging cause a build-up of fatty hyaline
            matter in blood vessels. Demographics and behavioral aspects play a
            major role in aggravating the chance of a stroke along with the
            comorbidities associated with Cerebral Infarction.
            <br />
            <br />
            A stroke is a medical emergency. Prompt treatment is crucial.
            Early action can minimize brain damage and potential complications.
          </p>
          <b>Common symptoms of a cerebral stroke may include:</b>
          <ul>
            <li>
              <b>Dizziness </b>
            </li>
            <li>
              <b>Headaches </b>
            </li>
            <li>
              <b>Nausea and Vomiting </b>
            </li>
            <li>
              <b>Double vision </b>
            </li>
          </ul>
          <p>
            This machine learning POC will help the identification of the risk
            probability of a subject to experience stroke. The model is trained
            on more than 3 Mn individual synthetic patients generated on the
            vLife™ platform.
            {" "}
          </p>
          <LinkContainer>
            <Grid container spacing={2} />
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <section>
            <h5>Please provide the following data inputs</h5>
          </section>
          <br />
          <section>
            <Grid container spacing={1}>
              <Grid item xs={12} md={7}>
                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Age"
                          name="age"
                          value={sampleData.age}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Activities of daily living score [HOOS]"
                          name="dailyLivingScore"
                          value={sampleData.dailyLivingScore}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="uotlined-adornment-weight"
                          variant="outlined"
                          label="Quality of life score"
                          name="qualityOfLifeScore"
                          value={sampleData.qualityOfLifeScore}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="VR-12 Physical functioning (PF) Score - Oblique Method"
                          name="physicalFunctioningScore"
                          value={sampleData.physicalFunctioningScore}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="VR-12 Role physical (RP) score - oblique method"
                          name="rolephysicalScore"
                          value={sampleData.rolephysicalScore}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="VR-12 Bodily Pain (BP) Score - Oblique Method"
                          name="bodilyPainScore"
                          value={sampleData.bodilyPainScore}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="VR-12 General health (GH) Score - Oblique Method"
                          name="generalHealthScore"
                          value={sampleData.generalHealthScore}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="VR-12 Vitality (VT) score - Oblique Method"
                          name="vitalityScore"
                          value={sampleData.vitalityScore}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="VR-12 Social functioning(SF) Score-Oblique Method"
                          name="socialFunctioningScore"
                          value={sampleData.socialFunctioningScore}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="VR-12 Role emotion(RE) Score-Oblique Method"
                          name="generalHealthScore"
                          value={sampleData.roleEmotionScore}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>
                <br />
                <section>
                  <BorderGray>
                    Not sure about valid data inputs? Try any of the following
                    sample data...
                    <StyledDiv align="right">
                      <StyledButton
                        variant="contained"
                        color="secondary"
                        onClick={showResult}
                      >
                        Sample 1
                      </StyledButton>

                      <StyledButton
                        variant="contained"
                        color="secondary"
                        onClick={showResult2}
                      >
                        Sample 2
                      </StyledButton>
                    </StyledDiv>
                  </BorderGray>
                </section>
                <br />
                <section>
                  <StyledDiv align="right">
                    <StyledButton
                      variant="contained"
                      color="primary"
                      onClick={getResult}
                    >
                      Submit
                    </StyledButton>
                  </StyledDiv>
                </section>
              </Grid>
              <Grid item xs={12} md={5}>
                <section>
                  <h3>Output</h3>
                </section>
                {showSpinner && <Spinner text="Loading..." />}
                {resultData && (
                  <section>
                    <Result>
                      <strong>Stroke:</strong>
                      {`${resultData.isSTROKE}`}
                    </Result>
                    <Grid container direction="row" justify="center">
                      <Grid item xs={12}>
                        <HighchartContainer
                          id="prediction-chart"
                          display
                        />
                      </Grid>
                    </Grid>
                  </section>
                )}
              </Grid>
            </Grid>
          </section>
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(PredictingCerebralInfarction);

const BorderGray = styled.div`
  border: 1px #d6d6d6 solid;
  padding: 20px;
`;
const StyledDiv = styled.div`
  text-align: right;
  @media only screen and (max-width: 959px) {
    text-align: center;
  }
`;
