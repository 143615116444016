import ls from 'localstorage-slim';
const lengthReg = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]{8,32}$/;
const oneNumReg = /[0-9]/;
const onelowercaseReg = /[a-z]/;
const oneUppercaseReg = /[A-Z]/;
const oneSpecialCharReg = /[!@#\$%\^\&*\)\(+=._-]/;

export function passwordValidation(value) {
  return {
    pwdLength: lengthReg.test(value),
    oneLower: onelowercaseReg.test(value),
    oneUpper: oneUppercaseReg.test(value),
    oneNumber: oneNumReg.test(value),
    oneSpecialChar: oneSpecialCharReg.test(value),
  };
}

export function isPasswordValid(value) {
  return lengthReg.test(value) && onelowercaseReg.test(value) && oneUppercaseReg.test(value) && oneNumReg.test(value) && oneSpecialCharReg.test(value);
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
export function allowedDomains(email) {
  const notAllowedDomains = [
    "Mail.com",
    "aol.com",
    "gmail.com",
    "yahoo.com",
    "outlook.com",
    "Inbox.com",
    "iCloud.com",
    "mail.com",
    "Zoho.com",
    "protonmail.com",
  ];
  return notAllowedDomains.indexOf(email.split("@")[1]) == -1;
}
export function validatePhoneNumber(mobile) {
  const reg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  return reg.test(mobile);
}
const getUserData = () => ls.get('sessData', { decrypt: true });
export const getUploadToken = () => ls.get('uploadsessData', { decrypt: true });

export const routesForPublic = [];
export const isRoutePublic = (route) => (!(routesForPublic.indexOf(route) > -1));
export default getUserData;
