import React, { memo, useState } from "react";
import {
    Grid, Typography, Slide, Divider,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// import local components

import styled from "styled-components";
import LazyHero from "react-lazy-hero";
import Collapse from "../../common/Collapse";
import {
    StyledButton,
    SolutionContainer,
    LinkContainer,
    Paragraph,
} from "../../../styles/common";

// images
import pdArchitecture from "../../../assets/images/app/PD_Architecture.webp";
import ioTArchitecture2 from "../../../assets/images/app/ioTArchitecture-2.webp";
import HospitalCard from '../../common/HospitalCard';
import ZoomImage from "../../common/ZoomImage";

const data = [
    [
        "Provider Dashboard",
        "",
        "",
        "",
        "",
        "https://apps.vlifevirtusa.com:8503/vLifePortalSSO/embedUrl?id=b6c3df35-7ef0-48b9-adbd-8e8f64bc2abe",
        "Click Here",
      ],

];

const ProviderDashboards = () => {

    return (
        <SolutionContainer>
            <section>
                <div>
                    <Collapse text="Description">
                        <Grid container xs={12} direction="row">
                            <Grid item xs={12} md={12}>
                                <Paragraph>
                                Today, healthcare professionals gather a lot of data, but it often is scattered between various departments and compiled on separate systems and documents, leaving all this potential untapped. To help organizations run smoothly, analyzing healthcare metrics on every division and monitoring the attendance, its nature, and the expenses it generates is very crucial. By constantly improving hospital performance, patient satisfaction levels, and generating insights with the help of modern healthcare analytics and professional dashboards, we can provide outstanding patient care. 
                                <br/><br/>
                                Electronic health records (EHR) are the major source of healthcare data. Fast Healthcare Interoperability Resources (FHIR) is an open-source standards framework for EHR. FHIR standard helps to exchange structured medical data across various systems. In FHIR, health care data is broken down into categories such as patients, laboratory results, and insurance claims, among many others. Each of these categories is represented by a FHIR Resource, which defines the component data elements, constraints on data, and data relationships that together make up an exchangeable patient record.
                                <br/><br/>
                                Provider dashboard is a modern analytics tool to monitor healthcare KPIs in a dynamic and interactive way. It contains various sets of interactive provider data and displays current, previous and forecasted insights over the Organization’s data. 
                                </Paragraph>
                                <center> <ZoomImage width="100%" src={pdArchitecture} /></center>
                                <br />
                                <ol>
                                    <li>Though, using healthcare data for research can be tricky, since there are many legal and financial hoops to jump through in order to use certain data. But these hurdles can be avoided with synthetic data created using Synthea, an open-source patient generator. Synthea creates realistic data that can be used without restriction. We generated 15k patient’s healthcare data from synthea in FHIR format. </li>
                                    <li>The synthetic data was then stored in an AWS HealthLake Data Store. HealthLake enables healthcare providers, payers, and pharmaceutical companies to store, transform, query, and analyze health data at a petabyte-scale. It performs much of the work of organizing, indexing, and structuring patient information, to provide a complete view of each patient’s medical history in a more secure, compliant, and auditable manner.</li>
                                    <li>The organized data is then exported back to an S3 bucket. The Healthlake export process uses the NLP technique and extracts medical entities from the textual narratives and discharge summaries and stores them in an S3 bucket.</li>
                                    <li>The exported data is transformed using Glue to extract the information added by the HealthLake. The Glue Job extract the resources and put each into a separate table.</li>
                                    <li>AWS Athena comes into the picture to create higher level combined tables from individual resource tables. Athena is a serverless, low latency interactive query service that works with S3 to analyze data using standard SQL.</li>
                                    <li>The dashboard is built on AWS QuickSight using patient information extracted from both structured and unstructured data stored in Athena tables.</li>
                                </ol>
                                To create the dashboard, we’ve utilized various resources such as AllergyIntolerance, CarePlan, Claim, Condition, Encounter, ExplanationOfBenefit, Location, Medication, MedicationAdministration, MedicationRequest, Observation, Organization, Patient, Practitioner, PractitionerRole and Procedure.
                          <br/><br/>
                           <b>Provider Dashboard Tiles</b>
                           <ul>
                             <li> <b>Provider Revenue –</b> display insights on organization’s total revenue by insurer, age-group of patients, county and over the years.  </li>
                             <li><b>Provider Cases –</b> shows analysis on total no. of patient’s visits by insurer, their age-group, visit-type, county and year.</li>
                             <li><b>Claims Details –</b> display insights on total claims by insurer, claim-type, patient’s age-group, provider’s county, patient’s gender & marital-status and visit-type.</li>
                             <li><b>Practitioner Details –</b> display insights on total practitioners distributed across counties, cities, by gender, visit-type and over the years. </li>
                             <li><b>Patient Visits –</b> show insights on patient’s visits by reason of visit, patient’s city, provider’s county, patient-mortality, patient’s gender, race & marital-status. It also provides insights on average length of stay by their visit-type.</li>
                             <li><b>Prescriptions Details –</b> display insights on total prescriptions by patient’s condition, their age-group, gender & race, medicine, visit-type and over the years.</li>
                             <li><b>Clinical Details –</b> display insights on no. of observations by its category, over the years and by patient’s gender. It also provides analysis on procedures, care-plans taken by the service-providers and about any allergies/intolerances associated with patients. </li>
                           </ul>
                            </Grid>
                        </Grid>
                        {/* <LinkContainer>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <StyledButton
                                        variant="outlined"
                                        color="primary"
                                        size="large"
                                        startIcon={<OpenInNewIcon />}
                                    >
                                        <a
                                            href="../../../assets/files/Intersystems_POC_HIMMS2020_v02.pdf"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Read More
                                        </a>
                                    </StyledButton>
                                </Grid>
                            </Grid>
                        </LinkContainer> */}
                    </Collapse>
                    <Collapse text="Demo">
                        <br/><br/>
                        <Grid container justifyContent="center">
                        {data.map((data) => (
                                <Grid item xs={12} sm={4} md={3}>
                                    <HospitalCard
                                     name={data[0]}
                                     utility={data[1]}
                                     link={data[2]}
                                     userName={data[3]}
                                     password1={data[4]}
                                     s3Link={data[5]}
                                     s3LinkButtonName={data[6]}
                                        />
                                </Grid>
                                ))}
                        </Grid>
                    </Collapse>
                </div>
            </section>
        </SolutionContainer>
    );
};

export default memo(ProviderDashboards);
const SubSectiontitle = styled.div`
  border-bottom: 3px solid #3175eb;
  text-align: left;
  margin-bottom: 20px;
  padding: 20px 0 10px 0;
`;
