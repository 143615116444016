import React from "react";
import desc from "./solutionDescriptions";
import solutionIds from "./solutionIds";

import IncisionCareManagement from "../../components/views/solutions/IncisionCareManagement";

import imageDeepeyeVisualizer from "../../assets/images/solutions/deepeye-visualizer.webp";
import incisionCare from "../../assets/images/solutions/incisionCare.webp";

// Jithma
import ResponsibleAI, {
  ResponsibleUIDemo,
} from "../../components/views/solutions/ResponsibleAI";
import AntibioticResistancePrediction from "../../components/views/solutions/AntibioticResistancePrediction";
import CompetitorAnalyzer from "../../components/views/solutions/CompetitorAnalyzer";
import DrugDiscovery from "../../components/views/solutions/DrugDiscovery";
import DrugRecommendation from "../../components/views/solutions/DrugRecommendation";
import ForecastingExcellence from "../../components/views/solutions/ForecastingExellence";
import DrugCascading from "../../components/views/solutions/DrugCascading";
import JakInhibitors from "../../components/views/solutions/JakInhibitors";
import DataInteroperablePlatform from "../../components/views/solutions/DataInteroperablePlatform";
import SurgerySkills from "../../components/views/solutions/SurgerySkills";
import TabularData from "../../components/views/solutions/TabularData";
import UltraSoundImageSegmentation from "../../components/views/solutions/UltraSoundImageSegmentation";
import MaskDetection from "../../components/views/solutions/MaskDetection";
import MentalHealthPrevalence from "../../components/views/solutions/MentalHealthPrevalence";
import NeptuneBotGraph from "../../components/views/solutions/NeptuneBotGraph";
import PredictingAdClick from "../../components/views/solutions/PredictingAdClick";
import CustomerSegmentation from "../../components/views/solutions/CustomerSegmentation";
import PredictingADR from "../../components/views/solutions/PredictingADR";
import GeneticVariantClassification from "../../components/views/solutions/GeneticVariantClassification";
import ClassfyingRheumotoidArthritisPatients from "../../components/views/solutions/ClassfyingRheumotoidArthritisPatients";
import PredictingHeartDisease from "../../components/views/solutions/PredictingHeartDisease";
import PredictingType2Diabetes from "../../components/views/solutions/PredictingType2Diabetes";
import TimePeriod2CompleteClinicalTrial from "../../components/views/solutions/TimePeriod2CompleteClinicalTrial";
import GeneVariations from "../../components/views/solutions/GeneVariations";
import PredictingEligibility from "../../components/views/solutions/PredictingEligibility";
import SideEffects from "../../components/views/solutions/SideEffects";
import BrainTumorSegmentation from "../../components/views/solutions/BrainTumorSegmentation";
import NetworkGraphAnalyzer from "../../components/views/solutions/NetworkGraphAnalyzer";
import SelfService from "../../components/views/solutions/SelfService";
import TimelinessOfCare from "../../components/views/solutions/TimelinessOfCare";
import StrokePrediction from "../../components/views/solutions/StrokePrediction";
import PredictingCerebralInfarction from "../../components/views/solutions/PredictingCerebralInfarction";
import LungCancerComorbidities from "../../components/views/solutions/LungCancerComorbidities";
import LiverDiseasePrediction from "../../components/views/solutions/LiverDiseasePrediction";
import DeepScap from "../../components/views/solutions/DeepScap";
import DeepNuclie from "../../components/views/solutions/DeepNuclie";
import GeneExpressionAnalysis from "../../components/views/solutions/GeneExpressionAnalysis";
import PredictBioMechanical from "../../components/views/solutions/PredictBioMechanical";
import BreastCancerClassification from "../../components/views/solutions/BreastCancerClassification";
import BreastCancerProteomes from "../../components/views/solutions/BreastCancerProteomes";
import CancerLectins from "../../components/views/solutions/CancerLectins";
import PredictOligomericState from "../../components/views/solutions/PredictOligomericState";
import FrequentPharmacyVisitor from "../../components/views/solutions/FrequentPharmacyVisitor";
import BehaviouralAnalysis from "../../components/views/solutions/BehaviouralAnalysis";
import Vnet from "../../components/views/solutions/vnet";
import MedicalAppointmentNoShow from "../../components/views/solutions/MedicalAppointmentNoShow";
import FreightCostPrediction from "../../components/views/solutions/FreightCostPrediction";
import ChestXRaysCovid19 from "../../components/views/solutions/ChestXRaysCovid19";
import FaceEmotionRecognizer from "../../components/views/solutions/FaceEmotionRecognizer";
import CryoTherapyAnalysis from "../../components/views/solutions/CryoTherapyAnalysis";
import MelanomaClassification from "../../components/views/solutions/MelanomaClassification";
import CervicalCancerRisk from "../../components/views/solutions/CervicalCancerRisk";
import EyesStatePrediction from "../../components/views/solutions/EyesStatePrediction";
import InsuranceComplaintsResolution from "../../components/views/solutions/InsuranceComplaintsResolution";
import AskForAModel from "../../components/views/solutions/AskForAModel";
import RetinaDamageClassification from "../../components/views/solutions/RetinaDamageClassification";
import PopulationHealthDashboard from "../../components/views/solutions/PopulationHealthDashboard";
import BloodCellInception from "../../components/views/solutions/BloodCellInception";
import SuicideIdeationPrediction from "../../components/views/solutions/SuicideIdeationPrediction";
import ACLTearPrediction from "../../components/views/solutions/ACLTearPrediction";
import IntracranialHemorrhageDetection from "../../components/views/solutions/IntracranialHemorrhageDetection";
import WomensHealthWellBeing from "../../components/views/solutions/WomensHealthWellBeing";
import PneumoniaDiagnosis from "../../components/views/solutions/PneumoniaDiagnosis";
import InsuranceClaimPrediction from "../../components/views/solutions/InsuranceClaimPrediction";
import PulmonaryEmbolismPrediction from "../../components/views/solutions/PulmonaryEmbolismPrediction";
import DiagnosisPredictor from "../../components/views/solutions/DiagnosisPredictor";
import UHGBreastCancerPrediction from "../../components/views/solutions/UHGBreastCancerPrediction";
import HeartAttackAnalysis from "../../components/views/solutions/HeartAttackAnalysis";
import StressDetectionUsingHRV from "../../components/views/solutions/StressDetectionUsingHRV";
import FootWoundImageSegmentation from "../../components/views/solutions/FootWoundImageSegmentation";
import PolypSegmentation from "../../components/views/solutions/PolypSegmentation";
import BowelPreparation from "../../components/views/solutions/BowelPreparation";
import DcGan from "../../components/views/solutions/DcGan";
import LengthStayHospital from "../../components/views/solutions/LengthStayHospital";
import WHOSuicideStatistics from "../../components/views/solutions/WHOSuicideStatistics";
import LegalDocParser from "../../components/views/solutions/LegalDocParser";
import PredictParkinsonDisease from "../../components/views/solutions/PredictParkinsonDisease";
import PortraitShadowRemoval from "../../components/views/solutions/PortraitShadowRemoval";
import CohortSense from "../../components/pages/cohortAnalyzer/CohortAnalyzerTool";
import SinGAN from "../../components/views/solutions/SinGAN";
import PatternAnalyzer from "../../components/views/solutions/PatternAnalyzer";
import ACE from "../../components/views/solutions/ACE";
import GenomicDataPreprocessor from "../../components/views/solutions/GenomicDataPreprocessor";
import Covid19Simulation from "../../components/views/solutions/Covid19Simulation";
import NeuronShapley from "../../components/views/solutions/NeuronShapley";
import ClinicalEventsPatient from "../../components/views/solutions/ClinicalEventsPatient";
import ActiveLearner from "../../components/views/solutions/ActiveLearner";
import Anomagram from "../../components/views/solutions/Anomagram";
import TurbofanTycoon from "../../components/views/solutions/TurbofanTycoon";
import EncryptedTextData from "../../components/views/solutions/EncryptedTextData";
import DentalRadiographs, {
  DentalRadiographsDemo,
} from "../../components/views/solutions/DentalRadiographs";
import DifferentialPrivacyText, {
  DifferentialPrivacyTextDemo,
} from "../../components/views/solutions/DifferentialPrivacyText";
import AnnotatedCSV, {
  AnnotatedCSVDemo,
} from "../../components/views/solutions/AnnotatedCSV";
import responsibleAI from "../../assets/images/solutions/differentialPrivacyText.webp";
import imageSim from "../../assets/images/solutions/imageSimililarity.webp";
import priorAuth from "../../assets/images/solutions/PriorAuth.webp";
import nlpImg from "../../assets/images/solutions/nlpimg.webp";
import annotatedCSV from "../../assets/images/solutions/feedback-analysis.webp";
import differentialPrivacyText from "../../assets/images/solutions/differentialPrivacyText.webp";
import nlpChatgpt from "../../assets/images/solutions/nlpChatgpt.webp";
import bernImg from "../../assets/images/solutions/bern.webp";
import adClick from "../../assets/images/solutions/adClick.webp";
import drugDiscovery from "../../assets/images/solutions/drugDiscovery.webp";
import drugRecommendation from "../../assets/images/solutions/drugRecommendation.webp";
import medicalReport from "../../assets/images/solutions/medicalReport.webp";
import antibioticResistancePrediction from "../../assets/images/solutions/antibioticResistancePrediction.webp";
import jakInhibitors from "../../assets/images/solutions/jakInhibitors.webp";
import competitorAnalyzer from "../../assets/images/solutions/competitorAnalyzer.webp";
import forecastingExcellence from "../../assets/images/solutions/forecastingExcellence.webp";
import dataInteroperablePlatform from "../../assets/images/solutions/dataInteroperablePlatform.webp";
import surgerySkills from "../../assets/images/solutions/surgerySkills.webp";
import tabularData from "../../assets/images/solutions/tabularData.webp";
import ultraSoundImageSegmentation from "../../assets/images/solutions/ultraSoundImageSegmentation.webp";
import maskDetection from "../../assets/images/solutions/maskDetection.webp";
import mentalHealthPrevalence from "../../assets/images/solutions/mentalHealthPrevalence.webp";
import customerSegmentation from "../../assets/images/solutions/customersegmentation.webp";
import predictingADR from "../../assets/images/solutions/predictingADR.webp";
import geneticVariantClassification from "../../assets/images/solutions/geneticVariantClassification.webp";
import classfyingRheumotoidArthritisPatients from "../../assets/images/solutions/classfyingRheumotoidArthritisPatients.webp";
import predictingHeartDisease from "../../assets/images/solutions/predictingHeartDisease.webp";
import predictingType2Diabetes from "../../assets/images/solutions/predictingType2Diabetes.webp";
import timePeriod2CompleteClinicalTrial from "../../assets/images/solutions/timePeriod2CompleteClinicalTrial.webp";
import geneVariations from "../../assets/images/solutions/geneVariations.webp";
import predictingEligibility from "../../assets/images/solutions/predictingEligibility.webp";
import kgbot from "../../assets/images/solutions/kgbot.webp";
import sideEffects from "../../assets/images/solutions/sideEffects.webp";
import brainTumorSegmentation from "../../assets/images/solutions/brainTumorSegmentation.webp";
import networkGraphAnalyzer from "../../assets/images/solutions/networkGraphAnalyzer.webp";
import selfService from "../../assets/images/solutions/selfService.webp";
import timelinessOfCare from "../../assets/images/solutions/timelinessOfCare.webp";
import strokePrediction from "../../assets/images/solutions/strokePrediction.webp";
import predictingCerebralInfarction from "../../assets/images/solutions/predictingCerebralInfarction.webp";
import lungCancerComorbidities from "../../assets/images/solutions/lungCancerComorbidities.webp";
import liverDiseasePrediction from "../../assets/images/solutions/liverDiseasePrediction.webp";
import deepScap from "../../assets/images/solutions/deepScap.webp";
import deepNuclie from "../../assets/images/solutions/deepNuclie.webp";
import geneExpressionAnalysis from "../../assets/images/solutions/geneExpressionAnalysis.webp";
import predictBioMechanical from "../../assets/images/solutions/predictBioMechanical.webp";
import breastCancerClassification from "../../assets/images/solutions/breastCancerClassification.webp";
import breastCancerProteomes from "../../assets/images/solutions/breastCancerProteomes.webp";
import cancerLectins from "../../assets/images/solutions/cancerLectins.webp";
import predictOligomericState from "../../assets/images/solutions/predictOligomericState.webp";
import frequentPharmacyVisitor from "../../assets/images/solutions/frequentPharmacyVisitor.webp";
import behaviouralAnalysis from "../../assets/images/solutions/behaviouralAnalysis.webp";
import medicalAppointmentNoShow from "../../assets/images/solutions/medicalAppointmentNoShow.webp";
import freightCostPrediction from "../../assets/images/solutions/freightCostPrediction.webp";
import chestXRaysCovid19 from "../../assets/images/solutions/chestXRaysCovid19.webp";
import faceEmotionRecognizer from "../../assets/images/solutions/faceEmotionRecognizer.webp";
import cryoTherapyAnalysis from "../../assets/images/solutions/cryoTherapyAnalysis.webp";
import melanomaClassification from "../../assets/images/solutions/melanomaClassification.webp";
import cervicalCancerRisk from "../../assets/images/solutions/cervicalCancerRisk.webp";
import eyesStatePrediction from "../../assets/images/solutions/eyesStatePrediction.webp";
import insuranceComplaintsResolution from "../../assets/images/solutions/insuranceComplaintsResolution.webp";
import askForAModel from "../../assets/images/solutions/askForAModel.webp";
import retinaDamageClassification from "../../assets/images/solutions/retinaDamageClassification.webp";
import hospitalRecommendation from "../../assets/images/solutions/hospitalRecommendation.webp";
import populationHealthDashboard from "../../assets/images/solutions/populationHealthDashboard.webp";
import bloodCellInception from "../../assets/images/solutions/bloodCellInception.webp";
import suicideIdeation from "../../assets/images/solutions/suicideIdeation.webp";
import aclTear from "../../assets/images/solutions/aclTear.webp";
import intracranialHemorrhage from "../../assets/images/solutions/intracranialHemorrhage.webp";
import womensHealthWellBeing from "../../assets/images/solutions/womensHealthWellBeing.webp";
import pneumoniaDiagnosis from "../../assets/images/solutions/pneumoniaDiagnosis.webp";
import insuranceClaimPrediction from "../../assets/images/solutions/insuranceClaimPrediction.webp";
import pulmonaryEmbolism from "../../assets/images/solutions/pulmonaryEmbolism.webp";
import diagnosisPredictor from "../../assets/images/solutions/diagnosisPredictor.webp";
import uhgBreastCancerPrediction from "../../assets/images/solutions/uhgBreastCancerPrediction.webp";
import heartAttackAnalysis from "../../assets/images/solutions/heartAttackAnalysis.webp";
import stressDetectionHRV from "../../assets/images/solutions/stressDetectionHRV.webp";
import footWoundImageSegmentation from "../../assets/images/solutions/footWoundImageSegmentation.webp";
import polypSegmentation from "../../assets/images/solutions/polypSegmentation.webp";
import bowelpreparation from "../../assets/images/solutions/bowelpreparation.webp";
import dcGan from "../../assets/images/solutions/dcGAN.webp";
import lengthStayHospital from "../../assets/images/solutions/lengthStayHospital.webp";
import whoSuicideStatistics from "../../assets/images/solutions/whoSuicideStatistics.webp";
import legalDocParser from "../../assets/images/solutions/legalDocParser.webp";
import portraitShadowRemoval from "../../assets/images/solutions/portraitShadowRemoval.webp";
import medicareLocator from "../../assets/images/solutions/medicareLocator.webp";
import singan from "../../assets/images/solutions/singan.webp";
import patternAnalyzer from "../../assets/images/solutions/patternAnalyzer.webp";
import ace from "../../assets/images/solutions/ace.webp";
import genomicDataPreprocessor from "../../assets/images/solutions/genomicDataPreprocessor.webp";
import diseasePrognosis from "../../assets/images/solutions/diseasePrognosis.webp";
import covid19Simulation from "../../assets/images/solutions/covid19Simulation.webp";
import neuronShapley from "../../assets/images/solutions/neuron_shapley.webp";
import clinicalEventsPatient from "../../assets/images/solutions/clinicalEventsPatient.webp";
import activeLearner from "../../assets/images/solutions/active_learner.webp";
import anomagram from "../../assets/images/solutions/anomagram.webp";
import turbofanTycoon from "../../assets/images/solutions/fedrated_learning.webp";
import textBasedEmotion from "../../assets/images/solutions/textBasedEmotion.webp";
import cancerDetect from "../../assets/images/solutions/cancerDetect.webp";
import proteinDeep from "../../assets/images/solutions/proteinDeep.webp";
import toxPrediction from "../../assets/images/solutions/tox.webp";
import anomaly from "../../assets/images/solutions/anomaly.webp";
import encryptedTextData from "../../assets/images/solutions/encryptedTextData.webp";
import dentalRadiographs from "../../assets/images/solutions/dentalRadiographs.webp";
import vqa from "../../assets/images/solutions/vqaTile.webp";
import questionGeneration from "../../assets/images/solutions/questionGeneration.webp";
import mlMatchingOriginal from "../../assets/images/solutions/mlMatchingOriginal.webp";
import PerioTileImage from "../../assets/images/solutions/PerioTileImage.webp";
import insuranceFraudDetection from "../../assets/images/solutions/ICFD_Tile_Image.webp";
import modelExplainabilityTileImage from "../../assets/images/solutions/model-explainability-tile-image.webp";
import medCode from "../../assets/images/solutions/med_code.webp";


// Vikram
import RoboticSegmentation from "../../components/views/solutions/RoboticSegmentation";
import ShoulderImplantTracker from "../../components/views/solutions/ShoulderImplantTracker";
import MentalHealth from "../../components/views/solutions/MentalHealth";
import PrimerClassifier from "../../components/views/solutions/PrimerClassifier";
import BioLemmatizer from "../../components/views/solutions/BioLemmatizer";
import OCRHandwritten from "../../components/views/solutions/OCRHandwritten";
import MirthHL7 from "../../components/views/solutions/MirthHL7";
import OCRCheckValidation from "../../components/views/solutions/OCRCheckValidation";
import ShapleyEMR from "../../components/views/solutions/ShapleyEMR";
import ShapleyImages from "../../components/views/solutions/ShapleyImages";
import MultiAccuracy from "../../components/views/solutions/MultiAccuracy";
import ClinicalNotesPreprocessor from "../../components/views/solutions/ClinicalNotesPreprocessor";
import FeasibilityAnalysis from "../../components/views/solutions/FeasibilityAnalysis";
import MortalityEffect from "../../components/views/solutions/MortalityEffect";
import TextSummarizer from "../../components/views/solutions/TextSummarizer";
import CpcaImages from "../../components/views/solutions/CpcaImages";
import Cpca from "../../components/views/solutions/Cpca";
import DrugReviewAnalyzer from "../../components/views/solutions/DrugReviewAnalyzer";
import CaloriePredictor from "../../components/views/solutions/CaloriePredictor";
import HumanproteinImageclassification from "../../components/views/solutions/HumanproteinImageclassification";
import GenomePhenotype from "../../components/views/solutions/GenomePhenotype";
import MiceProteinClassification from "../../components/views/solutions/MiceProteinClassification";
import GenomeFrequencyAnalysis from "../../components/views/solutions/GenomeFrequencyAnalysis";
import GeneSimilarity from "../../components/views/solutions/GeneSimilarity";
import GastrointestinalCancer from "../../components/views/solutions/GastrointestinalCancer";
import CervicalCancerScreening from "../../components/views/solutions/CervicalCancerScreening";
import ProstateCancerDetection from "../../components/views/solutions/ProstateCancerDetection";
import ColonPathology from "../../components/views/solutions/ColonPathology";
import GeneticAlgorithm from "../../components/views/solutions/GeneticAlgorithm";
import RetweetCountPredictor from "../../components/views/solutions/RetweetCountPredictor";
import HeartSoundsClassification from "../../components/views/solutions/HeartSoundsClassification";
import HealthCareCostEstimation from "../../components/views/solutions/HealthCareCostEstimation";
import UsUninsured from "../../components/views/solutions/UsUninsured";
import LifeInsurance from "../../components/views/solutions/LifeInsurance";
import SocialDistance from "../../components/views/solutions/SocialDistance";
import PredictMortalityKorean from "../../components/views/solutions/PredictMortalityKorean";
import ActivationMetastasis from "../../components/views/solutions/ActivationMetastasis";
import InsuranceChurn from "../../components/views/solutions/InsuranceChurn";
import FraudClaimsDetection from "../../components/views/solutions/FraudClaimsDetection";
import MedicalExpensePrediction from "../../components/views/solutions/MedicalExpensePrediction";
import Rom from "../../components/views/solutions/Rom";
import ArrhythmiaClassification from "../../components/views/solutions/ArrhythmiaClassification";
import DiabeticRetinopathy from "../../components/views/solutions/DiabeticRetinopathy";
import ImageColorization from "../../components/views/solutions/ImageColorization";
import SkinCancerAnalysis from "../../components/views/solutions/SkinCancerAnalysis";
import DrugMOAPrediction from "../../components/views/solutions/DrugMOAPrediction";
import OsteoarthritisSeverityPrediction from "../../components/views/solutions/OsteoarthritisSeverityPrediction";
import EEGClassification from "../../components/views/solutions/EEGClassification";
import EEGOnSensoryTask from "../../components/views/solutions/EEGOnSensoryTask";
import DementiaPrediction from "../../components/views/solutions/DementiaPrediction";
import BreastCancerMammography from "../../components/views/solutions/BreastCancerMammography";
import HealthInsuranceLeadPrediction from "../../components/views/solutions/HealthInsuranceLeadPrediction";
import SDOH from "../../components/views/solutions/SDOH";
import FasterAI from "../../components/pages/fasterAi/FasterAi";
import SurgicalSiteInfectionPrediction from "../../components/views/solutions/SurgicalSiteInfectionPrediction";
import FederatedLearningMedicalData from "../../components/views/solutions/FederatedLearningMedicalData";
import AmericanSignLanguageDetection from "../../components/views/solutions/AmericanSignLanguageDetection";
import ImageEnhancementESRGAN from "../../components/views/solutions/ImageEnhancementESRGAN";
import AraaliNetworks from "../../components/views/solutions/AraaliNetworks";
import FetalHealthClassification from "../../components/views/solutions/FetalHealthClassification";
import HealthInsuranceCoverageRatePrediction from "../../components/views/solutions/HealthInsuranceCoverageRatePrediction";
import UHGStrokePrediction from "../../components/views/solutions/UHGStrokePrediction";
import AdverseFoodEvents from "../../components/views/solutions/AdverseFoodEvents";
import OcrCertificate from "../../components/views/solutions/OcrCertificate";
import ProviderNetworkAnalysis from "../../components/views/solutions/ProviderNetworkAnalysis";
import SequenceGenerator from "../../components/views/solutions/SequenceGenerator";
import DopplegangerView from "../../components/views/solutions/DopplegangerView";
import Dataiku from "../../components/views/solutions/Dataiku";
import CovidDashboard from "../../components/views/solutions/CovidDashboard";
import ChronicKidneyDisease from "../../components/views/solutions/ChronicKidneyDisease";
import ImagedeIdentification from "../../components/views/solutions/ImagedeIdentification";
import SentimentAnalysis from "../../components/views/solutions/SentimentAnalysis";
import EchoNet from "../../components/views/solutions/EchoNet";
import ColbertAnalysis from "../../components/views/solutions/ColbertAnalysis";
import DistributionalShapley from "../../components/views/solutions/DistributionalShapley";
import Ganlab from "../../components/views/solutions/Ganlab";
import ConVnet from "../../components/views/solutions/ConVnet";
import CNNExplainer from "../../components/views/solutions/CNNExplainer";
import EXBERT from "../../components/views/solutions/EXBERT";
import SelfOrganizing from "../../components/views/solutions/SelfOrganizing";
import TextGeneration from "../../components/views/solutions/TextGeneration";
import AnomalyDetector from "../../components/views/solutions/AnomalyDetector";
import ImageModel from "../../components/views/solutions/ImageModel";
import AqueousSolubility from "../../components/views/solutions/AqueousSolubility";
import KnowledgeGraph from "../../components/views/solutions/KnowledgeGraph";
import PeriodontalChart, {
  PeriodontalChartDemo,
} from "../../components/views/solutions/PeriodontalChart";
import MedTech from "../../components/views/solutions/MedTech";
import DocSearch from "../../components/views/solutions/DocSearch";
import DocSearchBot from "../../components/views/solutions/DocSearchBot";
import BusinessRequirement from "../../components/views/solutions/BusinessRequirement";
import PriorAuth from "../../components/views/solutions/PriorAuth";

import shoulderImplant from "../../assets/images/solutions/shoulder-implant.webp";
import roboticInstrument from "../../assets/images/solutions/robotic-instrument.webp";
import primerIdentification from "../../assets/images/solutions/PrimerIdentification.webp";
import mentalHealth from "../../assets/images/solutions/mentalHealth.webp";
import durgCascading from "../../assets/images/solutions/durg-cascading.webp";
import bioLemmatizer from "../../assets/images/solutions/bio-lemmatizer.webp";
import mirth from "../../assets/images/solutions/mirth.webp";
import handWritternOCR from "../../assets/images/solutions/hand-writtern-ocr.webp";
import ocr from "../../assets/images/solutions/ocr.webp";
import shapleyEMR from "../../assets/images/solutions/shapley-emr.webp";
import shapleyImages from "../../assets/images/solutions/shapley-images.webp";
import multiAccuracy from "../../assets/images/solutions/multi-accuracy.webp";
import clinicalNotes from "../../assets/images/solutions/clinicalnotes.webp";
import feasibilityAnalyzer from "../../assets/images/solutions/feasibilityanalyzer.webp";
import mortalityEffect from "../../assets/images/solutions/mortality-effect.webp";
import cpcaImages from "../../assets/images/solutions/cpca_images.webp";
import textSummarizer from "../../assets/images/solutions/text-summarizer.webp";
import cpca from "../../assets/images/solutions/cpca.webp";
import drugReviewAnalyzer from "../../assets/images/solutions/drugreviewanalyzer.webp";
import caloriePredictor from "../../assets/images/solutions/calorie_predictor.webp";
import humanproteinImageclassification from "../../assets/images/solutions/ImageClassification.webp";
import genomePhenotype from "../../assets/images/solutions/genome-phenotype.webp";
import miceProteinClassification from "../../assets/images/solutions/Mice-Protein.webp";
import genomeFrequencyAnalysis from "../../assets/images/solutions/genome-fequency.webp";
import geneSimilarity from "../../assets/images/solutions/gene-similarity.webp";
import gastrointestinalCancer from "../../assets/images/solutions/gastrointestinal.webp";
import prostateCancerDetection from "../../assets/images/solutions/prostate.webp";
import cervicalCancerScreening from "../../assets/images/solutions/cervical-cancer.webp";
import colonPathology from "../../assets/images/solutions/colon-pathology.webp";
import geneticAlgorithm from "../../assets/images/solutions/genetic-algorithm.webp";
import retweetCountPredictor from "../../assets/images/solutions/retweet.webp";
import heartSoundsClassification from "../../assets/images/solutions/heartSound.webp";
import healthCareCostEstimation from "../../assets/images/solutions/outofpocket.webp";
import usUninsured from "../../assets/images/solutions/us_ininsured.webp";
import lifeInsurance from "../../assets/images/solutions/life-insurance.webp";
import socialDistance from "../../assets/images/solutions/social-distance-tile.webp";
import predictMortalityKorean from "../../assets/images/solutions/korean-mortality.webp";
import globalDashboard from "../../assets/images/solutions/global-internet.webp";
import activationMetastasis from "../../assets/images/solutions/activation-metastasis.webp";
import insuranceChurn from "../../assets/images/solutions/insurance-churn.webp";
import fraudClaimsDetection from "../../assets/images/solutions/fraud-claims.webp";
import medicalExpensePrediction from "../../assets/images/solutions/insurance-premium.webp";
import rom from "../../assets/images/solutions/rom1.webp";
import arrhythmiaClassification from "../../assets/images/solutions/Arrhythmia-prediction.webp";
import diabeticRetinopathy from "../../assets/images/solutions/diabetic_card.webp";
import imageColorization from "../../assets/images/solutions/image-colorization.webp";
import skinCancerAnalysis from "../../assets/images/solutions/skinCancer_sol.webp";
import drugMOAPrediction from "../../assets/images/solutions/drug-moa.webp";
import osteoarthritisSeverityPrediction from "../../assets/images/solutions/KneeOsteoarthritis-tile.webp";
import eEGClassification from "../../assets/images/solutions/sleep_eeg.webp";
import eEGOnSensoryTask from "../../assets/images/solutions/eeg-sensory-task.webp";
import medicalDeviceSimulator from "../../assets/images/solutions/medicalDeviceSimulator.webp";
import northAmerica from "../../assets/images/solutions/northAmerica.webp";
import ganLab from "../../assets/images/solutions/ganLab.webp";
import ibmMarketscan from "../../assets/images/solutions/ibmMarketscan.webp";
import synthea from "../../assets/images/solutions/synthea.webp";
import genRocket from "../../assets/images/solutions/genRocket.webp";
import predictinhibitors from "../../assets/images/solutions/predict-inhibitors.webp";
import dementiaPrediction from "../../assets/images/solutions/dementia.webp";
import breastCancerMammography from "../../assets/images/solutions/breastcancertile.webp";
import healthInsuranceLeadPrediction from "../../assets/images/solutions/hilp.webp";
import sdoh from "../../assets/images/solutions/sdoh.webp";
import surgicalSiteInfectionPrediction from "../../assets/images/solutions/ssi.webp";
import federatedLearningMedicalData from "../../assets/images/solutions/fl-tile.webp";
import americanSignLanguageDetection from "../../assets/images/solutions/asl.webp";
import fetalHealthClassification from "../../assets/images/solutions/fetalHealth.webp";
import healthInsuranceCoverageRatePrediction from "../../assets/images/solutions/healthInsuranceCoverage.webp";
import uHGStrokePrediction from "../../assets/images/solutions/uhg-stroke-predection.webp";
import adverseFoodEvents from "../../assets/images/solutions/adverseFoodEvent.webp";
import ocrCertificate from "../../assets/images/solutions/ocr-death-certificate.webp";
import providerNetworkAnalysis from "../../assets/images/solutions/pna.webp";
import sequenceGenerator from "../../assets/images/solutions/GeneSequence.webp";
import parkinsons from "../../assets/images/solutions/parkinsons.webp";
import dopplegangerView from "../../assets/images/solutions/digitaltwins.webp";
import hls from "../../assets/images/solutions/hls.webp";
import covidDashboards from "../../assets/images/solutions/covidDashboard.webp";
import chronicKidneyDisease from "../../assets/images/solutions/chronickidney.webp";
import imagedeIdentification from "../../assets/images/solutions/imagedeIdentification.webp";
import sentimentAnalysis from "../../assets/images/solutions/GCP.webp";
import echoNet from "../../assets/images/solutions/Echonet.webp";
import colbertAnalysis from "../../assets/images/solutions/colbert.webp";
import distributionalShapley from "../../assets/images/solutions/Dist_Shapley.webp";
import ganlabstatic from "../../assets/images/solutions/ganlabstatic.webp";
import conVnet from "../../assets/images/solutions/convnet.webp";
import cnnExplainer from "../../assets/images/solutions/cnnexplainer.webp";
import exbert from "../../assets/images/solutions/exbert.webp";
import selfOrganizing from "../../assets/images/solutions/self_organising_maps.webp";
import textSummarization from "../../assets/images/solutions/text-summarization.webp";
import textGeneration from "../../assets/images/solutions/text_generator.webp";
import imagemodal from "../../assets/images/solutions/imagemodal.webp";
import aqueous from "../../assets/images/solutions/aqueous.webp";
import knowledgeGraph from "../../assets/images/solutions/BioMedKG_Tile_Image.webp";
import docSimilarityReport from "../../assets/images/solutions/DocSimilarityReport.webp";
import medTech from "../../assets/images/solutions/DocSimilarityReport.webp";
import contextualSearch from "../../assets/images/solutions/cbs_tile_image1.webp";
import docSearch from "../../assets/images/solutions/contentOptimizationForSemanticSearch.webp";
import docSearchBot from "../../assets/images/solutions/chatbot.jpeg";
import auditAssist from "../../assets/images/solutions/auditAssist.webp";
import siteMonitoring from "../../assets/images/solutions/sitemonitoring.webp";

// innovation as service
import mlopsDataiku from "../../assets/images/solutions/mlopsDataiku.webp";
import raindrop from "../../assets/images/solutions/raindrop.webp";
import vHealth from "../../assets/images/solutions/vHealth.webp";
import whizAi from "../../assets/images/solutions/whizAi.webp";
import interSystems from "../../assets/images/solutions/interSystems.webp";
import healthBot from "../../assets/images/solutions/healthBot.webp";
import healthMonitor from "../../assets/images/solutions/healthMonitor.webp";
import healthLake from "../../assets/images/solutions/healthLake.webp";
import handGuesterRecognition from "../../assets/images/solutions/handGuesterRecognition.webp";
import ePic from "../../assets/images/solutions/ePic.webp";
import drugSalesForecasting from "../../assets/images/solutions/drugSalesForecasting.webp";
import teleHealth from "../../assets/images/solutions/teleHealth.webp";
import dataBricks from "../../assets/images/solutions/dataBricks.webp";
import roboFlow from "../../assets/images/solutions/roboflow.webp";
import iQuartic from "../../assets/images/solutions/iQuartic.webp";
import onyxHealth from "../../assets/images/solutions/onyx.webp";
import appealsGrievance from "../../assets/images/solutions/appealsGrievance.webp";
import careCohort from "../../assets/images/solutions/careCohort.webp";
import araaliNetworks from "../../assets/images/solutions/araaliNetworks.webp";
import pegaSmart from "../../assets/images/solutions/pegaSmart.webp";
import esrGan from "../../assets/images/solutions/esrGan.webp";
import patientStratification from "../../assets/images/solutions/patientStratification.webp";
import mareanaFaq from "../../assets/images/solutions/faq.webp";
import analyzerImg from "../../assets/images/solutions/analyzer.webp";
import salesTraining from "../../assets/images/solutions/salesTraining.webp";
import fasterAI from "../../assets/images/solutions/fasterAi.webp";
import plmLife from "../../assets/images/solutions/plmLife.webp";
import plmInsights from "../../assets/images/solutions/plmInsights.webp";

// iot receipes
import iotReciepe from "../../assets/images/solutions/iotReciepe.webp";
import guideForNonDevelopers from "../../assets/images/solutions/guideForNonDevelopers.webp";
import raspPiBase from "../../assets/images/solutions/raspPiBase.webp";
import ideas from "../../assets/images/solutions/ideas.webp";
import dataStorage from "../../assets/images/solutions/dataStorage.webp";
import dataLoggingApplications from "../../assets/images/solutions/dataLoggingApplications.webp";
import connectingTi from "../../assets/images/solutions/connectingTi.webp";
import optimizingPcb from "../../assets/images/solutions/optimizingPcb.webp";
import iotasaService from "../../assets/images/solutions/iotasaService.webp";
import continuousHighPerformance from "../../assets/images/solutions/continuousHighPerformance.webp";
import handlingAlerts from "../../assets/images/solutions/handlingAlerts.webp";
import useHttps from "../../assets/images/solutions/useHttps.webp";
import connectingSigfox from "../../assets/images/solutions/connectingSigfox.webp";
import virtualRealityMedicalSimulation from "../../assets/images/solutions/virtualRealityMedicalSimulation.webp";
import virtualRift from "../../assets/images/solutions/virtualRift.webp";
import videoConferencing from "../../assets/images/solutions/videoConferencing.webp";
import zeroshot from "../../assets/images/solutions/zeroshot_tile.webp";
// epidemiology

import confirmedCases from "../../assets/images/solutions/confirmedCases.webp";
import covid19CountryCases from "../../assets/images/solutions/covid19CountryCases.webp";
import covidDashboard from "../../assets/images/solutions/covidDashboard.webp";
import covidVaccination from "../../assets/images/solutions/covidVaccination.webp";
import coronavirus from "../../assets/images/solutions/coronavirus.webp";
import casesCountryWise from "../../assets/images/solutions/casesCountryWise.webp";
import projectBaseline from "../../assets/images/solutions/projectBaseline.webp";
import recoveryCases from "../../assets/images/solutions/recoveryCases.webp";

// collaborators
import blogAIML from "../../assets/images/solutions/blogAIML.webp";
import dataShapley from "../../assets/images/solutions/dataShapley.webp";
import genomics from "../../assets/images/solutions/genomics.webp";
import insurancePremium from "../../assets/images/solutions/insurancePremium.webp";
import microEnvironment from "../../assets/images/solutions/microEnvironment.webp";
import readmission from "../../assets/images/solutions/readmission.webp";
import rpmAws from "../../assets/images/solutions/rpmAws.webp";
import virus from "../../assets/images/solutions/virus.webp";
import IBMMarketscan from "../../components/views/solutions/IBMMarketscan";
import IOTRecipesView from "../../components/views/solutions/IOTRecipesView";
import PredictCovid19CasesCountryWise from "../../components/views/solutions/PredictCovid19CasesCountryWise";
import PredictConfirmedCases from "../../components/views/solutions/PredictConfirmedCases";
import PredictRecoveryCases from "../../components/views/solutions/PredictRecoveryCases";
import PredictMortalityCasesWorldwide from "../../components/views/solutions/PredictMortalityCasesWorldwide";

// oncology
import cervicalCancerClassification from "../../assets/images/solutions/cervicalCancerClassification.webp";
import malariaDetection from "../../assets/images/solutions/malariaDetection.webp";
import patientSimilarities from "../../assets/images/solutions/patientSimilarities.webp";
import metastatiClassification from "../../assets/images/solutions/metastatiClassification.webp";
import sARSCoVAssociation from "../../assets/images/solutions/sARSCoVAssociation.webp";
import covid19Genetics from "../../assets/images/solutions/covid19Genetics.webp";
import breastAssessmentTool from "../../assets/images/solutions/breastAssessmentTool.webp";
import colonCancerGeneAnalysis from "../../assets/images/solutions/colonCancerGeneAnalysis.webp";
import effectcovid19 from "../../assets/images/solutions/effect-of-covid19.webp";
import nomograms from "../../assets/images/solutions/nomograms.webp";
import cancerusstate from "../../assets/images/solutions/cancer-us-state.webp";
import canceruscountry from "../../assets/images/solutions/cancer-us-country.webp";
import GanLab from "../../components/pages/GanLab";
import HandGesture from "../../components/views/solutions/HandGesture";
import Immortility from "../../assets/images/solutions/immortility.webp";
import Instability from "../../assets/images/solutions/instability.webp";
import SuppressorSignals from "../../assets/images/solutions/suppressorSignals.webp";
import ResistanceCell from "../../assets/images/solutions/resistanceCell.webp";
import Sustained from "../../assets/images/solutions/sustained.webp";
import AlteredMetabolism from "../../assets/images/solutions/alteredMetabolism.webp";
import Immune from "../../assets/images/solutions/immune.webp";
import TumorPromoting from "../../assets/images/solutions/tumorPromoting.webp";
import Angiogenesis from "../../assets/images/solutions/angiogenesis.webp";
import NorthAmericaSimulated from "../../components/views/solutions/NorthAmericaSimulated";
import MedicareLocator from "../../components/views/solutions/MedicareLocator";
import VisionAidedGAN from "../../components/views/solutions/VisionAidedGAN";
import HLSDashboard from "../../components/pages/HLS/HLSDashboard";
import TextBasedEmotionClassification from "../../components/views/solutions/TextBasedEmotionClassification";
import TextSummarization from "../../components/views/solutions/TextSummarization";
import ZeroShotClassification from "../../components/views/solutions/ZeroShotClassification";

import VideoMetadataExtraction from "../../components/views/solutions/VideoMetadataExtraction";
import videoMetadata from "../../assets/images/solutions/videoMetadata.webp";
import videoMetadataBlog from "../../assets/images/solutions/videoMetaDataBlog.webp";
import GenomicMetadataIntegration from "../../components/views/solutions/GenomicMetadataIntegration";
import MedicalReport from "../../components/views/solutions/MedicalReportGeneration";
import CancerousCellDetection from "../../components/views/solutions/CancerousCellDetection";
import DifferentialPrivacy from "../../components/views/solutions/DifferentialPrivacy";
import diffPrivacy from "../../assets/images/solutions/differentialPrivacy.webp";
import genomicMetadata from "../../assets/images/solutions/GenomicMetaData.webp";
import IoTContinuous from "../../components/views/solutions/IoTContinuous";
import IOTCont from "../../assets/images/solutions/iotContinuous.webp";
import ProviderDashboards from "../../components/views/solutions/ProviderDashboards";
import providerDash from "../../assets/images/solutions/PD_TileImage.webp";
import MultimodalSingleCellIntegration from "../../components/views/solutions/MultimodalSingleCellIntegration";
import multimodal from "../../assets/images/solutions/multimodal.webp";
import llm from "../../assets/images/solutions/LLM-Evaluation.webp";
import VisualQnAMedicalImaging from "../../components/views/solutions/VisualQnAMedicalImaging";
import Bern from "../../components/views/solutions/Bern";
import ProteinDeepLearning from "../../components/views/solutions/ProteinDeepLearning";
import ToxPrediction from "../../components/views/solutions/ToxPrediction";
import TextGenerationNlp, {
  TextGenerationDemo,
} from "../../components/views/solutions/TextGenerationNlp";
import QuestionGeneration, {
  QuestionGenerationDemo,
} from "../../components/views/solutions/QuestionGeneration";
import NameMatcher, {
  NameMatcherDemo,
} from "../../components/views/solutions/NameMatcher";
import PriorAuthorization, {
  PriorAuthorizationDemo,
} from "../../components/views/solutions/PriorAuthorization";
import NlpToolkit, {
  NlpToolkitDemo,
} from "../../components/views/solutions/NlpToolkit";
import DocSimilarityReport, {
  DocSimilarityReportDemo,
} from "../../components/views/solutions/DocSimilarityReport";
import ImageSimDesc, {
  ImageSimDemo,
} from "../../components/views/solutions/ImageSim";
import InsuranceFraudDetectionDesc, {
  InsuranceFraudDetectionDemo,
} from "../../components/views/solutions/InsuranceFraudDetection";
import ResponsibleAIDesc, {
  ResponsibleAIDemo,
} from "../../components/views/solutions/ResponsibleAIModelExplainability";
import MedCodeDesc, { MedCodeDemo } from "../../components/views/solutions/MedCode";
import ContextualSearchDesc, { ContextualSearchDemo } from "../../components/views/solutions/ContextualSearch";
import { LLM, LLMDemo } from "../../components/views/solutions/LLM";
import NBADesc, {
  NBADemo,
} from "../../components/views/solutions/NBA";

export default [
  {
    id: solutionIds.llm,
    title: "LLM",
    summary:
      "LLM (Large Language Model) evaluation involves assessing the performance of multiple language models across various tasks and Key evaluation metrics.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },

    image: llm,

    relatedSolutions: [
    ],
    descView: <LLM />,
    newSoln: true,
    demoView: <LLMDemo />,
    searchTerms: ["llm"],
  },
  {
    id: solutionIds.nba,
    title: "NEXT BEST ACTION",
    summary:
      "The concept of Next Best Action (NBA) is a customer-centric approach that leverages data and predictive analytics to determine the most effective action to take for a specific customer at any given moment.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
 
    image: responsibleAI,
 
    relatedSolutions: [
     
    ],
 
    descView: <NBADesc/>,
    newSoln: true,
    demoView: <NBADemo/>,
    searchTerms: ["nba"],
  },
  {
    id: solutionIds.priorAuth,
    title: "Prior Authorization Summarizer",
    summary:
      "An application that captures the entire Prior Authorization process and generates a report of summary.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: priorAuth,
    relatedSolutions: [],
    demoView: <PriorAuth />,
  },
  {
    id: solutionIds.sdlcRequirement,
    title: "SDLC - Requirement Engineering Platform",
    summary:
      "SDLC - Requirements describe why a project is needed, whom it will benefit, when and where it will take place, and what standards will be used to evaluate it.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: siteMonitoring,
    relatedSolutions: [],
    demoView: <BusinessRequirement />,
  },
  {
    id: solutionIds.docSearch,
    title: "Knowledge Search Platform",
    summary:
      "This application simplifies access to medical information in documents by allowing users to ask questions in natural language with comprehensive answers and citations.This application facilitates access to information within medical documents through a user-friendly interface.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: docSearch,
    relatedSolutions: [],
    demoView: <DocSearch />,
  },
  {
    id: solutionIds.docSearchBot,
    title: "Knowledge Base Chatbot",
    summary:
      "This application simplifies access to medical information in documents by allowing users to ask questions in natural language with comprehensive answers and citations.This application facilitates access to information within medical documents through a user-friendly interface.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: docSearchBot,
    relatedSolutions: [],
    demoView: <DocSearchBot />,
  },
  {
    id: solutionIds.auditAssistReportPlatform,
    title: "Audit Assist Reporting Platform",
    summary:
      "A audit report generating application that leverages LLM to generate the observations, classify them and summarize them. Audit Report Platform assist auditors in crafting audit reports efficiently which is pivotal to the auditing process.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: auditAssist,
    relatedSolutions: [],
    externalUrl: "https://v1-apps.vlifevirtusa.com/arp/",
    demoView: null,
  },
  {
    id: solutionIds.siteMonitoring,
    title: "Site Monitoring",
    summary:
      "Application to capture data, document observations on Clinical Trail site, and generate reports and includes sentiment analysis, risks and summary of the observations.Clinical trials are conducted mostly manually by large MedTech firms and their CROs using many spreadsheets and papers. ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: siteMonitoring,
    relatedSolutions: [],
    externalUrl: "https://v1-apps.vlifevirtusa.com/site-monitoring/",
    demoView: null,
  },
  {
    id: solutionIds.medTech,
    title: "Gen AI-Based Clinical Trial Ops",
    summary:
      "Application to perform semantic segmentation of robotic instruments in a robot-assisted surgery. Using deep learning methods like segmentation for dental complication detection is a novel approach gaining popularity in dentistry.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: medTech,
    relatedSolutions: [],
    demoView: <MedTech />,
  },
  {
    id: solutionIds.antibioticResistancePrediction,
    title: "Antibiotic Resistance Prediction",
    summary:
      "An application that determines whether a given blood sample is resistant to the antibiotic Ciprofloxacin, which is used for the treatment of gonorrhoea, based on the presence and absence combination of 16 distinct unitig gene sequences. ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: antibioticResistancePrediction,
    relatedSolutions: [
      solutionIds.geneExpressionAnalysis,
      solutionIds.primerClassifier,
    ],
    demoView: <AntibioticResistancePrediction />,
  },
  {
    id: solutionIds.incisioncare,
    title: "Incision Care Management",
    summary:
      "AI as a service to predict the age of an incision whether it is 30 days old or more by using GAN, ML and advanced mathematics.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: incisionCare,
    relatedSolutions: [],
    demoView: <IncisionCareManagement />,
    searchTerms: ["Remote Patient Monitoring"],
  },
  {
    id: solutionIds.deepeyeVisualizer,
    title: "DeepEye Visualizer",
    summary:
      "Unsure of what your data consists of? Plugin your data and visualize from a plethora of Automated Insights",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: imageDeepeyeVisualizer,
    relatedSolutions: [],
    externalUrl: "https://apps.vlifevirtusa.com/vlife-components/deepEye/",
    demoView: null,
  },
  {
    id: solutionIds.drugDiscovery,
    title: "Drug Discovery",
    summary:
      "The goal is to create a novel small molecule which can bind with the coronavirus, using deep learning techniques for molecule generation and PyRx to evaluate binding affinities.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: drugDiscovery,
    relatedSolutions: [
      solutionIds.antibioticResistancePrediction,
      solutionIds.jakInhibitors,
      solutionIds.drugRecommendation,
    ],
    demoView: <DrugDiscovery />,
    searchTerms: ["Deep Learning", "Pharmaceutical"],
  },
  {
    id: solutionIds.shoulderImplant,
    title: "Shoulder Implant Tracker",
    summary:
      "A tool to identify shoulder implants in X-ray images and videos using the YOLO Machine Learning framework ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: shoulderImplant,
    relatedSolutions: [],
    demoView: <ShoulderImplantTracker />,
    searchTerms: ["Post Op"],
  },
  {
    id: solutionIds.competitorAnalyzer,
    title: "Competitor Analyzer",
    summary:
      "Finding Internal Competitions for pharmaceutical stores using the sales data, distance between the stores and store size.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: competitorAnalyzer,
    relatedSolutions: [solutionIds.customerSegmentation],

    demoView: <CompetitorAnalyzer />,
    searchTerms: ["Market Research"],
  },
  {
    id: solutionIds.responsibleAI,
    title: "Responsible AI - Fairness",
    summary:
      "A user friendly wizard to implement ResponsibleAI and overcome the risk of biased ML models data.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },

    image: responsibleAI,

    relatedSolutions: [
      solutionIds.multiAccuracy,
      solutionIds.imageModel,
      solutionIds.responsibleAIModelExplainability,
      solutionIds.differentialPrivacy,
    ],

    descView: <ResponsibleAI />,
    newSoln: true,
    demoView: <ResponsibleUIDemo />,
    searchTerms: [
      "DP",
      "private",
      "protected",
      "privacy",
      "encryption",
      "noise",
      "inference attack",
      "sensitive data",
      "NLP",
      "canary",
    ],
  },
  {
    id: solutionIds.docSimilarityReport,
    title: "Document Similarity Report",
    summary:
      "This tool provides a detailed comparison of two clinical text documents using state-of-the-art context-based transformers, as well as entity comparison in the form of an interactive dashboard.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: docSimilarityReport,
    relatedSolutions: [solutionIds.legalDocParser, solutionIds.plmLifeCycle, solutionIds.patientSimilarities, solutionIds.nameMatcher],
    demoView: <DocSimilarityReportDemo />,
    newSoln: true,
    descView: <DocSimilarityReport />,
    searchTerms: [],
  },
  {
    id: solutionIds.imageSim,
    title: "Image Similarity Toolkit",
    summary:
      "ImageSim is a powerful toolkit that leverages a wide range of image similarity metrics to analyze and evaluate images across diverse scenarios and contexts. With its comprehensive set of tools, ImageSim enables effortless exploration and assessment of images based on various criteria, empowering you to make informed decisions. Simplify your image analysis workflow and enhance your understanding of image similarity with ImageSim.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: imageSim,
    relatedSolutions: [solutionIds.sinGan, solutionIds.dcGAN, solutionIds.periodontalChart],
    descView: <ImageSimDesc />,
    newSoln: true,
    demoView: <ImageSimDemo />,
    searchTerms: ["image similarity toolkit"],
  },
  {
    id: solutionIds.priorAuthorization,
    title: "PA Approval Prediction and Volume Forecasting",
    summary:
      "A tool to predict Prior Authorization approval and forecast the volume of PAs.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: priorAuth,
    relatedSolutions: [
      solutionIds.lifeInsurance,
      solutionIds.medicalAppointmentNoShow,
      solutionIds.medicalExpensePrediction,
    ],
    descView: <PriorAuthorization />,
    newSoln: true,
    demoView: <PriorAuthorizationDemo />,
    searchTerms: [
      "PA",
      "Predict",
      "Forecast",
      "Prior Auth",
      "Prior Authorization",
      "Approval",
    ],
  },
  {
    id: solutionIds.nlpToolkit,
    title: "NLP Toolkit​",
    summary:
      "This tool kit is used for Named Entity Recognition model for clinical entities which helps structure information in clinical patient notes by classifying medical conditions.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: nlpImg,
    relatedSolutions: [solutionIds.clinicalnotesPreprocessor, solutionIds.bern, solutionIds.textSummarizer],
    descView: <NlpToolkit />,
    newSoln: true,
    demoView: <NlpToolkitDemo />,
    searchTerms: [],
  },
  {
    id: solutionIds.roboticSegmentation,
    title: "Robotic Instrument Segmentation",
    summary:
      "Application to perform semantic segmentation of robotic instruments in a robot-assisted surgery.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: roboticInstrument,
    relatedSolutions: [],
    demoView: <RoboticSegmentation />,
    searchTerms: ["Perioperative/Periop"],
  },
  {
    id: solutionIds.mentalHealth,
    title: "Mental Health Treatment Prediction",
    summary:
      "Predict the willingness of an employee to avail mental health treatment provided by their employer",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: mentalHealth,
    relatedSolutions: [],
    demoView: <MentalHealth />,
  },
  {
    id: solutionIds.primerClassifier,
    title: "Gene Sequence Primer Classifier",
    summary:
      "Classifier to predict whether a particular sequence will bind to the protein.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: primerIdentification,
    relatedSolutions: [
      solutionIds.geneExpressionAnalysis,
      solutionIds.antibioticResistancePrediction,
      solutionIds.sequenceGenerator,
    ],
    demoView: <PrimerClassifier />,
    searchTerms: ["Bioinformatics", "Genomics"],
  },
  {
    id: solutionIds.forecastingExcellence,
    title: "Drug Forecasting Excellence",
    summary:
      "A tool to forecast the consumption of medications projected over the following years. ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: forecastingExcellence,
    relatedSolutions: [
      solutionIds.drugDiscovery,
      solutionIds.drugRecommendation,
      solutionIds.predictingADR,
    ],
    demoView: <ForecastingExcellence />,
    searchTerms: ["Drug Consumption", "Pharmaceutical"],
  },
  {
    id: solutionIds.bioLemmatizer,
    title: "Bio Lemmatizer ",
    summary:
      "A tool to identify and predict diagnosis codes related to the particular clinical note and the approximate cost of medication  ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: bioLemmatizer,
    relatedSolutions: [
      solutionIds.clinicalnotesPreprocessor,
      solutionIds.textSummarizer,
    ],
    demoView: <BioLemmatizer />,
  },
  {
    id: solutionIds.medicalImagingVisualQnA,
    title: "Visual Question Answering in Medical Imaging",
    summary:
      "A Visual Question Answering application to interpret questions on DME Fundus Images.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: vqa,
    relatedSolutions: [
      solutionIds.neptuneGaphBot,
      solutionIds.diabeticRetinopathy,
      solutionIds.ultraSoundImageSegmentation,
    ],
    demoView: <VisualQnAMedicalImaging />,
    searchTerms: [
      "computer vision",
      "retinopathy",
      "chatbot",
      "mask",
      "image segmentation",
      "VQA",
    ],
  },
  {
    id: solutionIds.drugCascading,
    title: "Multimodal Graph Networks – Drug Cascading",
    summary:
      "Application to predict the polypharmacy side effects in patient mortality and morbidity. ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: durgCascading,
    relatedSolutions: [solutionIds.drugDiscovery, solutionIds.sideEffects],
    demoView: <DrugCascading />,
  },
  {
    id: solutionIds.jakInhibitors,
    title: "Adoption of JAK Inhibitors",
    summary:
      "Application to identify potential early adopters of the newly launched JAK Inhibitors that may pose as competitor drugs to Dupixent in the AD market ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: jakInhibitors,
    relatedSolutions: [
      solutionIds.drugDiscovery,
      solutionIds.drugRecommendation,
    ],
    demoView: <JakInhibitors />,
    searchTerms: ["Regeneron", "Pharmaceutical", "Dupixent"],
  },
  {
    id: solutionIds.cancerousCellDetection,
    title: "Cancerous Cell Detection using Deep Learning ",
    summary:
      "Deep Learning for Detecting Cancer cells (DLDC) is an application that uses Immunohistochemical staining of a tissue and acquire multiplex Immunofluorescence images, cell segmentation and quantitative single-cell IHC scoring to determine the pathological diagnose of the tissue.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: cancerDetect,
    relatedSolutions: [
      solutionIds.ultraSoundImageSegmentation,
      solutionIds.brainTumorSegmentation,
      solutionIds.bloodCellInception,
    ],
    demoView: <CancerousCellDetection />,
    searchTerms: ["cancer", "image segmentation", "cell", "pathology", "IHC"],
  },
  {
    id: solutionIds.proteinDeepLearning,
    title: "Thermodynamic Stability of Protein using Deep Learning (TSPDL)",
    summary:
      "TSPDL is a tool which uses deep learning methods to measure stability of protein by predicting melting temperature.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: proteinDeep,
    relatedSolutions: [
      solutionIds.drugDiscovery,
      solutionIds.predictingADR,
      solutionIds.drugMOAPrediction,
      solutionIds.aqueousSolubility,
    ],
    demoView: <ProteinDeepLearning />,
    searchTerms: [
      "drug discovery",
      "thermodynamics",
      "melting point",
      "drug",
      "deepchem",
      "protein",
    ],
  },
  {
    id: solutionIds.toxPrediction,
    title: "Toxicity Prediction of Chemical Compounds",
    summary:
      "A tool that makes use of machine learning in drug discovery to determine the toxicity of a chemical compound",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: toxPrediction,
    relatedSolutions: [
      solutionIds.drugDiscovery,
      solutionIds.predictingADR,
      solutionIds.drugMOAPrediction,
      solutionIds.aqueousSolubility,
    ],
    demoView: <ToxPrediction />,
    searchTerms: [
      "drug discovery",
      "toxicity",
      "bioavailability",
      "drug",
      "Tox21",
      "FDA",
    ],
  },
  {
    id: solutionIds.medicalReportGeneration,
    title: "Medical Report Generation for Chest X-ray",
    summary:
      "A Deep Learning application that gives textual medical reports from Chest Xrays. It extract the  image features from Xrays and attempts to generate a paragraph that is similar to clinically tested reports by using machine learning algorithms.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: medicalReport,
    relatedSolutions: [
      solutionIds.textGeneration,
      solutionIds.americanSignLanguageDetection,
      solutionIds.legalDocParser,
    ],
    demoView: <MedicalReport />,
    searchTerms: ["xray", "report", "nlp", "image-to-text"],
  },
  {
    id: solutionIds.differentialPrivacy,
    title: "Differential Privacy on Tabular Dataset",
    summary:
      "Guarantee Privacy on Tabular datasets using Differential Privacy Algorithm",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: diffPrivacy,
    relatedSolutions: [
      solutionIds.imageModel,
      solutionIds.federatedLearningMedicalData,
      solutionIds.shapleyEMR,
    ],
    demoView: <DifferentialPrivacy />,
    searchTerms: [
      "Differential Privacy",
      "private",
      "protected",
      "privacy",
      "encryption",
      "noise",
      "inference attack",
      "sensitive data",
    ],
  },
  {
    id: solutionIds.drugRecommendation,
    title: "Drug Recommendation for Medical Practitioners",
    summary:
      "Recommendation engine for Medical practitioner for recommending suitable drugs based on their prescribing patterns.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: drugRecommendation,
    relatedSolutions: [
      solutionIds.drugDiscovery,
      solutionIds.predictingADR,
      solutionIds.antibioticResistancePrediction,
      solutionIds.jakInhibitors,
    ],
    demoView: <DrugRecommendation />,
    searchTerms: ["Pharmaceutical"],
  },
  {
    id: solutionIds.ocrHandwritten,
    title: "OCR - Handwritten Check Validation",
    summary:
      "Optical Character Recognition (OCR) is an automated text reading process from images (Bank Checks) that includes machine printed (typographic) or handwritten character recognition and numeral recognition.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: handWritternOCR,
    relatedSolutions: [],
    demoView: <OCRHandwritten />,
  },
  {
    id: solutionIds.mirthHL7,
    title: "Mirth HL7 Transform",
    summary:
      "A data interoperability engine to transform one messages to other. The tool maps the field from an existing message to HL7 message.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: mirth,
    relatedSolutions: [
      solutionIds.dataInteroperablePlatform,
      solutionIds.interSystems,
    ],
    demoView: <MirthHL7 />,
    searchTerms: ["Interoperability"],
  },
  {
    id: solutionIds.dataInteroperablePlatform,
    title: "Data Interoperable RPM Platform",
    summary:
      "The application intends to showcase capabilities using Intersystem services and solutions around data management & data interoperability for HLS industry.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: dataInteroperablePlatform,
    relatedSolutions: [solutionIds.lengthStayHospital],
    demoView: <DataInteroperablePlatform />,
  },
  {
    id: solutionIds.surgerySkills,
    title: "vDoc: Skill Evaluation of Surgeons",
    summary:
      "vDoc is a tool to evaluate Surgical skills (Knot-Tying, Suturing and Needle Passing) of Surgeons into three categories – Novice, Intermediate and Expert. It is a very important step towards improving surgical practice in Healthcare.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: surgerySkills,
    relatedSolutions: [solutionIds.roboticSegmentation, solutionIds.rom],

    demoView: <SurgerySkills />,
    searchTerms: ["Perioperative/Periop"],
  },
  {
    id: solutionIds.tabularDataSynthesizer,
    title: "Tabular Data Synthesizer",
    summary:
      "An interactive visual experimentation tool for Conditional Tabular Generative Adversarial Networks (CTGANS) showcasing tabular data generation. ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: tabularData,
    relatedSolutions: [
      solutionIds.gan,
      solutionIds.fasterAi,
    ],
    demoView: <TabularData />,
    searchTerms: ["Synthetic Data", "CTGANS"],
  },
  {
    id: solutionIds.ultraSoundImageSegmentation,
    title: "Ultrasound Image Segmentation",
    summary:
      "Using advanced Machine Learning model to read a greyscale ultrasound image and segment four anatomical objects",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: ultraSoundImageSegmentation,
    relatedSolutions: [
      solutionIds.brainTumorSegmentation,
      solutionIds.deepScap,
      solutionIds.pneumoniaDiagnosis,
    ],
    demoView: <UltraSoundImageSegmentation />,
  },
  {
    id: solutionIds.maskDetection,
    title: "Face Mask Detection",
    summary:
      "vLife Mask Detector helps in detection of safety masks. It helps in determining the safety of stakeholders in times of Epidemics like Covid-19",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: maskDetection,
    relatedSolutions: [
      solutionIds.portraitShadowRemoval,
      solutionIds.faceEmotionRecognizer,
      solutionIds.handGuesterRecognition,
      solutionIds.socialDistance,
    ],
    demoView: <MaskDetection />,
    searchTerms: ["Covid"],
  },
  {
    id: solutionIds.mentalHealthPrevalence,
    title: "Mental Health Prevalence",
    summary:
      "Analysis to find how the mental health cases are distributed across countries over the years. Countries are grouped in clusters based on prevalence of mental health cases as a percentage of population.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: mentalHealthPrevalence,
    relatedSolutions: [
      solutionIds.behaviouralAnalysis,
      solutionIds.patientSimilarities,
    ],
    demoView: <MentalHealthPrevalence />,
  },
  {
    id: solutionIds.ocrCheckValidation,
    title: "OCR Check Validation",
    summary:
      "Optical Character Recognition technique in healthcare has the capability to recognize alphanumeric characters present in documents and store the information in an electronic file.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: ocr,
    relatedSolutions: [solutionIds.bioLemmatizer, solutionIds.legalDocParser],
    demoView: <OCRCheckValidation />,
    searchTerms: ["Cheque"],
  },
  {
    id: solutionIds.shapleyEMR,
    title: "Shapley UI - EMR",
    summary:
      "A metric to quantify the value of each training datum to the predictor performance. Identify and mathematically reduce noise from large training datasets",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: shapleyEMR,
    relatedSolutions: [
      solutionIds.shapleyImages,
      solutionIds.multiAccuracy,
      solutionIds.cpca,
      solutionIds.cpcaImages,
    ],
    demoView: <ShapleyEMR />,
    searchTerms: ["SAIL", "Stanford"],
  },
  {
    id: solutionIds.predictingAdClick,
    title: "Predicting Ad Click Through Rate",
    summary:
      "Identify right customers for an Advertisement based on consumer demographics",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: adClick,
    relatedSolutions: [],
    demoView: <PredictingAdClick />,
    searchTerms: ["Social Media"],
  },
  {
    id: solutionIds.customerSegmentation,
    title: "Customer Segmentation",
    summary:
      "Application to segment the customers based on the Customer Lifetime Value calculated by RFM(Recency, Frequency and Monetary) Analysis",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: customerSegmentation,
    relatedSolutions: [solutionIds.competitorAnalyzer],
    demoView: <CustomerSegmentation />,
    searchTerms: ["Market Research"],
  },
  {
    id: solutionIds.predictingADR,
    title: "Predicting Adverse Drug Reactions",
    summary:
      "Application to  predict if a patient will have any side effect or adverse reaction after taking a particular drug. ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: predictingADR,
    relatedSolutions: [
      solutionIds.sideEffects,
      solutionIds.mortalityEffect,
      solutionIds.drugRecommendation,
    ],
    demoView: <PredictingADR />,
    searchTerms: ["Pharmaceutical"],
  },
  {
    id: solutionIds.geneticVariantClassification,
    title: "Genetic Variant Classifier",
    summary:
      "Genetic Variant Classification (Binary Classification to predict whether genetic variant is conflicted or not)",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: geneticVariantClassification,
    relatedSolutions: [solutionIds.deepNuclie],
    demoView: <GeneticVariantClassification />,
    searchTerms: ["Bioinformatics", "Genomics"],
  },
  {
    id: solutionIds.classfyingRheumotoidArthritisPatients,
    title: "Classifying Rheumatoid Arthritis Patients",
    summary:
      "A Machine Learning application that helps user to classify whether the patient has Rheumatoid Arthritis or not based on their medical history and clinical measures.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: classfyingRheumotoidArthritisPatients,
    relatedSolutions: [solutionIds.jakInhibitors],
    demoView: <ClassfyingRheumotoidArthritisPatients />,
  },
  {
    id: solutionIds.predictingHeartDisease,
    title: "Predicting Heart Disease",
    summary:
      "Diagnosing any serious disease using a black box approach is a hard sell in machine learning. This automated algorithm predicts whether a patient has a heart disease, along with the factors that affect it. ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: predictingHeartDisease,
    relatedSolutions: [],
    demoView: <PredictingHeartDisease />,
  },
  {
    id: solutionIds.predictingType2Diabetes,
    title: "Predicting Type2 Diabetes",
    summary:
      "An application that predicts whether a patient has Type 2 Diabetes or not by analyzing the laboratory results captured for that particular patient. ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: predictingType2Diabetes,
    relatedSolutions: [],
    demoView: <PredictingType2Diabetes />,
    searchTerms: ["Diagnosis", "Clinical Procedure"],
  },
  {
    id: solutionIds.timePeriod2CompleteClinicalTrial,
    title: "Predict Time Taken to Complete a Clinical Trial Phase",
    summary:
      "Based on the data collected from NYS clinical trials, this tool will predict the number of days it would take to complete a phase in a clinical trial",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: timePeriod2CompleteClinicalTrial,
    relatedSolutions: [solutionIds.sideEffects, solutionIds.mortalityEffect],
    demoView: <TimePeriod2CompleteClinicalTrial />,
  },
  {
    id: solutionIds.geneVariations,
    title: "Evidence Based Genetic Classification",
    summary:
      "When a patient seems to have cancer,  a tumor sample is taken from the patient and it goes through genetic sequencing of DNA",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: geneVariations,
    relatedSolutions: [
      solutionIds.geneExpressionAnalysis,
      solutionIds.sequenceGenerator,
      solutionIds.antibioticResistancePrediction,
    ],
    demoView: <GeneVariations />,
    searchTerms: ["Cancer", "Tumor", "BioInformatics"],
  },
  {
    id: solutionIds.predictingEligibility,
    title: "Predicting Eligibility",
    summary:
      "A tool that leverages Deep Neural Network capabilities to predict whether a patient is eligible for a cancer clinical trial based on their diagnosis notes as well as EMR data. ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: predictingEligibility,
    relatedSolutions: [solutionIds.breastCancerProteomes],
    demoView: <PredictingEligibility />,
  },
  {
    id: solutionIds.neptuneGaphBot,
    title: "Knowledge Graph & Chatbot",
    summary:
      "Knowledge graph applications are one of the most popular graph use cases being built on Amazon Neptune today.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: kgbot,
    relatedSolutions: [
      solutionIds.knowledgeGraph,
      solutionIds.networkGraphAnalyzer,
      solutionIds.videoMetadataExtraction,
    ],
    demoView: <NeptuneBotGraph />,
    searchTerms: [
      "knowledge graph",
      "NLP",
      "entity extraction",
      " Amazon Lex",
      "AWS",
    ],
  },
  {
    id: solutionIds.sideEffects,
    title: "Predict if a patient has side effects for a drug",
    summary:
      "A tool to predict whether a patient will experience any side effects after consuming a particular drug. ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: sideEffects,
    relatedSolutions: [solutionIds.mortalityEffect, solutionIds.drugCascading],
    demoView: <SideEffects />,
  },
  {
    id: solutionIds.brainTumorSegmentation,
    title: "Brain Tumor Segmentation",
    summary:
      "Brain tumor segmentation seeks to separate healthy tissue from tumorous regions",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: brainTumorSegmentation,
    relatedSolutions: [
      solutionIds.strokePrediction,
      solutionIds.predictingCerebralInfarction,
    ],
    demoView: <BrainTumorSegmentation />,
  },
  {
    id: solutionIds.networkGraphAnalyzer,
    title: "Network Graph Analyzer",
    summary:
      "Application to identify the crucial Diseases and associated comorbidities in a patient network. ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: networkGraphAnalyzer,
    relatedSolutions: [
      solutionIds.drugCascading,
      solutionIds.drugDiscovery,
      solutionIds.dopplegangerView,
    ],
    demoView: <NetworkGraphAnalyzer />,
    searchTerms: ["Neo4j", "Drug", "Visualization"],
  },
  {
    id: solutionIds.selfService,
    title: "Self Service Dashboard",
    summary:
      "Power of Exploration at user service. Application for users can dig in deep and create insights at their own pace",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: selfService,
    relatedSolutions: [solutionIds.cohortSense],
    demoView: <SelfService />,
    searchTerms: ["Exploration"],
  },
  {
    id: solutionIds.timelinessOfCare,
    title: "Timeliness of Care",
    summary:
      "An application to minimize your waiting period for doctor’s availability and appointment. It helps in identifying which hospital or state provide care in timeliest manner.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: timelinessOfCare,
    relatedSolutions: [
      solutionIds.sideEffects,
      solutionIds.timePeriod2CompleteClinicalTrial,
    ],
    demoView: <TimelinessOfCare />,
  },
  {
    id: solutionIds.shapleyImages,
    title: "Shapley UI - IMAGES",
    summary:
      "A metric to quantify the value of each training datum to the predictor performance. Identify and mathematically reduce noise from large training datasets",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: shapleyImages,
    relatedSolutions: [
      solutionIds.shapleyEMR,
      solutionIds.cpca,
      solutionIds.cpcaImages,
      solutionIds.multiAccuracy,
    ],
    demoView: <ShapleyImages />,
    searchTerms: ["SAIL", "Stanford"],
  },
  {
    id: solutionIds.multiAccuracy,
    title: "Multi Accuracy",
    summary:
      "Black-Box Post-Processing for Fairness in Classification. Audit and improve the sub-group accuracy without impacting the actual classifier",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: multiAccuracy,
    relatedSolutions: [
      solutionIds.shapleyEMR,
      solutionIds.shapleyImages,
      solutionIds.cpca,
      solutionIds.cpcaImages,
    ],
    demoView: <MultiAccuracy />,
    searchTerms: ["SAIL", "Stanford"],
  },
  {
    id: solutionIds.clinicalnotesPreprocessor,
    title: "Clinical Notes Preprocessor",
    summary:
      "Application using Natural Language Processing to preprocess the clinical notes – documents having medical history of the patients for future reference.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: clinicalNotes,
    relatedSolutions: [solutionIds.bioLemmatizer, solutionIds.textSummarizer],
    demoView: <ClinicalNotesPreprocessor />,
    searchTerms: ["NLP"],
  },
  {
    id: solutionIds.feasibilityAnalysis,
    title: "Feasibility Analysis of Clinical Trial",
    summary:
      "Application using Deep Learning model to predict the feasibility of a Clinical Trial from its title and summary.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: feasibilityAnalyzer,
    relatedSolutions: [],
    demoView: <FeasibilityAnalysis />,
  },
  {
    id: solutionIds.mortalityEffect,
    title: "Predicting Mortality Effect of Drug on a Patient",
    summary:
      "An application to predict whether consumption of certain drug prescribed to patients have mortality effect or not based on the patient and drug details. ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: mortalityEffect,
    relatedSolutions: [solutionIds.sideEffects, solutionIds.predictingADR],
    demoView: <MortalityEffect />,
    searchTerms: ["Adverse", "Death", "Side Effect"],
  },
  {
    id: solutionIds.strokePrediction,
    title: "Prediction of Heart Stroke",
    summary:
      "The ML model is designed to integrate multiple indicators from many data sources to predict if a patient has stroke and the risk factors associated with it",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: strokePrediction,
    relatedSolutions: [solutionIds.brainTumorSegmentation],
    demoView: <StrokePrediction />,
    searchTerms: ["Heart", "Cardiac"],
  },
  {
    id: solutionIds.predictingCerebralInfarction,
    title: "Predicting Cerebral Infarction",
    summary:
      "An application that helps user to identify the risk probability of a patient to experience stroke or cerebral infarction using the Machine Learning model. ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: predictingCerebralInfarction,
    relatedSolutions: [
      solutionIds.brainTumorSegmentation,
      solutionIds.strokePrediction,
    ],
    demoView: <PredictingCerebralInfarction />,
    searchTerms: ["Brain", "Stroke"],
  },
  {
    id: solutionIds.textSummarizer,
    title: "Text Summarizer",
    summary:
      "Deep Learning Techniques to help Doctors quick scan the huge set of clinical notes and summarize huge texts",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: textSummarizer,
    relatedSolutions: [
      solutionIds.bioLemmatizer,
      solutionIds.clinicalnotesPreprocessor,
    ],
    demoView: <TextSummarizer />,
    searchTerms: ["Preprocessor", "NLP", "Clinical Notes"],
  },
  {
    id: solutionIds.cpcaImages,
    title: "cPCA-Images",
    summary:
      "Exploring patterns enriched in a dataset with contrastive principal component analysis",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: cpcaImages,
    relatedSolutions: [
      solutionIds.shapleyEMR,
      solutionIds.shapleyImages,
      solutionIds.cpca,
      solutionIds.multiAccuracy,
    ],
    demoView: <CpcaImages />,
    searchTerms: ["SAIL", "Stanford"],
  },
  {
    id: solutionIds.cpca,
    title: "CPCA",
    summary:
      "Exploring patterns enriched in a dataset with contrastive principal component analysis",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: cpca,
    relatedSolutions: [
      solutionIds.shapleyEMR,
      solutionIds.shapleyImages,
      solutionIds.cpcaImages,
      solutionIds.multiAccuracy,
    ],
    demoView: <Cpca />,
    searchTerms: ["SAIL", "Stanford"],
  },
  {
    id: solutionIds.drugReviewAnalyzer,
    title: "Drug Review Analyzer - Media Analytics",
    summary:
      "This use case collects multiple urls from a user and web scrapes the given links for the drug of interest, providing a statistical breakdown of the scraped data",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: drugReviewAnalyzer,
    relatedSolutions: [solutionIds.drugCascading],
    demoView: <DrugReviewAnalyzer />,
    searchTerms: [
      "Social Media",
      "Market Research",
      "Web scrap",
      "Sentiment Analysis",
    ],
  },
  {
    id: solutionIds.caloriePredictor,
    title: "Calorie Predictor",
    summary:
      "Upload a food item image to predict the type, size and calories in the food based based on highly accurate deep learning model",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: caloriePredictor,
    relatedSolutions: [solutionIds.portraitShadowRemoval],
    demoView: <CaloriePredictor />,
    searchTerms: ["Food", "Healthy", "Computer Vision"],
  },
  {
    id: solutionIds.lungCancerComorbidities,
    title: "Lung Cancer Comorbidities Predictor",
    summary:
      "Scaling Machine Learning Capabilities in Sound Database to predict the onset of lung diseases by Analyzing the respiratory Sounds",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: lungCancerComorbidities,
    relatedSolutions: [
      solutionIds.networkGraphAnalyzer,
      solutionIds.breastCancerClassification,
    ],
    demoView: <LungCancerComorbidities />,
    searchTerms: ["Deep Learning", "Pneumonia"],
  },
  {
    id: solutionIds.liverDiseasePrediction,
    title: "Liver Disease Prediction",
    summary:
      "An application to predict whether the patient is prone to Liver disease or not by considering chemical and demographic features of patient’s liver by employing Machine Learning models",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: liverDiseasePrediction,
    relatedSolutions: [],
    demoView: <LiverDiseasePrediction />,
    searchTerms: ["Deep Learning"],
  },
  {
    id: solutionIds.deepScap,
    title: "Deep Scap",
    summary:
      "Artificial Intelligence application to predict anatomical features of the scapulae from the projections on the principal components of the Statistical Shape Model ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: deepScap,
    relatedSolutions: [],
    demoView: <DeepScap />,
    searchTerms: ["PCA", "Shoulder", "Bone"],
  },
  {
    id: solutionIds.deepNuclie,
    title: "Deep Nuclei",
    summary:
      "An Algorithm using advanced Analytics concepts of Image segmentation to automate the process of nucleus detection. ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: deepNuclie,
    relatedSolutions: [
      solutionIds.predictOligomericState,
      solutionIds.geneticVariantClassification,
    ],
    demoView: <DeepNuclie />,
    searchTerms: ["Computer Vision", "Genomics"],
  },
  {
    id: solutionIds.geneExpressionAnalysis,
    title: "Gene Expression Analysis",
    summary:
      "The application aims at classifying people between Acute myeloid leukaemia (AML) and Acute lymphoblastic leukaemia (ALL) who either have some related symptoms or have a family history regarding these diseases based on their Gene Expressions.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: geneExpressionAnalysis,
    relatedSolutions: [
      solutionIds.primerClassifier,
      solutionIds.antibioticResistancePrediction,
      solutionIds.sequenceGenerator,
    ],
    demoView: <GeneExpressionAnalysis />,
    searchTerms: ["Genomics", "Molecule", "BioInformatics"],
  },
  {
    id: solutionIds.predictBioMechanical,
    title: "Biomechanical Feature Extraction ",
    summary:
      "Automated Machine Learning Algorithm to predict if a patient has hernia/Spondylolisthesis by analysing the biomechanical features",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: predictBioMechanical,
    relatedSolutions: [],
    demoView: <PredictBioMechanical />,
    searchTerms: ["Pelvic", "Hernia"],
  },
  {
    id: solutionIds.breastCancerClassification,
    title: "Breast Cancer Classification",
    summary:
      "Classifying Breast Cancer into benign or Malignant based on the characteristics of the cell nuclei",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: breastCancerClassification,
    relatedSolutions: [
      solutionIds.lungCancerComorbidities,
      solutionIds.melanomaClassification,
    ],
    demoView: <BreastCancerClassification />,
    searchTerms: ["Wisconsin", "Oncology"],
  },
  {
    id: solutionIds.breastCancerProteomes,
    title: "Breast Cancer Proteomes",
    summary:
      "The data is used to assess how the mutations in the DNA are affecting the protein expression landscape in breast cancer.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: breastCancerProteomes,
    relatedSolutions: [
      solutionIds.predictingEligibility,
      solutionIds.melanomaClassification,
    ],
    demoView: <BreastCancerProteomes />,
    searchTerms: ["Genomics"],
  },
  {
    id: solutionIds.cancerLectins,
    title: "Cancer Lectins Predictor",
    summary:
      "Unsure of the future direction of cancer therapy? Plug in the protein sequence to this effective and efficient computational tool to identify cancerlectins in a tumor cell.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: cancerLectins,
    relatedSolutions: [],
    demoView: <CancerLectins />,
    searchTerms: ["Deep Learning"],
  },
  {
    id: solutionIds.predictOligomericState,
    title: "Predicting Oligomeric State",
    summary:
      "Predicting Oligomeric State of Fluorescent Proteins using Machine Learning models. FPs represent an important class of bioluminescent proteins because of their immense utility for biomedical imaging in the life sciences.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: predictOligomericState,
    relatedSolutions: [],
    demoView: <PredictOligomericState />,
    searchTerms: ["Genomics", "Molecule", "Transformers"],
  },
  {
    id: solutionIds.frequentPharmacyVisitor,
    title: "Frequent Pharmacy Visitor",
    summary:
      "A mathematical approach to predict if a patient is frequently visiting pharmacy.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: frequentPharmacyVisitor,
    relatedSolutions: [solutionIds.lengthStayHospital],
    demoView: <FrequentPharmacyVisitor />,
    searchTerms: ["Pharmacist"],
  },
  {
    id: solutionIds.behaviouralAnalysis,
    title: "Behaviour Analysis of Patients",
    summary:
      "Why is the oligomerization of natural fluorescent proteins a problem? ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: behaviouralAnalysis,
    relatedSolutions: [
      solutionIds.patientSimilarities,
      solutionIds.patientStratification,
    ],
    demoView: <BehaviouralAnalysis />,
    searchTerms: ["Deep Learning"],
  },
  {
    id: solutionIds.humanproteinImageclassification,
    title: "Human Protein Image Classification",
    summary:
      "An application where human protein images are classified as different human organelles labels based on images obtained using high through-put confocal microscopy. ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: humanproteinImageclassification,
    relatedSolutions: [solutionIds.miceProteinClassification],
    demoView: <HumanproteinImageclassification />,
    searchTerms: [
      "Genomics",
      "BioInformatics",
      "Deep Learning",
      "Computer Vision",
    ],
  },
  {
    id: solutionIds.genomePhenotype,
    title: "Genome Phenotype in SNPs",
    summary:
      "An application for the analysis of gene variations and discovery of novel associations with traits and diseases by using Genome Phenotype SNPs raw data of an individual taken from DNA gene sequencing tools ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: genomePhenotype,
    relatedSolutions: [solutionIds.geneSimilarity],
    demoView: <GenomePhenotype />,
    searchTerms: ["Genomics", "Molecule", "Sequence"],
  },
  {
    id: solutionIds.miceProteinClassification,
    title: "Mice Protein Expression",
    summary:
      "Identification of subsets of proteins that are discriminant between the classes of mice described based on features such as genotype, behavior and treatment.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: miceProteinClassification,
    relatedSolutions: [solutionIds.humanproteinImageclassification],
    demoView: <MiceProteinClassification />,
    searchTerms: ["Genomics", "Molecule", "BioInformatics"],
  },
  {
    id: solutionIds.genomeFrequencyAnalysis,
    title: "Genome Proteins Frequency Analysis",
    summary:
      "A tool to identify the most occurring protein in the SAR-COV-2 virus affected DNA sequence. It will aid in identifying alpha helix which help during vaccine creation.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: genomeFrequencyAnalysis,
    relatedSolutions: [solutionIds.genomePhenotype],
    demoView: <GenomeFrequencyAnalysis />,
    searchTerms: ["Genomics", "Molecule", "BioInformatics"],
  },
  {
    id: solutionIds.geneSimilarity,
    title: "Gene Similarity of Pandemics",
    summary:
      "Covid-19 virus affected DNA is compared with other virus and pandemics like SARS, MERS, Malaria, HIV etc to find the similarity in gene information.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: geneSimilarity,
    relatedSolutions: [
      solutionIds.primerClassifier,
      solutionIds.genomePhenotype,
    ],
    demoView: <GeneSimilarity />,
    searchTerms: ["Genomics", "Molecule", "BioInformatics"],
  },
  {
    id: solutionIds.gastrointestinalCancer,
    title: "Gastrointestinal Cancer Classification",
    summary:
      "Application to identify whether patients with gastrointestinal cancer respond well to immunotherapy or not by analyzing histological image patches of colorectal and gastric cancer patients.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: gastrointestinalCancer,
    relatedSolutions: [
      solutionIds.prostateCancerDetection,
      solutionIds.cervicalCancerClassification,
      solutionIds.cryoTherapyAnalysis,
    ],
    demoView: <GastrointestinalCancer />,
    searchTerms: ["Oncology", "Tumor", "Computer Vision"],
  },
  {
    id: solutionIds.prostateCancerDetection,
    title: "Prostate Cancer Detection",
    summary:
      "Detecting Prostate Cancer on images of prostate tissue samples, and estimating severity of the disease using the most extensive multi-center dataset on Gleason grading.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: prostateCancerDetection,
    relatedSolutions: [
      solutionIds.melanomaClassification,
      solutionIds.skinCancerAnalysis,
    ],
    demoView: <ProstateCancerDetection />,
    searchTerms: ["Oncology", "Computer Vision"],
  },
  {
    id: solutionIds.cervicalCancerScreening,
    title: "Cervical Cancer Screening",
    summary:
      "Application to classify images to identify the transformation zones in cervix which improves the quality and efficiency of cervical cancer patients screening.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: cervicalCancerScreening,
    relatedSolutions: [
      solutionIds.gastrointestinalCancer,
      solutionIds.cervicalCancerRisk,
      solutionIds.cryoTherapyAnalysis,
    ],
    demoView: <CervicalCancerScreening />,
    searchTerms: ["Oncology", "Computer Vision"],
  },
  {
    id: solutionIds.vnet,
    title: "VNET",
    summary: "VNet",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: drugReviewAnalyzer,
    relatedSolutions: [],
    demoView: <Vnet />,
    isCustomPage: true,
  },
  {
    id: solutionIds.colonPathology,
    title: "Colon Pathology Classification",
    summary:
      "Study of the collection of textures in histological images of human colorectal cancer in order to classify the images into 8 different classes viz. Adino, Gastro, Lympho.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: colonPathology,
    relatedSolutions: [
      solutionIds.activationMetastasis,
      solutionIds.skinCancerAnalysis,
      solutionIds.cryoTherapyAnalysis,
    ],
    demoView: <ColonPathology />,
    searchTerms: ["Oncology", "Cancer", "Computer Vision"],
  },
  {
    id: solutionIds.geneticAlgorithm,
    title: "Genetic Algorithm for Optimized Model",
    summary:
      "Genetic Algorithm is metaheuristic inspired high quality evolutionary algorithm used to generate high quality solutions of optimization by relying biological inspired operations of mutation, crossover and selection",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: geneticAlgorithm,
    relatedSolutions: [
      solutionIds.geneticVariantClassification,
      solutionIds.geneVariations,
      solutionIds.sequenceGenerator,
    ],
    demoView: <GeneticAlgorithm />,
    searchTerms: ["Genomics", "Molecule", "BioInformatics"],
  },
  {
    id: solutionIds.retweetCountPredictor,
    title: "Predicting COVID-19 Retweet Count",
    summary:
      "Application to identify predicting power (number of retweets) of a tweet that contains words/phrases around coronavirus ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: retweetCountPredictor,
    relatedSolutions: [],
    demoView: <RetweetCountPredictor />,
    searchTerms: ["Corona", "Social Media", "NLP"],
  },
  {
    id: solutionIds.heartSoundsClassification,
    title: "Heartbeat Sounds Classification",
    summary:
      "Automated method involving Machine Learning to predict if the Heartbeat is normal or one among the set of 3 abnormalities",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: heartSoundsClassification,
    relatedSolutions: [
      solutionIds.strokePrediction,
      solutionIds.predictingHeartDisease,
    ],
    demoView: <HeartSoundsClassification />,
    searchTerms: ["Sound", "Cardiac"],
  },
  {
    id: solutionIds.medicalAppointmentNoShow,
    title: "Medical Appointment Noshow",
    summary:
      "A scientific approach to estimate No-Shows of a patient and plan resources accordingly",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: medicalAppointmentNoShow,
    relatedSolutions: [
      solutionIds.hospitalRecommendation,
      solutionIds.devReadmission,
    ],
    demoView: <MedicalAppointmentNoShow />,
    searchTerms: ["Hospital"],
  },
  {
    id: solutionIds.freightCostPrediction,
    title: "Supply Chain Management Freight Cost Prediction",
    summary:
      "Application to predict the shipment cost of flow of medical goods and services from manufacturer to patients.  ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: freightCostPrediction,
    relatedSolutions: [],
    demoView: <FreightCostPrediction />,
    searchTerms: ["Logistic", "Pharmaceutical"],
  },
  {
    id: solutionIds.chestXRaysCovid19,
    title: "Classifying Chest X-Rays to flag Covid-19",
    summary:
      "A Deep learning model to classify the lungs X-ray images of patients to identify whether a patient is prone to COVID 19 or not by analyzing the patterns in an X-ray.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: chestXRaysCovid19,
    relatedSolutions: [],
    demoView: <ChestXRaysCovid19 />,
    searchTerms: ["Corona", "Pneumonia", "Computer Vision"],
  },
  {
    id: solutionIds.faceEmotionRecognizer,
    title: "Face Emotion Recognizer",
    summary:
      "Application to recognise real-time Face Emotion in images and videos",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: faceEmotionRecognizer,
    relatedSolutions: [solutionIds.maskDetection],
    demoView: <FaceEmotionRecognizer />,
    searchTerms: ["Deep Learning", "Computer Vision"],
  },
  {
    id: solutionIds.cryoTherapyAnalysis,
    title: "Cryo Therapy Analysis",
    summary:
      "Analysis of the information related to patients, whose characteristics such as number of warts, area of the wart, sex and age etc. are used to determine the level of cancer extremity i.e., benign or malignant.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: cryoTherapyAnalysis,
    relatedSolutions: [
      solutionIds.gastrointestinalCancer,
      solutionIds.cervicalCancerScreening,
      solutionIds.activationMetastasis,
    ],
    demoView: <CryoTherapyAnalysis />,
    searchTerms: ["Surgery"],
  },
  {
    id: solutionIds.melanomaClassification,
    title: "Melanoma Skin Cancer Classification",
    summary:
      "An application to identify melanoma in images of skin lesions of patients. Image analysis tools that automate the diagnosis of melanoma will improve dermatologists' diagnostic accuracy. ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: melanomaClassification,
    relatedSolutions: [
      solutionIds.skinCancerAnalysis,
      solutionIds.activationMetastasis,
    ],
    demoView: <MelanomaClassification />,
    searchTerms: ["Oncology", "Computer Vision", "Deep Learning"],
  },
  {
    id: solutionIds.cervicalCancerRisk,
    title: "Cervical Cancer Risk Classification",
    summary:
      "Application to identify the risk of Cervical Cancer in patients after Biopsy examination using Advanced Machine Learning Techniques. ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: cervicalCancerRisk,
    relatedSolutions: [
      solutionIds.gastrointestinalCancer,
      solutionIds.cervicalCancerScreening,
      solutionIds.cryoTherapyAnalysis,
    ],
    demoView: <CervicalCancerRisk />,
    searchTerms: ["Oncology", "Computer Vision"],
  },
  {
    id: solutionIds.eyesStatePrediction,
    title: "Eyes State Prediction using EEG",
    summary:
      "Predict whether eyes are closed or open using the brain wave data observed during a Electroencephalogram (EEG) - test to evaluate the electrical activity in the brain.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: eyesStatePrediction,
    relatedSolutions: [solutionIds.brainTumorSegmentation],
    demoView: <EyesStatePrediction />,
    searchTerms: ["Brain"],
  },
  {
    id: solutionIds.insuranceComplaintsResolution,
    title: "Insurance Company Complaints Resolution",
    summary:
      "Demo for predicting the status of Health Insurance complaints helping the consumers in filing complaints.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: insuranceComplaintsResolution,
    relatedSolutions: [
      solutionIds.networkGraphAnalyzer,
      solutionIds.lengthStayHospital,
      solutionIds.medicareLocator,
    ],
    demoView: <InsuranceComplaintsResolution />,
    searchTerms: ["Payers", "Providers"],
  },
  {
    id: solutionIds.healthCareCostEstimation,
    title: "Out of Pocket Cost Estimation",
    summary:
      "Application that predicts the estimated total expense for any kind of medical procedure and calculates the insurance claimable amount along with the out-of-pocket expenses. ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: healthCareCostEstimation,
    relatedSolutions: [],
    demoView: <HealthCareCostEstimation />,
    searchTerms: ["Payers", "Insurance "],
  },
  {
    id: solutionIds.usUninsured,
    title: "Understanding the US Rural Uninsured",
    summary:
      "This application analyses the primary factors that affect the increase or decrease in the uninsured population across various counties in the United States. ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: usUninsured,
    relatedSolutions: [
      solutionIds.lengthStayHospital,
      solutionIds.medicareLocator,
    ],
    demoView: <UsUninsured />,
    searchTerms: ["Payer", "Market Research"],
  },
  {
    id: solutionIds.lifeInsurance,
    title: "Life Insurance Risk Assessment",
    summary:
      "A tool to assess the risk associated with the life insurance using the details of the policy holder. ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: lifeInsurance,
    relatedSolutions: [solutionIds.lengthStayHospital],
    demoView: <LifeInsurance />,
    searchTerms: ["Payer", "Health assessment", "Disease Prevention"],
  },
  {
    id: solutionIds.socialDistance,
    title: "Social Distance Detector",
    summary:
      "Tool to detect social distance in a video having people walking along a passage, street, or corridor. ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: socialDistance,
    relatedSolutions: [
      solutionIds.maskDetection,
      solutionIds.portraitShadowRemoval,
    ],
    demoView: <SocialDistance />,
    searchTerms: ["Deep Learning", "Disease Prevention", "Covid-19"],
  },
  {
    id: solutionIds.predictMortalityKorean,
    title: "Predicting Korean Mortality Cases",
    summary:
      "Application for Machine learning prediction for mortality of patients diagnosed with COVID-19 on a nationwide cohort of South Korea.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: predictMortalityKorean,
    relatedSolutions: [
      solutionIds.chestXRaysCovid19,
      solutionIds.predictConfimedCases,
    ],
    demoView: <PredictMortalityKorean />,
    searchTerms: ["Forecast", "Market Research"],
  },
  {
    id: solutionIds.askForAModel,
    title: "Ask for a Model",
    summary:
      "A form enabling the users to upload data and getting a ML model build on that data by vLife team.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: askForAModel,
    relatedSolutions: [],
    demoView: <AskForAModel />,
    isCustomPage: true,
  },
  {
    id: solutionIds.activationMetastasis,
    title: "Activation of Metastasis",
    summary:
      "Application to identify metastatic cancer in small image patches taken from larger digital pathology scans.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: activationMetastasis,
    relatedSolutions: [
      solutionIds.cryoTherapyAnalysis,
      solutionIds.skinCancerAnalysis,
    ],
    demoView: <ActivationMetastasis />,
    searchTerms: ["Tissues", "Genomics", "Deep Learning"],
  },
  {
    id: solutionIds.insuranceChurn,
    title: "Insurance Churn Prediction",
    summary:
      "Tool to predict the probability of customer churn for insurance provider companies.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: insuranceChurn,
    relatedSolutions: [
      solutionIds.insuranceComplaintsResolution,
      solutionIds.medicareLocator,
    ],
    demoView: <InsuranceChurn />,
    searchTerms: ["Payers"],
  },
  {
    id: solutionIds.fraudClaimsDetection,
    title: "Healthcare Provider Fraud Detection",
    summary:
      "Solution to predict the potentially fraudulent providers based on the claims filed by them",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: fraudClaimsDetection,
    relatedSolutions: [solutionIds.insuranceChurn],
    demoView: <FraudClaimsDetection />,
    searchTerms: ["Provider Management"],
  },
  {
    id: solutionIds.medicalExpensePrediction,
    title: "Medical Expense Prediction",
    summary:
      "Tool to predict the approximate medical expense of an individual based on demographic and lifestyle factors.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: medicalExpensePrediction,
    relatedSolutions: [
      solutionIds.insuranceComplaintsResolution,
      solutionIds.medicareLocator,
      solutionIds.lengthStayHospital,
    ],
    demoView: <MedicalExpensePrediction />,
    searchTerms: ["Provider Management", "Payers"],
  },
  {
    id: solutionIds.retinaDamageClassification,
    title: "Retina Damage Classification",
    summary:
      "Application to identify and predict the type of damage caused to the human retina from Retinal OCT images. ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: retinaDamageClassification,
    relatedSolutions: [
      solutionIds.ultraSoundImageSegmentation,
      solutionIds.eyesStatePrediction,
    ],
    demoView: <RetinaDamageClassification />,
    searchTerms: ["Computer Vision", "Deep Learning", "Eye care"],
  },
  {
    id: solutionIds.populationHealthDashboard,
    title: "Population Health Dashboard",
    summary:
      "A collection of visualizations that give insights on the health factor of the total population of the United States ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: populationHealthDashboard,
    relatedSolutions: [],
    demoView: <PopulationHealthDashboard />,
    searchTerms: ["Insights", "Visualization"],
  },
  {
    id: solutionIds.rom,
    title: "Predicting ROM of Knee Post TKR Surgery",
    summary:
      "A tool that makes use of a video analytics algorithm to calculate the range of motion as a part of post-op care for total knee replacement surgery of an orthopedic patient. ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: rom,
    relatedSolutions: [solutionIds.incisioncare],
    demoView: <Rom />,
  },
  {
    id: solutionIds.arrhythmiaClassification,
    title: "Predicting Arrhythmia on ECG Classification",
    summary:
      "Extending the ML capabilities to predict results from graphical data as Graph Analytics is the new story telling in big Data.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: arrhythmiaClassification,
    relatedSolutions: [],
    demoView: <ArrhythmiaClassification />,
    searchTerms: ["Diagnosis", "Health assessment", "Provider Management"],
  },
  {
    id: solutionIds.diabeticRetinopathy,
    title: "Diabetic Retinopathy Detection",
    summary:
      "Diabetic Retinopathy is a complication of diabetes that affect the eyes. Tool to identify severity of the blindness in eyes because of diabetes",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: diabeticRetinopathy,
    relatedSolutions: [solutionIds.predictingType2Diabetes],
    demoView: <DiabeticRetinopathy />,
    searchTerms: ["Computer Vision", "Deep Learning", "Eye care"],
  },
  {
    id: solutionIds.imageColorization,
    title: "Image Colorization",
    summary:
      "Tool to colorize the grayscale image into colorized image that represent the semantic colors and tones of the input image.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: imageColorization,
    relatedSolutions: [
      solutionIds.portraitShadowRemoval,
      solutionIds.handGuesterRecognition,
    ],
    demoView: <ImageColorization />,
    searchTerms: ["Diagnosis", "Deep Learning"],
  },
  {
    id: solutionIds.skinCancerAnalysis,
    title: "Skin Cancer Analysis",
    summary:
      "Skin cancer is the most common human malignancy, is primarily diagnosed visually, beginning with an initial clinical screening and followed potentially by dermoscopic analysis, a biopsy and histopathological examination.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: skinCancerAnalysis,
    relatedSolutions: [solutionIds.melanomaClassification],
    demoView: <SkinCancerAnalysis />,
    searchTerms: ["Diagnosis", "Cancer detection", "Skin Care"],
  },
  {
    id: solutionIds.bloodCellInception,
    title: "BloodCell Inception",
    summary:
      "Application that detects and classifies blood cell subtypes from blood sample images. ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: bloodCellInception,
    relatedSolutions: [],
    demoView: <BloodCellInception />,
    searchTerms: ["Diagnosis", "Deep Learning", "Immune system"],
  },
  {
    id: solutionIds.drugMOAPrediction,
    title: "Drug Mechanisms of Action Prediction",
    summary:
      "Solution to predict Mechanism of Action of various drugs which help provide information about the safety of the drug and how they affect the body",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: drugMOAPrediction,
    relatedSolutions: [
      solutionIds.drugDiscovery,
      solutionIds.drugRecommendation,
      solutionIds.predictingADR,
    ],
    demoView: <DrugMOAPrediction />,
    searchTerms: ["Clinical Trial", "Drug Research"],
  },
  {
    id: solutionIds.suicideIdeation,
    title: "Suicide Ideation Prediction",
    summary:
      "Predicting Suicide Ideation of an individual based on basic lifestyle based survey questions.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: suicideIdeation,
    relatedSolutions: [
      solutionIds.mentalHealthPrevalence,
      solutionIds.mentalHealth,
    ],
    demoView: <SuicideIdeationPrediction />,
    searchTerms: ["Mental Health Analytics", "Market Research"],
  },
  {
    id: solutionIds.osteoarthritisSeverityPrediction,
    title: "Osteoarthritis Severity Prediction",
    summary:
      "A tool to predict the severity of knee osteoarthritis in X-ray images",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: osteoarthritisSeverityPrediction,
    relatedSolutions: [
      solutionIds.classfyingRheumotoidArthritisPatients,
      solutionIds.aclTear,
      solutionIds.chestXRaysCovid19,
    ],
    demoView: <OsteoarthritisSeverityPrediction />,
    searchTerms: ["Deep Learning", "Knee Care"],
  },
  {
    id: solutionIds.eEGClassification,
    title: "Sleep Stage Classification from EEG data",
    summary:
      "Using a recording of a EEG signal measuring the electrical activity of the brain to understand sleep stages of a patient and make a diagnosis about the quality of their sleep.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: eEGClassification,
    relatedSolutions: [
      solutionIds.eyesStatePrediction,
      solutionIds.faceEmotionRecognizer,
    ],
    demoView: <EEGClassification />,
    searchTerms: ["Diagnosis", "Brain Activity", "Lifestyle"],
  },
  {
    id: solutionIds.eEGOnSensoryTask,
    title: "Classification of EEG Trials on a Basic Sensory Task",
    summary:
      "A tool that correctly classifies EEG signals as self-generated (condition 1) or playback (condition 2) based on trials conducted as a part of a button press and auditory tone related event",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: eEGOnSensoryTask,
    relatedSolutions: [
      solutionIds.eyesStatePrediction,
      solutionIds.eEGClassification,
    ],
    demoView: <EEGOnSensoryTask />,
    searchTerms: ["Diagnosis", "Brain Activity", "Disease Prevention"],
  },
  {
    id: solutionIds.aclTear,
    title: "ACL Tear Prediction",
    summary:
      "Application to identify the location of Anterior Cruciate Ligament (ACL) in Knee MRI Images and predict if there is an ACL tear present or not in the MRI image",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: aclTear,
    relatedSolutions: [],
    demoView: <ACLTearPrediction />,
    searchTerms: ["Deep Learning", "Computer Vision", "Knee Care"],
  },
  {
    id: solutionIds.intracranialHemorrhage,
    title: "Intracranial Hemorrhage Detection",
    summary:
      "Computer Vision Algorithm to detect acute Intracranial Hemorrhage using Image analytics and Deep Learning Techniques",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: intracranialHemorrhage,
    relatedSolutions: [],
    demoView: <IntracranialHemorrhageDetection />,
    searchTerms: ["Deep Learning", "Computer Vision", "Brain Activity"],
  },
  {
    id: solutionIds.womensHealthWellBeing,
    title: "Women's Health and well Being",
    summary:
      "A set of visualizations to analyze the health and well-being of women exclusively residing in the United States.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: womensHealthWellBeing,
    relatedSolutions: [
      solutionIds.cervicalCancerScreening,
      solutionIds.breastCancerClassification,
    ],
    demoView: <WomensHealthWellBeing />,
    searchTerms: ["Women's Health", "Disease Prevention"],
  },
  {
    id: solutionIds.pneumoniaDiagnosis,
    title: "Pneumonia Diagnosis",
    summary:
      "A tool that uses a deep learning algorithm to identify the presence of pneumonia in chilren from x-ray images of the chest area. ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: pneumoniaDiagnosis,
    relatedSolutions: [],
    demoView: <PneumoniaDiagnosis />,
    searchTerms: ["Diagnosis", "Lungs health"],
  },
  {
    id: solutionIds.insuranceClaimPrediction,
    title: "Insurance Claims Amount Prediction",
    summary:
      "An easy-to-use interface for the user to predict estimated insurance claims amount based on insurance, medical condition, and personal details of the user. ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: insuranceClaimPrediction,
    relatedSolutions: [
      solutionIds.insuranceComplaintsResolution,
      solutionIds.lifeInsurance,
      solutionIds.insuranceChurn,
    ],
    demoView: <InsuranceClaimPrediction />,
    searchTerms: ["Payers", "Provider Management"],
  },
  {
    id: solutionIds.dementiaPrediction,
    title: "Dementia Prediction",
    summary:
      "Application to detect dementia in older adults and to determine the factors responsible for it.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: dementiaPrediction,
    relatedSolutions: [
      solutionIds.mentalHealth,
      solutionIds.mentalHealthPrevalence,
      solutionIds.behaviouralAnalysis,
    ],
    demoView: <DementiaPrediction />,
    searchTerms: ["Brain Activity", "Health Assessment"],
  },
  {
    id: solutionIds.breastCancerMammography,
    title: "UHG-Mammogram Classifier to Detect Cancer",
    summary:
      "A tool that classifies a mammography scan as Normal, or having Benign or Malignant Tumors using a CNN model trained on a specially curated dataset consisting of mammography images from the MIAS dataset and synthetic images generated using SinGAN",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: breastCancerMammography,
    relatedSolutions: [
      solutionIds.shapleyImages,
      solutionIds.cervicalCancerScreening,
      solutionIds.breastCancerClassification,
      solutionIds.womensHealthWellBeing,
    ],
    demoView: <BreastCancerMammography />,
    searchTerms: ["Cancer Detection", "Diagnosis"],
  },
  {
    id: solutionIds.pulmonaryEmbolism,
    title: "Pulmonary Embolism Prediction",
    summary:
      "A tool to predict the presence and characteristics of pulmonary embolisms in chest CTPA (CT Pulmonary Angiography) images",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: pulmonaryEmbolism,
    relatedSolutions: [],
    demoView: <PulmonaryEmbolismPrediction />,
  },
  {
    id: solutionIds.diagnosisPredictor,
    title: "Diagnosis Predictor",
    summary:
      "Tool that automatically predicts the area of medical specialty required for a diagnosis based on a patient’s anamnesis with the help of NLP and machine learning. ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: diagnosisPredictor,
    relatedSolutions: [
      solutionIds.bioLemmatizer,
      solutionIds.clinicalnotesPreprocessor,
    ],
    demoView: <DiagnosisPredictor />,
    searchTerms: ["Diagnosis", "Clinical notes"],
  },
  {
    id: solutionIds.healthInsuranceLeadPrediction,
    title: "Health Insurance Lead Prediction",
    summary:
      "An efficient model to predict the probability of converting a lead for Health Insurance provider.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: healthInsuranceLeadPrediction,
    relatedSolutions: [
      solutionIds.insuranceChurn,
      solutionIds.insuranceComplaintsResolution,
    ],
    demoView: <HealthInsuranceLeadPrediction />,
    searchTerms: [
      "insurance analysis",
      "ROI on insurance",
      "Insurance Interest rates",
    ],
  },
  {
    id: solutionIds.uhgBreastCancerPrediction,
    title: "UHG-Breast Cancer Prediction using GANs and Shapley",
    summary:
      "Application to predict Breast cancer from cell nucleoli data synthetically generated using CTGANs and model performance enhanced by Shapley.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: uhgBreastCancerPrediction,
    relatedSolutions: [
      solutionIds.breastCancerMammography,
      solutionIds.breastCancerClassification,
      solutionIds.breastCancerProteomes,
    ],
    demoView: <UHGBreastCancerPrediction />,
    searchTerms: [
      "Female Cancer",
      "Stanford",
      "Google Algorithms",
      "Image Analytics",
    ],
  },
  {
    id: solutionIds.heartAttackAnalysis,
    title: "Heart Attack Analysis and Prediction",
    summary:
      "A machine learning application to predict whether a patient is prone to heart attack or not based on the patient's medical records",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: heartAttackAnalysis,
    relatedSolutions: [
      solutionIds.predictingHeartDisease,
      solutionIds.strokePrediction,
    ],
    demoView: <HeartAttackAnalysis />,
    searchTerms: [
      "Stroke",
      "Heart Diseases Analysis",
      "Artificial Intelligence",
      "Heart Surgery",
    ],
  },
  {
    id: solutionIds.sdoh,
    title: "Chronic Condition Prediction using SDOH",
    summary:
      "A solution to predict whether a person with given social determinants is prone to any chronic condition or not",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: sdoh,
    relatedSolutions: [
      solutionIds.mentalHealthPrevalence,
      solutionIds.predictingType2Diabetes,
    ],
    demoView: <SDOH />,
    searchTerms: ["Kidney Diseases"],
  },
  {
    id: solutionIds.stressDetectionHRV,
    title: "Stress Detection using HRV",
    summary:
      "Application to predict the psychological & physiological stress due to interruptions or time pressure causing heart rate variability",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: stressDetectionHRV,
    relatedSolutions: [
      solutionIds.faceEmotionRecognizer,
      solutionIds.heartSoundsClassification,
    ],
    demoView: <StressDetectionUsingHRV />,
    searchTerms: ["Mental Health Analytics", "Modern ML"],
  },
  {
    id: solutionIds.footWoundImageSegmentation,
    title: "Foot Wound Image Segmentation",
    summary:
      "Application to segment the foot wound area from natural images photographed during clinical visits",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: footWoundImageSegmentation,
    relatedSolutions: [
      solutionIds.ultraSoundImageSegmentation,
      solutionIds.roboticSegmentation,
    ],
    demoView: <FootWoundImageSegmentation />,
    searchTerms: ["Image Analytics", "Deep Learning", "Wound Care"],
  },
  {
    id: solutionIds.surgicalSiteInfectionPrediction,
    title: "Surgical Site Infection Prediction",
    summary:
      "Application to predict the number of infections based on past Surgical Site Infections (SSIs) experiences and calculating the Standardised Infection Ratio.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: surgicalSiteInfectionPrediction,
    relatedSolutions: [
      solutionIds.roboticSegmentation,
      solutionIds.skinCancerAnalysis,
    ],
    demoView: <SurgicalSiteInfectionPrediction />,
    searchTerms: ["surgery", "operation", "SSI"],
  },
  {
    id: solutionIds.federatedLearningMedicalData,
    title: "Federated Learning on Medical Data",
    summary:
      "Solution to demonstrate Federated Learning which is an approach to collaboratively learn a shared prediction model while keeping all the training data locally.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: federatedLearningMedicalData,
    relatedSolutions: [
      solutionIds.dataInteroperablePlatform,
      solutionIds.diabeticRetinopathy,
    ],
    demoView: <FederatedLearningMedicalData />,
    searchTerms: ["decentralized", "collaborative", "decoupling"],
  },
  {
    id: solutionIds.americanSignLanguageDetection,
    title: "American Sign Language Detection",
    summary:
      "Tool to detect and recognize American Sign Language Alphabets and other basic symbols",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: americanSignLanguageDetection,
    relatedSolutions: [
      solutionIds.maskDetection,
      solutionIds.ocrHandwritten,
      solutionIds.handGuesterRecognition,
    ],
    demoView: <AmericanSignLanguageDetection />,
    searchTerms: ["dumb's language", "sign prediction", "ML on images"],
  },
  {
    id: solutionIds.polypSegmentation,
    title: "Polyp Segmentation in Colonoscopy",
    summary:
      "An AI driven application to detect Polyps from Endoscopic Colonoscopy images.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: polypSegmentation,
    relatedSolutions: [
      solutionIds.colonPathology,
      solutionIds.footWoundImageSegmentation,
      solutionIds.ultraSoundImageSegmentation,
    ],
    demoView: <PolypSegmentation />,
    searchTerms: ["Deep Learning", "Cancer", "Clasification"],
  },
  {
    id: solutionIds.bowelpreparation,
    title: "Predict Bowel Preparation Score for Colonoscopy",
    summary:
      "Application to evaluate the quality of bowel cleansing to achieve high quality colonoscopy examinations.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: bowelpreparation,
    relatedSolutions: [
      solutionIds.colonPathology,
      solutionIds.polypSegmentation,
    ],
    demoView: <BowelPreparation />,
    searchTerms: ["Cancer", "Video Analytics", "Deep Learning"],
  },
  {
    id: solutionIds.fetalHealthClassification,
    title: "Fetal Health Classification",
    summary:
      "Application to classify the health of a fetus as Normal, Suspect or Pathological based on the features extracted from Cardiotocogram (CTG)",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: fetalHealthClassification,
    relatedSolutions: [solutionIds.womensHealthWellBeing],
    demoView: <FetalHealthClassification />,
    searchTerms: ["pregnancy", "fetus analysis"],
  },
  {
    id: solutionIds.healthInsuranceCoverageRatePrediction,
    title: "Health Insurance Coverage Rate Prediction",
    summary:
      "Application to analyze the spread of health insurance coverage across the states of the US",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: healthInsuranceCoverageRatePrediction,
    relatedSolutions: [
      solutionIds.healthInsuranceLeadPrediction,
      solutionIds.insuranceChurn,
    ],
    demoView: <HealthInsuranceCoverageRatePrediction />,
  },
  {
    id: solutionIds.uHGStrokePrediction,
    title: "UHG-Stroke Prediction using CT-GAN and Data Shapley",
    summary:
      "Application to predict the occurrence of stroke in a patient by analyzing various health indicators and demographic factors.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: uHGStrokePrediction,
    relatedSolutions: [
      solutionIds.uhgBreastCancerPrediction,
      solutionIds.breastCancerMammography,
    ],
    demoView: <UHGStrokePrediction />,
  },
  {
    id: solutionIds.adverseFoodEvents,
    title: "Adverse Food Events",
    summary:
      "A machine learning application to predict whether the consumption of a food product is having a severe effect or not on the consumer’s health.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: adverseFoodEvents,
    relatedSolutions: [solutionIds.caloriePredictor, solutionIds.sideEffects],
    demoView: <AdverseFoodEvents />,
    searchTerms: ["side effects of food"],
  },
  {
    id: solutionIds.lengthStayHospital,
    title: "Length of Stay in Hospital",
    summary:
      "Length of Stay for each patient helps determine how the hospitals can use this information for optimal resource allocation and better functioning.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: lengthStayHospital,
    relatedSolutions: [
      solutionIds.medicareLocator,
      solutionIds.providerNetworkAnalysis,
    ],
    demoView: <LengthStayHospital />,
    searchTerms: ["Provider Management", "Market Research"],
  },
  {
    id: solutionIds.whoSuicideStatistics,
    title: "WHO Suicide Statistics",
    summary:
      "Visualization on WHO gathered Suicide data from 1979 to 2016 for 141 countries",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: whoSuicideStatistics,
    relatedSolutions: [
      solutionIds.mentalHealthPrevalence,
      solutionIds.mentalHealth,
    ],
    demoView: <WHOSuicideStatistics />,
    searchTerms: ["Mental Health Analytics", "Market Research"],
  },
  {
    id: solutionIds.legalDocParser,
    title: "Legal Document Parser",
    summary:
      "Tool using LexNLP library to identify and retrieve information from unstructured legal text documents, including contracts, plans, policies, procedures, and other material.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: legalDocParser,
    relatedSolutions: [solutionIds.lengthStayHospital],
    demoView: <LegalDocParser />,
    searchTerms: ["Text scraping"],
  },
  {
    id: solutionIds.ocrCertificate,
    title: "OCR- Text Extractor and Translator",
    summary:
      "Tool to perform OCR and Language Translation for any document uploaded as an image",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: ocrCertificate,
    relatedSolutions: [
      solutionIds.ocrHandwritten,
      solutionIds.ocrCheckValidation,
    ],
    demoView: <OcrCertificate />,
    searchTerms: [
      "NLP",
      "AWS Translate",
      "Language Models",
      "Character Recognition",
    ],
  },
  {
    id: solutionIds.providerNetworkAnalysis,
    title: "Provider Network Analysis",
    summary:
      "A tool to identify top providers available based on US states and Diagnosis using market scan data",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: providerNetworkAnalysis,
    relatedSolutions: [
      solutionIds.lengthStayHospital,
      solutionIds.medicareLocator,
    ],
    demoView: <ProviderNetworkAnalysis />,
  },
  {
    id: solutionIds.sequenceGenerator,
    title: "Sequence Generator",
    summary:
      "Random DNA Sequence Generator - generates a random sequence of the length specified",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: sequenceGenerator,
    relatedSolutions: [
      solutionIds.primerClassifier,
      solutionIds.geneSimilarity,
    ],
    demoView: <SequenceGenerator />,
  },
  {
    id: solutionIds.dopplegangerView,
    title: "Digital Twins",
    summary:
      "A Neural Net based AI tool to find patient similarities for a given EMR",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: dopplegangerView,
    relatedSolutions: [],
    demoView: <DopplegangerView />,
  },
  {
    id: solutionIds.hls,
    title: "Virtusa HLS Revenue Forecasting Platform",
    summary:
      "An application that predicts revenue for  Virtusa at SBU level and the split up of the predicted revenue at segment level.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: hls,
    relatedSolutions: [],
    demoView: <HLSDashboard />,
  },
  {
    id: solutionIds.covidDashboard,
    title: "Coronavirus in the United States: Cases by State",
    summary:
      "This dashboard represents the snapshot of disease spread in USA. The number of cases confirmed, recovered and deceased are shown with respect to each state.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: covidDashboards,
    relatedSolutions: [],
    demoView: <CovidDashboard />,
  },
  {
    id: solutionIds.chronicKidneyDisease,
    title: "Predicting Chronic Kidney Disease Within 3 Years of T2D",
    summary:
      "Kidney Disease is most prone in Type2 Diabetes and is chronic nature. This model predicts the probability of CKD in T2D patients",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: chronicKidneyDisease,
    relatedSolutions: [],
    demoView: <ChronicKidneyDisease />,
  },
  {
    id: solutionIds.imagedeIdentification,
    title: "Medical Image De-Identification",
    summary:
      "Application to De-identify medical images with the help of Amazon Comprehend Medical and Amazon Rekognition",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: imagedeIdentification,
    relatedSolutions: [solutionIds.imageColorization, solutionIds.esrGAN],
    demoView: <ImagedeIdentification />,
  },
  {
    id: solutionIds.patternAnalyzer,
    title: "Pattern Analyzer",
    summary:
      "Application to Identify, compare and analyze the commonalities and variations between 2 different datasets across different proof vectors",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: patternAnalyzer,
    relatedSolutions: [
      solutionIds.dopplegangerView,
      solutionIds.tabularDataSynthesizer,
    ],
    demoView: <PatternAnalyzer />,
    searchTerms: [""],
  },
  {
    id: solutionIds.sentimentAnalysis,
    title: "Good Clinical Practice Audit Report - Sentiment Analysis",
    summary:
      "Application to perform sentiment analysis on the Monitoring Visit Report of Good Clinical Practice Clinical Trial Audit .",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: sentimentAnalysis,
    relatedSolutions: [
      solutionIds.drugReviewAnalyzer,
      solutionIds.feasibilityAnalysis,
    ],
    demoView: <SentimentAnalysis />,
  },
  {
    id: solutionIds.echoNet,
    title: "EchoNet-Dynamic Video based AI",
    summary:
      "EchoNet-Dynamic is a video based deep learning algorithm to predict ejection fraction and assess the cardiac functionality.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: echoNet,
    relatedSolutions: [
      solutionIds.roboticSegmentation,
      solutionIds.ultraSoundImageSegmentation,
      solutionIds.surgerySkills,
    ],
    demoView: <EchoNet />,
  },
  {
    id: solutionIds.colbertAnalysis,
    title: "Conversational AI Platform using ColBERT",
    summary:
      "Application to provide relevant answers to user provided questions using colBERT",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: colbertAnalysis,
    relatedSolutions: [
      solutionIds.bioLemmatizer,
      solutionIds.sentimentAnalysis,
    ],
    demoView: <ColbertAnalysis />,
  },
  {
    id: solutionIds.anomalyDetector,
    title: "Anomaly Detector",
    summary:
      "Anomaly Detector application uses PYOD library which is the most comprehensive and scalable Python library for detecting outlying objects in multivariate data.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: anomaly,
    relatedSolutions: [
      solutionIds.stressDetectionHRV,
      solutionIds.anomalyDetector,
      solutionIds.uhgBreastCancerPrediction,
    ],
    demoView: <AnomalyDetector />,
    searchTerms: [
      "anomaly",
      "outlier",
      "abnormal",
      "pyod",
      "outlier detection",
    ],
  },
  {
    id: solutionIds.imageModel,
    title: "Differential Privacy - Image Model",
    summary:
      "A tool that guarantees privacy on image data, as well as the models trained on these images",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: imagemodal,
    relatedSolutions: [
      solutionIds.differentialPrivacy,
      solutionIds.federatedLearningMedicalData,
      solutionIds.shapleyImages,
    ],
    demoView: <ImageModel />,
    searchTerms: ["DP", "SAIL", "private", "private"],
  },
  {
    id: solutionIds.aqueousSolubility,
    title: "Predicting Aqueous Solubility of Chemical Compounds",
    summary:
      "The ability of a substance to dissolve in water. The aqueous solubility of a medication determines its ability to be compounded, administrated and absorbed.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: aqueous,
    relatedSolutions: [
      solutionIds.drugDiscovery,
      solutionIds.predictingADR,
      solutionIds.drugMOAPrediction,
    ],
    demoView: <AqueousSolubility />,
    searchTerms: [
      "anomaly",
      "outlier",
      "abnormal",
      "pyod",
      "outlier detection",
    ],
  },
  {
    id: solutionIds.periodontalChart,
    title: "Periodontal Chart Parameter Extraction ",
    summary:
      "Tool that automates the process of data extraction from periodontal charts (unstructured data) for faster claims processes. ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: PerioTileImage,
    relatedSolutions: [
      solutionIds.legalDocParser,
      solutionIds.ocrCheckValidation,
      solutionIds.ocrCertificate,
    ],
    descView: <PeriodontalChart />,
    newSoln: true,
    demoView: <PeriodontalChartDemo />,
    searchTerms: [
      "Tooth",
      "Tooth",
      "OCR",
      "Probing Depth",
      "dental",
      "Gum",
      "Periodontitis",
    ],
  },
  {
    id: solutionIds.differentialPrivacyText,
    title: "Differential Privacy on Language Models (Text Generation)",
    summary:
      "Differential privacy applied on Text data to ensure data privacy during text generation.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: differentialPrivacyText,
    relatedSolutions: [
      solutionIds.imageModel,
      solutionIds.federatedLearningMedicalData,
      solutionIds.differentialPrivacy,
    ],
    descView: <DifferentialPrivacyText />,
    newSoln: true,
    demoView: <DifferentialPrivacyTextDemo />,
    searchTerms: [
      "DP",
      "private",
      "protected",
      "privacy",
      "encryption",
      "noise",
      "inference attack",
      "sensitive data",
      "NLP",
      "canary",
    ],
  },
  {
    id: solutionIds.annotatedCSV,
    title: "Product Review Analysis",
    summary:
      "Product Review Analysis is a tool that helps in identifying the sentiment behind a product review, and classifies the reviews based on a higher-order issue category, as well as its respective sub-category.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: annotatedCSV,
    relatedSolutions: [solutionIds.drugReviewAnalyzer, solutionIds.behaviouralAnalysis, solutionIds.textEmotionClassification],
    descView: <AnnotatedCSV />,
    newSoln: true,
    demoView: <AnnotatedCSVDemo />,
    searchTerms: [],
  },
  {
    id: solutionIds.encryptedTextData,
    title: "Homomorphic Encryption on Medical Tabular Data",
    summary:
      "Homomorphic Encryption of data, ensuring privacy while at the same time, allowing computations to be performed on the encrypted data using TenSEAL",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: encryptedTextData,
    relatedSolutions: [
      solutionIds.differentialPrivacy,
      solutionIds.imageModel,
      solutionIds.federatedLearningMedicalData,
    ],
    demoView: <EncryptedTextData />,
    searchTerms: [
      "secret key",
      "public key",
      "private",
      "protected",
      "privacy",
      "encryption",
      "noise",
      "inference attack",
      "sensitive data",
      "ckks",
      "tenseal",
    ],
  },
  {
    id: solutionIds.dentalRadiographs,
    title: "Tooth Instance Segmentation on Panoramic Dental Radiographs",
    summary:
      "Automatic teeth segmentation in panoramic x-ray images is an important research subject of image analysis in dentistry. Automatic semantic segmentation in one-shot panoramic x-ray image is carried out by using deep learning method with U-Net Model and binary image analysis to provide diagnostic information for the management of dental disorders, diseases, and conditions.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: dentalRadiographs,
    relatedSolutions: [
      solutionIds.differentialPrivacy,
      solutionIds.imageModel,
      solutionIds.federatedLearningMedicalData,
    ],
    descView: <DentalRadiographs />,
    newSoln: true,
    demoView: <DentalRadiographsDemo />,
    searchTerms: [
      "secret key",
      "public key",
      "private",
      "protected",
      "privacy",
      "encryption",
      "noise",
      "inference attack",
      "sensitive data",
      "ckks",
      "tenseal",
    ],
  },
  {
    id: solutionIds.knowledgeGraph,
    title: "Generating Knowledge graph from Bio-medical Literatures",
    summary:
      "Retrieve Biomedical Literature and extract medical-domain data in order to represent the knowledge in structured graphs which can be used further to get high-level insights of the data.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: knowledgeGraph,
    relatedSolutions: [
      solutionIds.networkGraphAnalyzer,
      solutionIds.videoMetadataExtraction,
    ],
    demoView: <KnowledgeGraph />,
    searchTerms: [
      "Knowledge Graph",
      "pubmed",
      "UMLS",
      "KG",
      "Neptune",
      "SemRep",
      "entity extraction",
    ],
  },
  {
    id: solutionIds.ace,
    title: "Automatic Concept-based Explanation(ACE)",
    summary:
      "ACE is an interpretability tool which automatically extract visual concepts that are human-meaningful, coherent and important for the neural network’s predictions.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: ace,
    relatedSolutions: [
      solutionIds.esrGAN,
      solutionIds.portraitShadowRemoval,
      solutionIds.echoNet,
    ],
    demoView: <ACE />,
  },
  {
    id: solutionIds.distributionalShapley,
    title: "Distributional Shapley Framework for Data Valuation",
    summary:
      "Application to overcome the limitations of Data Shapley with faster execution using Distributional Shapley where the value of a point is defined in the context of an underlying data distribution",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: distributionalShapley,
    relatedSolutions: [solutionIds.shapleyEMR, solutionIds.shapleyImages],
    demoView: <DistributionalShapley />,
  },
  {
    id: solutionIds.genomicDataPreprocessor,
    title: "Genomic Data Preprocessor (ADAM)",
    summary: "Using Adam library to preprocess genomic data files",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: genomicDataPreprocessor,
    relatedSolutions: [],
    demoView: <GenomicDataPreprocessor />,
    searchTerms: [""],
  },
  {
    id: solutionIds.diseasePrognosis,
    title: "Disease Prognosis",
    summary:
      "Predict the future probable diagnosis with cutting edge Neural Nets working on EMR data",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: diseasePrognosis,
    externalUrl: [
      [
        "Read More",
        "https://apps.vlifevirtusa.com/vlife-components/DiseasePrediction/",
      ],
    ],
  },

  {
    id: solutionIds.covid19Simulation,
    title: "COVID-19 Simulation",
    summary:
      "A simulator to analyze and forecast the effect of various policy interventions based on historical data at country level. The model leverages statistical algorithms to estimate impact by combining probabilities associated with various scenarios.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: covid19Simulation,
    relatedSolutions: [
      solutionIds.shapleyImages,
      solutionIds.shapleyEMR,
      solutionIds.cpcaImages,
      solutionIds.cpca,
      solutionIds.multiAccuracy,
    ],
    demoView: <Covid19Simulation />,
    searchTerms: [""],
  },
  {
    id: solutionIds.neuronShapley,
    title: "Implementing Neuron Shapley: Discovering responsible neurons",
    summary:
      "Application on Neuron Shapley implementation to quantify the contribution of individual neurons in prediction and performance of deep learning model.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: neuronShapley,
    relatedSolutions: [solutionIds.shapleyImages, solutionIds.dataShapley],
    demoView: <NeuronShapley />,
    searchTerms: [""],
  },
  {
    id: solutionIds.clinicalEventsPatient,
    title: "Predicting Clinical Events for a Patient ",
    summary:
      "Application to predict diagnosis and medication categories of a patient for a subsequent visit based on the Encounter records (e.g. diagnosis codes, medication codes or procedure codes) using RNN",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: clinicalEventsPatient,
    relatedSolutions: [
      solutionIds.medicalAppointmentNoShow,
      solutionIds.timelinessOfCare,
      solutionIds.lengthStayHospital,
    ],
    demoView: <ClinicalEventsPatient />,
    searchTerms: [""],
  },
  {
    id: solutionIds.visionAidedGAN,
    title: "Vision Aided GAN",
    summary:
      "Application on Neuron Shapley implementation to quantify the contribution of individual neurons in prediction and performance of deep learning model.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: genomicDataPreprocessor,
    relatedSolutions: [],
    demoView: <VisionAidedGAN />,
    searchTerms: [""],
  },
  {
    id: solutionIds.textGeneration,
    title: "Med-info Guide",
    summary:
      "Application to generate text related to medical and healthcare from the GPT2 Model.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: textGeneration,
    relatedSolutions: [
      solutionIds.textSummarizer,
      solutionIds.bioLemmatizer,
      solutionIds.legalDocParser,
    ],
    demoView: <TextGeneration />,
    searchTerms: [
      "NLG",
      "NLP",
      "Transformers",
      "Hugging face",
      "Generation",
      "Analytics",
    ],
  },
  {
    id: solutionIds.zeroShotClassification,
    title: "Manage and Tag Clinical Notes",
    summary:
      "An application to associate an appropriate label with a piece of text. This association is irrespective of the text domain and the aspect.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: zeroshot,
    relatedSolutions: [
      solutionIds.textEmotionClassification,
      solutionIds.textGeneration,
      solutionIds.textSummarizer,
    ],
    demoView: <ZeroShotClassification />,
    searchTerms: [
      "Zero shot classification",
      "topic classification",
      "sentiment analysis",
      "Hugging face",
    ],
  },
  {
    id: solutionIds.ioTContinuous,
    title: "IoT Continuous Patient Monitoring Dashboard",
    summary:
      "In the healthcare IoT space, continuous patient monitoring is one of the key enablers of reducing the risk of readmissions, managing chronic diseases more effectively, and improving patient outcomes.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: IOTCont,
    relatedSolutions: [solutionIds.medicalDeviceSimulator, solutionIds.devRPM],
    demoView: <IoTContinuous />,
    searchTerms: ["IoT Continuous", "Patient Monitoring Dashboard"],
  },
  {
    id: solutionIds.providerDashboards,
    title: "Provider Dashboards",
    summary:
      "To aid organizations run smoothly and provide outstanding patient care, provider dashboard imparts key analysis to monitor healthcare KPIs in a dynamic and interactive way. ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: providerDash,
    relatedSolutions: [
      solutionIds.plmInsights,
      solutionIds.dataInteroperablePlatform,
    ],
    demoView: <ProviderDashboards />,
    searchTerms: ["Provider Dashboards"],
  },
  {
    id: solutionIds.multimodalSingleCellIntegration,
    title: "Multimodal Single Cell Integration",
    summary:
      "The main goal of this usecase is to predict how DNA, RNA, and protein measurements co-vary in single cells.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: multimodal,
    relatedSolutions: [
      solutionIds.predictBioMechanical,
      solutionIds.breastCancerProteomes,
      solutionIds.sequenceGenerator,
      solutionIds.geneticVariantClassification,
    ],
    demoView: <MultimodalSingleCellIntegration />,
    searchTerms: ["dna", "rna", "protein", "bioinformatics"],
  },
  //Interactive-tools
  {
    id: solutionIds.ganlab,
    title: "Play with GANs",
    summary:
      "GanLab is an interactive visualization tool for learning and experimenting with GANs, a popular complex deep learning model. ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: ganlabstatic,
    type: "solution",
    relatedSolutions: [
      solutionIds.cnnExplainer,
      solutionIds.exBERT,
      solutionIds.conVnet,
      solutionIds.turbofanTycoon,
    ],
    demoView: <Ganlab />,
    searchTerms: [""],
    hideAddtoToCart: true,
  },
  {
    id: solutionIds.bern,
    title: "Named Entity Recognition for Biomedical Text Mining",
    summary:
      "BERN is a Bio BERT-based multi-type NER (Named Entity Recognition) tool that also supports normalization of extracted entities",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: bernImg,
    relatedSolutions: [
      solutionIds.bioLemmatizer,
      solutionIds.textSummarizer,
      solutionIds.zeroShotClassification,
    ],
    demoView: <Bern />,
    searchTerms: [
      "nlp",
      "clinical notes",
      "journal",
      "research paper",
      "annotate",
      "tag",
    ],
  },
  {
    id: solutionIds.textgenerationNLP,
    title: "Report Generation using Large Language Models",
    summary:
      "An NLP-based application that generates synthetic text data constructed from the prompt provided.",
    image: nlpChatgpt,
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    relatedSolutions: [solutionIds.textSummarizer, solutionIds.textGeneration],
    descView: <TextGenerationNlp />,
    newSoln: true,
    demoView: <TextGenerationDemo />,
    searchTerms: [
      "NLP",
      "Text, Synthetic data",
      "Open AI",
      "Transformer",
      "Text Generation",
      "GPT",
      "ChatGPT",
    ],
  },
  {
    id: solutionIds.questionGeneration,
    title: "Question Generation from Clinical Audit Reports",
    summary:
      "Given some content, the goal of Question Generation (QG) is to automatically generate a set of questions that can be answered by that content. This content can be in the form of sentences, paragraphs, documents, databases or even images.",
    image: questionGeneration,
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    relatedSolutions: [
      solutionIds.knowledgeGraph,
      solutionIds.ocrCertificate,
      solutionIds.textGeneration,
    ],
    descView: <QuestionGeneration />,
    newSoln: true,
    demoView: <QuestionGenerationDemo />,
    searchTerms: [
      "Question Generation",
      "QG",
      "MCQ",
      "FAQ",
      "multiple choice question",
      "frequently asked questions",
      "NLP",
      "T5",
      "QGen",
    ],
  },

  {
    id: solutionIds.nameMatcher,
    title: "Name Matcher ",
    summary:
      "A neural network/machine learning algorithm-powered fuzzy name matching tool that uses a combination of string distance and phonetic algorithms to perform near-to-accurate name matching on a small, as well as a large scale. ",
    image: mlMatchingOriginal,
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    relatedSolutions: [solutionIds.knowledgeGraph, solutionIds.textGeneration],
    descView: <NameMatcher />,
    newSoln: true,
    demoView: <NameMatcherDemo />,
    searchTerms: [
      "Fuzzy Name Matching",
      "Statistical similarity method",
      "AI",
      "Name matching",
      " Phonetic matching",
      "String distance matching",
      "Patient Search",
    ],
  },
  {
    id: solutionIds.cnnExplainer,
    title: "CNN Explainer",
    summary:
      "CNN Explainer is an interactive visualization tool to help beginners learn Convolutional Neural Networks, a foundational deep learning model.",
    type: "solution",
    hideAddtoToCart: true,
    image: cnnExplainer,
    relatedSolutions: [
      solutionIds.activeLearner,
      solutionIds.anomagram,
      solutionIds.conVnet,
      solutionIds.ganlab,
    ],
    demoView: <CNNExplainer />,
    searchTerms: [""],
  },
  {
    id: solutionIds.conVnet,
    title: "ConvNet Playground ",
    summary:
      "Convnet Playground is a tool for the interactive exploration of Convolutional Neural Networks (Convnets or CNNs)and allows the user to perform semantic image search using CNNs",
    type: "solution",
    hideAddtoToCart: true,
    type: "solution",
    image: conVnet,
    relatedSolutions: [
      solutionIds.exBERT,
      solutionIds.anomagram,
      solutionIds.selfOrganizing,
      solutionIds.ganlab,
    ],
    demoView: <ConVnet />,
    searchTerms: [""],
  },
  {
    id: solutionIds.exBERT,
    title: "EXBERT: A Visual Analysis Tool ",
    summary:
      "ExBERT is an interactive visualization tool that can reveal an intelligible structure in the learned representations of Transformer models.",
    type: "solution",
    hideAddtoToCart: true,
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: exbert,
    relatedSolutions: [
      solutionIds.activeLearner,
      solutionIds.anomagram,
      solutionIds.conVnet,
      solutionIds.cnnExplainer,
    ],
    demoView: <EXBERT />,
    searchTerms: [""],
  },
  {
    id: solutionIds.activeLearner,
    title: "Active Learning",
    summary:
      "Application built to perform Learning with Limited Labeled data using Active Learning",
    hideAddtoToCart: true,
    type: "solution",
    image: activeLearner,
    relatedSolutions: [
      solutionIds.turbofanTycoon,
      solutionIds.selfOrganizing,
      solutionIds.exBERT,
      solutionIds.ganlab,
    ],
    demoView: <ActiveLearner />,
    searchTerms: [""],
    hideAddtoToCart: true,
  },
  {
    id: solutionIds.anomagram,
    title: "Anomaly Detection with Autoencoders",
    summary:
      "An interactive visualization tool for exploring how a deep learning model can be applied to the task of anomaly detection (on stationary data).",
    type: "solution",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: anomagram,
    relatedSolutions: [
      solutionIds.conVnet,
      solutionIds.turbofanTycoon,
      solutionIds.exBERT,
      solutionIds.selfOrganizing,
    ],
    demoView: <Anomagram />,
    searchTerms: [""],
    hideAddtoToCart: true,
  },
  {
    id: solutionIds.turbofanTycoon,
    title: "Federated Learning – Play & Learn",
    summary:
      "Application to show the benefits of using a federated predictive model on a realistic turbofan data.",
    hideAddtoToCart: true,
    type: "solution",
    image: turbofanTycoon,
    relatedSolutions: [
      solutionIds.activeLearner,
      solutionIds.ganlab,
      solutionIds.exBERT,
      solutionIds.selfOrganizing,
    ],
    demoView: <TurbofanTycoon />,
    searchTerms: [""],
    hideAddtoToCart: true,
  },
  {
    id: solutionIds.selfOrganizing,
    title: "Self Organizing Maps",
    summary:
      "Application to demonstrate the Traveling Salesman Problem using Self-Organizing Maps",
    hideAddtoToCart: true,
    type: "solution",
    image: selfOrganizing,
    relatedSolutions: [
      solutionIds.anomagram,
      solutionIds.cnnExplainer,
      solutionIds.conVnet,
      solutionIds.turbofanTycoon,
    ],
    demoView: <SelfOrganizing />,
    searchTerms: [""],
    hideAddtoToCart: true,
  },
  {
    id: solutionIds.textSummarization,
    title: "Luhn’s Text Summarization",
    summary:
      "An interactive demonstration of using the Luhn method for text summarization.",
    hideAddtoToCart: true,
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: textSummarization,
    relatedSolutions: [solutionIds.colbertAnalysis, solutionIds.textSummarizer],
    demoView: <TextSummarization />,
    searchTerms: [""],
    hideAddtoToCart: true,
  },

  {
    id: solutionIds.videoMetadataExtraction,
    title: "Video Metadata Extraction & Knowledge Graph",
    summary:
      "This solution demonstrates AWS AI Services like Amazon Rekognition, Amazon Transcribe and Amazon Comprehend can help to extract valuable metadata from video assets and store that information in Amazon Neptune Graph database for maximum query performance and flexibility.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: videoMetadata,
    relatedSolutions: [],
    demoView: <VideoMetadataExtraction />,
    relatedSolutions: [
      solutionIds.networkGraphAnalyzer,
      solutionIds.sinGan,
      solutionIds.rom,
    ],
    searchTerms: [
      "knowledge graph",
      "metadata",
      "video",
      "entity",
      "neptune",
      "comprehend",
    ],
  },
  {
    id: solutionIds.genomicMetadataIntegration,
    title: "Genomic Metadata Integration",
    summary:
      "Genomic Metadata Integration, a web application that learns to automatically extract structured metadata (in the form of key-value pairs) from the plain text descriptions of GEO experiments. ",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: genomicMetadata,
    relatedSolutions: [],
    demoView: <GenomicMetadataIntegration />,
    relatedSolutions: [
      solutionIds.networkGraphAnalyzer,
      solutionIds.sinGan,
      solutionIds.rom,
    ],
    searchTerms: [
      "knowledge graph",
      "metadata",
      "video",
      "entity",
      "neptune",
      "comprehend",
    ],
  },

  // synthetic data
  {
    id: solutionIds.ibmMarketscan,
    title: "IBM MarketScan",
    summary:
      "The IBM® MarketScan® Research Databases are a family of research data sets that integrate deidentified patient-level health data",
    image: ibmMarketscan,
    relatedSolutions: [],
    type: "solution",
    demoView: <IBMMarketscan />,
    searchTerms: [
      "Research Database",
      "Health Data",
      "Datasets",
      "Synthetic Data",
    ],
  },
  {
    id: solutionIds.sinGan,
    title: "sinGAN",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    summary:
      "SinGAN is a tool for generating Synthetic Images from a single image. It also makes use of Exif tool to extract metadata from the images",
    image: singan,
    relatedSolutions: [solutionIds.tabularDataSynthesizer, solutionIds.gan],
    demoView: <SinGAN />,
    searchTerms: ["Synthetic Data"],
  },
  {
    id: solutionIds.dcGAN,
    title: "DCGAN",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    summary:
      "DCGAN is a tool for generating Synthetic Images from multiple images. It also makes use of Exif tool to extract metadata from the images.",
    image: dcGan,
    relatedSolutions: [solutionIds.gan, solutionIds.tabularDataSynthesizer],
    demoView: <DcGan />,
    searchTerms: [
      "Deep Learning",
      "Google Algorithms",
      "Complex Neural Networks",
      "Synthetic Data",
    ],
  },
  {
    id: solutionIds.gan,
    title: "Generative Adversarial Networks/GAN",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    summary:
      "A GAN is a machine learning (ML) model in which two neural networks compete with each other to become more accurate in their predictions.",
    image: ganLab,
    relatedSolutions: [],
    demoView: <GanLab />,
    isCustomPage: true,
    searchTerms: ["Synthetic Data", "Ganlab"],
  },
  {
    id: solutionIds.northAmericaSimulated,
    title: "North American Simulated Population",
    type: "solution",
    summary:
      "Entire US population Simulated data, which is PHI masked and HIPAA compliant​​",
    image: northAmerica,
    relatedSolutions: [],
    demoView: <NorthAmericaSimulated />,
    searchTerms: [
      "Simulated Data",
      "North America",
      "PHI Masked",
      "HIPAA compliant",
      "Synthetic Data",
    ],
  },
  {
    id: solutionIds.mlopsDataiku,
    title: "MLOPs Dataiku",
    summary:
      "Implementing MLOps using third-party application Dataiku showcasing how to operationalize ML/DL Models training, optimization and productionizing techniques",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: mlopsDataiku,
    relatedSolutions: [
      solutionIds.portraitShadowRemoval,
      solutionIds.handGuesterRecognition,
      solutionIds.healthMonitor,
    ],
    demoView: <Dataiku />,
    searchTerms: ["MLOps", "Supply chain", "Decision Making", "Dataiku"],
  },
  {
    id: solutionIds.portraitShadowRemoval,
    title: "Portrait Shadow Removal",
    summary:
      "A computational approach to manipulate shadows and lighting on portraits, allowing poorly lit faces to be relit post-capture in a realistic and controllable way.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: portraitShadowRemoval,
    relatedSolutions: [
      solutionIds.handGuesterRecognition,
      solutionIds.healthMonitor,
      solutionIds.mlopsDataiku,
    ],
    demoView: <PortraitShadowRemoval />,
    searchTerms: ["ST-GAN", "Shadow Removal", "Shadowfree", "GANs"],
  },
  {
    id: solutionIds.handGuesterRecognition,
    title: "Hand Gesture Recognition",
    summary: "Tool to detect and recognize simple hand gesture",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: handGuesterRecognition,
    relatedSolutions: [
      solutionIds.portraitShadowRemoval,
      solutionIds.healthMonitor,
      solutionIds.mlopsDataiku,
    ],
    demoView: <HandGesture />,
    searchTerms: [
      "Gesture Recognition",
      "CV",
      "Computer Vision",
      "Sign Language",
    ],
  },
  {
    id: solutionIds.esrGAN,
    title: "Image Enhancement using ESRGAN",
    summary:
      "An application using ESR GANS to generate realistic textures during single image super-resolution. ESRGAN is the enhanced version of the SRGAN",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: esrGan,
    relatedSolutions: [
      solutionIds.imageColorization,
      solutionIds.portraitShadowRemoval,
      solutionIds.sinGan,
    ],
    demoView: <ImageEnhancementESRGAN />,
    searchTerms: ["image analytics", "GAN", "improve image quality"],
  },
  {
    id: solutionIds.araali,
    title: "vLife & Araali Networks Collaboration",
    summary:
      "POC to demonstrate Araali with a vLife application by securing the network connections between services deployed on Kubernetes.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: araaliNetworks,
    demoView: <AraaliNetworks />,
    searchTerms: ["SecOps", "Network Security", "Kubernetes"],
  },
  {
    id: solutionIds.iotReceipesView,
    title: "IOT Receipes",
    summary: "",
    image: araaliNetworks,
    demoView: <IOTRecipesView />,
    searchTerms: ["Rapid Prototyping", "Software recipes", "IOT Development"],
  },
  {
    id: solutionIds.intera,
    title: "IOT Receipes",
    summary: "",
    image: araaliNetworks,
    demoView: <IOTRecipesView />,
    searchTerms: ["Rapid Prototyping", "Software recipes", "IOT Development"],
  },
  // epidemiology
  {
    id: solutionIds.predictCasesCountryWise,
    title: "Predict Covid-19 cases Country wise",
    summary:
      "The Application is aimed at predicting the confirmed cases of COVID-19 of different countries using Sigmoid Curve.",
    image: covid19CountryCases,
    type: "solution",
    demoView: <PredictCovid19CasesCountryWise />,
    searchTerms: ["Covid-19", "Forecast", "Visualization", "Insights"],
  },
  {
    id: solutionIds.predictConfimedCases,
    title: "Predicting Confirmed Cases Worldwide",
    summary:
      "Application to predict Confirmed cases worldwide caused due to COVID19 using advanced forecasting model Prophet.",
    image: confirmedCases,
    type: "solution",
    demoView: <PredictConfirmedCases />,
    searchTerms: ["Covid-19", "Corona virus", "confirmed", "Visualisation"],
  },
  {
    id: solutionIds.PredictMortalityCases,
    title: "Predicting Mortality Cases Worldwide",
    summary:
      "Application to predict Mortality cases worldwide caused due to COVID19 using advanced forecasting model Prophet.",
    image: mortalityEffect,
    type: "solution",
    demoView: <PredictMortalityCasesWorldwide />,
    searchTerms: [
      "Covid-19",
      "Corona virus",
      "mortaility rate",
      "Visualisation",
    ],
  },
  {
    id: solutionIds.predictingParkinson,
    title: "Predicting Parkinson's Disease",
    summary:
      "Disease state analysis in Parkinson’s patient is an automated method to predict whether the patient has Parkinson’s disease or not based on patients voice features.",
    image: parkinsons,
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    demoView: <PredictParkinsonDisease />,
    searchTerms: ["Voice Prediction"],
  },
  {
    id: solutionIds.predictRecoveryCases,
    title: "Predicting Recovery Cases Worldwide",
    summary:
      "Application to predict Recovered cases worldwide caused due to COVID19 using advanced forecasting model Prophet.",
    image: recoveryCases,
    type: "solution",
    demoView: <PredictRecoveryCases />,
    searchTerms: ["Covid-19", "Corona virus", "recovered", "Visualisation"],
  },
  {
    id: solutionIds.cohortSense,
    title: "Cohort Sense",
    summary:
      "A tool to Define, Analyze and Model custom cohorts. Transform the way you define and consume data",
    image: recoveryCases,
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    isCustomPage: true,
    demoView: <CohortSense />,
    searchTerms: ["exploration", "Data Representation", "EDA"],
  },

  // external solutions
  {
    id: solutionIds.medicalDeviceSimulator,
    title: "Medical Device Simulator",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    summary:
      "Create hundreds of virtual medical devices and simulate any volume of data, without having to configure and manage any physical devices​",
    image: medicalDeviceSimulator,
    externalUrl: "https://iot-device-simulator.vlifevirtusa.com/",
    searchTerms: ["Simulator", "data generation", "medical device"],
  },
  {
    id: solutionIds.genRocket,
    title: "GenRocket",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    summary:
      "Test data is no longer a bottleneck for continuous testing when you can generate synthetic test data on-demand and in real-time. With GenRocket’s Test Data Generation platform, high quality test data that reflects even the most complex data models can be provisioned whenever its needed.",
    image: genRocket,
    externalUrl: "https://app.genrocket.com",
    searchTerms: [
      "Synthetic Data Generation",
      "Automation",
      "Testing",
      "Test Data Automation",
    ],
  },
  {
    id: solutionIds.synthea,
    title: "Synthea",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    summary:
      "SyntheaTM is a synthetic patient generator that models the medical history of synthetic patients. Our mission is to output high-quality synthetic, realistic but not real, patient data and associated health records covering every aspect of healthcare.",
    image: synthea,
    searchTerms: ["Synthetic Patient Record", "Healthcare", "Simulator"],
  },
  {
    id: solutionIds.cancerIncidentsUSCountry,
    title: "Cancer Incidents Study per US County",
    type: "external",
    summary:
      "Studying the cancer data available from NIH’s National Cancer Institutes State Cancer Profiles. Data is studied to analyse and profile as per US county(s). The graph depicts county wise average annual cancer cases in USA for the years of 2012-2016",
    image: canceruscountry,
    externalUrl:
      "https://public.tableau.com/profile/vlife.virtusa#!/vizhome/CancerAverageIncidents/Sheet1?publish=yes",
  },
  {
    id: solutionIds.cancerIncidentsUSState,
    title: "Cancer Incidents Study per US state and Race",
    type: "external",
    summary:
      "Analysing the Cancer incidents data in US varying on the basis of race or state. It will aid in finding correlations between poverty levels, insurance levels and cancer incidence levels.",
    image: cancerusstate,
    externalUrl:
      "https://www.kaggle.com/salomekariuki/cancer-incidence-in-the-us-by-state-and-race",
  },
  {
    id: solutionIds.nomograms,
    title: "Nomograms",
    type: "external",
    summary:
      "Nomograms is a predictive tool to help patients and physicians make important treatment decisions. This tools is based on information from hundreds or even thousands of people with cancer.",
    image: nomograms,
    externalUrl: "https://www.mskcc.org/nomograms",
  },
  {
    id: solutionIds.replicativeImmortility,
    title: "Replicative Immortility",
    type: "external",
    summary:
      "Cancerous cells are independent and do not need growth factors to multiply. This Article shares a detailed analysis of all such factors which promotes the immortality of cancer cells",
    image: Immortility,
    externalUrl:
      "http://www.jargonwall.com/cancer/hallmarks-cancer-1-self-sufficiency-growth-signals/",
  },
  {
    id: solutionIds.genomeInstability,
    title: "Genome Instability",
    type: "external",
    summary:
      "It is important to understand how normal cell division is controlled through the cell cycle. This article shared the details about the cell cycle, the stages in it and the role of antigrowth signals.",
    image: Instability,
    externalUrl:
      "http://www.jargonwall.com/cancer/hallmarks-cancer-2-insensitivity-antigrowth-signals/",
  },
  {
    id: solutionIds.evasionGrowthSuppressorSignals,
    title: "Evasion of Growth Suppressor Signals",
    type: "external",
    summary:
      "Apoptosis is the opposite of cell growth; it is cell death. This Article focuses on the details about the regulators and Effectors of signals affecting apoptosis and the whole genome process followed by cancer cells to multiply",
    image: SuppressorSignals,
    externalUrl:
      "http://www.jargonwall.com/cancer/hallmarks-cancer-3-evading-apoptosis/",
  },
  {
    id: solutionIds.resistanceCellDeath,
    title: "Resistance to Cell Death",
    type: "external",
    summary:
      "How does a cancer cell multiplies, is resistant to death and how do they maintain telomeres? This Article answers to all such questions",
    image: ResistanceCell,
    externalUrl:
      "http://www.jargonwall.com/cancer/hallmarks-cancer-4-limitless-replicative-potential/",
  },
  {
    id: solutionIds.sustainedProliferation,
    title: "Sustained Proliferation",
    type: "external",
    summary:
      "A growing tumour, an aggregation of cancer cells, requires access to oxygen, nutrients and waste disposal. How do cancer cells subvert the normal neighbouring cells into playing a key role in driving tumour development? Answer lies here",
    image: Sustained,
    externalUrl:
      "http://www.jargonwall.com/cancer/hallmarks-cancer-5-sustained-angiogenesis/",
  },
  {
    id: solutionIds.alteredMetabolism,
    title: "Altered Metabolism",
    type: "external",
    summary:
      "metabolic abnormality in cancer cells demonstrates an increased glycolysis even in the presence of oxygen. However, tumor-related metabolic abnormalities are not limited to altered balance between glucose fermentation and oxidative phosphorylation.",
    image: AlteredMetabolism,
    externalUrl:
      "http://www.jargonwall.com/cancer/hallmarks-cancer-6-tissue-invasion-metastasis/",
  },
  {
    id: solutionIds.avoidingImmuneDestruction,
    title: "Avoiding Immune Destruction",
    type: "external",
    summary:
      "Cancer cells mutate and face selective pressures as they change and adapt to their environment. The evolving cancer cell has to out-compete the normal cells surrounding it, evade attack by immune cells, corrupt and co-opt otherwise loyal surrounding cells and migrate to distant parts of the body.",
    image: Immune,
    externalUrl:
      "http://www.jargonwall.com/cancer/hallmarks-cancer-7-genome-instability-mutation/",
  },
  {
    id: solutionIds.tumorPromotingInflammation,
    title: "Tumor-Promoting Inflammation",
    type: "external",
    summary:
      "Cancer cells hijack inflammatory mechanisms to promote their own growth and survival. During a normal inflammatory response by the innate and adaptive immune system, immune cells carry out their designated task of engulfing and/or destroying foreign invaders.",
    image: TumorPromoting,
    externalUrl:
      "http://www.jargonwall.com/cancer/hallmarks-cancer-8-tumor-promoting-inflammation/",
  },
  {
    id: solutionIds.AngiogenesisBloodVessel,
    title: "Angiogenesis - New Blood Vessel formation",
    type: "external",
    summary:
      "Angiogenesis is the formation of new blood vessels. This process involves the migration, growth, and differentiation of endothelial cells, which line the inside wall of blood vessels. The process of angiogenesis is controlled by chemical signals in the body.",
    image: Angiogenesis,
    externalUrl:
      "http://www.jargonwall.com/cancer/hallmarks-cancer-9-reprogramming-energy-metabolism/",
  },
  {
    id: solutionIds.covid19onCancerPatients,
    title: "Effect of Covid-19 on Cancer Patients",
    type: "external",
    summary:
      "The use case is on the study and research of the Covid-19 effects on cancer patient treatments.",
    image: effectcovid19,
    externalUrl:
      "https://www.kaggle.com/roche-data-science-coalition/uncover/discussion/142244",
  },
  {
    id: solutionIds.colonCancerGeneAnalysis,
    title: "Colon Cancer Gene Analysis",
    type: "external",
    summary:
      "Gene expression data for colon cancer affected persons  is studied and analysed to identify patterns, gene sources and other relevant information for colon cancer research.",
    image: colonCancerGeneAnalysis,
    externalUrl:
      "https://www.kaggle.com/masudur/colon-cancer-gene-expression-data",
  },
  {
    id: solutionIds.globalDashboard,
    title: "Global Dashboard",
    type: "external",
    summary:
      "A Covid-19 dashboard of global statistics of cumulative confirmed cases, patients recovered and total deaths.",
    image: globalDashboard,
    externalUrl: "https://v1-apps.vlifevirtusa.com/covidDashboard/",
    searchTerms: ["Covid-19", "Visualization", "Insights"],
  },
  {
    id: solutionIds.hospitalRecommendation,
    title: "Hospital Recommendation",
    type: "external",
    summary:
      "Identify the closest matches in a given EMR. Trained on Neural network architecture, works at population level​​",
    image: hospitalRecommendation,
    externalUrl: "https://apps.vlifevirtusa.com:8504/",
    searchTerms: ["Provider Management", "Deep Learning"],
  },
  {
    id: solutionIds.proteinKinases,
    title: "Predict Inhibitors of Protein Kinases",
    type: "external",
    summary:
      "Small molecules play an non-trivial role in cancer chemotherapy. Small molecules' activity targeting protein kinase is predicted predicted from a database of thousands of molecules of non inhibitors.",
    image: predictinhibitors,
    externalUrl: "https://www.kaggle.com/xiaotawkaggle/inhibitors",
  },
  {
    id: solutionIds.breastAssessmentTool,
    title: "The Breast Cancer Risk Assessment Tool",
    type: "external",
    summary:
      "The Breast Cancer Risk Assessment Tool allows health professionals to estimate a woman's risk of developing invasive breast cancer over the next 5 years and up to age 90 (lifetime risk).",
    image: breastAssessmentTool,
    externalUrl: "http://www.cancer.gov/bcrisktool",
  },
  {
    id: solutionIds.covid19Genetics,
    title: "Covid-19 Genetics",
    type: "external",
    summary:
      "The use case is the study of Covid-19 virus genetics, origin, and evolution including tracking of whole genomes and tracking variations of the virus over the time.",
    image: covid19Genetics,
    externalUrl:
      "https://covid19.nih.gov/news-and-stories/decoding-genetics-behind-covid19-infection",
  },
  {
    id: solutionIds.sARSCoVAssociation,
    title: "SARS-CoV-2 Drug Disease Gene Association",
    type: "external",
    summary:
      "Research on drug, gene, and disease associations for SARS-CoV-2 is being done in order to find out existing drugs that have interaction potential with SARS-CoV-2 and diseases which  overlap with target genes that have interaction potential with SARS-CoV-2",
    image: sARSCoVAssociation,
    externalUrl:
      "https://www.kaggle.com/markweston/sarscov2-drug-disease-gene-association",
  },
  {
    id: solutionIds.metastatiClassification,
    title: "Metastatic Tissue Classification",
    type: "external",
    summary:
      "Color images extracted from histopathologic scans of lymph node sections are analyzed and classified whether metastatic(tumour) tissue is present or not.",
    image: metastatiClassification,
    externalUrl:
      "https://www.kaggle.com/andrewmvd/metastatic-tissue-classification-patchcamelyon",
  },
  {
    id: solutionIds.patientSimilarities,
    title: "Patient Similarities",
    type: "external",
    summary:
      "Identify the closest matches in a given EMR. Trained on Neural network architecture, works at population level",
    image: patientSimilarities,
    externalUrl:
      "https://apps.vlifevirtusa.com/vlife-components/PatientSimilarities/",
  },
  {
    id: solutionIds.malariaDetection,
    title: "Malaria Detection from Cell Images",
    type: "external",
    summary:
      "Malaria parasites can be identified by examining under the microscope a drop of the patient’s blood. ML is used to identify presence of malaria in the cell images taken from microscope.l",
    image: malariaDetection,
    externalUrl:
      "https://www.kaggle.com/ingbiodanielh/malaria-detection-with-fastai-v2/comments#465424",
  },
  {
    id: solutionIds.cervicalCancerClassification,
    title: "Cervical Cancer Risk Classification",
    type: "external",
    summary:
      "About 11,000 new cases of invasive cervical cancer are diagnosed each year in the U.S. However, the number of new cervical cancer cases has been declining steadily over the past decades.",
    image: cervicalCancerClassification,
    externalUrl:
      "https://www.kaggle.com/loveall/cervical-cancer-risk-classification",
  },

  // innovation as a service
  {
    id: solutionIds.patientStratification,
    title: "Patient Stratification",
    summary:
      "Patient Stratification is division of patient groups into subgroups ('strata' or 'blocks'), each representing a particular section of patient population.",
    type: "no-route",
    image: patientStratification,
    externalUrl: "#",
    searchTerms: [
      "Patient Engagement",
      "clinical trials",
      "Risk stratification",
    ],
  },
  {
    id: solutionIds.teleHealth,
    title: "Tele Health",
    summary:
      "TeleHealth is the remote delivery of health care, health education, and health information services over telecommunications infrastructure.",
    type: "no-route",
    image: teleHealth,
    externalUrl: "#",
    searchTerms: ["Patient Engagement", "clinical trials", "Telemedicine"],
  },
  {
    id: solutionIds.whizai,
    title: "whizAI",
    summary:
      "WhizAI combines NLP, Data, Visualization, and Insights AI into a single platform that has been trained in life sciences language, analytics, and business.",
    type: "no-route",
    image: whizAi,
    externalUrl: "#",
  },
  {
    id: solutionIds.dataBrics,
    title: "Databricks",
    summary:
      "Unified Data Analytics Platform - One cloud platform for massive scale data engineering and collaborative data science.",
    type: "no-route",
    image: dataBricks,
    externalUrl: "#",
    searchTerms: [
      "Machine Learning Lifecycle",
      "Data Storage",
      "STreaming Analytics",
    ],
  },
  {
    id: solutionIds.onyxHealth,
    title: "OnyxHealth",
    summary:
      "Onyx’s mission is to provide FHIR-based interoperability to health payers, clinicians, researchers, labs….anyone who has need of accessing and securely sharing health data.",
    type: "no-route",
    image: onyxHealth,
    externalUrl: "#",
    searchTerms: [],
  },
  {
    id: solutionIds.roboFlow,
    title: "RoboFlow",
    summary:
      "Democratize computer vision by streamlining the computer vision process from end to end. It allows to upload, annotate, organize, train, and deploy model with ease.",
    type: "no-route",
    image: roboFlow,
    externalUrl: "#",
    searchTerms: [],
  },
  {
    id: solutionIds.iQuartic,
    title: "iQuartic",
    summary:
      "iQuartic is a provider of integrated products and services that help clients capture, organize, and analyse clinical data to better understand their member populations and improve the quality of care provided while optimizing risk-adjusted revenue.",
    type: "no-route",
    image: iQuartic,
    externalUrl: "#",
    searchTerms: [],
  },
  {
    id: solutionIds.interSystems,
    title: "InterSystems",
    summary:
      "POC done to showcase capabilities using InterSystems services and solutions around data management and data interoperability for healthcare and life science industry",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: interSystems,
    externalUrl: [
      [
        "Try Demo",
        "https://apps.vlifevirtusa.com:52773/csp/healthshare/virtusa/_DeepSee.UserPortal.DashboardViewer.zen?DASHBOARD=HIMSS%20dashboard.dashboard&DASHBOARD=HIMSS%20dashboard.dashboard&DASHBOARD=HIMSS%20dashboard.dashboard&DASHBOARD=HIMSS%20dashboard.dashboard&DASHBOARD=HIMSS%20dashboard.dashboard&DASHBOARD=HIMSS%20dashboard.dashboard&DASHBOARD=HIMSS%20dashboard.dashboard&DASHBOARD=HIMSS%20dashboard.dashboard&DASHBOARD=HIMSS%20dashboard.dashboard&EMBED=1&IRIS",
      ],
    ],
    searchTerms: ["data management", "data interoperability", "HLS"],
  },
  {
    id: solutionIds.healthBot,
    title: "Virtusa Health Bot",
    summary:
      "Virtusa Health Bot has been designed to enhance the patients understanding of their health condition provide accessibility to healthcare providers",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: healthBot,
    externalUrl: [
      ["Try Demo", "https://healthbotcontainersamplebb8a.azurewebsites.net/"],
    ],
    searchTerms: ["Healthbot", "Azure healthbot", "Healthchart"],
  },
  {
    id: solutionIds.ePic,
    title: "ePIC",
    summary:
      "ePIC solution harnesses digital technologies such as DPA, AI, NLP, and data analytics to ensure broad access to valid patient consent records.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: ePic,
    externalUrl: "https://material.vlifevirtusa.com/Innovation/ePIC.pdf",
    searchTerms: [
      "Digital processing Automation",
      "patient consent",
      "data analytics",
    ],
  },
  {
    id: solutionIds.talendForecasting,
    title: "Talend (Drug Sales Forecasting)",
    summary:
      "Drug Sales Forecasting is a solution to forecast the future sales of organisation’s products using historical sales data, through application of machine learning.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: drugSalesForecasting,
    externalUrl: "",
    searchTerms: ["sales forecasting", "Marketing", "Drug Sales"],
  },
  {
    id: solutionIds.healthMonitor,
    title: "vLife Health Monitor",
    summary:
      "A dashboard showing the health status of all the running applications in vLife",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: healthMonitor,
    externalUrl: [["Try Demo", "http://health.vlifevirtusa.com/"]],
    searchTerms: ["Dashboard", "Vlife Apps", "Application Health"],
  },
  // {
  //   id: solutionIds.apiMonitor,
  //   title: "vHealth API Marketplace",
  //   summary:
  //     "Healthcare API Marketplace to build, onboard own, partner, third party APIs to promote innovation by data sharing and uplift member engagement",
  //   get description() {
  //     return desc.filter((item) => item.id === this.id)[0].description;
  //   },
  //   image: vHealth,
  //   externalUrl: [["Try Demo", "https://healthcare.oip.virtusa.dev/home"]],
  //   searchTerms: ["innovation", "Partner APIs", "Third Part APIs"],
  // },
  {
    id: solutionIds.populationHealthLake,
    title: "Population Health Application using HealthLake",
    summary:
      "Using HealthLake capabilities to sort unstructured data and understand it. This POC is developed using AWS services to create dashboards and  charts over MIMIC data",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: healthLake,
    externalUrl: [
      [
        "Dashboard",
        "https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/177ee1ce-2dc0-4e74-9948-ce57667f831b",
      ],
    ],
    searchTerms: ["Advanced Analytics", "ML", "NLP", "Medical History"],
  },
  {
    id: solutionIds.raindrop,
    title: "Raindrop",
    summary:
      "Raindrop is the AI/ML enabled enterprise spend management platform that effectively manages how and who sells into a company, while saving on commodity acquisition costs",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: raindrop,
    externalUrl: [["Explore More", "https://raindrop.com"]],
    searchTerms: [
      "Spend analytics",
      "Contract lifecyscle management",
      "supplier lifecycle management",
      "Spend planning",
    ],
  },
  {
    id: solutionIds.appealsGrievances,
    title: "Intelligent Digitization of Appeals & Grievances",
    summary:
      "Simplify and automate A&G process to achieve 30% operational cost reduction with higher efficiency by adopting our smart AI-enabled A&G platform",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: appealsGrievance,
    externalUrl: [
      [
        "Explore More",
        "https://www.virtusa.com/solutions/appeals-grievances-processing",
      ],
    ],
    searchTerms: ["Appeals & Grievances", "AI", "Cost reduction", "Automation"],
  },
  {
    id: solutionIds.careCohortMgmt,
    title: "Care Cohort Management Solution",
    summary:
      "Improve patient health outcomes and manage chronic health condition using the Care Cohort Management solution.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: careCohort,
    externalUrl: [
      [
        "Explore More",
        "https://www.virtusa.com/solutions/care-cohort-management-solution",
      ],
    ],
  },
  {
    id: solutionIds.pegaSmartClaims,
    title: "Pega Smart Claims Management",
    summary:
      "Simplify and automate claims processing to achieve performance improvement with Pega Smart Claims Management",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: pegaSmart,
    externalUrl: [
      [
        "Brochure",
        "https://material.vlifevirtusa.com/A&G/Brochure-PegaClaimsModernization.pdf",
      ],
    ],
    searchTerms: ["SCM", "Claim Management", "Cost reduction"],
  },
  {
    id: solutionIds.plmLifeCycle,
    title: "PLM Lifecycle",
    summary:
      "Product lifecycle management (PLM) refers to the handling of a good as it moves through the typical stages of its product life",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: plmLife,
    externalUrl: [
      [
        "Brochure",
        "https://material.vlifevirtusa.com/PLM/Virtusa_PLM_Solution_Overview.pdf",
      ],
    ],
    searchTerms: ["PLM"],
  },
  {
    id: solutionIds.plmInsights,
    title: "PLM Insights",
    summary:
      "Most organizations have some measure of product data required for regulatory submission within their PLM system.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: plmInsights,
    externalUrl: [
      [
        "Try Demo",
        "https://us-east-1.online.tableau.com/#/site/virtusaeim/views/ProviderPerformance_May20_Demo/MedRX?%3Aiid=1%3Aiid%3D1",
      ],
    ],
    searchTerms: ["PLM"],
  },
  // mareana
  {
    id: solutionIds.salesTraining,
    title: "Sales Training",
    summary: "",
    type: "external",
    image: salesTraining,
    searchTerms: ["mareana"],
    externalUrl:
      "https://material.vlifevirtusa.com/Mareana/Sales%20Training.pdf",
  },
  {
    id: solutionIds.mareanaFaq,
    title: "FAQs",
    summary: "",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: mareanaFaq,
    searchTerms: ["mareana"],
  },
  {
    id: solutionIds.onePager,
    title: "OnePager",
    summary: "",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: analyzerImg,
    searchTerms: ["mareana"],
  },

  // collabortors
  {
    id: solutionIds.devPrognosis,
    title: "Prognosis of Breast Cancer",
    summary:
      "Application of Machine Learning and Deep Learning for Prediction and Prognosis of Breast Cancer",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: virus,
    externalUrl: [["Website", "https://fightlikeagirl.in/"]],
  },
  {
    id: solutionIds.devInsClaimPrediction,
    title: "Insurance Claims Prediction",
    summary: "Insurance Claims Prediction using Machine learning",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: insurancePremium,
    externalUrl: [
      [
        "Download Brochure",
        "https://material.vlifevirtusa.com/collaborators/brochure-insurance-claims-prediction.pdf",
      ],
    ],
  },
  {
    id: solutionIds.devTumorMicroEnv,
    title: "Changes in Tumor Microenvironment",
    summary:
      "Inducing Tumor Suppressive microenvironments through Genome Edited CD47−/− Syngeneic Cell Vaccination",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: microEnvironment,
    externalUrl: [],
  },
  {
    id: solutionIds.devReadmission,
    title: "Hospital Readmission",
    summary:
      "Predicting the probability of a patient to be readmitted within 30 days of discharge.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: readmission,
    externalUrl: [
      [
        "Download Brochure",
        "https://material.vlifevirtusa.com/collaborators/Brochure(30DayReadmission).pdf",
      ],
    ],
    searchTerms: ["Provider Management", "Disease Prevention"],
  },
  {
    id: solutionIds.devGenomics,
    title: "Introduction to Genomics",
    summary:
      "A white paper introducing genomics as a data analytical subject along with its fundamentals and applications.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: genomics,
    externalUrl: [
      [
        "White Paper",
        "https://material.vlifevirtusa.com/collaborators/Introduction_Genomics.pdf",
      ],
    ],
  },
  {
    id: solutionIds.devAIMLBlog,
    title: "Blogs on AI/ML",
    summary:
      "Blogs on AI/ML co,vering various areas of research and industry implemented use cases.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: blogAIML,
    externalUrl: [["Blog Link", "https://shashwatwork.github.io/"]],
  },
  {
    id: solutionIds.devRPM,
    title: "Remote Patient Monitoring in AWS",
    summary:
      "Article explaining the architecture and technology implementing Remote Patient Monitoring using AWS.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: rpmAws,
    externalUrl:
      "https://medium.com/@dilipanimpdoc/remote-patient-monitoring-in-aws-patient-engagement-9ac16cb25fe7",
  },
  {
    id: solutionIds.devShapleyGanIntro,
    title: "Introduction to Data Shapley and GAN",
    summary:
      "Blogs explaining the concepts and implementations of Data Shapley- Qualitative Analytical Tool for ML/AI and GAN algorithm.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: dataShapley,
    externalUrl: [
      [
        "GAN",
        "https://medium.com/analytics-vidhya/gan-an-imaginative-tool-into-reality-d8fa46aa6bff?source=friends_link&sk=13a3de5bfd715fe474d1f1cdabdde790",
      ],
    ],
  },
  {
    id: solutionIds.devVideoMetadata,
    title: "Video Metadata Extraction",
    summary:
      "A graph database is a database that uses graph structures for semantic queries with nodes, edges, and properties to represent and store data.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: videoMetadataBlog,
    externalUrl: [
      ["Blog", "https://material.vlifevirtusa.com/video-metadata/Blog.pdf"],
    ],
  },
  // epidemiology
  {
    id: solutionIds.casesDayWise,
    title: "Confirmed Cases Day Wise in Different Countries",
    summary:
      "A smart visualization showing day-wise tracking of confirmed Covid-19 cases across the world in each country",
    image: casesCountryWise,
    type: "external",
    externalUrl: "https://public.flourish.studio/visualisation/1612095/",
    searchTerms: ["Covid-19", "Corona virus", "countrywise", "Visualisation"],
  },
  {
    id: solutionIds.recoveredCasesPerDay,
    title: "Recovered Cases per day of COVID19 across the World",
    summary:
      "A visualization showing recovered cases of Covid-19 spread across the world for each country over a period of time.",
    image: coronavirus,
    type: "external",
    externalUrl:
      "https://apps.vlifevirtusa.com/vLife3tools/COVID-19/recovered.html",
    searchTerms: ["Covid-19", "Corona virus", "recovered", "daywise"],
  },
  {
    id: solutionIds.googleBaseline,
    title: "Google’s Project baseline on vLife",
    summary:
      "Google's Baseline platform is being used to facilitate screening and testing people for COVID-19, established as part of California’s community-based testing program under the direction of the California Department of Public Health.",
    image: projectBaseline,
    type: "external",
    externalUrl: "https://www.projectbaseline.com/study/covid-19/",
    searchTerms: [
      "Covid-19",
      "Corona virus",
      "covid testing",
      "Advance testing",
    ],
  },
  {
    id: solutionIds.vaccinationProgress,
    title: "COVID-19 World Vaccination Progress",
    summary:
      "A set of visualizations developed on the data obtained from ‘Our World in Data’ GitHub repository for covid-19 to understand the pace and type of vaccination in different countries.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: covidVaccination,
    searchTerms: ["Covid-19", "Corona virus", "Vaccination", "Visualisation"],
    externalUrl: "https://ourworldindata.org/covid-vaccinations/",
  },
  // iot receipes
  {
    id: solutionIds.hardwareRecipes,
    title: "HARDWARE / FIRMWARE RECIPES",
    summary:
      "Accelerate your IoT development using reusable modules (we call them IoT recipes). These ready-to-use hardware/firmware recipes come with source code, user guide, and design and circuit diagrams that are easy to use and deploy.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: rpmAws,
  },
  {
    id: solutionIds.softwareRecipes,
    title: "SOFTWARE RECIPES",
    summary:
      "Accelerate your IoT development using reusable modules (we call them IoT recipes). These ready-to-use software recipes come with source code, user guides, and block diagrams that are easy to use and deploy",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: iotReciepe,
  },
  {
    id: solutionIds.IotTempaltes,
    title: "3D TEMPLATES",
    summary:
      "We are also very proficient with our partners and startup ecosystem in machine prototyping and can help our customers assess the commercial and time-to-market aspects of mass production.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: virtualRift,
  },

  {
    id: solutionIds.hwReceipes1,
    title: "Data Storage on the TI CC2541 in BLE Applications",
    summary:
      "Texas Instruments CC2541 is among the most popular BLE SoCs now and is widely used in application of various sizes. With its 8051 based MCU core the CC2541 is capable of lot more than being...",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: dataStorage,
    externalUrl: [
      [
        "Download Receipe",
        "https://iot-recipes.vlifevirtusa.com/sources/data-storage-on-the-ti-cc2541-in-ble-applications.zip",
      ],
    ],
  },
  {
    id: solutionIds.hwReceipes2,
    title: "Connecting TI CC3200 WiFi Module to Cloud Using MQTT",
    summary:
      "This recipe gives the procedure for using Texas Instruments CC3200 SoC for establishing TCP/IP connection over Wi-Fi and then using MQTT protocol over TCP/IP for receiving and…",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: connectingTi,
    externalUrl: [
      [
        "Download Receipe",
        "https://iot-recipes.vlifevirtusa.com/sources/connecting-ti-cc3200-wifi-module-to-cloud-using-mqtt.zip",
      ],
    ],
  },
  {
    id: solutionIds.hwReceipes3,
    title:
      "Optimizing PCB Design with Optimum Passive Components (Resistors, capacitors and indictors) Placement",
    summary:
      "Printed circuit board (PCB) design in a product can make or break the product. There are so many intricate details to designing a PCB that it requires good grasp of fundamental electronics…",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: optimizingPcb,
    externalUrl: [
      [
        "Download Receipe",
        "https://iot-recipes.vlifevirtusa.com/sources/optimizing-pcb-design-with-optimum-passive-components-placement.pdf",
      ],
    ],
  },
  {
    id: solutionIds.hwReceipes4,
    title: "Connect a Sens'it 2.0 device to the IoT Platform",
    summary:
      "This recipe is building on the content from another recipe: Connecting Sigfox backend to Watson IoT Platform – by building an IoT Gateway in Bluemix. – with a few updates…",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: iotasaService,
    externalUrl: [
      [
        "Download Receipe",
        "https://iot-recipes.vlifevirtusa.com/sources/connect-a-sens-it-2.0-device-to-the-iot-platform.pdf",
      ],
    ],
  },
  {
    id: solutionIds.hwReceipes5,
    title: "Connecting a Resin.io Device with IoT",
    summary:
      "This recipe shows how to manually or automatically provision onto Watson IoT a device running a resin.io, and enable data communication. You would need an account registered on…",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: iotasaService,
    externalUrl: [
      [
        "Download Receipe",
        "https://iot-recipes.vlifevirtusa.com/sources/connecting-a-resin.io-device-with-iot.pdf",
      ],
    ],
  },
  {
    id: solutionIds.swReceipes1,
    title: "Minimizing CPU usage in Data Logging Applications",
    summary:
      "With power budgets getting smaller and smaller there is a need to squeeze every bit of power from the already small power sources in battery powered applications. Among the various techniques...",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: dataLoggingApplications,
    externalUrl: [
      [
        "Download Receipe",
        "https://iot-recipes.vlifevirtusa.com/sources/minimizing-cpu-usage-in-data-logging-applications.zip",
      ],
    ],
  },
  {
    id: solutionIds.swReceipes2,
    title:
      "Continuous high performance DB2 Q Replication plus repeatable query results (stable targets)",
    summary:
      "This recipe explains an approach to combine continuous real time replication and stable targets – using IBM DB2 Q Replication as well as efficient ELT processing based on DB2 SQL….",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: continuousHighPerformance,
    externalUrl: [
      [
        "Download Receipe",
        "https://iot-recipes.vlifevirtusa.com/sources/continuous-high-performance-db2-q-replication-plus-repeatable-query-results.pdf",
      ],
    ],
  },
  {
    id: solutionIds.swReceipes3,
    title:
      "Handling Alerts and Device Actions with Edge Analytics in IoT Platform",
    summary:
      "This recipe is continuation of Edge Analytics Recipe. In this recipe, we are going to showcase...",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: handlingAlerts,
    externalUrl: [
      [
        "Download Receipe",
        "https://iot-recipes.vlifevirtusa.com/sources/handling-alerts-and-device-actions-with-edge-analytics-in-iot-platform.pdf",
      ],
    ],
  },
  {
    id: solutionIds.swReceipes4,
    title: "Use HTTP(S) to publish commands to devices in IoT Platform",
    summary:
      "The HTTP(S) messaging protocol can be used by devices and applications to interact with the IBM Watson IoT Platform. This messaging protocol can be used instead of the MQTT…",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: useHttps,
    externalUrl: [
      [
        "Download Receipe",
        "https://iot-recipes.vlifevirtusa.com/sources/use-http(s)-to-publish-commands-to-devices-in-iot-platform.pdf",
      ],
    ],
  },
  {
    id: solutionIds.swReceipes5,
    title: "Connecting Sigfox backend to IoT Platform",
    summary:
      "IoT devices can be connected to the Sigfox network and take benefit from long-range broadcast capabilities. This recipe describes how to create a connection between devices of a…",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: connectingSigfox,
    externalUrl: [
      [
        "Download Receipe",
        "https://iot-recipes.vlifevirtusa.com/sources/connecting-sigfox-backend-to-iot-platform.pdf",
      ],
    ],
  },
  {
    id: solutionIds.arvr1,
    title: "Ideas to Build A $1,000,000 App in Oculus VR",
    summary:
      "Learn why the Oculus Rift is touted to be the next big thing, and why you should start developing applications for the Rift now! If you’ve been looking for a viable business opportunity...",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: ideas,
    externalUrl: [
      [
        "Download Receipe",
        "https://iot-recipes.vlifevirtusa.com/sources/ideas-to-build-a-%241%2C000%2C000-app-in-oculus-vr.pdf",
      ],
    ],
  },
  {
    id: solutionIds.arvr2,
    title: "Virtual Reality Medical Simulation",
    summary:
      "This tutorial contains everything that you need to create a healthcare simulation using Blender, Unity and Oculus Rift.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: virtualRealityMedicalSimulation,
    externalUrl: [
      [
        "Download Receipe",
        "https://iot-recipes.vlifevirtusa.com/sources/virtual-reality-medical-simulation.pdf",
      ],
    ],
  },
  {
    id: solutionIds.arvr3,
    title: "Virtual Reality for the Web: Oculus Rift",
    summary:
      "Virtual Reality (VR) is a technology for experiencing three dimensional computer graphics, since September 2012 the Oculus Rift gave VR a new boost. Through open source development…",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: virtualRift,
    externalUrl: [
      [
        "Download Receipe",
        "https://iot-recipes.vlifevirtusa.com/sources/virtual-reality-for-the-web-oculus-rift.pdf",
      ],
    ],
  },
  {
    id: solutionIds.tempaltes3d1,
    title: "Han Solo Raspi BASE",
    summary:
      "Raspberry Pi is a single-board computer the size of a credit-card. It’s just a circuit board with soldered components & ports and doesn’t come in fancy case. Raspberry Pi is extensively..",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: raspPiBase,
    externalUrl: [
      [
        "Download Receipe",
        "https://iot-recipes.vlifevirtusa.com/sources/han-solo-raspi-base.zip",
      ],
    ],
  },
  {
    id: solutionIds.otherTuts1,
    title: "Using APIs: Guide for Non-Developers",
    summary:
      "Except for Alchemy API, all Watson APIs have a nice REST API, which means that simple HTTP requests that may be performed by any browser. In this recipe, we’re going to use Firefox and…",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: guideForNonDevelopers,
    externalUrl: [
      [
        "Download Receipe",
        "https://iot-recipes.vlifevirtusa.com/sources/using-apis-guide-for-non-developers.pdf",
      ],
    ],
  },
  {
    id: solutionIds.otherTuts2,
    title: "Video Conferencing and Web Conferencing Service by ZOOM.US",
    summary:
      "Zoom, the cloud meeting company, unifies mobile collaboration, cloud video conferencing and simple online meetings into one easy-to-use platform. Our solution offers the best video…",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: videoConferencing,
    externalUrl: [
      [
        "Download Receipe",
        "https://iot-recipes.vlifevirtusa.com/sources/video-conferencing-and-web-conferencing-service-by-zoom.us.zip",
      ],
    ],
  },
  // pending solutions
  {
    id: solutionIds.fasterAi,
    title: "Faster Ai",
    summary:
      "No Code AI platform to build Machine Learning model pipeline with just drag and drop",
    image: fasterAI,
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    isCustomPage: true,
    relatedSolutions: [],
    demoView: <FasterAI />,
    searchTerms: ["No code plateform"],
  },
  {
    id: solutionIds.medicareLocator,
    title: "Medicare Locator",
    summary:
      "Locate medicare services based on states, city and zip-codes along with the ratings and contact details.",
    image: medicareLocator,
    description:
      "Medicare Locator Application helps in identifying the health care providers who provide Medicare services. It will help the patient to locate the Providers in the nearby locality by filtering with state, city & zip codes.<br/><br/>This tool generates the list of all the listed Medicare Facilities with the corresponding ratings and Contact Details of the healthcare provider. The following providers can be searched by the tool - hospitals, nursing homes, home health services and hospice care.",
    relatedSolutions: [],
    demoView: <MedicareLocator />,
    searchTerms: ["Patient Journey", "Provider Management"],
  },
  {
    id: solutionIds.textEmotionClassification,
    title: "Patient Feedback Analysis",
    summary:
      "Patient Feedback Analysis is used to identify emotions behind a text, to get a clear understanding of person patient's emotional state.",
    image: textBasedEmotion,
    description:
      "Patient Feedback Analysis uses a deep-learning transformer to identify emotions from a text/sentence. It shows top 5 emotions associated with the text and also their scores. The tranformer has been trained on goEmotions dataset using XtremeDistil",
    relatedSolutions: [solutionIds.textSummarizer, solutionIds.textGeneration],
    demoView: <TextBasedEmotionClassification />,
    searchTerms: [
      "Transformers",
      "Hugging face",
      "Sentiments",
      "Emotions",
      "NLP",
      "Text",
      "Analytics",
    ],
  },
  {
    id: solutionIds.insuranceFraudDetection,
    title: "Insurance Claim Fraud Detection",
    summary:
      "Utilizing graph analytics and graph neural networks (GNNs), this solution enables the detection of medical insurance fraud claims by uncovering hidden connections and patterns indicative of fraudulent behaviour.",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: insuranceFraudDetection,
    relatedSolutions: [
      solutionIds.insuranceClaimPrediction, solutionIds.healthInsuranceLeadPrediction, solutionIds.healthInsuranceCoverageRatePrediction,
      solutionIds.devInsClaimPrediction,
    ],
    descView: <InsuranceFraudDetectionDesc />,
    newSoln: true,
    demoView: <InsuranceFraudDetectionDemo />,
    searchTerms: [
      "Graph",
      "GNN",
      "KG",
      "Claim",
      "Provider",
      "Physician",
      "Beneficiary",
      "Patterns",
      "Relationships",
    ],
  },
  {
    id: solutionIds.medCode,
    title: "Med Code",
    summary: 'Med-Code is envisioned as a solution to assist medical coders, by processing unstructured clinical data from multiple sources pertaining to the case/patient and automate the medical coding. Powered by AI/ML technologies, Med-Code enables computer assisted medical coding to improve first pass rate for hospital claims and reduce denial rates due to incorrect coding.',
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: medCode,
    relatedSolutions: [
      solutionIds.bioLemmatizer, solutionIds.bern, solutionIds.textGeneration,
    ],
    descView: <MedCodeDesc />,
    newSoln: true,
    demoView: <MedCodeDemo />,
    searchTerms: [
      "med code", "ICD-10 code prediction",
      "Med-Code",
      "Med Code",
      "Extract ICD 10 codes",
      "BERT for ICD-10 identification"
    ],

  },
  {
    id: solutionIds.contextualSearch,
    title: "Context Based Search ",
    summary:
      "Context-Based Search (CBS) delivers personalized and context-aware search results, surpassing keyword limitations through dynamic adaptation and collaboration with a Large Language Model (LLM).",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: contextualSearch,
    relatedSolutions: [solutionIds.textSummarizer, solutionIds.textgenerationNLP],
    descView: <ContextualSearchDesc />,
    newSoln: true,
    demoView: <ContextualSearchDemo />,
    searchTerms: [
      'contextual search'
    ],
  },
  {
    id: solutionIds.responsibleAIModelExplainability,
    title: "Responsible AI - Model Explainability",
    summary:
      "Model explainability refers to the process of understanding and interpreting how machine learning models make predictions or decisions. It involves techniques and methods that provide insights into the inner workings of a model, enabling users to understand the factors that influence its outputs",
    get description() {
      return desc.filter((item) => item.id === this.id)[0].description;
    },
    image: modelExplainabilityTileImage,
    relatedSolutions: [solutionIds.responsibleAI, solutionIds.differentialPrivacy, solutionIds.differentialPrivacyText, solutionIds.imageModel],
    descView: <ResponsibleAIDesc />,
    newSoln: true,
    demoView: <ResponsibleAIDemo />,
    searchTerms: ["responsible AI", "Model Explainability",],
  },
];
