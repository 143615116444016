import React, { memo, useState } from "react";
import styled from "styled-components";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Grid } from "@material-ui/core";
import Highcharts from "highcharts";

// local components

import Collapse from "../../common/Collapse";
import CommonService from "../../../utils/services/CommonService";
import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  ListItem,
  HighchartContainer,
} from "../../../styles/common";

// image components
import shapleyImages_syntheticwithin1month from "../../../assets/images/app/Synthetic-Within1month.webp";
import shapleyImages_syntheticpost1month from "../../../assets/images/app/Synthetic-Post1month.webp";
import shapleyImages_realwithin1month from "../../../assets/images/app/shaply3.webp";
import shapleyImages_realpost1month from "../../../assets/images/app/shaply2.webp";
import shapleyImages_sample1 from "../../../assets/images/app/cnn.webp";

const ShapleyImages = () => {
  const [fields, setField] = useState([]);
  const [dataRows, setDataRows] = useState([]);
  const [result, setSample] = useState(false);
  const [cnnModelData, setCnnModelData] = useState(false);
  const [cnnPrediction, setCnnPrediction] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [showSpinner1, setSpinner1] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "sailApps", "shapleyImages",
  );

  const formHighChart = () => {
    Highcharts.chart('container_1', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      title: {
        text: 'Distribution of Synthetic and Real Images',
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.0f}%</b>',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.0f} %',
          },
        },
      },
      series: [{
        name: 'Probability',
        colorByPoint: true,
        data: [{
          name: 'Real Images',
          y: 81,

        }, {
          name: 'Synthetic Images',
          y: 230,
          sliced: true,

        }],
      }],
    });
  };

  const formDistributionChart = () => {
    Highcharts.chart('container', {
      chart: {
        type: 'pie',
      },
      title: {
        text: 'Distribution of Shapley values',
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.0f}%</b>',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
            format: '<b>{point.name}</b>: {point.percentage:.0f} %',
          },
        },
      },
      legend: {
        enabled: true,
      },
      dataLabels: {
        connectorWidth: 1,
      },
      series: [{
        name: " ",
        innerSize: 150,
        size: 200,
        showInLegend: true,
        data: [
          {
            name: 'Real Images',
            y: 81,
            color: " #000000",
          },
          {
            name: 'Synthetic Images',
            y: 230,

            color: "#ff3300",
          },
        ],
      }, {
        name: " ",
        innerSize: 100,
        size: 150,
        showInLegend: true,
        data: [{
          name: 'Positive Real Images',
          y: 76,
          color: "#404040",
        },
        {
          name: 'Negative Real Images',
          y: 5,
          color: "#a6a6a6",
        },
        {
          name: 'Positive Synthetic Images',
          y: 220,
          color: "#ff5c33",
        },
        {
          name: 'Negative Synthetic Images',
          y: 10,
          color: " #ff8566",
        }],

      }],
    });
  };

  const getSample = () => {
    setSpinner(true);
    setCnnModelData('');
    setSample('');

    getQueryResult("sampleData")
      .then((res) => {
        setSpinner(false);
        setSample(true);
        const previewData = JSON.parse(res.Data);
        const dataRows = previewData.data;
        const { fields } = previewData.schema;
        setField(fields);
        setDataRows(dataRows);
        formHighChart();
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
        setNotification({ open: "error", message: err.message });
      });
  };

  const getCnnModelData = () => {
    setCnnModelData(true);
    setCnnPrediction('');
  };

  const getCnnPrediction = () => {
    setCnnPrediction('');
    setSpinner1(true);

    getQueryResult("shapleyModel")
      .then((res) => {
        setSpinner1(false);
        setCnnPrediction(res.CNNModelShapley);
        formDistributionChart();
      })
      .catch((err) => {
        console.log(err);
        setSpinner(false);
        setNotification({ open: "error", message: err.message });
      });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            As data becomes the fuel driving technological and economic growth, a fundamental challenge
            is how to quantify the value of data in algorithmic predictions and decisions
          </p>
          <p>
            In this work, a principled framework to address data valuation in the context of supervised
            machine learning is showcased. Monte Carlo and gradient-based methods are implemented to
            efficiently estimate data Shapley values
          </p>
          <strong>Benefits Include:</strong>
          <ListItem>
            It is more powerful than the popular leave-one-out or leverage score algorithms
          </ListItem>
          <ListItem>
            Low Shapley value data effectively capture outliers and corruptions
          </ListItem>
          <ListItem>
            High Shapley value data inform what type of new data to acquire to improve the predictor
          </ListItem>
          <p>
            {' '}
            <strong>Scenario:</strong>
            A base CNN classifier is trained to predict the rate of recovery of a knee incision image.
            The classifier predicts if an image is within 1 month of surgery or not.
          </p>
          <p>
            {' '}
            <strong>Dataset:</strong>
            The dataset is a mix of real and synthetic images, which are exploded using SINGAN.
          </p>
          <p>
            {' '}
            <strong>Challenge:</strong>
            Demo the power of Shapley to eliminate noise from the dataset and improve model accuracy and model capacity.
          </p>
          <p>A deep dive analysis has been provided below.</p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/SAIL/Multiaccuracy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/amiratag/DataShapley"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://arxiv.org/abs/1904.02868"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Stanford AI : Citations
                </a>
              </StyledButton>

            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>

          <Grid item xs={12}>

            <StyledButton
              variant="contained"
              color="primary"
              onClick={getSample}
            >
              Sample Data
            </StyledButton>
            <br />
            <br />
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
            <section>
              <Grid container xs={12} spacing={2} direction="row" justify="center" alignItems="center">
                <Grid container spacing={2} xs={12} sm={12} md={6} direction="row" justify="center" alignItems="center">
                  <Grid xs={12}>
                    <h4>Synthetic Samples</h4>
                  </Grid>
                  <Grid xs={10} sm={5} md={6}>
                    <p>Before a Month Surgery</p>
                    <Image src={shapleyImages_syntheticwithin1month} />
                  </Grid>
                  <Grid xs={10} sm={5} md={6}>
                    <p>After a Month Surgery</p>
                    <Image src={shapleyImages_syntheticpost1month} />
                  </Grid>
                </Grid>
                <Grid container spacing={2} xs={12} sm={12} md={6} direction="row" justify="center" alignItems="center">
                  <Grid xs={12}>
                    <h4>Real Samples</h4>
                  </Grid>
                  <Grid xs={10} sm={5} md={6}>
                    <p>Before a Month Surgery</p>
                    <Image src={shapleyImages_realwithin1month} />
                  </Grid>
                  <Grid xs={10} sm={5} md={6}>
                    <p>After a Month Surgery</p>
                    <Image src={shapleyImages_realpost1month} />
                  </Grid>
                </Grid>
              </Grid>
              <Paper>
                <Table>
                  <TableHead>
                    <TableRow>
                      {fields.map((field) => (
                        <TableCell key={field.name} align="left"><strong>{field.name}</strong></TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dataRows.map((row) => (
                      <TableRow key={row.index}>
                        {fields.map((field) => (
                          <TableCell align="left">
                            {field.name === "index"
                              ? row[field.name] + 1
                              : row[field.name]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
              <br />
              <br />
              <HighchartContainer
                id="container_1"
                display={fields}
              />
              <br />
              <br />
              <StyledButton
                variant="contained"
                color="primary"
                onClick={getCnnModelData}
              >
                Base CNN Model
              </StyledButton>

              {cnnModelData
                && (
                <section>
                  <Grid container xs={12} spacing={4} direction="row" justify="center" alignItems="center">
                    <Grid item xs={12} sm={8} md={5}>
                      <strong><p>Metrics Table</p></strong>
                      <TableDiv>
                        <TableHead>
                          <TableRow>
                            <StyledTableHead align="left">Metric</StyledTableHead>
                            <StyledTableHead align="left">Value</StyledTableHead>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow key="0">
                            <TableCell align="left">Accuracy</TableCell>
                            <TableCell align="left">73</TableCell>
                          </TableRow>
                          <TableRow key="1">
                            <TableCell align="left">AUC</TableCell>
                            <TableCell align="left">77</TableCell>
                          </TableRow>
                          <TableRow key="2">
                            <TableCell align="left">Precision</TableCell>
                            <TableCell align="left">61</TableCell>
                          </TableRow>
                          <TableRow key="3">
                            <TableCell align="left">Recall</TableCell>
                            <TableCell align="left">100</TableCell>
                          </TableRow>
                        </TableBody>
                      </TableDiv>
                    </Grid>
                    <Grid item xs={12} sm={8} md={5}>
                      <strong>CNN Model Architecture</strong>
                      <img src={shapleyImages_sample1} width="100%" height="280px" />
                    </Grid>
                  </Grid>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={getCnnPrediction}
                  >
                    Retrain with shapley
                  </StyledButton>
                  {showSpinner1 && <Spinner text="Loading..." />}
                  {cnnPrediction
                    && (
                    <section>
                      <Grid container xs={12} spacing={4} direction="row" justify="center">
                        <Grid item xs={12} sm={8} md={5}>
                          <strong><p>Metrics Table</p></strong>
                          <TableDiv>
                            <TableHead>
                              <TableRow>
                                <StyledTableHead align="left">Metric</StyledTableHead>
                                <StyledTableHead align="left">Value</StyledTableHead>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow key="0">
                                <TableCell align="left">Accuracy</TableCell>
                                <TableCell align="left">{cnnPrediction.Accuracy}</TableCell>
                              </TableRow>
                              <TableRow key="1">
                                <TableCell align="left">AUC</TableCell>
                                <TableCell align="left">{cnnPrediction.ROC_AUC_Score}</TableCell>
                              </TableRow>
                              <TableRow key="2">
                                <TableCell align="left">Precision</TableCell>
                                <TableCell align="left">{cnnPrediction.Precision}</TableCell>
                              </TableRow>
                              <TableRow key="3">
                                <TableCell align="left">Recall</TableCell>
                                <TableCell align="left">{cnnPrediction.Recall}</TableCell>
                              </TableRow>
                            </TableBody>
                          </TableDiv>
                        </Grid>
                        <Grid item xs={12} sm={12} md={5}>
                          <strong><p>Score Distribution</p></strong>
                          <Paper>
                            <TableDiv>
                              <TableHead>
                                <TableRow>
                                  <StyledTableHead>Data Shapley Prediction</StyledTableHead>
                                  <StyledTableHead>Positive(%)</StyledTableHead>
                                  <StyledTableHead>Negative(%)</StyledTableHead>
                                  <StyledTableHead>Total</StyledTableHead>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow key="0">
                                  <TableCell align="left">Synthetic Samples</TableCell>
                                  <TableCell align="left">71</TableCell>
                                  <TableCell align="left">3</TableCell>
                                  <TableCell align="left">74</TableCell>
                                </TableRow>
                                <TableRow key="1">
                                  <TableCell align="left">Real Samples</TableCell>
                                  <TableCell align="left">24</TableCell>
                                  <TableCell align="left">2</TableCell>
                                  <TableCell align="left">26</TableCell>
                                </TableRow>
                                <TableRow key="2">
                                  <TableCell align="left">Total</TableCell>
                                  <TableCell align="left">95</TableCell>
                                  <TableCell align="left">5</TableCell>
                                  <TableCell align="left">100</TableCell>
                                </TableRow>
                              </TableBody>
                            </TableDiv>
                          </Paper>
                        </Grid>
                      </Grid>
                      <div id="container" />
                    </section>
                    )}
                </section>
                )}
            </section>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};
export default memo(ShapleyImages);

const Paper = styled.div`
  overflow: auto;
  margin-top: 5%;
`;
const Image = styled.img`
  border-radius: 3px;
  height: 150px;
  width: 150px;
  margin-bottom: 3%;
  @media only screen and (max-width: 900px) {
    width: auto;
    height: 150px;
    max-width: 150px;
  }
  @media only screen and (max-width: 450px) {
    width: auto;
    height: 180px;
    max-width: 180px;
  }
`;
const StyledTableHead = styled(TableCell)`
  background-color: #3c40af;
  color: white;
  font-weight: bold;
`;
const TableDiv = styled(Table)`
  @media only screen and (max-width: 900px) {
    margin-left: 3%;
  }
  @media only screen and (max-width: 450px) {
    margin-left: 5%;
  }
`;
