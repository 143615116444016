import React, { memo, useState } from "react";
import styled from "styled-components";
import { Grid, Paper } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components

import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  SolutionContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";

// images
import echoNet_sol1 from "../../../assets/images/app/echonet.webp";

const EchoNet = () => {
  const [result, setResult] = useState(false);

  const showVideo = () => {
    setResult({
      text: "Predicted Ejection Fraction value in video is greater than 55%, and it can be inferred that the heart is healthy ",
      video:
                "https://material.vlifevirtusa.com/EchoNet-Dynamic/output.mp4",
    });
  };

  return (
    <SolutionContainer>
      <Collapse text="Description">
        <Paragraph>
          <p>
            EchoNet-Dynamic is a video-based deep learning algorithm that achieves state-of-the-art assessment of cardiac function. Cardiac function is essential for the maintenance of normal systemic tissue perfusion; cardiac dysfunction manifests as dyspnea, fatigue, exercise intolerance, fluid retention and increased risk of mortality. Impairment of cardiac function is described as cardiomyopathy or heart failure and is a leading cause of hospitalization in the United States and a growing global health issue.
          </p>
          <strong> EchoNet-Dynamic is an end-to-end beat-to-beat deep learning model for: </strong>
          <ul>
            <li>
              Semantic segmentation of the left ventricle
            </li>
            <li>
              Prediction of ejection fraction by entire video
            </li>
            <li>
              Assessment of cardiomyopathy with reduced ejection fraction.
            </li>
          </ul>
          <strong> 1. Semantic segmentation of the left ventricle: -  </strong>
          <p>Semantic segmentation process has been used to trace the boundary of left ventricle to get segmented area of left ventricle. Internally the model has trained to estimate volume of left ventricle using that segmented area. </p>
          <p>Model design and training has been done in Python using the PyTorch deep learning library. Semantic segmentation was performed using the Deeplabv3 architecture. The segmentation model had a base architecture of a 50-layer residual network and minimized pixel-level binary cross entropy loss. The model has initialized random weights and has trained using a stochastic gradient descent optimize. </p>
          <center><img width="70%" src={echoNet_sol1} /></center>
          <strong> 2. Prediction of ejection fraction each frame of video: -  </strong>
          <p>Ejection fraction is percentage change in volume of left ventricle during two stages that is end-systolic and end-diastolic or we can say that ejection fraction is volume of blood is pumped by left ventricle. </p>
          <p><i>&nbsp;&nbsp;&nbsp;&nbsp;EF=(EDV-ESV)/EDV </i></p>
          <p><i>&nbsp;&nbsp;&nbsp;&nbsp;Where, EF: Ejection Fraction, EDV: End-Diastolic volume, ESV: End-Systolic Volume </i></p>
          <p>Prediction of ejection fraction has done using r2plus1d_18 architecture. For predicting ejection fraction, the models have trained to minimize the squared loss between the prediction and true ejection fraction using a stochastic gradient descent optimizer with an initial learning rate of 0.0001, momentum of 0.9 and batch size of 16 for 45 epochs. </p>
          <strong>3. Assessment of cardiomyopathy with reduced ejection fraction :- </strong>
          <p>As ejection fraction has a range of value so assessment of cardiomyopathy can be considered as range. </p>
          <Grid container xs={12} direction="row" justify="center" align="center">
            <Grid item xs={12} sm={10} md={6}>
              <Paper>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableHead align="left">Ejection Fraction (EF) % </StyledTableHead>
                      <StyledTableHead align="left">Pumping Ability of the Heart </StyledTableHead>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key="0">
                      <TableCell align="left">55% to 70% </TableCell>
                      <TableCell align="left">Normal</TableCell>
                    </TableRow>
                    <TableRow key="1">
                      <TableCell align="left">40% to 54% </TableCell>
                      <TableCell align="left">Slightly below normal </TableCell>
                    </TableRow>
                    <TableRow key="2">
                      <TableCell align="left">35% to 39% </TableCell>
                      <TableCell align="left">Moderately below normal </TableCell>
                    </TableRow>
                    <TableRow key="3">
                      <TableCell align="left">Less than 35% </TableCell>
                      <TableCell align="left">Severely below normal </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>
          <strong>Dataset: - </strong>
          <p>
            A standard full resting echocardiogram study consists of a series of 50–100 videos and still images visualizing the heart from different angles, locations and image acquisition techniques (two dimensional images, tissue Doppler images, color Doppler images and others). Each echocardiogram video corresponds to a unique patient and a unique visit. In this dataset, one apical four-chamber two-dimensional greyscale video is extracted from each study. Each video represents a unique individual as the dataset contains 10,030 echocardiography videos from 10,030 unique individuals
            who underwent echocardiography between 2016 and 2018 as part
            of clinical care at Stanford Health Care. Videos were randomly split
            into 7,465, 1,277 and 1,288 patients, respectively, for the training,
            validation and test sets.
            {' '}
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/EchoNet-Dynamic/EchoNet-Dynamic.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://echonet.github.io/dynamic/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Citations
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid
            container
            xs={12}
            direction="row"
            justify="space-around"
            alignItems="center"
            alignContent="center"
          >
            <Grid xs={12} sm={6} md={3}>
              <StyledFigure>
                <video height="300px" width="400px" controls>
                  <source
                    src="https://material.vlifevirtusa.com/EchoNet-Dynamic/input.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={showVideo}
                >
                  Predict EF rate
                </StyledButton>
              </StyledFigure>
            </Grid>
          </Grid>
          {result && (
          <section>
            <Result>
              <strong>Result:</strong>
              <Paragraph>{result.text}</Paragraph>
            </Result>
            <br />
            {result.video && (
            <Grid item xs={12}>
              <Video height="350px" width="350px" controls>
                <source src={result.video} type="video/mp4" />
                Your browser does not support the video tag.
              </Video>
            </Grid>
            )}
          </section>
          )}
          <br />
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};
export default memo(EchoNet);
const StyledFigure = styled.figure`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const Image = styled.img`
  border-radius: 3px;
  height: 200px;
  width: 200px;
  @media only screen and (max-width: 900px) {
    width: auto;
    max-width: 200px;
    min-width: 200px;
  }
  @media only screen and (max-width: 450px) {
    width: auto;
    max-width: 200px;
    min-width: 200px;
    float:center;
  }
`;
const Video = styled.video`
  height: 300px;
  width: 400px;
  @media only screen and (max-width: 900px) {
    width: auto;
    max-width: 250px;
    max-height:300px;
  }
  @media only screen and (max-width: 450px) {
    width: auto;
    max-width: 200px;
    max-height: 200px;
  }
`;
const ResultImage = styled.img`
  height: 300px;
  width: 450px;
  @media only screen and (max-width: 900px) {
    width: auto;
    max-width: 350px;
    max-height:300px;
  }
  @media only screen and (max-width: 450px) {
    width: auto;
    max-width: 250px;
    max-height: 200px;
  }
`;
const StyledTableHead = styled(TableCell)`
  background-color: #3c40af;
  color: white;
  font-weight: bold;
`;
