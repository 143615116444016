import React, { memo, useState } from "react";
import styled from "styled-components";
import { Grid, Paper, Typography } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import CommonService from "../../../utils/services/CommonService";

// import local components

import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
} from "../../../styles/common";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import Spinner from "../../common/loading/Spinner";

// images
import portraitShadowRemoval_sol1 from "../../../assets/images/app/portraitShadowRemoval_sol1.webp";
import portraitShadowRemoval_sample1 from "../../../assets/images/app/portraitShadowRemoval_sample1.webp";
import portraitShadowRemoval_sample2 from "../../../assets/images/app/portraitShadowRemoval_sample2.webp";

const PortraitShadowRemoval = () => {
  const { getQueryResult } = CommonService(
    "payerProvider",
    "shadowRemoval",
  );

  const [showSpinner, setSpinner] = useState(false);
  const [shadowUrl, setShadowUrl] = useState(false);
  const [imageUrl, setImageUrl] = useState(false);
  const [notification, setNotification] = useState("");

  const getImageResult = (sample) => {
    setSpinner(true);
    setShadowUrl('');
    setImageUrl('');

    getQueryResult(sample)
      .then((result) => {
        setSpinner(false);
        setShadowUrl(result.detected_shadow_url);
        setImageUrl(result.image_url);
      })
      .catch((err) => {
        setSpinner(false);
        setNotification({ open: "error", message: err.message });
      });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            In this POC, a Stacked Conditional Generative Adversarial Network
            (ST-CGAN) is used to produce a shadow-free image from the given
            input image. The model first aims at detecting the possible shadowed
            areas in the given input image, and then removes the detected
            shadows by analysing the surrounding pixel values and balancing them
            out.
          </p>
          <strong>Process workflow</strong>
          <p>The shadow-removal process workflow is as follows: </p>
          <p>1. Preparing the dataset for training</p>
          <p>
            The training dataset consists of three parts
            <ul>
              <li>
                Part-A : Shadow-infused images (Portrait images with shadows)
              </li>
              <li>
                Part-B : Shadow mask images (Alpha mask images of shadow areas)
              </li>
              <li>
                Part-C : Shadow-free images (Ground truth portrait images
                without shadows)
              </li>
            </ul>
            These three subsets are used for the training of the Stacked
            Conditional GAN model
          </p>
          <p>
            2. Training the model
            {' '}
            <br />
            {' '}
            The model is trained by running the
            train.py file which contains the model structure and various other
            additional components that include preparing the training dataset in
            train-ready format. The discriminator and generator checkpoints are
            then saved to be used while testing.
          </p>
          <p>
            3. Testing the model
            {' '}
            <br />
            {' '}
            The saved model checkpoints are loaded
            and a few sample images (portrait images with shadows) are passed
            through them to generate the detected shadow mask image along with
            the shadow-free image. This is done by running the test.py file.
          </p>
          <strong>Model Architecture</strong>
          <center>
            <Image1
              src={portraitShadowRemoval_sol1}
              alt=""
            />
          </center>
          <p>
            <strong>How does the model work? </strong>
            {' '}
            <br />
            {' '}
            The model network
            structure consists of two discriminant networks, D1 and D2, and two
            generative networks, G1 and G2, for detecting, removing, and
            restoring the original images without shadows. The generators and
            discriminators are stacked one on top of the other to better suit
            its functionality.
          </p>
          <p>
            The generator network G1 is responsible for detecting the shadowed
            area of the input image, which can be any image containing a shadow.
            It gives a binary image of the shadow area as the output.
          </p>
          <p>
            The generator network G2 takes the original shadow-infused image and
            the binary image generated by G1 as its input to generate the
            shadow-free image.
          </p>
          <p>
            The discriminant networks D1 and D2 are used to supervise the above
            two generation processes until the model converges.
          </p>
          <p>
            The inputs for D1 are:
            <ul>
              <li>
                A combination of the original shadow-infused image and the
                ground truth shadow mask image
              </li>
              <li>
                A combination of the original shadow-infused image and the
                shadow mask image generated by G1
              </li>
            </ul>
            These two inputs are then supervised by D1 to detect the real image
            from the generated fake image.
          </p>
          <p>
            The inputs for D2 are:
            <ul>
              <li>
                A combination of the original shadow-infused image, the ground
                truth shadow mask image, and the ground truth shadow removal
                image
              </li>
              <li>
                A combination of the original shadow-infused image, the shadow
                mask image generated by G1, and the shadow removal image
                generated by G2
              </li>
              These two inputs are then supervised by D2 to detect the real
              image from the generated fake image.
            </ul>
          </p>
          <p>
            <strong>Application : </strong>
          </p>
          <p>
            In this era of digital connect, many medical service providers have
            started digitizing their services. One such service that is slowly
            gaining popularity is virtual medical consultation. Patients can
            virtually connect with doctors and avail consultations online.
            During these consultations, there may be cases where patients are
            required to upload images of their self for better diagnosis and
            treatment (for example, dermatology related cases, post-surgery
            images, etc.) User images are usually not clear and may contain
            shadows caused by external factors, which makes it difficult to
            analyse. This tool helps doctors and medical practitioners to get
            user-submitted images with better clarity for easy diagnosis and
            treatment.
          </p>
          <strong> Useful Links</strong>
          <LinkContainer>
            <Grid container spacing={2}>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/PortraitShadowRemoval/PortraitShadowRemoval.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="http://www.ifp.illinois.edu/~vuongle2/helen/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Source
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://drive.google.com/file/d/1I0qw-65KBA6np8vIZzO6oeiOvcDBttAY/view"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Shadow Masks
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <br />
        <DemoContainer>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12} md={4}>
              <MaskPaper elevation={3}>
                <Image src={portraitShadowRemoval_sample1} />
                <br />
                <MarginButton
                  variant="contained"
                  color="primary"
                  onClick={getImageResult.bind(this, "sample")}
                >
                  Sample 1
                </MarginButton>
              </MaskPaper>
            </Grid>
            <Grid item xs={12} md={4}>
              <MaskPaper>
                <Image src={portraitShadowRemoval_sample2} />
                <br />
                <MarginButton
                  variant="contained"
                  color="primary"
                  onClick={getImageResult.bind(this, "sample1")}
                >
                  Sample 2
                </MarginButton>
              </MaskPaper>
            </Grid>
          </Grid>
          <section>{showSpinner && <Spinner text="Loading..." />}</section>
          {imageUrl && (
            <section>
              {imageUrl && (
                <section>
                  <Grid
                    container
                    xs={12}
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid xs={12} md={6} alignItems="center">
                      <ResponsiveTypography>
                        <FontAwesomeIcon icon={faImage} />
                        Shadow Detected in the image
                      </ResponsiveTypography>
                      <Image
                        src={shadowUrl}
                        alt=""
                      />
                    </Grid>
                    <Grid xs={12} md={6} alignItems="center">
                      <ResponsiveTypography>
                        <FontAwesomeIcon icon={faImage} />
                        Image After Shadow Removed
                      </ResponsiveTypography>
                      <Image src={imageUrl} alt="" />
                    </Grid>
                  </Grid>
                </section>
              )}
            </section>
          )}
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};

export default memo(PortraitShadowRemoval);
const MaskPaper = styled(Paper)`
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  @media only screen and (max-width: 450px) {
    padding-top: 0;
    height: auto;
    max-height: 300px;
    padding-bottom: 10px;
    .MuiTypography-root {
      font-size: 1rem;
    }
  }
`;
const Image = styled.img`
  width: 100%;
  max-width: 300px;
  max-height: 230px;
`;
const MarginButton = styled(StyledButton)`
  margin-right: 10px;
  margin-left: 10px;
`;
const Image1 = styled.img`
  @media only screen and (min-width: 900px) {
    width:800px;
    height:400px;
  }
`;
const ResponsiveTypography = styled(Typography)`
  @media only screen and (max-width: 900px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 700px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 700px) {
    font-size: 14px;
  }
`;
