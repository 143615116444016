import React, { memo, useState } from "react";
import {
  Box,
  Select,
  MenuItem,
  TextField,
  Alert,
  CircularProgress,
} from "@mui/material";
import Collapse from "../../common/Collapse";
import SolutionContainerWrapper from "../../common/SolutionContainerWrapper";
import CommonService from "../../../utils/services/CommonService";
import { StyledButton, DemoContainer, Paragraph } from "../../../styles/common";
import Spinner from "../../common/loading/Spinner";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Container,
  Button,
} from "@mui/material";
import styled from "styled-components";
import { useLocation, useHistory } from "react-router-dom";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
import TaskEvalution from "../../../assets/images/app/TaskEvalution.png";
import { getUploadToken } from "../../../utils";

Chart.register(...registerables);

export const LLM = () => {
  return (
    <SolutionContainerWrapper>
      <Collapse text="Description">
        <Paragraph>
          <Typography>
            <p>
              The LLM Evaluation Tool is designed to assess and evaluate various
              tasks performed by large language models (LLMs), including
              summarization, text generation, question answering, and sentiment
              analysis.
              <br />
              The evaluation involves measuring model outputs using automated
              metrics such as Perplexity, BLEU, and ROUGE (Rouge-1, Rouge-2, and
              Rouge-L), which compare the similarity between generated and
              reference texts.
              <br />
              Additionally, Human Evaluation Metrics like Fluency, Coherence,
              Relevance, and Diversity are also considered to ensure the model
              generates contextually accurate and natural-sounding outputs.
              <br /> We will explore all metrics mentioned above:
            </p>

            <p>
              <b>1. Perplexity: </b>
              <br />
              To compute the perplexity, we need to average the log-likelihood
              values and then exponentiate the negative of that average.
            </p>

            <p>
              <b>Formula: </b>
              <br />
              Perplexity = exp(−(1/N) * Σ<sub>i=1</sub>
              <sup>N</sup> log(P(w<sub>i</sub>)))
            </p>
            <p>
              Where:
              <br />N is the number of tokens (log-likelihood values in your
              case).
              <br />
              log(P(w<sub>i</sub>)) represents the log-likelihood of the i-th
              token.
            </p>

            <p>
              <b>2. F1-Score: </b>
              <br />
              F1-score is a measure of a language model's balance between
              precision and recall. It is calculated as the harmonic mean of
              precision and recall.
            </p>

            <p>
              Precision = True Positives / (True Positives + False Positives)
              <br />
              Recall = True Positives / (True Positives + False Negatives)
              <br />
              F1 Score = (2 × Precision × Recall) / (Precision + Recall)
            </p>

            <p>
              <b>3. BLEU Score: </b>
              <br />
              BLEU measures the n-gram overlap between the generated text and
              the reference translations. A higher BLEU score indicates a better
              translation.
            </p>

            <p>
              <b>Formula: </b>
              <br />
              BLEU = BP × exp(Σ(log(P<sub>n</sub>)) / N)
            </p>
            <p>
              Where:
              <br />
              BP: Brevity penalty
              <br />P<sub>n</sub>: Precision for n-grams
              <br />
              N: Maximum n-gram order
            </p>

            <p>
              <b>4. ROUGE: </b>
              <br />
              <b>ROUGE-1:</b> The number of N-grams which can be 1 and 2. For
              ROUGE-1, it is the number of words.
              <br />
              <b>ROUGE-2:</b> It is the number of sequences of 2 words (e.g.,
              ROUGE-1 for unigrams, ROUGE-2 for bigrams).
            </p>

            <p>
              <b>Formula: </b>
              <br />
              ROUGE-N = 2 × Recall × Precision / (Recall + Precision)
            </p>
            <p>
              Where:
              <br />
              Recall = (Number of overlapping n-grams) / (Total number of
              n-grams in reference text)
              <br />
              Precision = (Number of overlapping n-grams) / (Total number of
              n-grams in generated text)
            </p>

            <p>
              <b>Human Evaluations: </b>
            </p>

            <p>
              <b>1. Fluency:</b>
            </p>

            <p>
              Fluency measures the grammatical correctness and readability of
              the generated text. Fluent text is free from grammatical errors,
              awkward phrasing, and other language issues.
              <br />
              Approach: Since fluency is hard to quantify directly, we often use
              proxies like n-gram overlap (e.g., ROUGE, BLEU) or language model
              perplexity.
            </p>

            <p>
              <b>2. Coherence: </b>
            </p>

            <p>
              Coherence assesses how logically connected and consistent the
              sentences are.
              <br />
              Approach: Calculate the average cosine similarity between
              consecutive sentence embeddings. Coherence is higher when
              sentences relate well to each other.
            </p>

            <p>
              <b>Formula:</b>
            </p>

            <p>
              Coherence = (1 / (N - 1)) * Σ<sub>i=1</sub>
              <sup>N-1</sup> <b>CosineSimilarity</b>(<b>E</b>
              <sub>i</sub>, <b>E</b>
              <sub>i+1</sub>)
              <br />
              Where <b>E</b>
              <sub>i</sub> and <b>E</b>
              <sub>i+1</sub> are the embeddings of consecutive sentences, and N
              is the number of sentences.
            </p>

            <p>
              <b>3. Relevance :</b>
            </p>

            <p>
              It evaluates how well the generated text addresses the given
              prompt or question.
              <br />
              Approach: Measure the cosine similarity between the embedding of
              the prompt and the generated text.
            </p>

            <p>
              Relevance = <b>CosineSimilarity</b>(<b>E</b>
              <sub>prompt</sub>, <b>E</b>
              <sub>generated</sub>)
              <br />
              Where <b>E</b>
              <sub>prompt</sub> is the embedding of the prompt, and <b>E</b>
              <sub>generated</sub> is the embedding of the generated text.
            </p>

            <p>
              <b>4. Diversity: </b>
            </p>

            <p>
              It measures the richness in generated text. High diversity
              indicates less repetition.
              <br />
              Approach: Calculate the ratio of unique words to the total number
              of words in the generated text.
            </p>

            <p>
              <b>Formula:</b>
              <br />
              Diversity = (Number of unique words) / (Total number of words)
            </p>

            <p>
              <b>Task Specific Evaluation</b>
            </p>

            <img
              src={TaskEvalution}
              style={{ width: "600px", height: "300px" }}
              alt="Description"
            />
          </Typography>
        </Paragraph>
      </Collapse>
    </SolutionContainerWrapper>
  );
};

export const LLMDemo = memo(() => {
  const [tasks, setTasks] = useState("");
  const [models, setModels] = useState("");
  const [prompt, setPrompt] = useState("");
  const [referenceText, setReferenceText] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [error, setError] = useState("");
  const [submittedData, setSubmittedData] = useState(null);
  const [notification, setNotification] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDataSubmitted, setIsDataSubmitted] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const history = useHistory();

  // const { postQueryResult } = CommonService("z1dAppsv1", "llm");
  const { postQueryResult } = CommonService("llm", "llm");
  const Token = getUploadToken();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!tasks || !models || !prompt || !referenceText) {
      setNotification({ open: "error", message: "Fields are empty" });
      return;
    }

    setSubmittedData(null);
    setError("");
    setIsDataSubmitted(false);

    const params = {
      token: Token.token,
      task_name: tasks,
      model_name: models,
      prompt: prompt,
      reference_text: referenceText,
      Api_Key: apiKey,
    };

    setSpinner(true);

    try {
      const response = await postQueryResult("LLMMetricsllmmetrics", params);
      if (response.Error_Flag) {
        setError(response.Error_UI);
      } else {
        setSubmittedData(response);
        setIsDataSubmitted(true);
      }
    } catch (err) {
      setNotification({ open: "error", message: "Error: Network Error" });
      console.error(err);
    } finally {
      setSpinner(false);
    }
  };

  const handleClear = () => {
    setTasks("");
    setModels("");
    setPrompt("");
    setReferenceText("");
    setApiKey("");
    setError("");
    setSubmittedData(null);
  };

  const fillTask = (task, model, prompt, reference) => {
    setTasks(task);
    setModels(model);
    setPrompt(prompt);
    setReferenceText(reference);
  };

  const location = useLocation();
  const { formData } = location.state || {};
  const result = submittedData?.result || {};
  const isAllSelected = result;

  if (!result) {
    return (
      <div>
        <Container style={{ padding: 40 }}>
          <Typography variant="h6">No data available.</Typography>
        </Container>
      </div>
    );
  }

  const {
    "Task Name": taskName = "N/A",
    "Model Name": modelName = "N/A",
    GeneratedAnswer = "N/A",
    modelcomparision = {},
    Outputs = {},
    "Evaluation Report": {
      ROUGE = {},
      BLEU = {},
      "Human Evaluation Metrics": humanMetrics = {},
      Perplexity: perplexity = {},
      "F1 Score": f1Score = {},
    } = {},
    "Range Reference": rangeReference = {},
  } = result;

  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const labels = {
    Summarization: [
      "ROUGE-1",
      "ROUGE-2",
      "ROUGE-L",
      "BLEU",
      "Fluency",
      "Relevance",
      "Diversity",
      "Coherence",
    ],
    TextGeneration: [
      "Perplexity",
      "ROUGE-1",
      "ROUGE-2",
      "ROUGE-L",
      "BLEU",
      "Fluency",
      "Relevance",
      "Diversity",
    ],
    QuestionAnswering: [
      "F1Score",
      "ROUGE-1",
      "ROUGE-2",
      "ROUGE-L",
      "Fluency",
      "Relevance",
      "Diversity",
      "Coherence",
    ],
    SentimentAnalysis: [
      "F1Score",
      "Fluency",
      "Relevance",
      "Diversity",
      "Coherence",
    ],
  };

  const relevantLabels = labels[taskName] || [];
  const relevantData = Outputs;

  const LineData = {
    labels: relevantData ? Object.keys(relevantData) : [],
    datasets: relevantLabels.map((label) => {
      const dataPoints = Object.keys(relevantData).map((outputKey) => {
        const outputMetrics = relevantData[outputKey];
        const adjustedLabel = label.includes("Score")
          ? label
          : `${label} Score`;

        return {
          x: relevantLabels.indexOf(label) + 1,
          y:
            outputMetrics && outputMetrics[adjustedLabel] !== undefined
              ? outputMetrics[adjustedLabel]
              : 0,
        };
      });

      return {
        label: `${label}`,
        data: dataPoints,
        borderColor: getRandomColor(),
        fill: false,
      };
    }),
  };

  const lineOptions = {
    scales: {
      x: {
        type: "category",
        title: {
          display: true,
          text: "Metrics",
        },
      },
      y: {
        type: "linear",
        beginAtZero: true,
        title: {
          display: true,
          text: "Scores",
          padding: {
            top: 10,
          },
        },
        ticks: {
          stepSize: 0.05,
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: "top",
      },
      tooltip: {
        callbacks: {
          title: function (tooltipItems) {
            return tooltipItems[0].label;
          },
          label: function (tooltipItem) {
            return (
              tooltipItem.dataset.label + ": " + tooltipItem.raw.y.toFixed(5)
            );
          },
        },
      },
    },
  };

  const getScoreOrDefault = (score) => {
    return score !== undefined && score !== null ? score : "N/A";
  };

  const renderTable = (title, headers, rows) => (
    <>
      <Typography variant="h6" style={{ marginTop: 40 }}>
        <b>{title}</b>
      </Typography>
      <TableContainer component={Paper} style={{ color: "white" }}>
        <StyledTable>
          <TableHead style={{ backgroundColor: "#3c40af" }}>
            <TableRow>
              {headers.map((header) => (
                <TableCell key={header} style={{ color: "white" }}>
                  <b>{header}</b>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </StyledTable>
      </TableContainer>
    </>
  );

  const renderBLEURow = () =>
    Object.entries(BLEU || {}).map(([bleu, data]) => (
      <TableRow key={bleu}>
        <TableCell>
          <b>BLEU</b>
        </TableCell>
        <TableCell>
          {getScoreOrDefault(data[`Actual ${bleu.replace("-", "")} Score`])}
        </TableCell>
        <TableCell>{getScoreOrDefault(data["Observation"])}</TableCell>
        <TableCell>{getScoreOrDefault(data["Recommendations"])}</TableCell>
      </TableRow>
    ));

  const roUGERows = Object.entries(ROUGE || {}).map(([metric, data]) => (
    <TableRow key={metric}>
      <TableCell>
        <b>{metric}</b>
      </TableCell>
      <TableCell>
        {getScoreOrDefault(data[`Actual ${metric.replace("-", "")} Score`])}
      </TableCell>
      <TableCell>{getScoreOrDefault(data["Observation"])}</TableCell>
      <TableCell>{getScoreOrDefault(data["Recommendations"])}</TableCell>
    </TableRow>
  ));

  const humanMetricsRows = Object.entries(humanMetrics || {}).map(
    ([metric, data]) => (
      <TableRow key={metric}>
        <TableCell>
          <b>{metric}</b>
        </TableCell>
        <TableCell>
          {getScoreOrDefault(data[`Actual ${metric} Score`])}
        </TableCell>
        <TableCell>{getScoreOrDefault(data["Observation"])}</TableCell>
        <TableCell>{getScoreOrDefault(data["Recommendations"])}</TableCell>
      </TableRow>
    )
  );

  const perplexityRows = Object.entries(perplexity || {}).map(([key, data]) => (
    <TableRow key={key}>
      <TableCell>
        <b>{key}</b>
      </TableCell>
      <TableCell>
        {getScoreOrDefault(data["Actual Perplexity Score"])}
      </TableCell>
      <TableCell>{getScoreOrDefault(data["Observation"])}</TableCell>
      <TableCell>{getScoreOrDefault(data["Recommendations"])}</TableCell>
    </TableRow>
  ));

  const f1ScoreRow = Object.entries(f1Score || {}).map(([key, score]) => (
    <TableRow key={key}>
      <TableCell>
        <b>F1 Score</b>
      </TableCell>
      <TableCell>{getScoreOrDefault(score["Actual F1 Score"])}</TableCell>
      <TableCell>{getScoreOrDefault(score["Observation"])}</TableCell>
      <TableCell>{getScoreOrDefault(score["Recommendations"])}</TableCell>
    </TableRow>
  ));

  const formatDescription = (description) => {
    if (typeof description === "object" && description !== null) {
      return Object.entries(description)
        .map(([key, value]) => `${key}: ${value}`)
        .join(", ");
    }
    return description || "N/A";
  };

  const rangeReferenceRows = Object.entries(rangeReference || {}).flatMap(
    ([metricType, metrics]) =>
      Object.entries(metrics).map(([metric, { RANGE, Description }]) => (
        <TableRow key={`${metricType}-${metric}`}>
          <TableCell>{metricType}</TableCell>
          <TableCell>{metric}</TableCell>
          <TableCell>{RANGE || "N/A"}</TableCell>
          <TableCell>{formatDescription(Description)}</TableCell>
        </TableRow>
      ))
  );

  const renderEvaluationReport = () => {
    switch (taskName) {
      case "Summarization":
        return (
          <>
            {renderTable(
              "ROUGE Metrics",
              ["Metric", "Score", "Observation", "Recommendation"],
              roUGERows
            )}
            {renderTable(
              "BLEU Score",
              ["Metric", "Score", "Observation", "Recommendation"],
              renderBLEURow()
            )}
            {renderTable(
              "Human Evaluation Metrics",
              ["Metric", "Score", "Observation", "Recommendation"],
              humanMetricsRows
            )}
          </>
        );
      case "TextGeneration":
        return (
          <>
            {renderTable(
              "Perplexity",
              ["Metric", "Score", "Observation", "Recommendation"],
              perplexityRows
            )}
            {renderTable(
              "ROUGE Metrics",
              ["Metric", "Score", "Observation", "Recommendation"],
              roUGERows
            )}
            {renderTable(
              "BLEU Score",
              ["Metric", "Score", "Observation", "Recommendation"],
              renderBLEURow()
            )}
            {renderTable(
              "Human Evaluation Metrics",
              ["Metric", "Score", "Observation", "Recommendation"],
              humanMetricsRows
            )}
          </>
        );
      case "QuestionAnswering":
        return (
          <>
            {renderTable(
              "F1 Score",
              ["Metric", "Score", "Observation", "Recommendation"],
              f1ScoreRow
            )}
            {renderTable(
              "ROUGE Metrics",
              ["Metric", "Score", "Observation", "Recommendation"],
              roUGERows
            )}
            {renderTable(
              "BLEU Score",
              ["Metric", "Score", "Observation", "Recommendation"],
              renderBLEURow()
            )}
            {renderTable(
              "Human Evaluation Metrics",
              ["Metric", "Score", "Observation", "Recommendation"],
              humanMetricsRows
            )}
          </>
        );
      case "SentimentAnalysis":
        return (
          <>
            {renderTable(
              "F1 Score",
              ["Metric", "Score", "Observation", "Recommendation"],
              f1ScoreRow
            )}
            {renderTable(
              "Human Evaluation Metrics",
              ["Metric", "Score", "Observation", "Recommendation"],
              humanMetricsRows
            )}
          </>
        );
      default:
        return (
          <Typography variant="h6">
            No specific evaluation data for this task.
          </Typography>
        );
    }
  };

  //  Model Comparison Chart
  const ModelComparisonChart = () => {
    const models = ["Cohere", "Jurassic", "Claude"];
    const getModelData = (modelName) => {
      if (modelName === "All") {
        return {
          BLEU: (modelcomparision.BLEU || []).reduce(
            (sum, model) => sum + (model.Score || 0),
            0
          ),
          ROUGE1: (modelcomparision.ROUGE || []).reduce(
            (sum, model) => sum + (model["ROUGE-1 Score"] || 0),
            0
          ),
          ROUGE2: (modelcomparision.ROUGE || []).reduce(
            (sum, model) => sum + (model["ROUGE-2 Score"] || 0),
            0
          ),
          ROUGEL: (modelcomparision.ROUGE || []).reduce(
            (sum, model) => sum + (model["ROUGE-L Score"] || 0),
            0
          ),
          Perplexity: (modelcomparision.Perplexity || []).reduce(
            (sum, model) => sum + (model.Score || 0),
            0
          ),
          F1Score: (modelcomparision["F1 Score"] || []).reduce(
            (sum, model) => sum + (model.Score || 0),
            0
          ),
          Fluency: (modelcomparision["Human Evaluation Metrics"] || []).reduce(
            (sum, model) => sum + (model["Fluency Score"] || 0),
            0
          ),
          Relevance: (
            modelcomparision["Human Evaluation Metrics"] || []
          ).reduce((sum, model) => sum + (model["Relevance Score"] || 0), 0),
          Diversity: (
            modelcomparision["Human Evaluation Metrics"] || []
          ).reduce((sum, model) => sum + (model["Diversity Score"] || 0), 0),
          Coherence: (
            modelcomparision["Human Evaluation Metrics"] || []
          ).reduce((sum, model) => sum + (model["Coherence Score"] || 0), 0),
        };
      }
      const modelData =
        modelcomparision.ROUGE?.find(
          (model) => model["Model Name"] === modelName
        ) || {};

      const humanMetricsData =
        modelcomparision["Human Evaluation Metrics"]?.find(
          (model) => model["Model Name"] === modelName
        ) || {};

      const f1ScoreData =
        modelcomparision["F1 Score"]?.find(
          (model) => model["Model Name"] === modelName
        ) || {};

      return {
        BLEU:
          modelcomparision.BLEU?.find(
            (model) => model["Model Name"] === modelName
          )?.Score || 0,
        ROUGE1: modelData["ROUGE-1 Score"] || 0,
        ROUGE2: modelData["ROUGE-2 Score"] || 0,
        ROUGEL: modelData["ROUGE-L Score"] || 0,
        Perplexity:
          modelcomparision.Perplexity?.find(
            (model) => model["Model Name"] === modelName
          )?.Score || 0,
        F1Score: f1ScoreData.Score || 0,
        Fluency: humanMetricsData["Fluency Score"] || 0,
        Relevance: humanMetricsData["Relevance Score"] || 0,
        Diversity: humanMetricsData["Diversity Score"] || 0,
        Coherence: humanMetricsData["Coherence Score"] || 0,
      };
    };

    const cohereData = getModelData("Cohere");
    const jurassicData = getModelData("Jurassic");
    const claudeData = getModelData("Claude");

    const datasets = [];
    const labels = [];

    switch (taskName) {
      case "Summarization":
        labels.push(
          "ROUGE-1",
          "ROUGE-2",
          "ROUGE-L",
          "BLEU",
          "Fluency",
          "Relevance",
          "Diversity",
          "Coherence"
        );
        datasets.push(
          {
            label: "Cohere",
            data: [
              cohereData.ROUGE1,
              cohereData.ROUGE2,
              cohereData.ROUGEL,
              cohereData.BLEU,
              cohereData.Fluency,
              cohereData.Relevance,
              cohereData.Diversity,
              cohereData.Coherence,
            ],
            backgroundColor: "#4A90E2",
          },
          {
            label: "Jurassic",
            data: [
              jurassicData.ROUGE1,
              jurassicData.ROUGE2,
              jurassicData.ROUGEL,
              jurassicData.BLEU,
              jurassicData.Fluency,
              jurassicData.Relevance,
              jurassicData.Diversity,
              jurassicData.Coherence,
            ],
            backgroundColor: "#A0D468",
          },
          {
            label: "Claude",
            data: [
              claudeData.ROUGE1,
              claudeData.ROUGE2,
              claudeData.ROUGEL,
              claudeData.BLEU,
              claudeData.Fluency,
              claudeData.Relevance,
              claudeData.Diversity,
              claudeData.Coherence,
            ],
            backgroundColor: "#9B59B6",
          }
        );
        break;

      case "TextGeneration":
        labels.push(
          "Perplexity",
          "ROUGE-1",
          "ROUGE-2",
          "ROUGE-L",
          "BLEU",
          "Fluency",
          "Relevance",
          "Diversity",
          "Coherence"
        );
        datasets.push(
          {
            label: "Cohere",
            data: [
              cohereData.Perplexity,
              cohereData.ROUGE1,
              cohereData.ROUGE2,
              cohereData.ROUGEL,
              cohereData.BLEU,
              cohereData.Fluency,
              cohereData.Relevance,
              cohereData.Diversity,
              cohereData.Coherence,
            ],
            backgroundColor: "#4A90E2",
          },
          {
            label: "Jurassic",
            data: [
              jurassicData.Perplexity,
              jurassicData.ROUGE1,
              jurassicData.ROUGE2,
              jurassicData.ROUGEL,
              jurassicData.BLEU,
              jurassicData.Fluency,
              jurassicData.Relevance,
              jurassicData.Diversity,
              jurassicData.Coherence,
            ],
            backgroundColor: "#A0D468",
          },
          {
            label: "Claude",
            data: [
              claudeData.Perplexity,
              claudeData.ROUGE1,
              claudeData.ROUGE2,
              claudeData.ROUGEL,
              claudeData.BLEU,
              claudeData.Fluency,
              claudeData.Relevance,
              claudeData.Diversity,
              claudeData.Coherence,
            ],
            backgroundColor: "#9B59B6",
          }
        );
        break;

      case "QuestionAnswering":
        labels.push(
          "F1 Score",
          "ROUGE-1",
          "ROUGE-2",
          "ROUGE-L",
          "BLEU",
          "Fluency",
          "Relevance",
          "Diversity",
          "Coherence"
        );
        datasets.push(
          {
            label: "Cohere",
            data: [
              cohereData.F1Score,
              cohereData.ROUGE1,
              cohereData.ROUGE2,
              cohereData.ROUGEL,
              cohereData.BLEU,
              cohereData.Fluency,
              cohereData.Relevance,
              cohereData.Diversity,
              cohereData.Coherence,
            ],
            backgroundColor: "#4A90E2",
          },
          {
            label: "Jurassic",
            data: [
              jurassicData.F1Score,
              jurassicData.ROUGE1,
              jurassicData.ROUGE2,
              jurassicData.ROUGEL,
              jurassicData.BLEU,
              jurassicData.Fluency,
              jurassicData.Relevance,
              jurassicData.Diversity,
              jurassicData.Coherence,
            ],
            backgroundColor: "#A0D468",
          },
          {
            label: "Claude",
            data: [
              claudeData.F1Score,
              claudeData.ROUGE1,
              claudeData.ROUGE2,
              claudeData.ROUGEL,
              claudeData.BLEU,
              claudeData.Fluency,
              jurassicData.Relevance,
              jurassicData.Diversity,
              jurassicData.Coherence,
            ],
            backgroundColor: "#9B59B6",
          }
        );
        break;

      case "SentimentAnalysis":
        labels.push(
          "F1 Score",
          "Fluency",
          "Relevance",
          "Diversity",
          "Coherence"
        );
        datasets.push(
          {
            label: "Cohere",
            data: [
              cohereData.F1Score,
              cohereData.Fluency,
              cohereData.Relevance,
              cohereData.Diversity,
              cohereData.Coherence,
            ],
            backgroundColor: "#4A90E2",
          },
          {
            label: "Jurassic",
            data: [
              jurassicData.F1Score,
              jurassicData.Fluency,
              jurassicData.Relevance,
              jurassicData.Diversity,
              jurassicData.Coherence,
            ],
            backgroundColor: "#A0D468",
          },
          {
            label: "Claude",
            data: [
              claudeData.F1Score,
              claudeData.Fluency,
              claudeData.Relevance,
              claudeData.Diversity,
              claudeData.Coherence,
            ],
            backgroundColor: "#9B59B6",
          }
        );
        break;
      default:
        return null;
    }

    const options = {
      scales: {
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text: "Scores",
            font: {
              weight: "bold",
            },
          },
          ticks: {
            callback: function (value) {
              return value === 0 ? "0" : value;
            },
          },
        },
        x: {
          title: {
            display: true,
            text: "Metrics",
            font: {
              weight: "bold",
              fontcolor: "black",
            },
          },
          ticks: {
            font: {
              weight: "bold",
            },
          },
        },
      },
      responsive: true,
      plugins: {
        legend: {
          display: true,
          position: "top",
        },
      },
      width: 100,
      height: 100,
    };
    return (
      <div>
        <Typography variant="h6">
          <p>
            <b>Metrics Comparison Across Models average of 5 times </b>
          </p>
        </Typography>
        {datasets.length === 0 ? (
          <Typography variant="body1">
            No data available to display Bar Graphs.
          </Typography>
        ) : (
          <Bar
            data={{
              labels: labels,
              datasets: datasets,
            }}
            options={options}
          />
        )}
      </div>
    );
  };
  // const base64Image =
  //   typeof result === "string"
  //     ? result.replace(/^data:image\/png;base64,/, "")
  //     : null;

  // ............
  return (
    <SolutionContainerWrapper snackbar={notification}>
      <Collapse text="Demo">
        <Container style={{ padding: 40, position: "relative" }}>
          {error && (
            <Alert
              severity="error"
              sx={{
                mb: 3,
                backgroundColor: "#f8d7da",
                color: "#721c24",
                borderRadius: "5px",
                border: "1px solid #f5c6cb",
              }}
            >
              {error}
            </Alert>
          )}

          {loading && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 10,
              }}
            >
              <CircularProgress size={60} color="primary" />
            </Box>
          )}

          <Box mb={3}>
            <Box display="flex" alignItems="center">
              <Typography style={{ marginRight: 16 }}>
                Select a Task :
              </Typography>
              <Box display="flex" gap={2}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    fillTask(
                      "Summarization",
                      "Cohere",
                      "Climate change refers to significant changes in global temperatures and weather patterns over time. While climate change is a natural phenomenon, scientific evidence shows that human activities, such as burning fossil fuels and deforestation, have accelerated the rate of change. This has led to rising temperatures, melting ice caps, and extreme weather events. Addressing climate change requires global cooperation and the adoption of sustainable practices to reduce greenhouse gas emissions.",
                      "Climate change, caused by human activities, is accelerating global warming, leading to extreme weather. Global cooperation is needed to reduce emissions and combat climate change."
                    )
                  }
                >
                  Summarization
                </StyledButton>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    fillTask(
                      "TextGeneration",
                      "Jurassic",
                      "Generate information about AI",
                      "Artificial Intelligence (AI) is a branch of computer science that focuses on creating systems capable of performing tasks that typically require human intelligence. These tasks include learning, reasoning, problem-solving, perception, language understanding, and decision-making. AI can be broadly classified into two categories: Narrow AI and General AI."
                    )
                  }
                >
                  Text Generation
                </StyledButton>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    fillTask(
                      "QuestionAnswering",
                      "Jurassic",
                      "When did India gain independence from British rule?",
                      "August 15, 1947."
                    )
                  }
                >
                  Question Answering
                </StyledButton>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    fillTask(
                      "SentimentAnalysis",
                      "Cohere",
                      "I'm thrilled with the support I received, they resolved my issue quickly.",
                      "Positive"
                    )
                  }
                >
                  Sentiment Analysis
                </StyledButton>
              </Box>
            </Box>
          </Box>

          <Box mb={3} display="flex" alignItems="center">
            <Typography
              variant="subtitle1"
              sx={{ flex: "0 0 120px", marginBottom: "25px" }}
            >
              LLM Task
            </Typography>
            <Select
              value={tasks}
              onChange={(e) => setTasks(e.target.value)}
              displayEmpty
              fullWidth
              style={{ marginBottom: 20 }}
              sx={{
                flex: "1",
                maxWidth: "300px",
                height: "40px",
                ".MuiInputBase-input": { height: "10px", color: "black" },
                borderRadius: "5px",
                boxShadow: "2px 2px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <MenuItem value="" disabled>
                Select Task
              </MenuItem>
              <MenuItem value="Summarization">Summarization</MenuItem>
              <MenuItem value="TextGeneration">Text Generation</MenuItem>
              <MenuItem value="QuestionAnswering">Question Answering</MenuItem>
              <MenuItem value="SentimentAnalysis">Sentiment Analysis</MenuItem>
            </Select>
          </Box>

          <Box mb={3} display="flex" alignItems="center">
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              flex="1"
            >
              <Box mr={2} flex="1">
                <Typography variant="subtitle1" sx={{ marginBottom: "-35px" }}>
                  Model
                </Typography>
                <Select
                  value={models}
                  onChange={(e) => setModels(e.target.value)}
                  displayEmpty
                  fullWidth
                  style={{ marginLeft: 120, maxWidth: "300px" }}
                  sx={{
                    flex: "1",
                    height: "40px",
                    maxWidth: "200px",
                    ".MuiInputBase-input": { height: "10px" },
                    borderRadius: "5px",
                    boxShadow: "2px 2px 8px rgba(0, 0, 0, 0.2)",
                    marginBottom: "50px",
                  }}
                >
                  <MenuItem value="" disabled>
                    Select Model
                  </MenuItem>
                  <MenuItem value="Cohere">Cohere</MenuItem>
                  <MenuItem value="Jurassic">Jurassic</MenuItem>
                  <MenuItem value="Claude">Claude</MenuItem>
                  <MenuItem value="All">All</MenuItem>
                </Select>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                flex="1"
                style={{ maxWidth: "1500px" }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ marginBottom: "50px", marginRight: "20px" }}
                >
                  API Key (Optional)
                </Typography>
                <TextField
                  value={apiKey}
                  onChange={(e) => setApiKey(e.target.value)}
                  style={{
                    marginLeft: 10,
                    marginBottom: 20,
                    maxWidth: "200px",
                  }}
                  sx={{
                    height: "70px",
                    borderRadius: "5px",
                    ".MuiInputBase-input": { height: "10px" },
                  }}
                />
              </Box>
            </Box>
          </Box>

          <TextField
            label="Prompt/Question"
            multiline
            rows={4}
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            fullWidth
            style={{ marginBottom: 20 }}
          />
          <TextField
            label="Reference Text (Summary/Answer)"
            multiline
            rows={4}
            value={referenceText}
            onChange={(e) => setReferenceText(e.target.value)}
            fullWidth
            style={{ marginBottom: 20 }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <StyledButton
              variant="contained"
              color="primary"
              style={{ marginRight: "20px" }}
              onClick={handleClear}
            >
              Clear
            </StyledButton>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Submit
            </StyledButton>
          </div>

          {/* Spinner and Result Display */}
          {showSpinner && <Spinner text="Loading..." />}
          {isDataSubmitted && submittedData && isAllSelected ? (
            <div>
              {typeof result === "string" ? (
                <img
                  // src={`data:image/png;base64,${base64Image}`}
                  alt="Metrics Chart"
                  style={{
                    width: "100%",
                    maxHeight: "600px",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <>
                  {console.log(
                    "Result table after submitted else block:",
                    result
                  )}
                  {renderTable(
                    "Task Information",
                    ["Field", "Value"],
                    [
                      <TableRow key="task-name">
                        <TableCell>
                          <b>Task Name</b>
                        </TableCell>
                        <TableCell>{taskName}</TableCell>
                      </TableRow>,
                      <TableRow key="model-name">
                        <TableCell>
                          <b>Model Name</b>
                        </TableCell>
                        <TableCell>{modelName}</TableCell>
                      </TableRow>,
                      <TableRow key="generated-summary">
                        <TableCell>
                          <b>Generated Answer</b>
                        </TableCell>
                        <TableCell>{GeneratedAnswer}</TableCell>
                      </TableRow>,
                    ]
                  )}
                  {renderEvaluationReport()}
                  {renderTable(
                    "Range Reference",
                    ["Metric Type", "Metric", "Range", "Description"],
                    rangeReferenceRows
                  )}
                  <ModelComparisonChart />

                  <Typography variant="h6">
                    <b>Line Graph for {modelName} Model</b>
                  </Typography>
                  <Line data={LineData} options={lineOptions} />
                </>
              )}
            </div>
          ) : null}
        </Container>
      </Collapse>
    </SolutionContainerWrapper>
  );
});
export default LLMDemo;

const TableContainer = styled(Paper)`
  &.MuiPaper-root {
    max-height: ${(props) => props.maxHeight};
    max-width: 100%;
    overflow: auto;
  }
`;

const StyledTable = styled(Table)`
  @media only screen and (max-width: 1100px) {
    .MuiTableCell-root {
      font-size: 14px;
    }
  }
  @media only screen and (max-width: 600px) {
    .MuiTableCell-root {
      font-size: 12px;
    }
  }
`;
