import React, { memo, useState } from "react";
import styled from "styled-components";
import {
  Grid,
  Typography,
  TextField,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Grow from "@material-ui/core/Grow";
import List1 from "@material-ui/core/List";
import ListItem1 from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Input from "@material-ui/core/Input";
import Paper from '@material-ui/core/Paper';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from '@material-ui/core/TablePagination';
import TableContainer from '@material-ui/core/TableContainer';

// import sample value components
import literatureValue from "../../../constants/maps/literatureValue";

// import local components
import CommonService from "../../../utils/services/CommonService";

import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  List,
  ListItem,
} from "../../../styles/common";
import SolutionContainer from "../../common/SolutionContainerWrapper";

const { getQueryResult, uploadFile } = CommonService("corona", "genomeFrequencyAnalysis");

const GenomeFrequencyAnalysis = () => {
  const [showSpinner, setSpinner] = useState(false);
  const [result, setResult] = useState("");
  const [page, setpage] = useState(0);
  const [rowsPerPage, setrowsPerPage] = useState(5);
  const [samplevalue, setSamplevalue] = useState("");
  const [sampleData, setSampleData] = useState("");
  const [uploadfile, setUploadfile] = useState("");
  const [notification, setNotification] = useState("");

  const getsampleResult = (e) => {
    setSpinner(true);
    setResult("");
    const selectedSample = e.currentTarget.getAttribute("data-sample");
    setSamplevalue(selectedSample);
    getQueryResult("sample", selectedSample)
      .then((res) => {
        setSpinner(false);
        const result = res["DNA SEQUENCE IS:"];
        setResult(result);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const showSample1 = (e) => {
    const NewDataSet = {
      sample: literatureValue.frequencyAnalysisSample1,
    };
    setSampleData(NewDataSet);
    getsampleResult(e);
  };

  const showSample2 = (e) => {
    const NewDataSet = {
      sample: literatureValue.frequencyAnalysisSample2,
    };
    setSampleData(NewDataSet);
    getsampleResult(e);
  };

  const getUploadResult = (e) => {
    setSpinner(true);
    setResult("");
    setSampleData("");
    uploadFile("upload", uploadfile)
      .then((res) => {
        setSpinner(false);
        const result = res["DNA SEQUENCE IS:"];
        result.length === 9 ? setSamplevalue("2/") : setSamplevalue("1/");
        setResult(result);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const onChangeFile = (e) => {
    const file = e.currentTarget.files[0];
    setUploadfile(file);
  };

  const handleChangePage = (event, newPage) => {
    setpage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const rowsPerPage = event.target.value;
    setrowsPerPage(rowsPerPage);
    setpage(0);
  };

  const formTable = (result) => (
    <Paper>
      <TableContainer style={{ maxHeight: '400px' }}>
        <Typography>Frequency Of Amino Acids</Typography>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <StyledTableHead align="left">Amino Acids</StyledTableHead>
              <StyledTableHead align="left">Count</StyledTableHead>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(result).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((key) => (
              <TableRow key={key}>
                <TableCell align="left">{key[0]}</TableCell>
                <TableCell align="left">{key[1]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15, 20]}
        component="div"
        count={Object.entries(result).length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>COVID-19 is caused by SARS-COV-2 virus. It is a RNA virus. Covid isolate sample DNA sequence is taken for analysis. After entering into the host cell, reverse transcription will take place, that is RNA of the virus will convert into DNA so that it will integrate with DNA of host chromosome. The DNA of the virus is transcribed into mRNA. RNA copy carries the information needed to build a polypeptide. In Transcription T(Thymine) of the DNA sequence is replaced by U(Uracil). RNA is then translated to Amino acids.</p>
          <p>The tool takes an fasta file, having a DNA gene sequence or protein sequence, as an input. It calculates output  the lengths of  RNA, amino acids, functional proteins, and the largest protein, and the properties like – </p>
          <List>
            <ListItem>Aromaticity  -  property of cyclic (ring-shaped), planar (flat) structures with a ring of resonance bonds that gives increased stability compared to other geometric or connective arrangements with the same set of atoms. Out of the 20 amino acids found in protein structures, four are aromatic. They are phenylalanine, tyrosine, tryptophan and histidine</ListItem>
            <ListItem>Isoelectric point - also called the pI of the protein, is the pH at which the net charge of the protein is zero. </ListItem>
            <ListItem>Secondary structure composition- It is the fraction of helix, turn and sheet that are present in the protein sequence.</ListItem>
          </List>
          <LinkContainer>
            <Grid container spacing={2}>

              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://bigd.big.ac.cn/gwh/browse/virus/coronaviridae"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://github.com/Virtusa-vLife/Use-Cases/blob/master/Gene_Analysis/Genome_frequency_notebook.ipynb"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <br />
        <DemoContainer>
          {sampleData != ""
                        && (
                        <section>
                          <Grid container direction="row" justify="center" alignItems="center">
                            <Grid item xs={12} sm={10} md={8}>
                              <TextField
                                rows="8"
                                multiline
                                variant="outlined"
                                name="sample"
                                fullWidth
                                label="Sample Data"
                                value={sampleData.sample}
                              />
                              <br />
                              <br />
                            </Grid>
                          </Grid>
                        </section>
                        )}
          <Grid container spacing={1} direction="row" justify="center" alignItems="center">
            <Grid item xs={5} sm={3} md={2}>
              <StyledButton
                variant="contained"
                color="primary"
                data-sample="1/"
                onClick={showSample1}
              >
                Sample1
              </StyledButton>
            </Grid>
            <Grid item xs={5} sm={3} md={2}>
              <StyledButton
                variant="contained"
                color="primary"
                data-sample="2/"
                onClick={showSample2}
              >
                Sample2
              </StyledButton>
            </Grid>
            <Grid item xs={12} sm={12} md={1}>
              <h3>OR</h3>
            </Grid>
            <Grid item xs={10} sm={5} md={3}>
              <TextField
                type="file"
                color="primary"
                name="uploadedFile"
                onChange={onChangeFile}
                helperText="Please upload a FASTA file"
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <StyledButton
                style={{ marginTop: "0" }}
                variant="contained"
                color="primary"
                onClick={getUploadResult}
              >
                Submit
              </StyledButton>
            </Grid>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
            <Grid container XS={12}>
              <Grid item xs={12} md={12}>
                <Grow in={result} timeout={1500}>
                  <div>
                    <br />
                    <section>
                      <Result><strong>Result : </strong></Result>
                      {samplevalue == '1/'
                                                && (
                                                <section>
                                                  <Grid container xs={12} spacing={2} direction="row" justify="center" alignItems="center">
                                                    <Grid item xs={12} sm={12} md={5}>
                                                      <List1>
                                                        <ListItem1>
                                                          <ListItemIcon>
                                                            <ArrowForwardIosIcon />
                                                          </ListItemIcon>
                                                          <ListItemText primary={`Length of Largest Protein : ${result[0]}`} />
                                                        </ListItem1>
                                                        <ListItem1>
                                                          <ListItemIcon>
                                                            <ArrowForwardIosIcon />
                                                          </ListItemIcon>
                                                          <ListItemText primary={`Aromaticity : ${result[1]}`} />
                                                        </ListItem1>
                                                        <ListItem1>
                                                          <ListItemIcon>
                                                            <ArrowForwardIosIcon />
                                                          </ListItemIcon>
                                                          <ListItemText primary={`Isoelectric Point : ${result[2]}`} />
                                                        </ListItem1>
                                                        <ListItem1>
                                                          <ListItemIcon>
                                                            <ArrowForwardIosIcon />
                                                          </ListItemIcon>
                                                          <ListItemText primary={`Secondary Section Composition : ${result[4].toString().split(',').join(', ')}`} />
                                                        </ListItem1>
                                                        <ListItem1>
                                                          <ListItemIcon>
                                                            <ArrowForwardIosIcon />
                                                          </ListItemIcon>
                                                          <ListItemText primary={`Molecular Weight : ${result[5]}`} />
                                                        </ListItem1>
                                                      </List1>
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={5}>
                                                      <center>
                                                        <Tablesize>
                                                          {formTable(result[3])}
                                                        </Tablesize>
                                                      </center>
                                                    </Grid>
                                                  </Grid>
                                                </section>
                                                )}
                      {samplevalue == '2/'
                                                && (
                                                <section>
                                                  <Grid container xs={12} spacing={2} direction="row" justify="center" alignItems="center">
                                                    <Grid item xs={12} sm={12} md={5}>
                                                      <List1>
                                                        <ListItem1>
                                                          <ListItemIcon>
                                                            <ArrowForwardIosIcon />
                                                          </ListItemIcon>
                                                          <ListItemText primary={`Length of RNA : ${result[1]}`} />
                                                        </ListItem1>
                                                        <ListItem1>
                                                          <ListItemIcon>
                                                            <ArrowForwardIosIcon />
                                                          </ListItemIcon>
                                                          <ListItemText primary={`Length of Amino Acids : ${result[0]}`} />
                                                        </ListItem1>
                                                        <ListItem1>
                                                          <ListItemIcon>
                                                            <ArrowForwardIosIcon />
                                                          </ListItemIcon>
                                                          <ListItemText primary={`Length of Functional Proteins : ${result[2]}`} />
                                                        </ListItem1>
                                                        <ListItem1>
                                                          <ListItemIcon>
                                                            <ArrowForwardIosIcon />
                                                          </ListItemIcon>
                                                          <ListItemText primary={`Length of Largest Protein : ${result[3]}`} />
                                                        </ListItem1>
                                                        <ListItem1>
                                                          <ListItemIcon>
                                                            <ArrowForwardIosIcon />
                                                          </ListItemIcon>
                                                          <ListItemText primary={`Aromaticity : ${result[4]}`} />
                                                        </ListItem1>
                                                        <ListItem1>
                                                          <ListItemIcon>
                                                            <ArrowForwardIosIcon />
                                                          </ListItemIcon>
                                                          <ListItemText primary={`Isoelectricity : ${result[5]}`} />
                                                        </ListItem1>
                                                        <ListItem1>
                                                          <ListItemIcon>
                                                            <ArrowForwardIosIcon />
                                                          </ListItemIcon>
                                                          <ListItemText primary={`Secondary Section Composition : ${result[7].toString().split(',').join(', ')}`} />
                                                        </ListItem1>
                                                        <ListItem1>
                                                          <ListItemIcon>
                                                            <ArrowForwardIosIcon />
                                                          </ListItemIcon>
                                                          <ListItemText primary={`Molecular Weight : ${result[8]}`} />
                                                        </ListItem1>
                                                      </List1>
                                                    </Grid>
                                                    <Grid item xs={12} sm={10} md={5}>
                                                      <center>
                                                        <Tablesize>
                                                          {formTable(result[6])}
                                                        </Tablesize>
                                                      </center>
                                                    </Grid>
                                                  </Grid>
                                                </section>
                                                )}
                    </section>
                  </div>
                </Grow>
              </Grid>
            </Grid>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(GenomeFrequencyAnalysis);

const Tablesize = styled.div`
  margin-top:5%;
  width: 400px;
  @media only screen and (max-width: 900px) {
    width: 400px;
  }
  @media only screen and (max-width: 450px) {
    width: 270px;
  }
`;
const StyledTableHead = styled(TableCell)`
  background-color: #3c40af;
  color: white;
  font-weight: bold;
  z-index: 0;
`;
