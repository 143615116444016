import React, { Component } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';

import Loading from 'react-fullscreen-loading';
import 'react-toastify/dist/ReactToastify.css';

import {
  ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip,
} from 'recharts';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPause, faPlay, faStepForward, faUndo,
} from '@fortawesome/free-solid-svg-icons';
import { OpenInNew } from '@material-ui/icons';
import defaultImage from '../../assets/images/ganlab/default-image.webp';
import datasetImage from '../../assets/images/ganlab/dataset-image.webp';
import randonNoiseImage from '../../assets/images/ganlab/random-noise.webp';
import hiddenLayer5Image from '../../assets/images/ganlab/5layer.webp';
import hiddenLayer7Image from '../../assets/images/ganlab/7layer.webp';
import Navbar from '../common/Navbar';
import Footer from '../views/home/Footer';
import { PageTitle, StyledButton } from '../../styles/common';
import PresignedS3Link from '../common/PresignedS3Link';

class GanLab extends Component {
  state = {
    maxEpoch: 300,
    epochNumber: 0,
    isPlaying: false,
    gOptimizer: 'Adam',
    dOptimizer: 'Adam',
    gLearningRate: 0.01,
    dLearningRate: 0.01,
    gHiddenLayer: 7,
    dHiddenLayer: 7,
    selectedDataset: "set1",
    selectedCase: "case12",
    genLossChartDataActive: [
      { gLoss: 0 },
    ],
    disLossChartDataActive: [
      { dLoss: 0 },
    ],
    genLossChartData: [],
    disLossChartData: [],
    isLoading: false,
    errDisplayTime: 5000,
    endPoint: 'https://v1-apps.vlifevirtusa.com/SAILApplications/',
  };

  componentDidMount() {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const shopify = params.get('shopify');
    if (!(shopify || localStorage.getItem('sessData'))) {
      this.setState({ redirect: '/login?redirect=ganlab' });
    }
    window.scrollTo(0, 0);
    this.showLoader();
    axios.get(`${this.state.endPoint}loss-data?dataset=${this.state.selectedDataset}&gOptimizer=${this.state.gOptimizer}&gHiddenLayers=${this.state.gHiddenLayer}&gLearningRate=${this.state.gLearningRate}&dOptimizer=${this.state.dOptimizer}&dHiddenLayers=${this.state.dHiddenLayer}&dLearningRate=${this.state.dLearningRate}`)
      .then((res) => {
        this.setState({
          selectedDataset: res.data.datasetId,
          selectedCase: res.data.caseId.toString().toLowerCase(),
          genLossChartData: res.data.ganlossData,
          disLossChartData: res.data.dislossData,
        });
      })
      .then(() => {
        this.preloadImages();
        this.hideLoader();
      })
      .catch((error) => {
        this.hideLoader();
        toast("Unable to load data for this selection.", {
          type: toast.TYPE.ERROR,
          autoClose: this.state.errDisplayTime,
        });
      });
  }

  renderGenLossChart() {
    return (
      <ResponsiveContainer width="100%" height={185}>
        <LineChart
          margin={{
            top: 5, left: -20, right: 5, bottom: 5,
          }}
          data={this.state.genLossChartDataActive}
          fo
        >
          <Line type="monotone" dataKey="gLoss" strokeWidth={3} stroke="#007bff" dot={false} />
          <XAxis />
          <YAxis type="number" />
          <Tooltip wrapperStyle={{ fontSize: 13 }} />
        </LineChart>
      </ResponsiveContainer>
    );
  }

  renderDisLossChart() {
    return (
      <ResponsiveContainer width="100%" height={185}>
        <LineChart
          margin={{
            top: 5, left: -20, right: 5, bottom: 5,
          }}
          data={this.state.disLossChartDataActive}
          fo
        >
          <Line type="monotone" dataKey="dLoss" strokeWidth={3} stroke="#9f32b2" dot={false} />
          <XAxis />
          <YAxis type="number" />
          <Tooltip wrapperStyle={{ fontSize: 13 }} />
        </LineChart>
      </ResponsiveContainer>
    );
  }

  getDatasetBgColor(dataset) {
    return (this.state.selectedDataset === dataset) ? { backgroundColor: '#007bff' } : { backgroundColor: '#ffffff', border: '1px solid #cccccc' };
  }

  getAnimationState() {
    return (this.state.isPlaying) ? { animationPlayState: 'running' } : { animationPlayState: 'paused' };
  }

  getHiddenLayerImage(imageId) {
    return (imageId === 5) ? hiddenLayer5Image : hiddenLayer7Image;
  }

  getOutputImage(imageId) {
    try {
      return `../../assets/images/datasets/${this.state.selectedDataset}/${this.state.selectedCase}/image${imageId}.webp`;
    } catch (ex) {
      return defaultImage;
    }
  }

  getImageId() {
    return (this.state.epochNumber - 1) < 0 ? (this.state.epochNumber) : (this.state.epochNumber - 1);
  }

  preloadImages() {
    for (let i = 1; i <= this.state.maxEpoch; i++) {
      const img = new Image();
      img.src = this.getOutputImage(i);
    }
  }

  changeDataset(dataset) {
    this.resetSimulation(() => {
      this.setState({ selectedDataset: dataset });
    });
  }

  togglePlay(callback) {
    this.setState({ isPlaying: !this.state.isPlaying }, () => callback());
  }

  onPlayPressed() {
    this.showLoader();
    axios.get(`${this.state.endPoint}loss-data?dataset=${this.state.selectedDataset}&gOptimizer=${this.state.gOptimizer}&gHiddenLayers=${this.state.gHiddenLayer}&gLearningRate=${this.state.gLearningRate}&dOptimizer=${this.state.dOptimizer}&dHiddenLayers=${this.state.dHiddenLayer}&dLearningRate=${this.state.dLearningRate}`)
      .then((res) => {
        this.setState({
          selectedDataset: res.data.datasetId,
          selectedCase: res.data.caseId.toString().toLowerCase(),
          genLossChartData: res.data.ganlossData,
          disLossChartData: res.data.dislossData,
        });
      })
      .then(() => {
        this.preloadImages();
        this.hideLoader();
      })
      .then(() => {
        this.togglePlay(() => {
          if (this.state.isPlaying === true) {
            if (this.state.epochNumber === this.state.maxEpoch) {
              this.resetSimulation(() => this.togglePlay(() => {
                this.startSimulation(this.state.epochNumber);
              }));
            } else {
              this.startSimulation(this.state.epochNumber);
            }
          } else {
            this.pauseSimulation(() => { });
          }
        });
      })
      .catch((error) => {
        this.hideLoader();
        toast("Unable to load data for this selection.", {
          type: toast.TYPE.ERROR,
          autoClose: this.state.errDisplayTime,
        });
      });
  }

  startSimulation(currentEpoch) {
    if (currentEpoch < this.state.maxEpoch && this.state.isPlaying) {
      setTimeout(() => {
        this.setState({
          epochNumber: this.state.epochNumber + 1,
          genLossChartDataActive: this.state.genLossChartDataActive.concat(this.state.genLossChartData[currentEpoch]),
          disLossChartDataActive: this.state.disLossChartDataActive.concat(this.state.disLossChartData[currentEpoch]),
        }, () => {
          this.startSimulation(this.state.epochNumber);
        });
      }, 500);
    } else {
      this.pauseSimulation(() => { });
    }
  }

  pauseSimulation(callback) {
    this.setState({
      isPlaying: false,
    }, () => callback());
  }

  endSimulation(callback) {
    if (this.state.isPlaying === true) {
      this.setState({
        isPlaying: false,
      }, () => {
        setTimeout(() => {
          let add50 = this.state.epochNumber + 50;
          if (this.state.epochNumber > 250) {
            add50 = this.state.maxEpoch;
          }
          this.setState({
            isPlaying: false,
            epochNumber: add50,
            genLossChartDataActive: [{ gLoss: 0 }].concat(this.state.genLossChartData),
            disLossChartDataActive: [{ dLoss: 0 }].concat(this.state.disLossChartData),
          }, () => callback());
        }, 500);
      });
    } else {
      this.setState({
        isPlaying: false,
        epochNumber: this.state.maxEpoch,
        genLossChartDataActive: [{ gLoss: 0 }].concat(this.state.genLossChartData),
        disLossChartDataActive: [{ dLoss: 0 }].concat(this.state.disLossChartData),
      }, () => callback());
    }
  }

  resetSimulation(callback) {
    if (this.state.isPlaying === true) {
      this.setState({
        isPlaying: false,
      }, () => {
        setTimeout(() => {
          this.setState({
            isPlaying: false,
            epochNumber: 0,
            genLossChartDataActive: [{ gLoss: 0 }],
            disLossChartDataActive: [{ dLoss: 0 }],
          }, () => callback());
        }, 500);
      });
    } else {
      this.setState({
        isPlaying: false,
        epochNumber: 0,
        genLossChartDataActive: [{ gLoss: 0 }],
        disLossChartDataActive: [{ dLoss: 0 }],
      }, () => callback());
    }
  }

  changeGHiddenLayer(item) {
    this.setState({
      gHiddenLayer: item.target.value,
    }, this.resetSimulation(() => { }));
  }

  changeDHiddenLayer(item) {
    this.setState({
      dHiddenLayer: item.target.value,
    }, this.resetSimulation(() => { }));
  }

  changeGOptimizer(item) {
    this.setState({
      gOptimizer: item.target.value,
    }, this.resetSimulation(() => { }));
  }

  changeDOptimizer(item) {
    this.setState({
      dOptimizer: item.target.value,
    }, this.resetSimulation(() => { }));
  }

  changeGLearningRate(item) {
    this.setState({
      gLearningRate: item.target.value,
    }, this.resetSimulation(() => { }));
  }

  changeDLearningRate(item) {
    this.setState({
      dLearningRate: item.target.value,
    }, this.resetSimulation(() => { }));
  }

  showLoader() {
    this.setState({
      isLoading: true,
    });
  }

  hideLoader() {
    this.setState({
      isLoading: false,
    });
  }

  render() {
    return (
      <Container>
        {
          this.state.redirect && (
            <Redirect to={this.state.redirect} />
          )
        }
        <Navbar />
        <section className="descContainer">
          <PageTitle>Ganlabs</PageTitle>

          <section>
            <p>
              GAN Lab: An Interactive, Visual Experimentation Tool for Generative Adversarial Networks.The tool takes in real images as input and produces synthetically generated images that closely resemble the original sample
            </p>
            <p>
              <b>How does GAN works ?</b>
              <p>
                A generative adversarial network (GAN) is an effective type of generative model produces realistic images from a small piece of input. Randomness is used as an ingredient.
              </p>
              <p>
                There are 2 competitive networks: a generator and a discriminator. The generator tries to create random synthetic outputs (for instance, images of faces), while the discriminator tries to tell these apart from real outputs (say, a database of celebrities). After each epoch , they both get better and better—with the end result being a generator network that produces realistic outputs
              </p>
            </p>
            <Grid container>
              <Grid item xs={12} md={6}>
                <div>
                  <b>About the tool</b>
                  <ul>
                    <li>Dataset used: Human Chest X-Ray images</li>
                    <li>Buttons to start, stop and reset the tool  are provided</li>
                    <li>The tool can be configured to set the number of hidden layers in both generator and discriminator (5 or 7).</li>
                    <li>The learning rate can be chosen as 0.01 or 0.001.</li>
                    <li>The tool uses Adam optimizer but can be configured to use SGD as well</li>
                    <li>The tool is limited to run for 300 epochs.</li>
                    <li>The generation of synthetic images can be seen along with the dynamic generator’s and discriminator’s loss values generated after each epoch</li>
                  </ul>
                </div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div>
                  <b>Why GANLabs tool ? </b>
                  <ul>
                    <li>The tool can create a large set of synthetic images from few images. These images can be further used as augmented training dataset in areas suffering from lack of sufficient data</li>
                    <li>The tool can be useful in cases when original dataset is difficult to retrieve or when there are data security / privacy concerns. In that case synthetic data can be used.</li>
                    <li>The tool gives the option to generate data at different parameters and lets the user control quality of output based on the loss values.  </li>
                  </ul>
                </div>
              </Grid>
            </Grid>
            <StyledButton
              variant="outlined"
              color="primary"
              size="large"
              startIcon={<OpenInNew />}
            >
              <a
                href="https://material.vlifevirtusa.com/DeepFit/Ganlab.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                Notebook
              </a>
            </StyledButton>

          </section>
        </section>
        <div className="container-fluid main-container">
          <div className="row controller-bar">
            <div className="col-4 selection-container">
              <div className="selection-container-inner">
                <div className="selection-label">Dataset</div>
                <div className="selection-items-container">
                  <button style={this.getDatasetBgColor("set1")} className="btn-boot btn-default" onClick={() => this.changeDataset("set1")}>
                    <img src={datasetImage} className="img" alt="set1" width="35" height="35" />
                  </button>
                </div>
              </div>
            </div>
            <div className="col-4 controller-container">
              <button className="btn-boot btn-primary ctrl-btn-small m-2" onClick={() => this.resetSimulation(() => { })}>
                <FontAwesomeIcon icon={faUndo} />
              </button>
              <button className="btn-boot btn-primary ctrl-btn-play m-2" onClick={() => this.onPlayPressed()}>
                <FontAwesomeIcon icon={this.state.isPlaying ? faPause : faPlay} />
              </button>
              <button className="btn-boot btn-primary ctrl-btn-small m-2" onClick={() => this.endSimulation(() => { })}>
                <FontAwesomeIcon icon={faStepForward} />
              </button>
            </div>
            <div className="col-4 epoch-container">
              <div className="epoch-container-inner">
                <div className="epoch-label">Epoch</div>
                <div className="epoch-counter">{this.state.epochNumber}</div>
              </div>
            </div>
          </div>
          <div className="row working-container">
            <div className="col-7">
              <div className="section-title">MODEL OVERVIEW</div>
              <div className="outer-visualization-container">
                <svg className="visualization-svg">
                  <path style={this.getAnimationState()} d="M77,260 L125,260" />
                  <path style={this.getAnimationState()} d="M477,165 L525,165" />
                  <path style={this.getAnimationState()} d="M278,165 L323,165" />
                  <path style={this.getAnimationState()} d="M278,260 L323,260" />
                  <path style={this.getAnimationState()} d="M477,260 L525,260" />
                  <path style={this.getAnimationState()} d="M640,260 C690,265 640,165 690,165" />
                  <path
                    style={this.getAnimationState()}
                    d="M627,165 L713,165 a10,10 0 0 0 10,-10 L723,40 a10,10 0 0 0 -10,-10 L455,30 a10,10 0 0 0 -10,10 L445,135"
                  />
                  <path
                    style={this.getAnimationState()}
                    d="M627,260 L675,260 a10,10 0 0 1 10,10 L685,485 a10,10 0 0 1 -10,10 L254,495 a10,10 0 0 1 -10,-10 L244,288"
                  />
                </svg>
                <div className="visualization-container">

                  <div className="noise-label">Random Noise</div>
                  <div className="noise-container">
                    <img className="default-image" src={randonNoiseImage} alt="placeholder" />
                  </div>
                  <div className="generator-label">Generator</div>
                  <div className="generator-container">
                    <img className="default-image" src={this.getHiddenLayerImage(this.state.gHiddenLayer)} alt="placeholder" />
                  </div>
                  <div className="generator-selectors">
                    <div className="form-group">
                      <label className="custom-selector-label">Optimizer</label>
                      <select className="form-control custom-selector" id="d-1" onChange={this.changeGOptimizer.bind(this)}>
                        <option className="Adam" defaultValue>Adam</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label className="custom-selector-label">Learning Rate</label>
                      <select className="form-control custom-selector" id="d-2" onChange={this.changeGLearningRate.bind(this)}>
                        <option value="0.001" defaultValue>0.001</option>
                        <option value="0.01">0.01</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label className="custom-selector-label">Hidden Layer(s)</label>
                      <select className="form-control custom-selector" id="d-3" onChange={this.changeGHiddenLayer.bind(this)}>
                        <option value="7">7</option>
                        <option value="5">5</option>
                      </select>
                    </div>
                  </div>
                  <div className="discriminator-label">Discriminator</div>
                  <div className="discriminator-container">
                    <img className="default-image" src={this.getHiddenLayerImage(this.state.dHiddenLayer)} alt="placeholder" />
                  </div>
                  <div className="discriminator-selectors">
                    <div className="form-group">
                      <label className="custom-selector-label">Optimizer</label>
                      <select className="form-control custom-selector" id="d-1" onChange={this.changeDOptimizer.bind(this)}>
                        <option value="Adam" defaultValue>Adam</option>
                        <option value="Sgd">SGD</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label className="custom-selector-label">Learning Rate</label>
                      <select className="custom-selector form-control " id="d-2" onChange={this.changeDLearningRate.bind(this)}>
                        <option value="0.01" defaultValue>0.01</option>
                        <option value="0.001">0.001</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label className="custom-selector-label">Hidden Layer(s)</label>
                      <select className="custom-selector form-control " id="d-3" onChange={this.changeDHiddenLayer.bind(this)}>
                        <option value="7">7</option>
                        <option value="5">5</option>
                      </select>
                    </div>
                  </div>
                  <div className="g-loss-label">G Loss</div>
                  <div className="g-loss-container">
                    <div id="gLossValue" className="loss-value">{this.state.genLossChartDataActive[this.state.epochNumber].gLoss}</div>
                  </div>
                  <div className="d-loss-label">D Loss</div>
                  <div className="d-loss-container">
                    <div id="dLossValue" className="loss-value">{this.state.disLossChartDataActive[this.state.epochNumber].dLoss}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-5">
              <div className="">
                <div className="section-title">DISTRIBUTIONS</div>
                <div className="">
                  <div className="output-image-container">
                    <img className="output-image" src={this.getOutputImage(this.getImageId())} alt="Output" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="horizontal-seperator" />
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="section-title">G LOSS</div>
                  <div className="output-chart-container">
                    {this.renderGenLossChart()}
                  </div>
                </div>
                <div className="col-6">
                  <div className="section-title">D LOSS</div>
                  <div className="output-chart-container">
                    {this.renderDisLossChart()}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="horizontal-seperator" />
          </div>
          <p>
            Sample Images Generated After Running GAN
            <a href="https://vlife.virtusa.com/GAN-Images/Gan_images.zip" target="_blank" rel="noopener noreferrer">Click Here</a>
          </p>
          <ToastContainer />
          <Loading loaderColor="#165baa" loading={this.state.isLoading} />
        </div>
      </Container>

    );
  }
}

export default GanLab;
const Container = styled.section`
body {
  padding-right: 0 !important;
    overflow: auto !important;
}

.descContainer {
  padding: 50px;
  background: #ffffff;
}

.MuiPopover-root {
  margin-top: 10px !important;
}

.controller-bar {
    background-color: #f8f8f8;
    border: solid 1px #cccccc 
}
.controller-container {
    text-align: center;
}

.selection-container {
    text-align: left;
}

.epoch-container {
    text-align: right;
}

.ctrl-btn-play {
    width: 70px;
    height: 70px;
    border-radius: 35px;
    font-size: 25px;
}

.ctrl-btn-play:focus, .ctrl-btn-play:active {
    outline: none !important;
    box-shadow: none;
}

.ctrl-btn-small {
    background-color: #f8f8f8;
    border: #f8f8f8 1px solid;
    color:#007bff;
    font-size: 25px;
    width: 50px;
    height: 50px;
}

.ctrl-btn-small:hover {
    background-color: #e8e8e8;
    
    
    font-size: 25px;
}

.ctrl-btn-small:focus, .ctrl-btn-small:active {
    outline: none !important;
    box-shadow: none;
}

.epoch-container-inner {
    width: 100%;
    text-align: left;
    padding: 10px;
}

.epoch-label {
    font-size: 16px;
    font-weight: bold;
}

.epoch-counter {
    font-size: 30px;
    font-weight: bold;
    color: #7f7f7f;
    height: 45px;
}

.selection-container-inner {
    width: 100%;
    text-align: right;
    padding: 10px;
}

.selection-label {
    font-size: 16px;
    font-weight: bold;
}

.selection-items-container {
    width: 100%;
    height: 45px;
}

.selection-btn-image{
    padding: 2px;
    width: 43px;
    height: 43px;
    margin-top: 5px;
    margin-left: 10px;
}


/* SVG */

.outer-visualization-container {
    position: relative;
    height: 500px;
    width: 750px;
    margin: auto;
}

.visualization-svg,
.visualization-container {
  height: 500px;
  position: absolute;
  width: 750px;
}

.visualization-svg {
  pointer-events: none;
}

.visualization-svg path {
  fill: none;
  stroke: #165baa;
  stroke-dasharray: 10,2;
  stroke-width: 3;

  animation: dash 10s linear infinite forwards;
  -webkit-animation-play-state: paused;
  -moz-animation-play-state:paused;
  -o-animation-play-state:paused; 
  animation-play-state:paused;
}


@keyframes dash {
  /* from {
    stroke-dashoffset: 36000px;
  } */
  to {
    stroke-dashoffset: -360;
  }
}

.generator-container {
    position: absolute;
    width: 150px;
    height: 150px;
    background-color: #f8f8f8;
    border: 2px solid;
    border-color: #165baa;
    top: 137px;
    left: 125px;
}

.discriminator-container {
    position: absolute;
    width: 150px;
    height: 150px;
    background-color: #f8f8f8;
    border: 2px solid;
    border-color: #165baa;
    top: 137px;
    left: 325px;
}

.d-loss-container {
    position: absolute;
    width: 110px;
    height: 50px;
    background-color: #f8f8f8;
    border: 2px solid;
    border-color: #165baa;
    top: 233px;
    left: 525px;
}

.g-loss-container {
    position: absolute;
    width: 110px;
    height: 50px;
    background-color: #f8f8f8;
    border: 2px solid;
    border-color: #165baa;
    top: 138px;
    left: 525px;
}

.noise-container {
    position: absolute;
    width: 50px;
    height: 50px;
    background-color: #f8f8f8;
    border: 2px solid;
    border-color: #165baa;
    top: 235px;
    left: 25px;
}

.noise-label {
  position: absolute;
  color: #165baa;
  font-weight: bold;
  font-size: 12px;
  top: 217px;
  left: 25px;
  width: 100px;
  height: 25px;
}

.generator-label {
  position: absolute;
  color: #165baa;
  font-weight: bold;
  font-size: 12px;
  top: 120px;
  left: 125px;
  width: 150px;
  height: 25px;
}

.discriminator-label {
  position: absolute;
  color: #165baa;
  font-weight: bold;
  font-size: 12px;
  top: 120px;
  left: 325px;
  width: 150px;
  height: 25px;
}

.d-loss-label {
  position: absolute;
  color: #165baa;
  font-weight: bold;
  font-size: 12px;
  top: 215px;
  left: 525px;
  width: 150px;
  height: 25px;
}

.g-loss-label {
  position: absolute;
  color: #165baa;
  font-weight: bold;
  font-size: 12px;
  top: 120px;
  left: 525px;
  width: 150px;
  height: 25px;
}

.loss-value{
  position: relative;
  color: #165baa;
  font-weight: bold;
  font-size: 12px;
  width: auto;
  height: auto;
  text-align: center;
  margin-top: 15px;
}

.discriminator-selectors {
  position: absolute;
  width: 150px;
  height: 150px;
  top: 290px;
  left: 325px;
}

.generator-selectors {
  position: absolute;
  width: 150px;
  height: 150px;
  top: 290px;
  left: 125px;
}

.custom-selector {
  position: relative;
  height: 30px !important;
  width: 75px;
  margin-top: 2px;
  margin-bottom: 5px;
  font-size: 10px;
}

.custom-selector-label {
  font-size: 10px;
  margin-bottom: 0px;
}

.form-group {
  margin-bottom: 0px;
}

.default-image {
    position: relative;
    width: 100%;
}

/* end SVG */

.vertical-left-border {
    border-left: 1px solid #cccccc;
}

.horizontal-seperator {
    background-color: #cccccc;
    width: 100%;
    height: 1px;
    margin: 0px;
}

.output-image-container, .output-image {
    width: auto;
    height: 270px;
    margin-bottom: 15px;
}

.output-chart-container, .output-chart {
    width: 100%;
    height: 170px;
    margin-bottom: 15px;
}

.section-title {
    padding: 20px 0px;
    font-weight: bold;
    padding-left: 15px;

}

.recharts-cartesian-axis-tick {    
    font-size: 7px;
}

.Toastify__toast-body {
  font-size: 14px;
  padding-left: 10px;
}

.loading-background {
  background-color: rgba(255, 255, 255, 0.5);
}

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -15px;
}
.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 95%;
  margin-right: auto;
  margin-left: auto;
}

.btn-boot {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.m-2 {
  margin: 0.5rem !important;
}

/* .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
} */



.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.form-group {
  margin-bottom: 1rem;
}

.form-control {
  display: block;
  /* width: 100%; */
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  /* font-size: 1rem; */
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.img {
  vertical-align: middle;
  border-style: none;
  padding: 2px;
}
.rsc-float-button {
  bottom: 60px;
  right: 20px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0px solid green;
  -webkit-text-fill-color:#fff;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}
`;
