import React, { memo, useState } from "react";
import {
  Grid,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormControl,
  Typography,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { faInfoCircle, faThumbsDown } from "@fortawesome/free-solid-svg-icons";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { csv } from "d3";

// import local components
import styled from "styled-components";
import Collapse from "../../common/Collapse";
import { Paragraph, StyledButton, Result, LinkContainer } from "../../../styles/common";
import CommonService from "../../../utils/services/CommonService";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import Spinner from "../../common/loading/Spinner";

// images
import sinGan_sol1 from "../../../assets/images/app/sinGan_sol1.webp";
import sinGan_sol2 from "../../../assets/images/app/sinGan_sol2.webp";
import sinGan_sol3 from "../../../assets/images/app/sinGan_sol3.webp";
import sinGan_sample1 from "../../../assets/images/app/sinGan_sample1.webp";
import MailSent from "../../../assets/images/app/mailSent.svg";
import ZoomImage from "../../common/ZoomImage";

const SinGAN = () => {
  const initialData = {
    epochCount: 100,
    imageCount: 100,
    isError: false,
  };

  const [showUpload, setShowUpload] = useState(false);
  const [sampleImage, setSampleImage] = useState(false);
  const [sampleOutput, setSampleOutput] = useState(false);
  const [parameters, setParameters] = useState(initialData);
  const [disableBtn, setDisableBtn] = useState(false);
  const [mailSent, setMailSent] = useState("");
  const [uploadMetaData, setUploadMetaData] = useState("");
  const [uploadImgUrl, setUploadImgUrl] = useState("");
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");
  const [imageZoom, setImageZoom] = useState(true);
  const [metaDataFile, setMetaDataFile] = useState("");
  const [singanOutput, setSinganOutput] = useState("");
  const [selectedSample, setSelectedSample] = useState("");
  const [showSinganOutput, setShowSinganOutput] = useState("");
  const [metaData, setMetaData] = useState("");
  const [outputData, setOutputData] = useState("");

  const { getQueryResult, uploadFilesWithBody } = CommonService("z1dApps", "sinGan");

  const uploadImage = () => {
    const { uploadedImage, userName, userMailId } = parameters;
    if (!userMailId) {
      setNotification({ open: "error", message: "Please Enter Mail ID" });
      return;
    }
    let areValuesEmpty = false;
    Object.keys(parameters).forEach((key) => {
      if (parameters[key] === "") {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);
      setUploadMetaData("");
      setUploadImgUrl("");

      uploadFilesWithBody("upload", { file: uploadedImage }, `${userName},${userMailId}`)
        .then((res) => {
          setSpinner(false);
          if (res.status === 400) {
            setNotification({ open: "error", message: res.message });
          } else {
            setUploadImgUrl(res.uploaded_image);
            setNotification({ open: "success", message: "Image Upload success" });
          }
        })
        .catch((err) => {
          setSpinner(false);
          console.log(err);
        });
    }
  };

  const loadSample1Data = () => {
    setSampleImage("https://vlife.virtusa.com/Metadata/Sample1/kneeclr.jpg");
    setMetaDataFile("md_sample1.csv");
    setSinganOutput("md_singan_1.csv");
    setSelectedSample("Sample1");
    setMetaData("");
    setShowSinganOutput(false);
    setShowUpload(false);
  };

  const loadSample2Data = () => {
    setSampleImage("https://vlife.virtusa.com/Metadata/Sample2/leg.jpg");
    setMetaDataFile("md_sample2.csv");
    setSinganOutput("md_singan_2.csv");
    setSelectedSample("Sample2");
    setMetaData("");
    setShowSinganOutput(false);
    setShowUpload(false);
  };

  const showOutput = async () => {
    const data = await parseCSV(singanOutput);
    setShowSinganOutput(true);
    setOutputData(data);
  };

  const onChangeFields = (e) => {
    const { name } = e.target;
    const value = (e.target.files && e.target.files[0]) || e.target.value;
    setParameters({
      ...parameters,
      [name]: value,
    });
  };

  const zoomImage = (e) => {
    setImageZoom(e.target.src);
    console.log(e.target.src);
  };

  const handleModel = (open) => {
    setImageZoom(open);
  };

  const showMetaData = async () => {
    const data = await parseCSV(metaDataFile);
    console.log(data);
    setMetaData(data);
  };

  const parseCSV = (fileName) => csv(`../../assets/files/${fileName}`).then((data) => data);

  const getUploadMetaData = () => {
    const { userMailId } = parameters;
    setSpinner(true);
    setMailSent(false);
    setUploadMetaData("");

    getQueryResult("getMetaData", userMailId)
      .then((res) => {
        const previewData = JSON.parse(res.metadata);
        const { fields } = previewData.schema;
        const dataRows = previewData.data;
        setUploadMetaData({ fields, dataRows });
        setNotification({
          open: "success",
          message: "MetaData Loaded",
        });
        setSpinner(false);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({
          open: "error",
          message: err.message || "MetaData Loaded",
        });
      });
  };

  const showUploadClick = () => {
    setShowUpload(true);
    setSampleImage(false);
  };

  const formTable = (data) => (
    <Paper>
      <SinganTable>
        <Table>
          <TableHead>
            <TableRow>
              {Array.isArray(data)
                && data.columns.map((field) => (
                  <StyledTableHead key={field} align="left">
                    {field}
                  </StyledTableHead>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(data)
              && data.map((row) => (
                <TableRow key={row.index}>
                  {data.columns.map((field) => (
                    <TableCell key={field} align="left">
                      {row[field]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </SinganTable>
    </Paper>
  );
  const sendResultToMail = () => {
    const { userMailId, userName } = parameters;
    setSpinner(true);
    setMailSent(false);
    let isError = false;
    getQueryResult("getSinganResult", `${userName},${userMailId}`)
      .catch((err) => {
        setSpinner(false);
        isError = true;
        setNotification({
          open: "error",
          message: err.message || "Uploaded image not supported",
        });
      });
    setTimeout(() => {
      setSpinner(false);
      !isError && setMailSent('We will send you a mail within 5 hours with the link to download the generated synthetic images');
      setDisableBtn(true);
    }, 5000);
  };
  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Grid item xs={12} md={12}>
          <Paragraph>
            <p>
              It is a known fact that the quality and quantity of dataset used
              to train a Machine Learning model will have a direct effect on the
              model’s performance. Availability of an extensive, versatile
              dataset could seal the deal and one can start with building
              Machine learning/ Deep Learning Pipeline. But there are situations
              where either the datasets are readily not available or the
              datasets that are present is not enough to train a heavy duty
              model. One way of overcoming this challenge is Data Augmentation.
              Data augmentation is a great way to increase the variety of the
              dataset that the model can see, but again, there is a limit to how
              much it can help. The best solution to this challenge is a
              technique called Synthetic Data Genreation.
            </p>
            <p>
              The process of generating any kind of data synthetically or
              Artificially via programming is called Synthetic Data Generation.
              The data that can be used in these techniques can be images and
              text. They are mainly used in the business field where Privacy,
              Training Products and Testing Products are the key reasons to use.
              It is a booming technology that is being used by various
              businesses from online to offline business. One of the best
              examples of Synthetic Data Generation is GANs (Generative
              Adversarial Networks) which uses the Generator-Discriminator
              technique to generate images that are very realistic.
            </p>
            <p>
              <b>SinGAN is</b>
              {' '}
              a variant of GAN in which the network has to
              learn from a single natural image, in contrast to previous
              versions of GANs as they have multiple real images to learn
              representations. SinGAN is an unconditional generative model that
              can learn internal statistics of patches within a single image.
              SinGAN shows impressive results on various image manipulation
              tasks.
            </p>
            <ResponsiveTypography variant="h6" gutterBottom>
              <FontAwesomeIcon icon={faInfoCircle} />
              {' '}
              Architecture
            </ResponsiveTypography>
            <p>
              SinGAN architecture consists of a multi-scale pipeline, in which a
              pair of generator and discriminator are present, learning
              representations at different scales. They can be trained in a
              coarse-to-fine fashion, where generator and discriminator at the
              lowest scale learn coarse features like background and stuff,
              whereas at high scales, they learn very fine details like edges
              and corners.
            </p>
            <center>
              <img width="70%" src={sinGan_sol1} alt="" />
            </center>
            <p>
              To know more about the architecture and details of SinGAN, please
              refer this paper: &nbsp;
              <a href="https://arxiv.org/pdf/1905.01164.pdf" target="_blank" rel="noopener noreferrer">
                Click Here
              </a>
            </p>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <p> Below are the steps involved :</p>
                <ZoomImage width="100%" src={sinGan_sol2} />
              </Grid>
              <Grid item md={6}>
                <p>
                  The Metadata that can be extracted from the Exif tool in vLife
                  are listed below:
                </p>
                <ZoomImage width="100%" src={sinGan_sol3} />
              </Grid>
            </Grid>
            <p>
              The tool provides BYOD (Bring Your Own Data) feature to the users
              where the user can upload the Image that needs to be synthetically
              generated. The Rutime for SinGAN is minimum 3 hrs. Hence, the
              Synthetically Generated Images will be sent to the User in his
              Email id within 24 hrs.
            </p>
            <p>
              2 Sample images have been provided for reference for which the
              Synthetic Images are also generated. Click on Sample Button and
              Execute SinGAN to see the Synthetic Generated Images along with
              their Metadata that has been extracted out using our Exif Tool.
            </p>
          </Paragraph>
        </Grid>
        <LinkContainer>
          <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/tamarott/SinGAN/tree/master/Input"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/tamarott/SinGAN"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <section>
          <div>
            <center>
              <StyledButton
                variant="contained"
                color="secondary"
                onClick={loadSample1Data}
              >
                Sample 1
              </StyledButton>
              <StyledButton
                variant="contained"
                color="secondary"
                onClick={loadSample2Data}
              >
                Sample 2
              </StyledButton>
              <StyledButton
                variant="contained"
                color="secondary"
                onClick={showUploadClick}
              >
                Upload
              </StyledButton>
              <br />
            </center>
          </div>
        </section>
        {showUpload && (
          <section>
            <br />
            <Grid
              container
              xs={12}
              spacing={2}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={12} md={4} alignItems="center">
                <img
                  width="100%"
                  src={sinGan_sample1}
                  alt=""
                />
              </Grid>
              <Grid item xs={12} md={2} alignItems="center">
                <FormControl minWidth="80" varient="outlined" fullWidth>
                  <TextField
                    label="UserName"
                    autoComplete="off"
                    type="text"
                    variant="outlined"
                    name="userName"
                    value={parameters.userName}
                    onChange={onChangeFields}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={2} alignItems="center">
                <FormControl minWidth="80" varient="outlined" fullWidth>
                  <TextField
                    label="Mail Id"
                    autoComplete="off"
                    type="text"
                    variant="outlined"
                    name="userMailId"
                    value={parameters.userMailId}
                    onChange={onChangeFields}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3} alignItems="center">
                <FormControl minWidth="80" varient="outlined" fullWidth>
                  <TextField
                    type="file"
                    variant="outlined"
                    name="uploadedImage"
                    color="primary"
                    onChange={onChangeFields}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={1} alignItems="center">
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={uploadImage}
                  disabled={disableBtn}
                >
                  Execute
                </StyledButton>
              </Grid>
            </Grid>
            {uploadImgUrl && (
              <section>
                <br />
                <center>
                  <ImageBorder src={uploadImgUrl} width="300px" alt="" />
                  <br />
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={getUploadMetaData}
                    disabled={disableBtn}
                  >
                    Extract MetaData
                  </StyledButton>
                </center>
                {uploadMetaData && (
                  <section>
                    <br />
                    <SinganTable>
                      <Table>
                        <TableHead>
                          <TableRow>
                            {uploadMetaData.fields.map((field) => (
                              <StyledTableHead key={field.name} align="left">
                                {field.name}
                              </StyledTableHead>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {uploadMetaData.dataRows.map((row) => (
                            <TableRow key={row.index}>
                              {uploadMetaData.fields.map((field) => (
                                <TableCell align="left">
                                  {field.name === "index"
                                    ? row[field.name] + 1
                                    : row[field.name]}
                                </TableCell>
                              ))}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </SinganTable>
                    <br />
                    <center>
                      <StyledButton
                        variant="contained"
                        color="primary"
                        onClick={sendResultToMail}
                        disabled={disableBtn}
                      >
                        Execute SinGan
                      </StyledButton>
                    </center>
                    {mailSent && (
                      <center>
                        <img
                          width="200px"
                          height="200px"
                          src={MailSent}
                          alt=""
                        />
                        <Typography variant="h5" display="block">
                          Thank you for your Interest
                        </Typography>
                        <Typography
                          variant="h6"
                          display="block"
                          color="primary"
                        >
                          {mailSent}
                        </Typography>
                      </center>
                    )}
                    {" "}
                    <br />
                  </section>
                )}
              </section>
            )}
          </section>
        )}
        {sampleImage && (
          <section>
            <br />
            <center>
              <ImageBorder src={sampleImage} alt="" />
              <br />
              <StyledButton
                variant="contained"
                color="primary"
                onClick={showMetaData}
              >
                Extract MetaData
              </StyledButton>
            </center>
            {metaData && (
              <section>
                <br />
                {formTable(metaData)}
                <br />
                <center>
                  <StyledButton
                    variant="contained"
                    color="primary"
                    onClick={showOutput}
                  >
                    Execute SinGan
                  </StyledButton>
                </center>
                <br />
                {showSinganOutput && (
                  <section>
                    <br />
                    <Grid
                      container
                      xs={12}
                      direction="row"
                      justify="center"
                      alignItems="center"
                      spacing={3}
                    >
                      {[0, 1, 2, 3, 4, 5].map((num) => (
                        <Grid item xs={12} sm={6} md={4}>
                          <ImageBorder
                            src={`https://vlife.virtusa.com/Metadata/${selectedSample}/${num}.jpg `}
                            width="80%"
                            alt=""
                          />
                          <br />
                          <BlueText>
                            <strong>
                              Synthetic Image
                              {num + 1}
                            </strong>
                          </BlueText>
                        </Grid>
                      ))}
                    </Grid>
                    <br />
                    {formTable(outputData)}
                  </section>
                )}
              </section>
            )}
          </section>
        )}
        <br />

        <section>
          {showSpinner && <Spinner text="Loading..." />}
        </section>
      </Collapse>
      <zoomImgModal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={imageZoom}
        onClose={() => handleModel()}
      >
        <img width="100%" height="100%" src={imageZoom} alt="" />
      </zoomImgModal>
    </SolutionContainer>
  );
};

export default memo(SinGAN);

const ImageBorder = styled.img`
  border-radius: 5px;
  width: 100%;
  max-width: 300px;

`;

const SinganTable = styled.div`
 height:350px;
 overflow-x: hidden;
 overflow-y: hidden;
`;

const BlueText = styled.p`
color= #6154dd; 
`;

const zoomImgModal = styled(Modal)`
z-index= 5;
position= absolute;
background= black;
width= 50%;
height= 50%;
background-color= 'rgb(0,0,0)';
background-color= 'rgba(0,0,0, 0.9)';  
padding= 40px;
margin-left= 30px;
transition= 200ms all cubic-bezier(.4,0,.2,1);
`;

const StyledRadioGroup = styled(RadioGroup)`
  display: block;
`;

const ResponsiveTypography = styled(Typography)`
  @media only screen and (max-width: 900px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 450px) {
    font-size: 15px;
  }
`;
const StyledTableHead = styled(TableCell)`
  background-color: #3c40af;
  color: white;
  @media only screen and (max-width: 900px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 450px) {
    font-size: 12px;
  }
`;
