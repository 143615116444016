import React, { memo, useState, useEffect } from "react";
import styled from "styled-components";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Grid, Paper } from "@material-ui/core";
import CommonService from "../../../utils/services/CommonService";

// local components

import Collapse from "../../common/Collapse";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";

// image components
import stressDetectionUsingHRV_sample1 from "../../../assets/images/app/stressDetectionUsingHRV_sample1.webp";
import stressDetectionUsingHRV_sample2 from "../../../assets/images/app/stressDetectionUsingHRV_sample2.webp";
import stressDetectionUsingHRV_sample3 from "../../../assets/images/app/stressDetectionUsingHRV_sample3.webp";
import PresignedS3Link from "../../common/PresignedS3Link";

const StressDetectionUsingHRV = () => {
  const [dataRows, setDataRows] = useState("");
  const [fields, setFields] = useState("");
  const [notification, setNotification] = useState("");
  const [sample1Res, setSample1Res] = useState("");
  const [sample2Res, setSample2Res] = useState("");

  const tableData = [
    ["SDRR", "ms", "Standard Deviation of R-R intervals"],
    [
      "pNN50",
      "%",
      "Percentage of adjacent R-R intervals that differ by more than 50 milliseconds",
    ],
    ["RMSSD", "ms", "Root mean square of successive R-R interval differences"],
    ["HF", "Ms^2/Hz/nu", "High frequency power"],
    ["LF", "Ms^2/Hz/nu", "Low frequency power"],
    ["SD1", "ms", "Standard deviation of width"],
    ["SD2", "ms", "Standard deviation of length"],
    ["VLF", "Hz", "Power of very low frequency range"],
    ["sampen", "", "SampEn: Sample Entropy"],
  ];
  const sample1Data = [
    ["no stress"],
    ["time pressure"],
    ["no stress"],
    ["no stress"],
    ["interruption"],
    ["time pressure"],
    ["interruption"],
    ["no stress"],
    ["interruption"],
    ["no stress"],
  ];

  const sample1 = () => {
    setSample1Res(true);
    setSample2Res(false);
  };

  const { getQueryResult } = CommonService(
    "payerProvider",
    "stressDetectionUsingHRV",
  );

  useEffect(() => {
    getQueryResult("output")
      .then((result) => {
        const previewData = JSON.parse(result);
        const { fields } = previewData.schema;
        const dataRows = previewData.data;
        setFields(fields);
        setDataRows(dataRows);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Heart rate variability (HRV) is the physiological phenomenon of
            variation in the time interval between heartbeats. It is measured by
            the variation in the beat-to-beat interval. Measurement of HRV for
            use in monitoring training and recovery involves analysis of the
            heart’s beat-to-beat variation. By accurately measuring the time
            interval between heartbeats, the detected variation can be used to
            measure the psychological and physiological stress and fatigue on
            the body during training. Heart rate variability is the variance in
            time between the beats of your heart. So, if one’s heart rate is 60
            beats per minute, it is not actually beating once every second.
            Within that minute there may be 0.9 seconds between two beats, for
            example, and 1.15 seconds between two others. The greater this
            variability is, the more “ready” the body is to execute at an
            elevated level.These periods of time between successive heart beats
            are known as
            {' '}
            <b>RR intervals</b>
            {' '}
            (named for the heartbeat’s R-phase,
            the spikes seen on an ECG), measured in milliseconds.
          </p>
          <center>
            <img src={stressDetectionUsingHRV_sample1} alt="" />
          </center>
          <p>
            <b>About Dataset:</b>
          </p>

          <p>
            In this application dataset of heart rate variability (HRV) indices
            computed from the multimodal SWELL knowledge work (SWELL-KW) dataset
            for research on stress and user modelling. The SWELL was collected
            by researchers at the Institute for Computing and Information
            Sciences at Radboud University. It is a result of experiments
            conducted on 25 subjects doing typical office work (for example
            drafting reports, making presentations, reading e-mail, and
            searching for information). The subject went through typical working
            stressors such as receiving unexpected emails interruptions and
            pressure to complete their work on time. The experiment recorded
            various data including computer logging, facial expression, body
            postures, ECG signal, and skin conductance. The researchers also
            recorded the subjects’ subjective experience on task load, mental
            effort, emotion, and perceived stress. Each participant went through
            three different working conditions:
            <ul>
              <li>
                <b>no stress:</b>
                {' '}
                the subjects are allowed to work on the tasks
                as long as they needed for a maximum of 45 minutes, but they are
                not aware of the maximum duration of their tasks.
              </li>
              <li>
                <b>time pressure:</b>
                {' '}
                during this time, the time to finish the
                task was reduced to 2/3 of the time the participant took in the
                neutral condition.
              </li>
              <li>
                <b>interruption:</b>
                {' '}
                the participants received eight emails in
                the middle of their assigned tasks. Some emails were relevant to
                their tasks —and the participant was requested to take specific
                actions—while others were just irrelevant to their tasks.
              </li>
            </ul>
          </p>
          <p>Refer Sample DATA below:</p>
          {fields && (
            <ScrollableTable>
              <Table>
                <TableHead>
                  <TableRow>
                    {fields.map((field) => (
                      <StyledTableHead1 key={field.name}>
                        {field.name}
                      </StyledTableHead1>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(dataRows)
                    && dataRows.map((row) => (
                      <TableRow key={row.index}>
                        {fields.map((field) => (
                          <TableCell align="left">
                            {field.name === "index"
                              ? row[field.name] + 1
                              : row[field.name]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </ScrollableTable>
          )}
          <p>
            The Data includes the statistical values for RR including SD, Kurt,
            Mean,
          </p>
          <center>
            <img src={stressDetectionUsingHRV_sample2} alt="" />

            <Grid container item xs={8}>
              <Paper>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableHead>Abbreviation</StyledTableHead>
                      <StyledTableHead>Units</StyledTableHead>
                      <StyledTableHead>Description</StyledTableHead>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData.map((row, index) => (
                      <TableRow key={index}>
                        {row.map((field) => (
                          <TableCell align="left">{field}</TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </center>
          <p>
            <b>HRV Computation:</b>
          </p>
          <p>
            HRV indices were computed as follows: First, by extracting an
            inter-beat interval (IBI) signal from the peaks of the
            Electrocardiography (ECG) of each subject. Then, each HRV index is
            computed on a 5 minutes IBI array. A new IBI sample is appended to
            the IBI array while the oldest IBI sample is removed from its
            beginning. The new resulting IBI array is used to compute the next
            HRV index. This process is repeated until the end of the entire IBI
            signal.
          </p>
          <p>
            <b>Application:</b>
          </p>
          <p>
            Application can be used to provide a real-time biofeedback from the
            wearable when a person undergoes stress. An alert can be sent in the
            form of a notification on the iPhone to prompt the user to use a
            meditation app, take rest or play a calm song through google home
            automatically. The data can also be recorded and be displayed using
            an app.
          </p>
          <center>
            <img src={stressDetectionUsingHRV_sample3} alt="" />
          </center>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
          <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://www.kaggle.com/datasets/qiriro/swell-heart-rate-variability-hrv"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/realmichaelye/Stress-Prediction-Using-HRV"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <section>
            <Grid container justify="center">
              <Grid xs={2}>
                <MarginButton
                  variant="contained"
                  color="primary"
                  onClick={sample1}
                >
                  sample
                </MarginButton>
              </Grid>
            </Grid>
            {sample1Res && (
              <section>
                <br />
                <center>
                  <Result>
                    Please find below the predicted condition for each of the
                    sample row input
                  </Result>
                </center>
                <br />
                <Grid container alignItems="stretch" justifyContent="center">
                  <Grid item xs={6} sm={7} md={9} lg={9}>
                    {fields && (
                      <Paper>
                        <ScrollableTable>
                          <Table>
                            <TableHead>
                              <TableRow>
                                {fields.map((field) => (
                                  <StyledTableHead key={field.name}>
                                    {field.name}
                                  </StyledTableHead>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {Array.isArray(dataRows)
                                && dataRows.map((row) => (
                                  <TableRow key={row.index}>
                                    {fields.map((field) => (
                                      <TableCell align="left">
                                        {field.name === "index"
                                          ? row[field.name] + 1
                                          : row[field.name]}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </ScrollableTable>
                      </Paper>
                    )}
                  </Grid>
                  <Grid item xs={6} sm={5} md={3} lg={3}>
                    <Table1>
                      <TableHead>
                        <TableRow>
                          <StyledTableHead2>
                            Predicted Condition
                          </StyledTableHead2>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sample1Data.map((row, index) => (
                          <TableRow key={index}>
                            {row.map((field) => (
                              <StyledTableHead3>{field}</StyledTableHead3>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table1>
                  </Grid>
                </Grid>
                <br />
              </section>
            )}
          </section>
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};
export default memo(StressDetectionUsingHRV);

const StyledTableHead = styled(TableCell)`
  background-color: #3c40af;
  color: white;
  font-weight: bold;
`;
const StyledTableHead3 = styled(TableCell)`
  background-color: grey;
  color: white;
  font-weight: bold;
`;
const StyledTableHead2 = styled(TableCell)`
  background-color: #fc7703;
  color: white;
  font-weight: bold;
`;
const MarginButton = styled(StyledButton)`
  margin-right: 10px;
  margin-left: 10px;
`;
const ScrollableTable = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;

  .MuiTableCell-root {
    @media only screen and (max-width: 960px) {
      font-size: 14px;
      padding: 12px;
    }
    @media only screen and (max-width: 450px) {
      font-size: 10px;
      padding: 7px;
    }
  }
`;
const StyledTableHead1 = styled(TableCell)`
  font-weight: bold;
  @media only screen and (max-width: 900px) {
    font-size: 15px;
  }
  @media only screen and (max-width: 450px) {
    font-size: 12px;
  }
`;

const Table1 = styled(Table)`
  float: left;
  border: 1px solid white;
  overflow-x: auto;
  overflow-y: auto;
  text-align: center;
  margin-bottom: 20px;
  vertical-align: middle;
  border-collapse: collapse;
  // width: max-content;
  .MuiTableCell-root {
    @media only screen and (max-width: 960px) {
      font-size: 14px;
      padding: 12px;
    }
    @media only screen and (max-width: 450px) {
      font-size: 10px;
      padding: 7px;
    }
  }
`;
