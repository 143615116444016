import React, { memo, useState } from "react";
import { Grid } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import DataSourceIcon from "@material-ui/icons/GetApp";

// import local components
import Collapse from "../../common/Collapse";
import ImageAnalysisInput from "../../common/ImageAnalysisInput";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  SolutionContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";

const BloodCellInception = () => {
  const getResponse = (response) => {
    const res = typeof response === "object" ? response.result : response;
    setResponse(res);
  };

  const [textResponse, setResponse] = useState(false);

  return (
    <SolutionContainer>
      <Collapse text="Description">
        <Paragraph>
          <p>
            The diagnosis of blood-based diseases often involves identifying and characterizing patient blood samples. Automated methods to detect and classify blood cell subtypes are very important when it comes to the medical field.
          </p>
          <p>
            The dataset used to train this algorithm contains 12,500 augmented images of blood cells (JPEG) with accompanying cell type labels (CSV). There are approximately 3,000 images for each of 4 different cell types grouped into 4 different folders (according to cell type). The cell types are Eosinophil, Lymphocyte, Monocyte, and Neutrophil. This dataset is accompanied by an additional dataset containing the original 410 images (pre-augmentation) as well as two additional subtype labels (WBC vs WBC) and also bounding boxes for each cell in each of these 410 images (JPEG + XML metadata).
          </p>
          <p>
            The four different cell types are as follows:
          </p>
          <p>Upload the image of a blood sample to see where does it falls into</p>
          <p>
            <strong>Eosinophil -</strong>
            {' '}
            Eosinophils are a type of disease-fighting white blood cell. This condition most often indicates a parasitic infection, an allergic reaction or cancer.
          </p>
          <p>
            <strong>Lymphocyte -</strong>
            {' '}
            Lymphocytes are white blood cells that are also one of the body's main types of immune cells. They are made in the bone marrow and found in the blood and lymph tissue.
            {' '}
          </p>
          <p>
            <strong>Monocyte -</strong>
            {' '}
            Monocytes are a type of leukocyte, or white blood cell. They are the largest type of leukocyte and can differentiate into macrophages and myeloid lineage dendritic cells. As a part of the vertebrate innate immune system monocytes also influence the process of adaptive immunity
            {' '}
          </p>
          <p>
            <strong>Neutrophil-</strong>
            {' '}
            Neutrophils are a type of white blood cell (WBC or granulocyte) that protect us from infections, among other functions. They make up approximately 40 percent to 60 percent of the white blood cells in our bodies.
            {' '}
          </p>
          <p>
            This application takes a blood sample image as an input and identifies the type of blood cell present. This makes blood-related disease diagnosis faster.
          </p>
          <LinkContainer>
            <Grid container spacing={2}>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://www.kaggle.com/datasets/paultimothymooney/blood-cells"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Blood%20Cell%20Inception"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>

            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <ImageAnalysisInput
            img1="bloodCell_1.webp"
            img2="bloodCell_2.webp"
            url={["patientEngagement", "bloodcell"]}
            sample1url="SampleImage/"
            sample2url="SampleImage2/"
            result={getResponse}
          />
          {textResponse && (
            <Result>
              <strong>Result:</strong>
              {textResponse}
            </Result>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(BloodCellInception);
