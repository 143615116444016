import React, { memo, useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Highcharts from "highcharts";

// local components

import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import SolutionContainer from "../../common/SolutionContainerWrapper";

import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  HighchartContainer,
} from "../../../styles/common";
import { getUploadToken } from "../../../utils";

const InsuranceChurn = () => {
  const initialData = {
    feature_0: '',
    feature_1: '',
    feature_2: '',
    feature_3: '',
    feature_4: '',
    feature_5: '',
    feature_6: '',
    feature_7: '',
    feature_8: '',
    feature_9: '',
    feature_10: '',
    feature_11: '',
    feature_12: '',
    feature_13: '',
    feature_14: '',
    feature_15: '',
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [result, SetResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "payerProvider", "insuranceChurnPrediction",
  );
  const Token = getUploadToken()

  const showSample1 = () => {
    const NewDataSet = {
      feature_0: '-0.2765146',
      feature_1: '-0.42442881',
      feature_2: '1.34499695',
      feature_3: '-0.01228261',
      feature_4: '0.07622994',
      feature_5: '1.0766475',
      feature_6: '0.18219753',
      feature_7: '3',
      feature_8: '0',
      feature_9: '1',
      feature_10: '0',
      feature_11: '0',
      feature_12: '0',
      feature_13: '0',
      feature_14: '10',
      feature_15: '2',
    };

    setSampleData(NewDataSet);
  };

  const showSample2 = () => {
    const NewDataSet = {
      feature_0: '-1.21825437',
      feature_1: '-0.29436811',
      feature_2: '1.10468119',
      feature_3: '-0.52485428',
      feature_4: '-0.24656035',
      feature_5: '-0.41145311',
      feature_6: '-0.25194037',
      feature_7: '4',
      feature_8: '1',
      feature_9: '1',
      feature_10: '0',
      feature_11: '1',
      feature_12: '0',
      feature_13: '0',
      feature_14: '8',
      feature_15: '3',
    };

    setSampleData(NewDataSet);
  };

  const getResult = () => {
    const {
      feature_0, feature_1, feature_2, feature_3, feature_4, feature_5, feature_6, feature_7, feature_8, feature_9, feature_10, feature_11, feature_12, feature_13, feature_14, feature_15,
    } = sampleData;

    const params = `${feature_0},${feature_1},${feature_2},${feature_3},${feature_4},${feature_5},${feature_6},${feature_7},${feature_8},${feature_9},${feature_10},${feature_11},${feature_12},${feature_13},${feature_14},${feature_15},${Token.token}`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });
    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      SetResultData("");
      setSpinner(true);

      getQueryResult("withParams", params)
        .then((res) => {
          setSpinner(false);
          SetResultData(res[0]);
          const percentages = res[1];
          const params = [];

          percentages.map((arr) => {
            params.push({
              name: arr[0],
              y: arr[1],
            });
          });

          Highcharts.chart('container', {
            chart: {
              plotShadow: false,
              type: 'pie',
            },
            title: {
              text: 'MAJOR CONTRIBUTING FACTORS',
            },
            tooltip: {
              pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                  style: {
                    color: (Highcharts.theme && Highcharts.theme.contrastTextColor) || 'black',
                  },
                },
              },
            },
            credits: {
              enabled: false,
            },
            series: [{
              colorByPoint: true,
              data: params,
            }],
          });
        })
        .catch((err) => {
          setSpinner(false);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const handleInputs = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>Insurance providers operate in a very competitive environment, and customer churn rate is one factor that drives the competitiveness. Customer Churn is a term used in the business world to refer to the loss of a customer. A voluntary customary churn happens when the customer decides to change their current insurance provider due to various factors that revolve around the insurance plan offered, among other reasons.</p>
          <p>In this POC, a LightGBM classifier model is used to predict the likelihood of a customer to switch to another insurance provider by analyzing various anonymized features acquired after performing a survey. Features mentioned in the data are anonymized as data is sensitive in nature. This will help insurance providers to understand the reason for a customer’s decision to switch to another provider, thereby allowing them to improve company-customer relationship.</p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://www.kaggle.com/mukulsingh/insurance-churn-prediction"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/InsuranceChurnPrediction/InsuranceChurnPrediction.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo for Sample">
        <DemoContainer>
          <Grid container direction="row" justify="left" spacing={2}>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                name="feature_0"
                label="Feature 0"
                fullWidth
                value={sampleData.feature_0}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                name="feature_1"
                label="Feature 1"
                fullWidth
                value={sampleData.feature_1}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                name="feature_2"
                label="Feature 2"
                fullWidth
                value={sampleData.feature_2}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                name="feature_3"
                label="Feature 3"
                fullWidth
                value={sampleData.feature_3}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                name="feature_4"
                label="Feature 4"
                fullWidth
                value={sampleData.feature_4}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                name="feature_5"
                label="Feature 5"
                fullWidth
                value={sampleData.feature_5}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                name="feature_6"
                label="Feature 6"
                fullWidth
                value={sampleData.feature_6}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                name="feature_7"
                label="Feature 7"
                fullWidth
                value={sampleData.feature_7}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                name="feature_8"
                label="Feature 8"
                fullWidth
                value={sampleData.feature_8}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                name="feature_9"
                label="Feature 9"
                fullWidth
                value={sampleData.feature_9}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                name="feature_10"
                label="Feature 10"
                fullWidth
                value={sampleData.feature_10}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                name="feature_11"
                label="Feature 11"
                fullWidth
                value={sampleData.feature_11}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                name="feature_12"
                label="Feature 12"
                fullWidth
                value={sampleData.feature_12}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                name="feature_13"
                label="Feature 13"
                fullWidth
                value={sampleData.feature_13}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                name="feature_14"
                label="Feature 14"
                fullWidth
                value={sampleData.feature_14}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                variant="outlined"
                name="feature_15"
                label="Feature 15"
                fullWidth
                value={sampleData.feature_15}
                onChange={handleInputs}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={showSample1}
              >
                Sample Data1
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={showSample2}
              >
                Sample Data2
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={getResult}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
            <section>
              <Result>
                <strong>Result:</strong>
                {`${result}`}
              </Result>
            </section>
          )}
          <HighchartContainer
            id="container"
            display={result}
          />
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(InsuranceChurn);
