import React, { memo, useState } from "react";
import {
  Grid, TextField, FormControl, MenuItem,
} from "@material-ui/core";
import styled from "styled-components";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components
import Highcharts from "highcharts";
import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import CommonService from "../../../utils/services/CommonService";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  HighchartContainer,
  Result,
} from "../../../styles/common";
import getUserData, { getUploadToken } from '../../../utils';

const MedicalAppointmentNoShow = () => {
  const initialData = {
    age: "",
    gender: "",
    appointmentDate: "",
    scheduledDate: "",
    neighbourhood: "",
    scholarship: "",
    hypertension: "",
    diabetes: "",
    alcoholism: "",
    handicap: "",
    smsReceived: "",
    scheduledDayofWeek: "",
    appointmentDateofWeek: "",
    waitingDays: "",
  };

  const genders = [
    {
      value: 1,
      label: "Male",
    },
    {
      value: 0,
      label: "Female",
    },
  ];

  const scholarships = [
    {
      value: 0,
      label: "No",
    },
    {
      value: 1,
      label: "Yes",
    },
  ];

  const hypertensions = [
    {
      value: 0,
      label: "No",
    },
    {
      value: 1,
      label: "Yes",
    },
  ];

  const diabetes = [
    {
      value: 0,
      label: "No",
    },
    {
      value: 1,
      label: "Yes",
    },
  ];

  const alocoholic = [
    {
      value: 0,
      label: "No",
    },
    {
      value: 1,
      label: "Yes",
    },
  ];

  const handicaps = [
    {
      value: 0,
      label: "No",
    },
    {
      value: 1,
      label: "Yes",
    },
  ];

  const sms = [
    {
      value: 0,
      label: "No",
    },
    {
      value: 1,
      label: "Yes",
    },
  ];

  const appointmentDatesofWeek = [
    {
      value: 0,
      label: "Monday",
    },
    {
      value: 1,
      label: "Tuesday",
    },
    {
      value: 2,
      label: "Wednesday",
    },
    {
      value: 3,
      label: "Thursday",
    },
    {
      value: 4,
      label: "Friday",
    },
    {
      value: 5,
      label: "Saturday",
    },
    {
      value: 6,
      label: "Sunday",
    },
  ];

  const scheduledDays = [
    {
      value: 0,
      label: "Monday",
    },
    {
      value: 1,
      label: "Tuesday",
    },
    {
      value: 2,
      label: "Wednesday",
    },
    {
      value: 3,
      label: "Thursday",
    },
    {
      value: 4,
      label: "Friday",
    },
    {
      value: 5,
      label: "Saturday",
    },
    {
      value: 6,
      label: "Sunday",
    },
  ];

  const [sampleData, setSampleData] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "commercialAnalytics",
    "medicalAppointmentNoShow",
  );
  const Token = getUploadToken();

  const formHighChart = (data) => {
    Highcharts.chart("container_1", {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
      },
      title: {
        text: "Major Contributing factors",
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
        },
      },
      series: [
        {
          name: "feature importance",
          colorByPoint: true,
          data: [
            {
              name: data[1][1][0],
              y: data[1][1][1],
            },
            {
              name: data[1][2][0],
              y: data[1][2][1],
            },
            {
              name: data[1][3][0],
              y: data[1][3][1],
            },
            {
              name: data[1][4][0],
              y: data[1][4][1],
            },
            {
              name: data[1][5][0],
              y: data[1][5][1],
            },
            {
              name: data[1][6][0],
              y: data[1][6][1],
            },
            {
              name: data[1][7][0],
              y: data[1][7][1],
            },
            {
              name: data[1][8][0],
              y: data[1][8][1],
            },
            {
              name: data[1][9][0],
              y: data[1][9][1],
            },
          ],
        },
      ],
    });
    Highcharts.chart("container_2", {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
      },
      title: {
        text: "Probability Distribution for Predicted Class",
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
        },
      },
      series: [
        {
          name: "feature importance",
          colorByPoint: true,
          data: [
            {
              name: "Patient Show",
              y: data[2][0][1],
            },
            {
              name: "Patient No Show",
              y: data[2][0][2],
            },
          ],
        },
      ],
    });
  };
  const showResult = () => {
    const NewDataSet = {
      age: "64",
      gender: "1",
      appointmentDate: "2019-12-01",
      scheduledDate: "2019-12-01",
      neighbourhood: "12",
      scholarship: "0",
      hypertension: "0",
      diabetes: "1",
      alcoholism: "0",
      handicap: "0",
      smsReceived: "1",
      scheduledDayofWeek: "0",
      appointmentDateofWeek: "0",
      waitingDays: "22",
    };
    setSampleData(NewDataSet);
    setResultData("");
  };

  const showResult2 = () => {
    const NewDataSet = {
      age: "34",
      gender: "0",
      appointmentDate: "2019-04-29",
      scheduledDate: "2019-04-29",
      neighbourhood: "69",
      scholarship: "0",
      hypertension: "0",
      diabetes: "0",
      alcoholism: "0",
      handicap: "1",
      smsReceived: "0",
      scheduledDayofWeek: "4",
      appointmentDateofWeek: "4",
      waitingDays: "0",
    };
    setSampleData(NewDataSet);
    setResultData("");
  };

  const getResult = () => {
    const {
      age,
      gender,
      appointmentDate,
      scheduledDate,
      neighbourhood,
      scholarship,
      hypertension,
      diabetes,
      alcoholism,
      handicap,
      smsReceived,
      scheduledDayofWeek,
      appointmentDateofWeek,
      waitingDays,
    } = sampleData;

    const params = `${age},${gender},${appointmentDate},${scheduledDate},${neighbourhood},${scholarship},${hypertension},${diabetes},${alcoholism},${handicap},${smsReceived},${scheduledDayofWeek},${appointmentDateofWeek},${waitingDays},${Token.token}`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("output", params)
        .then((result) => {
          setSpinner(false);
          setResultData(result[0]);
          console.log("result", result);
          formHighChart(result);
        })
        .catch((err) => {
          setSpinner(false);
          console.log(err);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const onChangeFields = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };
  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <section>
            <p>
              {" "}
              A person makes a doctor appointment, receives all the instructions
              and does not show up for the appointment. Machine learning Random
              forest Model is built in order to predict Show/NoShow of patients
              who makes doctor appointment.
            </p>
            <p>
              The factors used for prediction are Gender, Age, Zip-code, if the
              patient is having Hypertension, diabetes, or if he is alcoholic or
              handicapped, date of appointment and date of booking, and if the
              patient is under some social welfare scheme or not.
              {" "}
            </p>
            <p>
              This use case will help the hospitals and other medical service
              provider centres in better planning their resources and managing
              the patients visiting in the OPD. The no show predictor will aid
              in lesser wait times for the patients and will ease the life of
              doctors, attendants and other medical staff at the hospitals
            </p>
          </section>
          <section />
          <LinkContainer>
            <Grid container spacing={2}>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Medical_Appointment_Noshow/Data"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Medical_Appointment_Noshow/Notebook"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <section>
            <Grid container direction="row" justify="center" spacing={2}>
              <Grid item xs={12} md={6}>
                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Age"
                          name="age"
                          value={sampleData.age}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          select
                          variant="outlined"
                          label="Gender"
                          name="gender"
                          value={sampleData.gender}
                          onChange={onChangeFields}
                        >
                          {genders.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Appointment Date"
                          name="appointmentDate"
                          value={sampleData.appointmentDate}
                          onChange={onChangeFields}
                          type="date"
                          InputLabelProps={{ shrink: true }}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Scheduled Date"
                          name="scheduledDate"
                          value={sampleData.scheduledDate}
                          onChange={onChangeFields}
                          type="date"
                          InputLabelProps={{ shrink: true }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Zip-code(Enter values 1-81)"
                          name="neighbourhood"
                          value={sampleData.neighbourhood}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          select
                          variant="outlined"
                          label="Scholarship"
                          name="scholarship"
                          value={sampleData.scholarship}
                          onChange={onChangeFields}
                        >
                          {scholarships.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          select
                          variant="outlined"
                          label="Hypertension"
                          name="hypertension"
                          value={sampleData.hypertension}
                          onChange={onChangeFields}
                        >
                          {hypertensions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Diabetes"
                          name="diabetes"
                          select
                          value={sampleData.diabetes}
                          onChange={onChangeFields}
                        >
                          {diabetes.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Alcoholism"
                          name="alcoholism"
                          value={sampleData.alcoholism}
                          onChange={onChangeFields}
                          select
                        >
                          {alocoholic.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Handicap"
                          name="handicap"
                          value={sampleData.handicap}
                          onChange={onChangeFields}
                          select
                        >
                          {handicaps.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="SMS Received"
                          name="smsReceived"
                          value={sampleData.smsReceived}
                          onChange={onChangeFields}
                          select
                        >
                          {sms.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Scheduled Day of Week"
                          name="scheduledDayofWeek"
                          value={sampleData.scheduledDayofWeek}
                          onChange={onChangeFields}
                          select
                        >
                          {scheduledDays.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>

                <br />

                <section>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Appointment Day of the Week"
                          name="appointmentDateofWeek"
                          value={sampleData.appointmentDateofWeek}
                          onChange={onChangeFields}
                          select
                        >
                          {appointmentDatesofWeek.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl minWidth="120" varient="outlined" fullWidth>
                        <TextField
                          id="outlined-adornment-weight"
                          variant="outlined"
                          label="Waiting Days"
                          name="waitingDays"
                          value={sampleData.waitingDays}
                          onChange={onChangeFields}
                          type="number"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </section>
                <br />
                <section>
                  <BorderGray>
                    Not sure about valid data inputs? Try any of the following
                    sample data...
                    <div align="right">
                      <StyledButton
                        variant="contained"
                        color="secondary"
                        onClick={showResult}
                      >
                        Sample 1
                      </StyledButton>

                      <StyledButton
                        variant="contained"
                        color="secondary"
                        onClick={showResult2}
                      >
                        Sample 2
                      </StyledButton>
                      <StyledButton
                        variant="contained"
                        color="primary"
                        onClick={getResult}
                      >
                        Submit
                      </StyledButton>
                    </div>
                  </BorderGray>
                </section>
              </Grid>
              <Grid item xs={12} md={6}>
                <section>
                  <h3>Output</h3>
                </section>
                {showSpinner && <Spinner text="Loading..." />}
                {resultData && (
                  <section>
                    <Result>
                      <strong>Result:</strong>
                      {`${resultData}`}
                    </Result>
                    <Grid container direction="row" justify="center">
                      <Grid item xs={12}>
                        <HighchartContainer id="container_1" display />
                        <HighchartContainer id="container_2" display />
                      </Grid>
                    </Grid>
                  </section>
                )}
              </Grid>
            </Grid>
          </section>
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(MedicalAppointmentNoShow);

const BorderGray = styled.div`
  border: 1px #d6d6d6 solid;
  padding: 20px;
`;
