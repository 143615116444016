import React, { memo, useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Divider,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  Paper,
  TableRow,
  TableContainer,
  TableHead,
  Checkbox,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import commonServices from "../../../utils/services/CommonService";
import Spinner from "../../common/loading/Spinner";
import NotificationWidget from '../../common/Notification';
import { DemoContainer, StyledBackdrop } from "../../../styles/common";
import Collapse from "../../common/Collapse";
import SolutionContainer from "../../common/SolutionContainerWrapper";
 
const steps = ["HCP Selection", "Product Selection", "NBA Execution"];
 
export const NBADemo = memo(() => {
  const [currentStep, setCurrentStep] = useState(0);
  const [territoryValue, setTerritoryValue] = useState([]);
  const [salesRep, setSalesRep] = useState([]);
  const [accountName, setAccountName] = useState([]);
  const [selectedTerritoryNumber, setSelectedTerritoryNumber] = useState('');
  const [selectedSalesRep, setSelectedSalesRep] = useState('');
  const [selectedAccountName, setSelectedAccountName] = useState('');
  const [notification, setNotification] = useState("");
  const [showSpinner, setSpinner] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [callAttain, setCallAttain] = useState("");
  const [productAttain, setProductAttain] = useState("");
  const [executed, setExecuted] = useState(false);
 
  const { getQueryResult, postQueryResult } = commonServices("nba", "nba");
 
  useEffect(() => {
    getTerritory();
  }, []);
 
  const getTerritory = () => {
    setSpinner(true);
    getQueryResult("NBATerritory")
      .then((res) => {
        setSpinner(false);
        if (res.Error_Flag) {
          setNotification({ open: "error", message: res?.Console });
          return;
        }
        setTerritoryValue(res.Result);
      })
      .catch((err) => {
        setSpinner(false);
        setNotification({ open: "error", message: err?.Console });
      });
  };
 
  const handleTerritory = (e) => {
    const value = e.target.value;
    setSelectedTerritoryNumber(value);
    setSpinner(true);
    postQueryResult("NBAsalesrep", { territory_number: value })
      .then((res) => {
        setSpinner(false);
        if (res.Error_Flag) {
          setNotification({ open: "error", message: res?.Console });
          return;
        }
        setSalesRep(res.Result);
      })
      .catch((err) => {
        setSpinner(false);
        setNotification({ open: "error", message: err?.Console });
      });
  };
 
  const handleSalesRep = (e) => {
    const value = e.target.value;
    setSelectedSalesRep(value);
    setSpinner(true);
    postQueryResult("NBAhcp", { sales_rep_id: value })
      .then((res) => {
        setSpinner(false);
        if (res.Error_Flag) {
          setNotification({ open: "error", message: res?.Console });
          return;
        }
        setAccountName(res.Result);
      })
      .catch((err) => {
        setSpinner(false);
        setNotification({ open: "error", message: err?.Console });
      });
  };
 
  const handleSubmit = () => {
    if (selectedTerritoryNumber && selectedSalesRep && selectedAccountName) {
      getProducts();
      setCurrentStep(1);
    }
  };
 
  const getProducts = () => {
    setSpinner(true);
    postQueryResult("NBAproducts", {
      sales_rep_id: selectedSalesRep,
      hcp_id: selectedAccountName,
    })
      .then((res) => {
        setSpinner(false);
        if (res.Error_Flag) {
          setNotification({ open: "error", message: res?.Console });
          return;
        }
        setProducts(res.Result);
      })
      .catch((err) => {
        setSpinner(false);
        setNotification({ open: "error", message: err?.Console });
      });
  };
 
  const handleNbaExecution = () => {
    setSpinner(true);
    postQueryResult("NBAsuggest", {
      territory_number: selectedTerritoryNumber,
      sales_rep_id: selectedSalesRep,
      call_id: "CPL000123",
      hcp: selectedAccountName,
      product_name: selectedProducts,
    })
      .then((res) => {
        setSpinner(false);
        if (res.Error_Flag) {
          setNotification({ open: "error", message: res?.Console });
          return;
        }
        setCallAttain(res.Call_Attain);
        setProductAttain(res.Product_Attain);
        setTableData(res.Result);
        setExecuted(true);
        setCurrentStep(2);
        setNotification({ open: "success", message: res?.Console });
      })
      .catch((err) => {
        setSpinner(false);
        setNotification({ open: "error", message: err?.Console });
      });
  };
 
  const handleBack = () => {
    setCurrentStep(currentStep - 1);
  };
 
 
  return (
    <SolutionContainer>
      <Collapse text="Demo">
        <DemoContainer>
          <Box>
            <Stepper activeStep={currentStep} alternativeLabel style={{ backgroundColor: 'white' }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
 
            {currentStep === 0 && (
              <Box display="flex" flexDirection="column" minHeight="80vh" height="80vh">
                <Box p={5} flex="1" height="300px" >
                  <Box display="flex" flexDirection="column" minHeight="80vh" height="87vh">
                    <div className="divider-line" style={{ height: '1px', backgroundColor: '#1b63b4', marginTop: '-45px' }}></div>
                    <Typography variant="h3" gutterBottom  >
                      <p style={{ fontSize: '30px', textAlign: 'left', color: '#0058A9' }}><b>HCP</b>&nbsp;selection</p>
                    </Typography>
                    <Box mb={3} display="flex" alignItems="center">
                      <Typography variant="subtitle1" sx={{ flex: '0 0 150px', lineHeight: 'normal' }}>
                        <b>Territory Name:</b>
                      </Typography>
                      <FormControl
                        fullWidth
                        margin="normal"
                        style={{
                          width: '300px',
                          border: '1px solid #ccc',
                          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                          overflow: 'hidden',
                          marginLeft: '40px',
                          marginBottom: '20px',
                        }}
                      >
                        <Select
                          labelId="territory-select-label"
                          value={selectedTerritoryNumber}
                          onChange={handleTerritory}
                          displayEmpty
                          style={{
                            width: '100%',
                            border: 'none',
                            padding: '8px',
                            borderRadius: '0',
                            '&:focus': {
                              outline: 'none',
                              border: 'none',
                            },
                          }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                                width: 250,
                              },
                            },
                          }}
                        >
                          <MenuItem value="" disabled>Select Territory</MenuItem>
                          {territoryValue.map((territory) => (
                            <MenuItem key={territory.territory_number} value={territory.territory_number}>
                              {territory.territory_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                   
                    {/* Sales Rep Name Selection */}
                    <Box mb={3} display="flex" alignItems="center">
                      <Typography variant="subtitle1" sx={{ flex: '0 0 150px' }}>
                        <b>Sales Rep Name:</b>
                      </Typography>
                      <FormControl
                        fullWidth
                        margin="normal"
                        style={{
                          width: '300px',
                          border: '1px solid #ccc',
                          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                          overflow: 'hidden',
                          marginLeft: '30px',
                        }}
                      >
                        <Select
                          labelId="sales-rep-select-label"
                          value={selectedSalesRep}
                          onChange={handleSalesRep}
                          displayEmpty
                          style={{
                            width: '100%',
                            border: 'none',
                            padding: '8px',
                            borderRadius: '0',
                            '&:focus': {
                              outline: 'none',
                              border: 'none',
                            },
                          }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                                width: 250,
                              },
                            },
                          }}
                        >
                          <MenuItem value="" disabled>Select Sales Rep</MenuItem>
                          {salesRep.map((rep) => (
                            <MenuItem key={rep.Id} value={rep.Id}>
                              {rep.Name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
 
                    {/* Account Name Selection */}
                    <Box mb={3} display="flex" alignItems="center">
                      <Typography variant="subtitle1" sx={{ flex: '0 0 150px' }}>
                        <b>Account Name:</b>
                      </Typography>
                      <FormControl
                        fullWidth
                        margin="normal"
                        style={{
                          width: '300px',
                          border: '1px solid #ccc',
                          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                          overflow: 'hidden',
                          marginLeft: '40px',
                        }}
                      >
                        <Select
                          labelId="account-name-select-label"
                          value={selectedAccountName}
                          onChange={(e) => setSelectedAccountName(e.target.value)}
                          displayEmpty
                          style={{
                            width: '100%',
                            border: 'none',
                            padding: '8px',
                            borderRadius: '0',
                            '&:focus': {
                              outline: 'none',
                              border: 'none',
                            },
                          }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                                width: 250,
                              },
                            },
                          }}
                        >
                          <MenuItem value="" disabled>Select Account Name</MenuItem>
                          {accountName.map((account) => (
                            <MenuItem key={account.Id} value={account.Id}>
                              {account.Name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <br />
 
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Button variant="contained" color="primary" onClick={handleSubmit}>Next</Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
 
            {currentStep === 1 && (
              <Box>
                <Typography variant="h3" gutterBottom>
                  <p style={{ fontSize: '30px', textAlign: 'left', color: '#0058A9' }}><b>Product</b>&nbsp;selection</p>
                </Typography>
                <Divider style={{ height: '1px', backgroundColor: '#1b63b5' }}></Divider>
 
                <Box mb={3} display="flex" justifyContent="space-between" paddingTop="15px">
                  <Box>
                    <Box textAlign="left">
                      <Typography variant="subtitle1">
                        <b>Selected Territory:</b> {territoryValue.find(t => t.territory_number === selectedTerritoryNumber)?.territory_name || 'N/A'}
                      </Typography>
                    </Box>
                    <Typography variant="subtitle1">
                      <b>Selected Sales Rep:</b> {salesRep.find(rep => rep.Id === selectedSalesRep)?.Name || 'N/A'}
                    </Typography>
                  </Box>
                  <Box textAlign="right">
                    <Typography variant="subtitle1">
                      <b>Selected Account Name:</b> {accountName.find(account => account.Id === selectedAccountName)?.Name || 'N/A'}
                    </Typography>
                  </Box>
                </Box>
                <Divider style={{ height: '1px', backgroundColor: '#1b63b5' }}></Divider>
 
 
 
                <Box mb={3} display="flex" alignItems="center" sx={{ marginTop: '15px' }}>
                  <Typography variant="subtitle1" sx={{ flex: '0 0 150px' }}>
                    <b>Focus Product For:</b>
                  </Typography>
                  <FormControl
                    fullWidth
                    margin="normal"
                    style={{
                      width: '300px',
                      border: '1px solid #ccc',
                      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                      overflow: 'hidden',
                      marginLeft: '20px',
                    }}
                  >
                    <Select
                      labelId="demo-multiple-name-label"
                      multiple
                      value={selectedProducts}
                      onChange={(e) => setSelectedProducts(e.target.value)}
                      renderValue={(selected) => selected.length ? selected.join(', ') : 'Select Products'}
                      displayEmpty
                      style={{
                        width: '100%',
                        border: 'none',
                        padding: '8px',
                        borderRadius: '0',
                        '&:focus': {
                          outline: 'none',
                          border: 'none',
                        },
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200,
                            width: 250,
                          },
                        },
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select Products
                      </MenuItem>
                      {products.map((product) => (
                        <MenuItem key={product} value={product}>
                          <Checkbox checked={selectedProducts.indexOf(product) > -1} />
                          {product}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
 
                <Box sx={{ display: 'flex', marginTop: '50px', justifyContent: 'center' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleBack}
                    style={{ marginRight: '16px' }}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNbaExecution}
                  >
                    Next Best Action
                  </Button>
                </Box>
 
              </Box>
            )}
 
            {currentStep === 2 && (
              <Box mt={4}>
                <Box mb={3} display="flex" justifyContent="space-between" sx={{ marginLeft: '-569px' }}>
                  <Divider style={{ height: '1px', backgroundColor: '#1b63b5' }}></Divider>
                  <Box textAlign="left">
                    <Typography variant="subtitle1">
                      <b>Selected Territory:</b> {territoryValue.find(t => t.territory_number === selectedTerritoryNumber)?.territory_name || 'N/A'}
                    </Typography>
                    <Typography variant="subtitle1">
                      <b>Selected Sales Rep:</b> {salesRep.find(rep => rep.Id === selectedSalesRep)?.Name || 'N/A'}
                    </Typography>
                  </Box>
                  <Box textAlign="right">
                    <Typography variant="subtitle1">
                      <b>Selected Account Name:</b> {accountName.find(account => account.Id === selectedAccountName)?.Name || 'N/A'}
                    </Typography>
                    <Typography variant="subtitle1">
                      <b>Focus Product:</b> {products.find(product => product.Id === selectedProducts)?.Name || 'N/A'}
                    </Typography>
                  </Box>
                </Box>
                <Divider style={{ height: '1px', backgroundColor: '#1b63b5' }}></Divider>
                <Typography variant="h6" style={{ fontSize: '20px', textAlign: 'left', color: '#0058A9', paddingTop: '10px' }}>
                  <b>NBA Summary for {selectedAccountName}</b>
                </Typography>
                {executed ? (
                  tableData.map((valuem, index) => (
                    <TableContainer component={Paper} key={index} style={{ marginTop: '20px' }}>
                      <Table>
                        <TableHead style={{ backgroundColor: '#89CFF0' }}>
                          <TableRow>
                            <TableCell><b>Product Name: </b>{valuem.product_name}</TableCell>
                            <TableCell><b>Last Month Highlights</b></TableCell>
                            <TableCell style={{ textAlign: "right" }}>
                              <b>Call target: </b>
                              <span style={{ color: valuem["Call target"] === 'Not Achieved' ? 'red' : 'green' }}>
                                {valuem["Call target"]}
                              </span><br />
                              <b>Product Sales target: </b>
                              <span style={{ color: valuem["Product Sales target"] === 'Not Achieved' ? 'red' : 'green' }}>
                                {valuem["Product Sales target"]}
                              </span>
                            </TableCell>
                            <TableCell style={{ textAlign: "right" }}>
                              <b>Number of new Rx of product M-O-M: </b>
                              {valuem["Number of new Rx of product M-O-M"] === 'Decreasing' ? (
                                <FontAwesomeIcon icon={faArrowDown} style={{ color: "red" }} />
                              ) : (
                                <FontAwesomeIcon icon={faArrowUp} style={{ color: "green" }} />
                              )}<br />
                              <b>Sales of product M-O-M: </b>
                              {valuem["Sales of product M-O-M"] === 'Decreasing' ? (
                                <FontAwesomeIcon icon={faArrowDown} style={{ color: "red" }} />
                              ) : (
                                <FontAwesomeIcon icon={faArrowUp} style={{ color: "green" }} />
                              )}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell colSpan={4}>
                              {valuem.suggestions?.split('\n').map((value, index) => (
                                <p key={index}>{value || ''}</p>
                              ))}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ))
                ) : (
                  <Typography variant="body1">No execution data available.</Typography>
                )}
                <Button variant="contained" color="primary" onClick={handleBack} style={{ marginTop: '10px' }}>
                  Back
                </Button>
              </Box>
            )}
            <StyledBackdrop open={showSpinner}>
              <Spinner />
            </StyledBackdrop>
            <NotificationWidget snackbar={notification} />
          </Box>
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
});
 
export default function NBADesc() {
  return (
    <SolutionContainer>
      <Collapse text="Description">
        <Box>
          <p>Next Best Action (NBA) is a customer-centric strategy that leverages data and predictive analytics to identify the most effective action for each customer at any given moment. This approach is widely utilized in marketing, sales, and customer service to enhance engagement and satisfaction. By analyzing customer data, NBA models can tailor real-time recommendations, ensuring that each interaction is relevant and personalized. This not only improves the customer experience but also fosters stronger relationships between the business and its customers.</p>
          <p>In practice, NBA involves predicting successful actions such as offering discounts, suggesting products, or providing timely information. These predictions help businesses prevent customer churn by addressing potential issues before they escalate. Additionally, NBA optimizes resources by focusing efforts on actions that are most likely to yield positive outcomes. This targeted approach ensures that marketing and sales initiatives are more efficient and effective, ultimately driving higher revenue and customer retention.</p>
          <p>However, implementing NBA requires accurate data, seamless integration across various systems, and careful consideration of fairness. Accurate data is crucial for making reliable predictions, while seamless integration ensures that insights can be acted upon in real-time. Fairness considerations are also important to avoid biases in the recommendations, ensuring that all customers receive equitable treatment. When executed correctly, NBA can significantly boost customer experience, increase revenue, and reduce costs, making it a powerful tool for modern businesses.</p>
        </Box>
      </Collapse>
    </SolutionContainer>
  );
}