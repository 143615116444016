import React from "react";
import Grid from "@material-ui/core/Grid";

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

import PropTypes from "prop-types";
import TableCell from "@material-ui/core/TableCell";
import Highcharts from "highcharts";
import {
  Button,
  Checkbox,
  Paper,
  Radio,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from "@material-ui/core";

import styled from "styled-components";
import CommonService from "../../../utils/services/CommonService";
import { HeaderCell } from "../../common/JsonDataTable";
import SolutionContainerWrapper from "../../common/SolutionContainerWrapper";
import Spinner from "../../common/loading/Spinner";
import { Paragraph, StyledButton } from "../../../styles/common";
import getUserData, { getUploadToken } from '../../../utils';

const { getQueryResult } = CommonService("ibri", "selfService");
const Token = getUploadToken()
class Selfserviceportal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
      Highcharts,
      sankeychart: [{}],
      piechart: [{}],
      barchart: [{}],
      donutdata: [{}],
      tb: [],
      table_name: "",
      db_name: "",
      db_type: "",
      db_table: "",
      charttype: "",
      t_data: [],
      headList: [],
      dataList: [],
      dimensions: [],
      measures: [],
      counts: [],
      checkedDimensions: [],
      checkedMeasure: "",
      checkedCount: "false",
      getcount: "",
      getchartType: "none",
      getchart: "none",
      buttonValid: false,
      loadingStatus: false,
    };

    this.checkBoxClick = this.checkBoxClick.bind(this);
    this.checkRadioClick = this.checkRadioClick.bind(this);
    this.checkCountClick = this.checkCountClick.bind(this);
    this.checkTypeClick = this.checkTypeClick.bind(this);
    this.buttonclick = this.buttonclick.bind(this);
  }

  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };

  handlecharttype = (event) => {
    this.setState({ value: event.target.value });
  };

  loadsankey() {
    this.setState({
      loadingStatus: false,
    });

    Highcharts.chart("container", {
      title: {
        text: "Sankey Visualization",
      },
      data: {
        enablePolling: true,
        dataRefreshRate: 1,
      },
      xAxis: {
        type: "category",
      },
      plotOptions: {
        series: {
          threshold: Infinity,
        },
      },
      events: {
        load() {
          this.setState({
            loadingStatus: false,
          });
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          keys: ["from", "to", "weight"],
          data: this.state.sankeychart,
          type: "sankey",
          name: "Count",

          showInNavigator: true,
        },
      ],
    });
  }

  loadPie() {
    console.log("Loading Pie");
    // Code for color Gradient in pie charts
    if (!Highcharts.charts.length) {
      Highcharts.setOptions({
        colors: Highcharts.map(
          Highcharts.getOptions().colors,
          (color) => ({
            radialGradient: {
              cx: 0.5,
              cy: 0.3,
              r: 0.7,
            },
            stops: [
              [0, color],
              [1, Highcharts.Color(color).brighten(-0.3).get("rgb")], // darken
            ],
          }),
        ),
      });
    }

    // Build the chart
    this.setState({
      loadingStatus: false,
    });

    Highcharts.chart("container", {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
      },
      title: {
        text: "Top 10 Results",
      },
      tooltip: {
        pointFormat: "{series.name} : <b>{point.y}</b>",
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b> : {point.y} ",
            style: {
              color:
                (Highcharts.theme && Highcharts.theme.contrastTextColor)
                || "black",
            },
            connectorColor: "silver",
          },
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: "Total Events",
          data: this.state.piechart,
        },
      ],
    });
  }

  loadbar() {
    this.setState({
      loadingStatus: false,
    });
    Highcharts.chart("container", {
      chart: {
        type: "column",
      },
      title: {
        text: "Top 10 Results",
      },
      data: {
        enablePolling: true,
        dataRefreshRate: 1,
      },
      xAxis: {
        type: "category",
      },
      yAxis: {
        min: 0,
        title: {
          text: "Events count",
        },
      },
      plotOptions: {
        series: {
          turboThreshold: "infinity",
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          data: this.state.barchart,
        },
      ],
    });
  }

  loaddonut() {
    this.setState({
      loadingStatus: false,
    });

    Highcharts.chart("container", {
      chart: {
        type: "pie",
        options3d: {
          enabled: true,
          alpha: 45,
        },
      },
      title: {
        text: "Top 10 Results",
      },
      subtitle: {
        text: "",
      },

      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          innerSize: 100,
          depth: 45,
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b> : {point.y} ",
            style: {
              color:
                (Highcharts.theme && Highcharts.theme.contrastTextColor)
                || "black",
            },
            connectorColor: "silver",
          },
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: "Patient Population",
          data: this.state.donutdata,
        },
      ],
    });
  }

  displayTable() {
    const classes = this.props;
    return (
      <ScrollablePaper>
        <Table className={classes.table}>
          <TableHead>
            <TableRow hover>
              {this.state.headList.map((headItem) => (
                <HeaderCell key={headItem}>{headItem}</HeaderCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.dataList.map((dataItem) => (
              <TableRow key={dataItem}>
                {dataItem.map((subItem) => (
                  <TableCell key={subItem}>{subItem}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </ScrollablePaper>
    );
  }

  chooseTable = (e) => this.setState({ table_name: e.target.value, loadingStatus: true });

  render() {
    const classes = this.props;
    return (
      <SolutionContainerWrapper snackbar={this.state.notification}>
        <Paragraph>
          <center>
            <strong>
              All Patient Health Data Self Service Options for IBRI & Synthetic
              Databases
            </strong>
          </center>
          <p>
            Explore data like never before. Self service dashboard is catered to
            users ranging form business analysts to data scientists to
            understand data before making informed decisions and building
            machine learning algorithms​.
          </p>

          <p>
            As the tool is python powered it is very robust in nature and
            compute time is much faster compared to the rest.
          </p>

          <Grid container>
            <Grid item xs={12} md={6}>
              <b>Available operations on data</b>

              <ul>
                <li>Data can be viewed at schema level​</li>
                <li>Data profiling of individual columns are addressed ​​</li>
                <li>
                  Statistical significance can be interpreted for each feature
                </li>
                <li>
                  Outlier assessment for continuous variables can be done
                </li>
              </ul>
            </Grid>

            <Grid item xs={12} md={6}>
              <b>Available operations on Visualizations</b>
              <ul>
                <li>A plethora of charts to choose from</li>
                <li>Quick turn out time for large volumes of data​​</li>
                <li>
                  Several combinations can be achieved based on the column
                  availability
                </li>
              </ul>
            </Grid>
          </Grid>
        </Paragraph>
        <br />

        <div className="form-group col-md-6">
          <Grid container spacing={3}>
            <Grid item xs={12} md={5}>
              <h3>Database</h3>
              <TextField
                id="outlined-adornment-weight"
                select
                variant="outlined"
                label="Choose Database"
                name="choose database"
                value={this.state.value}
                fullWidth
                onChange={this.handleChange}
                className={classes.dropdown}
              >
                <MenuItem value="none" disabled>
                  Choose Database
                </MenuItem>
                <MenuItem value="ibri">IBRI V1</MenuItem>
                <MenuItem value="ml_cohort">
                  ML Cohort (Synthetic Specific)
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={5}>
              <h3>Table</h3>
              <TextField
                id="outlined-adornment-weight"
                select
                variant="outlined"
                label="Choose Table"
                name="choose table"
                value={this.state.table_name}
                fullWidth
                onChange={this.chooseTable}
                className={classes.dropdown}
              >
                {this.state.tb.map((option) => (
                  <MenuItem value={option}>{option}</MenuItem>
                ))}
              </TextField>

              <div className="Chart" />
            </Grid>
            <Grid item xs={12}>
              <h3>Data Table</h3>
              {this.displayTable()}
              {' '}
              <br />
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={6} md={4}>
                <h3>Dimensions:</h3>
                {this.selectdimension()}
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <h3>MEASURES:</h3>
                {this.selectmeasure()}
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <h3>CHOOSE AGGREGATION TYPE:</h3>
                {this.selectcount()}
              </Grid>
            </Grid>
            <br />
            <br />
            <Grid item xs={12}>
              {this.chart()}
            </Grid>
          </Grid>
        </div>
        {this.state.loadingStatus && <Spinner />}
      </SolutionContainerWrapper>
    );
  }

  chart() {
    const classes = this.props;
    return (
      <div>
        <Grid container alignItems="center" spacing={3}>
          <Grid item md={4}>
            <h3>ChartType</h3>
          </Grid>

          <Grid item xs={12} md={4}>
            {this.state.checkedCount !== "" ? (
              <TextField
                select
                variant="outlined"
                value={this.state.getchartType}
                name="table-type"
                onChange={(event) => {
                  this.checkTypeClick(event.target.value);
                }}
                fullWidth
              >
                <MenuItem value="">Choose chartType</MenuItem>
                <MenuItem value="Sankey">Sankey</MenuItem>
                <MenuItem value="Pie">Pie</MenuItem>
                <MenuItem value="Bar">Bar</MenuItem>
                <MenuItem value="Donut">Donut</MenuItem>
              </TextField>
            ) : (
              ""
            )}
          </Grid>

          <ButtonGrid item xs={12} md={4}>
            {this.state.buttonValid ? (
              <MarginButton
                variant="contained"
                color="primary"
                onClick={this.buttonclick}
              >
                Visualize
              </MarginButton>
            ) : (
              ""
            )}
          </ButtonGrid>
        </Grid>

        <StyledDiv id="container" />
        <br />
      </div>
    );
  }

  checkBoxClick(value, checked) {
    if (checked) {
      const list = this.state.checkedDimensions;
      list.push(value);
      this.setState({ checkedDimensions: list });
    } else {
      const index = this.state.checkedDimensions.indexOf(value);
      if (index > -1) {
        const list = this.state.checkedDimensions;

        list.splice(index, 1);

        this.setState({
          checkedDimensions: list,
        });
      }
    }
  }

  checkRadioClick(value) {
    this.setState({
      checkedMeasure: value,
    });
  }

  checkCountClick(value) {
    this.setState({
      checkedCount: value,
      getcount: "count",
    });
  }

  selectdimension() {
    const classes = this.props;
    return (
      <div>
        {this.state.dimensions.map((item) => (
          <Grid>
            <br />
            <div>
              <Checkbox
                onClick={(event) => this.checkBoxClick(item[0], event.target.checked)}
              />
              {item[0]}
            </div>
          </Grid>
        ))}
      </div>
    );
  }

  selectmeasure() {
    const classes = this.props;
    return (
      <div>
        {this.state.measures.map((item) => (
          <Grid>
            <div>
              <Radio
                name="measure"
                onClick={(event) => this.checkRadioClick(item[0])}
              />
              {item[0]}
            </div>
          </Grid>
        ))}
      </div>
    );
  }

  selectcount() {
    const classes = this.props;
    return (
      <Grid>
        <div>
          {this.state.checkedMeasure !== "" ? (
            <div>
              <Radio
                name="count"
                onClick={(event) => this.checkCountClick(true)}
              />
              {" "}
              Count
            </div>
          ) : (
            ""
          )}
        </div>
      </Grid>
    );
  }

  createtable() {
    const head = this.state.t_data[0];
    const other = this.state.t_data.splice(1, this.state.t_data.length - 1);

    this.setState({
      headList: head,
      dataList: other,
    });
  }

  checkTypeClick(value) {
    console.log("checkTypeClick");
    this.setState({ getchartType: value });

    if (value !== "") {
      this.setState({
        buttonValid: true,
      });
    } else {
      this.setState({
        buttonValid: false,
      });
    }
  }

  buttonclick() {
    this.setState({ getchart: this.state.getchartType });
    if (this.state.buttonValid === true) {
      if (this.state.getchartType === "Sankey") {
        if (this.state.checkedDimensions.length >= 2) {
          this.setState({
            buttonValid: true,
            loadingStatus: true,
          });
          setTimeout(
            () => {
              // Start the timer
              this.chart();
            },
            1000,
          );
        } else {
          this.setState({
            notification: {
              open: "error",
              message: "Please select at least 2 dimension values",
            },
          });
          this.setState({
            buttonValid: false,
            loadingStatus: false,
          });
          document.getElementsByName("table-type")[0].value = "";
        }
      } else if (this.state.checkedDimensions.length === 1) {
        this.setState({
          buttonValid: true,
          loadingStatus: true,
        });
        setTimeout(
          () => {
            // Start the timer
            this.chart();
          },
          1000,
        );
      } else {
        this.setState({
          notification: {
            open: "error",
            message: "Please select a dimension value",
          },
        });
        this.setState({
          buttonValid: false,
          loadingStatus: false,
        });
        document.getElementsByName("table-type")[0].value = "";
      }
    }
  }

  getConfiguration(db) {
    if (db == "ibri") {
      this.setState({ db_name: "ibri", db_type: "postgres", db_table: "ibri" });
    } else if (db == "new_ibri") {
      this.setState({
        db_name: "new_ibri",
        db_type: "redshift",
        db_table: "synt_indiana",
      });
    } else if (db == "synt_emr") {
      this.setState({
        db_name: "synt_emr",
        db_type: "mySql",
        db_table: "synt_emr",
      });
    } else if (db == "ml_cohort") {
      this.setState({
        db_name: "ml_cohort",
        db_type: "redshift",
        db_table: "synt_indiana",
      });
    } else if (db == "proxi_v6") {
      this.setState({
        db_name: "proxi_v6",
        db_type: "redshift",
        db_table: "proxi_v6",
      });
    } else {
      this.setState({ db_name: "", db_type: "mySql", db_table: "" });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.value !== prevState.value) {
      this.getConfiguration(this.state.value);
    }

    if (this.state.db_name !== prevState.db_name) {
      const params = `${this.state.db_type},${this.state.db_name.replace(
        /[.,\s]/g,
        "_",
      )},${Token.token}`;
      console.log("params: ", params);
      getQueryResult("getData", params)
        .then((response) => {
          this.setState({ tb: response });
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (this.state.table_name !== prevState.table_name) {
      const params = `${this.state.db_type},${this.state.db_table.replace(
        /[.,\s]/g,
        "_",
      )},${this.state.table_name},${Token.token}`;
      getQueryResult("getTablename", params)
        .then((response) => {
          this.setState({ t_data: response, loadingStatus: false });
          this.createtable();
        })
        .catch((error) => {
          this.setState({ loadingStatus: false });
          console.log(error);
        });
    }

    if (this.state.t_data !== prevState.t_data) {
      const params = `${this.state.db_type},${this.state.db_table.replace(
        /[.,\s]/g,
        "_",
      )},${this.state.table_name},${this.state.t_data[0]},${Token.token}`;
      getQueryResult("columSplit", params)
        .then((response) => {
          this.setState({ dimensions: response[0] });
          this.setState({ measures: response[1] });
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (this.state.getchart !== prevState.getchart) {
      if (this.state.buttonValid) {
        const params = `${this.state.db_type},${this.state.db_table.replace(
          /[.,\s]/g,
          "_",
        )},${this.state.table_name},${this.state.checkedMeasure},${
          this.state.getcount
        },${this.state.getchart}&${this.state.checkedDimensions},${Token.token}`;
        getQueryResult("preProcessed", params)
          .then((response) => {
            if (this.state.getchart == "Sankey") {
              this.setState({ sankeychart: response[0] });

              console.log(response.data[0]);
            } else if (this.state.getchart == "Pie") {
              this.setState({ piechart: response[0] });

              console.log(response.data[0]);
            } else if (this.state.getchart == "Bar") {
              this.setState({ barchart: response[0] });
            } else if (this.state.getchart == "Donut") {
              this.setState({ donutdata: response[0] });
            } else {
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    if (this.state.sankeychart != prevState.sankeychart) {
      this.loadsankey();
    }

    if (this.state.barchart != prevState.barchart) {
      this.loadbar();
    }

    if (this.state.piechart != prevState.piechart) {
      this.loadPie();
    }

    if (this.state.donutdata != prevState.donutdata) {
      this.loaddonut();
    }

    if (this.state.checkedMeasure !== prevState.checkedMeasure) {
      this.selectcount();
    }
  }
}

Selfserviceportal.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default Selfserviceportal;

const ScrollablePaper = styled(Paper)`
  overflow: auto;
`;

const ButtonGrid = styled(Grid)`
  @media only screen and (max-width: 960px) {
    text-align: center;
  }
`;

const MarginButton = styled(StyledButton)`
  @media only screen and (max-width: 960px) {
    margin: 15px;
  }
`;

const StyledDiv = styled.div`
  margin: 10px 0px;
`;
