import React, { memo, useState } from "react";
import {
  Grid,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Grow from "@material-ui/core/Grow";
import Input from "@material-ui/core/Input";

// import local components
import CommonService from "../../../utils/services/CommonService";

import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  ListItem,
  List,
} from "../../../styles/common";
import SolutionContainer from "../../common/SolutionContainerWrapper";

// images
import social_distance_sol1 from "../../../assets/images/app/social-distance1.webp";
import social_distance_sol2 from "../../../assets/images/app/social-distance.webp";

const { uploadFile } = CommonService("z1dApps", "socialDistance");

const SocialDistance = () => {
  const [showSpinner, setSpinner] = useState(false);
  const [result, setResult] = useState(false);
  const [uploadfile, setUploadfile] = useState("");
  const [imageResult, setImageResult] = useState("");
  const [notification, setNotification] = useState("");

  const getsampleResult = () => {
    setResult(true);
    setImageResult("https://vlife.virtusa.com/SocialDistanceDetector/output_social_distance.mp4");
  };

  const getUploadResult = () => {
    setSpinner(true);
    setResult("");
    uploadFile("upload", uploadfile)
      .then((res) => {
        setSpinner(false);
        setResult(true);
        setImageResult(res);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const onChangeFile = (e) => {
    const file = e.currentTarget.files[0];
    setUploadfile(file);
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Social distancing is a method used to control the spread of contagious diseases. It implies that people
            should physically distance themselves from one another, reducing close contact, and thereby reducing the
            spread of a contagious disease (such as coronavirus).
          </p>
          <p>
            Social distance has other aspects also apart from staying 6 feet away. Wearing masks or face coverings
            are very important to prevent the transmission of virus.
          </p>
          <center>
            <Grid item xs={10} sm={10} md={10}>
              <img width="80%" src={social_distance_sol1} alt="" />
            </Grid>
          </center>
          <br />
          <p>
            Social distancing is arguably the most effective nonpharmaceutical way to prevent the spread of a disease — by
            definition, if people are not close together, they cannot spread germs.
          </p>
          <p>
            In this application, a video is fed as an input to a model that continuously detects the social distance
            between people walking and analyses whether they are within 2m (6 feet) distance from each other or not.
          </p>
          <p>OpenCV is used to implement social distancing detectors. </p>
          <strong>The steps to build a social distancing detector include: </strong>
          <List>
            <ListItem>
              Apply object detection to detect all people (and only people) in a video stream
            </ListItem>
            <ListItem>
              Compute the pairwise distances between all detected people
            </ListItem>
            <ListItem>
              Based on these distances, check to see if any two people are less than N pixels apart
            </ListItem>
          </List>
          <p>
            <strong>Application :</strong>
            {' '}
            Social Distance detection can be used at almost any public place like
            schools, hospitals, streets etc. in order to control the public and identify those people who are
            breaking the social distance norms.
          </p>
          <center>
            <Grid item xs={8} sm={8} md={10}>
              <img width="40%" src={social_distance_sol2} alt="" />
            </Grid>
          </center>
          <LinkContainer>
            <Grid container spacing={2}>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://www.kaggle.com/code/didiruh/social-distancing-detector/data?select=pedestrians.mp4"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/SocialDistanceDetector/SocialDistancingDetector.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <br />
        <DemoContainer>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={10} sm={6} md={4}>
              <video height="100%" width="100%" controls>
                <source
                  src="https://vlife.virtusa.com/SocialDistanceDetector/social_distance.mp4"
                  type="video/mp4"
                />
              </video>
              <br />
              <StyledButton
                variant="contained"
                color="primary"
                data-sample="sample_image1"
                onClick={getsampleResult}
              >
                Sample
              </StyledButton>
            </Grid>
            <Grid item xs={12} sm={12} md={1}>
              <h3>OR</h3>
            </Grid>
            <Grid item xs={10} sm={5} md={3}>
              <Input
                type="file"
                color="primary"
                name="uploadedFile"
                onChange={onChangeFile}
              />
            </Grid>
            <Grid item xs={4} sm={3} md={2}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={getUploadResult}
              >
                Execute
              </StyledButton>
            </Grid>

          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
            <Grid container XS={12}>
              <Grid item xs={12} md={12}>
                <Grow in={result} timeout={1500}>
                  <div>
                    <br />
                    <section>
                      <Result><strong>Result : </strong></Result>
                      <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={10} sm={8} md={6}>
                          <video height="100%" width="100%" controls>
                            <source
                              src={imageResult}
                              type="video/mp4"
                            />
                          </video>
                        </Grid>
                      </Grid>
                    </section>
                  </div>
                </Grow>
              </Grid>
            </Grid>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(SocialDistance);
