import React, { memo, useState, useEffect } from "react";
import SolutionContainerWrapper from "../../common/SolutionContainerWrapper";
import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import styled from "styled-components";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import {
    Paragraph,
    StyledButton,
    DemoContainer,
    LinkContainer,
    HighchartContainer
} from "../../../styles/common";
import {
    Grid,
    TableRow,
    TableHead,
    TableCell,
    TableBody,
    Table,
    TableContainer,
    Paper,
    AppBar,
    Tab,
    TextField
} from "@material-ui/core";
import {
    faInfoCircle,
    faTable
} from '@fortawesome/free-solid-svg-icons';
import { StyledTabs } from "./ColbertAnalysis";
import Input from "@material-ui/core/Input";
import CommonService from "../../../utils/services/CommonService";
import ZoomImage from "../../common/ZoomImage";
import Highcharts from 'highcharts';
import { validateForm, isObjKeyhasval } from "../solutions/vnet/util";
import InfoToolTip from "./vnet/components/InfoToolTip";
import productarch from '../../../assets/images/app/archdiagram.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// user id
import getUserData, { getUploadToken } from '../../../utils';

import Plot from 'react-plotly.js';


export const AnnotatedCSVDemo = memo(() => {

    const initialData = {
        text: "",
    };
    const { postQueryResult } = CommonService("z1dAppsv1", "annotatedcsv");
    const Token = getUploadToken()

    const { postQueryResult: postQueryRes, uploadFilesWithBody } = CommonService("pyod1", "pyod1");

    const [userId, setUserId] = useState(getUserData()?.token?.emailid);

    const [showSpinner, setSpinner] = useState(false);
    const [notification, setNotification] = useState("");
    const [selectedFile, setselectedFile] = useState('');
    const [result, setResult] = useState("");
    const [graphData, setGraphData] = useState([]);
    const [graphData2, setGraphData2] = useState([]);
    const [btnEnable, setBtnEnable] = useState(true);
    const [inputValue, setInputValue] = useState("");
    const [inputRange, setInputRange] = useState("50");
    const [output, setOutput] = useState("");
    const [datee1, setDate1] = useState("");
    const [datee2, setDate2] = useState("");
    const [path, setPath] = useState("");
    const [path2, setPath2] = useState("");
    const [imgresult, setImgresult] = useState("");
    const [sampleData, setSampleData] = useState(initialData);
    const [executeresult, setExecuteResult] = useState("");
    const [datasetName, setDatasetName] = useState("");

    // tab value
    const [tabVal, setValue] = useState(0);

    const tabhandleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleInputs = (e) => {
        const { name } = e.target;
        const { value } = e.target;
        setSampleData({
            ...sampleData,
            [name]: value,
        });
    };


    const handleRangeChange = (e) => {

        const value = e.target.value;
        setInputRange(value);

    };

    const handleDate1Change = (e) => {

        const value = e.target.value;
        setDate1(value);

    };

    const handleDate2Change = (e) => {

        const value = e.target.value;
        setDate2(value);

    };

    const showSample1 = () => {
        setOutput("");
        const NewDataSet = {
            text: "Four phone calls today. Average hold time, ten minutes. Fifth call, a person responded. Asked my question and the call was disconnected. Afterwards, there was no attempt by the representative to reach me. All this after calling last week and leaving a voicemail that the company did not follow up on. Customer service? Not yet.",
        };

        setSampleData(NewDataSet);
    };

    const showSample2 = () => {
        setOutput("");
        const NewDataSet = {
            text: "Great communication from the company. My order is always on time. Great business",
        };

        setSampleData(NewDataSet);
    };

    const [fieldError, setFieldError] = useState(false);

    const validationData = {
        inputRange: { maxNum: 100, minNum: 10, onlyNum: true },
    };

    const handleFieldError = (errData) => {
        setFieldError({
            ...fieldError,
            ...errData,
        });
    };

    const { validateOnBlur } = validateForm(validationData, handleFieldError);

    const getOutput = () => {

        const current = new Date();
        const date = `${current.getDate()}/${current.getMonth() + 1
            }/${current.getFullYear()}`;

        const {
            text,
        } = sampleData;

        const params = `${text}`;

        let areValuesEmpty = false;
        Object.keys(sampleData).forEach((key) => {
            if (sampleData[key] === "") {
                areValuesEmpty = true;
            }
        });
        if (areValuesEmpty) {
            setNotification({ open: "error", message: "Fields are empty" });
        } else {
            setOutput("");
            setSpinner(true);

            postQueryResult("rawtextoutput", {
                token: Token.token,
                Context_param: { 
                    Application_name: "Sentiment",
                    UserID: userId,
                },
                Content_param: {
                    review: params
                },
            })
                .then((res) => {
                    setSpinner(false);
                    setOutput(res.Result);
                    setNotification({ open: 'success', message: res.Console });
                })
                .catch((err) => {
                    setNotification({ open: 'error', message: err.message });
                    setSpinner(false);
                });
        }
    };

    const getUploadResult = () => {

        const current = new Date();
        const date = `${current.getDate()}/${current.getMonth() + 1
            }/${current.getFullYear()}`;

        if (!selectedFile) {
            setNotification({ open: 'error', message: 'please upload a csv file' });
            return;
        }

        setResult("");
        setImgresult("");
        setExecuteResult("");
        setDate1("");
        setDate2("");
        setSpinner(true);
        uploadFilesWithBody("upload", {
            file: selectedFile,
            Context_param: JSON.stringify({
                Application_name: "Sentiment",
                UserID: userId,
                Execution_time: date,
            }),
            Content_param: JSON.stringify({}),
        })
            .then((res) => {
                setSpinner(false);
                setResult(res.Result);
                setPath(res.Result.path);
                setDatasetName(res.Result.Dataset_Name);
                setNotification({ open: 'success', message: res.Console });
            })
            .catch((err) => {
                setNotification({ open: 'error', message: err.message });
                setSpinner(false);
            });
    };


    const getExecuteResult = () => {

        const current = new Date();
        const date = `${current.getDate()}/${current.getMonth() + 1
            }/${current.getFullYear()}`;

        if (inputRange < 10 || inputRange > 100) {
            setNotification({
                open: "error",
                message: "Value should be between 10 and 100",
            });
            return;
        }

        setExecuteResult("");
        setImgresult("");

        setSpinner(true);
        postQueryResult("execute", {
            token: Token.token,
            Context_param: {
                Application_name: "Sentiment",
                UserID: userId,
                Execution_time: date,
            },
            Content_param: {
                Dataset_Name: datasetName,
                s3path: path,
                wordcloudlimit: inputRange,

            },
        })
            .then((res) => {
                setSpinner(false);
                setExecuteResult(res.Result);
                setPath2(res.Result.s3path);
                setGraphData(res.Result.issueCount.xaxis);
                setGraphData2(res.Result.issueCount.yaxis);
                setNotification({ open: 'success', message: res.Console });
                setDate1("YYYY-MM-DD");
                setDate2("");
            })
            .catch((err) => {
                setNotification({ open: 'error', message: err.message });
                setSpinner(false);
            });
    };

    const getResult = () => {

        setImgresult("");
        const current = new Date();
        const date = `${current.getDate()}/${current.getMonth() + 1
            }/${current.getFullYear()}`;

        setSpinner(true);
        postQueryResult("result", {
            token: Token.token,
            Context_param: {
                Application_name: "Sentiment",
                UserID: userId,
            },
            Content_param: {
                s3path: path2, start_date: datee1, end_date: datee2
            },
        })
            .then((res) => {
                setSpinner(false);
                setImgresult(res.Result.output)
               
                setNotification({ open: 'success', message: res.Console });
                
                
            })
            .catch((err) => {
                setNotification({ open: 'error', message: err.message });
                setSpinner(false);
            });
    };

    const getSampleFileResult = () => {

        setResult("");
        setExecuteResult("");

        const current = new Date();
        const date = `${current.getDate()}/${current.getMonth() + 1
            }/${current.getFullYear()}`;

        setSpinner(true);
        postQueryRes("sampleupload", {
            token: Token.token,
            Context_param: {
                Application_name: "Sentiment",
                UserID: userId,
            },
            Content_param: {
                Dataset_Name: "ProductReviews.csv",
                s3Path: "Sentiment/sample/ProductReviews.csv"
            },
        })
            .then((res) => {
                setSpinner(false);
                setResult(res.Result);
                setPath(res.Result.path);
                setDatasetName(res.Result.Dataset_Name);
                setNotification({ open: 'success', message: res.Console });
            })
            .catch((err) => {
                setNotification({ open: 'error', message: err.message });
                setSpinner(false);
            });
    };


    const onChangeFile = (e) => {
        const file = e.target.files[0];
        if (file) {
            setselectedFile(file);
            setResult('');
        } else {
            setResult('');
        }
        if (['csv', 'xlsx'].indexOf(file?.name?.split('.')[1]?.toLowerCase()) == -1) {
            setNotification({ open: 'error', message: "please upload only csv and xlsxformat" });
            setselectedFile("");
            setBtnEnable(true)
            return false;
        }
        setBtnEnable(false)

    };



    return (
        <SolutionContainerWrapper snackbar={notification}>
            <Collapse text="Demo">
                <DemoContainer>
                    <AppBar position='static' color='default'>
                        <StyledTabs
                            value={tabVal}
                            onChange={tabhandleChange}
                            variant='fullWidth'
                            aria-label='simple tabs example'
                        >
                            <Tab label='RAW TEXT INPUT' />
                            <Tab label='FILE UPLOAD' />
                        </StyledTabs>
                    </AppBar>
                    <TabPanel value={tabVal} index={0} id={result}>
                        <Grid
                            container
                            spacing={2}
                            direction='row'
                            justify='center'
                            alignItems='center'
                            justifyContent="center"
                        >
                            <Grid item xs={12} sm={9} md={9}>
                                <TextField
                                    name="text"
                                    label="Review"
                                    variant="outlined"
                                    rows="5"
                                    multiline
                                    onChange={handleInputs}
                                    fullWidth
                                    value={sampleData.text}
                                >
                                </TextField>
                            </Grid>
                            <Grid item xs={12}>
                                <StyledButton
                                    style={{ marginTop: '0' }}
                                    variant="contained"
                                    color="primary"
                                    data-sample="sample1"
                                    onClick={showSample1}
                                >
                                    Sample 1
                                </StyledButton>

                                <StyledButton
                                    style={{ marginTop: '0' }}
                                    variant="contained"
                                    color="primary"
                                    data-sample="sample2"
                                    onClick={showSample2}
                                >
                                    Sample 2
                                </StyledButton>
                                <StyledButton
                                    style={{ marginTop: '0' }}
                                    variant='contained'
                                    color='primary'
                                    onClick={getOutput}
                                >
                                    Execute
                                </StyledButton>
                            </Grid>
                        </Grid>
                        {output && (
                            <section>
                                <br /><br />
                                <Grid
                                    container
                                    xs={12}
                                    spacing={0}
                                    direction="row"
                                    justifyContent="space-evenly"
                                    alignItems="center"
                                >
                                    <Grid item xs={12} sm={12} md={11}>
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <HeaderCol align="start">Reviews</HeaderCol>
                                                        <HeaderCol align="start">Category</HeaderCol>
                                                        <HeaderCol align="start">Subissue</HeaderCol>
                                                        <HeaderCol align="start">Sentiment</HeaderCol>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {output?.output?.reviews.map((value, index) => (
                                                        <TableRow>
                                                            <Cell>
                                                                {output?.output?.reviews[index]}
                                                            </Cell>
                                                            <Cell align="center">
                                                                <strong><Textcolor>{output?.output?.issueCategory[index]}</Textcolor></strong>
                                                            </Cell>
                                                            <Cell align="center">
                                                                <strong><Textcolor>{output?.output?.subissue[index]}</Textcolor></strong>
                                                            </Cell>
                                                            <Cell align="center">
                                                                {output?.output?.sentiment[index] == "Neutral" && (
                                                                    <>
                                                                        <strong><BlueText>{output?.output?.sentiment[index]}</BlueText></strong>
                                                                    </>
                                                                )
                                                                }
                                                                {output?.output?.sentiment[index] == "Positive" &&
                                                                    <>
                                                                        <strong><GreenText>{output?.output?.sentiment[index]}</GreenText></strong>
                                                                    </>
                                                                }
                                                                {output?.output?.sentiment[index] == "Negative" &&
                                                                    <>
                                                                        <strong><RedText>{output?.output?.sentiment[index]}</RedText></strong>
                                                                    </>
                                                                }

                                                            </Cell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </section>
                        )}
                    </TabPanel>
                    <TabPanel value={tabVal} index={1}>
                        <DemoHeader>
                            <Paragraph>
                                <h4>
                                    {' '}
                                    <FontAwesomeIcon icon={faInfoCircle} color="#4caf50" />{' '}
                                    &nbsp;Dataset -Requisite
                                </h4>
                                <ul>
                                    <li>The input data file must be of type .csv or .xlsx</li>
                                    <li>The file must contain two columns containing the reviews, as well as the review date</li>
                                    <li>Column headers must be “Review” and “Date</li>
                                </ul>
                                <h4>
                                    <FontAwesomeIcon icon={faTable} /> &nbsp; Choose Dataset
                                </h4>
                            </Paragraph>
                        </DemoHeader>
                        <Grid
                            container
                            spacing={2}
                            direction='row'
                            justify='center'
                            alignItems='center'
                            justifyContent="center"
                        >
                            <Grid item xs={12} sm={12} md={12}>
                                {/* <Paragraph>Please upload your own dataset or download a sample dataset from the given link and upload.</Paragraph> */}
                            </Grid>
                            <Grid item xs={9} sm={5} md={4}>
                                <Input
                                    type='file'
                                    color='primary'
                                    name='uploadedFile'
                                    onChange={onChangeFile}
                                />
                                <HyperText float="right">
                                    &nbsp;&nbsp;&nbsp;
                                    *supports csv and xlsx file formats only</HyperText>
                            </Grid>
                            <Grid item xs={3} sm={3} md={2}>
                                <StyledButton
                                    style={{ marginTop: '0' }}
                                    helperText="Please select a csv file only"
                                    variant='contained'
                                    color='primary'
                                    onClick={getUploadResult}
                                    disabled={btnEnable}
                                >
                                    Upload
                                </StyledButton>
                            </Grid>
                            <Grid item xs={12} sm={12} md={1}>
                                <h3>OR</h3>
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <StyledButton
                                    variant="contained"
                                    color="primary"
                                    onClick={getSampleFileResult}
                                >
                                    Select Sample File
                                </StyledButton>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <hr />
                            </Grid>
                        </Grid>
                        {result && (
                            <section>
                                <br /><br />
                                <Grid
                                    container
                                    xs={12}
                                    spacing={2}
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Grid item xs={10} sm={4} md={4}>
                                        <TextField
                                            name="prefix"
                                            label="Word Cloud Size"
                                            variant="outlined"
                                            onChange={handleRangeChange}
                                            fullWidth
                                            value={inputRange}
                                            onBlur={validateOnBlur}
                                            error={fieldError.inputRange}
                                            helperText={fieldError.inputRange}
                                        />
                                        <center><HyperText>*value should be between 10 and 100</HyperText></center>
                                    </Grid>
                                    <Grid item xs={12} sm={1} md={1}>
                                        <strong>
                                            <InfoToolTip
                                                title="Maximum number of words to be displayed in the word cloud"
                                                info=" "
                                            />
                                        </strong>
                                    </Grid>
                                    <Grid item xs={12} sm={3} md={2}>
                                        <StyledButton
                                            style={{ marginTop: '0' }}
                                            variant='contained'
                                            color='primary'
                                            onClick={getExecuteResult}
                                        >
                                            Execute
                                        </StyledButton>
                                    </Grid>
                                </Grid>
                                {executeresult && (
                                    <section>
                                        <br /><br />
                                        <Grid
                                            container
                                            xs={12}
                                            spacing={0}
                                            direction="row"
                                            justifyContent="space-evenly"
                                            alignItems="center"
                                        >
                                            <Grid item xs={12} sm={12} md={12}>
                                                <ScrollableTable component={Paper}>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <HeaderCol align="start">Reviews</HeaderCol>
                                                                <HeaderCol align="start">Category</HeaderCol>
                                                                <HeaderCol align="start">Subissue</HeaderCol>
                                                                <HeaderCol align="start">Sentiment</HeaderCol>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {executeresult?.output?.reviews.map((value, index) => (
                                                                <TableRow>
                                                                    <Cell>
                                                                        {executeresult?.output?.reviews[index]}
                                                                    </Cell>
                                                                    <Cell align="center">
                                                                        <strong><Textcolor>{executeresult?.output?.issueCategory[index]}</Textcolor></strong>
                                                                    </Cell>
                                                                    <Cell align="center">
                                                                        <strong><Textcolor>{executeresult?.output?.subissue[index]}</Textcolor></strong>
                                                                    </Cell>
                                                                    <Cell align="center">
                                                                        {executeresult?.output?.sentiment[index] == "Neutral" && (
                                                                            <>
                                                                                <strong><BlueText>{executeresult?.output?.sentiment[index]}</BlueText></strong>
                                                                            </>
                                                                        )
                                                                        }
                                                                        {executeresult?.output?.sentiment[index] == "Positive" &&
                                                                            <>
                                                                                <strong><GreenText>{executeresult?.output?.sentiment[index]}</GreenText></strong>
                                                                            </>
                                                                        }
                                                                        {executeresult?.output?.sentiment[index] == "Negative" &&
                                                                            <>
                                                                                <strong><RedText>{executeresult?.output?.sentiment[index]}</RedText></strong>
                                                                            </>
                                                                        }

                                                                    </Cell>
                                                                </TableRow>
                                                            ))}
                                                        </TableBody>
                                                    </Table>
                                                </ScrollableTable>
                                                <br /><br />
                                                <hr></hr>
                                            </Grid>
                                            <br /><br />
                                            <GridLine item xs={12} sm={6} md={6}>
                                                <Titles>Sentiment Distribution</Titles>
                                                {/* <HighchartContainer id="donut" display={result} /> */}
                                                <Plot
                                                    data={[
                                                        {
                                                            values: executeresult?.DonutChartData?.Value,
                                                            labels: executeresult?.DonutChartData?.Label,
                                                            domain: { column: 0 },
                                                            name: '',
                                                            hoverinfo: 'label+value',
                                                            hole: .5,
                                                            type: 'pie',
                                                            marker: {
                                                                colors: ["lightblue", "rgb(144, 237, 125)", "rgb(244, 91, 91)"]
                                                            },
                                                        }
                                                    ]}
                                                    layout={{
                                                        title: '',
                                                        annotations: [
                                                            {
                                                                font: {
                                                                    size: 20
                                                                },
                                                                showarrow: false,
                                                                text: '',
                                                                x: 0.17,
                                                                y: 0.5
                                                            }
                                                        ],
                                                        height: 350,
                                                        width: 500,
                                                        showlegend: true,
                                                    }}
                                                />
                                                <br /><br />
                                            </GridLine>

                                            <Grid item xs={12} sm={6} md={6}>
                                                <Titles>Sentiment Distribution by Category</Titles>
                                                {/* <HighchartContainer id="stackedBar" display={result} /> */}
                                                <Plot
                                                    data={[{
                                                        x: executeresult?.StackedBarChart[0],
                                                        y: executeresult?.StackedBarChart[1],
                                                        name: 'neutral',
                                                        type: 'bar',
                                                        marker: {
                                                            color: 'lightblue'
                                                        },
                                                    },
                                                    // {
                                                    //     x: executeresult?.StackedBarChart[0],
                                                    //     y: executeresult?.StackedBarChart[2],
                                                    //     name: 'positive',
                                                    //     type: 'bar',
                                                    //     marker: {
                                                    //         color: 'lightgreen'
                                                    //     },
                                                    // },
                                                    {
                                                        x: executeresult?.StackedBarChart[0],
                                                        y: executeresult?.StackedBarChart[3],
                                                        name: 'negative',
                                                        type: 'bar',
                                                        marker: {
                                                            color: 'rgb(244, 91, 91)'
                                                        },
                                                    }]}
                                                    layout={{ barmode: 'stack', width: 500, height: 350 }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <hr></hr>
                                            </Grid>
                                            <GridLine item xs={10} sm={6} md={6}>
                                                <Titles>Category-Wise Distribution</Titles>
                                                <Plot
                                                    data={[
                                                        {
                                                            type: 'bar', x: graphData
                                                            , y: graphData2
                                                        },
                                                    ]}
                                                    layout={{ width: 450, height: 350 }}
                                                />
                                            </GridLine>
                                            <Grid item xs={10} sm={6} md={6}>
                                                <Titles>Sub Category-Wise Distribution</Titles>
                                                <Plot
                                                    data={[
                                                        {
                                                            type: 'bar', x: executeresult?.subissueCount?.xaxis
                                                            , y: executeresult?.subissueCount?.yaxis
                                                        },
                                                    ]}
                                                    layout={{ width: 450, height: 350 }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <hr></hr>
                                            </Grid>
                                            <GridLine item xs={12} sm={6} md={6}>
                                                <Titles>Word Cloud on Sub-issue Categories</Titles>
                                                <ZoomImage width="80%" src={executeresult.wordcloud} alt="" />
                                            </GridLine>
                                            <Grid item xs={12} sm={6} md={6}>
                                                <Titles>Word Cloud on Most Frequently Occurring Words</Titles>
                                                <ZoomImage width="80%" src={executeresult.wordcloud2} alt="" />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <hr></hr>
                                            </Grid>
                                        </Grid>
                                        <p> <Titles>Trend Report</Titles></p>
                                        <Grid
                                            container
                                            xs={12}
                                            spacing={0}
                                            direction="row"
                                            justifyContent="space-evenly"
                                            alignItems="flex-start"
                                        >
                                            <Grid item xs={12} sm={4}>
                                                <TextField
                                                    id='outlined-basic'
                                                    size='small'
                                                    fullWidth
                                                    variant='outlined'
                                                    label='Start Date··'
                                                    name='tweetsFromDate'
                                                    type='date'
                                                    value={datee1}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={handleDate1Change}
                                                    format="YYYY-MM-DD"
                                                    InputProps={{ inputProps: { min: executeresult.minimumAllowedDate, max: executeresult.maximumAllowedDate } }}
                                                ></TextField>
                                                <center><HyperText>*Minimum Date: <strong>{executeresult.minimumAllowedDate}</strong></HyperText></center>
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <TextField
                                                    id='outlined-basic'
                                                    size='small'
                                                    fullWidth
                                                    variant='outlined'
                                                    label='End Date··'
                                                    name='tweetsFromDate'
                                                    type='date'
                                                    value={datee2}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    onChange={handleDate2Change}
                                                    format="YYYY-MM-DD"
                                                    InputProps={{ inputProps: { max: executeresult.maximumAllowedDate, min: executeresult.minimumAllowedDate } }}
                                                ></TextField>
                                                <center><HyperText>*Maximum Date: <strong>{executeresult.maximumAllowedDate}</strong></HyperText></center>
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={1}>
                                                <strong>
                                                    <InfoToolTip
                                                        title="The min and Max dates specified are according to the uploaded dataset"
                                                        info=" "
                                                    />
                                                </strong>
                                            </Grid>
                                            <Grid item xs={12} sm={2} md={2}>
                                                <StyledButton
                                                    style={{ marginTop: '0' }}
                                                    variant='contained'
                                                    color='primary'
                                                    onClick={getResult}
                                                >
                                                    Execute
                                                </StyledButton>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <hr></hr>
                                            </Grid>
                                        </Grid>
                                        {imgresult && (
                                            <section>
                                                <br />
                                                <Grid
                                                    container
                                                    xs={12}
                                                    spacing={0}
                                                    direction="row"
                                                    justifyContent="space-evenly"
                                                    alignItems="center"
                                                >
                                                    <GridLine item xs={12} sm={6} md={6}>
                                                        <Titles>Issue Category</Titles>
                                                        <ZoomImage width="80%" src={imgresult.Issuecategory} alt="" />
                                                    </GridLine>
                                                    <Grid item xs={12} sm={6} md={6}>
                                                        <Titles>Sub-Issue Category</Titles>
                                                        <ZoomImage width="80%" src={imgresult.Subissuecategory} alt="" />
                                                    </Grid>
                                                </Grid>
                                            </section>
                                        )}
                                    </section>
                                )}
                            </section>
                        )}
                    </TabPanel>
                    {showSpinner && (
                        <Spinner text="Loading.. Please wait for a minute..." />
                    )}
                </DemoContainer>
            </Collapse>
        </SolutionContainerWrapper >
    );
});

function TabPanel(props) {
    const { children, value, index } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tablepanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {value === index && <PanelContainer>{children}</PanelContainer>}
        </div>
    );
}

const PanelContainer = styled(Paper)`
  &.MuiPaper-root {
    padding: 15px;
  }
`;

const Cell = styled(TableCell)`
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
`;



const HyperText = styled(Paragraph)`
font-size: 13px;
    `;

const GridLine = styled(Grid)`
    border-right: 1px solid grey;
    height: 90%;
    `;

const Titles = styled.p`
font-size: 22px;
    `;

const Textcolor = styled.i`
color:  #004080;
    `;

const RedText = styled.i`
color:  red;
    `;

const BlueText = styled.i`
color:  orange;
    `;

const GreenText = styled.i`
color:  green;
    `;

const ScrollableTable = styled(TableContainer)`
  height: 400px;
    `;

const HeaderCol = styled(TableCell)`
                                    background-color: #00afb9;
                                    color: white;
                                    min-width: 100px;
                                    text-align: center;
                                    height: 5px;
                                    `;

export default function AnnotatedCSVDesc() {
    return (
        <SolutionContainerWrapper>
            <Collapse text="Description">
                <Paragraph>
                    <p>
                        In today's business landscape, it is crucial for companies to prioritize customer feedback to enhance their products effectively. Product managers require valuable insights to shape a comprehensive product roadmap, ensuring that customers receive what they truly desire rather than what businesses assume they need.
                    </p>
                    <p>However, manually analyzing the vast amount of unstructured data available would be a time-consuming endeavor.
                    </p>
                    <p>This is where sentiment analysis comes into play, offering several benefits:</p>
                    <p>
                        <ol>
                            <li>Gain a deep understanding of customer preferences and concerns regarding your product.</li>
                            <li>Compare your product reviews with those of your competitors, identifying areas of strength and areas that need improvement.</li>
                            <li>Obtain real-time, 24/7 access to the latest insights into your product.</li>
                            <li>Save countless hours by automating the data processing and analysis tasks.</li>
                        </ol>
                    </p>
                    <p>Sentiment analysis automates the process of comprehending the sentiment or opinion expressed in a given text. By employing this technique, you can automatically analyze product reviews and categorize them as Positive, Neutral, or Negative. Furthermore, textual content can also be classified based on a few pre-defined categories based on the context of text.</p>
                    <p>For this application, public reviews posted by customers for a general medical product have been collected from various sources like Yelp, Twitter and Trustpilot via web-scrapping. </p>
                    <p>These reviews have been used to train a chained neural network model to classify them according to the categories and sub-categories they fall under.
                        <ul>
                            <li><b>Higher-order issue categories:</b> “Order Management”, “Customer Service” and “Low Quality/Damaged goods”</li>
                            <li><b>Sub-categories:</b> “Incorrect/Incomplete Orders”, “Call Disconnects/Long Hold Times”, “Low Quality/damaged”, “Late Orders”, “Lubricant packs”, “Re-order workflow”, “Covid/Merger”, “Customer Service”, “Miscellaneous”</li>
                        </ul>
                    </p>
                    <p><b>Architecture:</b></p>
                    <center><ZoomImage src={productarch} width="100%" /></center>
                    <p>This application offers the following:
                        <ul>
                            <li>Out of the box sentiment analysis for each review</li>
                            <li>Categorization of reviews according to higher-order categories and sub-categories</li>
                            <li>A word-cloud with top n topics addressed in the reviews</li>
                            <li>A word-cloud with top occurring sub-issue categories</li>
                            <li>An overall, as well as a category-wise sentiment distribution</li>
                            <li>Trend chart generated for a list of reviews given as an input</li>
                        </ul>
                    </p>
                    <p>This application was developed as a POC, considering a general medical product reviews data collected from various sources like Trustpilot, Yelp, Twitter, etc., which can be ultimately modified to suit any DME (durable medical equipment) manufacturer that requires a review analysis dashboard for any DME.</p>
                </Paragraph>
                <LinkContainer>
                    <Grid container spacing={2}>
                        <Grid item>
                            <StyledButton
                                variant="outlined"
                                color="primary"
                                size="large"
                                startIcon={<OpenInNewIcon />}
                            >
                                <a
                                    href="https://material.vlifevirtusa.com/ProductReviewAnalysis/ProductReviewAnalysis.html"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Notebook
                                </a>
                            </StyledButton>
                        </Grid>
                    </Grid>
                </LinkContainer>
            </Collapse>
        </SolutionContainerWrapper>
    );
}
const DemoHeader = styled.div`
  text-align: left;
  h4 {
    border-bottom: 1px solid;
  }
`;
