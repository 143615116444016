import React, { memo, useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

//table
import {
  TableRow,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
} from "@material-ui/core";
import { StyledTableHead } from "./ShapleyEMR";
// local components
import Highcharts from "highcharts";
import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import CommonService from "../../../utils/services/CommonService";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";

function TextBasedEmotionClassification() {
  const [notification, setNotification] = useState("");
  const [sampleData, setSampleData] = useState({ text: "" });
  const [result, SetResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const { getQueryResult } = CommonService("z1dApps", "textBasedEmotion");

  
  const handleInputs = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  
  const showQuery1 = () => {
    SetResultData("");
    const NewDataSet = {
      text: "My Experience was a worst one. The staff was very rude",
    };

    setSampleData(NewDataSet);
  };

  
  const showQuery2 = () => {
    SetResultData("");
    const NewDataSet = {
      text: "I had a fever, because of quick action from medical team, I was well in a week",
    };

    setSampleData(NewDataSet);
  };

  
  const getResult = () => {
    const { text } = sampleData;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });
    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      SetResultData("");
      setSpinner(true);

      getQueryResult("output", text)
        .then((res) => {
          setSpinner(false);
          SetResultData(res);
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
          setSpinner(false);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Patient Feedback Analysis is an application that identifies
            emotions based on a given text/sentence. The application uses
            Natural Language Processing (NLP) to generate the results.
          </p>
          <p>
            In healthcare, feedback is provided by patients which can be used to
            improve the services in future.{" "}
          </p>
          <p>
            But, going through all feedback can be very tedious, costly and
            time-consuming. By using the application associated emotions can be
            generated from the feedback. Strong negative emotions such as anger,
            disapproval and so on, can be paid special attention. Thus,
            improving services provided and overall patients satisfaction.
          </p>
          <p>
            The application is also useful in explaining the mental state of a
            patient based on the emotions generated from survey/input taken by a
            practitioner. The insights developed can be used for providing
            better understanding for the possible associated cause and providing
            better medical help.
          </p>
          <p>
            <b> INPUT: </b> Application takes a text/sentence as input.
          </p>
          <p>
            {" "}
            <b> OUTPUT: </b>
            Application outputs a table containing 5 emotions with their scores.{" "}
          </p>
          <p>
            {" "}
            <b> Model Used and Training: </b>
            This application uses XtremeDistilTransformers which is a distilled
            task-agnostic transformer that leverages task transfer for learning
            a small universal model that can be applied to arbitrary tasks and
            languages. The model leverages task transfer combined with
            multi-task distillation techniques. The l6-h384 checkpoint used has
            6 layers, 384 hidden size, 12 attention heads corresponds to 22
            million parameters with 5.3x speedup over BERT-base. This model is
            more accurate and less resource consuming compared to widely used
            BERT and DistilBERT model. This model is trained on goEmotions
            dataset from huggingface. Each entry in the dataset contains a text
            and every text is associated with one of the 28 emotions present in
            dataset. Each text entry is tokenized and given to the model for the
            prediction. The model is then validated on validation dataset. After
            achieving good result on validation, the model is used to predict on
            input from the user.
          </p>
          <p>
            <b>Potential Use of the Application: </b>
            Automatically identifying the emotions can help build smart
            healthcare solutions that can detect depression and stress among the
            patients. It can be used to identify satisfaction of patients with
            the healthcare services provided. This tool can also be used to
            detect emotions from speech by integrating it with speech to text
            first.
          </p>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/TextBasedEmotionClassification/TextBasedEmotionClassification.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://huggingface.co/bergum/xtremedistil-l6-h384-go-emotion"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Data source
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>

      <Collapse text="Demo">
        <DemoContainer>
          {/* textfield */}
          <Grid
            container
            xs={12}
            spacing={2}
            direction="result"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={10} md={8}>
              <TextField
                rows="2"
                multiline
                variant="outlined"
                name="text"
                fullWidth
                label="Type your Query"
                onChange={handleInputs}
                value={sampleData.text}
              />
            </Grid>
          </Grid>

          <br />

          {/* Buttons */}
          <Grid
            container
            xs={12}
            spacing={2}
            direction="result"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={6} sm={3} md={3}>
              <StyledButton
                id="btn1"
                variant="contained"
                color="primary"
                data-sample="sample1"
                onClick={showQuery1}
              >
                Sample Query 1
              </StyledButton>
            </Grid>

            <Grid item xs={6} sm={3} md={3}>
              <StyledButton
                id="btn2"
                variant="contained"
                color="primary"
                data-sample="sample2"
                onClick={showQuery2}
              >
                Sample Query 2
              </StyledButton>
            </Grid>

            <Grid item xs={6} sm={3} md={3}>
              <StyledButton
                variant="contained"
                color="primary"
                data-sample="sample2"
                onClick={getResult}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>

          <br />
          {showSpinner && <Spinner text="Loading..." />}

          {result && (
            <>
              <Result>
                <strong>Result:</strong>
              </Result>
              <Grid
                container
                spacing={3}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={6} md={3}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableHead align="left">Label</StyledTableHead>
                          <StyledTableHead align="left">
                            Score(%)
                          </StyledTableHead>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {Object.entries(result).map((row) => (
                          <TableRow key={row[0]}>
                            <TableCell align="justify">{row[0]}</TableCell>
                            <TableCell aliign="justify">{row[1]}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </>
          )}
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
}

export default TextBasedEmotionClassification;
