import React, { memo, useState } from "react";
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import styled from "styled-components";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

// import local components
import Collapse from "../../common/Collapse";
import ImageAnalysisInput from "../../common/ImageAnalysisInput";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  SolutionContainer,
  LinkContainer,
} from "../../../styles/common";

// images
import pulmonaryEmbolismPrediction_sol1 from "../../../assets/images/app/pulmonaryEmbolismPrediction_sol1.webp";

const PulmonaryEmbolismPrediction = () => {
  const getResponse = (response) => {
    if (response) {
      setResponse_list(response);
    } else {
      setResponse_list(response);
    }
  };

  const [response_list, setResponse_list] = useState(false);

  return (
    <SolutionContainer>
      <Collapse text="Description">
        <Paragraph>
          <Grid
            container
            xs={12}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12} md={9}>
              <p>
                Pulmonary Embolism (PE) is a blood clot that occurs in the
                lung(s). The restriction of blood flow caused by the clot
                reduces the oxygen levels in the blood and affects other organs
                as well. Multiple or large pulmonary embolisms can be
                life-threatening and must be diagnosed as effectively as
                possible to reduce risks.
              </p>
              <p>
                The current method used to evaluate and diagnose patients
                suspected to have PE is CT Pulmonary Angiography (CTPA). These
                CT scans comprise hundreds of images that are analyzed to
                identify the presence of pulmonary embolisms. Although imaging
                using CTPA is the preferred effective method, time constraints
                arising from the radiologists' side may sometimes lead to
                delayed diagnosis.
              </p>
              <p>
                Combining machine learning capabilities with CTPA imaging could
                help to identify PE cases more accurately, thus making patient
                management, care, and treatment more effective. In this
                application, CT scan images in DICOM format are fed as input to
                detect the presence of pulmonary embolism in the image along
                with the area in which it is present.
              </p>
            </Grid>
            <Grid item xs={12} md={3}>
              <center>
                <Image1 width="100%" src={pulmonaryEmbolismPrediction_sol1} />
              </center>
            </Grid>
            <Grid>
              <Typography>
                The output of this tool comprises of the following:
              </Typography>
              <ul>
                <li>
                  <b>The intensity of the RV/LV ratio:</b>
                  {' '}
                  The right ventricular
                  to left ventricular ratio (RV: LV) measured during a CTPA is a
                  commonly used metric to identify whether the patient has
                  pulmonary hypertension, and to predict death or deterioration
                  in acute PE. An RV/LV ratio
                  {">=1"}
                  {' '}
                  is considered as “high”,
                  while a ratio less than 1 indicates a “low” value for the
                  ratio.
                </li>
                <li>
                  <b>The location of the presence of PE in the image:</b>
                  {" "}
                  Pulmonary embolisms can vary in size and quantity. Sometimes,
                  patients can have one embolism which can be either be small or
                  large in size or multiple embolisms across the lungs that can
                  again vary in size. This output points out whether there are
                  any embolisms in the right, left, or center of the CTPA image
                  given as input. The location of the detected PE is based
                  exclusively on the orientation of the CTPA images given as
                  input, and do not represent the exact location (i.e right or
                  left lung).
                </li>
                <li>
                  <b>The type of PE identified:</b>
                  {' '}
                  PE can be either acute or
                  chronic. Acute PE refers to sudden occurrences of blockages in
                  the pulmonary arteries that are mainly caused by blood clots
                  originating from one part of the body, and swiftly traveling
                  up to the lungs. This type of embolisms are of high risk and
                  may lead to death from cardiovascular disease if not treated
                  immediately. Chronic PE is mainly caused by residual
                  obstructions/clots present in the pulmonary circulation from
                  previous cases of acute PE. Unlike acute PE, this type of PE
                  shows gradual progressive symptoms that can lead to elevations
                  in the blood pressure in the pulmonary arteries over time.
                  This has a chance of developing into a rare, but treatable,
                  type of pulmonary hypertension known as Chronic Thromboembolic
                  Pulmonary Hypertension (CTPH).
                </li>
              </ul>
            </Grid>
          </Grid>
          <LinkContainer>
            <Grid container spacing={2}>

              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/PulmonaryEmbolism/PulmonaryEmbolism.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://www.kaggle.com/c/rsna-str-pulmonary-embolism-detection/data"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Dataset
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <ImageAnalysisInput
            img1="pulmonaryEmbolismPrediction_sample1.webp"
            img2="pulmonaryEmbolismPrediction_sample2.webp"
            url={["z1dApps", "pulmonaryEmbolism"]}
            sample1url="sample1"
            sample2url="sample2"
            helperTextForUpload="Please upload an image (of file type .dcm)"
            result={getResponse}
          />
          {response_list && (
            <BodyCA>
              <List>
                {response_list.map((list_item, index) => (
                  <ListItem>
                    <ListItemIcon><CheckCircleIcon /></ListItemIcon>
                    <ListItemText primary={list_item} />
                  </ListItem>
                ))}
              </List>
            </BodyCA>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(PulmonaryEmbolismPrediction);
const Image1 = styled.img`
  @media only screen and (min-width: 900px) {
    width: 80%;
    height: 250px;
  }
`;

const BodyCA = styled.section`
  text-align: left;
`;
