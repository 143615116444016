import React, { memo, useState, useEffect } from "react";
import {
  InputAdornment, MenuItem, TextField, Grid, Button,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Highcharts from 'highcharts';
import styled from 'styled-components';
// local components

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import Collapse from "../../common/Collapse";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  HighchartContainer,
} from "../../../styles/common";
import getUserData, { getUploadToken } from '../../../utils';
import CommonService from "../../../utils/services/CommonService";
import Spinner from "../../common/loading/Spinner";

const PredictCovid19CasesCountryWise = () => {
  const [result, setResult] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const [fields, setFields] = useState({
    country: "",
    selectedDate: "",
  });
  const { getQueryResult } = CommonService(
    "corona",
    "covid19CountryWise",
  );
  const Token = getUploadToken()

  const onChangeFields = (e) => {
    setFields({ ...fields, [e.target.name]: e.target.value });
  };

  const getResult = (e) => {
    const { selectedDate, country } = fields;
    const params = `${country},${selectedDate.split('-').reverse().join('-')},${Token.token}`;
    if (!selectedDate) {
      setNotification({ open: "error", message: "Please select Date" });
      return;
    }
    if (!country) {
      setNotification({ open: "error", message: "Please select Country" });
      return;
    }
    setResult(null);
    setSpinner(true);
    getQueryResult('result', params)
      .then((res) => {
        setSpinner(false);
        setResult(res);
        formHighChart(res);
      })
      .catch((err) => {
        setSpinner(false);
        setNotification({ error: err.messgae });
      });
  };

  const formHighChart = (data) => {
    const categories = data[0].map((fullDate) => {
      const date = new Date(fullDate);
      return `${months[date.getMonth()]}-${date.getDate()}`;
    });
    Highcharts.chart('container', {
      title: {
        text: `Trend for Covid-19 Prediction of ${fields.country}`,
      },
      yAxis: {
        title: {
          text: 'Confirmed Cases in Thousand',
        },
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
      },
      xAxis: {
        categories,
      },
      series: [{
        name: 'Actual cases',
        data: data[1],
        color: '#000',
      },
      {
        name: 'Predicted cases',
        data: data[2],
        dashStyle: 'dash',
      }],
      responsive: {
        rules: [{
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: 'horizontal',
              align: 'center',
              verticalAlign: 'bottom',
            },
          },
        }],
      },
    });
  };

  useEffect(() => {
    setSpinner(true);
    getQueryResult('countryList')
      .then((res) => {
        setSpinner(false);
        setCountryList(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err.message);
      });
  }, []);

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>The Application is aimed at predicting the confirmed cases of COVID-19 of different countries using Sigmoid Curve. </Paragraph>
        <Paragraph>We could visualize that the virus takes a S-Shaped pattern of the virus spread, ie., The curve is initially flattened and then it peaks out and again it starts to flatten. Select a country and the date to get to know the predicted number of covid-19 confirmed cases in that country. The tool aims to show the trend of the count of cases for both the actual number and the predicted number. </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>

            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/COVID-19/confirmed_cases/Confirmed_Cases.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://github.com/CSSEGISandData/COVID-19/blob/master/csse_covid_19_data/csse_covid_19_time_series/time_series_covid19_confirmed_global.csv"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container justify="center" alignItems="center" spacing={4}>
            <StyledGrid xs={12} sm={6} md={4}>
              <TextField
                id="date"
                label="Select Date"
                type="date"
                format="DD-MM-YYYY"
                fullWidth
                defaultValue="02-01-2020"
                onChange={onChangeFields}
                name="selectedDate"
                value={fields.selectedDate}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{ inputProps: { max: "2020-12-31" } }}
              />

            </StyledGrid>
            <StyledGrid xs={12} sm={6} md={4}>
              <TextField
                id="outlined-adornment-weight"
                variant="outlined"
                select
                fullWidth
                label="Country"
                name="country"
                InputProps={{
                  startAdornment: <InputAdornment position="start">
                    <FontAwesomeIcon icon={faFlag} />
                                  </InputAdornment>,
                }}
                value={fields.country}
                onChange={onChangeFields}
              >
                <MenuItem value="select" disabled>Select Country</MenuItem>
                {countryList?.map((option, index) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </StyledGrid>

            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={getResult}>
                Execute
              </Button>
            </Grid>
          </Grid>
          {showSpinner && <Spinner />}
          {result && (
            <Result>
              <strong>Result:</strong>
              {`Predicted Number of Cases on ${fields.selectedDate} : ${result[3]}`}
            </Result>
          )}

          <HighchartContainer id="container" display={result} />

        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(PredictCovid19CasesCountryWise);

const StyledGrid = styled(Grid)`
  &.MuiGrid-root {
    padding: 10px;
  }
`;
