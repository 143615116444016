import React, { memo, useState } from "react";
import { Grid } from "@material-ui/core";

import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// import local components
import styled from "styled-components";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";
import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";

// images
import chestXRaysCovid19_sample1 from "../../../assets/images/app/chestXRaysCovid19_sample1.webp";
import chestXRaysCovid19_sample2 from "../../../assets/images/app/chestXRaysCovid19_sample2.webp";

const ChestXRaysCovid19 = () => {
  const [response, setResponse] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService("z1dApps", "chestXRaysCovid19");

  const getSampleImgResult = (sample) => {
    setResponse(false);
    setSpinner(true);

    getQueryResult("output", sample)
      .then((res) => {
        setSpinner(false);
        setResponse(res);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Testing is critical in infected diseases such as COVID-19 which are
            declared pandemic. There are several tests that are currently
            implemented using blood and serum samples. Since COVID – 19
            prominently attacks the respiratory system and doctors frequently
            use X-rays and CT scans to diagnose pneumonia, lung inflammation,
            abscesses, and/or enlarged lymph nodes, it would be wise to analyze
            the X-ray images and identify symptoms
            <br />
            <br />
            In this application, a deep learning model is trained that analyzes
            the patterns in a X-ray and identifies the probability of a patient
            showing symptoms of coronavirus. The dataset to train the model is
            obtained from University of Montreal published by Dr. Joseph Cohen
            in the
            {" "}
            <a
              href="https://github.com/ieee8023/covid-chestxray-dataset"
              target="_blank"
              rel="noopener noreferrer"
            >
              following GitHub repo.
            </a>
          </p>
          <p>
            <LinkContainer>
              <Grid container spacing={2}>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://github.com/ieee8023/covid-chestxray-dataset"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
                <Grid item>
                  <StyledButton
                    variant="outlined"
                    color="primary"
                    size="large"
                    startIcon={<OpenInNewIcon />}
                  >
                    <a
                      href="https://material.vlifevirtusa.com/CovidChestXray/covid_detector.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Notebook
                    </a>
                  </StyledButton>
                </Grid>

              </Grid>
            </LinkContainer>
          </p>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <section>
            <Grid
              container
              xs={12}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid xs={12} sm={2} alignItems="center">
                <Image src={chestXRaysCovid19_sample1} width="150px" />
              </Grid>
              <Grid xs={12} sm={2}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={getSampleImgResult.bind(this, "detectcovid19/")}
                >
                  Sample 1
                </StyledButton>
              </Grid>
              <Grid xs={12} sm={1}>
                <h3>OR</h3>
              </Grid>
              <Grid xs={12} sm={2} alignItems="center">
                <Image src={chestXRaysCovid19_sample2} width="150px" />
              </Grid>
              <Grid xs={12} sm={2}>
                <StyledButton
                  variant="contained"
                  color="primary"
                  onClick={getSampleImgResult.bind(this, "detectcovid19_/")}
                >
                  Sample 2
                </StyledButton>
              </Grid>
            </Grid>
          </section>
          <br />
          <br />
          {showSpinner && <Spinner text="Loading..." />}
          {response && (
            <section>
              <Result>
                <strong>Result:</strong>
                {`${response}`}
              </Result>
            </section>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(ChestXRaysCovid19);
const Image = styled.img`
  @media only screen and (max-width: 1100px) {
    max-width: 130px;
    max-height: 130px;
  }
  `;
