import React, { memo, useState } from "react";
import styled from "styled-components";
import {
  Grid,
  Typography,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Grow from "@material-ui/core/Grow";
import Input from "@material-ui/core/Input";

// import local components
import CommonService from "../../../utils/services/CommonService";

import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
} from "../../../styles/common";
import SolutionContainer from "../../common/SolutionContainerWrapper";

// images
import heartSoundsClassification_sol1 from "../../../assets/images/app/heart-beat-dribble.gif";

const { getQueryResult, uploadFile } = CommonService("z1dApps", "heartSound");

const HeartSoundsClassification = () => {
  const [showSpinner, setSpinner] = useState(false);
  const [result, setResult] = useState("");
  const [uploadfile, setUploadfile] = useState("");
  const [notification, setNotification] = useState("");

  const getsampleResult = (e) => {
    setSpinner(true);
    setResult("");
    const selectedSample = e.currentTarget.getAttribute("data-sample");
    getQueryResult("withParams", selectedSample)
      .then((res) => {
        setSpinner(false);
        setResult(res);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const getUploadResult = () => {
    setSpinner(true);
    setResult("");
    uploadFile("upload", uploadfile)
      .then((res) => {
        setSpinner(false);
        setResult(res);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const onChangeFile = (e) => {
    const file = e.currentTarget.files[0];
    setUploadfile(file);
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <Grid container direction="row">
            <Grid item xs={12} sm={12} md={8}>
              <strong>Background</strong>
              <p>
                {' '}
                Heart sounds are the noises generated by the beating heart and the resultant flow of blood through it. In healthy adults, there are two normal heart sounds, often described as a lub and a dub (or dup), that occur in sequence with each heartbeat. These are the first heart sound (S1) and second heart sound (S2), produced by the closing of the atrioventricular valves and semilunar valves, respectively.
              </p>
              <strong>Problem</strong>
              {' '}
              <br />
              <p>An estimated 17.1 million people died from CVDs in 2004, representing 29% of all global deaths. Of these deaths, an estimated 7.2 million were due to coronary heart disease. Any method which can help to detect signs of heart disease could be of great help. </p>
              <p>The problem is of particular interest to machine learning researchers as it involves classification of audio sample data, where distinguishing between classes of interest is non-trivial. Data is gathered in real-world situations and frequently contains background noise of every conceivable type. The differences between heart sounds corresponding to different heart symptoms can also be extremely subtle and challenging to separate. Success in classifying this form of data requires extremely robust classifiers. Despite its medical significance, to date this is a relatively unexplored application for machine learning. Source:  </p>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <center>
                <Image src={heartSoundsClassification_sol1} alt="" />
                {' '}
              </center>
            </Grid>
          </Grid>
          <strong>Exploratory Data Analysis</strong>
          <p>
            The audio files are of varying lengths, between 1 second and 30 seconds (some have been clipped to reduce excessive noise and provide the salient fragment of the sound).
            Most information in heart sounds is contained in the low frequency components, with noise in the higher frequencies. It is common to apply a low-pass filter at 195 Hz. Fast Fourier transforms are also likely to provide useful information about volume and frequency over time.
          </p>
          <strong>Categories in Data</strong>
          <p>
            <strong>
              <i>1. Normal Class</i>
              {' '}
            </strong>
            <br />
            In the Normal category there are normal, healthy heart sounds. These may contain noise in the final second of the recording as the device is removed from the body. They may contain a variety of background noises (from traffic to radios). They may also contain occasional random noise corresponding to breathing, or brushing the microphone against clothing or skin. A normal heart sound has a clear “lub dub, lub dub” pattern, with the time from “lub” to “dub” shorter than the time from “dub” to the next “lub” (when the heart rate is less than 140 beats per minute)(source: Rita Getz)
          </p>
          <p>
            <strong>
              <i>2. Murmur</i>
              {' '}
            </strong>
            {' '}
            <br />
            Heart murmurs sound as though there is a “whooshing, roaring, rumbling, or turbulent fluid” noise in one of two temporal locations: (1) between “lub” and “dub”, or (2) between “dub” and “lub”. They can be a symptom of many heart disorders, some serious. There will still be a “lub” and a “dub”. One of the things that confuses non-medically trained people is that murmurs happen between lub and dub or between dub and lub; not on lub and not on dub.(source: Rita Getz)
          </p>
          <p>
            <strong>
              <i>3. Extrasystole</i>
              {' '}
            </strong>
            {' '}
            <br />
            Extrasystole sounds may appear occasionally and can be identified because there is a heart sound that is out of rhythm involving extra or skipped heartbeats, e.g. a “lub-lub dub” or a “lub dub-dub”. (This is not the same as an extra heart sound as the event is not regularly occuring.) An extrasystole may not be a sign of disease. It can happen normally in an adult and can be very common in children. However, in some situations extrasystoles can be caused by heart diseases. If these diseases are detected earlier, then treatment is likely to be more effective. (source: Rita Getz)
          </p>
          <p>
            <strong>
              <i>4. Artifact</i>
              {' '}
            </strong>
            {' '}
            <br />
            In the Artifact category there are a wide range of different sounds, including feedback squeals and echoes, speech, music and noise. There are usually no discernable heart sounds, and thus little or no temporal periodicity at frequencies below 195 Hz. This category is the most different from the others. It is important to be able to distinguish this category from the other three categories, so that someone gathering the data can be instructed to try again.(source: Rita Getz)
          </p>
          <p>The model takes Audio files as input to produce results. The audio can be recorded via the iStethoscope Pro iPhone app or from any digital stethoscope</p>
          <LinkContainer>
            <Grid container spacing={2}>

              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="http://www.peterjbentley.com/heartchallenge/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Data Source
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/HeartbeatAnalysis/heart-sounds-analysis-and-classification-with-lstm.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <br />
        <DemoContainer>
          <Grid container spacing={2} direction="row" justify="center" alignItems="center">
            <Grid item xs={12} sm={6} md={5}>
              <Audio controls>
                <source src="https://material.vlifevirtusa.com/Heartbeat_Sound/HeartSound_sample1.wav" type="audio/wav" />
              </Audio>
              <br />
              <StyledButton
                variant="contained"
                color="primary"
                data-sample="sample1"
                onClick={getsampleResult}
              >
                Heart sound sample 1
              </StyledButton>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <Audio controls>
                <source src="https://material.vlifevirtusa.com/Heartbeat_Sound/HeartSound_sample2.wav" type="audio/wav" />
              </Audio>
              <br />
              <StyledButton
                variant="contained"
                color="primary"
                data-sample="sample2"
                onClick={getsampleResult}
              >
                Heart sound sample 2
              </StyledButton>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <h3>OR</h3>
            </Grid>
            <Grid item xs={10} sm={6} md={4}>
              <Input
                type="file"
                color="primary"
                name="uploadedFile"
                onChange={onChangeFile}
              />
              <Typography gutterBottom>
                Please upload an audio clip (of file type .wav)
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={getUploadResult}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
          <Grid container spacing={2} XS={12}>
            <Grid item xs={12} md={12}>
              <Grow in={result} timeout={1500}>
                <div>
                  <section>
                    <Result>
                      <strong>Result : </strong>
                      <h4>{result}</h4>
                    </Result>
                  </section>
                </div>
              </Grow>
            </Grid>
          </Grid>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(HeartSoundsClassification);

const Image = styled.img`
  width: 80%;
  @media only screen and (max-width: 900px) {
    width: auto;
    max-width: 300px;
  }  @media only screen and (max-width: 450px) {
    width: auto;
    max-width: 300px;
  }
`;
const Audio = styled.audio`
  margin-top: 3%;
  margin-bottom: 3%;
  width: 300px;
  @media only screen and (max-width: 900px) {
    width: 250px;
  }  @media only screen and (max-width: 450px) {
    width: 220px;
  }
`;
