import React, { memo, useState } from "react";
import {
  Grid,
  FormControl,
  TextField,
  InputAdornment,
  MenuItem,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components

import Highcharts from "highcharts";
import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import CommonService from "../../../utils/services/CommonService";
import Collapse from "../../common/Collapse";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  HighchartContainer,
  Result,
} from "../../../styles/common";
import getUserData, { getUploadToken } from '../../../utils';

const PredictingAdClick = () => {
  const initialData = {
    daily_spent: "",
    age: "",
    income: "",
    internet_usage: "",
    city: "selectCity",
    gender: "selectGender",
    country: "226",
  };

  const cityList = [
    {
      name: "Jordantown",
      value: "317",
    },
    {
      name: "New Steve",
      value: "530",
    },
    {
      name: "Butlerfort",
      value: "61",
    },
    {
      name: "New Daniellefort",
      value: "487",
    },
    {
      name: "Angelhaven",
      value: "21",
    },
  ];
  const gender = [
    { name: "Female", value: 1 },
    { name: "Male", value: 0 },
  ];

  const [sampleData, setSampleData] = useState(initialData);
  const [resultData, setResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService(
    "commercialAnalytics",
    "predictingAdClick",
  );
  const Token = getUploadToken()

  const formHighChart = (data) => {
    Highcharts.chart("container_1", {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
      },
      title: {
        text: "Major Contributing factors",
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "<b>{point.name}</b>: {point.percentage:.1f} %",
          },
        },
      },
      series: [
        {
          name: "feature importance",
          colorByPoint: true,
          data: [
            {
              name: data[1][0],
              y: data[1][1],
            },
            {
              name: data[2][0],
              y: data[2][1],
            },
            {
              name: data[3][0],
              y: data[3][1],
            },
            {
              name: data[4][0],
              y: data[4][1],
            },
            {
              name: data[5][0],
              y: data[5][1],
            },
            {
              name: data[6][0],
              y: data[6][1],
            },
            {
              name: data[7][0],
              y: data[7][1],
            },
          ],
        },
      ],
    });
  };

  const showResult = () => {
    const NewDataSet = {
      daily_spent: "55.20",
      age: "39",
      income: "76560.59",
      internet_usage: "159.46",
      city: "317",
      gender: "1",
      country: "226",
    };
    setSampleData(NewDataSet);
  };
  const showResult2 = () => {
    const NewDataSet = {
      daily_spent: "79.89",
      age: "30",
      income: "50356.06",
      internet_usage: "241.38",
      city: "21",
      gender: "1",
      country: "226",
    };
    setSampleData(NewDataSet);
  };

  const getResult = () => {
    const {
      daily_spent, age, income, internet_usage, city, gender, country,
    } = sampleData;

    const params = `${daily_spent},${age},${income},${internet_usage},${city},${gender},${country},${Token.token}`;
    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });

    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("adClickRate", params)
        .then((res) => {
          setSpinner(false);
          setResultData(res);
          formHighChart(res);
        })
        .catch((err) => {
          setSpinner(false);
          console.log(err);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const onChangeFields = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Advertisements are considered to be a major part of Marketing
            campaign. Before sending out advertisements, it is important to
            understand who the customers are. Ads drive a large part of revenue
            pipeline but a great AD without understanding target consumer is as
            good as firing arrows in dark.
            <br />
            <br />
            This tool can act as a torch in the dark cave of AD campaigning.
            With minimal estimates of target audience, any marketing campaign
            can be fuelled in the right direction and also ensure that right
            message has been passed across the targeted audience.
            <br />
            <br />
            This solution workaround with a fake advertising data set,
            indicating whether or not a particular internet user clicked on an
            Advertisement. Logistic Regression is used as predictive model.
          </p>
          Features present in Data
          <ul type="disc">
            <li>Daily Time Spent on Site </li>
            <li>Age </li>
            <li>Area Income </li>
            <li>Ad Topic Line </li>
            <li>City </li>
            <li>Male </li>
            <li>Country </li>
            <li>Timestamp </li>
            <li>Clicked on Ad </li>
          </ul>
          <LinkContainer>
            <Grid container spacing={2}>

              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Predict_Ad_Click"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://github.com/Virtusa-vLife/Use-Cases/tree/master/Predict_Ad_Click/data"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Data source
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <section>
            <Grid
              container
              xs={12}
              direction="row"
              justify="center"
              spacing={1}
            >
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Age"
                    name="age"
                    onChange={onChangeFields}
                    value={sampleData.age}
                  />
                  <br />
                  <TextField
                    fullWidth
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Daily Time Spent on Site"
                    name="daily_spent"
                    onChange={onChangeFields}
                    value={sampleData.daily_spent}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          Minutes
                        </InputAdornment>
                      ),
                    }}
                  />
                  <br />
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="City"
                    select
                    name="city"
                    onChange={onChangeFields}
                    value={sampleData.city}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">🏙</InputAdornment>
                      ),
                    }}
                  >
                    <MenuItem key="selectCity" value="selectCity" disabled>
                      Select City
                    </MenuItem>
                    {cityList.map((city) => (
                      <MenuItem key={city.value} value={city.value}>
                        {city.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <br />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    id="outlined-adornment-weight"
                    select
                    variant="outlined"
                    label="Gender"
                    name="gender"
                    onChange={onChangeFields}
                    value={sampleData.gender}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">⚥</InputAdornment>
                      ),
                    }}
                  >
                    <MenuItem key="selectGender" value="selectGender" disabled>
                      Select Gender
                    </MenuItem>
                    {gender.map((data) => (
                      <MenuItem value={data.value}>{data.name}</MenuItem>
                    ))}
                  </TextField>
                  <br />

                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    select
                    label="Country"
                    name="country"
                    onChange={onChangeFields}
                    value={sampleData.country}
                  >
                    <MenuItem key="0" value="226">
                      United States of America
                    </MenuItem>
                  </TextField>
                  <br />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <FormControl fullWidth>
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Daily Internet Usage"
                    name="internet_usage"
                    onChange={onChangeFields}
                    value={sampleData.internet_usage}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Gb</InputAdornment>
                      ),
                    }}
                  />
                  <br />
                  <TextField
                    id="outlined-adornment-weight"
                    variant="outlined"
                    label="Area Income"
                    name="income"
                    onChange={onChangeFields}
                    value={sampleData.income}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                  <br />
                </FormControl>
              </Grid>
            </Grid>
            <br />
            <StyledButton
              variant="contained"
              color="primary"
              onClick={showResult}
            >
              Sample Data 1
            </StyledButton>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={showResult2}
            >
              Sample Data 2
            </StyledButton>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={getResult}
            >
              Execute
            </StyledButton>
          </section>
          <br />
          <br />
          {showSpinner && <Spinner text="Loading..." />}
          {resultData && (
            <Result>
              <strong>Result:</strong>
              {`${resultData[0]}`}
            </Result>
          )}
          <Grid item xs={12}>
            <HighchartContainer id="container_1" display />
          </Grid>
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(PredictingAdClick);
