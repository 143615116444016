import React, { memo, useState } from "react";
import styled from "styled-components";
import {
  Grid,
  Typography,
} from "@material-ui/core";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Grow from "@material-ui/core/Grow";
import Input from "@material-ui/core/Input";

// import local components
import CommonService from "../../../utils/services/CommonService";

import Collapse from "../../common/Collapse";
import Spinner from "../../common/loading/Spinner";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  List,
  ListItem,
} from "../../../styles/common";
import SolutionContainer from "../../common/SolutionContainerWrapper";

// images
import imageColorization_sample1 from "../../../assets/images/app/chest-xray.webp";

const { getQueryResult, uploadFile } = CommonService("z1dApps", "imageColorization");

const ImageColorization = () => {
  const [showSpinner, setSpinner] = useState(false);
  const [result, setResult] = useState("");
  const [uploadresult, setUploadResult] = useState("");
  const [imageResult1, setImageResult1] = useState("");
  const [imageResult2, setImageResult2] = useState("");
  const [uploadfile, setUploadfile] = useState("");
  const [notification, setNotification] = useState("");

  const getsampleResult = (e) => {
    setSpinner(true);
    setResult("");
    setUploadResult("");
    getQueryResult("sample")
      .then((res) => {
        setSpinner(false);
        setResult(res);
        setImageResult1("");
        setImageResult2(res.result);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const getUploadResult = () => {
    setSpinner(true);
    setResult("");
    setUploadResult("");
    uploadFile("upload", uploadfile)
      .then((res) => {
        setSpinner(false);
        setResult(res);
        setUploadResult(res);
        setImageResult1(res.result[0]);
        setImageResult2(res.result[1]);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };

  const onChangeFile = (e) => {
    const file = e.currentTarget.files[0];
    setUploadfile(file);
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>Image colorization is the process of taking an input grayscale (black and white) image and then producing an output colorized image that represents the semantic colors and tones of the input.</p>
          <strong>Technique:</strong>
          <p>Image colorization using Convolutional Neural Networks to "hallucinate” what an input grayscale image would look like when colorized.</p>
          <p>To produce more plausible black and white image colorizations, a few additional techniques are utilized such as mean annealing and a specialized loss function for color rebalancing.</p>
          <p>The entire (simplified) process can be summarized as:</p>
          <List>
            <ListItem>Convert all training images from the RGB color space to the Lab color space.</ListItem>
            <ListItem>Use the L channel as the input to the network and train the network to predict the ab channels.</ListItem>
            <ListItem>Combine the input L channel with the predicted ab channels.</ListItem>
            <ListItem>Convert the Lab image back to RGB.</ListItem>
          </List>
          <strong>Applications: </strong>
          <p>Colorized medical images assist in different diseases perversion and treatment. As all minor details are not fully represented by grey scale medical images, therefore sometimes healthy tissues of similar pixels intensity are considered as infectious by medical experts due to the high contrast of medical modality.</p>
          <p>Resulting colorized images contribute in anomalies detection, including cancerous tissues and internal bleeding. </p>
          <p>The main objective of this medical image enhancement research is to facilitate medical professionals in identification of various biological structures. </p>
          <p>Medical colorized images supports in accurate and prompt analysis of patients disease along with suitable medication. Future research can focus on constructing clinical decision support system using colorized medical imaging for robust diagnosis and precise medical treatment.</p>
          <LinkContainer>
            <Grid container spacing={2}>

              <Grid item>
                <StyledButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  startIcon={<OpenInNewIcon />}
                >
                  <a
                    href="https://material.vlifevirtusa.com/ImageColorization/ImageColorization.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Notebook
                  </a>
                </StyledButton>
              </Grid>
            </Grid>
          </LinkContainer>
        </Paragraph>
      </Collapse>
      <Collapse text="Demo">
        <br />
        <DemoContainer>
          <Grid container spacing={2} direction="row" justify="center" alignItems="center">
            <Grid item xs={10} sm={5} md={3}>
              <Image src={imageColorization_sample1} />
              <br />
              <StyledButton
                variant="contained"
                color="primary"
                onClick={getsampleResult}
              >
                Sample1
              </StyledButton>
            </Grid>
            <Grid item xs={12} sm={12} md={1}>
              <h3>OR</h3>
            </Grid>
            <Grid item xs={9} sm={5} md={3}>
              <Input
                type="file"
                color="primary"
                name="uploadedFile"
                onChange={onChangeFile}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={2}>
              <StyledButton
                style={{ marginTop: "0" }}
                variant="contained"
                color="primary"
                onClick={getUploadResult}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
          <Grid container spacing={2} xs={12}>
            <Grid item xs={12} md={12}>
              <Grow in={result} timeout={1500}>
                <div>
                  <section>
                    <Result><strong>Result : </strong></Result>
                  </section>
                  <Grid container direction="row" justify="center" alignItems="center">
                    {uploadresult && (
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography variant="h6" gutterBottom>Original Image </Typography>
                      <ResultImg
                        width="60%"
                        height="250px"
                        src={imageResult1}
                        alt=""
                      />
                    </Grid>
                    )}
                    <Grid item xs={12} sm={6} md={6}>
                      <Typography variant="h6" gutterBottom>Colorized Image</Typography>
                      <ResultImg
                        width="60%"
                        height="250px"
                        src={imageResult2}
                        alt=""
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grow>
            </Grid>
          </Grid>
          )}
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(ImageColorization);

const Image = styled.img`
  width: 200px;
  height: 200px;
  @media only screen and (max-width: 900px) {
    width: auto;
    max-width: 220px;
  }  @media only screen and (max-width: 450px) {
    width: auto;
    max-width: 250px;
  }
`;

const ResultImg = styled.img`
  @media only screen and (max-width: 900px) {
    width: auto;
    max-width: 300px;
    height: 200px;
  }  @media only screen and (max-width: 450px) {
    width: auto;
    max-width: 200px;
    height: 200px;
  }
`;
