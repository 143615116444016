import React, { memo, useState, useEffect } from "react";
import styled from "styled-components";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import {
  Grid,
  Typography,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Table,
} from "@material-ui/core";
import Highcharts from "highcharts";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// local components

import Collapse from "../../common/Collapse";
import CommonService from "../../../utils/services/CommonService";
import Spinner from "../../common/loading/Spinner";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  HighchartContainer,
} from "../../../styles/common";

// image components
import uhgBreastCancerPrediction_sample1 from "../../../assets/images/app/uhgBreastCancerPrediction_sample1.webp";

const UHGBreastCancerPrediction = () => {
  const [dataRows, setDataRows] = useState(false);
  const [fields, setFields] = useState(null);
  const [notification, setNotification] = useState("");
  const [cnnModelDataRes, setCNNModelDataRes] = useState("");
  const [cnnPredictionRes, setCNNPredictionRes] = useState("");
  const [isShapleyChart, setIsShapleyChart] = useState(false);
  const [showSpinner, setSpinner] = useState(false);

  const { getQueryResult } = CommonService(
    "patientEngagement",
    "uhgbreastcancerprediction",
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formHighChart = () => {
    Highcharts.chart("container_1", {
      chart: {
        type: "column",
      },

      title: {
        text: "Scores Comparison before and after Shapley",
      },
      legend: {
        align: "right",
        verticalAlign: "middle",
        layout: "vertical",
      },
      xAxis: {
        categories: ["Accuracy", "AUC", "Precision", "Recall "],
        labels: {
          x: -10,
        },
        title: {
          text: "Values",
        },
      },
      yAxis: {
        allowDecimals: false,
        title: {
          text: "Metrics",
        },
      },
      series: [
        {
          name: "Before Data Shapley ",
          data: [71.33, 66.57, 73.97, 43.2],
        },
        {
          name: "After Data Shapley ",
          data: [78.6, 71.95, 83.33, 49.29],
        },
      ],

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                align: "center",
                verticalAlign: "bottom",
                layout: "horizontal",
              },
              yAxis: {
                labels: {
                  align: "left",
                  x: 0,
                  y: -5,
                },
                title: {
                  text: null,
                },
              },
              subtitle: {
                text: null,
              },
              credits: {
                enabled: false,
              },
            },
          },
        ],
      },
    });
  };

  const getCnnModelData = () => {
    setCNNModelDataRes(true);
    setCNNPredictionRes("");
  };

  const getcnnPrediction = () => {
    formHighChart();
    setIsShapleyChart(true);
    setCNNPredictionRes(true);
  };
  const getSampleData1 = () => {
    setFields("");
    setDataRows("");
    setSpinner(true);

    getQueryResult("sampleData")
      .then((res) => {
        const previewData = JSON.parse(res[0]);
        setFields(previewData.schema.fields);
        setDataRows(previewData.data);
        setSpinner(false);
        console.log(res);
      })
      .catch((err) => {
        setSpinner(false);
        console.log(err);
        setNotification({ open: "error", message: err.message });
      });
  };
  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <p>
            Cancer occurs when changes called mutations take place in genes that
            regulate cell growth. The mutations let the cells divide and
            multiply in an uncontrolled way. Breast cancer is cancer that
            develops in breast cells. Typically, the cancer forms in either the
            lobules or the ducts of the breast. Lobules are the glands that
            produce milk, and ducts are the pathways that bring the milk from
            the glands to the nipple. Cancer can also occur in the fatty tissue
            or the fibrous connective tissue within your breast. The
            uncontrolled cancer cells often invade other healthy breast tissue
            and can travel to the lymph nodes under the arms. The lymph nodes
            are a primary pathway that helps the cancer cells move to other
            parts of the body.
          </p>
          <p>
            Breast cancer can typically be detected during a screening session,
            before the symptoms start showing, or lump is observed. If Breast
            cancer is suspected, a biopsy is conducted to identify whether the
            tumors are non-cancerous or ‘benign’, or whether they are cancerous
            or ‘malignant’.
          </p>

          <ResponsiveTypography variant="h6" gutterBottom>
            <FontAwesomeIcon icon={faInfoCircle} />
            {' '}
            Dataset
          </ResponsiveTypography>
          <p>
            The machine learning methodology has long been used in medical
            diagnosis. The Wisconsin Breast Cancer Database (WBCD) dataset has
            been widely used in research experiments. The breast cancer
            diagnosis using feature values calculated from digitized image of a
            Fine Needle Aspirate (FNA) of a breast mass. These features describe
            the characteristics of the cell nuclei present in the image.
            Separating plane described above was obtained using Multi-surface
            Method-Tree (MSM-T) a classification method which uses linear
            programming to construct a decision tree
          </p>
          <p>
            The dataset consists 32 (ID, diagnosis, 30 real-valued input
            features). The mean, standard error, and "worst" or largest (mean of
            the three largest values) of these features were computed for each
            image, resulting in 30 features There are no missing values and the
            class distribution is; 357 benign, 212 malignant, Hence there isn’t
            much variability or disproportion within the dataset.
          </p>
          <p>
            Ten real-valued features are computed for each cell nucleus:
            <ul>
              <li>
                radius (mean of distances from center to points on the
                perimeter)
              </li>
              <li> texture (standard deviation of gray-scale values)</li>
              <li> perimeter</li>
              <li> area</li>
              <li> smoothness (local variation in radius lengths)</li>
              <li> compactness (perimeter^2 / area - 1.0)</li>
              <li> concavity (severity of concave portions of the contour)</li>
              <li>
                concave points (number of concave portions of the contour)
              </li>
              <li> symmetry</li>
              <li> fractal dimension</li>
            </ul>
          </p>
          <ResponsiveTypography variant="h6" gutterBottom>
            <FontAwesomeIcon icon={faInfoCircle} />
            {' '}
            CTGAN
          </ResponsiveTypography>
          <p>
            CTGAN is a collection of Deep Learning based Synthetic Data
            Generators for single table data, which are able to learn from real
            data and generate synthetic clones with high fidelity.
          </p>
          <p>
            Modeling the probability distribution of rows in tabular data and
            generating realistic synthetic data is a non-trivial task. Tabular
            data usually contains a mix of discrete and continuous columns.
            Continuous columns may have multiple modes whereas discrete columns
            are sometimes imbalanced making the modeling difficult.
            {" "}
          </p>
          <p>
            The sdv.tabular.CTGAN model is based on the GAN-based Deep Learning
            data synthesizer which was presented at the NeurIPS 2020 conference
            by the paper titled Modeling Tabular data using Conditional GAN.
            {" "}
          </p>
          <p>
            From the original 569 rows, using CTGAN we generated 1000 synthetic
            data and later combined the original dataset and the synthetic data
            set, concluding the final data set to1569 rows with its 31 columns.
            {" "}
          </p>

          <ResponsiveTypography variant="h6" gutterBottom>
            <FontAwesomeIcon icon={faInfoCircle} />
            {' '}
            Random forest Classifier
          </ResponsiveTypography>
          <p>
            Random forest, like its name implies, consists of a large number of
            individual decision trees that operate as an ensemble. Each
            individual tree in the random forest spits out a class prediction
            and the class with the most votes becomes our model’s prediction.
            The random forest is a classification algorithm consisting of many
            decisions trees. It uses bagging and feature randomness when
            building each individual tree to try to create an uncorrelated
            forest of trees whose prediction by committee is more accurate than
            that of any individual tree.
          </p>

          <ResponsiveTypography variant="h6" gutterBottom>
            <FontAwesomeIcon icon={faInfoCircle} />
            {' '}
            Data Shapley
          </ResponsiveTypography>
          <p>
            Although Ml models give us fairly good performance results, it is
            undeniable that the dataset used for training may have noisy data.
            Quantifying the value of each data point towards the final
            performance of an AI/ML model can help us identify those data points
            that contribute more positively and those that bring down the model
            performance. This can be achieved using Data Shapley. Data Shapley
            makes use of a principled framework to address the quantification of
            data in the context of machine learning. Truncated Monte Carlo(TMC)
            and Gradient-based methods are implemented to efficiently estimate
            the Shapley values for each data point in the dataset.
          </p>

          <ResponsiveTypography variant="h6" gutterBottom>
            <FontAwesomeIcon icon={faInfoCircle} />
            {' '}
            Why use Data Shapley?
          </ResponsiveTypography>
          <ul>
            <li>
              It is more powerful and effective than generic method like
              leave-one-out or leverage-score sampling algorithms.
            </li>
            <li>
              Data Shapley calculates and assigns a “Shapley value” to each data
              point in the dataset.
            </li>
            <li>
              Data Points with relatively low Shapley values can be considered
              as outliers or corrupted data. Those with higher Shapley values
              can be used to retrain an AI/ML model to obtain increased
              performance.
            </li>
          </ul>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://material.vlifevirtusa.com/BreastCancer/Breast_cancer_CTGAN.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notebook
                </a>
              </StyledButton>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://arxiv.org/abs/1904.02868"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Stanford AI : Citations
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <section>
            <Grid
              container
              xs={12}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid xs={4} alignItems="center">
                <MarginButton
                  variant="contained"
                  color="primary"
                  onClick={getSampleData1}
                >
                  SAMPLE DATA
                </MarginButton>
              </Grid>
            </Grid>
          </section>
          {showSpinner && (
            <section>
              <br />
              {' '}
              <br />
              <Spinner text="Loading..." />
            </section>
          )}
          <br />
          {fields && (
            <section>
              <h4>Sample Tabular Data</h4>

              <ScrollableTable>
                <Table>
                  <TableHead>
                    <TableRow>
                      {fields.map((field) => (
                        <TableCell key={field.name} align="left">
                          {field.name}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(dataRows)
                      && dataRows.map((row) => (
                        <TableRow key={row.index}>
                          {fields.map((field) => (
                            <TableCell align="left">
                              {field.name === "index"
                                ? row[field.name] + 1
                                : row[field.name]}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </ScrollableTable>
              <br />
              <br />
              <center>
                <h4>Class Distribution</h4>
                <Grid item xs={12}>
                  <img width="50%" src={uhgBreastCancerPrediction_sample1} />
                </Grid>
              </center>
              <br />
              <br />
              <p>
                A Random Forest Classifier has been trained on the
                above-mentioned dataset to classify the mammography test result
                as Normal or as having Benign or Malignant tumors.
              </p>
              <br />
              <MarginButton
                variant="contained"
                color="primary"
                onClick={getCnnModelData}
              >
                SHOW RESULTS
              </MarginButton>
              <br />
              <br />
              {cnnModelDataRes && (
                <section>
                  <Grid
                    container
                    xs={12}
                    spacing={2}
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <Grid item xs={12} sm={4}>
                      <h4>Metrics Before Data Shapley </h4>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <StyledTableHead>Values</StyledTableHead>
                            <StyledTableHead>Metrics</StyledTableHead>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          <TableRow key="0">
                            <TableCell align="left">Accuracy</TableCell>
                            <TableCell align="left">71.33</TableCell>
                          </TableRow>
                          <TableRow key="1">
                            <TableCell align="left">AUC</TableCell>
                            <TableCell align="left">66.57</TableCell>
                          </TableRow>
                          <TableRow key="2">
                            <TableCell align="left">Precision</TableCell>
                            <TableCell align="left">73.97</TableCell>
                          </TableRow>
                          <TableRow key="3">
                            <TableCell align="left">Recall</TableCell>
                            <TableCell align="left">43.20</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                    <br />
                    <br />
                    <Grid xs={12} md={4}>
                      <MarginButton
                        variant="contained"
                        color="primary"
                        onClick={getcnnPrediction}
                      >
                        RETRAIN WITH SHAPLEY
                      </MarginButton>
                    </Grid>
                    {cnnPredictionRes && (
                      <Grid xs={12} md={4}>
                        <Grid item xs={12} sm={12}>
                          <h4>
                            <p>Metrics After Data Shapley </p>
                          </h4>

                          <Table>
                            <TableHead>
                              <TableRow>
                                <StyledTableHead>Values</StyledTableHead>
                                <StyledTableHead>Metrics</StyledTableHead>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow key="0">
                                <TableCell align="left">Accuracy</TableCell>
                                <TableCell align="left">78.60</TableCell>
                              </TableRow>
                              <TableRow key="1">
                                <TableCell align="left">AUC</TableCell>
                                <TableCell align="left">71.95</TableCell>
                              </TableRow>
                              <TableRow key="2">
                                <TableCell align="left">Precision</TableCell>
                                <TableCell align="left">83.33</TableCell>
                              </TableRow>
                              <TableRow key="3">
                                <TableCell align="left">Recall</TableCell>
                                <TableCell align="left">49.29</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </section>
              )}
            </section>
          )}
          <Grid container direction="row" justify="center">
            <Grid item xs={12} sm={12}>
              <HighchartContainer id="container_1" display={isShapleyChart} />
            </Grid>
          </Grid>
        </DemoContainer>
      </Collapse>
    </SolutionContainer>
  );
};
export default memo(UHGBreastCancerPrediction);

const MarginButton = styled(StyledButton)`
  margin-right: 10px;
  margin-left: 10px;
`;
const ScrollableTable = styled.div`
  overflow: scroll;
  max-height: 500px;
  .MuiTableCell-root {
    @media only screen and (max-width: 900px) {
      font-size: 14px;
    }
    @media only screen and (max-width: 450px) {
      font-size: 12px;
    }
  }
`;
const StyledTableHead = styled(TableCell)`
  background-color: #3c40af;
  color: white;
  @media only screen and (max-width: 900px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 450px) {
    font-size: 12px;
  }
`;

const ResponsiveTypography = styled(Typography)`
  @media only screen and (max-width: 900px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 450px) {
    font-size: 15px;
  }
`;
