import React, { memo, useState } from "react";
import { Grid, TextField } from "@material-ui/core";
import Highcharts from "highcharts";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

// local components
import Collapse from "../../common/Collapse";

import Spinner from "../../common/loading/Spinner";
import CommonService from "../../../utils/services/CommonService";
import SolutionContainer from "../../common/SolutionContainerWrapper";
import {
  Paragraph,
  StyledButton,
  DemoContainer,
  LinkContainer,
  Result,
  HighchartContainer,
  List,
  ListItem,
} from "../../../styles/common";
import getUserData, { getUploadToken } from '../../../utils';

// images
import arrhythmiaClassification_sol1 from "../../../assets/images/app/arrhytmiaPopup.webp";
import arrhythmiaClassification_sol2 from "../../../assets/images/app/arrhytmiaPopup2.webp";

const ArrhythmiaClassification = () => {
  const initialData = {
    ecgMin: "",
    ecgMax: "",
    ecg25: "",
    ecg50: "",
    ecg75: "",
  };

  const [sampleData, setSampleData] = useState(initialData);
  const [result, SetResultData] = useState(false);
  const [showSpinner, setSpinner] = useState(false);
  const [notification, setNotification] = useState("");

  const { getQueryResult } = CommonService("patientEngagement", "arrhythmiaClassification");
  const Token = getUploadToken()

  const formHighChart = (data) => {
    Highcharts.chart("container", {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      title: {
        text: 'Prediction of  Arrhythmia on ECG Classification',
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          },
        },
      },
      series: [{
        name: 'feature importance',
        colorByPoint: true,
        data: [{
          name: data[1][0][0],
          y: data[1][0][1],
        }, {
          name: data[1][1][0],
          y: data[1][1][1],
        }, {
          name: data[1][2][0],
          y: data[1][2][1],
        }, {
          name: data[1][3][0],
          y: data[1][3][1],
        }, {
          name: data[1][4][0],
          y: data[1][4][1],
        },
        ],
      }],
    });
  };

  const showSample1 = () => {
    const NewDataSet = {
      ecgMin: "0.079985",
      ecgMax: "0.138432",
      ecg25: "0.00000",
      ecg50: "0.052686",
      ecg75: "0.101756",
    };

    setSampleData(NewDataSet);
  };

  const showSample2 = () => {
    const NewDataSet = {
      ecgMin: "0.36567146",
      ecgMax: "0.40034175",
      ecg25: "0.0000000",
      ecg50: "0.42352259",
      ecg75: "0.57763617",
    };

    setSampleData(NewDataSet);
  };

  const getResult = () => {
    const {
      ecgMin, ecgMax, ecg25, ecg50, ecg75,
    } = sampleData;

    const params = `${ecgMin},${ecgMax},${ecg25},${ecg50},${ecg75},${Token.token}`;

    let areValuesEmpty = false;
    Object.keys(sampleData).forEach((key) => {
      if (sampleData[key] === "") {
        areValuesEmpty = true;
      }
    });
    if (areValuesEmpty) {
      setNotification({ open: "error", message: "Fields are empty" });
    } else {
      setSpinner(true);

      getQueryResult("output", params)
        .then((res) => {
          console.log(res);
          setSpinner(false);
          SetResultData(res);
          formHighChart(res);
        })
        .catch((err) => {
          console.log(err);
          setSpinner(false);
          setNotification({ open: "error", message: err.message });
        });
    }
  };

  const handleInputs = (e) => {
    const { name } = e.target;
    const { value } = e.target;
    setSampleData({
      ...sampleData,
      [name]: value,
    });
  };

  return (
    <SolutionContainer snackbar={notification}>
      <Collapse text="Description">
        <Paragraph>
          <strong>What is an ECG?</strong>
          <p>An electrocardiogram (ECG) is a simple test that can be used to check your heart's rhythm and electrical activity. Sensors attached to the skin are used to detect the electrical signals produced by your heart each time it beats. These signals are recorded by a machine and are looked at by a doctor to see if they're unusual. An ECG may be requested by a heart specialist (cardiologist) or any doctor who thinks you might have a problem with your heart, including your general physician. </p>
          <p>An ECG may be requested by a heart specialist (cardiologist) or any doctor who thinks you might have a problem with your heart, including your GP. That's the result of this test we will analyse.</p>
          <p>A normal ECG looks like this : </p>
          <center>
            <Grid item xs={10} sm={8} md={8}>
              <img width="60%" height="300px" src={arrhythmiaClassification_sol1} />
            </Grid>
          </center>
          <strong>Solution</strong>
          <p>
            This application is designed to predict cases of arrhythmia from ECG images.
            The algorithm is trained on the MIT-BIH Arrhythmia database, which contains 48
            half-hour excerpts of two-channel ambulatory ECG recordings, obtained from 47
            subjects studied by the BIH Arrhythmia Laboratory between 1975 and 1979.
            Twenty-three recordings were chosen at random from a set of 4000 24-hour
            ambulatory ECG recordings collected from a mixed population of inpatients
            (about 60%) and outpatients (about 40%) at Boston's Beth Israel Hospital;
            the remaining 25 recordings were selected from the same set to include less
            common but clinically significant cases of arrhythmia that would not be well-represented in a small random sample.
          </p>
          <p>Since the ECG matrix requires 100+ input variables, feature extraction has been performed to take only the important variables into consideration, which are the quartile ranges of the graph. </p>
          <p>The dataset has 5 classes  </p>
          <List>
            <ListItem>
              <strong>N:</strong>
              {' '}
              Non-echotic beats (normal beat)
              {' '}
            </ListItem>
            <ListItem>
              <strong>S:</strong>
              {' '}
              Supraventricular ectopic beats
              {' '}
            </ListItem>
            <ListItem>
              <strong>V:</strong>
              {' '}
              Ventricular ectopic beats
            </ListItem>
            <ListItem>
              <strong>F:</strong>
              {' '}
              Fusion Beats
              {' '}
            </ListItem>
            <ListItem>
              <strong>Q:</strong>
              {' '}
              Unknown Beats
              {' '}
            </ListItem>
          </List>
          <p>The frequency distribution of these classes are as follows: </p>
          <center>
            <Grid item xs={10} sm={8} md={8}>
              <img width="60%" height="300px" src={arrhythmiaClassification_sol2} />
            </Grid>
          </center>
        </Paragraph>
        <LinkContainer>
          <Grid container spacing={2}>
            <Grid item>
              <StyledButton
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInNewIcon />}
              >
                <a
                  href="https://physionet.org/content/mitdb/1.0.0/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Data Source
                </a>
              </StyledButton>
            </Grid>
          </Grid>
        </LinkContainer>
      </Collapse>
      <Collapse text="Demo">
        <DemoContainer>
          <Grid container direction="row" justify="left" spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="Mean of ECG Record*"
                name="ecgMin"
                fullWidth
                onChange={handleInputs}
                value={sampleData.ecgMin}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="25% Quartile of the ECG Record*"
                name="ecg25"
                fullWidth
                onChange={handleInputs}
                value={sampleData.ecg25}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="75% Quartile of the ECG Record*"
                name="ecg75"
                fullWidth
                onChange={handleInputs}
                value={sampleData.ecg75}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="Standard Deviation of ECG Record*"
                name="ecgMax"
                fullWidth
                onChange={handleInputs}
                value={sampleData.ecgMax}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                variant="outlined"
                label="50% Quartile of the ECG Record*"
                name="ecg50"
                fullWidth
                onChange={handleInputs}
                value={sampleData.ecg50}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledButton
                variant="contained"
                color="primary"
                onClick={showSample1}
              >
                Sample Data1
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={showSample2}
              >
                Sample Data2
              </StyledButton>

              <StyledButton
                variant="contained"
                color="primary"
                onClick={getResult}
              >
                Execute
              </StyledButton>
            </Grid>
          </Grid>
          {' '}
          <br />
          <br />
          {showSpinner && <Spinner text="Loading..." />}
          {result && (
          <Result>
            <strong>Result:</strong>
            {`${result[0]}`}
          </Result>
          )}
          <HighchartContainer
            id="container"
            display={result}
          />
        </DemoContainer>
      </Collapse>

    </SolutionContainer>
  );
};

export default memo(ArrhythmiaClassification);
